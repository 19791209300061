// Code generated by protoc-gen-iqwebapi. DO NOT EDIT.
// source: tkttemporaluisvc.proto

import axios from 'axios';
import StoreHash from '@/utils/StoreHash';

const initialState = {
};

const valFuncFind = function (status) {
	return (status == 200) ? true : (status == 404) ? true : false;
}

const default_pageinfo = {
	PageNumber: 0,
	PageItemCount: 0,
	TotalPages: 0,
	TotalItemCount: 0,
	IndexStart: 0,
	PageSize: 10,
	DisplayPageList: [],
	DisplayPageEitherSide: 4,
}

const getters = {
};

const mutations = {
};

const actions = {
	//
	// Service: IQWorkflowService
	// 
	/**
	* RPC Method: GetWorkflowExecutionHistory
	* Description: Get workflow history
	* HTTP Method: GET
	* API Path: /api/v3/temporal/${req.TNamespace}/workflows/${req.TWorkflowID}/${req.TRunID}
	* @param    {GetWorkflowExecutionHistoryRequest} req The API payload object (GetWorkflowExecutionHistoryRequest)
	* @return   {GetWorkflowExecutionHistoryResponse} The API response object (GetWorkflowExecutionHistoryResponse)
	*
	* @typedef  {Object}  GetWorkflowExecutionHistoryRequest
	* @property {api_workflowservice_v1_GetWorkflowExecutionHistoryRequest} request     
	* @property {Number}  SPID        
	* @property {String}  TNamespace  
	* @property {String}  TWorkflowID 
	* @property {String}  TRunID      
	*
	* @typedef  {Object}  GetWorkflowExecutionHistoryResponse
	* @property {api_workflowservice_v1_GetWorkflowExecutionHistoryResponse} response 
	*
	*/
	GetWorkflowExecutionHistory({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.get(`/v3/temporal/${req.TNamespace}/workflows/${req.TWorkflowID}/${req.TRunID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetWorkflowExecutionHistory ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetWorkflowExecutionHistory ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetWorkflowExecutionHistory ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetWorkflowExecutionHistory UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetWorkflowExecutionHistory'))
			})
	},

	/**
	* RPC Method: ListClosedWorkflowExecutions
	* Description: Get closed workflows
	* HTTP Method: GET
	* API Path: /api/v3/temporal/${req.TNamespace}/workflows/closed
	* @param    {ListClosedWorkflowExecutionsRequest} req The API payload object (ListClosedWorkflowExecutionsRequest)
	* @return   {ListClosedWorkflowExecutionsResponse} The API response object (ListClosedWorkflowExecutionsResponse)
	*
	* @typedef  {Object}  ListClosedWorkflowExecutionsRequest
	* @property {api_workflowservice_v1_ListClosedWorkflowExecutionsRequest} request       
	* @property {Number}  SPID          
	* @property {String}  TNamespace    
	* @property {Number}  TStartDate    
	* @property {Number}  TEndDate      
	* @property {String}  TWorkflowID   
	* @property {String}  TWorkflowName 
	* @property {String}  TRunID        
	*
	* @typedef  {Object}  ListClosedWorkflowExecutionsResponse
	* @property {api_workflowservice_v1_ListClosedWorkflowExecutionsResponse} response 
	*
	*/
	ListClosedWorkflowExecutions({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.get(`/v3/temporal/${req.TNamespace}/workflows/closed`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('ListClosedWorkflowExecutions ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('ListClosedWorkflowExecutions ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('ListClosedWorkflowExecutions ERROR:', error)
					return Promise.reject(error)
				}
				console.log('ListClosedWorkflowExecutions UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in ListClosedWorkflowExecutions'))
			})
	},

	/**
	* RPC Method: ListNamespaces
	* Description: Get list of workflow namespaces
	* HTTP Method: GET
	* API Path: /api/v3/temporal/namespaces
	* @param    {ListNamespacesRequest} req The API payload object (ListNamespacesRequest)
	* @return   {ListNamespacesResponse} The API response object (ListNamespacesResponse)
	*
	* @typedef  {Object}  ListNamespacesRequest
	* @property {api_workflowservice_v1_ListNamespacesRequest} request 
	* @property {Number}  SPID    
	*
	* @typedef  {Object}  ListNamespacesResponse
	* @property {api_workflowservice_v1_DescribeNamespaceResponse[]} namespaces 
	*
	*/
	ListNamespaces({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.get(`/v3/temporal/namespaces`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('ListNamespaces ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('ListNamespaces ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('ListNamespaces ERROR:', error)
					return Promise.reject(error)
				}
				console.log('ListNamespaces UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in ListNamespaces'))
			})
	},

	/**
	* RPC Method: ListOpenWorkflowExecutions
	* Description: Get open workflows
	* HTTP Method: GET
	* API Path: /api/v3/temporal/${req.TNamespace}/workflows/open
	* @param    {ListOpenWorkflowExecutionsRequest} req The API payload object (ListOpenWorkflowExecutionsRequest)
	* @return   {ListOpenWorkflowExecutionsResponse} The API response object (ListOpenWorkflowExecutionsResponse)
	*
	* @typedef  {Object}  ListOpenWorkflowExecutionsRequest
	* @property {api_workflowservice_v1_ListOpenWorkflowExecutionsRequest} request       
	* @property {Number}  SPID          
	* @property {String}  TNamespace    
	* @property {Number}  TStartDate    
	* @property {Number}  TEndDate      
	* @property {String}  TWorkflowID   
	* @property {String}  TWorkflowName 
	* @property {String}  TRunID        
	*
	* @typedef  {Object}  ListOpenWorkflowExecutionsResponse
	* @property {api_workflowservice_v1_ListOpenWorkflowExecutionsResponse} response 
	*
	*/
	ListOpenWorkflowExecutions({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.get(`/v3/temporal/${req.TNamespace}/workflows/open`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('ListOpenWorkflowExecutions ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('ListOpenWorkflowExecutions ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('ListOpenWorkflowExecutions ERROR:', error)
					return Promise.reject(error)
				}
				console.log('ListOpenWorkflowExecutions UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in ListOpenWorkflowExecutions'))
			})
	},

	/**
	* RPC Method: ListWorkflowExecutions
	* Description: Get all workflows
	* HTTP Method: GET
	* API Path: /api/v3/temporal/{request.namespace}/workflows
	* @param    {ListWorkflowExecutionsRequest} req The API payload object (ListWorkflowExecutionsRequest)
	* @return   {ListWorkflowExecutionsResponse} The API response object (ListWorkflowExecutionsResponse)
	*
	* @typedef  {Object}  ListWorkflowExecutionsRequest
	* @property {api_workflowservice_v1_ListWorkflowExecutionsRequest} request    ListNamespaces returns the information and configuration for all namespaces.
	* @property {Number}  SPID       ListOpenWorkflowExecutions is a visibility API to list the open executions in a specific namespace.
	* @property {String}  TNamespace ListClosedWorkflowExecutions is a visibility API to list the closed executions in a specific namespace.
	*
	* @typedef  {Object}  ListWorkflowExecutionsResponse
	* @property {api_workflowservice_v1_ListWorkflowExecutionsResponse} response 
	*
	*/
	ListWorkflowExecutions({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.get(`/v3/temporal/{request.namespace}/workflows`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('ListWorkflowExecutions ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('ListWorkflowExecutions ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('ListWorkflowExecutions ERROR:', error)
					return Promise.reject(error)
				}
				console.log('ListWorkflowExecutions UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in ListWorkflowExecutions'))
			})
	},
};

export default {
	namespaced: true,
	state: initialState,
	getters,
	mutations,
	actions,
};