// Code generated by protoc-gen-iqwebapi. DO NOT EDIT.
// source: serviceprovider.proto

import axios from 'axios';
import StoreHash from '@/utils/StoreHash';

const initialState = {
	ServiceProviders: {
		UUIDs: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
	ServiceProviderSearches: {
		SearchTexts: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
	ServiceProviderSettings: {
		UUIDs: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
	ServiceProviderSettingSearches: {
		SearchTexts: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
};

const valFuncFind = function (status) {
	return (status == 200) ? true : (status == 404) ? true : false;
}

const default_pageinfo = {
	PageNumber: 0,
	PageItemCount: 0,
	TotalPages: 0,
	TotalItemCount: 0,
	IndexStart: 0,
	PageSize: 10,
	DisplayPageList: [],
	DisplayPageEitherSide: 4,
}

const getters = {
	getServiceProviderByUUID: state => (UUID) => (state.ServiceProviders.UUIDs[ UUID ] && !state.ServiceProviders.UUIDs[ UUID ].expired()) ?  state.ServiceProviders.UUIDs[ UUID ].hashedData() : null,
	getServiceProvidersForSearchText: state => (SearchText) => state.ServiceProviderSearches[ SearchText ] ? state.ServiceProviderSearches[ SearchText ] : null,
	getServiceProviderSettingByUUID: state => (UUID) => (state.ServiceProviderSettings.UUIDs[ UUID ] && !state.ServiceProviderSettings.UUIDs[ UUID ].expired()) ?  state.ServiceProviderSettings.UUIDs[ UUID ].hashedData() : null,
	getServiceProviderSettingsForSearchText: state => (SearchText) => state.ServiceProviderSettingSearches[ SearchText ] ? state.ServiceProviderSettingSearches[ SearchText ] : null,
};

const mutations = {
	// mutSaveServiceProvider saves a single ServiceProvider object
	// into both the StoreHash cache and the VueX store
	mutSaveServiceProvider(state, obj) {
		if (!obj.UUID) { return } // Must have UUID to Save

		if (!(obj.UUID in state.ServiceProviders.UUIDs)) {
			state.ServiceProviders.UUIDs[ obj.UUID ] = new StoreHash(state.ServiceProviders.lifetime)
		}
		state.ServiceProviders.UUIDs[ obj.UUID ].fix(obj) // Add item to StoreHash
	},
	// mutRemoveServiceProviderByUUID removes a ServiceProvider object
	// with the given UUID from StoreHash cache and VueX store
	mutRemoveServiceProviderByUUID(state, UUID) {
		if (!UUID) {
			// Must have UUID to Remove
			return
		}
		delete state.ServiceProviders.UUIDs[ UUID ]    // remove item from StoreHash
	},
	// mutSaveServiceProviderSearch saves a search response
	// with the given SearchText to the StoreHash cache and VueX store
	mutSaveServiceProviderSearch(state, { SearchText, Response }) {
		if (!SearchText || !Response) {
			// Must have SearchText to Save and Response object
			return
		}
		let storedObj = {
			PageInfo: Object.assign({}, default_pageinfo), // shallow copy of default_pageinfo
			UUIDs: [],
		}
		// Deep copy ??? i.e. JSON.parse(JSON.stringify(obj));
		if (Response.PageInfo) {
			Response.PageInfo = Object.assign({}, Response.PageInfo) // shallow copy of the PageInfo
		}
		if (Array.isArray(Response.ServiceProviders)) {
			for (let i = 0; i < Response.ServiceProviders.length; i++) {
				storedObj.UUIDs.push(Response.ServiceProviders[i].UUID)
			}
		}
		state.ServiceProviderSearches[ SearchText ] = storedObj      // Add search text and list of UUIDs to VueX state

		if (!(SearchText in state.ServiceProviderSearches.SearchTexts)) {
			state.ServiceProviderSearches.SearchTexts[ SearchText ] = new StoreHash(state.ServiceProviderSearches.lifetime)
		}
		state.ServiceProviderSearches.SearchTexts[ SearchText ].fix() // Add item to StoreHash
	},
	// mutRemoveServiceProviderSearch removes a search response
	// with the given SearchText from StoreHash cache and VueX store
	mutRemoveServiceProviderSearch(state, SearchText) {
		if (!SearchText) {
			// Must have SearchText to Remove
			return
		}
		state.ServiceProviderSearches[ SearchText ] = null               // cause VueX to trigger a data cascade
		delete state.ServiceProviderSearches[ SearchText ]               // remove item from VueX state
		delete state.ServiceProviderSearches.SearchTexts[ SearchText ]  // remove item from StoreHash
	},
	// mutSaveServiceProviders saves a paginated response of ServiceProviders
	mutSaveServiceProviders(state, { Response }) {
		if (!Response || !Array.isArray(Response.ServiceProviders)) {
			// Must have Response object with results
			return
		}
		for (let i = 0; i < Response.ServiceProviders.length; i++) {
			if (!(Response.ServiceProviders[i].UUID in state.ServiceProviders.UUIDs)) {
				state.ServiceProviders.UUIDs[ Response.ServiceProviders[i].UUID ] = new StoreHash(state.ServiceProviders.lifetime)
			}
			state.ServiceProviders.UUIDs[ Response.ServiceProviders[i].UUID ].fix(Response.ServiceProviders[i])  // Add single ServiceProvider to StoreHash
		}
	},
	// mutSaveServiceProviderSetting saves a single ServiceProviderSetting object
	// into both the StoreHash cache and the VueX store
	mutSaveServiceProviderSetting(state, obj) {
		if (!obj.UUID) { return } // Must have UUID to Save

		if (!(obj.UUID in state.ServiceProviderSettings.UUIDs)) {
			state.ServiceProviderSettings.UUIDs[ obj.UUID ] = new StoreHash(state.ServiceProviderSettings.lifetime)
		}
		state.ServiceProviderSettings.UUIDs[ obj.UUID ].fix(obj) // Add item to StoreHash
	},
	// mutRemoveServiceProviderSettingByUUID removes a ServiceProviderSetting object
	// with the given UUID from StoreHash cache and VueX store
	mutRemoveServiceProviderSettingByUUID(state, UUID) {
		if (!UUID) {
			// Must have UUID to Remove
			return
		}
		delete state.ServiceProviderSettings.UUIDs[ UUID ]    // remove item from StoreHash
	},
	// mutSaveServiceProviderSettingSearch saves a search response
	// with the given SearchText to the StoreHash cache and VueX store
	mutSaveServiceProviderSettingSearch(state, { SearchText, Response }) {
		if (!SearchText || !Response) {
			// Must have SearchText to Save and Response object
			return
		}
		let storedObj = {
			PageInfo: Object.assign({}, default_pageinfo), // shallow copy of default_pageinfo
			UUIDs: [],
		}
		// Deep copy ??? i.e. JSON.parse(JSON.stringify(obj));
		if (Response.PageInfo) {
			Response.PageInfo = Object.assign({}, Response.PageInfo) // shallow copy of the PageInfo
		}
		if (Array.isArray(Response.ServiceProviderSettings)) {
			for (let i = 0; i < Response.ServiceProviderSettings.length; i++) {
				storedObj.UUIDs.push(Response.ServiceProviderSettings[i].UUID)
			}
		}
		state.ServiceProviderSettingSearches[ SearchText ] = storedObj      // Add search text and list of UUIDs to VueX state

		if (!(SearchText in state.ServiceProviderSettingSearches.SearchTexts)) {
			state.ServiceProviderSettingSearches.SearchTexts[ SearchText ] = new StoreHash(state.ServiceProviderSettingSearches.lifetime)
		}
		state.ServiceProviderSettingSearches.SearchTexts[ SearchText ].fix() // Add item to StoreHash
	},
	// mutRemoveServiceProviderSettingSearch removes a search response
	// with the given SearchText from StoreHash cache and VueX store
	mutRemoveServiceProviderSettingSearch(state, SearchText) {
		if (!SearchText) {
			// Must have SearchText to Remove
			return
		}
		state.ServiceProviderSettingSearches[ SearchText ] = null               // cause VueX to trigger a data cascade
		delete state.ServiceProviderSettingSearches[ SearchText ]               // remove item from VueX state
		delete state.ServiceProviderSettingSearches.SearchTexts[ SearchText ]  // remove item from StoreHash
	},
	// mutSaveServiceProviderSettings saves a paginated response of ServiceProviderSettings
	mutSaveServiceProviderSettings(state, { Response }) {
		if (!Response || !Array.isArray(Response.ServiceProviderSettings)) {
			// Must have Response object with results
			return
		}
		for (let i = 0; i < Response.ServiceProviderSettings.length; i++) {
			if (!(Response.ServiceProviderSettings[i].UUID in state.ServiceProviderSettings.UUIDs)) {
				state.ServiceProviderSettings.UUIDs[ Response.ServiceProviderSettings[i].UUID ] = new StoreHash(state.ServiceProviderSettings.lifetime)
			}
			state.ServiceProviderSettings.UUIDs[ Response.ServiceProviderSettings[i].UUID ].fix(Response.ServiceProviderSettings[i])  // Add single ServiceProviderSetting to StoreHash
		}
	},
};

const actions = {
	// getCachedServiceProviderByUUID fetches a ServiceProvider from the cache
	// and if not in the cache, fetches it from the API
	getCachedServiceProviderByUUID({ state, dispatch, commit }, { UUID, RefreshCache }) {
		if (!RefreshCache && state.ServiceProviders.UUIDs[ UUID ] && !state.ServiceProviders.UUIDs[ UUID ].expired()) {
			// Return the cached record
			return state.ServiceProviders.UUIDs[ UUID ].hashedData()
		}
		// Fetch from the API
		return dispatch('GetServiceProviderByUUID', { UUID }).then(respPayload => {
			commit('mutSaveServiceProvider', respPayload)
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveServiceProviderByUUID', UUID)
			return Promise.reject(error)
		})
	},

	// getCachedServiceProvidersBySearch fetches ServiceProviders from the cache
	// and if not in the cache, fetches it from the API
	getCachedServiceProvidersBySearch({ state, getters, dispatch, commit }, { SPID, SearchText, RefreshCache }) {
		if (!RefreshCache && state.ServiceProviderSearches.SearchTexts[ SearchText ] && !state.ServiceProviderSearches.SearchTexts[ SearchText ].expired()) {
			// Return the cached records
			let resp = getters.getServiceProvidersForSearchText(SearchText)
			resp.ServiceProviders = []
			for (let i = 0; i < resp.UUIDs.length; i++) {
				resp.ServiceProviders.push(getters.getServiceProviderByUUID(resp.UUIDs[i]))
			}
			return Promise.resolve(resp)
		}
		// Search using the API
		const apiReq = {
			SPID: SPID,
			SmartSearch: SearchText,
		}
		return dispatch('FindServiceProvidersPaginated', apiReq).then(respPayload => {
			commit('mutSaveServiceProviderSearch', { SearchText: SearchText, Response: respPayload })
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveServiceProviderSearch', SearchText)
			return Promise.reject(error)
		})
	},

	// getCachedServiceProviderSettingByUUID fetches a ServiceProviderSetting from the cache
	// and if not in the cache, fetches it from the API
	getCachedServiceProviderSettingByUUID({ state, dispatch, commit }, { UUID, RefreshCache }) {
		if (!RefreshCache && state.ServiceProviderSettings.UUIDs[ UUID ] && !state.ServiceProviderSettings.UUIDs[ UUID ].expired()) {
			// Return the cached record
			return state.ServiceProviderSettings.UUIDs[ UUID ].hashedData()
		}
		// Fetch from the API
		return dispatch('GetServiceProviderSettingByUUID', { UUID }).then(respPayload => {
			commit('mutSaveServiceProviderSetting', respPayload)
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveServiceProviderSettingByUUID', UUID)
			return Promise.reject(error)
		})
	},

	// getCachedServiceProviderSettingsBySearch fetches ServiceProviderSettings from the cache
	// and if not in the cache, fetches it from the API
	getCachedServiceProviderSettingsBySearch({ state, getters, dispatch, commit }, { SPID, SearchText, RefreshCache }) {
		if (!RefreshCache && state.ServiceProviderSettingSearches.SearchTexts[ SearchText ] && !state.ServiceProviderSettingSearches.SearchTexts[ SearchText ].expired()) {
			// Return the cached records
			let resp = getters.getServiceProviderSettingsForSearchText(SearchText)
			resp.ServiceProviderSettings = []
			for (let i = 0; i < resp.UUIDs.length; i++) {
				resp.ServiceProviderSettings.push(getters.getServiceProviderSettingByUUID(resp.UUIDs[i]))
			}
			return Promise.resolve(resp)
		}
		// Search using the API
		const apiReq = {
			SPID: SPID,
			SmartSearch: SearchText,
		}
		return dispatch('FindServiceProviderSettingsPaginated', apiReq).then(respPayload => {
			commit('mutSaveServiceProviderSettingSearch', { SearchText: SearchText, Response: respPayload })
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveServiceProviderSettingSearch', SearchText)
			return Promise.reject(error)
		})
	},

	//
	// Service: ServiceProviderRPC
	// 
	/**
	* AddServiceProvider: Add a new ServiceProvider record
	* HTTP Method: POST
	* API Path: /api/v3/service_provider
	* @param    {ServiceProvider} req The API payload object (ServiceProvider)
	* @return   {ServiceProvider} The API response object (ServiceProvider)
	*
	* @typedef  {Object}  ServiceProvider
	* @property {String}  UUID                    The ServiceProvider UUID to search/filter by
	* @property {Number}  SPID                    The Unique ID (int32)
	* @property {String}  Name                    Name
	* @property {Number}  CreatedAt               When the record was first created
	* @property {Number}  UpdatedAt               When the record was last updated
	* @property {Number}  DeletedAt               When the record was deleted
	* @property {StringSlice} Features                The Features enabled for this ServiceProvider
	* @property {String}  LogoWideURL             URL of the Wide Logo
	* @property {String}  LogoIconURL             URL of the Icon Logo
	* @property {String}  DefaultEmailFromName    Default Notification E-Mail From Name (optional)
	* @property {String}  DefaultEmailFromAddress Default Notification E-Mail From Address (required)
	* @property {String}  DefaultSMSFromName      Default SMS From Name (optional, not supported for replies)
	* @property {String}  DefaultSMSFromNumber    Default SMS From Number (optional)
	*
	*/
	AddServiceProvider({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Add)
		return axios.post(`/v3/service_provider`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('AddServiceProvider ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('AddServiceProvider ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('AddServiceProvider ERROR:', error)
					return Promise.reject(error)
				}
				console.log('AddServiceProvider UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in AddServiceProvider'))
			})
	},

	/**
	* AddServiceProviderSetting: Add a new ServiceProviderSetting record
	* HTTP Method: POST
	* API Path: /api/v3/service_provider_setting
	* @param    {ServiceProviderSetting} req The API payload object (ServiceProviderSetting)
	* @return   {ServiceProviderSetting} The API response object (ServiceProviderSetting)
	*
	* @typedef  {Object}  ServiceProviderSetting
	* @property {String}  UUID      The ServiceProvider UUID to search/filter by
	* @property {Number}  SPID      The Unique ID (int32)
	* @property {String}  KeyGroup  Key group name
	* @property {String}  Key       Key name
	* @property {String}  Value     
	* @property {Number}  CreatedAt When the record was first created
	* @property {Number}  UpdatedAt When the record was last updated
	* @property {Number}  DeletedAt When the record was deleted
	*
	*/
	AddServiceProviderSetting({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Add)
		return axios.post(`/v3/service_provider_setting`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('AddServiceProviderSetting ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('AddServiceProviderSetting ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('AddServiceProviderSetting ERROR:', error)
					return Promise.reject(error)
				}
				console.log('AddServiceProviderSetting UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in AddServiceProviderSetting'))
			})
	},

	/**
	* AddServiceProviderSettings: Add multiple new ServiceProviderSetting records
	* HTTP Method: POST
	* API Path: /api/v3/service_provider_settings
	* @param    {ServiceProviderSettings} req The API payload object (ServiceProviderSettings)
	* @return   {ServiceProviderSettings} The API response object (ServiceProviderSettings)
	*
	* @typedef  {Object}  ServiceProviderSettings
	* @property {Number}  SPID                    Service Provider ID - Overrides the SPID in the ServiceProviderSetting objects
	* @property {ServiceProviderSetting[]} ServiceProviderSettings 
	* @property {String}  KeyGroup                KeyGroup (optional) If specified, overrides the KeyGroup in the ServiceProviderSetting objects
	*
	*/
	AddServiceProviderSettings({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.post(`/v3/service_provider_settings`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('AddServiceProviderSettings ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('AddServiceProviderSettings ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('AddServiceProviderSettings ERROR:', error)
					return Promise.reject(error)
				}
				console.log('AddServiceProviderSettings UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in AddServiceProviderSettings'))
			})
	},

	/**
	* DeleteServiceProvider: delete customer entity via customer UUID
	* HTTP Method: DELETE
	* API Path: /api/v3/service_provider/${req.UUID}
	* @param    {ServiceProviderUUIDSearch} req The API payload object (ServiceProviderUUIDSearch)
	* @return   {ServiceProvider} The API response object (ServiceProvider)
	*
	* @typedef  {Object}  ServiceProviderUUIDSearch
	* @property {Number}  SPID Service Provider ID
	* @property {String}  UUID The ServiceProvider UUID to search for
	*
	* @typedef  {Object}  ServiceProvider
	* @property {String}  UUID                    The ServiceProvider UUID to search/filter by
	* @property {Number}  SPID                    The Unique ID (int32)
	* @property {String}  Name                    Name
	* @property {Number}  CreatedAt               When the record was first created
	* @property {Number}  UpdatedAt               When the record was last updated
	* @property {Number}  DeletedAt               When the record was deleted
	* @property {StringSlice} Features                The Features enabled for this ServiceProvider
	* @property {String}  LogoWideURL             URL of the Wide Logo
	* @property {String}  LogoIconURL             URL of the Icon Logo
	* @property {String}  DefaultEmailFromName    Default Notification E-Mail From Name (optional)
	* @property {String}  DefaultEmailFromAddress Default Notification E-Mail From Address (required)
	* @property {String}  DefaultSMSFromName      Default SMS From Name (optional, not supported for replies)
	* @property {String}  DefaultSMSFromNumber    Default SMS From Number (optional)
	*
	*/
	DeleteServiceProvider({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Delete)
		return axios.delete(`/v3/service_provider/${req.UUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveServiceProvider', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('DeleteServiceProvider ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('DeleteServiceProvider ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('DeleteServiceProvider ERROR:', error)
					return Promise.reject(error)
				}
				console.log('DeleteServiceProvider UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in DeleteServiceProvider'))
			})
	},

	/**
	* DeleteServiceProviderSetting: delete customer entity via customer UUID
	* HTTP Method: DELETE
	* API Path: /api/v3/service_provider_setting/${req.UUID}
	* @param    {ServiceProviderSettingUUIDSearch} req The API payload object (ServiceProviderSettingUUIDSearch)
	* @return   {ServiceProviderSetting} The API response object (ServiceProviderSetting)
	*
	* @typedef  {Object}  ServiceProviderSettingUUIDSearch
	* @property {Number}  SPID Service Provider ID
	* @property {String}  UUID The ServiceProviderSetting UUID to search for
	* @property {String}  Key  
	*
	* @typedef  {Object}  ServiceProviderSetting
	* @property {String}  UUID      The ServiceProvider UUID to search/filter by
	* @property {Number}  SPID      The Unique ID (int32)
	* @property {String}  KeyGroup  Key group name
	* @property {String}  Key       Key name
	* @property {String}  Value     
	* @property {Number}  CreatedAt When the record was first created
	* @property {Number}  UpdatedAt When the record was last updated
	* @property {Number}  DeletedAt When the record was deleted
	*
	*/
	DeleteServiceProviderSetting({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Delete)
		return axios.delete(`/v3/service_provider_setting/${req.UUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveServiceProviderSetting', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('DeleteServiceProviderSetting ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('DeleteServiceProviderSetting ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('DeleteServiceProviderSetting ERROR:', error)
					return Promise.reject(error)
				}
				console.log('DeleteServiceProviderSetting UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in DeleteServiceProviderSetting'))
			})
	},

	/**
	* FindServiceProviderSettingsPaginated: Search for ServiceProviderSettings by given fields
	* HTTP Method: GET
	* API Path: /api/v3/service_provider_setting
	* @param    {ServiceProviderSettingSearchRequest} req The API payload object (ServiceProviderSettingSearchRequest)
	* @return   {ServiceProviderSettingPageResponse} The API response object (ServiceProviderSettingPageResponse)
	*
	* @typedef  {Object}  ServiceProviderSettingSearchRequest
	* @property {SearchOptions[]} SearchOptions  Search options to use (for sorting and/or pagination)
	* @property {Boolean} IncludeDeleted Include inactive records?
	* @property {Number}  SPID           The ServiceProviderSetting ID to search/filter by
	* @property {String}  Key            Name to search/filter by
	* @property {String}  KeyGroup       Key group name
	*
	* @typedef  {Object}  SearchOptions  Search options
	* @property {Number}  PageNumber     The page number to start at (or provide IndexStart)
	* @property {Number}  PageSize       The page size
	* @property {Number}  IndexStart     The record number to start at (or provide PageNumber)
	* @property {String}  SortBy         The field to sort by
	* @property {Boolean} SortDesc       Is the sort direction descending?
	*
	* @typedef  {Object}  ServiceProviderSettingPageResponse
	* @property {PageInfo} PageInfo                PageInfo only needs the values ThisPageNumber && PageSize set
	* @property {ServiceProviderSetting[]} ServiceProviderSettings The list of matching ServiceProviderSettings
	*
	* @typedef  {Object}   PageInfo
	* @property {Number}   PageNumber             The page number
	* @property {Number}   PageItemCount          Number of items on this page
	* @property {Number}   TotalPages             Total pages available
	* @property {Number}   TotalItemCount         Total items available
	* @property {Number}   IndexStart             The record number this search started at
	* @property {Number}   PageSize               The length of the page
	* @property {Number[]} DisplayPageList        The array of page numbers to display
	* @property {Number}   DisplayPagesEitherSide Number of pages to display either side
	*
	*/
	FindServiceProviderSettingsPaginated({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Find)
		return axios.get(`/v3/service_provider_setting`, { params: req, validateStatus: valFuncFind }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			commit('mutSaveServiceProviderSettings', { Response: response.data })
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('FindServiceProviderSettingsPaginated ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('FindServiceProviderSettingsPaginated ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('FindServiceProviderSettingsPaginated ERROR:', error)
					return Promise.reject(error)
				}
				console.log('FindServiceProviderSettingsPaginated UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in FindServiceProviderSettingsPaginated'))
			})
	},

	/**
	* FindServiceProvidersPaginated: Search for ServiceProviders by given fields
	* HTTP Method: GET
	* API Path: /api/v3/service_provider
	* @param    {ServiceProviderSearchRequest} req The API payload object (ServiceProviderSearchRequest)
	* @return   {ServiceProviderPageResponse} The API response object (ServiceProviderPageResponse)
	*
	* @typedef  {Object}  ServiceProviderSearchRequest
	* @property {SearchOptions[]} SearchOptions  Search options to use (for sorting and/or pagination)
	* @property {Boolean} IncludeDeleted Include inactive records?
	* @property {Number}  SPID           The ServiceProvider ID to search/filter by
	* @property {String}  Name           Name to search/filter by
	*
	* @typedef  {Object}  SearchOptions  Search options
	* @property {Number}  PageNumber     The page number to start at (or provide IndexStart)
	* @property {Number}  PageSize       The page size
	* @property {Number}  IndexStart     The record number to start at (or provide PageNumber)
	* @property {String}  SortBy         The field to sort by
	* @property {Boolean} SortDesc       Is the sort direction descending?
	*
	* @typedef  {Object}  ServiceProviderPageResponse
	* @property {PageInfo} PageInfo         PageInfo only needs the values ThisPageNumber && PageSize set
	* @property {ServiceProvider[]} ServiceProviders The list of matching ServiceProviders
	*
	* @typedef  {Object}   PageInfo
	* @property {Number}   PageNumber             The page number
	* @property {Number}   PageItemCount          Number of items on this page
	* @property {Number}   TotalPages             Total pages available
	* @property {Number}   TotalItemCount         Total items available
	* @property {Number}   IndexStart             The record number this search started at
	* @property {Number}   PageSize               The length of the page
	* @property {Number[]} DisplayPageList        The array of page numbers to display
	* @property {Number}   DisplayPagesEitherSide Number of pages to display either side
	*
	*/
	FindServiceProvidersPaginated({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Find)
		return axios.get(`/v3/service_provider`, { params: req, validateStatus: valFuncFind }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			commit('mutSaveServiceProviders', { Response: response.data })
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('FindServiceProvidersPaginated ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('FindServiceProvidersPaginated ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('FindServiceProvidersPaginated ERROR:', error)
					return Promise.reject(error)
				}
				console.log('FindServiceProvidersPaginated UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in FindServiceProvidersPaginated'))
			})
	},

	/**
	* GetServiceProviderBySPID: Get a ServiceProvider using the unique ServiceProvider SPID
	* HTTP Method: GET
	* API Path: /api/v3/service_provider_spid/${req.SPID}
	* @param    {ServiceProviderSPIDSearch} req The API payload object (ServiceProviderSPIDSearch)
	* @return   {ServiceProvider} The API response object (ServiceProvider)
	*
	* @typedef  {Object}  ServiceProviderSPIDSearch
	* @property {Number}  SPID Service Provider ID
	*
	* @typedef  {Object}  ServiceProvider
	* @property {String}  UUID                    The ServiceProvider UUID to search/filter by
	* @property {Number}  SPID                    The Unique ID (int32)
	* @property {String}  Name                    Name
	* @property {Number}  CreatedAt               When the record was first created
	* @property {Number}  UpdatedAt               When the record was last updated
	* @property {Number}  DeletedAt               When the record was deleted
	* @property {StringSlice} Features                The Features enabled for this ServiceProvider
	* @property {String}  LogoWideURL             URL of the Wide Logo
	* @property {String}  LogoIconURL             URL of the Icon Logo
	* @property {String}  DefaultEmailFromName    Default Notification E-Mail From Name (optional)
	* @property {String}  DefaultEmailFromAddress Default Notification E-Mail From Address (required)
	* @property {String}  DefaultSMSFromName      Default SMS From Name (optional, not supported for replies)
	* @property {String}  DefaultSMSFromNumber    Default SMS From Number (optional)
	*
	*/
	GetServiceProviderBySPID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.get(`/v3/service_provider_spid/${req.SPID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetServiceProviderBySPID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetServiceProviderBySPID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetServiceProviderBySPID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetServiceProviderBySPID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetServiceProviderBySPID'))
			})
	},

	/**
	* GetServiceProviderByUUID: Get a ServiceProvider using the unique ServiceProvider UUID
	* HTTP Method: GET
	* API Path: /api/v3/service_provider/${req.UUID}
	* @param    {ServiceProviderUUIDSearch} req The API payload object (ServiceProviderUUIDSearch)
	* @return   {ServiceProvider} The API response object (ServiceProvider)
	*
	* @typedef  {Object}  ServiceProviderUUIDSearch
	* @property {Number}  SPID Service Provider ID
	* @property {String}  UUID The ServiceProvider UUID to search for
	*
	* @typedef  {Object}  ServiceProvider
	* @property {String}  UUID                    The ServiceProvider UUID to search/filter by
	* @property {Number}  SPID                    The Unique ID (int32)
	* @property {String}  Name                    Name
	* @property {Number}  CreatedAt               When the record was first created
	* @property {Number}  UpdatedAt               When the record was last updated
	* @property {Number}  DeletedAt               When the record was deleted
	* @property {StringSlice} Features                The Features enabled for this ServiceProvider
	* @property {String}  LogoWideURL             URL of the Wide Logo
	* @property {String}  LogoIconURL             URL of the Icon Logo
	* @property {String}  DefaultEmailFromName    Default Notification E-Mail From Name (optional)
	* @property {String}  DefaultEmailFromAddress Default Notification E-Mail From Address (required)
	* @property {String}  DefaultSMSFromName      Default SMS From Name (optional, not supported for replies)
	* @property {String}  DefaultSMSFromNumber    Default SMS From Number (optional)
	*
	*/
	GetServiceProviderByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Get)
		return axios.get(`/v3/service_provider/${req.UUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveServiceProvider', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetServiceProviderByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetServiceProviderByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetServiceProviderByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetServiceProviderByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetServiceProviderByUUID'))
			})
	},

	/**
	* GetServiceProviderSettingByUUID: Get a ServiceProviderSetting using the unique ServiceProviderSetting UUID
	* HTTP Method: GET
	* API Path: /api/v3/service_provider_setting/${req.UUID}
	* @param    {ServiceProviderSettingUUIDSearch} req The API payload object (ServiceProviderSettingUUIDSearch)
	* @return   {ServiceProviderSetting} The API response object (ServiceProviderSetting)
	*
	* @typedef  {Object}  ServiceProviderSettingUUIDSearch
	* @property {Number}  SPID Service Provider ID
	* @property {String}  UUID The ServiceProviderSetting UUID to search for
	* @property {String}  Key  
	*
	* @typedef  {Object}  ServiceProviderSetting
	* @property {String}  UUID      The ServiceProvider UUID to search/filter by
	* @property {Number}  SPID      The Unique ID (int32)
	* @property {String}  KeyGroup  Key group name
	* @property {String}  Key       Key name
	* @property {String}  Value     
	* @property {Number}  CreatedAt When the record was first created
	* @property {Number}  UpdatedAt When the record was last updated
	* @property {Number}  DeletedAt When the record was deleted
	*
	*/
	GetServiceProviderSettingByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Get)
		return axios.get(`/v3/service_provider_setting/${req.UUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveServiceProviderSetting', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetServiceProviderSettingByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetServiceProviderSettingByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetServiceProviderSettingByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetServiceProviderSettingByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetServiceProviderSettingByUUID'))
			})
	},

	/**
	* GetServiceProviderSettingsUUIDs: 
	* HTTP Method: GET
	* API Path: /api/v3/service_provider_setting_uuids
	* @param    {ServiceProviderSettingSearchRequest} req The API payload object (ServiceProviderSettingSearchRequest)
	* @return   {tktcommonproto.UUIDsResponse} The API response object (tktcommonproto.UUIDsResponse)
	*
	* @typedef  {Object}  ServiceProviderSettingSearchRequest
	* @property {SearchOptions[]} SearchOptions  Search options to use (for sorting and/or pagination)
	* @property {Boolean} IncludeDeleted Include inactive records?
	* @property {Number}  SPID           The ServiceProviderSetting ID to search/filter by
	* @property {String}  Key            Name to search/filter by
	* @property {String}  KeyGroup       Key group name
	*
	* @typedef  {Object}  tktcommonproto.UUIDsResponse
	*
	*/
	GetServiceProviderSettingsUUIDs({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: FindUUIDs)
		return axios.get(`/v3/service_provider_setting_uuids`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetServiceProviderSettingsUUIDs ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetServiceProviderSettingsUUIDs ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetServiceProviderSettingsUUIDs ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetServiceProviderSettingsUUIDs UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetServiceProviderSettingsUUIDs'))
			})
	},

	/**
	* GetServiceProvidersUUIDs: 
	* HTTP Method: GET
	* API Path: /api/v3/service_provider_uuids
	* @param    {ServiceProviderSearchRequest} req The API payload object (ServiceProviderSearchRequest)
	* @return   {tktcommonproto.UUIDsResponse} The API response object (tktcommonproto.UUIDsResponse)
	*
	* @typedef  {Object}  ServiceProviderSearchRequest
	* @property {SearchOptions[]} SearchOptions  Search options to use (for sorting and/or pagination)
	* @property {Boolean} IncludeDeleted Include inactive records?
	* @property {Number}  SPID           The ServiceProvider ID to search/filter by
	* @property {String}  Name           Name to search/filter by
	*
	* @typedef  {Object}  tktcommonproto.UUIDsResponse
	*
	*/
	GetServiceProvidersUUIDs({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: FindUUIDs)
		return axios.get(`/v3/service_provider_uuids`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetServiceProvidersUUIDs ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetServiceProvidersUUIDs ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetServiceProvidersUUIDs ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetServiceProvidersUUIDs UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetServiceProvidersUUIDs'))
			})
	},

	/**
	* PurgeServiceProvider: Purge customer entity via customer UUID
	* HTTP Method: DELETE
	* API Path: /api/v3/service_provider/${req.UUID}/purge
	* @param    {ServiceProviderUUIDSearch} req The API payload object (ServiceProviderUUIDSearch)
	* @return   {ServiceProvider} The API response object (ServiceProvider)
	*
	* @typedef  {Object}  ServiceProviderUUIDSearch
	* @property {Number}  SPID Service Provider ID
	* @property {String}  UUID The ServiceProvider UUID to search for
	*
	* @typedef  {Object}  ServiceProvider
	* @property {String}  UUID                    The ServiceProvider UUID to search/filter by
	* @property {Number}  SPID                    The Unique ID (int32)
	* @property {String}  Name                    Name
	* @property {Number}  CreatedAt               When the record was first created
	* @property {Number}  UpdatedAt               When the record was last updated
	* @property {Number}  DeletedAt               When the record was deleted
	* @property {StringSlice} Features                The Features enabled for this ServiceProvider
	* @property {String}  LogoWideURL             URL of the Wide Logo
	* @property {String}  LogoIconURL             URL of the Icon Logo
	* @property {String}  DefaultEmailFromName    Default Notification E-Mail From Name (optional)
	* @property {String}  DefaultEmailFromAddress Default Notification E-Mail From Address (required)
	* @property {String}  DefaultSMSFromName      Default SMS From Name (optional, not supported for replies)
	* @property {String}  DefaultSMSFromNumber    Default SMS From Number (optional)
	*
	*/
	PurgeServiceProvider({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Purge)
		return axios.delete(`/v3/service_provider/${req.UUID}/purge`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveServiceProvider', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('PurgeServiceProvider ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('PurgeServiceProvider ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('PurgeServiceProvider ERROR:', error)
					return Promise.reject(error)
				}
				console.log('PurgeServiceProvider UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in PurgeServiceProvider'))
			})
	},

	/**
	* PurgeServiceProviderSetting: Purge customer entity via customer UUID
	* HTTP Method: DELETE
	* API Path: /api/v3/service_provider_setting/${req.UUID}/purge
	* @param    {ServiceProviderSettingUUIDSearch} req The API payload object (ServiceProviderSettingUUIDSearch)
	* @return   {ServiceProviderSetting} The API response object (ServiceProviderSetting)
	*
	* @typedef  {Object}  ServiceProviderSettingUUIDSearch
	* @property {Number}  SPID Service Provider ID
	* @property {String}  UUID The ServiceProviderSetting UUID to search for
	* @property {String}  Key  
	*
	* @typedef  {Object}  ServiceProviderSetting
	* @property {String}  UUID      The ServiceProvider UUID to search/filter by
	* @property {Number}  SPID      The Unique ID (int32)
	* @property {String}  KeyGroup  Key group name
	* @property {String}  Key       Key name
	* @property {String}  Value     
	* @property {Number}  CreatedAt When the record was first created
	* @property {Number}  UpdatedAt When the record was last updated
	* @property {Number}  DeletedAt When the record was deleted
	*
	*/
	PurgeServiceProviderSetting({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Purge)
		return axios.delete(`/v3/service_provider_setting/${req.UUID}/purge`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveServiceProviderSetting', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('PurgeServiceProviderSetting ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('PurgeServiceProviderSetting ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('PurgeServiceProviderSetting ERROR:', error)
					return Promise.reject(error)
				}
				console.log('PurgeServiceProviderSetting UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in PurgeServiceProviderSetting'))
			})
	},

	/**
	* UpdateServiceProvider: Update a ServiceProvider record
	* HTTP Method: PUT
	* API Path: /api/v3/service_provider/${req.UUID}
	* @param    {ServiceProvider} req The API payload object (ServiceProvider)
	* @return   {ServiceProvider} The API response object (ServiceProvider)
	*
	* @typedef  {Object}  ServiceProvider
	* @property {String}  UUID                    The ServiceProvider UUID to search/filter by
	* @property {Number}  SPID                    The Unique ID (int32)
	* @property {String}  Name                    Name
	* @property {Number}  CreatedAt               When the record was first created
	* @property {Number}  UpdatedAt               When the record was last updated
	* @property {Number}  DeletedAt               When the record was deleted
	* @property {StringSlice} Features                The Features enabled for this ServiceProvider
	* @property {String}  LogoWideURL             URL of the Wide Logo
	* @property {String}  LogoIconURL             URL of the Icon Logo
	* @property {String}  DefaultEmailFromName    Default Notification E-Mail From Name (optional)
	* @property {String}  DefaultEmailFromAddress Default Notification E-Mail From Address (required)
	* @property {String}  DefaultSMSFromName      Default SMS From Name (optional, not supported for replies)
	* @property {String}  DefaultSMSFromNumber    Default SMS From Number (optional)
	*
	*/
	UpdateServiceProvider({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Update)
		return axios.put(`/v3/service_provider/${req.UUID}`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveServiceProvider', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('UpdateServiceProvider ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('UpdateServiceProvider ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('UpdateServiceProvider ERROR:', error)
					return Promise.reject(error)
				}
				console.log('UpdateServiceProvider UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in UpdateServiceProvider'))
			})
	},

	/**
	* UpdateServiceProviderSetting: Update a ServiceProviderSetting record
	* HTTP Method: PUT
	* API Path: /api/v3/service_provider_setting/${req.UUID}
	* @param    {ServiceProviderSetting} req The API payload object (ServiceProviderSetting)
	* @return   {ServiceProviderSetting} The API response object (ServiceProviderSetting)
	*
	* @typedef  {Object}  ServiceProviderSetting
	* @property {String}  UUID      The ServiceProvider UUID to search/filter by
	* @property {Number}  SPID      The Unique ID (int32)
	* @property {String}  KeyGroup  Key group name
	* @property {String}  Key       Key name
	* @property {String}  Value     
	* @property {Number}  CreatedAt When the record was first created
	* @property {Number}  UpdatedAt When the record was last updated
	* @property {Number}  DeletedAt When the record was deleted
	*
	*/
	UpdateServiceProviderSetting({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Update)
		return axios.put(`/v3/service_provider_setting/${req.UUID}`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveServiceProviderSetting', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('UpdateServiceProviderSetting ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('UpdateServiceProviderSetting ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('UpdateServiceProviderSetting ERROR:', error)
					return Promise.reject(error)
				}
				console.log('UpdateServiceProviderSetting UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in UpdateServiceProviderSetting'))
			})
	},

	/**
	* UpdateServiceProviderSettings: Update multiple ServiceProviderSetting records
	* HTTP Method: PUT
	* API Path: /api/v3/service_provider_settings
	* @param    {ServiceProviderSettings} req The API payload object (ServiceProviderSettings)
	* @return   {ServiceProviderSettings} The API response object (ServiceProviderSettings)
	*
	* @typedef  {Object}  ServiceProviderSettings
	* @property {Number}  SPID                    Service Provider ID - Overrides the SPID in the ServiceProviderSetting objects
	* @property {ServiceProviderSetting[]} ServiceProviderSettings 
	* @property {String}  KeyGroup                KeyGroup (optional) If specified, overrides the KeyGroup in the ServiceProviderSetting objects
	*
	*/
	UpdateServiceProviderSettings({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.put(`/v3/service_provider_settings`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('UpdateServiceProviderSettings ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('UpdateServiceProviderSettings ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('UpdateServiceProviderSettings ERROR:', error)
					return Promise.reject(error)
				}
				console.log('UpdateServiceProviderSettings UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in UpdateServiceProviderSettings'))
			})
	},
};

export default {
	namespaced: true,
	state: initialState,
	getters,
	mutations,
	actions,
};