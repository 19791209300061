// Code generated by protoc-gen-iqwebapi. DO NOT EDIT.
// source: domain.proto

import axios from 'axios';
import StoreHash from '@/utils/StoreHash';

const initialState = {
	InternetDomainSearches: {},
};

const valFuncFind = function (status) {
	return (status == 200) ? true : (status == 404) ? true : false;
}

const default_pageinfo = {
	PageNumber: 0,
	PageItemCount: 0,
	TotalPages: 0,
	TotalItemCount: 0,
	IndexStart: 0,
	PageSize: 10,
	DisplayPageList: [],
	DisplayPageEitherSide: 4,
}

let hashed = {
	InternetDomains: {
		UUIDs: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
	InternetDomainSearches: {
		SearchTexts: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
}

const getters = {
	getInternetDomainByUUID: hashed => (UUID) => (hashed.InternetDomains.UUIDs[ UUID ] && !hashed.InternetDomains.UUIDs[ UUID ].expired()) ?  hashed.InternetDomains.UUIDs[ UUID ].hashedData() : null,
	getInternetDomainsForSearchText: state => (SearchText) => state.InternetDomainSearches[ SearchText ] ? state.InternetDomainSearches[ SearchText ] : null,
};

const mutations = {
	// mutSaveInternetDomain saves a single InternetDomain object
	// into both the StoreHash cache and the VueX store
	mutSaveInternetDomain(state, obj) {
		if (!obj.UUID) { return } // Must have UUID to Save

		if (!(obj.UUID in hashed.InternetDomains.UUIDs)) {
			hashed.InternetDomains.UUIDs[ obj.UUID ] = new StoreHash(hashed.InternetDomains.lifetime)
		}
		hashed.InternetDomains.UUIDs[ obj.UUID ].fix(obj) // Add item to StoreHash
	},
	// mutRemoveInternetDomainByUUID removes a InternetDomain object
	// with the given UUID from StoreHash cache and VueX store
	mutRemoveInternetDomainByUUID(state, UUID) {
		if (!UUID) {
			// Must have UUID to Remove
			return
		}
		delete hashed.InternetDomains.UUIDs[ UUID ]    // remove item from StoreHash
	},
	// mutSaveInternetDomainSearch saves a search response
	// with the given SearchText to the StoreHash cache and VueX store
	mutSaveInternetDomainSearch(state, { SearchText, Response }) {
		if (!SearchText || !Response) {
			// Must have SearchText to Save and Response object
			return
		}
		let storedObj = {
			PageInfo: Object.assign({}, default_pageinfo), // shallow copy of default_pageinfo
			UUIDs: [],
		}
		// Deep copy ??? i.e. JSON.parse(JSON.stringify(obj));
		if (Response.PageInfo) {
			Response.PageInfo = Object.assign({}, Response.PageInfo) // shallow copy of the PageInfo
		}
		if (Array.isArray(Response.InternetDomains)) {
			for (let i = 0; i < Response.InternetDomains.length; i++) {
				storedObj.UUIDs.push(Response.InternetDomains[i].UUID)
			}
		}
		state.InternetDomainSearches[ SearchText ] = storedObj      // Add search text and list of UUIDs to VueX state

		if (!(SearchText in hashed.InternetDomainSearches.SearchTexts)) {
			hashed.InternetDomainSearches.SearchTexts[ SearchText ] = new StoreHash(hashed.InternetDomainSearches.lifetime)
		}
		hashed.InternetDomainSearches.SearchTexts[ SearchText ].fix() // Add item to StoreHash
	},
	// mutRemoveInternetDomainSearch removes a search response
	// with the given SearchText from StoreHash cache and VueX store
	mutRemoveInternetDomainSearch(state, SearchText) {
		if (!SearchText) {
			// Must have SearchText to Remove
			return
		}
		state.InternetDomainSearches[ SearchText ] = null               // cause VueX to trigger a data cascade
		delete state.InternetDomainSearches[ SearchText ]               // remove item from VueX state
		delete hashed.InternetDomainSearches.SearchTexts[ SearchText ]  // remove item from StoreHash
	},
	// mutSaveInternetDomains saves a paginated response of InternetDomains
	mutSaveInternetDomains(state, { Response }) {
		if (!Response || !Array.isArray(Response.InternetDomains)) {
			// Must have Response object with results
			return
		}
		for (let i = 0; i < Response.InternetDomains.length; i++) {
			if (!(Response.InternetDomains[i].UUID in hashed.InternetDomains.UUIDs)) {
				hashed.InternetDomains.UUIDs[ Response.InternetDomains[i].UUID ] = new StoreHash(hashed.InternetDomains.lifetime)
			}
			hashed.InternetDomains.UUIDs[ Response.InternetDomains[i].UUID ].fix(Response.InternetDomains[i])  // Add single InternetDomain to StoreHash
		}
	},
};

const actions = {
	// getCachedInternetDomainByUUID fetches a InternetDomain from the cache
	// and if not in the cache, fetches it from the API
	getCachedInternetDomainByUUID({ getters, dispatch, commit }, { UUID, RefreshCache }) {
		if (!RefreshCache && hashed.InternetDomains.UUIDs[ UUID ] && !hashed.InternetDomains.UUIDs[ UUID ].expired()) {
			// Return the cached record
			return hashed.InternetDomains.UUIDs[ UUID ].hashedData()
		}
		// Fetch from the API
		return dispatch('GetInternetDomainByUUID', { UUID }).then(respPayload => {
			commit('mutSaveInternetDomain', respPayload)
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveInternetDomainByUUID', UUID)
			return Promise.reject(error)
		})
	},

	// getCachedInternetDomainsBySearch fetches InternetDomains from the cache
	// and if not in the cache, fetches it from the API
	getCachedInternetDomainsBySearch({ getters, dispatch, commit }, { SPID, SearchText, RefreshCache }) {
		if (!RefreshCache && hashed.InternetDomainSearches.SearchTexts[ SearchText ] && !hashed.InternetDomainSearches.SearchTexts[ SearchText ].expired()) {
			// Return the cached records
			let resp = getters.getInternetDomainsForSearchText(SearchText)
			resp.InternetDomains = []
			for (let i = 0; i < resp.UUIDs.length; i++) {
				resp.InternetDomains.push(getters.getInternetDomainByUUID(resp.UUIDs[i]))
			}
			return Promise.resolve(resp)
		}
		// Search using the API
		const apiReq = {
			SPID: SPID,
			SmartSearch: SearchText,
		}
		return dispatch('GetInternetDomainsPaginated', apiReq).then(respPayload => {
			commit('mutSaveInternetDomainSearch', { SearchText: SearchText, Response: respPayload })
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveInternetDomainSearch', SearchText)
			return Promise.reject(error)
		})
	},

	//
	// Service: TemplateRPC
	// 
	/**
	* AddInternetDomain: Add a InternetDomain object
	* HTTP Method: POST
	* API Path: /api/v3/internet_domain
	* @param    {InternetDomain} req The API payload object (InternetDomain)
	* @return   {InternetDomain} The API response object (InternetDomain)
	*
	* @typedef  {Object}  InternetDomain
	* @property {String}  UUID                         
	* @property {Number}  SPID                         Default Service Provider ID
	* @property {Number}  CreatedAtNanos               When the object was first created
	* @property {Number}  UpdatedAtNanos               When the object was last updated
	* @property {Number}  DeletedAtNanos               When the object was deleted
	* @property {String}  CustomerUUID                 CustomerUUID (optional)
	* @property {String}  DomainName                   DomainName
	* @property {String}  RegistrantFirstName          RegistrantFirstName contains the FirstName for the Registrant Contact
	* @property {String}  RegistrantLastName           RegistrantLastName contains the LastName for the Registrant Contact
	* @property {String}  RegistrantEmail              RegistrantEmail contains the Email for the Registrant Contact
	* @property {String}  RegistrantAddress1           RegistrantAddress1 contains the Address1 for the Registrant Contact
	* @property {String}  RegistrantAddress2           RegistrantAddress2 (optional) contains the Address2 for the Registrant Contact
	* @property {String}  RegistrantCity               RegistrantCity contains the City for the Registrant Contact
	* @property {String}  RegistrantPostalCode         RegistrantPostalCode contains the PostalCode for the Registrant Contact
	* @property {String}  RegistrantState              RegistrantState (optional) contains the State for the Registrant Contact
	* @property {String}  RegistrantCountryISO3166     RegistrantCountryISO3166 contains the CountryISO3166 for the Registrant Contact
	* @property {String}  TechnicalFirstName           TechnicalFirstName contains the FirstName for the Technical Contact
	* @property {String}  TechnicalLastName            TechnicalLastName contains the LastName for the Technical Contact
	* @property {String}  TechnicalEmail               TechnicalEmail contains the Email for the Technical Contact
	* @property {String}  TechnicalAddress1            TechnicalAddress1 contains the Address1 for the Technical Contact
	* @property {String}  TechnicalAddress2            TechnicalAddress2 (optionals) Address2contain the  for the Technical Contact
	* @property {String}  TechnicalCity                TechnicalCity contain thes  for Citythe Technical Contact
	* @property {String}  TechnicalPostalCode          TechnicalPostalCode contains the PostalCode for the Technical Contact
	* @property {String}  TechnicalState               TechnicalState (optionals) Statecontain the  for the Technical Contact
	* @property {String}  TechnicalCountryISO3166      TechnicalCountryISO3166 contains the CountryISO3166 for the Technical Contact
	* @property {String}  BillingFirstName             BillingFirstName contains the FirstName for the Billing Contact
	* @property {String}  BillingLastName              BillingLastName contains the LastName for the Billing Contact
	* @property {String}  BillingEmail                 BillingEmail contains the Email for the Billing Contact
	* @property {String}  BillingAddress1              BillingAddress1 contains the Address1 for the Billing Contact
	* @property {String}  BillingAddress2              BillingAddress2 (optional) contains the Address2 for the Billing Contact
	* @property {String}  BillingCity                  BillingCity contains the City for the Billing Contact
	* @property {String}  BillingPostalCode            BillingPostalCode contains the PostalCode for the Billing Contact
	* @property {String}  BillingState                 BillingState (optional) contains the State for the Billing Contact
	* @property {String}  BillingCountryISO3166        BillingCountryISO3166 contains the CountryISO3166 for the Billing Contact
	* @property {String}  NameServer1Name              NameServer1Name contains the primary nameserver name
	* @property {String}  NameServer1IP                NameServer1IP contains the primary nameserver name
	* @property {String}  NameServer2Name              NameServer2Name contains the secondary nameserver name
	* @property {String}  NameServer2IP                NameServer2IP contains the secondary nameserver name
	* @property {String}  NameServer3Name              NameServer3Name contains the 3rd nameserver name (optional)
	* @property {String}  NameServer3IP                NameServer3IP contains the 3rd nameserver name (optional)
	* @property {String}  NameServer4Name              NameServer4Name contains the 4th nameserver name (optional)
	* @property {String}  NameServer4IP                NameServer4IP contains the 4th nameserver name (optional)
	* @property {ENUM}    DomainNameRegistrationStatus DomainNameRegistrationStatus contains the registration status
	* @property {Boolean} CanRegister                  
	* @property {String}  NoRegisterReason             
	* @property {Boolean} CanTransfer                  
	* @property {String}  NoTransferReason             
	*
	*/
	AddInternetDomain({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Add)
		return axios.post(`/v3/internet_domain`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('AddInternetDomain ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('AddInternetDomain ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('AddInternetDomain ERROR:', error)
					return Promise.reject(error)
				}
				console.log('AddInternetDomain UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in AddInternetDomain'))
			})
	},

	/**
	* CheckInternetDomains: Check the registration/transfer status on Internet Domains
	* HTTP Method: POST
	* API Path: /api/v3/internet_domain_check
	* @param    {InternetDomainCheckRequest} req The API payload object (InternetDomainCheckRequest)
	* @return   {InternetDomainCheckResponse} The API response object (InternetDomainCheckResponse)
	*
	* @typedef  {Object}  InternetDomainCheckRequest
	* @property {String}  DomainNames 
	*
	* @typedef  {Object}  InternetDomainCheckResponse
	* @property {InternetDomain[]} InternetDomains 
	*
	*/
	CheckInternetDomains({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.post(`/v3/internet_domain_check`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('CheckInternetDomains ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('CheckInternetDomains ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('CheckInternetDomains ERROR:', error)
					return Promise.reject(error)
				}
				console.log('CheckInternetDomains UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in CheckInternetDomains'))
			})
	},

	/**
	* DeleteInternetDomainByUUID: Deletes a InternetDomain object. Requires JWT
	* HTTP Method: DELETE
	* API Path: /api/v3/internet_domain/${req.UUID}
	* @param    {InternetDomainUUIDSearch} req The API payload object (InternetDomainUUIDSearch)
	* @return   {InternetDomain} The API response object (InternetDomain)
	*
	* @typedef  {Object}  InternetDomainUUIDSearch
	* @property {String}  UUID                         
	* @property {Number}  SPID                         Service Provider ID - always required
	* @property {String}  CustomerUUID                 (optional) CustomerUUID
	* @property {String}  DomainName                   DomainName
	* @property {ENUM}    DomainNameRegistrationStatus DomainNameRegistrationStatus contains the registration status
	*
	* @typedef  {Object}  InternetDomain
	* @property {String}  UUID                         
	* @property {Number}  SPID                         Default Service Provider ID
	* @property {Number}  CreatedAtNanos               When the object was first created
	* @property {Number}  UpdatedAtNanos               When the object was last updated
	* @property {Number}  DeletedAtNanos               When the object was deleted
	* @property {String}  CustomerUUID                 CustomerUUID (optional)
	* @property {String}  DomainName                   DomainName
	* @property {String}  RegistrantFirstName          RegistrantFirstName contains the FirstName for the Registrant Contact
	* @property {String}  RegistrantLastName           RegistrantLastName contains the LastName for the Registrant Contact
	* @property {String}  RegistrantEmail              RegistrantEmail contains the Email for the Registrant Contact
	* @property {String}  RegistrantAddress1           RegistrantAddress1 contains the Address1 for the Registrant Contact
	* @property {String}  RegistrantAddress2           RegistrantAddress2 (optional) contains the Address2 for the Registrant Contact
	* @property {String}  RegistrantCity               RegistrantCity contains the City for the Registrant Contact
	* @property {String}  RegistrantPostalCode         RegistrantPostalCode contains the PostalCode for the Registrant Contact
	* @property {String}  RegistrantState              RegistrantState (optional) contains the State for the Registrant Contact
	* @property {String}  RegistrantCountryISO3166     RegistrantCountryISO3166 contains the CountryISO3166 for the Registrant Contact
	* @property {String}  TechnicalFirstName           TechnicalFirstName contains the FirstName for the Technical Contact
	* @property {String}  TechnicalLastName            TechnicalLastName contains the LastName for the Technical Contact
	* @property {String}  TechnicalEmail               TechnicalEmail contains the Email for the Technical Contact
	* @property {String}  TechnicalAddress1            TechnicalAddress1 contains the Address1 for the Technical Contact
	* @property {String}  TechnicalAddress2            TechnicalAddress2 (optionals) Address2contain the  for the Technical Contact
	* @property {String}  TechnicalCity                TechnicalCity contain thes  for Citythe Technical Contact
	* @property {String}  TechnicalPostalCode          TechnicalPostalCode contains the PostalCode for the Technical Contact
	* @property {String}  TechnicalState               TechnicalState (optionals) Statecontain the  for the Technical Contact
	* @property {String}  TechnicalCountryISO3166      TechnicalCountryISO3166 contains the CountryISO3166 for the Technical Contact
	* @property {String}  BillingFirstName             BillingFirstName contains the FirstName for the Billing Contact
	* @property {String}  BillingLastName              BillingLastName contains the LastName for the Billing Contact
	* @property {String}  BillingEmail                 BillingEmail contains the Email for the Billing Contact
	* @property {String}  BillingAddress1              BillingAddress1 contains the Address1 for the Billing Contact
	* @property {String}  BillingAddress2              BillingAddress2 (optional) contains the Address2 for the Billing Contact
	* @property {String}  BillingCity                  BillingCity contains the City for the Billing Contact
	* @property {String}  BillingPostalCode            BillingPostalCode contains the PostalCode for the Billing Contact
	* @property {String}  BillingState                 BillingState (optional) contains the State for the Billing Contact
	* @property {String}  BillingCountryISO3166        BillingCountryISO3166 contains the CountryISO3166 for the Billing Contact
	* @property {String}  NameServer1Name              NameServer1Name contains the primary nameserver name
	* @property {String}  NameServer1IP                NameServer1IP contains the primary nameserver name
	* @property {String}  NameServer2Name              NameServer2Name contains the secondary nameserver name
	* @property {String}  NameServer2IP                NameServer2IP contains the secondary nameserver name
	* @property {String}  NameServer3Name              NameServer3Name contains the 3rd nameserver name (optional)
	* @property {String}  NameServer3IP                NameServer3IP contains the 3rd nameserver name (optional)
	* @property {String}  NameServer4Name              NameServer4Name contains the 4th nameserver name (optional)
	* @property {String}  NameServer4IP                NameServer4IP contains the 4th nameserver name (optional)
	* @property {ENUM}    DomainNameRegistrationStatus DomainNameRegistrationStatus contains the registration status
	* @property {Boolean} CanRegister                  
	* @property {String}  NoRegisterReason             
	* @property {Boolean} CanTransfer                  
	* @property {String}  NoTransferReason             
	*
	*/
	DeleteInternetDomainByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Delete)
		return axios.delete(`/v3/internet_domain/${req.UUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveInternetDomain', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('DeleteInternetDomainByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('DeleteInternetDomainByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('DeleteInternetDomainByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('DeleteInternetDomainByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in DeleteInternetDomainByUUID'))
			})
	},

	/**
	* GetInternetDomainByUUID: Get a single InternetDomain object using the UUID
	* HTTP Method: GET
	* API Path: /api/v3/internet_domain/${req.UUID}
	* @param    {InternetDomainUUIDSearch} req The API payload object (InternetDomainUUIDSearch)
	* @return   {InternetDomain} The API response object (InternetDomain)
	*
	* @typedef  {Object}  InternetDomainUUIDSearch
	* @property {String}  UUID                         
	* @property {Number}  SPID                         Service Provider ID - always required
	* @property {String}  CustomerUUID                 (optional) CustomerUUID
	* @property {String}  DomainName                   DomainName
	* @property {ENUM}    DomainNameRegistrationStatus DomainNameRegistrationStatus contains the registration status
	*
	* @typedef  {Object}  InternetDomain
	* @property {String}  UUID                         
	* @property {Number}  SPID                         Default Service Provider ID
	* @property {Number}  CreatedAtNanos               When the object was first created
	* @property {Number}  UpdatedAtNanos               When the object was last updated
	* @property {Number}  DeletedAtNanos               When the object was deleted
	* @property {String}  CustomerUUID                 CustomerUUID (optional)
	* @property {String}  DomainName                   DomainName
	* @property {String}  RegistrantFirstName          RegistrantFirstName contains the FirstName for the Registrant Contact
	* @property {String}  RegistrantLastName           RegistrantLastName contains the LastName for the Registrant Contact
	* @property {String}  RegistrantEmail              RegistrantEmail contains the Email for the Registrant Contact
	* @property {String}  RegistrantAddress1           RegistrantAddress1 contains the Address1 for the Registrant Contact
	* @property {String}  RegistrantAddress2           RegistrantAddress2 (optional) contains the Address2 for the Registrant Contact
	* @property {String}  RegistrantCity               RegistrantCity contains the City for the Registrant Contact
	* @property {String}  RegistrantPostalCode         RegistrantPostalCode contains the PostalCode for the Registrant Contact
	* @property {String}  RegistrantState              RegistrantState (optional) contains the State for the Registrant Contact
	* @property {String}  RegistrantCountryISO3166     RegistrantCountryISO3166 contains the CountryISO3166 for the Registrant Contact
	* @property {String}  TechnicalFirstName           TechnicalFirstName contains the FirstName for the Technical Contact
	* @property {String}  TechnicalLastName            TechnicalLastName contains the LastName for the Technical Contact
	* @property {String}  TechnicalEmail               TechnicalEmail contains the Email for the Technical Contact
	* @property {String}  TechnicalAddress1            TechnicalAddress1 contains the Address1 for the Technical Contact
	* @property {String}  TechnicalAddress2            TechnicalAddress2 (optionals) Address2contain the  for the Technical Contact
	* @property {String}  TechnicalCity                TechnicalCity contain thes  for Citythe Technical Contact
	* @property {String}  TechnicalPostalCode          TechnicalPostalCode contains the PostalCode for the Technical Contact
	* @property {String}  TechnicalState               TechnicalState (optionals) Statecontain the  for the Technical Contact
	* @property {String}  TechnicalCountryISO3166      TechnicalCountryISO3166 contains the CountryISO3166 for the Technical Contact
	* @property {String}  BillingFirstName             BillingFirstName contains the FirstName for the Billing Contact
	* @property {String}  BillingLastName              BillingLastName contains the LastName for the Billing Contact
	* @property {String}  BillingEmail                 BillingEmail contains the Email for the Billing Contact
	* @property {String}  BillingAddress1              BillingAddress1 contains the Address1 for the Billing Contact
	* @property {String}  BillingAddress2              BillingAddress2 (optional) contains the Address2 for the Billing Contact
	* @property {String}  BillingCity                  BillingCity contains the City for the Billing Contact
	* @property {String}  BillingPostalCode            BillingPostalCode contains the PostalCode for the Billing Contact
	* @property {String}  BillingState                 BillingState (optional) contains the State for the Billing Contact
	* @property {String}  BillingCountryISO3166        BillingCountryISO3166 contains the CountryISO3166 for the Billing Contact
	* @property {String}  NameServer1Name              NameServer1Name contains the primary nameserver name
	* @property {String}  NameServer1IP                NameServer1IP contains the primary nameserver name
	* @property {String}  NameServer2Name              NameServer2Name contains the secondary nameserver name
	* @property {String}  NameServer2IP                NameServer2IP contains the secondary nameserver name
	* @property {String}  NameServer3Name              NameServer3Name contains the 3rd nameserver name (optional)
	* @property {String}  NameServer3IP                NameServer3IP contains the 3rd nameserver name (optional)
	* @property {String}  NameServer4Name              NameServer4Name contains the 4th nameserver name (optional)
	* @property {String}  NameServer4IP                NameServer4IP contains the 4th nameserver name (optional)
	* @property {ENUM}    DomainNameRegistrationStatus DomainNameRegistrationStatus contains the registration status
	* @property {Boolean} CanRegister                  
	* @property {String}  NoRegisterReason             
	* @property {Boolean} CanTransfer                  
	* @property {String}  NoTransferReason             
	*
	*/
	GetInternetDomainByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Get)
		return axios.get(`/v3/internet_domain/${req.UUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveInternetDomain', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetInternetDomainByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetInternetDomainByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetInternetDomainByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetInternetDomainByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetInternetDomainByUUID'))
			})
	},

	/**
	* GetInternetDomainsPaginated: Searches for multiple InternetDomain objects using a search request
	* HTTP Method: GET
	* API Path: /api/v3/internet_domain
	* @param    {InternetDomainSearchRequest} req The API payload object (InternetDomainSearchRequest)
	* @return   {InternetDomainPageResponse} The API response object (InternetDomainPageResponse)
	*
	* @typedef  {Object}  InternetDomainSearchRequest
	* @property {SearchOptions[]} SearchOptions                Search options to use (for sorting and/or pagination)
	* @property {Boolean} IncludeDeleted               IncludeDeleted field present on the search request ensures that deleted records (records with DeleteAtNanos > 0) are not returned unless this is set
	* @property {Number}  SPID                         (optional) SPID
	* @property {String}  CustomerUUID                 (optional) CustomerUUID
	* @property {String}  DomainName                   DomainName
	* @property {ENUM}    DomainNameRegistrationStatus DomainNameRegistrationStatus contains the registration status
	*
	* @typedef  {Object}  SearchOptions  Search options
	* @property {Number}  PageNumber     The page number to start at (or provide IndexStart)
	* @property {Number}  PageSize       The page size
	* @property {Number}  IndexStart     The record number to start at (or provide PageNumber)
	* @property {String}  SortBy         The field to sort by
	* @property {Boolean} SortDesc       Is the sort direction descending?
	*
	* @typedef  {Object}  InternetDomainPageResponse
	* @property {PageInfo} PageInfo        PageInfo only needs the values ThisPageNumber && PageSize set
	* @property {InternetDomain[]} InternetDomains 
	*
	* @typedef  {Object}   PageInfo
	* @property {Number}   PageNumber             The page number
	* @property {Number}   PageItemCount          Number of items on this page
	* @property {Number}   TotalPages             Total pages available
	* @property {Number}   TotalItemCount         Total items available
	* @property {Number}   IndexStart             The record number this search started at
	* @property {Number}   PageSize               The length of the page
	* @property {Number[]} DisplayPageList        The array of page numbers to display
	* @property {Number}   DisplayPagesEitherSide Number of pages to display either side
	*
	*/
	GetInternetDomainsPaginated({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Find)
		return axios.get(`/v3/internet_domain`, { params: req, validateStatus: valFuncFind }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			commit('mutSaveInternetDomains', { Response: response.data })
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetInternetDomainsPaginated ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetInternetDomainsPaginated ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetInternetDomainsPaginated ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetInternetDomainsPaginated UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetInternetDomainsPaginated'))
			})
	},

	/**
	* PurgeInternetDomainByUUID: Purges a InternetDomain object. Requires JWT
	* HTTP Method: DELETE
	* API Path: /api/v3/internet_domain/${req.UUID}/purge
	* @param    {InternetDomainUUIDSearch} req The API payload object (InternetDomainUUIDSearch)
	* @return   {InternetDomain} The API response object (InternetDomain)
	*
	* @typedef  {Object}  InternetDomainUUIDSearch
	* @property {String}  UUID                         
	* @property {Number}  SPID                         Service Provider ID - always required
	* @property {String}  CustomerUUID                 (optional) CustomerUUID
	* @property {String}  DomainName                   DomainName
	* @property {ENUM}    DomainNameRegistrationStatus DomainNameRegistrationStatus contains the registration status
	*
	* @typedef  {Object}  InternetDomain
	* @property {String}  UUID                         
	* @property {Number}  SPID                         Default Service Provider ID
	* @property {Number}  CreatedAtNanos               When the object was first created
	* @property {Number}  UpdatedAtNanos               When the object was last updated
	* @property {Number}  DeletedAtNanos               When the object was deleted
	* @property {String}  CustomerUUID                 CustomerUUID (optional)
	* @property {String}  DomainName                   DomainName
	* @property {String}  RegistrantFirstName          RegistrantFirstName contains the FirstName for the Registrant Contact
	* @property {String}  RegistrantLastName           RegistrantLastName contains the LastName for the Registrant Contact
	* @property {String}  RegistrantEmail              RegistrantEmail contains the Email for the Registrant Contact
	* @property {String}  RegistrantAddress1           RegistrantAddress1 contains the Address1 for the Registrant Contact
	* @property {String}  RegistrantAddress2           RegistrantAddress2 (optional) contains the Address2 for the Registrant Contact
	* @property {String}  RegistrantCity               RegistrantCity contains the City for the Registrant Contact
	* @property {String}  RegistrantPostalCode         RegistrantPostalCode contains the PostalCode for the Registrant Contact
	* @property {String}  RegistrantState              RegistrantState (optional) contains the State for the Registrant Contact
	* @property {String}  RegistrantCountryISO3166     RegistrantCountryISO3166 contains the CountryISO3166 for the Registrant Contact
	* @property {String}  TechnicalFirstName           TechnicalFirstName contains the FirstName for the Technical Contact
	* @property {String}  TechnicalLastName            TechnicalLastName contains the LastName for the Technical Contact
	* @property {String}  TechnicalEmail               TechnicalEmail contains the Email for the Technical Contact
	* @property {String}  TechnicalAddress1            TechnicalAddress1 contains the Address1 for the Technical Contact
	* @property {String}  TechnicalAddress2            TechnicalAddress2 (optionals) Address2contain the  for the Technical Contact
	* @property {String}  TechnicalCity                TechnicalCity contain thes  for Citythe Technical Contact
	* @property {String}  TechnicalPostalCode          TechnicalPostalCode contains the PostalCode for the Technical Contact
	* @property {String}  TechnicalState               TechnicalState (optionals) Statecontain the  for the Technical Contact
	* @property {String}  TechnicalCountryISO3166      TechnicalCountryISO3166 contains the CountryISO3166 for the Technical Contact
	* @property {String}  BillingFirstName             BillingFirstName contains the FirstName for the Billing Contact
	* @property {String}  BillingLastName              BillingLastName contains the LastName for the Billing Contact
	* @property {String}  BillingEmail                 BillingEmail contains the Email for the Billing Contact
	* @property {String}  BillingAddress1              BillingAddress1 contains the Address1 for the Billing Contact
	* @property {String}  BillingAddress2              BillingAddress2 (optional) contains the Address2 for the Billing Contact
	* @property {String}  BillingCity                  BillingCity contains the City for the Billing Contact
	* @property {String}  BillingPostalCode            BillingPostalCode contains the PostalCode for the Billing Contact
	* @property {String}  BillingState                 BillingState (optional) contains the State for the Billing Contact
	* @property {String}  BillingCountryISO3166        BillingCountryISO3166 contains the CountryISO3166 for the Billing Contact
	* @property {String}  NameServer1Name              NameServer1Name contains the primary nameserver name
	* @property {String}  NameServer1IP                NameServer1IP contains the primary nameserver name
	* @property {String}  NameServer2Name              NameServer2Name contains the secondary nameserver name
	* @property {String}  NameServer2IP                NameServer2IP contains the secondary nameserver name
	* @property {String}  NameServer3Name              NameServer3Name contains the 3rd nameserver name (optional)
	* @property {String}  NameServer3IP                NameServer3IP contains the 3rd nameserver name (optional)
	* @property {String}  NameServer4Name              NameServer4Name contains the 4th nameserver name (optional)
	* @property {String}  NameServer4IP                NameServer4IP contains the 4th nameserver name (optional)
	* @property {ENUM}    DomainNameRegistrationStatus DomainNameRegistrationStatus contains the registration status
	* @property {Boolean} CanRegister                  
	* @property {String}  NoRegisterReason             
	* @property {Boolean} CanTransfer                  
	* @property {String}  NoTransferReason             
	*
	*/
	PurgeInternetDomainByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Purge)
		return axios.delete(`/v3/internet_domain/${req.UUID}/purge`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveInternetDomain', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('PurgeInternetDomainByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('PurgeInternetDomainByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('PurgeInternetDomainByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('PurgeInternetDomainByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in PurgeInternetDomainByUUID'))
			})
	},

	/**
	* RegisterInternetDomains: Modify a InternetDomain object and return the result without saving.
	* HTTP Method: POST
	* API Path: /api/v3/internet_domain_register
	* @param    {InternetDomainRegisterRequest} req The API payload object (InternetDomainRegisterRequest)
	* @return   {InternetDomainRegisterResponse} The API response object (InternetDomainRegisterResponse)
	*
	* @typedef  {Object}  InternetDomainRegisterRequest
	* @property {InternetDomainSingleRequest[]} RegisterDomains 
	*
	* @typedef  {Object}  InternetDomainRegisterResponse
	* @property {InternetDomain[]} InternetDomains 
	*
	*/
	RegisterInternetDomains({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.post(`/v3/internet_domain_register`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('RegisterInternetDomains ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('RegisterInternetDomains ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('RegisterInternetDomains ERROR:', error)
					return Promise.reject(error)
				}
				console.log('RegisterInternetDomains UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in RegisterInternetDomains'))
			})
	},

	/**
	* TransferInternetDomains: Transfer an Internet Domain
	* HTTP Method: POST
	* API Path: /api/v3/internet_domain_transfer
	* @param    {InternetDomainTransferRequest} req The API payload object (InternetDomainTransferRequest)
	* @return   {InternetDomainTransferResponse} The API response object (InternetDomainTransferResponse)
	*
	* @typedef  {Object}  InternetDomainTransferRequest
	* @property {InternetDomainSingleRequest[]} TransferDomains 
	*
	* @typedef  {Object}  InternetDomainTransferResponse
	* @property {InternetDomain[]} InternetDomains 
	*
	*/
	TransferInternetDomains({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.post(`/v3/internet_domain_transfer`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('TransferInternetDomains ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('TransferInternetDomains ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('TransferInternetDomains ERROR:', error)
					return Promise.reject(error)
				}
				console.log('TransferInternetDomains UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in TransferInternetDomains'))
			})
	},

	/**
	* UpdateInternetDomain: Update a single InternetDomain object
	* HTTP Method: PUT
	* API Path: /api/v3/internet_domain/${req.UUID}
	* @param    {InternetDomain} req The API payload object (InternetDomain)
	* @return   {InternetDomain} The API response object (InternetDomain)
	*
	* @typedef  {Object}  InternetDomain
	* @property {String}  UUID                         
	* @property {Number}  SPID                         Default Service Provider ID
	* @property {Number}  CreatedAtNanos               When the object was first created
	* @property {Number}  UpdatedAtNanos               When the object was last updated
	* @property {Number}  DeletedAtNanos               When the object was deleted
	* @property {String}  CustomerUUID                 CustomerUUID (optional)
	* @property {String}  DomainName                   DomainName
	* @property {String}  RegistrantFirstName          RegistrantFirstName contains the FirstName for the Registrant Contact
	* @property {String}  RegistrantLastName           RegistrantLastName contains the LastName for the Registrant Contact
	* @property {String}  RegistrantEmail              RegistrantEmail contains the Email for the Registrant Contact
	* @property {String}  RegistrantAddress1           RegistrantAddress1 contains the Address1 for the Registrant Contact
	* @property {String}  RegistrantAddress2           RegistrantAddress2 (optional) contains the Address2 for the Registrant Contact
	* @property {String}  RegistrantCity               RegistrantCity contains the City for the Registrant Contact
	* @property {String}  RegistrantPostalCode         RegistrantPostalCode contains the PostalCode for the Registrant Contact
	* @property {String}  RegistrantState              RegistrantState (optional) contains the State for the Registrant Contact
	* @property {String}  RegistrantCountryISO3166     RegistrantCountryISO3166 contains the CountryISO3166 for the Registrant Contact
	* @property {String}  TechnicalFirstName           TechnicalFirstName contains the FirstName for the Technical Contact
	* @property {String}  TechnicalLastName            TechnicalLastName contains the LastName for the Technical Contact
	* @property {String}  TechnicalEmail               TechnicalEmail contains the Email for the Technical Contact
	* @property {String}  TechnicalAddress1            TechnicalAddress1 contains the Address1 for the Technical Contact
	* @property {String}  TechnicalAddress2            TechnicalAddress2 (optionals) Address2contain the  for the Technical Contact
	* @property {String}  TechnicalCity                TechnicalCity contain thes  for Citythe Technical Contact
	* @property {String}  TechnicalPostalCode          TechnicalPostalCode contains the PostalCode for the Technical Contact
	* @property {String}  TechnicalState               TechnicalState (optionals) Statecontain the  for the Technical Contact
	* @property {String}  TechnicalCountryISO3166      TechnicalCountryISO3166 contains the CountryISO3166 for the Technical Contact
	* @property {String}  BillingFirstName             BillingFirstName contains the FirstName for the Billing Contact
	* @property {String}  BillingLastName              BillingLastName contains the LastName for the Billing Contact
	* @property {String}  BillingEmail                 BillingEmail contains the Email for the Billing Contact
	* @property {String}  BillingAddress1              BillingAddress1 contains the Address1 for the Billing Contact
	* @property {String}  BillingAddress2              BillingAddress2 (optional) contains the Address2 for the Billing Contact
	* @property {String}  BillingCity                  BillingCity contains the City for the Billing Contact
	* @property {String}  BillingPostalCode            BillingPostalCode contains the PostalCode for the Billing Contact
	* @property {String}  BillingState                 BillingState (optional) contains the State for the Billing Contact
	* @property {String}  BillingCountryISO3166        BillingCountryISO3166 contains the CountryISO3166 for the Billing Contact
	* @property {String}  NameServer1Name              NameServer1Name contains the primary nameserver name
	* @property {String}  NameServer1IP                NameServer1IP contains the primary nameserver name
	* @property {String}  NameServer2Name              NameServer2Name contains the secondary nameserver name
	* @property {String}  NameServer2IP                NameServer2IP contains the secondary nameserver name
	* @property {String}  NameServer3Name              NameServer3Name contains the 3rd nameserver name (optional)
	* @property {String}  NameServer3IP                NameServer3IP contains the 3rd nameserver name (optional)
	* @property {String}  NameServer4Name              NameServer4Name contains the 4th nameserver name (optional)
	* @property {String}  NameServer4IP                NameServer4IP contains the 4th nameserver name (optional)
	* @property {ENUM}    DomainNameRegistrationStatus DomainNameRegistrationStatus contains the registration status
	* @property {Boolean} CanRegister                  
	* @property {String}  NoRegisterReason             
	* @property {Boolean} CanTransfer                  
	* @property {String}  NoTransferReason             
	*
	*/
	UpdateInternetDomain({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Update)
		return axios.put(`/v3/internet_domain/${req.UUID}`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveInternetDomain', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('UpdateInternetDomain ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('UpdateInternetDomain ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('UpdateInternetDomain ERROR:', error)
					return Promise.reject(error)
				}
				console.log('UpdateInternetDomain UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in UpdateInternetDomain'))
			})
	},
};

export default {
	namespaced: true,
	state: initialState,
	getters,
	mutations,
	actions,
};