<template>
    <transition-group name="fade" class="app-toast-list">
        <app-toast v-for="toast in toasts_list" :key="toast.uuid"
            :uuid="toast.uuid"
            :message="toast.message"
            :type="toast.type"
            :delay="toast.delay"

            @close="onClose"
        />
    </transition-group>
</template>

<script>
import { mapGetters } from 'vuex'

import appToast from './components/app-toast'

export default {
    components: {
        appToast,
    },

    data() {
        return {}
    },

    methods: {
        onClose(uuid) {
            this.$store.dispatch('removeToast', { uuid })
        },
    },

    computed: {
        ...mapGetters([
            'toasts_list',
        ]),
    },
}
</script>

<style lang="scss">
.app-toast-list {
    $padding: 32px;

    position: fixed;

    top: $padding;
    right: $padding;

    z-index: $z-index-app-toast-list;

    width: 100%;
    max-width: unquote('min(540px, 100% - #{ $padding * 2 })');

    .app-toast {
        margin-bottom: 16px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

@media (max-width: $tablet-size) {
    .app-toast-list {}
}

@media (max-width: $mobile-size) {
    .app-toast-list {
        $padding: 15px;

        top: $padding;
        right: $padding;

        max-width: unquote('min(540px, 100% - #{ $padding * 2 })');
    }
}
</style>