const PRODUCT_AVAILABILITY_CHARACTERISTICS_ENABLED = process.env.VUE_APP_PRODUCT_AVAILABILITY_CHARACTERISTICS_ENABLED
    ? isNaN(process.env.VUE_APP_PRODUCT_AVAILABILITY_CHARACTERISTICS_ENABLED)
        ? process.env.VUE_APP_PRODUCT_AVAILABILITY_CHARACTERISTICS_ENABLED.trim().toLowerCase() == 'true'
        : process.env.VUE_APP_PRODUCT_AVAILABILITY_CHARACTERISTICS_ENABLED > 0
    : false

const state = {}

const getters = {
    is_product_availability_characteristics_enabled: () => PRODUCT_AVAILABILITY_CHARACTERISTICS_ENABLED,
}

const mutations = {}

const actions = {}

export default {
    state,
    getters,
    mutations,
    actions,
}