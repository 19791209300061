import dynStorage from '@/utils/storage-dynamic'
import axios from 'axios'

import store from '@/store'
import router from '@/router'

const RESET_PASSWORD_REDIRECT_HOST = process.env.VUE_APP_RESET_PASSWORD_REDIRECT_HOST

const GOOGLE_AUTH_ENABLED = process.env.VUE_APP_GOOGLE_AUTH_ENABLED
    ? isNaN(process.env.VUE_APP_GOOGLE_AUTH_ENABLED)
        ? process.env.VUE_APP_GOOGLE_AUTH_ENABLED.trim().toLowerCase() == 'true'
        : process.env.VUE_APP_GOOGLE_AUTH_ENABLED > 0
    : false

const MFA_AUTH_TOGGLER_ENABLED = process.env.VUE_APP_MFA_AUTH_TOGGLER_ENABLED
    ? isNaN(process.env.VUE_APP_MFA_AUTH_TOGGLER_ENABLED)
        ? process.env.VUE_APP_MFA_AUTH_TOGGLER_ENABLED.trim().toLowerCase() == 'true'
        : process.env.VUE_APP_MFA_AUTH_TOGGLER_ENABLED > 0
    : false

const JWT_EXPIRY_DIALOG = process.env.VUE_APP_JWT_EXPIRY_DIALOG ? parseInt(process.env.VUE_APP_JWT_EXPIRY_DIALOG) || 0 : 0
const jwt_expiry_dialog = JWT_EXPIRY_DIALOG * 60 * 1000

let logout_timeout = null
let dialog_timeout = null

const state = {
    show_jwt_expiry_dialog: false,

    auth_data: null,
    this_users_sp_access: null,
    roles_by_customer: null,

    is_super_admin: false,
    is_prerelease_mode: false,

    current_spid: 0,

    current_customeruuid: "",
    available_service_providers: [],
}

const logout = () => {
    const jwt_expiry = dynStorage.get('jwt_expiry') * 1000

    const now = Date.now()

    const time_difference = now - jwt_expiry

    if (time_difference >= 0) {
        const resolve_redirect = () => {
            router.push({ name: 'sign-in' })
        }

        if (store.getters.show_jwt_expiry_dialog) {
            store.dispatch('closeJWTExpiryDialog').then(() => {}).catch(() => {})
        }

        store.dispatch('Logout').then(resolve_redirect).catch(resolve_redirect)
    } else {
        logout_timeout = setTimeout(logout, Math.abs(time_difference))
    }
}

const showJWTExpiryDialog = () => {
    const jwt_expiry = dynStorage.get('jwt_expiry') * 1000

    const now = Date.now()

    const time_difference = now - (jwt_expiry - jwt_expiry_dialog)

    if (time_difference >= 0) {
        store.dispatch('showJWTExpiryDialog').then(() => {}).catch(() => {})
    } else {
        dialog_timeout = setTimeout(showJWTExpiryDialog, Math.abs(time_difference))
    }
}

const getters = {
    reset_password_redirect_host: () => RESET_PASSWORD_REDIRECT_HOST,

    is_google_auth_enabled: () => GOOGLE_AUTH_ENABLED,
    is_mfa_auth_toggler_enabled: () => MFA_AUTH_TOGGLER_ENABLED,

    is_prerelease_mode: state => state.is_prerelease_mode,
    show_jwt_expiry_dialog: state => state.show_jwt_expiry_dialog,

    jwt: state => state.auth_data ? state.auth_data.JWT ? state.auth_data.JWT : "" : "",
    jwt_expiry: state => state.auth_data ? state.auth_data.JWTExpiry ? state.auth_data.JWTExpiry : 0 : 0,
    jwt_is_valid: state => state.auth_data ? state.auth_data.JWTExpiry ? (state.auth_data.JWTExpiry > Math.round(new Date().getTime() / 1000)) ? true : false : false : false,
    jwt_is_expired: state => state.auth_data ? state.auth_data.JWTExpiry ? (state.auth_data.JWTExpiry > Math.round(new Date().getTime() / 1000)) ? false : true : true : true,

    // Current identity information
    id_uuid: state => state.auth_data && state.auth_data.UUID ? state.auth_data.UUID : "",
    id_auth_id: state => state.auth_data && state.auth_data.AuthID ? state.auth_data.AuthID : "",
    id_first_name: state => state.auth_data && state.auth_data.FirstName ? state.auth_data.FirstName : "",
    id_last_name: state => state.auth_data && state.auth_data.LastName ? state.auth_data.LastName : "",
    id_email: state => state.auth_data ? state.auth_data.Email ? state.auth_data.Email : state.auth_data.AuthID : "",
    id_mobile: state => state.auth_data && state.auth_data.MobileNumber ? state.auth_data.MobileNumber : "",
    id_display_name: state => {
        return !state.auth_data ? "" : (state.auth_data.FirstName || state.auth_data.lastName) ? ((state.auth_data.FirstName ? state.auth_data.FirstName : "") + (state.auth_data.LastName ? " " + state.auth_data.LastName : "")) : state.auth_data.Email ? state.auth_data.Email : ""
    },
    is_mfa_used: state => state.auth_data && state.auth_data.UsedMFA,

    // Service Provider Roles
    available_service_providers: state => state.available_service_providers,
    current_sp: state => (state.current_spid && state.this_users_sp_access && state.this_users_sp_access.has(state.current_spid)) ? state.this_users_sp_access.get(state.current_spid) : null,
    current_sp_roles: state => (state.current_spid && state.this_users_sp_access && state.this_users_sp_access.has(state.current_spid) && state.this_users_sp_access.get(state.current_spid).Roles) ? state.this_users_sp_access.get(state.current_spid).Roles : [],
    sp_roles_by_spid: state => spid => state.this_users_sp_access && state.this_users_sp_access.has(spid) ? state.this_users_sp_access.get(spid).Roles : [],
    current_sp_has_role: getters => rolename => getters.current_sp_roles.indexOf(rolename.toUpperCase()) === -1 ? true : false,
    this_users_sp_access: state => state.this_users_sp_access,

    is_sp_admin: state => (state.current_spid && state.this_users_sp_access && state.this_users_sp_access.has(state.current_spid) && Array.isArray(state.this_users_sp_access.get(state.current_spid).Roles)) ? state.this_users_sp_access.get(state.current_spid).Roles.includes("SP_ADMIN") : false,
    is_super_admin: (state) => state.is_super_admin,

    // Customer Roles
    cust_roles_by_uuid: state => (uuid) => { return state.roles_by_customer && state.roles_by_customer.has(uuid) ? state.roles_by_customer.get(uuid) : [] },
    current_cust_roles: state => (state.current_customeruuid && state.roles_by_customer && state.roles_by_customer.has(state.current_customeruuid)) ? state.roles_by_customer.get(state.current_customeruuid) : [],
    current_cust_has_role: getters => rolename => getters.current_cust_roles.indexOf(rolename.toUpperCase()) === -1 ? true : false,

    // Application state controlled:
    current_spid: (state) => state.current_spid ? state.current_spid : 0,

    current_customeruuid: (state) => state.current_customeruuid ? state.current_customeruuid : '',
}

const mutations = {
    mut_prerelease_mode(state, onoff) {
        if (onoff) {
            state.is_prerelease_mode = true
        } else {
            state.is_prerelease_mode = false
        }
    },
    showJWTExpiryDialog(state) {
        state.show_jwt_expiry_dialog = true
    },
    closeJWTExpiryDialog(state) {
        state.show_jwt_expiry_dialog = false
    },

    mut_auth_data(state, newAuthData) {
        if (newAuthData &&
            newAuthData.JWT &&
            newAuthData.JWTExpiry &&
            (newAuthData.JWTExpiry > Math.round(new Date().getTime() / 1000))
        ) {
            const now = Date.now()

            state.auth_data = newAuthData

            state.jwt = newAuthData.JWT
            state.jwt_expiry = newAuthData.JWTExpiry

            dynStorage.set('jwt', state.jwt)
            dynStorage.set('jwt_expiry', state.jwt_expiry)

            if (logout_timeout !== null) {
                clearTimeout(logout_timeout)
            }
            if (dialog_timeout !== null) {
                clearTimeout(dialog_timeout)
            }

            const logout_timeout_delay = state.jwt_expiry * 1000 - now

            logout_timeout = setTimeout(logout, logout_timeout_delay)

            if (JWT_EXPIRY_DIALOG > 0) {
                const dialog_timeout_delay = state.jwt_expiry * 1000 - now - jwt_expiry_dialog

                dialog_timeout = setTimeout(showJWTExpiryDialog, dialog_timeout_delay)
            }
        } else {
            state.auth_data = null
            state.jwt = null
            state.jwt_expiry = 0
            dynStorage.remove('jwt')
            dynStorage.remove('jwt_expiry')
        }
    },

    // mut_auth_roles takes the API response, and creates 2 x Maps
    //  to enable faster/more efficient roles lookups
    //  1) this_users_sp_access - This map is of format map[spid] = { SPID: 0, Name: '', Roles: ["role1","role2","role3"] }
    //  2) roles_by_customer - This map is of format map[customer_uuid] = ["role1","role2","role3"]
    mut_auth_roles(state, newRoles) {
        let lastSPID = 0
        let lastCustomerUUID = ""
        if (!newRoles) {
            state.this_users_sp_access = null
            state.roles_by_customer = null
            return
        }
        state.this_users_sp_access = new Map()
        state.roles_by_customer = new Map()
        for (const roleNum in newRoles) {
            if (newRoles[roleNum].SPID && !newRoles[roleNum].CustomerUUID) {
                let mapkey = newRoles[roleNum].SPID
                let newrole = newRoles[roleNum].RoleName ? newRoles[roleNum].RoleName : newRoles[roleNum].RoleID
                if (newrole == 'SUPER_ADMIN') {
                    state.is_super_admin = true
                }
                let thissp = {
                    SPID: newRoles[roleNum].SPID,
                    Roles: [],
                }
                if (state.this_users_sp_access.has(mapkey)) {
                    thissp = state.this_users_sp_access.get(mapkey)
                }
                if (thissp.Roles.indexOf(newrole) === -1) {
                    thissp.Roles.push(newrole.toUpperCase())
                }
                state.this_users_sp_access.set(mapkey, thissp)
                lastSPID = mapkey
            }
            if (!newRoles[roleNum].SPID && newRoles[roleNum].CustomerUUID) {
                let mapkey = newRoles[roleNum].CustomerUUID
                let newrole = newRoles[roleNum].RoleName ? newRoles[roleNum].RoleName : newRoles[roleNum].RoleID
                let roles = []
                if (state.roles_by_customer.has(mapkey)) {
                    roles = state.roles_by_customer.get(mapkey)
                }
                if (roles.indexOf(newrole) === -1) {
                    roles.push(newrole.toUpperCase())
                }
                state.roles_by_customer.set(mapkey, roles)
                lastCustomerUUID = mapkey
            }
        }
        if (state.current_customeruuid == '' && state.roles_by_customer.size == 1) {
            // set default CustomerUUID, if its not already set, as we only have 1
            state.current_customeruuid = lastCustomerUUID
            dynStorage.set('current_customeruuid', lastCustomerUUID)
        }
    },

    // mut_default_spid sets the default SPID, if its valid and if one is not already set
    mut_default_spid(state, newDefaultSPID) {
        if (state.current_spid == 0) {
            if (state.is_super_admin || (state.this_users_sp_access && state.this_users_sp_access.has(newDefaultSPID))) {
                state.current_spid = newDefaultSPID
                dynStorage.set('current_spid', newDefaultSPID)
            } else {
                dynStorage.remove('current_spid')
            }
        }
    },

    // mut_set_current_spid sets the current SPID, if its valid
    mut_set_current_spid(state, newSPID) {
        if (state.is_super_admin || (state.this_users_sp_access && state.this_users_sp_access.has(newSPID))) {
            state.current_spid = newSPID
            dynStorage.set('current_spid', newSPID)
        } else {
            console.log('Unable to set Current SPID to:', newSPID, '(no roles)')

            // dynStorage.remove('current_spid')

            throw new Error(`Unable to set Current SPID to: ${ newSPID } (no roles)`)
        }
    },

    // mut_update_jwt is desgined to be called from the Axios handler. When the
    // axios middleware recieves the X-Auth-Token + X-Auth-Token-Expiry headers
    // in the HTTP(S) response, then the backend has auto-renewed the token,
    // and it should call this to update the locally cached JWT and JWTExpiry
    mut_update_jwt(state, { JWT, JWTExpiry }) {
        if (JWT &&
            JWTExpiry &&
            (JWTExpiry > Math.round(new Date().getTime() / 1000))
        ) {
            state.jwt = JWT
            state.jwt_expiry = JWTExpiry
            dynStorage.set('jwt', state.jwt)
            dynStorage.set('jwt_expiry', state.jwt_expiry)
        } else {
            state.auth_data = null
            state.jwt = null
            state.jwt_expiry = 0
            dynStorage.remove('jwt')
            dynStorage.remove('jwt_expiry')
        }
    },

    // mut_logout is designed to set the auth state back to the original
    // (logged out) state
    mut_logout(state) {
        if (logout_timeout !== null) {
            clearTimeout(logout_timeout)
        }
        if (dialog_timeout !== null) {
            clearTimeout(dialog_timeout)
        }

        dynStorage.remove('jwt')
        dynStorage.remove('jwt_expiry')

        /**
         * On the call with PM we spoke about
         * that we must keep "current SPID" after user`s logout
         */
        // dynStorage.remove('current_spid')

        dynStorage.remove('current_customeruuid')
        state.auth_data = null
        state.this_users_sp_access = null
        state.roles_by_customer = null

        state.current_spid = 0
        state.is_super_admin = false

        state.current_customeruuid = ""
    },

    mut_service_providers(state, { ServiceProviders }) {
        if (ServiceProviders) {
            state.available_service_providers = ServiceProviders
            let new_this_users_sp_access = new Map()

            for (const spNum in ServiceProviders) {
                if (!ServiceProviders[spNum].SPID) {
                    continue
                }
                const mapkey = ServiceProviders[spNum].SPID
                if (state.this_users_sp_access && state.this_users_sp_access.has(mapkey)) {
                    const thissp = state.this_users_sp_access.get(mapkey)
                    const newsp = {
                        ...ServiceProviders[spNum],
                        Roles: thissp.Roles,
                    }
                    new_this_users_sp_access.set(mapkey, newsp)
                } else {
                    if (state.is_super_admin) {
                        const newsp = {
                            ...ServiceProviders[spNum],
                            Roles: ["SUPER_ADMIN"],
                        }
                        new_this_users_sp_access.set(mapkey, newsp)
                    } else {
                        const newsp = {
                            ...ServiceProviders[spNum],
                            Roles: [],
                        }
                        new_this_users_sp_access.set(mapkey, newsp)
                        console.warn('WARNING: SPID', mapkey, 'is defined in ServiceProviders response, but not in roles')
                    }
                }
            }
            state.this_users_sp_access = new_this_users_sp_access
        } else {
            state.available_service_providers = []
        }
    },
}

const actions = {
    setUnsupportedFeatures({ commit }, { Enabled }) {
        commit('mut_prerelease_mode', Enabled)
        return Promise.resolve()
    },
    showJWTExpiryDialog({ commit }) {
        commit('showJWTExpiryDialog')

        return Promise.resolve()
    },
    closeJWTExpiryDialog({ commit }) {
        commit('closeJWTExpiryDialog')

        return Promise.resolve()
    },
    refreshJWT({ commit }) {
        const jwt = dynStorage.get('jwt')

        const SPID = +dynStorage.get('current_spid')

        const payload = {
            AuthCode: jwt,
            AuthID: 'jwt',
            IdentityProvider: 'JWT_REFRESH',
            IncludeRoles: true,
            SPID: SPID,
        }

        return axios.post(`/v2/${ payload.SPID }/auth_check`, payload)
            .then(response => {
                commit('mut_auth_data', response.data)
            })
            .catch(error => {
                return Promise.reject(error)
            })
    },


    /*
     * LoginEmailPassword is a wrapped for AuthCheck
     * It is intended to be used for LOCAL Email and Password authentication
    */
    LoginEmailPassword({ dispatch }, { login, pass }) {
        return dispatch('AuthCheck', {
            IdentityProvider: "LOCAL",
            AuthID: login,
            AuthCode: pass,
            IncludeRoles: true,
        })
    },

    RefreshServiceProviders({ dispatch, commit }) {
        return dispatch('api_serviceprovider/FindServiceProvidersPaginated', { 'SearchOptions.PageSize': 1000 }).then((spresponse) => {
            commit('mut_service_providers', spresponse)
            return Promise.resolve(spresponse)
        }).catch((sperr) => {
            console.log('RefreshServiceProviders FindServiceProvidersPaginated error', sperr)
            commit('mut_service_providers', [])
            return Promise.resolve([])
        })
    },

    /**
     * AuthCheck makes the API call to check authentication
     */
     AuthCheck({ dispatch, getters, commit }, payload) {
        const storedSPID = +dynStorage.get('current_spid')

        const params = {
            ...payload,

            SPID: payload.SPID || storedSPID || 1,

            IncludeRoles: true,
        }

        return axios.post(`/v2/${ params.SPID }/auth_check`, params, { refresh: false })
            .then(response => {
                if (!response.data) {
                    return Promise.reject(Error('No payload'))
                }

                commit('mut_auth_data', response.data)
                commit('mut_auth_roles', response.data.Roles)

                return dispatch('RefreshServiceProviders')
                    .then(() => {
                        switch (getters.available_service_providers.length) {
                            case 0:
                                dispatch('addToast', {
                                    message: 'You have not been assigned any roles. Please contact your system administrator',
                                    type: 'error',
                                    // delay: 5000,
                                })

                                return Promise.reject(Error('No assigned Service Provider(s)'))
                            case 1:
                                // This Identity only has access to 1 SPID - Use that SPID as the default SPID
                                console.log('Setting current SPID to only available: ', getters.available_service_providers[0].SPID)
                                commit('mut_default_spid', getters.available_service_providers[0].SPID)
                                break
                            default:
                                // This Identity has access to multiple SPIDs - Use the cookie/local storage value for default SPID
                                if (storedSPID) {
                                    // mut_default_spid checks to see if the SPID is in the list of available SPIDs
                                    commit('mut_default_spid', storedSPID)
                                }
                        }

                        return Promise.resolve(response)
                    })
                    .catch(error => {
                        console.log('RefreshServiceProviders error:', error)

                        commit('mut_service_providers', [])

                        return Promise.resolve(response)
                    })
            })
            .catch(error => {
                if (error) {
                    console.log('AuthCheck error:', error)

                    return Promise.reject(error)
                }

                return Promise.reject(Error('Unknown error in AuthCheck'))
            })
    },

    // AutoLogin is designed to be run when the application loads
    // to resume/recover the application authentication state
    // It will re-authenticate with the API to ensure the JWT is still valid
    AutoLogin({ dispatch, commit }) {
        const storedJWT = dynStorage.get('jwt')
        if (!storedJWT) {
            commit('mut_logout')
            // AutoLogin must always return a resolved Promise
            return Promise.resolve()
        }

        return dispatch('AuthCheck', {
            IdentityProvider: "JWT_REFRESH",
            AuthID: "jwt",
            AuthCode: storedJWT,
            IncludeRoles: true,
        }).then(() => {
            // AutoLogin must always return a resolved Promise
            return Promise.resolve()
        })
            .catch(() => {
                // AutoLogin must always return a resolved Promise
                return Promise.resolve()
            })
    },

    // Logout logs the user out, and removes all authentication state
    Logout({ dispatch, commit }) {
        commit('mut_logout')
        dispatch('ResetServiceProviderState')
    },

    // Resets all Service Provider state (eg: when changing service providers)
    ResetServiceProviderState({ commit }) {
        commit('resetHashedCustomers')

        commit('resetHashedTeams')
        commit('resetHashedMembers')

        commit('resetHashedBulk')

        commit('fwa/resetHashedFWA')
        commit('resetHashedFibre')
        commit('resetHashedMobile')

        commit('resetHashedRadiusServices')
        commit('resetHashedRadiusSessions')

        commit('resetHashedDigitalComms')

        commit('resetHashedProviders')
        commit('resetHashedBrandings')
    },

    // setJWT is called from axios middleware to update the local cache
    // of the JWT and JWT Expirty
    setJWT({ getters, commit }, { JWT, JWTExpiry }) {
        if (!JWT || JWT == "-" || JWTExpiry == -1) {
            commit('mut_logout')
            return
        }
        if (!JWTExpiry) {
            commit('mut_update_jwt', { JWT, JWTExpiry: getters.jwt_expiry })
            return
        }
        commit('mut_update_jwt', { JWT, JWTExpiry })
    },

    setCurrentSPID({ getters, commit }, newSPID) {
        try {
            commit('mut_set_current_spid', newSPID)
        } catch (error) {
            return Promise.reject(error)
        }

        /*
            if (getters.current_spid != newSPID) {
                console.log('setCurrentSPID WARNING: Unable to set SPID to', newSPID)
            }
        */
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
}