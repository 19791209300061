import Vue from 'vue'

const state = {
    bulk_sip_trunks: {},
}

const getters = {
    bulk_sip_trunks: state => state.bulk_sip_trunks,
}

const mutations = {
    saveBulkSIPTrunk(state, sip_trunk) {
        Vue.set(state.bulk_sip_trunks, sip_trunk.UUID, sip_trunk)
    },

    saveBulkSIPTrunksByUUIDs(state, { sip_trunks, bulk_uuids }) {
        for (const uuid in state.bulk_sip_trunks) {
            if (!bulk_uuids[uuid]) {
                Vue.delete(state.bulk_sip_trunks, uuid)
            }
        }
        
        for (let i = 0, len = sip_trunks.length; i < len; i++) {
            if (bulk_uuids[ sip_trunks[i].UUID ]) {
                Vue.set(state.bulk_sip_trunks, sip_trunks[i].UUID, sip_trunks[i])
            }
        }
    },

    clearBulkSIPTrunks(state) {
        state.bulk_sip_trunks = {}
    },
}

const actions = {
    loadBulkSIPTrunksByUUIDs({getters, commit, dispatch}, bulk_uuids) {
        const requests = []

        const current_spid = getters.current_spid
        const bulk_sip_trunks = getters.bulk_sip_trunks

        for (let i = 0, len = bulk_uuids.length; i < len; i++) {
            const uuid = bulk_uuids[i]

            if (!(uuid in bulk_sip_trunks)) {
                requests.push(
                    dispatch('api_siptrunk/GetSIPTrunkByUUID', { SPID: current_spid, UUID: uuid }).then(sip_trunk => {
                        commit('saveBulkSIPTrunk', sip_trunk)
                    })
                )
            }
        }

        return Promise.allSettled(requests)
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
}