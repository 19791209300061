// Code generated by protoc-gen-iqwebapi. DO NOT EDIT.
// source: service_instance.proto

import axios from 'axios';
import StoreHash from '@/utils/StoreHash';

const initialState = {
	ServiceInstances: {
		UUIDs: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
	ServiceInstanceCharacteristics: {
		UUIDs: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
	ServiceInstanceStates: {
		UUIDs: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
};

const valFuncFind = function (status) {
	return (status == 200) ? true : (status == 404) ? true : false;
}

const default_pageinfo = {
	PageNumber: 0,
	PageItemCount: 0,
	TotalPages: 0,
	TotalItemCount: 0,
	IndexStart: 0,
	PageSize: 10,
	DisplayPageList: [],
	DisplayPageEitherSide: 4,
}

const getters = {
	getServiceInstanceByUUID: state => (UUID) => (state.ServiceInstances.UUIDs[ UUID ] && !state.ServiceInstances.UUIDs[ UUID ].expired()) ?  state.ServiceInstances.UUIDs[ UUID ].hashedData() : null,
	getServiceInstanceCharacteristicByUUID: state => (UUID) => (state.ServiceInstanceCharacteristics.UUIDs[ UUID ] && !state.ServiceInstanceCharacteristics.UUIDs[ UUID ].expired()) ?  state.ServiceInstanceCharacteristics.UUIDs[ UUID ].hashedData() : null,
	getServiceInstanceStateByUUID: state => (UUID) => (state.ServiceInstanceStates.UUIDs[ UUID ] && !state.ServiceInstanceStates.UUIDs[ UUID ].expired()) ?  state.ServiceInstanceStates.UUIDs[ UUID ].hashedData() : null,
};

const mutations = {
	// mutSaveServiceInstance saves a single ServiceInstance object
	// into both the StoreHash cache and the VueX store
	mutSaveServiceInstance(state, obj) {
		if (!obj.UUID) { return } // Must have UUID to Save

		if (!(obj.UUID in state.ServiceInstances.UUIDs)) {
			state.ServiceInstances.UUIDs[ obj.UUID ] = new StoreHash(state.ServiceInstances.lifetime)
		}
		state.ServiceInstances.UUIDs[ obj.UUID ].fix(obj) // Add item to StoreHash
	},
	// mutRemoveServiceInstanceByUUID removes a ServiceInstance object
	// with the given UUID from StoreHash cache and VueX store
	mutRemoveServiceInstanceByUUID(state, UUID) {
		if (!UUID) {
			// Must have UUID to Remove
			return
		}
		delete state.ServiceInstances.UUIDs[ UUID ]    // remove item from StoreHash
	},
	// mutSaveServiceInstances saves a paginated response of ServiceInstances
	mutSaveServiceInstances(state, { Response }) {
		if (!Response || !Array.isArray(Response.ServiceInstances)) {
			// Must have Response object with results
			return
		}
		for (let i = 0; i < Response.ServiceInstances.length; i++) {
			if (!(Response.ServiceInstances[i].UUID in state.ServiceInstances.UUIDs)) {
				state.ServiceInstances.UUIDs[ Response.ServiceInstances[i].UUID ] = new StoreHash(state.ServiceInstances.lifetime)
			}
			state.ServiceInstances.UUIDs[ Response.ServiceInstances[i].UUID ].fix(Response.ServiceInstances[i])  // Add single ServiceInstance to StoreHash
		}
	},
	// mutSaveServiceInstanceCharacteristic saves a single ServiceInstanceCharacteristic object
	// into both the StoreHash cache and the VueX store
	mutSaveServiceInstanceCharacteristic(state, obj) {
		if (!obj.UUID) { return } // Must have UUID to Save

		if (!(obj.UUID in state.ServiceInstanceCharacteristics.UUIDs)) {
			state.ServiceInstanceCharacteristics.UUIDs[ obj.UUID ] = new StoreHash(state.ServiceInstanceCharacteristics.lifetime)
		}
		state.ServiceInstanceCharacteristics.UUIDs[ obj.UUID ].fix(obj) // Add item to StoreHash
	},
	// mutRemoveServiceInstanceCharacteristicByUUID removes a ServiceInstanceCharacteristic object
	// with the given UUID from StoreHash cache and VueX store
	mutRemoveServiceInstanceCharacteristicByUUID(state, UUID) {
		if (!UUID) {
			// Must have UUID to Remove
			return
		}
		delete state.ServiceInstanceCharacteristics.UUIDs[ UUID ]    // remove item from StoreHash
	},
	// mutSaveServiceInstanceCharacteristics saves a paginated response of ServiceInstanceCharacteristics
	mutSaveServiceInstanceCharacteristics(state, { Response }) {
		if (!Response || !Array.isArray(Response.ServiceInstanceCharacteristics)) {
			// Must have Response object with results
			return
		}
		for (let i = 0; i < Response.ServiceInstanceCharacteristics.length; i++) {
			if (!(Response.ServiceInstanceCharacteristics[i].UUID in state.ServiceInstanceCharacteristics.UUIDs)) {
				state.ServiceInstanceCharacteristics.UUIDs[ Response.ServiceInstanceCharacteristics[i].UUID ] = new StoreHash(state.ServiceInstanceCharacteristics.lifetime)
			}
			state.ServiceInstanceCharacteristics.UUIDs[ Response.ServiceInstanceCharacteristics[i].UUID ].fix(Response.ServiceInstanceCharacteristics[i])  // Add single ServiceInstanceCharacteristic to StoreHash
		}
	},
	// mutSaveServiceInstanceState saves a single ServiceInstanceState object
	// into both the StoreHash cache and the VueX store
	mutSaveServiceInstanceState(state, obj) {
		if (!obj.UUID) { return } // Must have UUID to Save

		if (!(obj.UUID in state.ServiceInstanceStates.UUIDs)) {
			state.ServiceInstanceStates.UUIDs[ obj.UUID ] = new StoreHash(state.ServiceInstanceStates.lifetime)
		}
		state.ServiceInstanceStates.UUIDs[ obj.UUID ].fix(obj) // Add item to StoreHash
	},
	// mutRemoveServiceInstanceStateByUUID removes a ServiceInstanceState object
	// with the given UUID from StoreHash cache and VueX store
	mutRemoveServiceInstanceStateByUUID(state, UUID) {
		if (!UUID) {
			// Must have UUID to Remove
			return
		}
		delete state.ServiceInstanceStates.UUIDs[ UUID ]    // remove item from StoreHash
	},
};

const actions = {
	// getCachedServiceInstanceByUUID fetches a ServiceInstance from the cache
	// and if not in the cache, fetches it from the API
	getCachedServiceInstanceByUUID({ state, dispatch, commit }, { UUID, RefreshCache }) {
		if (!RefreshCache && state.ServiceInstances.UUIDs[ UUID ] && !state.ServiceInstances.UUIDs[ UUID ].expired()) {
			// Return the cached record
			return state.ServiceInstances.UUIDs[ UUID ].hashedData()
		}
		// Fetch from the API
		return dispatch('GetServiceInstanceByUUID', { UUID }).then(respPayload => {
			commit('mutSaveServiceInstance', respPayload)
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveServiceInstanceByUUID', UUID)
			return Promise.reject(error)
		})
	},

	// getCachedServiceInstanceCharacteristicByUUID fetches a ServiceInstanceCharacteristic from the cache
	// and if not in the cache, fetches it from the API
	getCachedServiceInstanceCharacteristicByUUID({ state, dispatch, commit }, { UUID, RefreshCache }) {
		if (!RefreshCache && state.ServiceInstanceCharacteristics.UUIDs[ UUID ] && !state.ServiceInstanceCharacteristics.UUIDs[ UUID ].expired()) {
			// Return the cached record
			return state.ServiceInstanceCharacteristics.UUIDs[ UUID ].hashedData()
		}
		// Fetch from the API
		return dispatch('GetServiceInstanceCharacteristicByUUID', { UUID }).then(respPayload => {
			commit('mutSaveServiceInstanceCharacteristic', respPayload)
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveServiceInstanceCharacteristicByUUID', UUID)
			return Promise.reject(error)
		})
	},

	// getCachedServiceInstanceStateByUUID fetches a ServiceInstanceState from the cache
	// and if not in the cache, fetches it from the API
	getCachedServiceInstanceStateByUUID({ state, dispatch, commit }, { UUID, RefreshCache }) {
		if (!RefreshCache && state.ServiceInstanceStates.UUIDs[ UUID ] && !state.ServiceInstanceStates.UUIDs[ UUID ].expired()) {
			// Return the cached record
			return state.ServiceInstanceStates.UUIDs[ UUID ].hashedData()
		}
		// Fetch from the API
		return dispatch('GetServiceInstanceStateByUUID', { UUID }).then(respPayload => {
			commit('mutSaveServiceInstanceState', respPayload)
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveServiceInstanceStateByUUID', UUID)
			return Promise.reject(error)
		})
	},

	//
	// Service: ServiceInstanceRPC
	// 
	/**
	* RPC Method: AddServiceInstance
	* Description: Create a new service instance
	* HTTP Method: POST
	* API Path: /api/v1/${req.SPID}/service_instance
	* @param    {ServiceInstance} req The API payload object (ServiceInstance)
	* @return   {ServiceInstance} The API response object (ServiceInstance)
	*
	* @typedef  {Object}  ServiceInstance
	* @property {String}  UUID                Unique ID for this service instance
	* @property {Number}  SPID                SPID that this instance belongs to
	* @property {String}  ServiceCatalogUUID  Service catalog entry this instance was cloned from
	* @property {String}  ServiceType         The type of service, ie Mobility, Fixed, TV...
	* @property {Number}  CreatedAtNanos      Unixnano when this product was created
	* @property {Number}  UpdatedAtNanos      Unixnano when this product was updated
	* @property {Number}  DeletedAtNanos      Unixnano when this product was deleted
	* @property {String}  CustomerUUID        Internal Customer UUID (if TKT is the CRM for the customer)
	* @property {String}  ExternalRef         External system reference (this is the product instance UUID if TKT is the CRM)
	* @property {String}  ServiceOrderUUID    The service instance state that was replaced by this one
	* @property {String}  ProductOrderUUID    The product instance UUID related to this service instance
	* @property {String}  ProductInstanceUUID 
	*
	*/
	AddServiceInstance({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Add)
		return axios.post(`/v1/${req.SPID}/service_instance`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('AddServiceInstance ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('AddServiceInstance ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('AddServiceInstance ERROR:', error)
					return Promise.reject(error)
				}
				console.log('AddServiceInstance UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in AddServiceInstance'))
			})
	},

	/**
	* RPC Method: DeleteServiceInstance
	* Description: Create a new service instance
	* HTTP Method: DELETE
	* API Path: /api/v1/${req.SPID}/service_instance
	* @param    {DeleteServiceInstanceRequest} req The API payload object (DeleteServiceInstanceRequest)
	* @return   {ServiceInstance} The API response object (ServiceInstance)
	*
	* @typedef  {Object}  DeleteServiceInstanceRequest
	* @property {Number}  SPID The SPID
	* @property {String}  UUID The UUID of the service instance
	*
	* @typedef  {Object}  ServiceInstance
	* @property {String}  UUID                Unique ID for this service instance
	* @property {Number}  SPID                SPID that this instance belongs to
	* @property {String}  ServiceCatalogUUID  Service catalog entry this instance was cloned from
	* @property {String}  ServiceType         The type of service, ie Mobility, Fixed, TV...
	* @property {Number}  CreatedAtNanos      Unixnano when this product was created
	* @property {Number}  UpdatedAtNanos      Unixnano when this product was updated
	* @property {Number}  DeletedAtNanos      Unixnano when this product was deleted
	* @property {String}  CustomerUUID        Internal Customer UUID (if TKT is the CRM for the customer)
	* @property {String}  ExternalRef         External system reference (this is the product instance UUID if TKT is the CRM)
	* @property {String}  ServiceOrderUUID    The service instance state that was replaced by this one
	* @property {String}  ProductOrderUUID    The product instance UUID related to this service instance
	* @property {String}  ProductInstanceUUID 
	*
	*/
	DeleteServiceInstance({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Delete)
		return axios.delete(`/v1/${req.SPID}/service_instance`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveServiceInstance', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('DeleteServiceInstance ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('DeleteServiceInstance ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('DeleteServiceInstance ERROR:', error)
					return Promise.reject(error)
				}
				console.log('DeleteServiceInstance UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in DeleteServiceInstance'))
			})
	},

	/**
	* RPC Method: FindServiceInstanceCharacteristicsPaginated
	* Description: Search through service characteristics
	* HTTP Method: GET
	* API Path: /api/v1/service_instance_characteristic
	* @param    {ServiceInstanceCharacteristicSearchRequest} req The API payload object (ServiceInstanceCharacteristicSearchRequest)
	* @return   {ServiceInstanceCharacteristicSearchResponse} The API response object (ServiceInstanceCharacteristicSearchResponse)
	*
	* @typedef  {Object}  ServiceInstanceCharacteristicSearchRequest
	* @property {SearchOptions[]} SearchOptions       Standard search options
	* @property {Number}  SPID                The service provider
	* @property {String}  ServiceInstanceUUID The service instance that this characteristic belongs to
	* @property {String}  CustomerUUID        Internal Customer UUID (if TKT is the CRM for the customer)
	* @property {String}  Name                Name of this characteristic
	* @property {String}  Value               Value of this characteristic
	* @property {String}  DefaultValue        Default value of this characteristic
	* @property {Boolean} Required            Is this characteristic required (ie, no default)
	* @property {String}  ValueType           The type of this characteristic, string, int, imsi, ipv4_address, ipv6_address, etc
	* @property {String}  ValidationRules     The validation rules for this type
	*
	* @typedef  {Object}  SearchOptions  Search options
	* @property {Number}  PageNumber     The page number to start at (or provide IndexStart)
	* @property {Number}  PageSize       The page size
	* @property {Number}  IndexStart     The record number to start at (or provide PageNumber)
	* @property {String}  SortBy         The field to sort by
	* @property {Boolean} SortDesc       Is the sort direction descending?
	*
	* @typedef  {Object}  ServiceInstanceCharacteristicSearchResponse
	* @property {PageInfo} PageInfo                       PageInfo only needs the values ThisPageNumber && PageSize set
	* @property {ServiceInstanceCharacteristic[]} ServiceInstanceCharacteristics Repeated list of product instances
	*
	* @typedef  {Object}   PageInfo
	* @property {Number}   PageNumber             The page number
	* @property {Number}   PageItemCount          Number of items on this page
	* @property {Number}   TotalPages             Total pages available
	* @property {Number}   TotalItemCount         Total items available
	* @property {Number}   IndexStart             The record number this search started at
	* @property {Number}   PageSize               The length of the page
	* @property {Number[]} DisplayPageList        The array of page numbers to display
	* @property {Number}   DisplayPagesEitherSide Number of pages to display either side
	*
	*/
	FindServiceInstanceCharacteristicsPaginated({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Find)
		return axios.get(`/v1/service_instance_characteristic`, { params: req, validateStatus: valFuncFind }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			commit('mutSaveServiceInstanceCharacteristics', { Response: response.data })
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('FindServiceInstanceCharacteristicsPaginated ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('FindServiceInstanceCharacteristicsPaginated ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('FindServiceInstanceCharacteristicsPaginated ERROR:', error)
					return Promise.reject(error)
				}
				console.log('FindServiceInstanceCharacteristicsPaginated UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in FindServiceInstanceCharacteristicsPaginated'))
			})
	},

	/**
	* RPC Method: FindServiceInstancesPaginated
	* Description: Search through services
	* HTTP Method: GET
	* API Path: /api/v1/${req.SPID}/service_instance
	* @param    {ServiceInstanceSearchRequest} req The API payload object (ServiceInstanceSearchRequest)
	* @return   {ServiceInstanceSearchResponse} The API response object (ServiceInstanceSearchResponse)
	*
	* @typedef  {Object}  ServiceInstanceSearchRequest
	* @property {SearchOptions[]} SearchOptions       Standard search options
	* @property {String}  UUID                UUID of the instance, use get service by uuid instead
	* @property {String}  ServiceType         The service type of the instance: mobility
	* @property {ServiceInstanceState} State               The state of the service instance
	* @property {Number}  SPID                The SPID
	* @property {String}  ProductInstanceUUID The product instance UUID related to this service instance
	* @property {String}  SmartSearch         Search using smart search
	* @property {String}  ExternalRef         The external ref of the service instance
	* @property {String}  CustomerUUID        The customer uuid of this service
	*
	* @typedef  {Object}  SearchOptions  Search options
	* @property {Number}  PageNumber     The page number to start at (or provide IndexStart)
	* @property {Number}  PageSize       The page size
	* @property {Number}  IndexStart     The record number to start at (or provide PageNumber)
	* @property {String}  SortBy         The field to sort by
	* @property {Boolean} SortDesc       Is the sort direction descending?
	*
	* @typedef  {Object}  ServiceInstanceSearchResponse
	* @property {PageInfo} PageInfo         PageInfo only needs the values ThisPageNumber && PageSize set
	* @property {ServiceInstance[]} ServiceInstances A list of service instances
	*
	* @typedef  {Object}   PageInfo
	* @property {Number}   PageNumber             The page number
	* @property {Number}   PageItemCount          Number of items on this page
	* @property {Number}   TotalPages             Total pages available
	* @property {Number}   TotalItemCount         Total items available
	* @property {Number}   IndexStart             The record number this search started at
	* @property {Number}   PageSize               The length of the page
	* @property {Number[]} DisplayPageList        The array of page numbers to display
	* @property {Number}   DisplayPagesEitherSide Number of pages to display either side
	*
	*/
	FindServiceInstancesPaginated({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Find)
		// Prepare URI params...
		let paramSPID = encodeURIComponent(req.SPID)
		return axios.get(`/v1/${paramSPID}/service_instance`, { params: req, validateStatus: valFuncFind }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			commit('mutSaveServiceInstances', { Response: response.data })
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('FindServiceInstancesPaginated ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('FindServiceInstancesPaginated ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('FindServiceInstancesPaginated ERROR:', error)
					return Promise.reject(error)
				}
				console.log('FindServiceInstancesPaginated UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in FindServiceInstancesPaginated'))
			})
	},

	/**
	* RPC Method: GetServiceInstanceByServiceTypeAndProductInstanceUUID
	* Description: Search through services
	* HTTP Method: GET
	* API Path: /api/v1/${req.SPID}/service_instance/by_service_type/${req.ProductInstanceUUID}
	* @param    {ServiceTypeAndProductInstanceUUIDSearch} req The API payload object (ServiceTypeAndProductInstanceUUIDSearch)
	* @return   {ServiceInstance} The API response object (ServiceInstance)
	*
	* @typedef  {Object}  ServiceTypeAndProductInstanceUUIDSearch
	* @property {Number}  SPID                The SPID
	* @property {String}  ProductInstanceUUID The UUID of the product instance
	* @property {String}  ServiceType         The ServiceType
	* @property {String}  CustomerUUID        The customer uuid of the service instance
	*
	* @typedef  {Object}  ServiceInstance
	* @property {String}  UUID                Unique ID for this service instance
	* @property {Number}  SPID                SPID that this instance belongs to
	* @property {String}  ServiceCatalogUUID  Service catalog entry this instance was cloned from
	* @property {String}  ServiceType         The type of service, ie Mobility, Fixed, TV...
	* @property {Number}  CreatedAtNanos      Unixnano when this product was created
	* @property {Number}  UpdatedAtNanos      Unixnano when this product was updated
	* @property {Number}  DeletedAtNanos      Unixnano when this product was deleted
	* @property {String}  CustomerUUID        Internal Customer UUID (if TKT is the CRM for the customer)
	* @property {String}  ExternalRef         External system reference (this is the product instance UUID if TKT is the CRM)
	* @property {String}  ServiceOrderUUID    The service instance state that was replaced by this one
	* @property {String}  ProductOrderUUID    The product instance UUID related to this service instance
	* @property {String}  ProductInstanceUUID 
	*
	*/
	GetServiceInstanceByServiceTypeAndProductInstanceUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		// Prepare URI params...
		let paramSPID = encodeURIComponent(req.SPID)
		let paramProductInstanceUUID = encodeURIComponent(req.ProductInstanceUUID)
		return axios.get(`/v1/${paramSPID}/service_instance/by_service_type/${paramProductInstanceUUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetServiceInstanceByServiceTypeAndProductInstanceUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetServiceInstanceByServiceTypeAndProductInstanceUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetServiceInstanceByServiceTypeAndProductInstanceUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetServiceInstanceByServiceTypeAndProductInstanceUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetServiceInstanceByServiceTypeAndProductInstanceUUID'))
			})
	},

	/**
	* RPC Method: GetServiceInstanceByUUID
	* Description: Search through services
	* HTTP Method: GET
	* API Path: /api/v1/${req.SPID}/service_instance/${req.UUID}
	* @param    {ServiceInstanceUUIDSearch} req The API payload object (ServiceInstanceUUIDSearch)
	* @return   {ServiceInstance} The API response object (ServiceInstance)
	*
	* @typedef  {Object}  ServiceInstanceUUIDSearch
	* @property {Number}  SPID         The SPID
	* @property {String}  UUID         The UUID of the service instance
	* @property {String}  CustomerUUID The customer uuid of the service instance
	*
	* @typedef  {Object}  ServiceInstance
	* @property {String}  UUID                Unique ID for this service instance
	* @property {Number}  SPID                SPID that this instance belongs to
	* @property {String}  ServiceCatalogUUID  Service catalog entry this instance was cloned from
	* @property {String}  ServiceType         The type of service, ie Mobility, Fixed, TV...
	* @property {Number}  CreatedAtNanos      Unixnano when this product was created
	* @property {Number}  UpdatedAtNanos      Unixnano when this product was updated
	* @property {Number}  DeletedAtNanos      Unixnano when this product was deleted
	* @property {String}  CustomerUUID        Internal Customer UUID (if TKT is the CRM for the customer)
	* @property {String}  ExternalRef         External system reference (this is the product instance UUID if TKT is the CRM)
	* @property {String}  ServiceOrderUUID    The service instance state that was replaced by this one
	* @property {String}  ProductOrderUUID    The product instance UUID related to this service instance
	* @property {String}  ProductInstanceUUID 
	*
	*/
	GetServiceInstanceByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Get)
		// Prepare URI params...
		let paramSPID = encodeURIComponent(req.SPID)
		let paramUUID = encodeURIComponent(req.UUID)
		return axios.get(`/v1/${paramSPID}/service_instance/${paramUUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveServiceInstance', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetServiceInstanceByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetServiceInstanceByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetServiceInstanceByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetServiceInstanceByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetServiceInstanceByUUID'))
			})
	},

	/**
	* RPC Method: UpdateServiceInstance
	* Description: Create a new service instance
	* HTTP Method: PUT
	* API Path: /api/v1/${req.SPID}/service_instance
	* @param    {ServiceInstance} req The API payload object (ServiceInstance)
	* @return   {ServiceInstance} The API response object (ServiceInstance)
	*
	* @typedef  {Object}  ServiceInstance
	* @property {String}  UUID                Unique ID for this service instance
	* @property {Number}  SPID                SPID that this instance belongs to
	* @property {String}  ServiceCatalogUUID  Service catalog entry this instance was cloned from
	* @property {String}  ServiceType         The type of service, ie Mobility, Fixed, TV...
	* @property {Number}  CreatedAtNanos      Unixnano when this product was created
	* @property {Number}  UpdatedAtNanos      Unixnano when this product was updated
	* @property {Number}  DeletedAtNanos      Unixnano when this product was deleted
	* @property {String}  CustomerUUID        Internal Customer UUID (if TKT is the CRM for the customer)
	* @property {String}  ExternalRef         External system reference (this is the product instance UUID if TKT is the CRM)
	* @property {String}  ServiceOrderUUID    The service instance state that was replaced by this one
	* @property {String}  ProductOrderUUID    The product instance UUID related to this service instance
	* @property {String}  ProductInstanceUUID 
	*
	*/
	UpdateServiceInstance({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Update)
		return axios.put(`/v1/${req.SPID}/service_instance`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveServiceInstance', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('UpdateServiceInstance ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('UpdateServiceInstance ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('UpdateServiceInstance ERROR:', error)
					return Promise.reject(error)
				}
				console.log('UpdateServiceInstance UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in UpdateServiceInstance'))
			})
	},
};

export default {
	namespaced: true,
	state: initialState,
	getters,
	mutations,
	actions,
};