import store from '@/store'
import Vue from 'vue'
import Router from 'vue-router'
import permissions from './permissions'

Vue.use(Router)

const modules_env = store.getters.modules_env
// const toasts_list = store.getters.toasts_list

const show_billing_tab = store.getters.show_billing_tab
const show_orders_tab = store.getters.show_orders_tab

const vfenvironment_options = store.getters.vfenvironment_options
const have_vfenvironment_options = store.getters.have_vfenvironment_options
const env_pattern = `(${ Object.keys(vfenvironment_options).join('|') })`

const router = new Router({
    mode: 'history',
    routes: [
        { path: '/sign-in', component: () => import('@/views/layouts/not-authenticated'), children: [
            { path: '', name: 'sign-in', component: () => import('@/views/sign-in-v2') },
        ], beforeEnter: permissions(['!is-auth'], { name: 'main-dashboard' })},

        { path: '/forgot-password', component: () => import('@/views/layouts/not-authenticated'), children: [
            { path: '', name: 'forgot-password', component: () => import('@/views/forgot-password') },
        ], beforeEnter: permissions(['!is-auth'], { name: 'main-dashboard' })},

        { path: '/reset_password/:token', component: () => import('@/views/layouts/not-authenticated'), children: [
            { path: '', name: 'create-new-password', component: () => import('@/views/create-new-password') },
        ], beforeEnter: permissions(['!is-auth'], { name: 'main-dashboard' })},

        { path: '/spid', component: () => import('@/views/layouts'), children: [
            { path: '', name: 'spid', component: () => import('@/views/spid'), beforeEnter: permissions(['!with-current-spid'], { name: 'main-dashboard' }) },
        ], beforeEnter: permissions(['is-auth']) },

        { path: '/', component: () => import('@/views/layouts'), children: [
            { path: '',                       name: 'main-dashboard',  component: () => import('@/views/main-dashboard-v2') },
            { path: 'dash',                   name: 'main-dashboard-v1',  component: () => import('@/views/main-dashboard') },
            { path: 'customers/:page(\\d+)?', name: 'customers',       component: () => import('@/views/customers') },

            { path: 'customer/:uuid', component: () => import('@/views/customer'), children: [
                { path: '', name: 'customer', beforeEnter: ({ params }, from, next) => next({ name: 'customer-details', params, replace: true }) },

                { path: 'details',  name: 'customer-details',  component: () => import('@/views/customer/details')  },

                ...(show_billing_tab ? [
                    { path: 'billing',  name: 'customer-billing',  component: () => import('@/views/customer/billing')  },
                ] : []),

                ...(show_orders_tab ? [
                    { path: 'orders/:page(\\d+)?',   name: 'customer-orders',   component: () => import('@/views/customer/orders')   },
                ] : []),

                { path: 'products/:type?', name: 'customer-products', component: () => import('@/views/customer/products') },
            ] },

            ...(modules_env.PROD_FWA ? [
                { path: 'fwa', component: () => import('@/views/fwa'), children: [
                    { path: '',              name: 'fwa',                 beforeEnter: (to, from, next) => next({ name: 'fwa-services', replace: true }) },

                    { path: 'add-service',           name: 'fwa-add-service',     component: () => import('@/views/fwa/add-service')     },
                    { path: 'services/:page(\\d+)?', name: 'fwa-services',        component: () => import('@/views/fwa/services')        },
                    { path: 'orders/:page(\\d+)?',   name: 'fwa-orders',          component: () => import('@/views/fwa/orders')          },
                    { path: 'products',              name: 'fwa-products',        component: () => import('@/views/fwa/products')        },

                    { path: 'service/:uuid',         name: 'fwa-service-details', component: () => import('@/views/fwa/service-details') },
                ], beforeEnter: permissions(['module-allowed-fwa']) },
            ] : []),

            ...(modules_env.PROD_MOBILE ? [
                { path: 'postpay-mobile', component: () => import('@/views/postpay-mobile'), children: [
                    { path: '',                         name: 'postpay-mobile',                 beforeEnter: (to, from, next) => next({ name: 'postpay-mobile-services', replace: true }) },

                    { path: 'add-service',              name: 'postpay-mobile-add-service',        component: () => import('@/views/postpay-mobile/add-service')        },
                    { path: 'services/:page(\\d+)?',    name: 'postpay-mobile-services',           component: () => import('@/views/postpay-mobile/services')           },
                    { path: 'orders/:page(\\d+)?',      name: 'postpay-mobile-orders',             component: () => import('@/views/postpay-mobile/orders')             },
                    { path: 'products/:page(\\d+)?',    name: 'postpay-mobile-products',           component: () => import('@/views/postpay-mobile/products')           },
                ], beforeEnter: permissions(['module-allowed-postpay-mobile']) },
            ] : []),

            ...(modules_env.PROD_FIBRE ? [
                { path: 'fibre', component: () => import('@/views/fibre'), children: [
                    { path: '',                      name: 'fibre',                 beforeEnter: (to, from, next) => next({ name: 'fibre-services', replace: true }) },

                    { path: 'bulk-add-service',      name: 'fibre-bulk-add-service', component: () => import('@/views/fibre/bulk-add-service') },
                    { path: 'add-service',           name: 'fibre-add-service',      component: () => import('@/views/fibre/add-service')      },
                    { path: 'services/:page(\\d+)?', name: 'fibre-services',         component: () => import('@/views/fibre/services')         },
                    { path: 'orders/:page(\\d+)?',   name: 'fibre-orders',           component: () => import('@/views/fibre/orders')           },
                    { path: 'products/:page(\\d+)?', name: 'fibre-products',         component: () => import('@/views/fibre/products')         },

                    { path: 'order/:uuid',   name: 'fibre-order-details',   component: () => import('@/views/fibre/order-details')   },
                    { path: 'service/:uuid', name: 'fibre-service-details', component: () => import('@/views/fibre/service-details') },

                    { path: 'order/:uuid/notes',   name: 'fibre-order-notes',   component: () => import('@/views/fibre/order-notes')   },
                    { path: 'service/:uuid/notes', name: 'fibre-service-notes', component: () => import('@/views/fibre/service-notes') },
                ], beforeEnter: permissions(['module-allowed-fibre']) },
            ] : []),

            ...(modules_env.DOMAINS ? [
                { path: 'domains', component: () => import('@/views/domains'), children: [
                    { path: '', name: 'domains', beforeEnter: (to, from, next) => next({ name: 'domains-domain-list', replace: true })                },

                    { path: 'domain-list/:page(\\d+)?', name: 'domains-domain-list',      component: () => import('@/views/domains/domain-list')      },

                    { path: 'register-domain',                                            component: () => import('@/views/domains/register-domain'), children: [
                        { path: '', component: () => import('@/views/domains/register-domain/tabs'), children: [
                            { path: '',              name: 'domains-register-domain',               beforeEnter: (to, from, next) => next({ name: 'domains-register-domain-register' }) },

                            { path: 'register',      name: 'domains-register-domain-register',      component: () => import('@/views/domains/register-domain/tabs/register')            },
                            { path: 'bulk-register', name: 'domains-register-domain-bulk-register', component: () => import('@/views/domains/register-domain/tabs/bulk-register')       },
                        ] },

                        { path: 'confirm-registration/:page(\\d+)?', name: 'domains-register-domain-confirm-registration', component: () => import('@/views/domains/register-domain/confirm-registration') }
                    ] },

                    { path: 'transfer-domain',                               name: 'domains-transfer-domain',                  component: () => import('@/views/domains/transfer-domain')                  },
                    { path: 'transfer-domain/confirm-transfer/:page(\\d+)?', name: 'domains-transfer-domain-confirm-transfer', component: () => import('@/views/domains/transfer-domain/confirm-transfer') },

                    { path: 'default-settings',                   name: 'domains-default-settings',                   component: () => import('@/views/domains/default-settings')                   },
                    { path: 'default-settings/technical-contact', name: 'domains-default-settings-technical-contact', component: () => import('@/views/domains/default-settings/technical-contact') },
                    { path: 'default-settings/billing-contact',   name: 'domains-default-settings-billing-contact',   component: () => import('@/views/domains/default-settings/billing-contact')   },
                    { path: 'default-settings/nameservers',       name: 'domains-default-settings-nameservers',       component: () => import('@/views/domains/default-settings/nameservers')       },

                    /**
                     * @todo
                     * Implement getting data about specific domain in a navigation hook "beforeEnter"
                     */

                    { path: ':uuid',                    name: 'domain',                    component: () => import('@/views/domain')                    },
                    { path: ':uuid/registrant-contact', name: 'domain-registrant-contact', component: () => import('@/views/domain/registrant-contact') },
                    { path: ':uuid/technical-contact',  name: 'domain-technical-contact',  component: () => import('@/views/domain/technical-contact')  },
                    { path: ':uuid/billing-contact',    name: 'domain-billing-contact',    component: () => import('@/views/domain/billing-contact')    },
                    { path: ':uuid/nameservers',        name: 'domain-nameservers',        component: () => import('@/views/domain/nameservers')        },

                ], beforeEnter: permissions(['module-allowed-domains']) },
            ] : []),

            ...(modules_env.SIP_TRUNKING ? [
                { path: 'sip-trunking', component: () => import('@/views/sip-trunking'), children: [
                    { path: 'add-trunk', name: 'sip-trunking-add-trunk', component: () => import('@/views/sip-trunking/add-trunk') },

                    { path: '', name: 'sip-trunking', beforeEnter: (to, from, next) => next({ name: 'sip-trunking-sip-trunks', replace: true }) },

                    { path: 'sip-trunks/:page(\\d+)?', name: 'sip-trunking-sip-trunks',       component: () => import('@/views/sip-trunking/sip-trunks') },

                    { path: 'sip-trunk/create',        name: 'sip-trunking-sip-trunk-create', component: () => import('@/views/sip-trunk')               },
                    { path: 'sip-trunk/:uuid',         name: 'sip-trunking-sip-trunk-edit',   component: () => import('@/views/sip-trunk')               },

                    { path: 'sip-trunk/bulk-edit-numbers/:uuid', name: 'sip-trunking-sip-trunk-bulk-edit-numbers', component: () => import('@/views/sip-trunk/bulk-edit-numbers') },
                    { path: 'sip-trunk/product-notes/:uuid',     name: 'sip-trunking-sip-trunk-product-notes',     component: () => import('@/views/sip-trunk/product-notes')     },
                    { path: 'sip-trunk/add-phone-number/:uuid',  name: 'sip-trunking-sip-trunk-add-phone-number',  component: () => import('@/views/sip-trunk/add-phone-number')  },

                    // { path: 'channels',                name: 'sip-trunking-channels',         component: () => import('@/views/sip-trunking/channels')   },
                    // { path: 'routing',                 name: 'sip-trunking-routing',          component: () => import('@/views/sip-trunking/routing')    },

                    { path: 'tools',                                                          component: () => import('@/views/sip-trunking/tools'), children: [
                        { path: '', name: 'sip-trunking-tools', beforeEnter: (to, from, next) => next({ name: 'sip-trunking-tools-common-tasks', replace: true }) },

                        { path: 'common-tasks/:page(\\d+)?', name: 'sip-trunking-tools-common-tasks', component: () => import('@/views/sip-trunking/tools/common-tasks') },
                        { path: 'advanced',                  name: 'sip-trunking-tools-advanced',     component: () => import('@/views/sip-trunking/tools/advanced')     },
                    ] },

                    { path: 'tools/common-tasks/bulk-options/:uuids', name: 'sip-trunking-tools-common-tasks-bulk-options', component: () => import('@/views/sip-trunking/tools/common-tasks/bulk-options') },

                ], beforeEnter: permissions(['module-allowed-sip-trunking']) },
            ] : []),

            ...(modules_env.BULK_PRE_QUAL ? [
                { path: 'bulk', component: () => import('@/views/bulk'), children: [
                    { path: '',                            name: 'bulk',          beforeEnter: ({ params }, from, next) => next({ name: 'bulk-new', params, replace: true }) },

                    { path: '/bulk-new',                   name: 'bulk-new',      component: () => import('@/views/bulk/new-search') },
                    { path: '/bulk-previous/:page(\\d+)?', name: 'bulk-previous', component: () => import('@/views/bulk/previous-searches') },
                ], beforeEnter: permissions(['module-allowed-bulk']) },

                { path: 'bulk-search/:uuid/:page(\\d+)?', name: 'bulk-search', component: () => import('@/views/bulk/search-details'), beforeEnter: permissions(['module-allowed-bulk']) },
            ] : []),

            ...(modules_env.PREPAY_MOBILE ? [
                { path: 'prepay-mobile', component: () => import('@/views/prepay-mobile'), children: [
                    { path: '', name: 'prepay-mobile', beforeEnter: (to, from, next) => next({ name: 'prepay-mobile-preactivation', replace: true }) },

                    { path: 'preactivation', component: () => import('@/views/prepay-mobile/preactivation'), children: [
                        { path: '', name: 'prepay-mobile-preactivation', beforeEnter: (to, from, next) => next({ name: 'prepay-mobile-preactivation-reserve', replace: true }) },

                        { path: 'reserve',                            name: 'prepay-mobile-preactivation-reserve',               component: () => import('@/views/prepay-mobile/preactivation/reserve')               },
                        { path: 'previous-reservations/:page(\\d+)?', name: 'prepay-mobile-preactivation-previous-reservations', component: () => import('@/views/prepay-mobile/preactivation/previous-reservations') },
                        { path: 'preactivation',                      name: 'prepay-mobile-preactivation-preactivation',         component: () => import('@/views/prepay-mobile/preactivation/preactivation')         },
                    ] },

                    { path: 'add-service',              name: 'prepay-mobile-add-service',        component: () => import('@/views/prepay-mobile/add-service')        },
                    { path: 'services/:page(\\d+)?',    name: 'prepay-mobile-services',           component: () => import('@/views/prepay-mobile/services')           },
                    { path: 'orders/:page(\\d+)?',      name: 'prepay-mobile-orders',             component: () => import('@/views/prepay-mobile/orders')             },
                    { path: 'products/:page(\\d+)?',    name: 'prepay-mobile-products',           component: () => import('@/views/prepay-mobile/products')           },

                    { path: 'customer/:customer_uuid/service/:uuid',            name: 'prepay-mobile-service-details',    component: () => import('@/views/prepay-mobile/service-details')    },
                    { path: 'service/:uuid/statistics', name: 'prepay-mobile-service-statistics', component: () => import('@/views/prepay-mobile/service-statistics') },
                ], beforeEnter: permissions(['module-allowed-prepay-mobile']) },
            ] : []),

            ...(modules_env.SVC_RADIUS ? [
                { path: 'radius', component: () => import('@/views/radius'), children: [
                    { path: '',                      name: 'radius',                 beforeEnter: (to, from, next) => next({ name: 'radius-services', replace: true }) },

                    { path: 'services/:page(\\d+)?', name: 'radius-services',        component: () => import('@/views/radius/services')        },
                    { path: 'service/:uuid',         name: 'radius-service-details', component: () => import('@/views/radius/service-details') },

                    { path: 'sessions/:page(\\d+)?', name: 'radius-sessions',        component: () => import('@/views/radius/sessions')        },
                    { path: 'session/:uuid',         name: 'radius-session-details', component: () => import('@/views/radius/session-details') },

                    { path: 'usage',                 name: 'radius-usage',           component: () => import('@/views/radius/usage')           },
                    { path: 'config',                name: 'radius-config',          component: () => import('@/views/radius/config')          },
                ], beforeEnter: permissions(['module-allowed-radius']) },
            ] : []),

            ...(modules_env.SVC_NUMBER_PORTING ? [
                { path: 'number-porting', component: () => import('@/views/number-porting'), children: [

                    { path: '', name: 'number-porting', beforeEnter: (to, from, next) => next({ name: 'number-porting-number-ports', replace: true }) },

                    { path: 'number-ports/:page(\\d+)?', name: 'number-porting-number-ports', component: () => import('@/views/number-porting/number-ports') },

                    { path: 'number-port/create', name: 'number-porting-number-port-create', component: () => import('@/views/number-porting/number-port') },
                    { path: 'number-port/:uuid',  name: 'number-porting-number-port-edit',   component: () => import('@/views/number-porting/number-port') },

                    { path: 'number-port/:uuid/details', name: 'number-porting-number-port-details', component: () => import('@/views/number-porting/number-port-details') },

                ], beforeEnter: permissions(['module-allowed-number-porting']) },
            ] : []),

            ...(modules_env.SVC_MATRIXX ? [
                { path: 'matrixx', component: () => import('@/views/matrixx'), children: [
                    { path: '',                             name: 'matrixx',                        beforeEnter: (to, from, next) => next({ name: 'matrixx-sp-groups', replace: true }) },

                    { path: 'sp-groups/:page(\\d+)?',       name: 'matrixx-sp-groups',              component: () => import('@/views/matrixx/sp-groups'),             beforeEnter: permissions(['is-super-admin'], { name: 'matrixx-sp-groups' }) },
                    { path: 'sp-group/add-sp-group',        name: 'matrixx-add-sp-group',           component: () => import('@/views/matrixx/add-sp-group'),          beforeEnter: permissions(['is-super-admin'], { name: 'matrixx-sp-groups' }) },
                    { path: 'sp-group/:uuid',               name: 'matrixx-sp-group-details',       component: () => import('@/views/matrixx/sp-group-details'),      beforeEnter: permissions(['is-super-admin'], { name: 'matrixx-sp-groups' }) },

                    { path: 'customer-groups/:page(\\d+)?',               name: 'matrixx-customer-groups',               component: () => import('@/views/matrixx/customer-groups')                                                                                           },
                    { path: 'customer-group/add-customer-group',          name: 'matrixx-add-customer-group',            component: () => import('@/views/matrixx/add-customer-group'),     beforeEnter: permissions(['is-super-admin'], { name: 'matrixx-customer-groups' }) },
                    { path: 'customer-group/:uuid',                       name: 'matrixx-customer-group-details',        component: () => import('@/views/matrixx/customer-group-details'), beforeEnter: permissions(['is-super-admin'], { name: 'matrixx-customer-groups' }) },
                    { path: 'customer-group/:customer_uuid/device/:uuid', name: 'matrixx-customer-group-device-details', component: () => import('@/views/matrixx/device-details')                                                                                            },

                    { path: 'subscriptions/:page(\\d+)?',    name: 'matrixx-subscriptions',          component: () => import('@/views/matrixx/subscriptions')                                                                                        },
                    { path: 'subscription/add-subscription', name: 'matrixx-add-subscription',       component: () => import('@/views/matrixx/add-subscription'),    beforeEnter: permissions(['is-super-admin'], { name: 'matrixx-subscriptions' }) },
                    { path: 'subscription/:uuid',            name: 'matrixx-subscription-details',   component: () => import('@/views/matrixx/subscription-details')                                                                                 },

                    { path: 'devices/:page(\\d+)?',         name: 'matrixx-devices',                component: () => import('@/views/matrixx/devices')                                                                                  },
                    { path: 'device/add-device',            name: 'matrixx-add-device',             component: () => import('@/views/matrixx/add-device'),    beforeEnter: permissions(['is-super-admin'], { name: 'matrixx-devices' }) },
                    { path: 'device/:uuid',                 name: 'matrixx-device-details',         component: () => import('@/views/matrixx/device-details')                                                                           },
                ], beforeEnter: permissions(['module-allowed-matrixx', 'is-super-admin']) },
            ] : []),

            ...(modules_env.SVC_SMS_ROUTER ? [
                { path: 'smsc', component: () => import('@/views/matrixx'), children: [
                    { path: '',                             name: 'smsc',                        beforeEnter: (to, from, next) => next({ name: 'smsc-entries', replace: true }) },
                    { path: 'smsc-entries/:page(\\d+)?',    name: 'smsc-entries',                component: () => import('@/views/smsc/smsc-entries'),             beforeEnter: permissions(['is-super-admin'], { name: 'smsc-entries' }) },
                    { path: 'smsc-entries/:msisdn',               name: 'smsc-entries-details',    component: () => import('@/views/smsc/smsc-details'),      beforeEnter: permissions(['is-super-admin'], { name: 'smsc-entries' }) },
                ], beforeEnter: permissions(['module-allowed-smsc', 'is-super-admin']) },
            ] : []),

            ...(modules_env.SVC_TV ? [
                { path: 'tv', component: () => import('@/views/tv'), children: [
                    { path: '',                name: 'tv',     beforeEnter: (to, from, next) => next({ name: 'households', replace: true }) },

                    { path: 'households',                               component: () => import('@/views/tv/households'), children: [
                        { path: '', name: 'households', beforeEnter: (to, from, next) => next({ name: 'households-local', replace: true }) },

                        { path: 'local/:page(\\d+)?',   name: 'households-local',   component: () => import('@/views/tv/households/local')   },
                        { path: 'kaltura',              name: 'households-kaltura', component: () => import('@/views/tv/households/kaltura') },
                    ] },

                    { path: 'households/local/:uuid', name: 'households-local-details', component: () => import('@/views/tv/households/local/details') },

                    { path: 'households/kaltura/search-results/:page(\\d+)?', name: 'households-kaltura-search-results', component: () => import('@/views/tv/households/kaltura/search-results') },

                    { path: 'devices',                               component: () => import('@/views/tv/devices'), children: [
                        { path: '', name: 'devices', beforeEnter: (to, from, next) => next({ name: 'devices-local', replace: true }) },

                        { path: 'local/:page(\\d+)?',   name: 'devices-local',   component: () => import('@/views/tv/devices/local')   },
                        { path: 'kaltura',              name: 'devices-kaltura', component: () => import('@/views/tv/devices/kaltura') },
                    ] },

                    { path: 'devices/local/:uuid', name: 'devices-local-details', component: () => import('@/views/tv/devices/local/details') },

                    { path: 'devices/kaltura/search-results/:page(\\d+)?', name: 'devices-kaltura-search-results', component: () => import('@/views/tv/devices/kaltura/search-results') },

                    { path: 'entitlements/:page(\\d+)?', name: 'entitlements', component: () => import('@/views/tv/entitlements') },
                ], beforeEnter: permissions(['module-allowed-tv']) },
            ] : []),

            ...(modules_env.DIGITAL_COMMS ? [
                { path: 'digital-comms', component: () => import('@/views/digital-comms'), children: [
                    { path: '',                       name: 'digital-comms',               beforeEnter: (to, from, next) => next({ name: 'digital-comms-templates', replace: true }) },

                    { path: 'templates/:page(\\d+)?', name: 'digital-comms-templates',     component: () => import('@/views/digital-comms/templates')     },

                    { path: 'template/:uuid', component: () => import('@/views/digital-comms/template'), children: [
                        { path: '',      name: 'digital-comms-template',       beforeEnter: ({ params }, from, next) => next({ name: 'digital-comms-template-general', params, replace: true }) },

                        { path: 'general',  name: 'digital-comms-template-general',  component: () => import('@/views/digital-comms/template/general')  },
                        { path: 'email',    name: 'digital-comms-template-email',    component: () => import('@/views/digital-comms/template/email')    },
                        { path: 'sms',      name: 'digital-comms-template-sms',      component: () => import('@/views/digital-comms/template/sms')      },
                        { path: 'advanced', name: 'digital-comms-template-advanced', component: () => import('@/views/digital-comms/template/advanced') },
                    ] },

                    { path: 'configuration',          name: 'digital-comms-configuration', component: () => import('@/views/digital-comms/configuration') },
                ], beforeEnter: permissions(['module-allowed-digital-comms']) },
            ] : []),

            ...(modules_env.SMS_GATEWAY ? [
                { path: 'sms-gateway', component: () => import('@/views/sms-gateway'), children: [

                    { path: '', name: 'sms-gateway', beforeEnter: (to, from, next) => next({ name: 'sms-gateway-send-message', replace: true }) },

                    { path: 'send-message', name: 'sms-gateway-send-message', component: () => import('@/views/sms-gateway/send-message') },
                    { path: 'queues',       name: 'sms-gateway-queues',       component: () => import('@/views/sms-gateway/queues')       },

                ], beforeEnter: permissions(['module-allowed-sms-gateway']) },
            ] : []),

            ...(modules_env.IP_MANAGER ? [
                { path: 'ip-manager', component: () => import('@/views/ip-manager'), children: [
                    { path: '',                   name: 'ip-manager',          beforeEnter: (to, from, next) => next({ name: 'ip-manager-query', replace: true }) },

                    { path: 'query/:ip?',         name: 'ip-manager-query',    component: () => import('@/views/ip-manager/query')    },
                    { path: 'allocate/:cidr?',    name: 'ip-manager-allocate', component: () => import('@/views/ip-manager/allocate') },

                    { path: 'vrfs/:page(\\d+)?',  name: 'ip-manager-vrfs',     component: () => import('@/views/ip-manager/vrfs')     },
                    { path: 'pools/:page(\\d+)?', name: 'ip-manager-pools',    component: () => import('@/views/ip-manager/pools')    },
                ], beforeEnter: permissions(['module-allowed-ip-manager']) },
            ] : []),

            ...(modules_env.PSTN_MANAGER ? [
                { path: 'pstn-manager', component: () => import('@/views/pstn-manager'), children: [
                    { path: '',                    name: 'pstn-manager',          beforeEnter: (to, from, next) => next({ name: 'pstn-manager-lookup', replace: true }) },

                    { path: 'lookup/:page(\\d+)?', name: 'pstn-manager-lookup',   component: () => import('@/views/pstn-manager/lookup')                                                                                  },
                    { path: 'allocate',            name: 'pstn-manager-allocate', component: () => import('@/views/pstn-manager/allocate'), beforeEnter: permissions(['is-super-admin'], { name: 'pstn-manager-lookup' }) },
                ], beforeEnter: permissions(['module-allowed-pstn-manager']) },
            ] : []),

            ...(modules_env.NUMBER_MANAGER ? [
                { path: 'number-manager', component: () => import('@/views/number-manager'), children: [
                    { path: '', name: 'number-manager', beforeEnter: (to, from, next) => next({ name: 'number-manager-ranges', replace: true }), },

                    { path: 'ranges/:page(\\d+)?',      name: 'number-manager-ranges',            component: () => import('@/views/number-manager/ranges'),      },

                    { path: 'range/create',             name: 'number-manager-range-create',      component: () => import('@/views/number-manager/range'),       },
                    { path: 'range/:uuid/:spid?',       name: 'number-manager-range-edit',        component: () => import('@/views/number-manager/range'),       },

                    { path: 'sim-ranges/:page(\\d+)?',  name: 'number-manager-sim-ranges',        component: () => import('@/views/number-manager/sim-ranges'),  },

                    { path: 'sim-range/:uuid',          name: 'number-manager-sim-range-edit',    component: () => import('@/views/number-manager/sim-range'),   },

                    { path: 'allocations/:page(\\d+)?', name: 'number-manager-allocations',       component: () => import('@/views/number-manager/allocations'), },

                    { path: 'allocation/create',        name: 'number-manager-allocation-create', component: () => import('@/views/number-manager/allocation'),  },
                    { path: 'allocation/:uuid',         name: 'number-manager-allocation-edit',   component: () => import('@/views/number-manager/allocation'),  },
                ], beforeEnter: permissions(['module-allowed-number-manager']) },
            ] : []),

            ...(modules_env.LOYALTY_MANAGEMENT ? [
                { path: 'loyalty-management', component: () => import('@/views/loyalty-management'), children: [
                    { path: '', name: 'loyalty-management', beforeEnter: (to, from, next) => next({ name: 'loyalty-management-search-voucher', replace: true }), },

                    { path: 'search-voucher',               name: 'loyalty-management-search-voucher',  component: () => import('@/views/loyalty-management/search-voucher'),  },
                    { path: 'search-voucher/:voucher_uuid', name: 'loyalty-management-customer-search', component: () => import('@/views/loyalty-management/customer-search'), },
                ], beforeEnter: permissions(['module-allowed-loyalty-management']) },
            ] : []),

            ...(modules_env.SRV_ASAP ? [
                { path: 'asap', component: () => import('@/views/asap'), children: [
                    ...(have_vfenvironment_options ? [
                        { path: '', name: 'asap', beforeEnter: (to, from, next) => next({ name: 'asap-configuration', replace: true }) },

                        { path: 'configuration', component: () => import('@/views/asap/configuration'), children: [
                            { path: '', component: () => import('@/views/asap/configuration/tabs'), children: [
                                { path: '', name: 'asap-configuration', beforeEnter: (to, from, next) => next({ name: 'asap-configuration-list', params: { env: Object.keys(vfenvironment_options)[0] }, replace: true }) },
    
                                {
                                    path: `:env${ env_pattern }/:page(\\d+)?`,
                                    name: 'asap-configuration-list',
                                    component: () => import('@/views/asap/configuration/tabs/list')
                                },
                            ] },
    
                            {
                                path: `:env${ env_pattern }/create`,
                                name: 'asap-configuration-create',
                                component: () => import('@/views/asap/configuration/config-details')
                            },
    
                            {
                                path: `:env${ env_pattern }/:uuid`,
                                name: 'asap-configuration-edit',
                                component: () => import('@/views/asap/configuration/config-details')
                            },
                        ] },
                    ] : [
                        { path: '', name: 'asap', beforeEnter: (to, from, next) => next({ name: 'asap-workflows', replace: true }) },
                    ]),

                    { path: 'workflows/:namespace_id?/:page(\\d+)?', name: 'asap-workflows', component: () => import('@/views/asap/workflows-v3') },

                    { path: 'workflow/:namespace_name/:workflow_id/:run_id', component: () => import('@/views/asap/workflow'), children: [
                        { path: '', name: 'asap-workflow-details', beforeEnter: ({ params }, from, next) => next({ name: 'asap-workflow-summary', params, replace: true }) },

                        { path: 'summary', name: 'asap-workflow-summary', component: () => import('@/views/asap/workflow/summary') },
                        { path: 'history', name: 'asap-workflow-history', component: () => import('@/views/asap/workflow/history') },
                    ] },
                ], beforeEnter: permissions(['module-allowed-asap']) },
            ] : []),

            ...(modules_env.SERVICE_INVENTORY ? [
                { path: 'service-inventory/:page(\\d+)?', name: 'service-inventory', component: () => import('@/views/service-inventory'), beforeEnter: permissions(['module-allowed-service-inventory']) },
            ] : []),

            ...(modules_env.SERVICE_ORDERS ? [
                { path: 'service-orders/:page(\\d+)?', name: 'service-orders', component: () => import('@/views/service-orders'), beforeEnter: permissions(['module-allowed-service-orders']) },
            ] : []),

            { path: 'settings', component: () => import('@/views/settings'), children: [
                { path: '',                      name: 'settings',              beforeEnter: ({ params }, from, next) => next({ name: 'members', params, replace: true }) },

                { path: '/members/:page(\\d+)?',    name: 'members',      component: () => import('@/views/members')      },
                { path: '/teams/:page(\\d+)?',      name: 'teams',        component: () => import('@/views/teams')        },
                { path: '/org-settings',            name: 'org-settings', component: () => import('@/views/org-settings') },
                { path: '/api-access/:page(\\d+)?', name: 'api-access',   component: () => import('@/views/api-access')   },
                { path: '/webhooks',                name: 'webhooks',     component: () => import('@/views/webhooks')     },

                { path: '/service-provider-products/:page(\\d+)?', name: 'service-provider-products', component: () => import('@/views/service-provider-products') },
            ] },

            /**
             * @todo
             * #21318
             */
            { path: '/service-provider-product/:uuid', name: 'service-provider-product', component: () => import('@/views/product'), props: { readonlyMode: true, showServiceProviderPricesTable: false } },

            { path: 'member/:auth_id', name: 'member', component: () => import('@/views/member') },
            { path: 'team/:uuid/:page(\\d+)?',      name: 'team',   component: () => import('@/views/team')      },

            { path: 'member-creating', name: 'add-member', component: () => import('@/views/member-creating') },

            { path: 'admin', component: () => import('@/views/admin'), children: [
                { path: '',           name: 'admin',     beforeEnter: ({ params }, from, next) => next({ name: 'providers', params, replace: true }) },

                { path: '/providers/:page(\\d+)?', name: 'providers', component: () => import('@/views/providers') },
                { path: '/products/:page(\\d+)?',  name: 'products',  component: () => import('@/views/products')  },
                { path: '/services/:page(\\d+)?',  name: 'services',  component: () => import('@/views/services')  },
                { path: '/resources/:page(\\d+)?', name: 'resources', component: () => import('@/views/resources') },
            ], beforeEnter: permissions(['is-super-admin']) },

            { path: '/product/:uuid',                            name: 'product',                component: () => import('@/views/product'),                beforeEnter: permissions(['is-super-admin']) },
            { path: '/product/:type',                            name: 'product-creating',       component: () => import('@/views/product'),                beforeEnter: permissions(['is-super-admin']) },
            { path: '/product/:uuid/characteristic/:char_uuid?', name: 'product-characteristic', component: () => import('@/views/product/characteristic'), beforeEnter: permissions(['is-super-admin']) },
            { path: '/product/:uuid/price/:price_uuid?',         name: 'product-price',          component: () => import('@/views/product/price'),          beforeEnter: permissions(['is-super-admin']) },

            { path: 'provider/create',  name: 'provider-create', component: () => import('@/views/provider-create'), beforeEnter: permissions(['is-super-admin', 'is-allow-create-new-provider']) },
            { path: 'provider/:uuid',   name: 'provider',        component: () => import('@/views/provider'),        beforeEnter: permissions(['is-super-admin']) },

            { path: '/service/:uuid',                            name: 'service',                component: () => import('@/views/service'),                beforeEnter: permissions(['is-super-admin']) },
            { path: '/service/:type',                            name: 'service-creating',       component: () => import('@/views/service'),                beforeEnter: permissions(['is-super-admin']) },
            { path: '/service/:uuid/characteristic/:char_uuid?', name: 'service-characteristic', component: () => import('@/views/service/characteristic'), beforeEnter: permissions(['is-super-admin']) },
        ], beforeEnter: permissions(['is-auth', 'with-current-spid']) },

        { path: '/api', name: 'api', component: () => import('@/views/api'), beforeEnter: permissions(['is-auth']) },

        { path: '/test', name: 'test',                                                       component: () => import('@/views/test'),
            children: [
                { path: 'app-delete-modal',          name: 'app-delete-modal',               component: () => import('@/views/test/app-delete-modal')          },
                { path: 'api',                       name: 'test-api',                       component: () => import('@/views/test/api')                       },
                { path: 'icons',                     name: 'test-icons',                     component: () => import('@/views/test/icons')                     },
                { path: 'app-branding-color-scheme', name: 'test-app-branding-color-scheme', component: () => import('@/views/test/app-branding-color-scheme') },

                { path: 'app-table',                                                      component: () => import('@/views/test/app-table'),
                    children: [
                        { path: '',                name: 'app-table',                 beforeEnter: (to, from, next) => next({ name: 'app-table-customers', replace: true }) },

                        { path: 'customers',       name: 'app-table-customers',       component: () => import('@/views/test/app-table/customers')                           },
                        { path: 'icons',           name: 'app-table-icons',           component: () => import('@/views/test/app-table/icons')                               },
                        { path: 'product-orders',  name: 'app-table-product-orders',  component: () => import('@/views/test/app-table/product-orders')                      },
                        { path: 'service-orders',  name: 'app-table-service-orders',  component: () => import('@/views/test/app-table/service-orders')                      },
                        { path: 'fwa',             name: 'app-table-fwa',             component: () => import('@/views/test/app-table/fwa')                                 },
                        { path: 'mobile-services', name: 'app-table-mobile-services', component: () => import('@/views/test/app-table/mobile-services')                     },
                        { path: 'providers',       name: 'app-table-providers',       component: () => import('@/views/test/app-table/providers')                           },
                    ]
                },

                { path: 'app-date-range',      name: 'test-app-date-range',      component: () => import('@/views/test/app-date-range')      },
                { path: 'app-datetime-picker', name: 'test-app-datetime-picker', component: () => import('@/views/test/app-datetime-picker') },
                { path: 'sa-email-builder',    name: 'sa-email-builder',         component: () => import('@/views/test/sa-email-builder')    },
                { path: 'api-redoc',           name: 'api-redoc',                component: () => import('@/views/test/api-redoc')           },
            ]
        },
        {
            path: '/example', name: 'example', component: () => import('@/views/example'),
            children: [
                { path: 'auth', name: 'auth', component: () => import('@/views/example/auth') },
            ]
        },

        { path: '*', component: () => import('@/views/404') },
    ],

    // Scroll up when changing route
    scrollBehavior(to, from, savedPosition) {
        let scroll = { x: 0, y: 0 }

        if (to.hash) {
            scroll = { selector: to.hash }
        } else if (savedPosition) {
            scroll = savedPosition
        }

        return scroll
    },
})

/*
    router.beforeEach((to, from, next) => {
        if (toasts_list.length > 0) {
            store.dispatch('clearAllToasts')
        }

        next()
    })
*/

export default router