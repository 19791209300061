import axios from 'axios'
import axiosUnique from '@/utils/axiosUnique'

import StoreHash from '@/utils/StoreHash'

let hashed = {
    bulk_services: new StoreHash(5 * 60 * 1000), // 5 min
}

const state = {
    bulk_services: {},

    bulk_filter: null,
}

const getters = {  
    bulk_services: state => state.bulk_services,

    bulk_filter: state => state.bulk_filter,
}

const mutations = {
    saveBulkServices(state, services) {
        let bulk_services = {}

        for (let i = 0, len = services.length; i < len; i++) {
            const service = services[i]

            bulk_services[ service.Name ] = service
        }

        state.bulk_services = bulk_services
        
        hashed.bulk_services.fix()
    },

    resetHashedBulk(state) {
        state.bulk_services = {}

        hashed.bulk_services.reset()
    },

    saveBulkFilter(state, filter) {
        state.bulk_filter = filter
    },    
    clearBulkFilter(state) {
        state.bulk_filter = null
    },
}

const actions = {
    getBulkServices({getters, commit}) {
        if (hashed.bulk_services.expired()) {
            /**
             * @todo Replace 2 corresponding lines below for real data
             */
            return import('./hardcoded-test-data/bulk/bulk-services.json').then(apidata => {
                const services = apidata.default.Services

            // return axiosUnique.get(`/v2/:spid/bulk/services`).then(({apidata}) => {
            //     const services = response.apidata.Services

                commit('saveBulkServices', services)

                return Promise.resolve(getters.bulk_services)
            }).catch(error => {
                return Promise.reject(error)
            })
        } else {
            return Promise.resolve(getters.bulk_services)
        }
    },

    createBulkSearch({commit}, bulk) {
        /**
         * @todo Replace for real data
         */
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve(bulk)
            }, 500)
        })

        return axios.post(`/v2/:spid/bulk`, bulk).then(({apidata}) => {
            const uuid = apidata.UUID

            return Promise.resolve(apidata)
        }).catch(error => {
            return Promise.reject(error)
        })
    },

    getBulkSearches({commit, dispatch}, { params, filter }) {
        /**
         * @todo Replace 2 corresponding lines below for real data
         */
        return import('./hardcoded-test-data/bulk/bulk-searches.json').then(response => {
            const searches = response.default.Searches

        // return axiosUnique.get(`/v2/:spid/bulk`, { params }).then(response => {
        //     const searches = response.apidata.Searches

            const action = filter ? 'saveBulkFilter' : 'clearBulkFilter'
            const resolve = () => Promise.resolve(response)

            return dispatch(action, filter).then(resolve).catch(resolve)
        }).catch(error => {
            return Promise.reject(error)
        })
    },

    removeBulkSearch({commit}, uuid) {
        /**
         * @todo Replace for real data
         */
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve(true)
            }, 500)
        })

        return axios.delete(`/v2/:spid/bulk/${ uuid }`).then(({apidata}) => {
            return Promise.resolve(apidata)
        }).catch(error => {
            return Promise.reject(error)
        })
    },
    
    getBulkSearchAddresses({dispatch}, { uuid, params, with_services }) {
        /**
         * @todo Replace lines below for real data
         */
        return import('./hardcoded-test-data/bulk/bulk-search-uuid-addresses.json').then(response => {

        // return axiosUnique.get(`/v2/:spid/bulk/${ uuid }`, { params }).then(response => {

            const resolve = () => Promise.resolve(response)

            return with_services
                ? dispatch('getBulkServices')
                    .then(resolve)
                    .catch(resolve)
                : resolve()
        }).catch(error => {
            return Promise.reject(error)
        })
    },

    removeBulkSearchAddressServices({commit}, { uuid, services }) {
        /**
         * @todo Replace for real data
         */
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve(true)
            }, 500)
        })

        return axios.delete(`/v2/:spid/bulk/${ uuid }/services`, services).then(({apidata}) => {
            return Promise.resolve(apidata)
        }).catch(error => {
            return Promise.reject(error)
        })
    },

    loadBulkFilter({getters}) {
        const filter = getters.bulk_filter

        return filter
            ? Promise.resolve(filter)
            : Promise.reject(filter)
    },
    saveBulkFilter({commit, getters}, filter) {
        commit('saveBulkFilter', filter)
        return Promise.resolve(getters.bulk_filter)
    },
    clearBulkFilter({commit, getters}) {
        commit('clearBulkFilter')
        return Promise.resolve(getters.bulk_filter)
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
}