// Code generated by protoc-gen-iqwebapi. DO NOT EDIT.
// source: fwa.proto

import axios from 'axios';
import StoreHash from '@/utils/StoreHash';

const initialState = {
};

const valFuncFind = function (status) {
	return (status == 200) ? true : (status == 404) ? true : false;
}

const default_pageinfo = {
	PageNumber: 0,
	PageItemCount: 0,
	TotalPages: 0,
	TotalItemCount: 0,
	IndexStart: 0,
	PageSize: 10,
	DisplayPageList: [],
	DisplayPageEitherSide: 4,
}

const getters = {
};

const mutations = {
};

const actions = {
	//
	// Service: FWARPC
	// 
	/**
	* RPC Method: CreateFWAProduct
	* Description: This endpoint allows ordering of a new FWA service based on the one of the available plans
	* 
	* **Notes about the provisioning process**
	* - Select a Customer to provision a product on
	* - Use the [plan search API](#operation/FindFWAPlansPaginated) to find the appropriate plan, it is recommended to always do this step instead of just recording and using the UUID as the UUID of the plan could change if changes are made to the plan by your wholesale provider. If the plan UUID changes it will cause order failures.
	* - Use the SIM availabily API to select a SIM:
	*   - If the SIM IMSI is being entered manually or selected via some other process then SIM availablity search is not required.
	*   - You can just select the next SIM in the list if there are no other specific requirements.
	* - Create an order for the product with the ordering API call.
	* 
	* HTTP Method: POST
	* API Path: /api/v1/${req.SPID}/FWA
	* @param    {FWACreateRequest} req The API payload object (FWACreateRequest)
	* @return   {FWACreateResponse} The API response object (FWACreateResponse)
	*
	* @typedef  {Object}  FWACreateRequest
	* @property {Number}  SPID               SPID is your service provider ID
	* @property {String}  CustomerUUID       This is the customer to which the new product will be provisioned for
	* @property {String}  PlanUUID           This is the UUID of the selected plan retrived from the FWA Plan seach
	* @property {String}  PrequalUUID        This is the UUID returned from the prequalification service
	* @property {String}  IMSI               This is the IMSI of the SIM card that has been selected for this service. This is mutually exclusive to the SIMID paramter.
	* @property {String}  MSISDN             This is the phone number for this service - it is required due to the nature of the mobile network but it is not possible to use the voice service on FWA at this time.
	* @property {String}  SIMID              This is the SIMID of the SIM card that has been selected for this service. This is mutually exclusive to the IMSI paramter.
	* @property {Boolean} AutoAllocateMSISDN If the MSISDN should be auto allocated
	*
	* @typedef  {Object}  FWACreateResponse
	* @property {Number}  SPID    This is your service provider ID
	* @property {String}  OrderID This is the product order UUID, it can be queried using the product order APIs to determine the current state and history of the order
	*
	*/
	CreateFWAProduct({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.post(`/v1/${req.SPID}/FWA`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('CreateFWAProduct ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('CreateFWAProduct ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('CreateFWAProduct ERROR:', error)
					return Promise.reject(error)
				}
				console.log('CreateFWAProduct UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in CreateFWAProduct'))
			})
	},

	/**
	* RPC Method: FindFWAPlansPaginated
	* Description: This endpoint allows for browsing, searching among and filtering the available FWA plans.
	* HTTP Method: GET
	* API Path: /api/v1/${req.SPID}/FWA_plans
	* @param    {FWAPlanSearchRequest} req The API payload object (FWAPlanSearchRequest)
	* @return   {FWAPlanPageResponse} The API response object (FWAPlanPageResponse)
	*
	* @typedef  {Object}  FWAPlanSearchRequest
	* @property {SearchOptions[]} SearchOptions       Search options to use (for sorting and/or pagination)
	* @property {Number}  SPID                SPID is the service provider ID that the product belongs to
	* @property {String}  CustomerUUID        Unused
	* @property {String}  SiteClass           Filter by site class - matches the FWA.SiteClass characteristic
	* @property {String}  Location            Filter by Location - if you have done a prequal already
	* @property {String}  ProductInstanceUUID Filter by existing Instance - if you wish to make a plan change
	*
	* @typedef  {Object}  FWAPlanPageResponse
	* @property {PageInfo} PageInfo Pagination information
	* @property {FWAPlan[]} FWAPlans The list of matching plans
	*
	*/
	FindFWAPlansPaginated({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		// Prepare URI params...
		let paramSPID = encodeURIComponent(req.SPID)
		return axios.get(`/v1/${paramSPID}/FWA_plans`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('FindFWAPlansPaginated ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('FindFWAPlansPaginated ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('FindFWAPlansPaginated ERROR:', error)
					return Promise.reject(error)
				}
				console.log('FindFWAPlansPaginated UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in FindFWAPlansPaginated'))
			})
	},

	/**
	* RPC Method: FindFWAProductsPaginated
	* Description: This endpoint allows for browsing and filtering of currently provisioned FWA services
	* HTTP Method: GET
	* API Path: /api/v1/${req.SPID}/FWA
	* @param    {FWAGetRequest} req The API payload object (FWAGetRequest)
	* @return   {FWAGetResponse} The API response object (FWAGetResponse)
	*
	* @typedef  {Object}  FWAGetRequest
	* @property {SearchOptions[]} SearchOptions Pagination options
	* @property {Number}  SPID          This is your service provider ID
	* @property {String}  CustomerUUID  Limit search to a specific customer
	* @property {String}  UUID          Limit search to a particular product
	*
	* @typedef  {Object}  FWAGetResponse
	* @property {PageInfo} PageInfo    Pagination options
	* @property {FWAProduct[]} FWAProducts An array of FWA Product objects
	*
	*/
	FindFWAProductsPaginated({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		// Prepare URI params...
		let paramSPID = encodeURIComponent(req.SPID)
		return axios.get(`/v1/${paramSPID}/FWA`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('FindFWAProductsPaginated ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('FindFWAProductsPaginated ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('FindFWAProductsPaginated ERROR:', error)
					return Promise.reject(error)
				}
				console.log('FindFWAProductsPaginated UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in FindFWAProductsPaginated'))
			})
	},

	/**
	* RPC Method: GetFWAProductByUUID
	* Description: This endpoint allows for fetching of a FWA product
	* HTTP Method: GET
	* API Path: /api/v1/${req.SPID}/customer/${req.CustomerUUID}/FWA/${req.UUID}
	* @param    {FWAGetRequest} req The API payload object (FWAGetRequest)
	* @return   {FWAProduct} The API response object (FWAProduct)
	*
	* @typedef  {Object}  FWAGetRequest
	* @property {SearchOptions[]} SearchOptions Pagination options
	* @property {Number}  SPID          This is your service provider ID
	* @property {String}  CustomerUUID  Limit search to a specific customer
	* @property {String}  UUID          Limit search to a particular product
	*
	* @typedef  {Object}  FWAProduct
	* @property {String}  UUID           Unique ID for this product instance
	* @property {Number}  SPID           Service provider ID
	* @property {String}  CustomerUUID   The Customer this product is attached to
	* @property {String}  PrequalUUID    The UUID given to you by the prequal service
	* @property {String}  IMSI           The IMSI of the selected SIM
	* @property {String}  MSISDN         MSISDN - the phone number for this product
	* @property {String}  PlanUUID       The UUID of the plan this product is using
	* @property {Number}  Cents          The cost of this product in cents
	* @property {Number}  CreatedAtNanos When this product was first created
	* @property {Number}  UpdatedAtNanos When this product was last updated
	* @property {Number}  DeletedAtNanos When this product was terminated
	* @property {String}  State          The current state of this product
	*
	*/
	GetFWAProductByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		// Prepare URI params...
		let paramSPID = encodeURIComponent(req.SPID)
		let paramCustomerUUID = encodeURIComponent(req.CustomerUUID)
		let paramUUID = encodeURIComponent(req.UUID)
		return axios.get(`/v1/${paramSPID}/customer/${paramCustomerUUID}/FWA/${paramUUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetFWAProductByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetFWAProductByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetFWAProductByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetFWAProductByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetFWAProductByUUID'))
			})
	},

	/**
	* RPC Method: TerminateFWAProduct
	* Description: This endpoint creates an order to terminate the referenced product.
	* HTTP Method: DELETE
	* API Path: /api/v1/${req.SPID}/FWA/${req.UUID}
	* @param    {FWATerminateRequest} req The API payload object (FWATerminateRequest)
	* @return   {FWATerminateResponse} The API response object (FWATerminateResponse)
	*
	* @typedef  {Object}  FWATerminateRequest
	* @property {Number}  SPID         This is your service provider ID
	* @property {String}  UUID         This is the UUID of the product that is being terminated
	* @property {String}  CustomerUUID This is the UUID of the customer
	*
	* @typedef  {Object}  FWATerminateResponse
	* @property {Number}  SPID This is your servce provider ID
	* @property {String}  UUID TODO,  This is the UUID of the terminate order
	*
	*/
	TerminateFWAProduct({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.delete(`/v1/${req.SPID}/FWA/${req.UUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('TerminateFWAProduct ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('TerminateFWAProduct ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('TerminateFWAProduct ERROR:', error)
					return Promise.reject(error)
				}
				console.log('TerminateFWAProduct UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in TerminateFWAProduct'))
			})
	},

	/**
	* RPC Method: UpdateFWAProduct
	* Description: This endpoint is fow making changes to a FWA service that is currently provisioned in the system. Pass in the changes you wish to make and the system will create a change order and update the product instance.
	* HTTP Method: PUT
	* API Path: /api/v1/${req.SPID}/FWA/${req.UUID}
	* @param    {FWAUpdateRequest} req The API payload object (FWAUpdateRequest)
	* @return   {FWAUpdateResponse} The API response object (FWAUpdateResponse)
	*
	* @typedef  {Object}  FWAUpdateRequest
	* @property {Number}  SPID          This is your service provider ID
	* @property {String}  UUID          The product UUID you want to change
	* @property {String}  CustomerUUID  Change the product over to a new customer
	* @property {String}  ReferenceCode ??
	* @property {String}  PlanUUID      Change this product to a new plan
	* @property {String}  IMSI          Change the IMSI - for example if the customer needs a replacement SIM for some reason (sim swap)
	* @property {String}  MSISDN        Change the phone number attached to this service
	* @property {String}  SIMID         This is the SIMID of the SIM card that has been selected for this service. This is mutually exclusive to the IMSI paramter.
	*
	* @typedef  {Object}  FWAUpdateResponse
	* @property {Number}  SPID    This is your service provider ID
	* @property {String}  UUID    This is the UUID of the product that is being changed
	* @property {String}  OrderID This is the UUID of the change order
	*
	*/
	UpdateFWAProduct({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.put(`/v1/${req.SPID}/FWA/${req.UUID}`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('UpdateFWAProduct ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('UpdateFWAProduct ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('UpdateFWAProduct ERROR:', error)
					return Promise.reject(error)
				}
				console.log('UpdateFWAProduct UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in UpdateFWAProduct'))
			})
	},
};

export default {
	namespaced: true,
	state: initialState,
	getters,
	mutations,
	actions,
};