// Code generated by protoc-gen-iqwebapi. DO NOT EDIT.
// source: smsgw.proto

import axios from 'axios';
import StoreHash from '@/utils/StoreHash';

const initialState = {
};

const valFuncFind = function (status) {
	return (status == 200) ? true : (status == 404) ? true : false;
}

const default_pageinfo = {
	PageNumber: 0,
	PageItemCount: 0,
	TotalPages: 0,
	TotalItemCount: 0,
	IndexStart: 0,
	PageSize: 10,
	DisplayPageList: [],
	DisplayPageEitherSide: 4,
}

const getters = {
};

const mutations = {
};

const actions = {
	//
	// Service: SMSGateway
	// 
	/**
	* RPC Method: GetQueueStats
	* Description: Get the Queue Stats for a specified time interval (in seconds)
	* HTTP Method: POST
	* API Path: /api/v1/sms/queue_stats
	* @param    {QueueStatsRequest} req The API payload object (QueueStatsRequest)
	* @return   {QueueStatsResponse} The API response object (QueueStatsResponse)
	*
	* @typedef  {Object}  QueueStatsRequest
	* @property {Number}  SPID                  
	* @property {String}  QueueName             The subscription ID we want to retrieve the stats for
	* @property {Number}  TimeIntervalInSeconds Time window we collect the stats
	*
	* @typedef  {Object}  QueueStatsResponse
	* @property {String}  QueueName         The subscription ID we retrieved the stats for
	* @property {Number}  QueueLengthHigh   The Lenght of the queue
	* @property {Number}  QueueLengthMedium 
	* @property {Number}  QueueLengthLow    
	*
	*/
	GetQueueStats({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.post(`/v1/sms/queue_stats`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetQueueStats ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetQueueStats ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetQueueStats ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetQueueStats UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetQueueStats'))
			})
	},

	/**
	* RPC Method: SendMessage
	* Description: sends an SMS message with the given priority and through the specified route
	* HTTP Method: POST
	* API Path: /api/v1/sms/send
	* @param    {Message} req The API payload object (Message)
	* @return   {DeliveryReference} The API response object (DeliveryReference)
	*
	* @typedef  {Object}  Message
	* @property {String}  ID             ID is the ID of this message, as defined by the sender., This field is optional for a message.
	* @property {String}  To             To is the destination that the message should be delivered, to, this can be a mobile number or shortcode.
	* @property {String}  From           From is the sending location of this message and can be , a mobile number or shortcode.
	* @property {Route} Route          SMSC describes when to use the SMSC vs the third-party, sender associated with the Message.
	* @property {ENUM}    Priority       Priority of the message, a negative number is low priority, a positive number is high priority, zero is standard.
	* @property {String}  Text           Text content of the message. Should be ascii or unicode.
	* @property {Boolean} ReportDelivery ReportDelivery marks whether or not this message needs to , produce a delivery report after is has been delivered. If, true, then the sender of this message will be notified , after this message has been delivered.
	*
	* @typedef  {Object}  DeliveryReference
	* @property {String}  UUID UUID is the SMS Gateway internal ID of the message that, was delivered, this will be provided in any relevant , delivery reports.
	*
	*/
	SendMessage({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.post(`/v1/sms/send`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('SendMessage ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('SendMessage ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('SendMessage ERROR:', error)
					return Promise.reject(error)
				}
				console.log('SendMessage UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in SendMessage'))
			})
	},

	/**
	* RPC Method: SendModicaMessage
	* Description: Modica MO callback
	* HTTP Method: POST
	* API Path: /${req.SPID}/callback/modica/mo
	* @param    {ModicaMessage} req The API payload object (ModicaMessage)
	* @return   {Nothing} The API response object (Nothing)
	*
	* @typedef  {Object}  ModicaMessage
	* @property {String}  ID          
	* @property {String}  Source      
	* @property {String}  Destination 
	* @property {String}  Content     
	* @property {String}  Operator    
	* @property {String}  ReplyTo     
	* @property {String}  Encoding    
	* @property {Number}  SPID        
	*
	* @typedef  {Object}  Nothing
	*
	*/
	SendModicaMessage({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.post(`/${req.SPID}/callback/modica/mo`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('SendModicaMessage ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('SendModicaMessage ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('SendModicaMessage ERROR:', error)
					return Promise.reject(error)
				}
				console.log('SendModicaMessage UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in SendModicaMessage'))
			})
	},

	/**
	* RPC Method: SendTMF861Message
	* Description: SendTMF861Message sends a TMF681 encapsulated message with the given priority and to the specified vendor
	* HTTP Method: POST
	* API Path: /tmf-api/communicationManagement/v4/communicationMessage
	* @param    {TMF681CommunicationMessage} req The API payload object (TMF681CommunicationMessage)
	* @return   {TMF681CommunicationMessage} The API response object (TMF681CommunicationMessage)
	*
	* @typedef  {Object}  TMF681CommunicationMessage
	* @property {String}  ExternalID         The External message ID supplied by the sending system, required
	* @property {String}  NaaSID             The NAAS message ID, generated by the NaaS upon successful receipt of the SMS
	* @property {String}  SMSContent         The contents of the SMS, required
	* @property {ENUM}    Priority           The message priority [AUTO | LOW | MEDIUM | HIGH]. Defaults to AUTO if not supplied
	* @property {Boolean} DeliverReceiptReqd Whether or not a delivery receipt is required. Defaults to false
	* @property {String}  Vendor             The SMS Partner vendor to send the message to. Defaults to AUTO
	* @property {String}  SubmitTime         The time the message was submitted, in ISO333
	* @property {TMF681CommunicationMessageSender} Sender             Sender details
	* @property {TMF681CommunicationMessageReceiver[]} Receiver           Receiver details
	* @property {String}  AcceptTime         The time the message was accepted
	* @property {TMF681CommunicationMessageDeliveryStatus[]} DeliveryStatus     The status of Delivery (optional)
	* @property {Number}  SPID               Magic field for auth
	*
	*/
	SendTMF861Message({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.post(`/tmf-api/communicationManagement/v4/communicationMessage`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('SendTMF861Message ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('SendTMF861Message ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('SendTMF861Message ERROR:', error)
					return Promise.reject(error)
				}
				console.log('SendTMF861Message UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in SendTMF861Message'))
			})
	},

	/**
	* RPC Method: UpdateDeliveryStatus
	* Description: update the given delivery status
	* HTTP Method: POST
	* API Path: /api/v1/sms/dlr
	* @param    {DeliveryStatus} req The API payload object (DeliveryStatus)
	* @return   {Nothing} The API response object (Nothing)
	*
	* @typedef  {Object}  DeliveryStatus
	* @property {DeliveryReference} DeliveryReference DeliveryReference as returned by a previous call to, SendMessage.
	* @property {ENUM}    Status            Status of the delivery.
	* @property {Message} Message           Message is not guaranteed to contain any useful fields., But may in some cases store the sender's ID for the, message or contain the original message as sent by the, sender.
	*
	* @typedef  {Object}  Nothing
	*
	*/
	UpdateDeliveryStatus({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.post(`/v1/sms/dlr`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('UpdateDeliveryStatus ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('UpdateDeliveryStatus ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('UpdateDeliveryStatus ERROR:', error)
					return Promise.reject(error)
				}
				console.log('UpdateDeliveryStatus UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in UpdateDeliveryStatus'))
			})
	},

	/**
	* RPC Method: UpdateModicaDeliveryStatus
	* Description: Modica DLR callback
	* HTTP Method: POST
	* API Path: /${req.SPID}/callback/modica/dlr
	* @param    {ModicaDeliveryStatus} req The API payload object (ModicaDeliveryStatus)
	* @return   {Nothing} The API response object (Nothing)
	*
	* @typedef  {Object}  ModicaDeliveryStatus
	* @property {String}  MessageID 
	* @property {ENUM}    Status    
	* @property {String}  Reference 
	* @property {Number}  SPID      
	*
	* @typedef  {Object}  Nothing
	*
	*/
	UpdateModicaDeliveryStatus({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.post(`/${req.SPID}/callback/modica/dlr`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('UpdateModicaDeliveryStatus ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('UpdateModicaDeliveryStatus ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('UpdateModicaDeliveryStatus ERROR:', error)
					return Promise.reject(error)
				}
				console.log('UpdateModicaDeliveryStatus UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in UpdateModicaDeliveryStatus'))
			})
	},
};

export default {
	namespaced: true,
	state: initialState,
	getters,
	mutations,
	actions,
};