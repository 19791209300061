// Code generated by protoc-gen-iqwebapi. DO NOT EDIT.
// source: location.proto

import axios from 'axios';
import StoreHash from '@/utils/StoreHash';

const initialState = {
	CustomerAddressEntities: {
		UUIDs: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
	IQLocations: {
		UUIDs: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
};

const valFuncFind = function (status) {
	return (status == 200) ? true : (status == 404) ? true : false;
}

const default_pageinfo = {
	PageNumber: 0,
	PageItemCount: 0,
	TotalPages: 0,
	TotalItemCount: 0,
	IndexStart: 0,
	PageSize: 10,
	DisplayPageList: [],
	DisplayPageEitherSide: 4,
}

const getters = {
	getCustomerAddressEntityByUUID: state => (UUID) => (state.CustomerAddressEntities.UUIDs[ UUID ] && !state.CustomerAddressEntities.UUIDs[ UUID ].expired()) ?  state.CustomerAddressEntities.UUIDs[ UUID ].hashedData() : null,
	getIQLocationByUUID: state => (UUID) => (state.IQLocations.UUIDs[ UUID ] && !state.IQLocations.UUIDs[ UUID ].expired()) ?  state.IQLocations.UUIDs[ UUID ].hashedData() : null,
};

const mutations = {
	// mutSaveCustomerAddressEntity saves a single CustomerAddressEntity object
	// into both the StoreHash cache and the VueX store
	mutSaveCustomerAddressEntity(state, obj) {
		if (!obj.UUID) { return } // Must have UUID to Save

		if (!(obj.UUID in state.CustomerAddressEntities.UUIDs)) {
			state.CustomerAddressEntities.UUIDs[ obj.UUID ] = new StoreHash(state.CustomerAddressEntities.lifetime)
		}
		state.CustomerAddressEntities.UUIDs[ obj.UUID ].fix(obj) // Add item to StoreHash
	},
	// mutRemoveCustomerAddressEntityByUUID removes a CustomerAddressEntity object
	// with the given UUID from StoreHash cache and VueX store
	mutRemoveCustomerAddressEntityByUUID(state, UUID) {
		if (!UUID) {
			// Must have UUID to Remove
			return
		}
		delete state.CustomerAddressEntities.UUIDs[ UUID ]    // remove item from StoreHash
	},
	// mutSaveCustomerAddressEntities saves a paginated response of CustomerAddressEntities
	mutSaveCustomerAddressEntities(state, { Response }) {
		if (!Response || !Array.isArray(Response.CustomerAddressEntities)) {
			// Must have Response object with results
			return
		}
		for (let i = 0; i < Response.CustomerAddressEntities.length; i++) {
			if (!(Response.CustomerAddressEntities[i].UUID in state.CustomerAddressEntities.UUIDs)) {
				state.CustomerAddressEntities.UUIDs[ Response.CustomerAddressEntities[i].UUID ] = new StoreHash(state.CustomerAddressEntities.lifetime)
			}
			state.CustomerAddressEntities.UUIDs[ Response.CustomerAddressEntities[i].UUID ].fix(Response.CustomerAddressEntities[i])  // Add single CustomerAddressEntity to StoreHash
		}
	},
	// mutSaveIQLocation saves a single IQLocation object
	// into both the StoreHash cache and the VueX store
	mutSaveIQLocation(state, obj) {
		if (!obj.UUID) { return } // Must have UUID to Save

		if (!(obj.UUID in state.IQLocations.UUIDs)) {
			state.IQLocations.UUIDs[ obj.UUID ] = new StoreHash(state.IQLocations.lifetime)
		}
		state.IQLocations.UUIDs[ obj.UUID ].fix(obj) // Add item to StoreHash
	},
	// mutRemoveIQLocationByUUID removes a IQLocation object
	// with the given UUID from StoreHash cache and VueX store
	mutRemoveIQLocationByUUID(state, UUID) {
		if (!UUID) {
			// Must have UUID to Remove
			return
		}
		delete state.IQLocations.UUIDs[ UUID ]    // remove item from StoreHash
	},
};

const actions = {
	// getCachedCustomerAddressEntityByUUID fetches a CustomerAddressEntity from the cache
	// and if not in the cache, fetches it from the API
	getCachedCustomerAddressEntityByUUID({ state, dispatch, commit }, { UUID, RefreshCache }) {
		if (!RefreshCache && state.CustomerAddressEntities.UUIDs[ UUID ] && !state.CustomerAddressEntities.UUIDs[ UUID ].expired()) {
			// Return the cached record
			return state.CustomerAddressEntities.UUIDs[ UUID ].hashedData()
		}
		// Fetch from the API
		return dispatch('GetCustomerAddressEntityByUUID', { UUID }).then(respPayload => {
			commit('mutSaveCustomerAddressEntity', respPayload)
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveCustomerAddressEntityByUUID', UUID)
			return Promise.reject(error)
		})
	},

	// getCachedIQLocationByUUID fetches a IQLocation from the cache
	// and if not in the cache, fetches it from the API
	getCachedIQLocationByUUID({ state, dispatch, commit }, { UUID, RefreshCache }) {
		if (!RefreshCache && state.IQLocations.UUIDs[ UUID ] && !state.IQLocations.UUIDs[ UUID ].expired()) {
			// Return the cached record
			return state.IQLocations.UUIDs[ UUID ].hashedData()
		}
		// Fetch from the API
		return dispatch('GetIQLocationByUUID', { UUID }).then(respPayload => {
			commit('mutSaveIQLocation', respPayload)
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveIQLocationByUUID', UUID)
			return Promise.reject(error)
		})
	},

	//
	// Service: CustomerAddressRPC
	// 
	/**
	* DeleteCustomerAddressEntity: delete customer address entity via customer address UUID
	* HTTP Method: DELETE
	* API Path: /api/v2/${req.SPID}/customer/${req.CustomerUUID}/location/address/${req.UUID}
	* @param    {DeleteCustomerAddressEntityRequest} req The API payload object (DeleteCustomerAddressEntityRequest)
	* @return   {CustomerAddressEntity} The API response object (CustomerAddressEntity)
	*
	* @typedef  {Object}  DeleteCustomerAddressEntityRequest
	* @property {String}  UUID         mandatory
	* @property {Number}  SPID         mandatory
	* @property {String}  CustomerUUID mandatory
	*
	* @typedef  {Object}  CustomerAddressEntity
	* @property {String}  UUID                
	* @property {Number}  SPID                Service Provider ID
	* @property {String}  CustomerUUID        foreign key map back to customer table
	* @property {ENUM}    AddressType         Type of Address
	* @property {String}  GooglePlaceID       Google PlaceID
	* @property {Number}  SlamID              given by vodafone - unique
	* @property {Number}  SamID               given by vodafone - unique
	* @property {Number}  DPID                given by vodafone - unique
	* @property {Number}  CreatedAtNanos      timestamp on creation
	* @property {Number}  UpdatedAtNanos      timestamp on update
	* @property {Number}  DeletedAtNanos      timestamp on deletion
	* @property {ENUM}    LocationProvider    indicates which source the address info comes from, e.g. vodafone, google
	* @property {String}  LocationProviderIDX the related indentifier for the address, e.g., if LocationProvider is vodafone-slam, then the LocationProviderIDX will equal to SlamID, if LocationProvider is google, then it will equals to google place id
	* @property {String}  UnitType            e.g. apartment
	* @property {String}  UnitTypeExtra       
	* @property {String}  Number              street number
	* @property {String}  NumberPre           e.g. 1103 in 1103/430 Queen Street
	* @property {String}  NumberPost          e.g. B in 42B Queen Street
	* @property {String}  StreetName          
	* @property {String}  StreetType          e.g. avenue
	* @property {String}  Suburb              
	* @property {String}  City                
	* @property {String}  State               
	* @property {String}  Country             
	* @property {String}  PostCode            
	* @property {String}  Formatted           well-formatted address string based on the whole address structure
	* @property {String}  FormattedOriginal   raw address info retrieved from address provider
	* @property {String}  Notes               
	* @property {String}  Latitude            
	* @property {String}  Longitude           
	* @property {Number}  Rank                
	* @property {Number}  Score               
	*
	*/
	DeleteCustomerAddressEntity({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Delete)
		return axios.delete(`/v2/${req.SPID}/customer/${req.CustomerUUID}/location/address/${req.UUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveCustomerAddressEntity', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('DeleteCustomerAddressEntity ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('DeleteCustomerAddressEntity ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('DeleteCustomerAddressEntity ERROR:', error)
					return Promise.reject(error)
				}
				console.log('DeleteCustomerAddressEntity UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in DeleteCustomerAddressEntity'))
			})
	},

	/**
	* GetCustomerAddressEntitiesPaginated: Get all the address records belongs to one customer
	* HTTP Method: GET
	* API Path: /api/v2/${req.SPID}/customer/${req.CustomerUUID}/location/addresses
	* @param    {CustomerAddressEntitySearchRequest} req The API payload object (CustomerAddressEntitySearchRequest)
	* @return   {CustomerAddressEntityPageResponse} The API response object (CustomerAddressEntityPageResponse)
	*
	* @typedef  {Object}  CustomerAddressEntitySearchRequest
	* @property {SearchOptions[]} SearchOptions       Search options to use (for sorting and/or pagination)
	* @property {Boolean} IncludeDeleted      
	* @property {Number}  SPID                
	* @property {String}  CustomerUUID        
	* @property {ENUM}    AddressType         Type of Address
	* @property {ENUM}    LocationProvider    indicates which source the address info comes from, e.g. vodafone, google
	* @property {String}  LocationProviderIDX the related indentifier for the address, e.g., if LocationProvider is vodafone-slam, then the LocationProviderIDX will equal to SlamID, if LocationProvider is google, then it will equals to google place id
	*
	* @typedef  {Object}  SearchOptions  Search options
	* @property {Number}  PageNumber     The page number to start at (or provide IndexStart)
	* @property {Number}  PageSize       The page size
	* @property {Number}  IndexStart     The record number to start at (or provide PageNumber)
	* @property {String}  SortBy         The field to sort by
	* @property {Boolean} SortDesc       Is the sort direction descending?
	*
	* @typedef  {Object}  CustomerAddressEntityPageResponse
	* @property {PageInfo} PageInfo                PageInfo only needs the values ThisPageNumber && PageSize set
	* @property {CustomerAddressEntity[]} CustomerAddressEntities 
	*
	* @typedef  {Object}   PageInfo
	* @property {Number}   PageNumber             The page number
	* @property {Number}   PageItemCount          Number of items on this page
	* @property {Number}   TotalPages             Total pages available
	* @property {Number}   TotalItemCount         Total items available
	* @property {Number}   IndexStart             The record number this search started at
	* @property {Number}   PageSize               The length of the page
	* @property {Number[]} DisplayPageList        The array of page numbers to display
	* @property {Number}   DisplayPagesEitherSide Number of pages to display either side
	*
	*/
	GetCustomerAddressEntitiesPaginated({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Find)
		return axios.get(`/v2/${req.SPID}/customer/${req.CustomerUUID}/location/addresses`, { params: req, validateStatus: valFuncFind }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			commit('mutSaveCustomerAddressEntities', { Response: response.data })
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetCustomerAddressEntitiesPaginated ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetCustomerAddressEntitiesPaginated ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetCustomerAddressEntitiesPaginated ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetCustomerAddressEntitiesPaginated UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetCustomerAddressEntitiesPaginated'))
			})
	},

	/**
	* GetCustomerAddressEntityByUUID: Get a specific address record belonging to one customer
	* HTTP Method: GET
	* API Path: /api/v2/${req.SPID}/customer/${req.CustomerUUID}/location/addresses/${req.UUID}
	* @param    {CustomerAddressEntityUUIDSearch} req The API payload object (CustomerAddressEntityUUIDSearch)
	* @return   {CustomerAddressEntity} The API response object (CustomerAddressEntity)
	*
	* @typedef  {Object}  CustomerAddressEntityUUIDSearch
	* @property {Number}  SPID         
	* @property {String}  UUID         
	* @property {String}  CustomerUUID 
	*
	* @typedef  {Object}  CustomerAddressEntity
	* @property {String}  UUID                
	* @property {Number}  SPID                Service Provider ID
	* @property {String}  CustomerUUID        foreign key map back to customer table
	* @property {ENUM}    AddressType         Type of Address
	* @property {String}  GooglePlaceID       Google PlaceID
	* @property {Number}  SlamID              given by vodafone - unique
	* @property {Number}  SamID               given by vodafone - unique
	* @property {Number}  DPID                given by vodafone - unique
	* @property {Number}  CreatedAtNanos      timestamp on creation
	* @property {Number}  UpdatedAtNanos      timestamp on update
	* @property {Number}  DeletedAtNanos      timestamp on deletion
	* @property {ENUM}    LocationProvider    indicates which source the address info comes from, e.g. vodafone, google
	* @property {String}  LocationProviderIDX the related indentifier for the address, e.g., if LocationProvider is vodafone-slam, then the LocationProviderIDX will equal to SlamID, if LocationProvider is google, then it will equals to google place id
	* @property {String}  UnitType            e.g. apartment
	* @property {String}  UnitTypeExtra       
	* @property {String}  Number              street number
	* @property {String}  NumberPre           e.g. 1103 in 1103/430 Queen Street
	* @property {String}  NumberPost          e.g. B in 42B Queen Street
	* @property {String}  StreetName          
	* @property {String}  StreetType          e.g. avenue
	* @property {String}  Suburb              
	* @property {String}  City                
	* @property {String}  State               
	* @property {String}  Country             
	* @property {String}  PostCode            
	* @property {String}  Formatted           well-formatted address string based on the whole address structure
	* @property {String}  FormattedOriginal   raw address info retrieved from address provider
	* @property {String}  Notes               
	* @property {String}  Latitude            
	* @property {String}  Longitude           
	* @property {Number}  Rank                
	* @property {Number}  Score               
	*
	*/
	GetCustomerAddressEntityByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Get)
		return axios.get(`/v2/${req.SPID}/customer/${req.CustomerUUID}/location/addresses/${req.UUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveCustomerAddressEntity', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetCustomerAddressEntityByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetCustomerAddressEntityByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetCustomerAddressEntityByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetCustomerAddressEntityByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetCustomerAddressEntityByUUID'))
			})
	},

	/**
	* SaveCustomerAddressEntity: save the customer address entity
	* HTTP Method: POST
	* API Path: /api/v2/${req.SPID}/customer/${req.CustomerUUID}/location/address
	* @param    {CustomerAddressEntity} req The API payload object (CustomerAddressEntity)
	* @return   {CustomerAddressEntity} The API response object (CustomerAddressEntity)
	*
	* @typedef  {Object}  CustomerAddressEntity
	* @property {String}  UUID                
	* @property {Number}  SPID                Service Provider ID
	* @property {String}  CustomerUUID        foreign key map back to customer table
	* @property {ENUM}    AddressType         Type of Address
	* @property {String}  GooglePlaceID       Google PlaceID
	* @property {Number}  SlamID              given by vodafone - unique
	* @property {Number}  SamID               given by vodafone - unique
	* @property {Number}  DPID                given by vodafone - unique
	* @property {Number}  CreatedAtNanos      timestamp on creation
	* @property {Number}  UpdatedAtNanos      timestamp on update
	* @property {Number}  DeletedAtNanos      timestamp on deletion
	* @property {ENUM}    LocationProvider    indicates which source the address info comes from, e.g. vodafone, google
	* @property {String}  LocationProviderIDX the related indentifier for the address, e.g., if LocationProvider is vodafone-slam, then the LocationProviderIDX will equal to SlamID, if LocationProvider is google, then it will equals to google place id
	* @property {String}  UnitType            e.g. apartment
	* @property {String}  UnitTypeExtra       
	* @property {String}  Number              street number
	* @property {String}  NumberPre           e.g. 1103 in 1103/430 Queen Street
	* @property {String}  NumberPost          e.g. B in 42B Queen Street
	* @property {String}  StreetName          
	* @property {String}  StreetType          e.g. avenue
	* @property {String}  Suburb              
	* @property {String}  City                
	* @property {String}  State               
	* @property {String}  Country             
	* @property {String}  PostCode            
	* @property {String}  Formatted           well-formatted address string based on the whole address structure
	* @property {String}  FormattedOriginal   raw address info retrieved from address provider
	* @property {String}  Notes               
	* @property {String}  Latitude            
	* @property {String}  Longitude           
	* @property {Number}  Rank                
	* @property {Number}  Score               
	*
	*/
	SaveCustomerAddressEntity({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Update)
		return axios.post(`/v2/${req.SPID}/customer/${req.CustomerUUID}/location/address`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveCustomerAddressEntity', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('SaveCustomerAddressEntity ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('SaveCustomerAddressEntity ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('SaveCustomerAddressEntity ERROR:', error)
					return Promise.reject(error)
				}
				console.log('SaveCustomerAddressEntity UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in SaveCustomerAddressEntity'))
			})
	},

	//
	// Service: LocationRPC
	// 
	/**
	* FindAddress: Search for a location via a variety of methods
	* HTTP Method: GET
	* API Path: /api/v2/${req.SPID}/location/search
	* @param    {LocationSearchRequest} req The API payload object (LocationSearchRequest)
	* @return   {LocationSearchResponse} The API response object (LocationSearchResponse)
	*
	* @typedef  {Object}  LocationSearchRequest
	* @property {Number}  SPID              mandatory
	* @property {String}  AddressText       the address text to search for
	* @property {String}  GeoLat            the geo lat to search for
	* @property {String}  GeoLong           the geo long to search for
	* @property {ENUM}    LocationProviders indicates which location engines to search in. [google,vodafone-slam,eaglegis]
	* @property {Boolean} SearchAll         indicates which search all LocationProviders (true), or (by default=false), to return as soon as a location engine is able to respond
	*
	* @typedef  {Object}  LocationSearchResponse
	* @property {IQLocation[]} Locations search result - available and selectable address list (each element comes with SlamID and DisplayAddress)
	*
	*/
	FindAddress({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.get(`/v2/${req.SPID}/location/search`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('FindAddress ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('FindAddress ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('FindAddress ERROR:', error)
					return Promise.reject(error)
				}
				console.log('FindAddress UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in FindAddress'))
			})
	},

	/**
	* FindAddressByLocationProviderIDX: Search for a location by LocationProvider and LocationProviderIDX
	* HTTP Method: GET
	* API Path: /api/v2/${req.SPID}/location/${req.LocationProvider}/${req.LocationProviderIDX}
	* @param    {LocationSearchByLocationIDXRequest} req The API payload object (LocationSearchByLocationIDXRequest)
	* @return   {LocationSearchResponse} The API response object (LocationSearchResponse)
	*
	* @typedef  {Object}  LocationSearchByLocationIDXRequest
	* @property {Number}  SPID                mandatory
	* @property {ENUM}    LocationProvider    e.g. google, vodafone-slamid
	* @property {String}  LocationProviderIDX e.g. if LocationProvider is vodafone-slamid, then the LocationProviderIDX will equal to SlamID, if LocationProvider is google, then it will equals to google place id
	*
	* @typedef  {Object}  LocationSearchResponse
	* @property {IQLocation[]} Locations search result - available and selectable address list (each element comes with SlamID and DisplayAddress)
	*
	*/
	FindAddressByLocationProviderIDX({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.get(`/v2/${req.SPID}/location/${req.LocationProvider}/${req.LocationProviderIDX}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('FindAddressByLocationProviderIDX ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('FindAddressByLocationProviderIDX ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('FindAddressByLocationProviderIDX ERROR:', error)
					return Promise.reject(error)
				}
				console.log('FindAddressByLocationProviderIDX UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in FindAddressByLocationProviderIDX'))
			})
	},

	//
	// Service: ServiceAvailabilityRPC
	// 
	/**
	* GetServiceAvailability: check the available services for a location via SlamID or Latitude&Longitude
	* HTTP Method: GET
	* API Path: /api/v2/${req.SPID}/service_availability/${req.LocationProvider}/${req.LocationProviderIDX}
	* @param    {GetServiceAvailabilityRequest} req The API payload object (GetServiceAvailabilityRequest)
	* @return   {GetServiceAvailabilityResponse} The API response object (GetServiceAvailabilityResponse)
	*
	* @typedef  {Object}  GetServiceAvailabilityRequest
	* @property {Number}  SPID                mandatory
	* @property {ENUM}    LocationProvider    indicates which source the address info comes from, e.g. vodafone, google
	* @property {String}  LocationProviderIDX the related indentifier for the address, e.g., if LocationProvider is vodafone-slam, then the LocationProviderIDX will equal to SlamID, if LocationProvider is google, then it will equals to google place id
	* @property {String}  GeoLat              the geo lat to search for
	* @property {String}  GeoLong             the geo long to search for
	*
	* @typedef  {Object}  GetServiceAvailabilityResponse
	* @property {Service[]} Services available services
	* @property {IQLocation} Location structured address entity
	*
	*/
	GetServiceAvailability({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.get(`/v2/${req.SPID}/service_availability/${req.LocationProvider}/${req.LocationProviderIDX}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetServiceAvailability ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetServiceAvailability ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetServiceAvailability ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetServiceAvailability UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetServiceAvailability'))
			})
	},
};

export default {
	namespaced: true,
	state: initialState,
	getters,
	mutations,
	actions,
};