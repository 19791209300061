const $tablet_size = 1024               // $tablet-size: 1024px;
const $mobile_size = 480                // $mobile-size: 480px;

const $container_padding        = 30    // $container-padding: 30px;
const $container_padding_tablet = 30    // $container-padding-tablet: 30px;
const $container_padding_mobile = 15    // $container-padding-mobile: 15px;

const $table_tag_types = [
    'acknowledged',
    'completed',
    'success',
    'inprogress',
    'held',
    'info',
    'pending',
    'warning',
    'cancelled',
    'danger',
]

const $table_tag_titles = {
    'requiresnewrfstime': 'Book RFS',
    'requiressitevisitscopebooking': 'Book Scoping Visit',
    'requiressitevisitinstallbooking': 'Book Install Visit',
    'requiressitevisitinstallcsebooking': 'Book Install & CSE',
    'requiressitevisitcsebooking': 'Book CSE Visit',
    'requiressitevisitchangeontbooking': 'Book Change ONT Visit',
    'requiressitevisitreplaceontbooking': 'Book Site Visit',
}

const state = {}

const getters = {
    $tablet_size: () => $tablet_size,
    $mobile_size: () => $mobile_size,

    $container_padding:        () => $container_padding,
    $container_padding_tablet: () => $container_padding_tablet,
    $container_padding_mobile: () => $container_padding_mobile,

    $table_tag_types: () => $table_tag_types,
    $table_tag_titles: () => $table_tag_titles,
}

const mutations = {}

const actions = {}

export default {
    state,
    getters,
    mutations,
    actions,
}