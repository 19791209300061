import axios from 'axios'
import axiosUnique from '@/utils/axiosUnique'

import StoreHash from '@/utils/StoreHash'

let hashed = {
    providers: {
        spids: {},
        lifetime: 1 * 60 * 1000, // 1 min
    },
    providers_all: new StoreHash(1 * 60 * 1000), // 1 min
    providers_features: new StoreHash(5 * 60 * 1000), // 5 min
}

const state = {
    providers: {}, // remove
    providers_features: [],
    providers_filter: null,
}

const getters = {
    providers: state => state.providers,
    providers_spids: state => state.providers,
    providers_uuids: state => {
        let providers = {}

        for (const spid in state.providers) {
            const provider = state.providers[spid]

            providers[ provider.UUID ] = provider
        }

        return providers
    },
    providers_features: state => state.providers_features,

    providers_filter: state => state.providers_filter,
}

const mutations = {
    saveProviders(state, { providers, is_all }) {
        // console.group('saveProviders(state, { providers, is_all }) {')
        // console.log('providers', providers)
        // console.log('is_all', is_all)

        let providers_list = {}

        for (let n = 0, len = providers.length; n < len; n++) {
            const provider = providers[n]
            const spid = provider.SPID

            if (!(spid in hashed.providers.spids)) {
                hashed.providers.spids[ spid ] = new StoreHash(hashed.providers.lifetime)
            }
            
            providers_list[ spid ] = provider

            hashed.providers.spids[ spid ].fix()
        }
        // console.log('providers_list', providers_list)

        if (is_all) {
            state.providers = providers_list
            hashed.providers_all.fix()
        } else {
            if (Object.keys(providers_list).length) {
                state.providers = {
                    ...state.providers,
                    ...providers_list
                }
            }
        }

        // console.groupEnd()
    },

    saveProvider(state, provider) {
        const spid = provider.SPID

        if (!(spid in hashed.providers.spids)) {
            hashed.providers.spids[ spid ] = new StoreHash(hashed.providers.lifetime)
        }

        state.providers = {
            ...state.providers,
            [ spid ]: provider
        }

        hashed.providers.spids[ spid ].fix()
    },

    saveProvidersFeatures(state, features) {
        let providers_features = {}

        for (let i = 0, len = features.length; i < len; i++) {
            const feature = features[i]

            providers_features[ feature.Name ] = feature
        }

        state.providers_features = providers_features
        // console.log('saveProvidersFeatures:', providers_features)
    },

    saveProvidersFilter(state, filter) {
        state.providers_filter = filter
    },    
    clearProvidersFilter(state) {
        state.providers_filter = null
    },

    resetHashedProviders(state) {
        state.providers = {}
        state.providers_features = {}

        hashed.providers.spids = {}
        hashed.providers_all.reset()
        hashed.providers_features.reset()
    },
}

const actions = {
    getProvidersAll({getters, dispatch}) {
        if (hashed.providers_all.expired()) {
            const params = {
                'SearchOptions.PageSize': -1
            }

            return dispatch('getProviders', { params, is_all: true })
                .then(() => Promise.resolve(getters.providers))
                .catch(error => Promise.reject(error))
        } else {
            return Promise.resolve(getters.providers)
        }
    },

    getProviders({commit}, { params, is_all }) {
        return axiosUnique.get('/v3/service_provider', { params }).then(response => {
            const providers = response.data.ServiceProviders

            commit('saveProviders', { providers, is_all })
            
            return Promise.resolve(response)
        }).catch(error => {
            return Promise.reject(error)
        })
    },

    getProvidersFeatures({getters, commit}) {
        /**
         * @todo Replace 2 corresponding lines below for real data
         */
        return import('./hardcoded-test-data/providers/providers-features.json').then(apidata => {
            const features = apidata.default

        // return axiosUnique.get(`/v2/:spid/providers/features`).then(({apidata}) => {
        //     const features = response.apidata.Features

            commit('saveProvidersFeatures', features)

            return Promise.resolve(getters.providers_features)
        }).catch(error => {
            return Promise.reject(error)
        })
    },

    loadProvidersFilter({getters}) {
        const filter = getters.providers_filter

        return filter
            ? Promise.resolve(filter)
            : Promise.reject(filter)
    },
    saveProvidersFilter({commit, getters}, filter) {
        commit('saveProvidersFilter', filter)
        return Promise.resolve(getters.providers_filter)
    },
    clearProvidersFilter({commit, getters}) {
        commit('clearProvidersFilter')
        return Promise.resolve(getters.providers_filter)
    },



    addProviderUser({commit}, { spid, paiload }) {
        /**
         * @todo Replace for real data
         */
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve(true)
            }, 500)
        })

        return axios.post(`/v2/:spid/provider/${ spid }/users`, payload).then(({apidata}) => {
            return Promise.resolve(apidata)
        }).catch(error => {
            return Promise.reject(error)
        })
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
}