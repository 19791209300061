// Code generated by protoc-gen-iqwebapi. DO NOT EDIT.
// source: matrixx.proto

import axios from 'axios';
import StoreHash from '@/utils/StoreHash';

const initialState = {
	MtxDeviceSearches: {},
	MtxGroupSearches: {},
	MtxSubscriptionSearches: {},
	MtxUserSearches: {},
};

const valFuncFind = function (status) {
	return (status == 200) ? true : (status == 404) ? true : false;
}

const default_pageinfo = {
	PageNumber: 0,
	PageItemCount: 0,
	TotalPages: 0,
	TotalItemCount: 0,
	IndexStart: 0,
	PageSize: 10,
	DisplayPageList: [],
	DisplayPageEitherSide: 4,
}

let hashed = {
	MtxDevices: {
		UUIDs: {},
		lifetime: 1 * 60 * 1000, // 1 minute
	},
	MtxDeviceSearches: {
		SearchTexts: {},
		lifetime: 1 * 60 * 1000, // 1 minute
	},
	MtxGroups: {
		UUIDs: {},
		lifetime: 1 * 60 * 1000, // 1 minute
	},
	MtxGroupSearches: {
		SearchTexts: {},
		lifetime: 1 * 60 * 1000, // 1 minute
	},
	MtxSubscriptions: {
		UUIDs: {},
		lifetime: 1 * 60 * 1000, // 1 minute
	},
	MtxSubscriptionSearches: {
		SearchTexts: {},
		lifetime: 1 * 60 * 1000, // 1 minute
	},
	MtxUsers: {
		UUIDs: {},
		lifetime: 1 * 60 * 1000, // 1 minute
	},
	MtxUserSearches: {
		SearchTexts: {},
		lifetime: 1 * 60 * 1000, // 1 minute
	},
}

const getters = {
	getMtxDeviceByUUID: hashed => (UUID) => (hashed.MtxDevices.UUIDs[ UUID ] && !hashed.MtxDevices.UUIDs[ UUID ].expired()) ?  hashed.MtxDevices.UUIDs[ UUID ].hashedData() : null,
	getMtxDevicesForSearchText: state => (SearchText) => state.MtxDeviceSearches[ SearchText ] ? state.MtxDeviceSearches[ SearchText ] : null,
	getMtxGroupByUUID: hashed => (UUID) => (hashed.MtxGroups.UUIDs[ UUID ] && !hashed.MtxGroups.UUIDs[ UUID ].expired()) ?  hashed.MtxGroups.UUIDs[ UUID ].hashedData() : null,
	getMtxGroupsForSearchText: state => (SearchText) => state.MtxGroupSearches[ SearchText ] ? state.MtxGroupSearches[ SearchText ] : null,
	getMtxSubscriptionByUUID: hashed => (UUID) => (hashed.MtxSubscriptions.UUIDs[ UUID ] && !hashed.MtxSubscriptions.UUIDs[ UUID ].expired()) ?  hashed.MtxSubscriptions.UUIDs[ UUID ].hashedData() : null,
	getMtxSubscriptionsForSearchText: state => (SearchText) => state.MtxSubscriptionSearches[ SearchText ] ? state.MtxSubscriptionSearches[ SearchText ] : null,
	getMtxUserByUUID: hashed => (UUID) => (hashed.MtxUsers.UUIDs[ UUID ] && !hashed.MtxUsers.UUIDs[ UUID ].expired()) ?  hashed.MtxUsers.UUIDs[ UUID ].hashedData() : null,
	getMtxUsersForSearchText: state => (SearchText) => state.MtxUserSearches[ SearchText ] ? state.MtxUserSearches[ SearchText ] : null,
};

const mutations = {
	// mutSaveMtxDevice saves a single MtxDevice object
	// into both the StoreHash cache and the VueX store
	mutSaveMtxDevice(state, obj) {
		if (!obj.UUID) { return } // Must have UUID to Save

		if (!(obj.UUID in hashed.MtxDevices.UUIDs)) {
			hashed.MtxDevices.UUIDs[ obj.UUID ] = new StoreHash(hashed.MtxDevices.lifetime)
		}
		hashed.MtxDevices.UUIDs[ obj.UUID ].fix(obj) // Add item to StoreHash
	},
	// mutRemoveMtxDeviceByUUID removes a MtxDevice object
	// with the given UUID from StoreHash cache and VueX store
	mutRemoveMtxDeviceByUUID(state, UUID) {
		if (!UUID) {
			// Must have UUID to Remove
			return
		}
		delete hashed.MtxDevices.UUIDs[ UUID ]    // remove item from StoreHash
	},
	// mutSaveMtxDeviceSearch saves a search response
	// with the given SearchText to the StoreHash cache and VueX store
	mutSaveMtxDeviceSearch(state, { SearchText, Response }) {
		if (!SearchText || !Response) {
			// Must have SearchText to Save and Response object
			return
		}
		let storedObj = {
			PageInfo: Object.assign({}, default_pageinfo), // shallow copy of default_pageinfo
			UUIDs: [],
		}
		// Deep copy ??? i.e. JSON.parse(JSON.stringify(obj));
		if (Response.PageInfo) {
			Response.PageInfo = Object.assign({}, Response.PageInfo) // shallow copy of the PageInfo
		}
		if (Array.isArray(Response.MtxDevices)) {
			for (let i = 0; i < Response.MtxDevices.length; i++) {
				storedObj.UUIDs.push(Response.MtxDevices[i].UUID)
			}
		}
		state.MtxDeviceSearches[ SearchText ] = storedObj      // Add search text and list of UUIDs to VueX state

		if (!(SearchText in hashed.MtxDeviceSearches.SearchTexts)) {
			hashed.MtxDeviceSearches.SearchTexts[ SearchText ] = new StoreHash(hashed.MtxDeviceSearches.lifetime)
		}
		hashed.MtxDeviceSearches.SearchTexts[ SearchText ].fix() // Add item to StoreHash
	},
	// mutRemoveMtxDeviceSearch removes a search response
	// with the given SearchText from StoreHash cache and VueX store
	mutRemoveMtxDeviceSearch(state, SearchText) {
		if (!SearchText) {
			// Must have SearchText to Remove
			return
		}
		state.MtxDeviceSearches[ SearchText ] = null               // cause VueX to trigger a data cascade
		delete state.MtxDeviceSearches[ SearchText ]               // remove item from VueX state
		delete hashed.MtxDeviceSearches.SearchTexts[ SearchText ]  // remove item from StoreHash
	},
	// mutSaveMtxDevices saves a paginated response of MtxDevices
	mutSaveMtxDevices(state, { Response }) {
		if (!Response || !Array.isArray(Response.MtxDevices)) {
			// Must have Response object with results
			return
		}
		for (let i = 0; i < Response.MtxDevices.length; i++) {
			if (!(Response.MtxDevices[i].UUID in hashed.MtxDevices.UUIDs)) {
				hashed.MtxDevices.UUIDs[ Response.MtxDevices[i].UUID ] = new StoreHash(hashed.MtxDevices.lifetime)
			}
			hashed.MtxDevices.UUIDs[ Response.MtxDevices[i].UUID ].fix(Response.MtxDevices[i])  // Add single MtxDevice to StoreHash
		}
	},
	// mutSaveMtxGroup saves a single MtxGroup object
	// into both the StoreHash cache and the VueX store
	mutSaveMtxGroup(state, obj) {
		if (!obj.UUID) { return } // Must have UUID to Save

		if (!(obj.UUID in hashed.MtxGroups.UUIDs)) {
			hashed.MtxGroups.UUIDs[ obj.UUID ] = new StoreHash(hashed.MtxGroups.lifetime)
		}
		hashed.MtxGroups.UUIDs[ obj.UUID ].fix(obj) // Add item to StoreHash
	},
	// mutRemoveMtxGroupByUUID removes a MtxGroup object
	// with the given UUID from StoreHash cache and VueX store
	mutRemoveMtxGroupByUUID(state, UUID) {
		if (!UUID) {
			// Must have UUID to Remove
			return
		}
		delete hashed.MtxGroups.UUIDs[ UUID ]    // remove item from StoreHash
	},
	// mutSaveMtxGroupSearch saves a search response
	// with the given SearchText to the StoreHash cache and VueX store
	mutSaveMtxGroupSearch(state, { SearchText, Response }) {
		if (!SearchText || !Response) {
			// Must have SearchText to Save and Response object
			return
		}
		let storedObj = {
			PageInfo: Object.assign({}, default_pageinfo), // shallow copy of default_pageinfo
			UUIDs: [],
		}
		// Deep copy ??? i.e. JSON.parse(JSON.stringify(obj));
		if (Response.PageInfo) {
			Response.PageInfo = Object.assign({}, Response.PageInfo) // shallow copy of the PageInfo
		}
		if (Array.isArray(Response.MtxGroups)) {
			for (let i = 0; i < Response.MtxGroups.length; i++) {
				storedObj.UUIDs.push(Response.MtxGroups[i].UUID)
			}
		}
		state.MtxGroupSearches[ SearchText ] = storedObj      // Add search text and list of UUIDs to VueX state

		if (!(SearchText in hashed.MtxGroupSearches.SearchTexts)) {
			hashed.MtxGroupSearches.SearchTexts[ SearchText ] = new StoreHash(hashed.MtxGroupSearches.lifetime)
		}
		hashed.MtxGroupSearches.SearchTexts[ SearchText ].fix() // Add item to StoreHash
	},
	// mutRemoveMtxGroupSearch removes a search response
	// with the given SearchText from StoreHash cache and VueX store
	mutRemoveMtxGroupSearch(state, SearchText) {
		if (!SearchText) {
			// Must have SearchText to Remove
			return
		}
		state.MtxGroupSearches[ SearchText ] = null               // cause VueX to trigger a data cascade
		delete state.MtxGroupSearches[ SearchText ]               // remove item from VueX state
		delete hashed.MtxGroupSearches.SearchTexts[ SearchText ]  // remove item from StoreHash
	},
	// mutSaveMtxGroups saves a paginated response of MtxGroups
	mutSaveMtxGroups(state, { Response }) {
		if (!Response || !Array.isArray(Response.MtxGroups)) {
			// Must have Response object with results
			return
		}
		for (let i = 0; i < Response.MtxGroups.length; i++) {
			if (!(Response.MtxGroups[i].UUID in hashed.MtxGroups.UUIDs)) {
				hashed.MtxGroups.UUIDs[ Response.MtxGroups[i].UUID ] = new StoreHash(hashed.MtxGroups.lifetime)
			}
			hashed.MtxGroups.UUIDs[ Response.MtxGroups[i].UUID ].fix(Response.MtxGroups[i])  // Add single MtxGroup to StoreHash
		}
	},
	// mutSaveMtxSubscription saves a single MtxSubscription object
	// into both the StoreHash cache and the VueX store
	mutSaveMtxSubscription(state, obj) {
		if (!obj.UUID) { return } // Must have UUID to Save

		if (!(obj.UUID in hashed.MtxSubscriptions.UUIDs)) {
			hashed.MtxSubscriptions.UUIDs[ obj.UUID ] = new StoreHash(hashed.MtxSubscriptions.lifetime)
		}
		hashed.MtxSubscriptions.UUIDs[ obj.UUID ].fix(obj) // Add item to StoreHash
	},
	// mutRemoveMtxSubscriptionByUUID removes a MtxSubscription object
	// with the given UUID from StoreHash cache and VueX store
	mutRemoveMtxSubscriptionByUUID(state, UUID) {
		if (!UUID) {
			// Must have UUID to Remove
			return
		}
		delete hashed.MtxSubscriptions.UUIDs[ UUID ]    // remove item from StoreHash
	},
	// mutSaveMtxSubscriptionSearch saves a search response
	// with the given SearchText to the StoreHash cache and VueX store
	mutSaveMtxSubscriptionSearch(state, { SearchText, Response }) {
		if (!SearchText || !Response) {
			// Must have SearchText to Save and Response object
			return
		}
		let storedObj = {
			PageInfo: Object.assign({}, default_pageinfo), // shallow copy of default_pageinfo
			UUIDs: [],
		}
		// Deep copy ??? i.e. JSON.parse(JSON.stringify(obj));
		if (Response.PageInfo) {
			Response.PageInfo = Object.assign({}, Response.PageInfo) // shallow copy of the PageInfo
		}
		if (Array.isArray(Response.MtxSubscriptions)) {
			for (let i = 0; i < Response.MtxSubscriptions.length; i++) {
				storedObj.UUIDs.push(Response.MtxSubscriptions[i].UUID)
			}
		}
		state.MtxSubscriptionSearches[ SearchText ] = storedObj      // Add search text and list of UUIDs to VueX state

		if (!(SearchText in hashed.MtxSubscriptionSearches.SearchTexts)) {
			hashed.MtxSubscriptionSearches.SearchTexts[ SearchText ] = new StoreHash(hashed.MtxSubscriptionSearches.lifetime)
		}
		hashed.MtxSubscriptionSearches.SearchTexts[ SearchText ].fix() // Add item to StoreHash
	},
	// mutRemoveMtxSubscriptionSearch removes a search response
	// with the given SearchText from StoreHash cache and VueX store
	mutRemoveMtxSubscriptionSearch(state, SearchText) {
		if (!SearchText) {
			// Must have SearchText to Remove
			return
		}
		state.MtxSubscriptionSearches[ SearchText ] = null               // cause VueX to trigger a data cascade
		delete state.MtxSubscriptionSearches[ SearchText ]               // remove item from VueX state
		delete hashed.MtxSubscriptionSearches.SearchTexts[ SearchText ]  // remove item from StoreHash
	},
	// mutSaveMtxSubscriptions saves a paginated response of MtxSubscriptions
	mutSaveMtxSubscriptions(state, { Response }) {
		if (!Response || !Array.isArray(Response.MtxSubscriptions)) {
			// Must have Response object with results
			return
		}
		for (let i = 0; i < Response.MtxSubscriptions.length; i++) {
			if (!(Response.MtxSubscriptions[i].UUID in hashed.MtxSubscriptions.UUIDs)) {
				hashed.MtxSubscriptions.UUIDs[ Response.MtxSubscriptions[i].UUID ] = new StoreHash(hashed.MtxSubscriptions.lifetime)
			}
			hashed.MtxSubscriptions.UUIDs[ Response.MtxSubscriptions[i].UUID ].fix(Response.MtxSubscriptions[i])  // Add single MtxSubscription to StoreHash
		}
	},
	// mutSaveMtxUser saves a single MtxUser object
	// into both the StoreHash cache and the VueX store
	mutSaveMtxUser(state, obj) {
		if (!obj.UUID) { return } // Must have UUID to Save

		if (!(obj.UUID in hashed.MtxUsers.UUIDs)) {
			hashed.MtxUsers.UUIDs[ obj.UUID ] = new StoreHash(hashed.MtxUsers.lifetime)
		}
		hashed.MtxUsers.UUIDs[ obj.UUID ].fix(obj) // Add item to StoreHash
	},
	// mutRemoveMtxUserByUUID removes a MtxUser object
	// with the given UUID from StoreHash cache and VueX store
	mutRemoveMtxUserByUUID(state, UUID) {
		if (!UUID) {
			// Must have UUID to Remove
			return
		}
		delete hashed.MtxUsers.UUIDs[ UUID ]    // remove item from StoreHash
	},
	// mutSaveMtxUserSearch saves a search response
	// with the given SearchText to the StoreHash cache and VueX store
	mutSaveMtxUserSearch(state, { SearchText, Response }) {
		if (!SearchText || !Response) {
			// Must have SearchText to Save and Response object
			return
		}
		let storedObj = {
			PageInfo: Object.assign({}, default_pageinfo), // shallow copy of default_pageinfo
			UUIDs: [],
		}
		// Deep copy ??? i.e. JSON.parse(JSON.stringify(obj));
		if (Response.PageInfo) {
			Response.PageInfo = Object.assign({}, Response.PageInfo) // shallow copy of the PageInfo
		}
		if (Array.isArray(Response.MtxUsers)) {
			for (let i = 0; i < Response.MtxUsers.length; i++) {
				storedObj.UUIDs.push(Response.MtxUsers[i].UUID)
			}
		}
		state.MtxUserSearches[ SearchText ] = storedObj      // Add search text and list of UUIDs to VueX state

		if (!(SearchText in hashed.MtxUserSearches.SearchTexts)) {
			hashed.MtxUserSearches.SearchTexts[ SearchText ] = new StoreHash(hashed.MtxUserSearches.lifetime)
		}
		hashed.MtxUserSearches.SearchTexts[ SearchText ].fix() // Add item to StoreHash
	},
	// mutRemoveMtxUserSearch removes a search response
	// with the given SearchText from StoreHash cache and VueX store
	mutRemoveMtxUserSearch(state, SearchText) {
		if (!SearchText) {
			// Must have SearchText to Remove
			return
		}
		state.MtxUserSearches[ SearchText ] = null               // cause VueX to trigger a data cascade
		delete state.MtxUserSearches[ SearchText ]               // remove item from VueX state
		delete hashed.MtxUserSearches.SearchTexts[ SearchText ]  // remove item from StoreHash
	},
	// mutSaveMtxUsers saves a paginated response of MtxUsers
	mutSaveMtxUsers(state, { Response }) {
		if (!Response || !Array.isArray(Response.MtxUsers)) {
			// Must have Response object with results
			return
		}
		for (let i = 0; i < Response.MtxUsers.length; i++) {
			if (!(Response.MtxUsers[i].UUID in hashed.MtxUsers.UUIDs)) {
				hashed.MtxUsers.UUIDs[ Response.MtxUsers[i].UUID ] = new StoreHash(hashed.MtxUsers.lifetime)
			}
			hashed.MtxUsers.UUIDs[ Response.MtxUsers[i].UUID ].fix(Response.MtxUsers[i])  // Add single MtxUser to StoreHash
		}
	},
};

const actions = {
	// getCachedMtxDeviceByUUID fetches a MtxDevice from the cache
	// and if not in the cache, fetches it from the API
	getCachedMtxDeviceByUUID({ getters, dispatch, commit }, { UUID, RefreshCache }) {
		if (!RefreshCache && hashed.MtxDevices.UUIDs[ UUID ] && !hashed.MtxDevices.UUIDs[ UUID ].expired()) {
			// Return the cached record
			return hashed.MtxDevices.UUIDs[ UUID ].hashedData()
		}
		// Fetch from the API
		return dispatch('GetMtxDeviceByUUID', { UUID }).then(respPayload => {
			commit('mutSaveMtxDevice', respPayload)
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveMtxDeviceByUUID', UUID)
			return Promise.reject(error)
		})
	},

	// getCachedMtxDevicesBySearch fetches MtxDevices from the cache
	// and if not in the cache, fetches it from the API
	getCachedMtxDevicesBySearch({ getters, dispatch, commit }, { SPID, SearchText, RefreshCache }) {
		if (!RefreshCache && hashed.MtxDeviceSearches.SearchTexts[ SearchText ] && !hashed.MtxDeviceSearches.SearchTexts[ SearchText ].expired()) {
			// Return the cached records
			let resp = getters.getMtxDevicesForSearchText(SearchText)
			resp.MtxDevices = []
			for (let i = 0; i < resp.UUIDs.length; i++) {
				resp.MtxDevices.push(getters.getMtxDeviceByUUID(resp.UUIDs[i]))
			}
			return Promise.resolve(resp)
		}
		// Search using the API
		const apiReq = {
			SPID: SPID,
			SmartSearch: SearchText,
		}
		return dispatch('GetMtxDevicesPaginated', apiReq).then(respPayload => {
			commit('mutSaveMtxDeviceSearch', { SearchText: SearchText, Response: respPayload })
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveMtxDeviceSearch', SearchText)
			return Promise.reject(error)
		})
	},

	// getCachedMtxGroupByUUID fetches a MtxGroup from the cache
	// and if not in the cache, fetches it from the API
	getCachedMtxGroupByUUID({ getters, dispatch, commit }, { UUID, RefreshCache }) {
		if (!RefreshCache && hashed.MtxGroups.UUIDs[ UUID ] && !hashed.MtxGroups.UUIDs[ UUID ].expired()) {
			// Return the cached record
			return hashed.MtxGroups.UUIDs[ UUID ].hashedData()
		}
		// Fetch from the API
		return dispatch('GetMtxGroupByUUID', { UUID }).then(respPayload => {
			commit('mutSaveMtxGroup', respPayload)
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveMtxGroupByUUID', UUID)
			return Promise.reject(error)
		})
	},

	// getCachedMtxGroupsBySearch fetches MtxGroups from the cache
	// and if not in the cache, fetches it from the API
	getCachedMtxGroupsBySearch({ getters, dispatch, commit }, { SPID, SearchText, RefreshCache }) {
		if (!RefreshCache && hashed.MtxGroupSearches.SearchTexts[ SearchText ] && !hashed.MtxGroupSearches.SearchTexts[ SearchText ].expired()) {
			// Return the cached records
			let resp = getters.getMtxGroupsForSearchText(SearchText)
			resp.MtxGroups = []
			for (let i = 0; i < resp.UUIDs.length; i++) {
				resp.MtxGroups.push(getters.getMtxGroupByUUID(resp.UUIDs[i]))
			}
			return Promise.resolve(resp)
		}
		// Search using the API
		const apiReq = {
			SPID: SPID,
			SmartSearch: SearchText,
		}
		return dispatch('GetMtxGroupsPaginated', apiReq).then(respPayload => {
			commit('mutSaveMtxGroupSearch', { SearchText: SearchText, Response: respPayload })
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveMtxGroupSearch', SearchText)
			return Promise.reject(error)
		})
	},

	// getCachedMtxSubscriptionByUUID fetches a MtxSubscription from the cache
	// and if not in the cache, fetches it from the API
	getCachedMtxSubscriptionByUUID({ getters, dispatch, commit }, { UUID, RefreshCache }) {
		if (!RefreshCache && hashed.MtxSubscriptions.UUIDs[ UUID ] && !hashed.MtxSubscriptions.UUIDs[ UUID ].expired()) {
			// Return the cached record
			return hashed.MtxSubscriptions.UUIDs[ UUID ].hashedData()
		}
		// Fetch from the API
		return dispatch('GetMtxSubscriptionByUUID', { UUID }).then(respPayload => {
			commit('mutSaveMtxSubscription', respPayload)
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveMtxSubscriptionByUUID', UUID)
			return Promise.reject(error)
		})
	},

	// getCachedMtxSubscriptionsBySearch fetches MtxSubscriptions from the cache
	// and if not in the cache, fetches it from the API
	getCachedMtxSubscriptionsBySearch({ getters, dispatch, commit }, { SPID, SearchText, RefreshCache }) {
		if (!RefreshCache && hashed.MtxSubscriptionSearches.SearchTexts[ SearchText ] && !hashed.MtxSubscriptionSearches.SearchTexts[ SearchText ].expired()) {
			// Return the cached records
			let resp = getters.getMtxSubscriptionsForSearchText(SearchText)
			resp.MtxSubscriptions = []
			for (let i = 0; i < resp.UUIDs.length; i++) {
				resp.MtxSubscriptions.push(getters.getMtxSubscriptionByUUID(resp.UUIDs[i]))
			}
			return Promise.resolve(resp)
		}
		// Search using the API
		const apiReq = {
			SPID: SPID,
			SmartSearch: SearchText,
		}
		return dispatch('GetMtxSubscriptionsPaginated', apiReq).then(respPayload => {
			commit('mutSaveMtxSubscriptionSearch', { SearchText: SearchText, Response: respPayload })
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveMtxSubscriptionSearch', SearchText)
			return Promise.reject(error)
		})
	},

	// getCachedMtxUserByUUID fetches a MtxUser from the cache
	// and if not in the cache, fetches it from the API
	getCachedMtxUserByUUID({ getters, dispatch, commit }, { UUID, RefreshCache }) {
		if (!RefreshCache && hashed.MtxUsers.UUIDs[ UUID ] && !hashed.MtxUsers.UUIDs[ UUID ].expired()) {
			// Return the cached record
			return hashed.MtxUsers.UUIDs[ UUID ].hashedData()
		}
		// Fetch from the API
		return dispatch('GetMtxUserByUUID', { UUID }).then(respPayload => {
			commit('mutSaveMtxUser', respPayload)
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveMtxUserByUUID', UUID)
			return Promise.reject(error)
		})
	},

	// getCachedMtxUsersBySearch fetches MtxUsers from the cache
	// and if not in the cache, fetches it from the API
	getCachedMtxUsersBySearch({ getters, dispatch, commit }, { SPID, SearchText, RefreshCache }) {
		if (!RefreshCache && hashed.MtxUserSearches.SearchTexts[ SearchText ] && !hashed.MtxUserSearches.SearchTexts[ SearchText ].expired()) {
			// Return the cached records
			let resp = getters.getMtxUsersForSearchText(SearchText)
			resp.MtxUsers = []
			for (let i = 0; i < resp.UUIDs.length; i++) {
				resp.MtxUsers.push(getters.getMtxUserByUUID(resp.UUIDs[i]))
			}
			return Promise.resolve(resp)
		}
		// Search using the API
		const apiReq = {
			SPID: SPID,
			SmartSearch: SearchText,
		}
		return dispatch('GetMtxUsersPaginated', apiReq).then(respPayload => {
			commit('mutSaveMtxUserSearch', { SearchText: SearchText, Response: respPayload })
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveMtxUserSearch', SearchText)
			return Promise.reject(error)
		})
	},

	//
	// Service: MatrixxRPC
	// 
	/**
	* AssignMtxDeviceToMtxSubscription: Assign a Matrixx device to a Matrixx subscription
	* HTTP Method: PUT
	* API Path: /api/v3/${req.SPID}/matrixx_subscription/assign_device
	* @param    {MtxSubscriptionAssignMtxDevice} req The API payload object (MtxSubscriptionAssignMtxDevice)
	* @return   {MtxResponse} The API response object (MtxResponse)
	*
	* @typedef  {Object}  MtxSubscriptionAssignMtxDevice
	* @property {String}  MtxSubscriptionUUID 
	* @property {String}  MtxDeviceUUID       
	* @property {Number}  SPID                
	*
	* @typedef  {Object}  MtxResponse
	* @property {Number}  Result     
	* @property {String}  ResultText 
	*
	*/
	AssignMtxDeviceToMtxSubscription({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.put(`/v3/${req.SPID}/matrixx_subscription/assign_device`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('AssignMtxDeviceToMtxSubscription ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('AssignMtxDeviceToMtxSubscription ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('AssignMtxDeviceToMtxSubscription ERROR:', error)
					return Promise.reject(error)
				}
				console.log('AssignMtxDeviceToMtxSubscription UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in AssignMtxDeviceToMtxSubscription'))
			})
	},

	/**
	* AssignMtxGroupToMtxGroup: Assign a Matrixx Group to a Matrixx Group
	* HTTP Method: PUT
	* API Path: /api/v3/${req.SPID}/matrixx_group/assign_group
	* @param    {MtxGroupAssignMtxGroup} req The API payload object (MtxGroupAssignMtxGroup)
	* @return   {MtxResponse} The API response object (MtxResponse)
	*
	* @typedef  {Object}  MtxGroupAssignMtxGroup
	* @property {String}  ParentMtxGroupUUID 
	* @property {String}  MtxGroupUUID       
	* @property {Number}  SPID               
	*
	* @typedef  {Object}  MtxResponse
	* @property {Number}  Result     
	* @property {String}  ResultText 
	*
	*/
	AssignMtxGroupToMtxGroup({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.put(`/v3/${req.SPID}/matrixx_group/assign_group`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('AssignMtxGroupToMtxGroup ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('AssignMtxGroupToMtxGroup ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('AssignMtxGroupToMtxGroup ERROR:', error)
					return Promise.reject(error)
				}
				console.log('AssignMtxGroupToMtxGroup UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in AssignMtxGroupToMtxGroup'))
			})
	},

	/**
	* AssignMtxSubscriptionToMtxGroup: Assign a Matrixx Subscription to a Matrixx Group
	* HTTP Method: PUT
	* API Path: /api/v3/${req.SPID}/matrixx_group/assign_subscription
	* @param    {MtxGroupAssignMtxSubscription} req The API payload object (MtxGroupAssignMtxSubscription)
	* @return   {MtxResponse} The API response object (MtxResponse)
	*
	* @typedef  {Object}  MtxGroupAssignMtxSubscription
	* @property {String}  MtxGroupUUID        
	* @property {String}  MtxSubscriptionUUID 
	* @property {Number}  SPID                
	*
	* @typedef  {Object}  MtxResponse
	* @property {Number}  Result     
	* @property {String}  ResultText 
	*
	*/
	AssignMtxSubscriptionToMtxGroup({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.put(`/v3/${req.SPID}/matrixx_group/assign_subscription`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('AssignMtxSubscriptionToMtxGroup ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('AssignMtxSubscriptionToMtxGroup ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('AssignMtxSubscriptionToMtxGroup ERROR:', error)
					return Promise.reject(error)
				}
				console.log('AssignMtxSubscriptionToMtxGroup UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in AssignMtxSubscriptionToMtxGroup'))
			})
	},

	/**
	* AssignMtxSubscriptionToMtxUser: Assigns a subscription to a user
	* HTTP Method: POST
	* API Path: /api/v3/${req.SPID}/matrixx_user/assign_mtx_subscription
	* @param    {MtxUserAssignMtxSubscription} req The API payload object (MtxUserAssignMtxSubscription)
	* @return   {MtxResponse} The API response object (MtxResponse)
	*
	* @typedef  {Object}  MtxUserAssignMtxSubscription
	* @property {String}  MtxUserUUID         
	* @property {String}  MtxSubscriptionUUID 
	* @property {MtxPricingRole[]} RoleInfoArray       
	* @property {Number}  SPID                
	*
	* @typedef  {Object}  MtxResponse
	* @property {Number}  Result     
	* @property {String}  ResultText 
	*
	*/
	AssignMtxSubscriptionToMtxUser({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.post(`/v3/${req.SPID}/matrixx_user/assign_mtx_subscription`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('AssignMtxSubscriptionToMtxUser ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('AssignMtxSubscriptionToMtxUser ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('AssignMtxSubscriptionToMtxUser ERROR:', error)
					return Promise.reject(error)
				}
				console.log('AssignMtxSubscriptionToMtxUser UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in AssignMtxSubscriptionToMtxUser'))
			})
	},

	/**
	* CreateNewMtxDevice: Add a Matrixx Device object
	* HTTP Method: POST
	* API Path: /api/v3/${req.SPID}/matrixx_device/create
	* @param    {MtxDevice} req The API payload object (MtxDevice)
	* @return   {MtxDevice} The API response object (MtxDevice)
	*
	* @typedef  {Object}  MtxDevice
	* @property {String}  UUID             
	* @property {Number}  SPID             Default Service Provider ID
	* @property {Number}  CreatedAtNanos   When the object was first created
	* @property {Number}  UpdatedAtNanos   When the object was last updated
	* @property {Number}  DeletedAtNanos   When the object was deleted
	* @property {Number}  IMEI             
	* @property {Number}  IMSI             
	* @property {StringSlice} AccessNumbers    Can this use this format
	* @property {String}  MtxObjectID      MtxResponseDevice MtxResponseDevice = 10 [json_name= "MtxResponseDevice", (tagger.tags) = "json:\"MtxResponseDevice,omitempty\" db:\"MtxResponseDevice\" gorm:\"Column:MtxResponseDevice;NOT NULL\" bson:\"MtxResponseDevice\" csv:\"MtxResponseDevice\" query:\"MtxResponseDevice\" xml:\"MtxResponseDevice\" dynamodb:\"MtxResponseDevice\" datastore:\"MtxResponseDevice\" bigquery:\"MtxResponseDevice\" spanner:\"MtxResponseDevice\" mapstructure:\"MtxResponseDevice\" graphql:\"MtxResponseDevice\" firestore:\"MtxResponseDevice\""];
	* @property {String}  SubscriptionUUID 
	* @property {ENUM}    Status           
	* @property {String}  MtxType          
	*
	*/
	CreateNewMtxDevice({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.post(`/v3/${req.SPID}/matrixx_device/create`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('CreateNewMtxDevice ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('CreateNewMtxDevice ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('CreateNewMtxDevice ERROR:', error)
					return Promise.reject(error)
				}
				console.log('CreateNewMtxDevice UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in CreateNewMtxDevice'))
			})
	},

	/**
	* CreateNewMtxGroup: Add a new Matrixx Group object
	* HTTP Method: POST
	* API Path: /api/v3/${req.SPID}/matrixx_group/create
	* @param    {MtxGroup} req The API payload object (MtxGroup)
	* @return   {MtxGroup} The API response object (MtxGroup)
	*
	* @typedef  {Object}  MtxGroup
	* @property {String}  UUID            
	* @property {Number}  SPID            
	* @property {String}  ParentGroupUUID 
	* @property {Number}  CreatedAtNanos  
	* @property {Number}  UpdatedAtNanos  
	* @property {Number}  DeletedAtNanos  
	* @property {String}  Name            
	* @property {ENUM}    Status          
	* @property {String}  MtxObjectID     
	* @property {String}  MtxType         
	*
	*/
	CreateNewMtxGroup({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.post(`/v3/${req.SPID}/matrixx_group/create`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('CreateNewMtxGroup ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('CreateNewMtxGroup ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('CreateNewMtxGroup ERROR:', error)
					return Promise.reject(error)
				}
				console.log('CreateNewMtxGroup UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in CreateNewMtxGroup'))
			})
	},

	/**
	* CreateNewMtxSubscription: Add a new Matrixx Subscription object
	* HTTP Method: POST
	* API Path: /api/v3/${req.SPID}/matrixx_subscription/create
	* @param    {MtxSubscription} req The API payload object (MtxSubscription)
	* @return   {MtxSubscription} The API response object (MtxSubscription)
	*
	* @typedef  {Object}  MtxSubscription
	* @property {String}  UUID           
	* @property {Number}  SPID           
	* @property {Number}  CreatedAtNanos 
	* @property {Number}  UpdatedAtNanos 
	* @property {Number}  DeletedAtNanos 
	* @property {String}  Name           
	* @property {ENUM}    Status         
	* @property {String}  MtxObjectID    
	* @property {String}  GroupUUID      
	* @property {String}  MtxType        
	*
	*/
	CreateNewMtxSubscription({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.post(`/v3/${req.SPID}/matrixx_subscription/create`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('CreateNewMtxSubscription ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('CreateNewMtxSubscription ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('CreateNewMtxSubscription ERROR:', error)
					return Promise.reject(error)
				}
				console.log('CreateNewMtxSubscription UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in CreateNewMtxSubscription'))
			})
	},

	/**
	* CreateNewMtxUser: Add a new Matrixx User object
	* HTTP Method: POST
	* API Path: /api/v3/${req.SPID}/matrixx_user/create
	* @param    {MtxUser} req The API payload object (MtxUser)
	* @return   {MtxUser} The API response object (MtxUser)
	*
	* @typedef  {Object}  MtxUser
	* @property {String}  UUID              
	* @property {Number}  SPID              Default Service Provider ID
	* @property {Number}  CreatedAtNanos    When the object was first created
	* @property {Number}  UpdatedAtNanos    When the object was last updated
	* @property {Number}  DeletedAtNanos    When the object was deleted
	* @property {String}  FirstName         
	* @property {String}  LastName          
	* @property {String}  Password          
	* @property {String}  Email             
	* @property {String}  Phone             
	* @property {String}  ContactPreference 
	* @property {String}  SubscriptionUUID  
	* @property {String}  MtxObjectID       
	* @property {String}  MtxType           
	*
	*/
	CreateNewMtxUser({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.post(`/v3/${req.SPID}/matrixx_user/create`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('CreateNewMtxUser ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('CreateNewMtxUser ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('CreateNewMtxUser ERROR:', error)
					return Promise.reject(error)
				}
				console.log('CreateNewMtxUser UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in CreateNewMtxUser'))
			})
	},

	/**
	* DeleteMtxDeviceByUUID: Deletes a Matrixx Device object. Requires JWT
	* HTTP Method: DELETE
	* API Path: /api/v3/${req.SPID}/matrixx_device/${req.UUID}
	* @param    {MtxDeviceUUIDSearch} req The API payload object (MtxDeviceUUIDSearch)
	* @return   {MtxDevice} The API response object (MtxDevice)
	*
	* @typedef  {Object}  MtxDeviceUUIDSearch
	* @property {String}  UUID 
	* @property {Number}  SPID Service Provider ID - always required
	*
	* @typedef  {Object}  MtxDevice
	* @property {String}  UUID             
	* @property {Number}  SPID             Default Service Provider ID
	* @property {Number}  CreatedAtNanos   When the object was first created
	* @property {Number}  UpdatedAtNanos   When the object was last updated
	* @property {Number}  DeletedAtNanos   When the object was deleted
	* @property {Number}  IMEI             
	* @property {Number}  IMSI             
	* @property {StringSlice} AccessNumbers    Can this use this format
	* @property {String}  MtxObjectID      MtxResponseDevice MtxResponseDevice = 10 [json_name= "MtxResponseDevice", (tagger.tags) = "json:\"MtxResponseDevice,omitempty\" db:\"MtxResponseDevice\" gorm:\"Column:MtxResponseDevice;NOT NULL\" bson:\"MtxResponseDevice\" csv:\"MtxResponseDevice\" query:\"MtxResponseDevice\" xml:\"MtxResponseDevice\" dynamodb:\"MtxResponseDevice\" datastore:\"MtxResponseDevice\" bigquery:\"MtxResponseDevice\" spanner:\"MtxResponseDevice\" mapstructure:\"MtxResponseDevice\" graphql:\"MtxResponseDevice\" firestore:\"MtxResponseDevice\""];
	* @property {String}  SubscriptionUUID 
	* @property {ENUM}    Status           
	* @property {String}  MtxType          
	*
	*/
	DeleteMtxDeviceByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Delete)
		return axios.delete(`/v3/${req.SPID}/matrixx_device/${req.UUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveMtxDevice', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('DeleteMtxDeviceByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('DeleteMtxDeviceByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('DeleteMtxDeviceByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('DeleteMtxDeviceByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in DeleteMtxDeviceByUUID'))
			})
	},

	/**
	* DeleteMtxGroupByUUID: Deletes a Matrixx Group object. Requires JWT
	* HTTP Method: DELETE
	* API Path: /api/v3/${req.SPID}/matrixx_group/${req.UUID}
	* @param    {MtxGroupUUIDSearch} req The API payload object (MtxGroupUUIDSearch)
	* @return   {MtxGroup} The API response object (MtxGroup)
	*
	* @typedef  {Object}  MtxGroupUUIDSearch
	* @property {String}  UUID 
	* @property {Number}  SPID 
	*
	* @typedef  {Object}  MtxGroup
	* @property {String}  UUID            
	* @property {Number}  SPID            
	* @property {String}  ParentGroupUUID 
	* @property {Number}  CreatedAtNanos  
	* @property {Number}  UpdatedAtNanos  
	* @property {Number}  DeletedAtNanos  
	* @property {String}  Name            
	* @property {ENUM}    Status          
	* @property {String}  MtxObjectID     
	* @property {String}  MtxType         
	*
	*/
	DeleteMtxGroupByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Delete)
		return axios.delete(`/v3/${req.SPID}/matrixx_group/${req.UUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveMtxGroup', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('DeleteMtxGroupByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('DeleteMtxGroupByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('DeleteMtxGroupByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('DeleteMtxGroupByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in DeleteMtxGroupByUUID'))
			})
	},

	/**
	* DeleteMtxSubscriptionByUUID: Deletes a Matrixx Subscription object. Requires JWT
	* HTTP Method: DELETE
	* API Path: /api/v3/${req.SPID}/matrixx_subscription/${req.UUID}
	* @param    {MtxSubscriptionUUIDSearch} req The API payload object (MtxSubscriptionUUIDSearch)
	* @return   {MtxSubscription} The API response object (MtxSubscription)
	*
	* @typedef  {Object}  MtxSubscriptionUUIDSearch
	* @property {String}  UUID 
	* @property {Number}  SPID 
	*
	* @typedef  {Object}  MtxSubscription
	* @property {String}  UUID           
	* @property {Number}  SPID           
	* @property {Number}  CreatedAtNanos 
	* @property {Number}  UpdatedAtNanos 
	* @property {Number}  DeletedAtNanos 
	* @property {String}  Name           
	* @property {ENUM}    Status         
	* @property {String}  MtxObjectID    
	* @property {String}  GroupUUID      
	* @property {String}  MtxType        
	*
	*/
	DeleteMtxSubscriptionByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Delete)
		return axios.delete(`/v3/${req.SPID}/matrixx_subscription/${req.UUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveMtxSubscription', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('DeleteMtxSubscriptionByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('DeleteMtxSubscriptionByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('DeleteMtxSubscriptionByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('DeleteMtxSubscriptionByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in DeleteMtxSubscriptionByUUID'))
			})
	},

	/**
	* DeleteMtxUserByUUID: Deletes a User object. Requires JWT
	* HTTP Method: DELETE
	* API Path: /api/v3/${req.SPID}/matrixx_user/${req.UUID}
	* @param    {MtxUserUUIDSearch} req The API payload object (MtxUserUUIDSearch)
	* @return   {MtxUser} The API response object (MtxUser)
	*
	* @typedef  {Object}  MtxUserUUIDSearch
	* @property {String}  UUID 
	* @property {Number}  SPID Service Provider ID - always required
	*
	* @typedef  {Object}  MtxUser
	* @property {String}  UUID              
	* @property {Number}  SPID              Default Service Provider ID
	* @property {Number}  CreatedAtNanos    When the object was first created
	* @property {Number}  UpdatedAtNanos    When the object was last updated
	* @property {Number}  DeletedAtNanos    When the object was deleted
	* @property {String}  FirstName         
	* @property {String}  LastName          
	* @property {String}  Password          
	* @property {String}  Email             
	* @property {String}  Phone             
	* @property {String}  ContactPreference 
	* @property {String}  SubscriptionUUID  
	* @property {String}  MtxObjectID       
	* @property {String}  MtxType           
	*
	*/
	DeleteMtxUserByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Delete)
		return axios.delete(`/v3/${req.SPID}/matrixx_user/${req.UUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveMtxUser', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('DeleteMtxUserByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('DeleteMtxUserByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('DeleteMtxUserByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('DeleteMtxUserByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in DeleteMtxUserByUUID'))
			})
	},

	/**
	* GetMtxBalancesPaginated: List of Matrixx Balances
	* HTTP Method: GET
	* API Path: /api/v3/${req.SPID}/matrixx_pricing/balances
	* @param    {MtxBalancePageRequest} req The API payload object (MtxBalancePageRequest)
	* @return   {MtxBalancePageResponse} The API response object (MtxBalancePageResponse)
	*
	* @typedef  {Object}  MtxBalancePageRequest
	* @property {SearchOptions[]} SearchOptions 
	* @property {Number}  SPID          
	*
	* @typedef  {Object}  MtxBalancePageResponse
	* @property {PageInfo} PageInfo    
	* @property {MtxBalance[]} MtxBalances 
	*
	*/
	GetMtxBalancesPaginated({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.get(`/v3/${req.SPID}/matrixx_pricing/balances`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetMtxBalancesPaginated ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetMtxBalancesPaginated ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetMtxBalancesPaginated ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetMtxBalancesPaginated UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetMtxBalancesPaginated'))
			})
	},

	/**
	* GetMtxDeviceByUUID: Get a single Matrixx Device object using the UUID
	* HTTP Method: GET
	* API Path: /api/v3/${req.SPID}/matrixx_device/${req.UUID}
	* @param    {MtxDeviceUUIDSearch} req The API payload object (MtxDeviceUUIDSearch)
	* @return   {MtxDevice} The API response object (MtxDevice)
	*
	* @typedef  {Object}  MtxDeviceUUIDSearch
	* @property {String}  UUID 
	* @property {Number}  SPID Service Provider ID - always required
	*
	* @typedef  {Object}  MtxDevice
	* @property {String}  UUID             
	* @property {Number}  SPID             Default Service Provider ID
	* @property {Number}  CreatedAtNanos   When the object was first created
	* @property {Number}  UpdatedAtNanos   When the object was last updated
	* @property {Number}  DeletedAtNanos   When the object was deleted
	* @property {Number}  IMEI             
	* @property {Number}  IMSI             
	* @property {StringSlice} AccessNumbers    Can this use this format
	* @property {String}  MtxObjectID      MtxResponseDevice MtxResponseDevice = 10 [json_name= "MtxResponseDevice", (tagger.tags) = "json:\"MtxResponseDevice,omitempty\" db:\"MtxResponseDevice\" gorm:\"Column:MtxResponseDevice;NOT NULL\" bson:\"MtxResponseDevice\" csv:\"MtxResponseDevice\" query:\"MtxResponseDevice\" xml:\"MtxResponseDevice\" dynamodb:\"MtxResponseDevice\" datastore:\"MtxResponseDevice\" bigquery:\"MtxResponseDevice\" spanner:\"MtxResponseDevice\" mapstructure:\"MtxResponseDevice\" graphql:\"MtxResponseDevice\" firestore:\"MtxResponseDevice\""];
	* @property {String}  SubscriptionUUID 
	* @property {ENUM}    Status           
	* @property {String}  MtxType          
	*
	*/
	GetMtxDeviceByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Get)
		return axios.get(`/v3/${req.SPID}/matrixx_device/${req.UUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveMtxDevice', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetMtxDeviceByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetMtxDeviceByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetMtxDeviceByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetMtxDeviceByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetMtxDeviceByUUID'))
			})
	},

	/**
	* GetMtxDevicesPaginated: Searches for multiple Matrixx Device objects using a search request
	* HTTP Method: GET
	* API Path: /api/v3/${req.SPID}/matrixx_device
	* @param    {MtxDeviceSearchRequest} req The API payload object (MtxDeviceSearchRequest)
	* @return   {MtxDevicePageResponse} The API response object (MtxDevicePageResponse)
	*
	* @typedef  {Object}  MtxDeviceSearchRequest
	* @property {SearchOptions[]} SearchOptions    Search options to use (for sorting and/or pagination)
	* @property {Boolean} IncludeDeleted   
	* @property {Number}  SPID             
	* @property {Number}  IMEI             string SmartSearch = 4;
	* @property {Number}  IMSI             
	* @property {Int32Slice} AccessNumbers    
	* @property {String}  SubscriptionUUID 
	* @property {String}  MtxType          
	*
	* @typedef  {Object}  SearchOptions  Search options
	* @property {Number}  PageNumber     The page number to start at (or provide IndexStart)
	* @property {Number}  PageSize       The page size
	* @property {Number}  IndexStart     The record number to start at (or provide PageNumber)
	* @property {String}  SortBy         The field to sort by
	* @property {Boolean} SortDesc       Is the sort direction descending?
	*
	* @typedef  {Object}  MtxDevicePageResponse
	* @property {PageInfo} PageInfo   PageInfo only needs the values ThisPageNumber && PageSize set
	* @property {MtxDevice[]} MtxDevices 
	*
	* @typedef  {Object}   PageInfo
	* @property {Number}   PageNumber             The page number
	* @property {Number}   PageItemCount          Number of items on this page
	* @property {Number}   TotalPages             Total pages available
	* @property {Number}   TotalItemCount         Total items available
	* @property {Number}   IndexStart             The record number this search started at
	* @property {Number}   PageSize               The length of the page
	* @property {Number[]} DisplayPageList        The array of page numbers to display
	* @property {Number}   DisplayPagesEitherSide Number of pages to display either side
	*
	*/
	GetMtxDevicesPaginated({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Find)
		return axios.get(`/v3/${req.SPID}/matrixx_device`, { params: req, validateStatus: valFuncFind }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			commit('mutSaveMtxDevices', { Response: response.data })
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetMtxDevicesPaginated ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetMtxDevicesPaginated ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetMtxDevicesPaginated ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetMtxDevicesPaginated UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetMtxDevicesPaginated'))
			})
	},

	/**
	* GetMtxGroupByUUID: Get a single Matrixx Group object using the UUID
	* HTTP Method: GET
	* API Path: /api/v3/${req.SPID}/matrixx_group/${req.UUID}
	* @param    {MtxGroupUUIDSearch} req The API payload object (MtxGroupUUIDSearch)
	* @return   {MtxGroup} The API response object (MtxGroup)
	*
	* @typedef  {Object}  MtxGroupUUIDSearch
	* @property {String}  UUID 
	* @property {Number}  SPID 
	*
	* @typedef  {Object}  MtxGroup
	* @property {String}  UUID            
	* @property {Number}  SPID            
	* @property {String}  ParentGroupUUID 
	* @property {Number}  CreatedAtNanos  
	* @property {Number}  UpdatedAtNanos  
	* @property {Number}  DeletedAtNanos  
	* @property {String}  Name            
	* @property {ENUM}    Status          
	* @property {String}  MtxObjectID     
	* @property {String}  MtxType         
	*
	*/
	GetMtxGroupByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Get)
		return axios.get(`/v3/${req.SPID}/matrixx_group/${req.UUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveMtxGroup', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetMtxGroupByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetMtxGroupByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetMtxGroupByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetMtxGroupByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetMtxGroupByUUID'))
			})
	},

	/**
	* GetMtxGroupsPaginated: Searches for multiple Matrixx Groups using a search request
	* HTTP Method: GET
	* API Path: /api/v3/${req.SPID}/matrixx_group
	* @param    {MtxGroupSearchRequest} req The API payload object (MtxGroupSearchRequest)
	* @return   {MtxGroupPageResponse} The API response object (MtxGroupPageResponse)
	*
	* @typedef  {Object}  MtxGroupSearchRequest
	* @property {SearchOptions[]} SearchOptions   
	* @property {Boolean} IncludeDeleted  
	* @property {Number}  SPID            
	* @property {String}  Name            
	* @property {ENUM}    Status          
	* @property {String}  ParentGroupUUID 
	* @property {String}  MtxType         
	*
	* @typedef  {Object}  SearchOptions  Search options
	* @property {Number}  PageNumber     The page number to start at (or provide IndexStart)
	* @property {Number}  PageSize       The page size
	* @property {Number}  IndexStart     The record number to start at (or provide PageNumber)
	* @property {String}  SortBy         The field to sort by
	* @property {Boolean} SortDesc       Is the sort direction descending?
	*
	* @typedef  {Object}  MtxGroupPageResponse
	* @property {PageInfo} PageInfo  
	* @property {MtxGroup[]} MtxGroups 
	*
	* @typedef  {Object}   PageInfo
	* @property {Number}   PageNumber             The page number
	* @property {Number}   PageItemCount          Number of items on this page
	* @property {Number}   TotalPages             Total pages available
	* @property {Number}   TotalItemCount         Total items available
	* @property {Number}   IndexStart             The record number this search started at
	* @property {Number}   PageSize               The length of the page
	* @property {Number[]} DisplayPageList        The array of page numbers to display
	* @property {Number}   DisplayPagesEitherSide Number of pages to display either side
	*
	*/
	GetMtxGroupsPaginated({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Find)
		return axios.get(`/v3/${req.SPID}/matrixx_group`, { params: req, validateStatus: valFuncFind }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			commit('mutSaveMtxGroups', { Response: response.data })
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetMtxGroupsPaginated ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetMtxGroupsPaginated ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetMtxGroupsPaginated ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetMtxGroupsPaginated UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetMtxGroupsPaginated'))
			})
	},

	/**
	* GetMtxOffersPaginated: List of bundles and offers from Matrixx
	* HTTP Method: GET
	* API Path: /api/v3/${req.SPID}/matrixx_pricing/offers
	* @param    {MtxOfferPageRequest} req The API payload object (MtxOfferPageRequest)
	* @return   {MtxOfferPageResponse} The API response object (MtxOfferPageResponse)
	*
	* @typedef  {Object}  MtxOfferPageRequest
	* @property {SearchOptions[]} SearchOptions         
	* @property {Boolean} ShowCurrentOffersOnly 
	* @property {Number}  SPID                  
	*
	* @typedef  {Object}  MtxOfferPageResponse
	* @property {PageInfo} PageInfo  
	* @property {MtxOffer[]} MtxOffers 
	*
	*/
	GetMtxOffersPaginated({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.get(`/v3/${req.SPID}/matrixx_pricing/offers`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetMtxOffersPaginated ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetMtxOffersPaginated ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetMtxOffersPaginated ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetMtxOffersPaginated UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetMtxOffersPaginated'))
			})
	},

	/**
	* GetMtxPricingRolesPaginated: List of pricing roles for assigning user subscriptions
	* HTTP Method: GET
	* API Path: /api/v3/${req.SPID}/matrixx_pricing/pricing_roles
	* @param    {MtxPricingRolePageRequest} req The API payload object (MtxPricingRolePageRequest)
	* @return   {MtxPricingRolePageResponse} The API response object (MtxPricingRolePageResponse)
	*
	* @typedef  {Object}  MtxPricingRolePageRequest
	* @property {SearchOptions[]} SearchOptions 
	* @property {Number}  SPID          
	*
	* @typedef  {Object}  MtxPricingRolePageResponse
	* @property {PageInfo} PageInfo        
	* @property {MtxPricingRole[]} MtxPricingRoles 
	*
	*/
	GetMtxPricingRolesPaginated({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.get(`/v3/${req.SPID}/matrixx_pricing/pricing_roles`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetMtxPricingRolesPaginated ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetMtxPricingRolesPaginated ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetMtxPricingRolesPaginated ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetMtxPricingRolesPaginated UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetMtxPricingRolesPaginated'))
			})
	},

	/**
	* GetMtxSubscriptionByUUID: Get a single Matrixx Subscription object using the UUID
	* HTTP Method: GET
	* API Path: /api/v3/${req.SPID}/matrixx_subscription/${req.UUID}
	* @param    {MtxSubscriptionUUIDSearch} req The API payload object (MtxSubscriptionUUIDSearch)
	* @return   {MtxSubscription} The API response object (MtxSubscription)
	*
	* @typedef  {Object}  MtxSubscriptionUUIDSearch
	* @property {String}  UUID 
	* @property {Number}  SPID 
	*
	* @typedef  {Object}  MtxSubscription
	* @property {String}  UUID           
	* @property {Number}  SPID           
	* @property {Number}  CreatedAtNanos 
	* @property {Number}  UpdatedAtNanos 
	* @property {Number}  DeletedAtNanos 
	* @property {String}  Name           
	* @property {ENUM}    Status         
	* @property {String}  MtxObjectID    
	* @property {String}  GroupUUID      
	* @property {String}  MtxType        
	*
	*/
	GetMtxSubscriptionByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Get)
		return axios.get(`/v3/${req.SPID}/matrixx_subscription/${req.UUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveMtxSubscription', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetMtxSubscriptionByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetMtxSubscriptionByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetMtxSubscriptionByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetMtxSubscriptionByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetMtxSubscriptionByUUID'))
			})
	},

	/**
	* GetMtxSubscriptionsPaginated: Searches for multiple Matrixx Subscriptions using a search request
	* HTTP Method: GET
	* API Path: /api/v3/${req.SPID}/matrixx_subscription
	* @param    {MtxSubscriptionSearchRequest} req The API payload object (MtxSubscriptionSearchRequest)
	* @return   {MtxSubscriptionPageResponse} The API response object (MtxSubscriptionPageResponse)
	*
	* @typedef  {Object}  MtxSubscriptionSearchRequest
	* @property {SearchOptions[]} SearchOptions  
	* @property {Boolean} IncludeDeleted 
	* @property {Number}  SPID           
	* @property {String}  Name           
	* @property {ENUM}    Status         
	* @property {String}  GroupUUID      
	* @property {String}  MtxType        
	*
	* @typedef  {Object}  SearchOptions  Search options
	* @property {Number}  PageNumber     The page number to start at (or provide IndexStart)
	* @property {Number}  PageSize       The page size
	* @property {Number}  IndexStart     The record number to start at (or provide PageNumber)
	* @property {String}  SortBy         The field to sort by
	* @property {Boolean} SortDesc       Is the sort direction descending?
	*
	* @typedef  {Object}  MtxSubscriptionPageResponse
	* @property {PageInfo} PageInfo         
	* @property {MtxSubscription[]} MtxSubscriptions 
	*
	* @typedef  {Object}   PageInfo
	* @property {Number}   PageNumber             The page number
	* @property {Number}   PageItemCount          Number of items on this page
	* @property {Number}   TotalPages             Total pages available
	* @property {Number}   TotalItemCount         Total items available
	* @property {Number}   IndexStart             The record number this search started at
	* @property {Number}   PageSize               The length of the page
	* @property {Number[]} DisplayPageList        The array of page numbers to display
	* @property {Number}   DisplayPagesEitherSide Number of pages to display either side
	*
	*/
	GetMtxSubscriptionsPaginated({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Find)
		return axios.get(`/v3/${req.SPID}/matrixx_subscription`, { params: req, validateStatus: valFuncFind }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			commit('mutSaveMtxSubscriptions', { Response: response.data })
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetMtxSubscriptionsPaginated ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetMtxSubscriptionsPaginated ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetMtxSubscriptionsPaginated ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetMtxSubscriptionsPaginated UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetMtxSubscriptionsPaginated'))
			})
	},

	/**
	* GetMtxUserByUUID: Get a single User object using the UUID
	* HTTP Method: GET
	* API Path: /api/v3/${req.SPID}/matrixx_user/${req.UUID}
	* @param    {MtxUserUUIDSearch} req The API payload object (MtxUserUUIDSearch)
	* @return   {MtxUser} The API response object (MtxUser)
	*
	* @typedef  {Object}  MtxUserUUIDSearch
	* @property {String}  UUID 
	* @property {Number}  SPID Service Provider ID - always required
	*
	* @typedef  {Object}  MtxUser
	* @property {String}  UUID              
	* @property {Number}  SPID              Default Service Provider ID
	* @property {Number}  CreatedAtNanos    When the object was first created
	* @property {Number}  UpdatedAtNanos    When the object was last updated
	* @property {Number}  DeletedAtNanos    When the object was deleted
	* @property {String}  FirstName         
	* @property {String}  LastName          
	* @property {String}  Password          
	* @property {String}  Email             
	* @property {String}  Phone             
	* @property {String}  ContactPreference 
	* @property {String}  SubscriptionUUID  
	* @property {String}  MtxObjectID       
	* @property {String}  MtxType           
	*
	*/
	GetMtxUserByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Get)
		return axios.get(`/v3/${req.SPID}/matrixx_user/${req.UUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveMtxUser', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetMtxUserByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetMtxUserByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetMtxUserByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetMtxUserByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetMtxUserByUUID'))
			})
	},

	/**
	* GetMtxUsersPaginated: Searches for multiple User using a search request
	* HTTP Method: GET
	* API Path: /api/v3/${req.SPID}/matrixx_user
	* @param    {MtxUserSearchRequest} req The API payload object (MtxUserSearchRequest)
	* @return   {MtxUserPageResponse} The API response object (MtxUserPageResponse)
	*
	* @typedef  {Object}  MtxUserSearchRequest
	* @property {SearchOptions[]} SearchOptions    
	* @property {Boolean} IncludeDeleted   
	* @property {Number}  SPID             
	* @property {String}  SubscriptionUUID string SmartSearch = 4;
	* @property {String}  MtxType          
	*
	* @typedef  {Object}  SearchOptions  Search options
	* @property {Number}  PageNumber     The page number to start at (or provide IndexStart)
	* @property {Number}  PageSize       The page size
	* @property {Number}  IndexStart     The record number to start at (or provide PageNumber)
	* @property {String}  SortBy         The field to sort by
	* @property {Boolean} SortDesc       Is the sort direction descending?
	*
	* @typedef  {Object}  MtxUserPageResponse
	* @property {PageInfo} PageInfo PageInfo only needs the values ThisPageNumber && PageSize set
	* @property {MtxUser[]} MtxUsers 
	*
	* @typedef  {Object}   PageInfo
	* @property {Number}   PageNumber             The page number
	* @property {Number}   PageItemCount          Number of items on this page
	* @property {Number}   TotalPages             Total pages available
	* @property {Number}   TotalItemCount         Total items available
	* @property {Number}   IndexStart             The record number this search started at
	* @property {Number}   PageSize               The length of the page
	* @property {Number[]} DisplayPageList        The array of page numbers to display
	* @property {Number}   DisplayPagesEitherSide Number of pages to display either side
	*
	*/
	GetMtxUsersPaginated({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Find)
		return axios.get(`/v3/${req.SPID}/matrixx_user`, { params: req, validateStatus: valFuncFind }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			commit('mutSaveMtxUsers', { Response: response.data })
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetMtxUsersPaginated ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetMtxUsersPaginated ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetMtxUsersPaginated ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetMtxUsersPaginated UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetMtxUsersPaginated'))
			})
	},

	/**
	* ModifyMtxDevice: Update a single Matrixx Device object
	* HTTP Method: PUT
	* API Path: /api/v3/${req.SPID}/matrixx_device/${req.UUID}/modify
	* @param    {MtxDevice} req The API payload object (MtxDevice)
	* @return   {MtxDevice} The API response object (MtxDevice)
	*
	* @typedef  {Object}  MtxDevice
	* @property {String}  UUID             
	* @property {Number}  SPID             Default Service Provider ID
	* @property {Number}  CreatedAtNanos   When the object was first created
	* @property {Number}  UpdatedAtNanos   When the object was last updated
	* @property {Number}  DeletedAtNanos   When the object was deleted
	* @property {Number}  IMEI             
	* @property {Number}  IMSI             
	* @property {StringSlice} AccessNumbers    Can this use this format
	* @property {String}  MtxObjectID      MtxResponseDevice MtxResponseDevice = 10 [json_name= "MtxResponseDevice", (tagger.tags) = "json:\"MtxResponseDevice,omitempty\" db:\"MtxResponseDevice\" gorm:\"Column:MtxResponseDevice;NOT NULL\" bson:\"MtxResponseDevice\" csv:\"MtxResponseDevice\" query:\"MtxResponseDevice\" xml:\"MtxResponseDevice\" dynamodb:\"MtxResponseDevice\" datastore:\"MtxResponseDevice\" bigquery:\"MtxResponseDevice\" spanner:\"MtxResponseDevice\" mapstructure:\"MtxResponseDevice\" graphql:\"MtxResponseDevice\" firestore:\"MtxResponseDevice\""];
	* @property {String}  SubscriptionUUID 
	* @property {ENUM}    Status           
	* @property {String}  MtxType          
	*
	*/
	ModifyMtxDevice({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.put(`/v3/${req.SPID}/matrixx_device/${req.UUID}/modify`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('ModifyMtxDevice ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('ModifyMtxDevice ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('ModifyMtxDevice ERROR:', error)
					return Promise.reject(error)
				}
				console.log('ModifyMtxDevice UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in ModifyMtxDevice'))
			})
	},

	/**
	* ModifyMtxGroup: Modify a Matrixx Group object
	* HTTP Method: PUT
	* API Path: /api/v3/${req.SPID}/matrixx_group/${req.UUID}/modify
	* @param    {MtxGroup} req The API payload object (MtxGroup)
	* @return   {MtxGroup} The API response object (MtxGroup)
	*
	* @typedef  {Object}  MtxGroup
	* @property {String}  UUID            
	* @property {Number}  SPID            
	* @property {String}  ParentGroupUUID 
	* @property {Number}  CreatedAtNanos  
	* @property {Number}  UpdatedAtNanos  
	* @property {Number}  DeletedAtNanos  
	* @property {String}  Name            
	* @property {ENUM}    Status          
	* @property {String}  MtxObjectID     
	* @property {String}  MtxType         
	*
	*/
	ModifyMtxGroup({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.put(`/v3/${req.SPID}/matrixx_group/${req.UUID}/modify`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('ModifyMtxGroup ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('ModifyMtxGroup ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('ModifyMtxGroup ERROR:', error)
					return Promise.reject(error)
				}
				console.log('ModifyMtxGroup UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in ModifyMtxGroup'))
			})
	},

	/**
	* ModifyMtxSubscription: Modify a Matrixx Subscription object
	* HTTP Method: PUT
	* API Path: /api/v3/${req.SPID}/matrixx_subscription/${req.UUID}/modify
	* @param    {MtxSubscription} req The API payload object (MtxSubscription)
	* @return   {MtxSubscription} The API response object (MtxSubscription)
	*
	* @typedef  {Object}  MtxSubscription
	* @property {String}  UUID           
	* @property {Number}  SPID           
	* @property {Number}  CreatedAtNanos 
	* @property {Number}  UpdatedAtNanos 
	* @property {Number}  DeletedAtNanos 
	* @property {String}  Name           
	* @property {ENUM}    Status         
	* @property {String}  MtxObjectID    
	* @property {String}  GroupUUID      
	* @property {String}  MtxType        
	*
	*/
	ModifyMtxSubscription({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.put(`/v3/${req.SPID}/matrixx_subscription/${req.UUID}/modify`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('ModifyMtxSubscription ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('ModifyMtxSubscription ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('ModifyMtxSubscription ERROR:', error)
					return Promise.reject(error)
				}
				console.log('ModifyMtxSubscription UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in ModifyMtxSubscription'))
			})
	},

	/**
	* ModifyMtxUser: Update a single matrixx user object
	* HTTP Method: PUT
	* API Path: /api/v3/${req.SPID}/matrixx_user/${req.UUID}/update
	* @param    {MtxUser} req The API payload object (MtxUser)
	* @return   {MtxUser} The API response object (MtxUser)
	*
	* @typedef  {Object}  MtxUser
	* @property {String}  UUID              
	* @property {Number}  SPID              Default Service Provider ID
	* @property {Number}  CreatedAtNanos    When the object was first created
	* @property {Number}  UpdatedAtNanos    When the object was last updated
	* @property {Number}  DeletedAtNanos    When the object was deleted
	* @property {String}  FirstName         
	* @property {String}  LastName          
	* @property {String}  Password          
	* @property {String}  Email             
	* @property {String}  Phone             
	* @property {String}  ContactPreference 
	* @property {String}  SubscriptionUUID  
	* @property {String}  MtxObjectID       
	* @property {String}  MtxType           
	*
	*/
	ModifyMtxUser({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.put(`/v3/${req.SPID}/matrixx_user/${req.UUID}/update`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('ModifyMtxUser ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('ModifyMtxUser ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('ModifyMtxUser ERROR:', error)
					return Promise.reject(error)
				}
				console.log('ModifyMtxUser UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in ModifyMtxUser'))
			})
	},

	/**
	* MtxBalance: Detailed Matrixx Balance
	* HTTP Method: GET
	* API Path: /api/v3/${req.SPID}/matrixx_pricing/balance/${req.BalanceID}
	* @param    {MtxBalanceDetailRequest} req The API payload object (MtxBalanceDetailRequest)
	* @return   {MtxBalanceDetailedResponse} The API response object (MtxBalanceDetailedResponse)
	*
	* @typedef  {Object}  MtxBalanceDetailRequest
	* @property {Number}  BalanceID 
	* @property {Number}  SPID      
	*
	* @typedef  {Object}  MtxBalanceDetailedResponse
	* @property {String}  Name           
	* @property {Number}  BalanceID      
	* @property {String}  ClassName      
	* @property {Number}  EndTimeNanos   
	* @property {String}  EndType        
	* @property {Number}  StartTimeNanos 
	* @property {String}  StartType      
	* @property {String}  QuantityUnit   
	*
	*/
	MtxBalance({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.get(`/v3/${req.SPID}/matrixx_pricing/balance/${req.BalanceID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('MtxBalance ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('MtxBalance ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('MtxBalance ERROR:', error)
					return Promise.reject(error)
				}
				console.log('MtxBalance UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in MtxBalance'))
			})
	},

	/**
	* MtxGroupPayment: Topup the main balance on a Matrixx Group
	* HTTP Method: PUT
	* API Path: /api/v3/${req.SPID}/matrixx_group/payment
	* @param    {MtxGroupPaymentRequest} req The API payload object (MtxGroupPaymentRequest)
	* @return   {MtxResponse} The API response object (MtxResponse)
	*
	* @typedef  {Object}  MtxGroupPaymentRequest
	* @property {String}  MtxGroupUUID 
	* @property {Object}  Amount       
	* @property {Number}  SPID         
	*
	* @typedef  {Object}  MtxResponse
	* @property {Number}  Result     
	* @property {String}  ResultText 
	*
	*/
	MtxGroupPayment({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.put(`/v3/${req.SPID}/matrixx_group/payment`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('MtxGroupPayment ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('MtxGroupPayment ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('MtxGroupPayment ERROR:', error)
					return Promise.reject(error)
				}
				console.log('MtxGroupPayment UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in MtxGroupPayment'))
			})
	},

	/**
	* MtxGroupPurchaseMtxOffers: Purchase offers for a Matrixx Group
	* HTTP Method: PUT
	* API Path: /api/v3/${req.SPID}/matrixx_group/assign_offers
	* @param    {MtxGroupAssignMtxOffersRequest} req The API payload object (MtxGroupAssignMtxOffersRequest)
	* @return   {MtxResponse} The API response object (MtxResponse)
	*
	* @typedef  {Object}  MtxGroupAssignMtxOffersRequest
	* @property {String}  MtxGroupUUID 
	* @property {MtxOfferData[]} MtxOffers    
	* @property {ENUM}    ExecuteMode  
	* @property {Number}  SPID         
	*
	* @typedef  {Object}  MtxResponse
	* @property {Number}  Result     
	* @property {String}  ResultText 
	*
	*/
	MtxGroupPurchaseMtxOffers({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.put(`/v3/${req.SPID}/matrixx_group/assign_offers`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('MtxGroupPurchaseMtxOffers ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('MtxGroupPurchaseMtxOffers ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('MtxGroupPurchaseMtxOffers ERROR:', error)
					return Promise.reject(error)
				}
				console.log('MtxGroupPurchaseMtxOffers UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in MtxGroupPurchaseMtxOffers'))
			})
	},

	/**
	* MtxGroupRecharge: Recharge the main balance on a Matrixx Group
	* HTTP Method: PUT
	* API Path: /api/v3/${req.SPID}/matrixx_group/recharge
	* @param    {MtxGroupRechargeRequest} req The API payload object (MtxGroupRechargeRequest)
	* @return   {MtxResponse} The API response object (MtxResponse)
	*
	* @typedef  {Object}  MtxGroupRechargeRequest
	* @property {String}  MtxGroupUUID 
	* @property {Number}  BalanceID    
	* @property {Object}  Amount       
	* @property {Number}  SPID         
	*
	* @typedef  {Object}  MtxResponse
	* @property {Number}  Result     
	* @property {String}  ResultText 
	*
	*/
	MtxGroupRecharge({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.put(`/v3/${req.SPID}/matrixx_group/recharge`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('MtxGroupRecharge ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('MtxGroupRecharge ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('MtxGroupRecharge ERROR:', error)
					return Promise.reject(error)
				}
				console.log('MtxGroupRecharge UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in MtxGroupRecharge'))
			})
	},

	/**
	* MtxGroupRemoveMtxOffers: Remove offers from a matrixx Group
	* HTTP Method: PUT
	* API Path: /api/v3/${req.SPID}/matrixx_group/remove_offers
	* @param    {MtxGroupRemoveMtxOffersRequest} req The API payload object (MtxGroupRemoveMtxOffersRequest)
	* @return   {MtxResponse} The API response object (MtxResponse)
	*
	* @typedef  {Object}  MtxGroupRemoveMtxOffersRequest
	* @property {String}  MtxGroupUUID                  
	* @property {MtxOfferCancellationResource[]} MtxOfferCancellationResources 
	* @property {ENUM}    ExecuteMode                   
	* @property {Number}  SPID                          
	*
	* @typedef  {Object}  MtxResponse
	* @property {Number}  Result     
	* @property {String}  ResultText 
	*
	*/
	MtxGroupRemoveMtxOffers({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.put(`/v3/${req.SPID}/matrixx_group/remove_offers`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('MtxGroupRemoveMtxOffers ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('MtxGroupRemoveMtxOffers ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('MtxGroupRemoveMtxOffers ERROR:', error)
					return Promise.reject(error)
				}
				console.log('MtxGroupRemoveMtxOffers UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in MtxGroupRemoveMtxOffers'))
			})
	},

	/**
	* MtxGroupTopup: Topup a balance on a Matrixx Group
	* HTTP Method: PUT
	* API Path: /api/v3/${req.SPID}/matrixx_group/topup
	* @param    {MtxGroupTopupRequest} req The API payload object (MtxGroupTopupRequest)
	* @return   {MtxResponse} The API response object (MtxResponse)
	*
	* @typedef  {Object}  MtxGroupTopupRequest
	* @property {String}  MtxGroupUUID      
	* @property {Number}  Topup             
	* @property {String}  Voucher           
	* @property {Number}  BalanceResourceID 
	* @property {Object}  Amount            
	* @property {ENUM}    ExecuteMode       
	* @property {Number}  SPID              
	*
	* @typedef  {Object}  MtxResponse
	* @property {Number}  Result     
	* @property {String}  ResultText 
	*
	*/
	MtxGroupTopup({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.put(`/v3/${req.SPID}/matrixx_group/topup`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('MtxGroupTopup ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('MtxGroupTopup ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('MtxGroupTopup ERROR:', error)
					return Promise.reject(error)
				}
				console.log('MtxGroupTopup UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in MtxGroupTopup'))
			})
	},

	/**
	* MtxGroupWallet: Retrieve balance information
	* HTTP Method: GET
	* API Path: /api/v3/${req.SPID}/matrixx_group/${req.MtxGroupUUID}/wallet
	* @param    {MtxGroupWalletRequest} req The API payload object (MtxGroupWalletRequest)
	* @return   {MtxGroupWalletResponse} The API response object (MtxGroupWalletResponse)
	*
	* @typedef  {Object}  MtxGroupWalletRequest
	* @property {String}  MtxGroupUUID 
	* @property {Number}  SPID         
	*
	* @typedef  {Object}  MtxGroupWalletResponse
	* @property {String}  MtxGroupUUID 
	* @property {MtxBalanceInfo[]} BalanceArray 
	* @property {String}  Result       
	* @property {String}  ResultText   
	*
	*/
	MtxGroupWallet({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.get(`/v3/${req.SPID}/matrixx_group/${req.MtxGroupUUID}/wallet`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('MtxGroupWallet ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('MtxGroupWallet ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('MtxGroupWallet ERROR:', error)
					return Promise.reject(error)
				}
				console.log('MtxGroupWallet UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in MtxGroupWallet'))
			})
	},

	/**
	* MtxOffer: Detailed offer or bundle from Matrixx
	* HTTP Method: GET
	* API Path: /api/v3/${req.SPID}/matrixx_pricing/offer/${req.OfferID}
	* @param    {MtxOfferRequest} req The API payload object (MtxOfferRequest)
	* @return   {MtxOfferDetailedResponse} The API response object (MtxOfferDetailedResponse)
	*
	* @typedef  {Object}  MtxOfferRequest
	* @property {Number}  OfferID 
	* @property {Number}  SPID    
	*
	* @typedef  {Object}  MtxOfferDetailedResponse
	* @property {Number}  OfferID                   
	* @property {String}  Name                      
	* @property {Number}  StartTimeNanos            
	* @property {String}  StartType                 
	* @property {Number}  EndTimeNanos              
	* @property {String}  EndType                   
	* @property {Number}  EndTimeRelativeOffset     
	* @property {String}  EndTimeRelativeOffsetUnit 
	* @property {Number}  RequiredBalanceArray      
	* @property {Number}  OfferRevision             
	*
	*/
	MtxOffer({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.get(`/v3/${req.SPID}/matrixx_pricing/offer/${req.OfferID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('MtxOffer ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('MtxOffer ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('MtxOffer ERROR:', error)
					return Promise.reject(error)
				}
				console.log('MtxOffer UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in MtxOffer'))
			})
	},

	/**
	* PurgeMtxDeviceByUUID: Purges a Matrixx Device object. Requires JWT
	* HTTP Method: DELETE
	* API Path: /api/v3/${req.SPID}/matrixx_device/${req.UUID}/purge
	* @param    {MtxDeviceUUIDSearch} req The API payload object (MtxDeviceUUIDSearch)
	* @return   {MtxDevice} The API response object (MtxDevice)
	*
	* @typedef  {Object}  MtxDeviceUUIDSearch
	* @property {String}  UUID 
	* @property {Number}  SPID Service Provider ID - always required
	*
	* @typedef  {Object}  MtxDevice
	* @property {String}  UUID             
	* @property {Number}  SPID             Default Service Provider ID
	* @property {Number}  CreatedAtNanos   When the object was first created
	* @property {Number}  UpdatedAtNanos   When the object was last updated
	* @property {Number}  DeletedAtNanos   When the object was deleted
	* @property {Number}  IMEI             
	* @property {Number}  IMSI             
	* @property {StringSlice} AccessNumbers    Can this use this format
	* @property {String}  MtxObjectID      MtxResponseDevice MtxResponseDevice = 10 [json_name= "MtxResponseDevice", (tagger.tags) = "json:\"MtxResponseDevice,omitempty\" db:\"MtxResponseDevice\" gorm:\"Column:MtxResponseDevice;NOT NULL\" bson:\"MtxResponseDevice\" csv:\"MtxResponseDevice\" query:\"MtxResponseDevice\" xml:\"MtxResponseDevice\" dynamodb:\"MtxResponseDevice\" datastore:\"MtxResponseDevice\" bigquery:\"MtxResponseDevice\" spanner:\"MtxResponseDevice\" mapstructure:\"MtxResponseDevice\" graphql:\"MtxResponseDevice\" firestore:\"MtxResponseDevice\""];
	* @property {String}  SubscriptionUUID 
	* @property {ENUM}    Status           
	* @property {String}  MtxType          
	*
	*/
	PurgeMtxDeviceByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Purge)
		return axios.delete(`/v3/${req.SPID}/matrixx_device/${req.UUID}/purge`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveMtxDevice', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('PurgeMtxDeviceByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('PurgeMtxDeviceByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('PurgeMtxDeviceByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('PurgeMtxDeviceByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in PurgeMtxDeviceByUUID'))
			})
	},

	/**
	* PurgeMtxGroupByUUID: Purges a Matrixx Group object. Requires JWT
	* HTTP Method: DELETE
	* API Path: /api/v3/${req.SPID}/matrixx_group/${req.UUID}/purge
	* @param    {MtxGroupUUIDSearch} req The API payload object (MtxGroupUUIDSearch)
	* @return   {MtxGroup} The API response object (MtxGroup)
	*
	* @typedef  {Object}  MtxGroupUUIDSearch
	* @property {String}  UUID 
	* @property {Number}  SPID 
	*
	* @typedef  {Object}  MtxGroup
	* @property {String}  UUID            
	* @property {Number}  SPID            
	* @property {String}  ParentGroupUUID 
	* @property {Number}  CreatedAtNanos  
	* @property {Number}  UpdatedAtNanos  
	* @property {Number}  DeletedAtNanos  
	* @property {String}  Name            
	* @property {ENUM}    Status          
	* @property {String}  MtxObjectID     
	* @property {String}  MtxType         
	*
	*/
	PurgeMtxGroupByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Purge)
		return axios.delete(`/v3/${req.SPID}/matrixx_group/${req.UUID}/purge`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveMtxGroup', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('PurgeMtxGroupByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('PurgeMtxGroupByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('PurgeMtxGroupByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('PurgeMtxGroupByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in PurgeMtxGroupByUUID'))
			})
	},

	/**
	* PurgeMtxSubscriptionByUUID: Purges a Matrixx Subscription object. Requires JWT
	* HTTP Method: DELETE
	* API Path: /api/v3/${req.SPID}/matrixx_subscription/${req.UUID}/purge
	* @param    {MtxSubscriptionUUIDSearch} req The API payload object (MtxSubscriptionUUIDSearch)
	* @return   {MtxSubscription} The API response object (MtxSubscription)
	*
	* @typedef  {Object}  MtxSubscriptionUUIDSearch
	* @property {String}  UUID 
	* @property {Number}  SPID 
	*
	* @typedef  {Object}  MtxSubscription
	* @property {String}  UUID           
	* @property {Number}  SPID           
	* @property {Number}  CreatedAtNanos 
	* @property {Number}  UpdatedAtNanos 
	* @property {Number}  DeletedAtNanos 
	* @property {String}  Name           
	* @property {ENUM}    Status         
	* @property {String}  MtxObjectID    
	* @property {String}  GroupUUID      
	* @property {String}  MtxType        
	*
	*/
	PurgeMtxSubscriptionByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Purge)
		return axios.delete(`/v3/${req.SPID}/matrixx_subscription/${req.UUID}/purge`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveMtxSubscription', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('PurgeMtxSubscriptionByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('PurgeMtxSubscriptionByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('PurgeMtxSubscriptionByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('PurgeMtxSubscriptionByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in PurgeMtxSubscriptionByUUID'))
			})
	},

	/**
	* PurgeMtxUserByUUID: Purges a user object. Requires JWT
	* HTTP Method: DELETE
	* API Path: /api/v3/${req.SPID}/matrixx_user/${req.UUID}/purge
	* @param    {MtxUserUUIDSearch} req The API payload object (MtxUserUUIDSearch)
	* @return   {MtxUser} The API response object (MtxUser)
	*
	* @typedef  {Object}  MtxUserUUIDSearch
	* @property {String}  UUID 
	* @property {Number}  SPID Service Provider ID - always required
	*
	* @typedef  {Object}  MtxUser
	* @property {String}  UUID              
	* @property {Number}  SPID              Default Service Provider ID
	* @property {Number}  CreatedAtNanos    When the object was first created
	* @property {Number}  UpdatedAtNanos    When the object was last updated
	* @property {Number}  DeletedAtNanos    When the object was deleted
	* @property {String}  FirstName         
	* @property {String}  LastName          
	* @property {String}  Password          
	* @property {String}  Email             
	* @property {String}  Phone             
	* @property {String}  ContactPreference 
	* @property {String}  SubscriptionUUID  
	* @property {String}  MtxObjectID       
	* @property {String}  MtxType           
	*
	*/
	PurgeMtxUserByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Purge)
		return axios.delete(`/v3/${req.SPID}/matrixx_user/${req.UUID}/purge`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveMtxUser', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('PurgeMtxUserByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('PurgeMtxUserByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('PurgeMtxUserByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('PurgeMtxUserByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in PurgeMtxUserByUUID'))
			})
	},

	/**
	* RemoveMtxDeviceFromMtxSubscription: Remove a Matrixx device from a Matrixx subscription
	* HTTP Method: PUT
	* API Path: /api/v3/${req.SPID}/matrixx_subscription/remove_device
	* @param    {MtxSubscriptionRemoveMtxDevice} req The API payload object (MtxSubscriptionRemoveMtxDevice)
	* @return   {MtxResponse} The API response object (MtxResponse)
	*
	* @typedef  {Object}  MtxSubscriptionRemoveMtxDevice
	* @property {String}  MtxSubscriptionUUID 
	* @property {String}  MtxDeviceUUID       
	* @property {Number}  SPID                
	*
	* @typedef  {Object}  MtxResponse
	* @property {Number}  Result     
	* @property {String}  ResultText 
	*
	*/
	RemoveMtxDeviceFromMtxSubscription({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.put(`/v3/${req.SPID}/matrixx_subscription/remove_device`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('RemoveMtxDeviceFromMtxSubscription ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('RemoveMtxDeviceFromMtxSubscription ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('RemoveMtxDeviceFromMtxSubscription ERROR:', error)
					return Promise.reject(error)
				}
				console.log('RemoveMtxDeviceFromMtxSubscription UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in RemoveMtxDeviceFromMtxSubscription'))
			})
	},

	/**
	* RemoveMtxGroupFromMtxGroup: Remove a Matrixx Group from a Matrixx Group
	* HTTP Method: PUT
	* API Path: /api/v3/${req.SPID}/matrixx_group/remove_group
	* @param    {MtxGroupRemoveMtxGroup} req The API payload object (MtxGroupRemoveMtxGroup)
	* @return   {MtxResponse} The API response object (MtxResponse)
	*
	* @typedef  {Object}  MtxGroupRemoveMtxGroup
	* @property {String}  ParentMtxGroupUUID 
	* @property {String}  MtxGroupUUID       
	* @property {Number}  SPID               
	*
	* @typedef  {Object}  MtxResponse
	* @property {Number}  Result     
	* @property {String}  ResultText 
	*
	*/
	RemoveMtxGroupFromMtxGroup({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.put(`/v3/${req.SPID}/matrixx_group/remove_group`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('RemoveMtxGroupFromMtxGroup ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('RemoveMtxGroupFromMtxGroup ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('RemoveMtxGroupFromMtxGroup ERROR:', error)
					return Promise.reject(error)
				}
				console.log('RemoveMtxGroupFromMtxGroup UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in RemoveMtxGroupFromMtxGroup'))
			})
	},

	/**
	* RemoveMtxSubscriptionFromMtxGroup: Remove a Matrixx Subscription from a Matrixx Group
	* HTTP Method: PUT
	* API Path: /api/v3/${req.SPID}/matrixx_group/remove_subscription
	* @param    {MtxGroupRemoveMtxSubscription} req The API payload object (MtxGroupRemoveMtxSubscription)
	* @return   {MtxResponse} The API response object (MtxResponse)
	*
	* @typedef  {Object}  MtxGroupRemoveMtxSubscription
	* @property {String}  MtxGroupUUID        
	* @property {String}  MtxSubscriptionUUID 
	* @property {Number}  SPID                
	*
	* @typedef  {Object}  MtxResponse
	* @property {Number}  Result     
	* @property {String}  ResultText 
	*
	*/
	RemoveMtxSubscriptionFromMtxGroup({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.put(`/v3/${req.SPID}/matrixx_group/remove_subscription`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('RemoveMtxSubscriptionFromMtxGroup ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('RemoveMtxSubscriptionFromMtxGroup ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('RemoveMtxSubscriptionFromMtxGroup ERROR:', error)
					return Promise.reject(error)
				}
				console.log('RemoveMtxSubscriptionFromMtxGroup UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in RemoveMtxSubscriptionFromMtxGroup'))
			})
	},

	/**
	* RemoveMtxSubscriptionFromMtxUser: removes a subscription from a user
	* HTTP Method: DELETE
	* API Path: /api/v3/${req.SPID}/matrixx_user/remove_mtx_subscription
	* @param    {MtxUserRemoveMtxSubscription} req The API payload object (MtxUserRemoveMtxSubscription)
	* @return   {MtxResponse} The API response object (MtxResponse)
	*
	* @typedef  {Object}  MtxUserRemoveMtxSubscription
	* @property {String}  MtxUserUUID         
	* @property {String}  MtxSubscriptionUUID 
	* @property {Number}  SPID                
	*
	* @typedef  {Object}  MtxResponse
	* @property {Number}  Result     
	* @property {String}  ResultText 
	*
	*/
	RemoveMtxSubscriptionFromMtxUser({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.delete(`/v3/${req.SPID}/matrixx_user/remove_mtx_subscription`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('RemoveMtxSubscriptionFromMtxUser ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('RemoveMtxSubscriptionFromMtxUser ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('RemoveMtxSubscriptionFromMtxUser ERROR:', error)
					return Promise.reject(error)
				}
				console.log('RemoveMtxSubscriptionFromMtxUser UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in RemoveMtxSubscriptionFromMtxUser'))
			})
	},

	/**
	* Simulate: Simulate usage for a device
	* HTTP Method: POST
	* API Path: /api/v3/${req.SPID}/matrixx_simulate/${req.DeviceUUID}
	* @param    {MtxSimulate} req The API payload object (MtxSimulate)
	* @return   {MtxResponse} The API response object (MtxResponse)
	*
	* @typedef  {Object}  MtxSimulate
	* @property {String}  DeviceUUID 
	* @property {ENUM}    Type       
	* @property {Number}  Amount     
	* @property {Number}  SPID       
	*
	* @typedef  {Object}  MtxResponse
	* @property {Number}  Result     
	* @property {String}  ResultText 
	*
	*/
	Simulate({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.post(`/v3/${req.SPID}/matrixx_simulate/${req.DeviceUUID}`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('Simulate ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('Simulate ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('Simulate ERROR:', error)
					return Promise.reject(error)
				}
				console.log('Simulate UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in Simulate'))
			})
	},
};

export default {
	namespaced: true,
	state: initialState,
	getters,
	mutations,
	actions,
};