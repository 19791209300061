// Code generated by protoc-gen-iqwebapi. DO NOT EDIT.
// source: siptrunk.proto

import axios from 'axios';
import StoreHash from '@/utils/StoreHash';

const initialState = {
	SIPTrunks: {
		UUIDs: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
};

const valFuncFind = function (status) {
	return (status == 200) ? true : (status == 404) ? true : false;
}

const default_pageinfo = {
	PageNumber: 0,
	PageItemCount: 0,
	TotalPages: 0,
	TotalItemCount: 0,
	IndexStart: 0,
	PageSize: 10,
	DisplayPageList: [],
	DisplayPageEitherSide: 4,
}

const getters = {
	getSIPTrunkByUUID: state => (UUID) => (state.SIPTrunks.UUIDs[ UUID ] && !state.SIPTrunks.UUIDs[ UUID ].expired()) ?  state.SIPTrunks.UUIDs[ UUID ].hashedData() : null,
};

const mutations = {
	// mutSaveSIPTrunk saves a single SIPTrunk object
	// into both the StoreHash cache and the VueX store
	mutSaveSIPTrunk(state, obj) {
		if (!obj.UUID) { return } // Must have UUID to Save

		if (!(obj.UUID in state.SIPTrunks.UUIDs)) {
			state.SIPTrunks.UUIDs[ obj.UUID ] = new StoreHash(state.SIPTrunks.lifetime)
		}
		state.SIPTrunks.UUIDs[ obj.UUID ].fix(obj) // Add item to StoreHash
	},
	// mutRemoveSIPTrunkByUUID removes a SIPTrunk object
	// with the given UUID from StoreHash cache and VueX store
	mutRemoveSIPTrunkByUUID(state, UUID) {
		if (!UUID) {
			// Must have UUID to Remove
			return
		}
		delete state.SIPTrunks.UUIDs[ UUID ]    // remove item from StoreHash
	},
	// mutSaveSIPTrunks saves a paginated response of SIPTrunks
	mutSaveSIPTrunks(state, { Response }) {
		if (!Response || !Array.isArray(Response.SIPTrunks)) {
			// Must have Response object with results
			return
		}
		for (let i = 0; i < Response.SIPTrunks.length; i++) {
			if (!(Response.SIPTrunks[i].UUID in state.SIPTrunks.UUIDs)) {
				state.SIPTrunks.UUIDs[ Response.SIPTrunks[i].UUID ] = new StoreHash(state.SIPTrunks.lifetime)
			}
			state.SIPTrunks.UUIDs[ Response.SIPTrunks[i].UUID ].fix(Response.SIPTrunks[i])  // Add single SIPTrunk to StoreHash
		}
	},
};

const actions = {
	// getCachedSIPTrunkByUUID fetches a SIPTrunk from the cache
	// and if not in the cache, fetches it from the API
	getCachedSIPTrunkByUUID({ state, dispatch, commit }, { UUID, RefreshCache }) {
		if (!RefreshCache && state.SIPTrunks.UUIDs[ UUID ] && !state.SIPTrunks.UUIDs[ UUID ].expired()) {
			// Return the cached record
			return state.SIPTrunks.UUIDs[ UUID ].hashedData()
		}
		// Fetch from the API
		return dispatch('GetSIPTrunkByUUID', { UUID }).then(respPayload => {
			commit('mutSaveSIPTrunk', respPayload)
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveSIPTrunkByUUID', UUID)
			return Promise.reject(error)
		})
	},

	//
	// Service: SipTrunkProductRPC
	// 
	/**
	* RPC Method: AddSIPTrunk
	* Description: Add a SIPTrunk object
	* HTTP Method: POST
	* API Path: /api/v3/${req.SPID}/sip_trunk
	* @param    {SIPTrunk} req The API payload object (SIPTrunk)
	* @return   {SIPTrunk} The API response object (SIPTrunk)
	*
	* @typedef  {Object}  SIPTrunk
	* @property {String}  UUID                  
	* @property {Number}  SPID                  Default Service Provider ID
	* @property {Number}  CreatedAtNanos        When the object was first created
	* @property {Number}  UpdatedAtNanos        When the object was last updated
	* @property {Number}  DeletedAtNanos        When the object was deleted
	* @property {Boolean} Enabled               Is this SIP Trunk enabled?
	* @property {String}  Username              The SIP username for this trunk
	* @property {Boolean} Registered            Is this trunk registered?
	* @property {String}  Password              SIP Password
	* @property {String}  Description           Text description of this trunk
	* @property {String}  PilotNumber           Pilot Number for this trunk
	* @property {Boolean} Trunking              
	* @property {Boolean} MenuActive            
	* @property {Boolean} DoNotDisturb          
	* @property {Boolean} Private               
	* @property {Boolean} BlockPrivateNumbers   
	* @property {Boolean} SendCallerID          
	* @property {Boolean} CFNAEnabled           Call Forward Not Available Enabled
	* @property {Number}  CFNARingTime          Call Forward Not Available Ring Time (in seconds)
	* @property {String}  CFNADestination       Call Forward Not Available Destination
	* @property {Boolean} CFBEnabled            Call Forward on Busy Enabled
	* @property {String}  CFBDestination        Call Forward on Busy Destination
	* @property {Boolean} CFAEnabled            Call Forward Always Enabled
	* @property {String}  CFADestination        Call Forward Always Destination
	* @property {Boolean} CFNREnabled           Call Forward Not Registered Enabled
	* @property {String}  CFNRDestination       Call Forward Not Registered Destination
	* @property {Boolean} RecordInbound         Call Recording Inbound Enabled
	* @property {String}  RecordInboundAddress  Call Recording Inbound Address (SIP address)
	* @property {Boolean} RecordOutbound        Call Recording Outbound Enabled
	* @property {String}  RecordOutboundAddress Call Recording Inbound Destination
	* @property {StringSlice} PhoneNumbers          Phone numbers attached to this SIP Trunk
	* @property {String}  EndpointIPAddress     SIP Destination IP Address
	* @property {String}  EndpointDomain        SIP Destination Domain
	* @property {String}  EndpointUsername      SIP Destination Username
	* @property {String}  EndpointPassword      SIP Destination Password
	*
	*/
	AddSIPTrunk({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Add)
		return axios.post(`/v3/${req.SPID}/sip_trunk`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('AddSIPTrunk ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('AddSIPTrunk ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('AddSIPTrunk ERROR:', error)
					return Promise.reject(error)
				}
				console.log('AddSIPTrunk UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in AddSIPTrunk'))
			})
	},

	/**
	* RPC Method: BulkEditSipTrunk
	* Description: Bulk edit a bunch of sip trunks by provided uuid
	* HTTP Method: PUT
	* API Path: /api/v3/${req.SPID}/sip_trunk/bulk_edit
	* @param    {SipTrunkBulkEditRequest} req The API payload object (SipTrunkBulkEditRequest)
	* @return   {SipTrunkBulkEditResponse} The API response object (SipTrunkBulkEditResponse)
	*
	* @typedef  {Object}  SipTrunkBulkEditRequest
	* @property {Number}  SPID            
	* @property {String}  UUIDs           
	* @property {Boolean} CFNAEnabled     Call Forward Not Available Enabled
	* @property {Number}  CFNARingTime    Call Forward Not Available Ring Time (in seconds)
	* @property {String}  CFNADestination Call Forward Not Available Destination
	* @property {Boolean} CFBEnabled      Call Forward on Busy Enabled
	* @property {String}  CFBDestination  Call Forward on Busy Destination
	* @property {Boolean} CFAEnabled      Call Forward Always Enabled
	* @property {String}  CFADestination  Call Forward Always Destination
	* @property {Boolean} CFNREnabled     Call Forward Not Registered Enabled
	* @property {String}  CFNRDestination Call Forward Not Registered Destination
	*
	* @typedef  {Object}  SipTrunkBulkEditResponse
	* @property {SIPTrunk[]} EditedSIPTrunks 
	*
	*/
	BulkEditSipTrunk({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.put(`/v3/${req.SPID}/sip_trunk/bulk_edit`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('BulkEditSipTrunk ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('BulkEditSipTrunk ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('BulkEditSipTrunk ERROR:', error)
					return Promise.reject(error)
				}
				console.log('BulkEditSipTrunk UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in BulkEditSipTrunk'))
			})
	},

	/**
	* RPC Method: DeleteSIPTrunkByUUID
	* Description: Deletes a SIP Trunk. Requires JWT
	* HTTP Method: DELETE
	* API Path: /api/v3/${req.SPID}/sip_trunk/${req.UUID}
	* @param    {SIPTrunkUUIDSearch} req The API payload object (SIPTrunkUUIDSearch)
	* @return   {SIPTrunk} The API response object (SIPTrunk)
	*
	* @typedef  {Object}  SIPTrunkUUIDSearch
	* @property {String}  UUID 
	* @property {Number}  SPID Service Provider ID - always required
	*
	* @typedef  {Object}  SIPTrunk
	* @property {String}  UUID                  
	* @property {Number}  SPID                  Default Service Provider ID
	* @property {Number}  CreatedAtNanos        When the object was first created
	* @property {Number}  UpdatedAtNanos        When the object was last updated
	* @property {Number}  DeletedAtNanos        When the object was deleted
	* @property {Boolean} Enabled               Is this SIP Trunk enabled?
	* @property {String}  Username              The SIP username for this trunk
	* @property {Boolean} Registered            Is this trunk registered?
	* @property {String}  Password              SIP Password
	* @property {String}  Description           Text description of this trunk
	* @property {String}  PilotNumber           Pilot Number for this trunk
	* @property {Boolean} Trunking              
	* @property {Boolean} MenuActive            
	* @property {Boolean} DoNotDisturb          
	* @property {Boolean} Private               
	* @property {Boolean} BlockPrivateNumbers   
	* @property {Boolean} SendCallerID          
	* @property {Boolean} CFNAEnabled           Call Forward Not Available Enabled
	* @property {Number}  CFNARingTime          Call Forward Not Available Ring Time (in seconds)
	* @property {String}  CFNADestination       Call Forward Not Available Destination
	* @property {Boolean} CFBEnabled            Call Forward on Busy Enabled
	* @property {String}  CFBDestination        Call Forward on Busy Destination
	* @property {Boolean} CFAEnabled            Call Forward Always Enabled
	* @property {String}  CFADestination        Call Forward Always Destination
	* @property {Boolean} CFNREnabled           Call Forward Not Registered Enabled
	* @property {String}  CFNRDestination       Call Forward Not Registered Destination
	* @property {Boolean} RecordInbound         Call Recording Inbound Enabled
	* @property {String}  RecordInboundAddress  Call Recording Inbound Address (SIP address)
	* @property {Boolean} RecordOutbound        Call Recording Outbound Enabled
	* @property {String}  RecordOutboundAddress Call Recording Inbound Destination
	* @property {StringSlice} PhoneNumbers          Phone numbers attached to this SIP Trunk
	* @property {String}  EndpointIPAddress     SIP Destination IP Address
	* @property {String}  EndpointDomain        SIP Destination Domain
	* @property {String}  EndpointUsername      SIP Destination Username
	* @property {String}  EndpointPassword      SIP Destination Password
	*
	*/
	DeleteSIPTrunkByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Delete)
		return axios.delete(`/v3/${req.SPID}/sip_trunk/${req.UUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveSIPTrunk', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('DeleteSIPTrunkByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('DeleteSIPTrunkByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('DeleteSIPTrunkByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('DeleteSIPTrunkByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in DeleteSIPTrunkByUUID'))
			})
	},

	/**
	* RPC Method: GetSIPTrunkByUUID
	* Description: Get a single SIP Trunk object using the UUID
	* HTTP Method: GET
	* API Path: /api/v3/${req.SPID}/sip_trunk/${req.UUID}
	* @param    {SIPTrunkUUIDSearch} req The API payload object (SIPTrunkUUIDSearch)
	* @return   {SIPTrunk} The API response object (SIPTrunk)
	*
	* @typedef  {Object}  SIPTrunkUUIDSearch
	* @property {String}  UUID 
	* @property {Number}  SPID Service Provider ID - always required
	*
	* @typedef  {Object}  SIPTrunk
	* @property {String}  UUID                  
	* @property {Number}  SPID                  Default Service Provider ID
	* @property {Number}  CreatedAtNanos        When the object was first created
	* @property {Number}  UpdatedAtNanos        When the object was last updated
	* @property {Number}  DeletedAtNanos        When the object was deleted
	* @property {Boolean} Enabled               Is this SIP Trunk enabled?
	* @property {String}  Username              The SIP username for this trunk
	* @property {Boolean} Registered            Is this trunk registered?
	* @property {String}  Password              SIP Password
	* @property {String}  Description           Text description of this trunk
	* @property {String}  PilotNumber           Pilot Number for this trunk
	* @property {Boolean} Trunking              
	* @property {Boolean} MenuActive            
	* @property {Boolean} DoNotDisturb          
	* @property {Boolean} Private               
	* @property {Boolean} BlockPrivateNumbers   
	* @property {Boolean} SendCallerID          
	* @property {Boolean} CFNAEnabled           Call Forward Not Available Enabled
	* @property {Number}  CFNARingTime          Call Forward Not Available Ring Time (in seconds)
	* @property {String}  CFNADestination       Call Forward Not Available Destination
	* @property {Boolean} CFBEnabled            Call Forward on Busy Enabled
	* @property {String}  CFBDestination        Call Forward on Busy Destination
	* @property {Boolean} CFAEnabled            Call Forward Always Enabled
	* @property {String}  CFADestination        Call Forward Always Destination
	* @property {Boolean} CFNREnabled           Call Forward Not Registered Enabled
	* @property {String}  CFNRDestination       Call Forward Not Registered Destination
	* @property {Boolean} RecordInbound         Call Recording Inbound Enabled
	* @property {String}  RecordInboundAddress  Call Recording Inbound Address (SIP address)
	* @property {Boolean} RecordOutbound        Call Recording Outbound Enabled
	* @property {String}  RecordOutboundAddress Call Recording Inbound Destination
	* @property {StringSlice} PhoneNumbers          Phone numbers attached to this SIP Trunk
	* @property {String}  EndpointIPAddress     SIP Destination IP Address
	* @property {String}  EndpointDomain        SIP Destination Domain
	* @property {String}  EndpointUsername      SIP Destination Username
	* @property {String}  EndpointPassword      SIP Destination Password
	*
	*/
	GetSIPTrunkByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Get)
		return axios.get(`/v3/${req.SPID}/sip_trunk/${req.UUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveSIPTrunk', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetSIPTrunkByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetSIPTrunkByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetSIPTrunkByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetSIPTrunkByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetSIPTrunkByUUID'))
			})
	},

	/**
	* RPC Method: GetSIPTrunksPaginated
	* Description: Searches for multiple SIP Trunks using a search request
	* HTTP Method: GET
	* API Path: /api/v3/${req.SPID}/sip_trunk
	* @param    {SIPTrunkSearchRequest} req The API payload object (SIPTrunkSearchRequest)
	* @return   {SIPTrunkPageResponse} The API response object (SIPTrunkPageResponse)
	*
	* @typedef  {Object}  SIPTrunkSearchRequest
	* @property {SearchOptions[]} SearchOptions  Search options to use (for sorting and/or pagination)
	* @property {Boolean} IncludeDeleted IncludeDeleted field present on the search request ensures that deleted records (records with DeleteAtNanos > 0) are not returned unless this is set
	* @property {Number}  SPID           (optional) SPID
	* @property {String}  Description    (optional) Description
	*
	* @typedef  {Object}  SearchOptions  Search options
	* @property {Number}  PageNumber     The page number to start at (or provide IndexStart)
	* @property {Number}  PageSize       The page size
	* @property {Number}  IndexStart     The record number to start at (or provide PageNumber)
	* @property {String}  SortBy         The field to sort by
	* @property {Boolean} SortDesc       Is the sort direction descending?
	*
	* @typedef  {Object}  SIPTrunkPageResponse
	* @property {PageInfo} PageInfo  PageInfo only needs the values ThisPageNumber && PageSize set
	* @property {SIPTrunk[]} SIPTrunks 
	*
	* @typedef  {Object}   PageInfo
	* @property {Number}   PageNumber             The page number
	* @property {Number}   PageItemCount          Number of items on this page
	* @property {Number}   TotalPages             Total pages available
	* @property {Number}   TotalItemCount         Total items available
	* @property {Number}   IndexStart             The record number this search started at
	* @property {Number}   PageSize               The length of the page
	* @property {Number[]} DisplayPageList        The array of page numbers to display
	* @property {Number}   DisplayPagesEitherSide Number of pages to display either side
	*
	*/
	GetSIPTrunksPaginated({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Find)
		return axios.get(`/v3/${req.SPID}/sip_trunk`, { params: req, validateStatus: valFuncFind }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			commit('mutSaveSIPTrunks', { Response: response.data })
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetSIPTrunksPaginated ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetSIPTrunksPaginated ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetSIPTrunksPaginated ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetSIPTrunksPaginated UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetSIPTrunksPaginated'))
			})
	},

	/**
	* RPC Method: PurgeSIPTrunkByUUID
	* Description: Purges a SIP Trunk. Requires JWT
	* HTTP Method: DELETE
	* API Path: /api/v3/${req.SPID}/sip_trunk/${req.UUID}/purge
	* @param    {SIPTrunkUUIDSearch} req The API payload object (SIPTrunkUUIDSearch)
	* @return   {SIPTrunk} The API response object (SIPTrunk)
	*
	* @typedef  {Object}  SIPTrunkUUIDSearch
	* @property {String}  UUID 
	* @property {Number}  SPID Service Provider ID - always required
	*
	* @typedef  {Object}  SIPTrunk
	* @property {String}  UUID                  
	* @property {Number}  SPID                  Default Service Provider ID
	* @property {Number}  CreatedAtNanos        When the object was first created
	* @property {Number}  UpdatedAtNanos        When the object was last updated
	* @property {Number}  DeletedAtNanos        When the object was deleted
	* @property {Boolean} Enabled               Is this SIP Trunk enabled?
	* @property {String}  Username              The SIP username for this trunk
	* @property {Boolean} Registered            Is this trunk registered?
	* @property {String}  Password              SIP Password
	* @property {String}  Description           Text description of this trunk
	* @property {String}  PilotNumber           Pilot Number for this trunk
	* @property {Boolean} Trunking              
	* @property {Boolean} MenuActive            
	* @property {Boolean} DoNotDisturb          
	* @property {Boolean} Private               
	* @property {Boolean} BlockPrivateNumbers   
	* @property {Boolean} SendCallerID          
	* @property {Boolean} CFNAEnabled           Call Forward Not Available Enabled
	* @property {Number}  CFNARingTime          Call Forward Not Available Ring Time (in seconds)
	* @property {String}  CFNADestination       Call Forward Not Available Destination
	* @property {Boolean} CFBEnabled            Call Forward on Busy Enabled
	* @property {String}  CFBDestination        Call Forward on Busy Destination
	* @property {Boolean} CFAEnabled            Call Forward Always Enabled
	* @property {String}  CFADestination        Call Forward Always Destination
	* @property {Boolean} CFNREnabled           Call Forward Not Registered Enabled
	* @property {String}  CFNRDestination       Call Forward Not Registered Destination
	* @property {Boolean} RecordInbound         Call Recording Inbound Enabled
	* @property {String}  RecordInboundAddress  Call Recording Inbound Address (SIP address)
	* @property {Boolean} RecordOutbound        Call Recording Outbound Enabled
	* @property {String}  RecordOutboundAddress Call Recording Inbound Destination
	* @property {StringSlice} PhoneNumbers          Phone numbers attached to this SIP Trunk
	* @property {String}  EndpointIPAddress     SIP Destination IP Address
	* @property {String}  EndpointDomain        SIP Destination Domain
	* @property {String}  EndpointUsername      SIP Destination Username
	* @property {String}  EndpointPassword      SIP Destination Password
	*
	*/
	PurgeSIPTrunkByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Purge)
		return axios.delete(`/v3/${req.SPID}/sip_trunk/${req.UUID}/purge`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveSIPTrunk', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('PurgeSIPTrunkByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('PurgeSIPTrunkByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('PurgeSIPTrunkByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('PurgeSIPTrunkByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in PurgeSIPTrunkByUUID'))
			})
	},

	/**
	* RPC Method: UpdateSIPTrunk
	* Description: Update a single SIPTrunk object
	* HTTP Method: PUT
	* API Path: /api/v3/${req.SPID}/sip_trunk/${req.UUID}
	* @param    {SIPTrunk} req The API payload object (SIPTrunk)
	* @return   {SIPTrunk} The API response object (SIPTrunk)
	*
	* @typedef  {Object}  SIPTrunk
	* @property {String}  UUID                  
	* @property {Number}  SPID                  Default Service Provider ID
	* @property {Number}  CreatedAtNanos        When the object was first created
	* @property {Number}  UpdatedAtNanos        When the object was last updated
	* @property {Number}  DeletedAtNanos        When the object was deleted
	* @property {Boolean} Enabled               Is this SIP Trunk enabled?
	* @property {String}  Username              The SIP username for this trunk
	* @property {Boolean} Registered            Is this trunk registered?
	* @property {String}  Password              SIP Password
	* @property {String}  Description           Text description of this trunk
	* @property {String}  PilotNumber           Pilot Number for this trunk
	* @property {Boolean} Trunking              
	* @property {Boolean} MenuActive            
	* @property {Boolean} DoNotDisturb          
	* @property {Boolean} Private               
	* @property {Boolean} BlockPrivateNumbers   
	* @property {Boolean} SendCallerID          
	* @property {Boolean} CFNAEnabled           Call Forward Not Available Enabled
	* @property {Number}  CFNARingTime          Call Forward Not Available Ring Time (in seconds)
	* @property {String}  CFNADestination       Call Forward Not Available Destination
	* @property {Boolean} CFBEnabled            Call Forward on Busy Enabled
	* @property {String}  CFBDestination        Call Forward on Busy Destination
	* @property {Boolean} CFAEnabled            Call Forward Always Enabled
	* @property {String}  CFADestination        Call Forward Always Destination
	* @property {Boolean} CFNREnabled           Call Forward Not Registered Enabled
	* @property {String}  CFNRDestination       Call Forward Not Registered Destination
	* @property {Boolean} RecordInbound         Call Recording Inbound Enabled
	* @property {String}  RecordInboundAddress  Call Recording Inbound Address (SIP address)
	* @property {Boolean} RecordOutbound        Call Recording Outbound Enabled
	* @property {String}  RecordOutboundAddress Call Recording Inbound Destination
	* @property {StringSlice} PhoneNumbers          Phone numbers attached to this SIP Trunk
	* @property {String}  EndpointIPAddress     SIP Destination IP Address
	* @property {String}  EndpointDomain        SIP Destination Domain
	* @property {String}  EndpointUsername      SIP Destination Username
	* @property {String}  EndpointPassword      SIP Destination Password
	*
	*/
	UpdateSIPTrunk({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Update)
		return axios.put(`/v3/${req.SPID}/sip_trunk/${req.UUID}`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveSIPTrunk', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('UpdateSIPTrunk ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('UpdateSIPTrunk ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('UpdateSIPTrunk ERROR:', error)
					return Promise.reject(error)
				}
				console.log('UpdateSIPTrunk UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in UpdateSIPTrunk'))
			})
	},

	/**
	* RPC Method: GetSipTrunkNumbersBySipTrunkUUID
	* Description: Get a numbers for a specific sip trunk
	* HTTP Method: GET
	* API Path: /api/v3/${req.SPID}/sip_trunk/numbers/${req.SipTrunkUUID}
	* @param    {SipTrunkNumbersBySipTrunkUUID} req The API payload object (SipTrunkNumbersBySipTrunkUUID)
	* @return   {SipTrunkNumbersResponse} The API response object (SipTrunkNumbersResponse)
	*
	* @typedef  {Object}  SipTrunkNumbersBySipTrunkUUID
	* @property {PageInfo} PageInfo     
	* @property {String}  SipTrunkUUID 
	* @property {Number}  SPID         
	*
	* @typedef  {Object}  SipTrunkNumbersResponse
	* @property {SIPTrunkNumber[]} SipTrunkNumbers 
	*
	*/
	GetSipTrunkNumbersBySipTrunkUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.get(`/v3/${req.SPID}/sip_trunk/numbers/${req.SipTrunkUUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetSipTrunkNumbersBySipTrunkUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetSipTrunkNumbersBySipTrunkUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetSipTrunkNumbersBySipTrunkUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetSipTrunkNumbersBySipTrunkUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetSipTrunkNumbersBySipTrunkUUID'))
			})
	},

	/**
	* RPC Method: BulkEditSipTrunkNumber
	* Description: Bulk edit a bunch of sip trunk numbers by provided uuid
	* HTTP Method: PUT
	* API Path: /api/v3/${req.SPID}/sip_trunk/numbers/bulk_edit
	* @param    {BulkEditSipTrunkNumberRequest} req The API payload object (BulkEditSipTrunkNumberRequest)
	* @return   {SipTrunkNumbersResponse} The API response object (SipTrunkNumbersResponse)
	*
	* @typedef  {Object}  BulkEditSipTrunkNumberRequest
	* @property {SIPTrunkNumber[]} SIPTrunkNumbers 
	* @property {Number}  SPID            
	*
	* @typedef  {Object}  SipTrunkNumbersResponse
	* @property {SIPTrunkNumber[]} SipTrunkNumbers 
	*
	*/
	BulkEditSipTrunkNumber({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.put(`/v3/${req.SPID}/sip_trunk/numbers/bulk_edit`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('BulkEditSipTrunkNumber ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('BulkEditSipTrunkNumber ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('BulkEditSipTrunkNumber ERROR:', error)
					return Promise.reject(error)
				}
				console.log('BulkEditSipTrunkNumber UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in BulkEditSipTrunkNumber'))
			})
	},
};

export default {
	namespaced: true,
	state: initialState,
	getters,
	mutations,
	actions,
};