<template>
    <div id="app" :class="{ 'is-touch-device': is_touch_device }">
        <app-toast-list />
        <router-view />
    </div>
</template>

<script>
import appToastList from '@/components/app-toast-list'

import isTouchDevice from '@/helpers/is-touch-device'

let timeout = null

export default {
    components: {
        appToastList,
    },

    mounted() {
        if (this.is_touch_device) {
            document.addEventListener('touchstart', this.onTouchStart)
            document.addEventListener('touchend', this.onTouchEnd)
        }
    },

    methods: {
        onTouchStart(event) {
            const target = event.target

            if (target.classList.contains('btn') && target.classList.contains('btn-calm')) {
                target.classList.remove('btn-calm')
            }
        },

        onTouchEnd(event) {
            const delay_in_ms = 150

            if (timeout) {
                clearTimeout(timeout)
            }

            timeout = setTimeout(() => {
                const target = event.target

                if (target.classList.contains('btn')) {
                    target.classList.add('btn-calm')
                }
            }, delay_in_ms)
        },
    },

    computed: {
        is_touch_device() {
            return isTouchDevice()
        },
    },
}
</script>

<style lang="scss">
#app {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    min-height: 100%;
}
</style>