import axiosUnique from '@/utils/axiosUnique';
import StoreHash from '@/utils/StoreHash';
import axios from 'axios';


let hashed = {
    fwa_services: {
        uuids: {},
        lifetime: 5 * 60 * 1000, // 5 min
    }
}

const initialState = {
    service_profile_options: {},
    product_catalog_options: {},

    last_order: null,
    fwa_services: {},
    fwa_services_pageinfo: null,
    fwa_product_orders: null,
    fwa_product_orders_pageinfo: null,
    fwa_product_instances: null,
    fwa_product_instances_pageinfo: null,
    default_pageinfo: {
        PageNumber: 0,
        PageItemCount: 0,
        TotalPages: 0,
        TotalItemCount: 0,
        IndexStart: 0,
        PageSize: 10,
        DisplayPageList: [],
        DisplayPageEitherSide: 4,
    },
};

const getters = {
    getServiceProfileByUUID: state => uuid => {
        return state.service_profile_options[uuid]
    },
    getProductCatalogByUUID: state => uuid => {
        return state.product_catalog_options[uuid]
    },

    getFWAServiceByUUID: state => uuid => state.fwa_services[uuid],

    last_order: state => state.last_order ? state.last_order : null,
    fwa_services: state => state.fwa_services ? state.fwa_services : {},
    fwa_services_pageinfo: state => state.fwa_services_pageinfo ? state.fwa_services_pageinfo : state.default_pageinfo,
    fwa_product_orders: state => state.fwa_product_orders ? state.fwa_product_orders : [],
    fwa_product_orders_pageinfo: state => state.fwa_product_orders_pageinfo ? state.fwa_product_orders_pageinfo : state.default_pageinfo,
    fwa_product_instances: state => state.fwa_product_instances ? state.fwa_product_instances : [],
    fwa_product_instances_pageinfo: state => state.fwa_product_instances_pageinfo ? state.fwa_product_instances_pageinfo : state.default_pageinfo,
};

const mutations = {
    saveServiceProfileOptions(state, options) {
        for (let i = 0, len = options.length; i < len; i++) {
            state.service_profile_options[options[i].UUID] =  options[i]
        }
    },

    saveProductCatalogOptions(state, options) {
        for (let i = 0, len = options.length; i < len; i++) {
            state.product_catalog_options[options[i].UUID] =  options[i]
        }
    },

    // mut_last_order stores the last order placed
    mut_last_order(state, lastorder) {
        state.last_order = lastorder
    },

    // mut_set_fwa_services stores the list of fetched fwa_services
    mut_set_fwa_services(state, apidata) {
        state.fwa_services = apidata.ServiceInstances
        state.fwa_services_pageinfo = apidata.PageInfo

        const services = apidata.ServiceInstances

        let services_list = {}

        for (let n = 0, len = services.length; n < len; n++) {
            const fwa_service = services[n]
            const uuid = fwa_service.UUID

            if (!(uuid in hashed.fwa_services.uuids)) {
                hashed.fwa_services.uuids[ uuid ] = new StoreHash(hashed.fwa_services.lifetime)
            }
            
            services_list[ uuid ] = fwa_service

            hashed.fwa_services.uuids[ uuid ].fix()
        }

        if (Object.keys(services_list).length) {
            state.fwa_services = {
                ...state.fwa_services,
                ...services_list
            }
        }
    },
    mut_set_fwa_service(state, service) {
        const uuid = service.UUID

        if (!(uuid in hashed.fwa_services.uuids)) {
            hashed.fwa_services.uuids[ uuid ] = new StoreHash(hashed.fwa_services.lifetime)
        }

        state.fwa_services = {
            ...state.fwa_services,
            [ uuid ]: service
        }

        hashed.fwa_services.uuids[ uuid ].fix()
    },

    deleteFWAServiceByUUID(state, uuid) {
        if (uuid in hashed.fwa_services.uuids) {
            delete hashed.fwa_services.uuids[ uuid ]
        }

        if (uuid in state.fwa_services) {
            delete state.fwa_services[ uuid ]
        }
    },

    // mut_set_fwa_product_orders stores the list of fetched FWA Product Order
    mut_set_fwa_product_orders(state, apiresp) {
        state.fwa_product_orders = apiresp.ProductOrders
        state.fwa_product_orders_pageinfo = apiresp.PageInfo
    },

    // mut_set_fwa_product_instances stores the list of fetched FWA Product Instances
    mut_set_fwa_product_instances(state, apiresp) {
        if (apiresp.ProductOrders) {
            state.fwa_product_instances = apiresp.ProductOrders
        }
        if (apiresp.ProductInstances) {
            state.fwa_product_instances = apiresp.ProductInstances
        }
        state.fwa_product_instances_pageinfo = apiresp.PageInfo
    },

    resetHashedFWA(state) {
        state.service_profile_options = {}
        state.product_catalog_options = {}
    
        state.last_order = null
        state.fwa_services = {}
        state.fwa_services_pageinfo = null
        state.fwa_product_orders = null
        state.fwa_product_orders_pageinfo = null
        state.fwa_product_instances = null
        state.fwa_product_instances_pageinfo = null
        state.default_pageinfo = {
            PageNumber: 0,
            PageItemCount: 0,
            TotalPages: 0,
            TotalItemCount: 0,
            IndexStart: 0,
            PageSize: 10,
            DisplayPageList: [],
            DisplayPageEitherSide: 4,
        }
    },
};

const actions = {
    getServiceProfileOptions({ commit }) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                import('./hardcoded-test-data/fwa/service-profile-options.json')
                    .then(response => {
                        const PageInfo = response.default.PageInfo
                        const Options = response.default.Options

                        commit('saveServiceProfileOptions', Options)

                        resolve({ PageInfo, Options })
                    })
                    .catch(error => {
                        reject(error)
                    })
            }, 1000)
        })

        /*
            const params = {
                'SearchOptions.PageSize': -1,
            }
    
            return axiosUnique.get('/v2/:spid/fwa/service-profile-options', params)
               .then(response => {
                   const PageInfo = response.apidata.PageInfo
                   const Options = response.apidata.Options
    
                   resolve({ PageInfo, Options })
               })
               .catch(error => {
                   return Promise.reject(error)
               })
        */
    },

    getProductCatalogOptions({ commit }) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                import('./hardcoded-test-data/fwa/product-catalog-options.json')
                    .then(response => {
                        const PageInfo = response.default.PageInfo
                        const Options = response.default.Options

                        commit('saveProductCatalogOptions', Options)

                        resolve({ PageInfo, Options })
                    })
                    .catch(error => {
                        reject(error)
                    })
            }, 2000)
        })

        /*
            const params = {
                'SearchOptions.PageSize': -1,
            }
    
            return axiosUnique.get('/v2/:spid/fwa/product-catalog-options', params)
                .then(response => {
                    const PageInfo = response.apidata.PageInfo
                    const Options = response.apidata.Options
    
                    resolve({ PageInfo, Options })
                })
                .catch(error => {
                    return Promise.reject(error)
                })
        */
    },

    // OrderFWA makes the API call to order FWA
    OrderFWA({ rootGetters, commit }, partialPayload) {
        if (!partialPayload.SPID) {
            requestparams.SPID = rootGetters['current_spid']
        }
        if (!partialPayload.SPID) {
            console.log('OrderFWA ERROR: No SPID provided')
            return Promise.reject(Error('No SPID provided'))
        }
        if (!partialPayload.CustomerUUID) {
            partialPayload.CustomerUUID = "12341234"
        }
        if (!partialPayload.CustomerUUID) {
            console.log('OrderFWA ERROR: No CustomerUUID provided')
            return Promise.reject(Error('No CustomerUUID provided'))
        }
        // if (!partialPayload.ReferenceCode) {
        //     console.log('OrderFWA ERROR: No ReferenceCode provided')
        //     return Promise.reject(Error('No ReferenceCode provided'))
        // }
        if (!partialPayload.ServiceProfile) {
            console.log('OrderFWA ERROR: No ServiceProfile provided')
            return Promise.reject(Error('No ServiceProfile provided'))
        }
        if (!partialPayload.IMSI) {
            console.log('OrderFWA ERROR: No IMSI provided')
            return Promise.reject(Error('No IMSI provided'))
        }
        if (!partialPayload.MSISDN) {
            console.log('OrderFWA ERROR: No MSISDN provided')
            return Promise.reject(Error('No MSISDN provided'))
        }
        if (!partialPayload.ProductCatalogUUID) {
            console.log('OrderFWA ERROR: No ProductCatalogUUID provided')
            return Promise.reject(Error('No ProductCatalogUUID provided'))
        }
        const payload = {
            SPID: partialPayload.SPID,
            CustomerUUID: partialPayload.CustomerUUID,
            ReferenceCode: partialPayload.ReferenceCode,
            ServiceProfile: partialPayload.ServiceProfile,
            AutoAllocateIMSI: false,
            IMSI: partialPayload.IMSI,
            AutoAllocateMSISDN: false,
            MSISDN: partialPayload.MSISDN,
            ProductCatalogUUID: partialPayload.ProductCatalogUUID,
        }
        return axios.post(`/v1/${payload.SPID}/customer/${payload.CustomerUUID}/FWA`, payload).then(response => {
            if (!response.data) {
                return Promise.reject(Error('no payload'))
            }
            commit('mut_last_order', response.data)
            return Promise.resolve(response)
        })
            .catch(error => {
                if (error) {
                    console.log('OrderFWA ERROR:', error)
                    return Promise.reject(error)
                }
                return Promise.reject(Error('unknown error in OrderFWA'))
            })
    },

    // getFWAServices makes the API call to order FWA
    getFWAServices({ rootGetters, commit }, requestparams = {}) {
        const SPID = requestparams.SPID || rootGetters['current_spid']
        
        if (SPID) {
            let params = {
                SPID,
                ServiceType: 'mobility',
                ProductType: 'FWA',
            }

            if (requestparams.SearchOptions) {
                if (requestparams.SearchOptions.PageNumber) {
                    params['SearchOptions.PageNumber'] = requestparams.SearchOptions.PageNumber
                }
                if (requestparams.SearchOptions.PageSize) {
                    params['SearchOptions.PageSize'] = requestparams.SearchOptions.PageSize
                }
            }

            return axiosUnique.get(`/v1/${ SPID }/service_instance`, { params }).then(({apidata}) => {
                if (apidata) {
                    commit('mut_set_fwa_services', apidata)
                    
                    return Promise.resolve(apidata)
                } else {
                    return Promise.reject(new Error('no response data'))
                }
            })
            .catch(error => Promise.reject(error || new Error('unknown error in getFWAServices')))
        } else {
            console.log('getFWAServices ERROR: No SPID provided')
            return Promise.reject(new Error('No SPID provided'))
        }
    },

    getFWAServicesByUUID({ rootGetters, getters, commit }, { uuid, refresh }) {
        const SPID = rootGetters['current_spid']

        if (SPID) {
            if (!(uuid in hashed.fwa_services.uuids)) {
                hashed.fwa_services.uuids[ uuid ] = new StoreHash(hashed.fwa_services.lifetime)
            }

            if (refresh || hashed.fwa_services.uuids[ uuid ].expired()) {
                let params = {
                    SPID,
                    UUID: uuid,
                    ServiceType: 'mobility',
                    ProductType: 'FWA',
                }

                return axiosUnique.get(`/v1/${ SPID }/service_instance`, { params }).then(({apidata}) => {
                    const service = apidata && apidata.ServiceInstances ? apidata.ServiceInstances[0] : null

                    if (service) {
                        commit('mut_set_fwa_service', service)

                        return Promise.resolve(getters.getFWAServiceByUUID(uuid))
                    } else {
                        return Promise.reject(new Error('no response data'))
                    }
                }).catch(error => {
                    if (error) {
                        console.log('getFWAServices ERROR:', error)
                        return Promise.reject(error)
                    }
                    return Promise.reject(new Error('unknown error in getFWAServices'))
                })
            } else {
                return Promise.resolve(getters.getFWAServiceByUUID(uuid))
            }
        } else {
            console.log('getFWAServices ERROR: No SPID provided')
            return Promise.reject(new Error('No SPID provided'))
        }
    },

    deleteFWAServiceByUUID({ commit }, { uuid }) {

        return new Promise((resolve, reject) => {
            setTimeout(() => {
                import('./hardcoded-test-data/fwa/successfully-deleted-fwa-service.json')
                    .then(response => {
                        const service = response.default

                        commit('deleteFWAServiceByUUID', uuid)

                        resolve({ service })
                    })
                    .catch(error => {
                        reject(error)
                    })
            }, 2000)
        })

        /*
            return axiosUnique.delete(`/v2/:spid/fwa/service/${ uuid }`)
                .then(({ apidata }) => {
                    const service = apidata

                    commit('deleteFWAServiceByUUID', uuid)

                    return Promise.resolve({ service })
                })
                .catch(error => {
                    return Promise.reject(error)
                })
        */
    },

    // GetFWAProductOrders makes the API call to order FWA
    GetFWAProductOrders({ rootGetters, commit }, requestparams) {
        if (!requestparams) {
            requestparams = {}
        }
        if (!requestparams.SPID) {
            requestparams.SPID = rootGetters['current_spid']
        }
        if (!requestparams.SPID) {
            console.log('GetFWAProductOrders ERROR: No SPID provided')
            return Promise.reject(Error('No SPID provided'))
        }
        let queryparams = {
            SPID: requestparams.SPID,
            // ServiceType: 'mobility',
            // ProductType: 'FWA',
        }
        if (requestparams.SearchOptions) {
            if (requestparams.SearchOptions.PageNumber) {
                queryparams['SearchOptions.PageNumber'] = requestparams.SearchOptions.PageNumber
            }
            if (requestparams.SearchOptions.PageSize) {
                queryparams['SearchOptions.PageSize'] = requestparams.SearchOptions.PageSize
            }
        }
        return axios.get(`/v1/${requestparams.SPID}/product_order`, { params: queryparams }).then(response => {
            if (!response.data) {
                return Promise.reject(Error('no requestparams'))
            }
            commit('mut_set_fwa_product_orders', response.data)
            return Promise.resolve(response)
        })
            .catch(error => {
                if (error) {
                    console.log('GetFWAProductOrders ERROR:', error)
                    return Promise.reject(error)
                }
                return Promise.reject(Error('unknown error in GetFWAProductOrders'))
            })
    },
    // GetFWAProductInstances makes the API call to order FWA
    GetFWAProductInstances({ rootGetters, commit }, requestparams) {
        if (!requestparams) {
            requestparams = {}
        }
        if (!requestparams.SPID) {
            requestparams.SPID = rootGetters['current_spid']
        }
        if (!requestparams.SPID) {
            console.log('GetFWAProductInstances ERROR: No SPID provided')
            return Promise.reject(Error('No SPID provided'))
        }
        let queryparams = {
            SPID: requestparams.SPID,
            // ServiceType: 'mobility',
            // ProductType: 'FWA',
        }
        if (requestparams.SearchOptions) {
            if (requestparams.SearchOptions.PageNumber) {
                queryparams['SearchOptions.PageNumber'] = requestparams.SearchOptions.PageNumber
            }
            if (requestparams.SearchOptions.PageSize) {
                queryparams['SearchOptions.PageSize'] = requestparams.SearchOptions.PageSize
            }
        }
        return axios.get(`/v1/${requestparams.SPID}/product_instance`, { params: queryparams }).then(response => {
            if (!response.data) {
                return Promise.reject(Error('no requestparams'))
            }
            commit('mut_set_fwa_product_instances', response.data)
            return Promise.resolve(response)
        })
            .catch(error => {
                if (error) {
                    console.log('GetFWAProductInstances ERROR:', error)
                    return Promise.reject(error)
                }
                return Promise.reject(Error('unknown error in GetFWAProductInstances'))
            })
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    mutations,
    actions,
};