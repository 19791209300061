import axios from 'axios'
import axiosUnique from '@/utils/axiosUnique'

import StoreHash from '@/utils/StoreHash'

let hashed = {
    digital_comms_templates: {
        uuids: {},
        lifetime: 5 * 60 * 1000, // 5 min
    },

    digital_comms_emails: {
        uuids: {},
        lifetime: 5 * 60 * 1000, // 5 min
    },

    digital_comms_configuration: new StoreHash(5 * 60 * 1000), // 5 min,

    digital_comms_templates_ids: new StoreHash(5 * 60 * 1000), // 5 min,
}

const state = {
    digital_comms_templates: {},
    digital_comms_emails: {},

    digital_comms_configuration: {
        email: {},
        sms: {},
    },

    digital_comms_templates_ids: [],
}

const getters = {
    getDigitalCommsTemplateByUUID: state => uuid => state.digital_comms_templates[uuid],
    getDigitalCommsTemplateEmailByUUID: state => uuid => state.digital_comms_emails[uuid],
    
    digital_comms_configuration: state => state.digital_comms_configuration,
    digital_comms_templates_ids: state => state.digital_comms_templates_ids,
}

const mutations = {
    saveDigitalCommsTemplates(state, templates) {
        let templates_list = {}

        for (let n = 0, len = templates.length; n < len; n++) {
            const digital_comms_template = templates[n]
            const uuid = digital_comms_template.UUID

            if (!(uuid in hashed.digital_comms_templates.uuids)) {
                hashed.digital_comms_templates.uuids[ uuid ] = new StoreHash(hashed.digital_comms_templates.lifetime)
            }
            
            templates_list[ uuid ] = digital_comms_template

            hashed.digital_comms_templates.uuids[ uuid ].fix()
        }

        if (Object.keys(templates_list).length) {
            state.digital_comms_templates = {
                ...state.digital_comms_templates,
                ...templates_list
            }
        }
    },

    saveDigitalCommsTemplate(state, template) {
        const uuid = template.UUID

        if (!(uuid in hashed.digital_comms_templates.uuids)) {
            hashed.digital_comms_templates.uuids[ uuid ] = new StoreHash(hashed.digital_comms_templates.lifetime)
        }

        state.digital_comms_templates = {
            ...state.digital_comms_templates,
            [ uuid ]: template
        }

        hashed.digital_comms_templates.uuids[ uuid ].fix()
    },

    saveDigitalCommsTemplateEmail(state, { uuid, email }) {
        if (!(uuid in hashed.digital_comms_emails.uuids)) {
            hashed.digital_comms_emails.uuids[ uuid ] = new StoreHash(hashed.digital_comms_emails.lifetime)
        }

        state.digital_comms_emails = {
            ...state.digital_comms_emails,
            [ uuid ]: email
        }

        hashed.digital_comms_emails.uuids[ uuid ].fix()
    },

    saveDigitalCommsTemplateSMS(state, { uuid, sms }) {
        if (uuid in state.digital_comms_templates) {
            state.digital_comms_templates = {
                ...state.digital_comms_templates,
                [ uuid ]: {
                    ...state.digital_comms_templates[uuid],
                    SMS: sms
                },
            }
        }
    },

    saveDigitalCommsConfiguration(state, { Email, SMS }) {
        state.digital_comms_configuration = {
            email: {
                ...state.digital_comms_configuration.email,
                ...(Email || {})
            },
            sms: {
                ...state.digital_comms_configuration.sms,
                ...(SMS || {})
            },
        }
        
        hashed.digital_comms_configuration.fix()
    },

    saveDigitalCommsTemplatesIDs(state, ids) {
        state.digital_comms_templates_ids = ids.map(id => ({...id, Title: `${ id.TemplateID } - ${ id.Name }`}))

        hashed.digital_comms_templates_ids.fix()
    },

    resetHashedDigitalComms(state) {
        state.digital_comms_templates = {}
        state.digital_comms_emails = {}
        state.digital_comms_configuration = {
            email: {},
            sms: {},
        }
        state.digital_comms_templates_ids = []

        hashed.digital_comms_templates.uuids = {}
        hashed.digital_comms_configuration.reset()
        hashed.digital_comms_templates_ids.reset()
    },
}

const actions = {
    getDigitalCommsTemplates({commit}, { params }) {
        /**
         * @todo Replace 2 corresponding lines below for real data
         */
        return import('./hardcoded-test-data/digital-comms/digital-comms-templates-list.json').then(response => {
            const templates = response.default.Templates

        // return axiosUnique.get(`/v2/:spid/digital-comms/templates`, { params }).then(response => {
        //     const templates = response.apidata.Templates

            commit('saveDigitalCommsTemplates', templates)

            return Promise.resolve(response)
        }).catch(error => {
            return Promise.reject(error)
        })
    },

    getDigitalCommsTemplateByUUID({getters, commit}, { uuid, refresh }) {
        if (!(uuid in hashed.digital_comms_templates.uuids)) {
            hashed.digital_comms_templates.uuids[ uuid ] = new StoreHash(hashed.digital_comms_templates.lifetime)
        }

        if (refresh || hashed.digital_comms_templates.uuids[ uuid ].expired()) {
            /**
             * @todo Replace 5 corresponding lines below for real data
             */
            return import('./hardcoded-test-data/digital-comms/digital-comms-template-by-uuid.json').then(apidata => {
                const template = apidata.default
                      template.UUID = uuid

                commit('saveDigitalCommsTemplate', template)

                return Promise.resolve(getters.getDigitalCommsTemplateByUUID(uuid))
                
            // return axiosUnique.get(`/v2/:spid/digital-comms/template/${ uuid }`).then(({apidata}) => {
            //     const template = apidata

                commit('saveDigitalCommsTemplate', template)
                return Promise.resolve(getters.getDigitalCommsTemplateByUUID(uuid))
            }).catch(error => {
                return Promise.reject(error)
            })
        } else {
            return Promise.resolve(getters.getDigitalCommsTemplateByUUID(uuid))
        }
    },

    getDigitalCommsTemplateEmailByUUID({getters, commit}, { uuid, refresh }) {
        if (!(uuid in hashed.digital_comms_emails.uuids)) {
            hashed.digital_comms_emails.uuids[ uuid ] = new StoreHash(hashed.digital_comms_emails.lifetime)
        }

        if (refresh || hashed.digital_comms_emails.uuids[ uuid ].expired()) {
            /**
             * @todo Replace 5 corresponding lines below for real data
             */
            return import(`./hardcoded-test-data/digital-comms/emails/${ uuid }.mjml`).then(apidata => {
                const email = apidata.default

                commit('saveDigitalCommsTemplateEmail', { uuid, email })

                return Promise.resolve(getters.getDigitalCommsTemplateEmailByUUID(uuid))
                
            // return axiosUnique.get(`/v2/:spid/digital-comms/template/${ uuid }/email`).then(({apidata}) => {
            //     const email = apidata

                commit('saveDigitalCommsTemplateEmail', { uuid, email })
                return Promise.resolve(getters.getDigitalCommsTemplateEmailByUUID(uuid))
            }).catch(error => {
                return Promise.reject(error)
            })
        } else {
            return Promise.resolve(getters.getDigitalCommsTemplateEmailByUUID(uuid))
        }
    },

    updateDigitalCommsTemplate({getters, commit}, { uuid, payload }) {
        /**
         * @todo Replace for real data
         */
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                commit('saveDigitalCommsTemplate', {...getters.getDigitalCommsTemplateByUUID(uuid), ...payload })

                resolve(getters.getDigitalCommsTemplateByUUID(uuid))
            }, 250)
        })

        return axios.put(`/v2/:spid/digital-comms/template/${ uuid }`, payload).then(({apidata}) => {
            const template = apidata

            commit('saveDigitalCommsTemplate', template)

            return Promise.resolve(getters.getDigitalCommsTemplateByUUID(uuid))
        }).catch(error => {
            return Promise.reject(error)
        })
    },

    updateDigitalCommsTemplateSMS({dispatch}, { uuid, sms }) {
        const payload = {
            SMS: sms,
        }

        return dispatch('updateDigitalCommsTemplate', { uuid, payload })
    },

    getDigitalCommsConfiguration({getters, commit}) {
        if (hashed.digital_comms_configuration.expired()) {
            /**
             * @todo Replace 2 corresponding lines below for real data
             */
            return import('./hardcoded-test-data/digital-comms/digital-comms-configuration.json').then(response => {
                const configuration = response.default.Configuration

            // return axiosUnique.get(`/v2/:spid/digital-comms/configuration`).then(apidata => {
            //     const configuration = apidata.Configuration

                commit('saveDigitalCommsConfiguration', configuration)

                return Promise.resolve(getters.digital_comms_configuration)
            }).catch(error => {
                return Promise.reject(error)
            })
        } else {
            return Promise.resolve(getters.digital_comms_configuration)
        }
    },

    updateDigitalCommsConfiguration({getters, commit}, { email, sms }) {
        const payload = {}

        if (email) { payload.Email = email }
        if (sms)   { payload.SMS   = sms   }

        if (Object.keys(payload).length) {
            /**
             * @todo Replace for real data
             */
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    commit('saveDigitalCommsConfiguration', payload)

                    resolve(getters.digital_comms_configuration)
                }, 250)
            })

            return axios.put(`/v2/:spid/digital-comms/configuration`, payload).then(({apidata}) => {
                const configuration = apidata.Configuration

                commit('saveDigitalCommsConfiguration', configuration)

                return Promise.resolve(getters.digital_comms_configuration)
            }).catch(error => {
                return Promise.reject(error)
            })
        } else {
            Promise.reject(new Error('Configuration not provided'))
        }
    },

    getDigitalCommsTemplatesIDs({getters, commit}) {
        if (hashed.digital_comms_templates_ids.expired()) {
            /**
             * @todo Replace 2 corresponding lines below for real data
             */
            return import('./hardcoded-test-data/digital-comms/digital-comms-templates-ids.json').then(response => {
                const ids = response.default

            // return axiosUnique.get(`/v2/:spid/digital-comms/templates/ids`).then(({apidata}) => {
            //     const ids = apidata

                commit('saveDigitalCommsTemplatesIDs', ids)

                return Promise.resolve(getters.digital_comms_templates_ids)
            }).catch(error => Promise.reject(error))
        } else {
            return Promise.resolve(getters.digital_comms_templates_ids)
        }
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
}