const state = {
    locations: {},
}

const getters = {
    locations: state => state.locations,
}

const mutations = {
    saveLocations(state, locations) {
        state.locations = {
            ...locations,
        }
    },

    deleteLocation(state, key) {
        delete state.locations[key]

        state.locations = {
            ...state.locations,
        }
    },
}

const actions = {}

export default {
    namespaced: true,

    state,
    getters,
    mutations,
    actions,
}