// Code generated by protoc-gen-iqwebapi. DO NOT EDIT.
// source: asap.proto

import axios from 'axios';
import StoreHash from '@/utils/StoreHash';

const initialState = {
	ASAPConfigs: {
		UUIDs: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
	ASAPConfigSearches: {
		SearchTexts: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
	ASAPWorkflowObjects: {
		UUIDs: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
	ASAPWorkflowObjectSearches: {
		SearchTexts: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
};

const valFuncFind = function (status) {
	return (status == 200) ? true : (status == 404) ? true : false;
}

const default_pageinfo = {
	PageNumber: 0,
	PageItemCount: 0,
	TotalPages: 0,
	TotalItemCount: 0,
	IndexStart: 0,
	PageSize: 10,
	DisplayPageList: [],
	DisplayPageEitherSide: 4,
}

const getters = {
	getASAPConfigByUUID: state => (UUID) => (state.ASAPConfigs.UUIDs[ UUID ] && !state.ASAPConfigs.UUIDs[ UUID ].expired()) ?  state.ASAPConfigs.UUIDs[ UUID ].hashedData() : null,
	getASAPConfigsForSearchText: state => (SearchText) => state.ASAPConfigSearches[ SearchText ] ? state.ASAPConfigSearches[ SearchText ] : null,
	getASAPWorkflowObjectByUUID: state => (UUID) => (state.ASAPWorkflowObjects.UUIDs[ UUID ] && !state.ASAPWorkflowObjects.UUIDs[ UUID ].expired()) ?  state.ASAPWorkflowObjects.UUIDs[ UUID ].hashedData() : null,
	getASAPWorkflowObjectsForSearchText: state => (SearchText) => state.ASAPWorkflowObjectSearches[ SearchText ] ? state.ASAPWorkflowObjectSearches[ SearchText ] : null,
};

const mutations = {
	// mutSaveASAPConfig saves a single ASAPConfig object
	// into both the StoreHash cache and the VueX store
	mutSaveASAPConfig(state, obj) {
		if (!obj.UUID) { return } // Must have UUID to Save

		if (!(obj.UUID in state.ASAPConfigs.UUIDs)) {
			state.ASAPConfigs.UUIDs[ obj.UUID ] = new StoreHash(state.ASAPConfigs.lifetime)
		}
		state.ASAPConfigs.UUIDs[ obj.UUID ].fix(obj) // Add item to StoreHash
	},
	// mutRemoveASAPConfigByUUID removes a ASAPConfig object
	// with the given UUID from StoreHash cache and VueX store
	mutRemoveASAPConfigByUUID(state, UUID) {
		if (!UUID) {
			// Must have UUID to Remove
			return
		}
		delete state.ASAPConfigs.UUIDs[ UUID ]    // remove item from StoreHash
	},
	// mutSaveASAPConfigSearch saves a search response
	// with the given SearchText to the StoreHash cache and VueX store
	mutSaveASAPConfigSearch(state, { SearchText, Response }) {
		if (!SearchText || !Response) {
			// Must have SearchText to Save and Response object
			return
		}
		let storedObj = {
			PageInfo: Object.assign({}, default_pageinfo), // shallow copy of default_pageinfo
			UUIDs: [],
		}
		// Deep copy ??? i.e. JSON.parse(JSON.stringify(obj));
		if (Response.PageInfo) {
			Response.PageInfo = Object.assign({}, Response.PageInfo) // shallow copy of the PageInfo
		}
		if (Array.isArray(Response.ASAPConfigs)) {
			for (let i = 0; i < Response.ASAPConfigs.length; i++) {
				storedObj.UUIDs.push(Response.ASAPConfigs[i].UUID)
			}
		}
		state.ASAPConfigSearches[ SearchText ] = storedObj      // Add search text and list of UUIDs to VueX state

		if (!(SearchText in state.ASAPConfigSearches.SearchTexts)) {
			state.ASAPConfigSearches.SearchTexts[ SearchText ] = new StoreHash(state.ASAPConfigSearches.lifetime)
		}
		state.ASAPConfigSearches.SearchTexts[ SearchText ].fix() // Add item to StoreHash
	},
	// mutRemoveASAPConfigSearch removes a search response
	// with the given SearchText from StoreHash cache and VueX store
	mutRemoveASAPConfigSearch(state, SearchText) {
		if (!SearchText) {
			// Must have SearchText to Remove
			return
		}
		state.ASAPConfigSearches[ SearchText ] = null               // cause VueX to trigger a data cascade
		delete state.ASAPConfigSearches[ SearchText ]               // remove item from VueX state
		delete state.ASAPConfigSearches.SearchTexts[ SearchText ]  // remove item from StoreHash
	},
	// mutSaveASAPConfigs saves a paginated response of ASAPConfigs
	mutSaveASAPConfigs(state, { Response }) {
		if (!Response || !Array.isArray(Response.ASAPConfigs)) {
			// Must have Response object with results
			return
		}
		for (let i = 0; i < Response.ASAPConfigs.length; i++) {
			if (!(Response.ASAPConfigs[i].UUID in state.ASAPConfigs.UUIDs)) {
				state.ASAPConfigs.UUIDs[ Response.ASAPConfigs[i].UUID ] = new StoreHash(state.ASAPConfigs.lifetime)
			}
			state.ASAPConfigs.UUIDs[ Response.ASAPConfigs[i].UUID ].fix(Response.ASAPConfigs[i])  // Add single ASAPConfig to StoreHash
		}
	},
	// mutSaveASAPWorkflowObject saves a single ASAPWorkflowObject object
	// into both the StoreHash cache and the VueX store
	mutSaveASAPWorkflowObject(state, obj) {
		if (!obj.UUID) { return } // Must have UUID to Save

		if (!(obj.UUID in state.ASAPWorkflowObjects.UUIDs)) {
			state.ASAPWorkflowObjects.UUIDs[ obj.UUID ] = new StoreHash(state.ASAPWorkflowObjects.lifetime)
		}
		state.ASAPWorkflowObjects.UUIDs[ obj.UUID ].fix(obj) // Add item to StoreHash
	},
	// mutRemoveASAPWorkflowObjectByUUID removes a ASAPWorkflowObject object
	// with the given UUID from StoreHash cache and VueX store
	mutRemoveASAPWorkflowObjectByUUID(state, UUID) {
		if (!UUID) {
			// Must have UUID to Remove
			return
		}
		delete state.ASAPWorkflowObjects.UUIDs[ UUID ]    // remove item from StoreHash
	},
	// mutSaveASAPWorkflowObjectSearch saves a search response
	// with the given SearchText to the StoreHash cache and VueX store
	mutSaveASAPWorkflowObjectSearch(state, { SearchText, Response }) {
		if (!SearchText || !Response) {
			// Must have SearchText to Save and Response object
			return
		}
		let storedObj = {
			PageInfo: Object.assign({}, default_pageinfo), // shallow copy of default_pageinfo
			UUIDs: [],
		}
		// Deep copy ??? i.e. JSON.parse(JSON.stringify(obj));
		if (Response.PageInfo) {
			Response.PageInfo = Object.assign({}, Response.PageInfo) // shallow copy of the PageInfo
		}
		if (Array.isArray(Response.ASAPWorkflowObjects)) {
			for (let i = 0; i < Response.ASAPWorkflowObjects.length; i++) {
				storedObj.UUIDs.push(Response.ASAPWorkflowObjects[i].UUID)
			}
		}
		state.ASAPWorkflowObjectSearches[ SearchText ] = storedObj      // Add search text and list of UUIDs to VueX state

		if (!(SearchText in state.ASAPWorkflowObjectSearches.SearchTexts)) {
			state.ASAPWorkflowObjectSearches.SearchTexts[ SearchText ] = new StoreHash(state.ASAPWorkflowObjectSearches.lifetime)
		}
		state.ASAPWorkflowObjectSearches.SearchTexts[ SearchText ].fix() // Add item to StoreHash
	},
	// mutRemoveASAPWorkflowObjectSearch removes a search response
	// with the given SearchText from StoreHash cache and VueX store
	mutRemoveASAPWorkflowObjectSearch(state, SearchText) {
		if (!SearchText) {
			// Must have SearchText to Remove
			return
		}
		state.ASAPWorkflowObjectSearches[ SearchText ] = null               // cause VueX to trigger a data cascade
		delete state.ASAPWorkflowObjectSearches[ SearchText ]               // remove item from VueX state
		delete state.ASAPWorkflowObjectSearches.SearchTexts[ SearchText ]  // remove item from StoreHash
	},
	// mutSaveASAPWorkflowObjects saves a paginated response of ASAPWorkflowObjects
	mutSaveASAPWorkflowObjects(state, { Response }) {
		if (!Response || !Array.isArray(Response.ASAPWorkflowObjects)) {
			// Must have Response object with results
			return
		}
		for (let i = 0; i < Response.ASAPWorkflowObjects.length; i++) {
			if (!(Response.ASAPWorkflowObjects[i].UUID in state.ASAPWorkflowObjects.UUIDs)) {
				state.ASAPWorkflowObjects.UUIDs[ Response.ASAPWorkflowObjects[i].UUID ] = new StoreHash(state.ASAPWorkflowObjects.lifetime)
			}
			state.ASAPWorkflowObjects.UUIDs[ Response.ASAPWorkflowObjects[i].UUID ].fix(Response.ASAPWorkflowObjects[i])  // Add single ASAPWorkflowObject to StoreHash
		}
	},
};

const actions = {
	// getCachedASAPConfigByUUID fetches a ASAPConfig from the cache
	// and if not in the cache, fetches it from the API
	getCachedASAPConfigByUUID({ state, dispatch, commit }, { UUID, RefreshCache }) {
		if (!RefreshCache && state.ASAPConfigs.UUIDs[ UUID ] && !state.ASAPConfigs.UUIDs[ UUID ].expired()) {
			// Return the cached record
			return state.ASAPConfigs.UUIDs[ UUID ].hashedData()
		}
		// Fetch from the API
		return dispatch('GetASAPConfigByUUID', { UUID }).then(respPayload => {
			commit('mutSaveASAPConfig', respPayload)
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveASAPConfigByUUID', UUID)
			return Promise.reject(error)
		})
	},

	// getCachedASAPConfigsBySearch fetches ASAPConfigs from the cache
	// and if not in the cache, fetches it from the API
	getCachedASAPConfigsBySearch({ state, getters, dispatch, commit }, { SPID, SearchText, RefreshCache }) {
		if (!RefreshCache && state.ASAPConfigSearches.SearchTexts[ SearchText ] && !state.ASAPConfigSearches.SearchTexts[ SearchText ].expired()) {
			// Return the cached records
			let resp = getters.getASAPConfigsForSearchText(SearchText)
			resp.ASAPConfigs = []
			for (let i = 0; i < resp.UUIDs.length; i++) {
				resp.ASAPConfigs.push(getters.getASAPConfigByUUID(resp.UUIDs[i]))
			}
			return Promise.resolve(resp)
		}
		// Search using the API
		const apiReq = {
			SPID: SPID,
			SmartSearch: SearchText,
		}
		return dispatch('GetASAPConfigsPaginated', apiReq).then(respPayload => {
			commit('mutSaveASAPConfigSearch', { SearchText: SearchText, Response: respPayload })
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveASAPConfigSearch', SearchText)
			return Promise.reject(error)
		})
	},

	// getCachedASAPWorkflowObjectByUUID fetches a ASAPWorkflowObject from the cache
	// and if not in the cache, fetches it from the API
	getCachedASAPWorkflowObjectByUUID({ state, dispatch, commit }, { UUID, RefreshCache }) {
		if (!RefreshCache && state.ASAPWorkflowObjects.UUIDs[ UUID ] && !state.ASAPWorkflowObjects.UUIDs[ UUID ].expired()) {
			// Return the cached record
			return state.ASAPWorkflowObjects.UUIDs[ UUID ].hashedData()
		}
		// Fetch from the API
		return dispatch('GetASAPWorkflowObjectByUUID', { UUID }).then(respPayload => {
			commit('mutSaveASAPWorkflowObject', respPayload)
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveASAPWorkflowObjectByUUID', UUID)
			return Promise.reject(error)
		})
	},

	// getCachedASAPWorkflowObjectsBySearch fetches ASAPWorkflowObjects from the cache
	// and if not in the cache, fetches it from the API
	getCachedASAPWorkflowObjectsBySearch({ state, getters, dispatch, commit }, { SPID, SearchText, RefreshCache }) {
		if (!RefreshCache && state.ASAPWorkflowObjectSearches.SearchTexts[ SearchText ] && !state.ASAPWorkflowObjectSearches.SearchTexts[ SearchText ].expired()) {
			// Return the cached records
			let resp = getters.getASAPWorkflowObjectsForSearchText(SearchText)
			resp.ASAPWorkflowObjects = []
			for (let i = 0; i < resp.UUIDs.length; i++) {
				resp.ASAPWorkflowObjects.push(getters.getASAPWorkflowObjectByUUID(resp.UUIDs[i]))
			}
			return Promise.resolve(resp)
		}
		// Search using the API
		const apiReq = {
			SPID: SPID,
			SmartSearch: SearchText,
		}
		return dispatch('GetASAPWorkflowObjectsPaginated', apiReq).then(respPayload => {
			commit('mutSaveASAPWorkflowObjectSearch', { SearchText: SearchText, Response: respPayload })
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveASAPWorkflowObjectSearch', SearchText)
			return Promise.reject(error)
		})
	},

	//
	// Service: ASAPConfigRPC
	// 
	/**
	* RPC Method: AddASAPConfig
	* Description: Add a ASAPConfig object
	* HTTP Method: POST
	* API Path: /api/v3/asap/${req.VFEnvironment}/config
	* @param    {ASAPConfig} req The API payload object (ASAPConfig)
	* @return   {ASAPConfig} The API response object (ASAPConfig)
	*
	* @typedef  {Object}  ASAPConfig
	* @property {String}  UUID           
	* @property {Number}  SPID           Default Service Provider ID
	* @property {Number}  CreatedAtNanos When the object was first created
	* @property {Number}  UpdatedAtNanos When the object was last updated
	* @property {Number}  DeletedAtNanos When the object was deleted
	* @property {String}  Description    Object Description
	* @property {Number}  FromMSISDN     
	* @property {Number}  ToMSISDN       
	* @property {String}  ProductCode    
	* @property {String}  ServiceNode    
	* @property {String}  SMSBillingId   
	* @property {Boolean} ExceptionFlag  
	* @property {String}  OutdialPrefix  
	* @property {String}  VFEnvironment  
	*
	*/
	AddASAPConfig({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Add)
		return axios.post(`/v3/asap/${req.VFEnvironment}/config`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('AddASAPConfig ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('AddASAPConfig ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('AddASAPConfig ERROR:', error)
					return Promise.reject(error)
				}
				console.log('AddASAPConfig UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in AddASAPConfig'))
			})
	},

	/**
	* RPC Method: CreateNewASAPWorkflow
	* Description: Creartes a new ASAP workflow from the suplied XML payload
	* HTTP Method: PUT
	* API Path: /api/v3/asap/${req.VFEnvironment}/workflow
	* @param    {ASAPWorkflowRequest} req The API payload object (ASAPWorkflowRequest)
	* @return   {ASAPWorkflowResponse} The API response object (ASAPWorkflowResponse)
	*
	* @typedef  {Object}  ASAPWorkflowRequest
	* @property {Number}  SPID          
	* @property {String}  UUID          (optional) UUID
	* @property {String}  OrderType     
	* @property {String}  Payload       
	* @property {String}  VFEnvironment 
	*
	* @typedef  {Object}  ASAPWorkflowResponse
	* @property {Number}  SPID          
	* @property {String}  UUID          (optional) UUID
	* @property {String}  OrderType     
	* @property {String}  Payload       
	* @property {String}  Status        
	* @property {String}  VFEnvironment 
	*
	*/
	CreateNewASAPWorkflow({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.put(`/v3/asap/${req.VFEnvironment}/workflow`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('CreateNewASAPWorkflow ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('CreateNewASAPWorkflow ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('CreateNewASAPWorkflow ERROR:', error)
					return Promise.reject(error)
				}
				console.log('CreateNewASAPWorkflow UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in CreateNewASAPWorkflow'))
			})
	},

	/**
	* RPC Method: DeleteASAPConfigByUUID
	* Description: Deletes a ASAPConfig object. Requires JWT
	* HTTP Method: DELETE
	* API Path: /api/v3/asap/${req.VFEnvironment}/config/${req.UUID}
	* @param    {ASAPConfigUUIDSearch} req The API payload object (ASAPConfigUUIDSearch)
	* @return   {ASAPConfig} The API response object (ASAPConfig)
	*
	* @typedef  {Object}  ASAPConfigUUIDSearch
	* @property {String}  UUID          
	* @property {Number}  SPID          Service Provider ID - always required
	* @property {String}  Description   (optional) Description
	* @property {Number}  FromMSISDN    
	* @property {Number}  ToMSISDN      
	* @property {String}  ProductCode   
	* @property {String}  VFEnvironment 
	*
	* @typedef  {Object}  ASAPConfig
	* @property {String}  UUID           
	* @property {Number}  SPID           Default Service Provider ID
	* @property {Number}  CreatedAtNanos When the object was first created
	* @property {Number}  UpdatedAtNanos When the object was last updated
	* @property {Number}  DeletedAtNanos When the object was deleted
	* @property {String}  Description    Object Description
	* @property {Number}  FromMSISDN     
	* @property {Number}  ToMSISDN       
	* @property {String}  ProductCode    
	* @property {String}  ServiceNode    
	* @property {String}  SMSBillingId   
	* @property {Boolean} ExceptionFlag  
	* @property {String}  OutdialPrefix  
	* @property {String}  VFEnvironment  
	*
	*/
	DeleteASAPConfigByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Delete)
		return axios.delete(`/v3/asap/${req.VFEnvironment}/config/${req.UUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveASAPConfig', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('DeleteASAPConfigByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('DeleteASAPConfigByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('DeleteASAPConfigByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('DeleteASAPConfigByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in DeleteASAPConfigByUUID'))
			})
	},

	/**
	* RPC Method: GetASAPConfigByUUID
	* Description: Get a single ASAPConfig object using the UUID
	* HTTP Method: GET
	* API Path: /api/v3/asap/${req.VFEnvironment}/config/${req.UUID}
	* @param    {ASAPConfigUUIDSearch} req The API payload object (ASAPConfigUUIDSearch)
	* @return   {ASAPConfig} The API response object (ASAPConfig)
	*
	* @typedef  {Object}  ASAPConfigUUIDSearch
	* @property {String}  UUID          
	* @property {Number}  SPID          Service Provider ID - always required
	* @property {String}  Description   (optional) Description
	* @property {Number}  FromMSISDN    
	* @property {Number}  ToMSISDN      
	* @property {String}  ProductCode   
	* @property {String}  VFEnvironment 
	*
	* @typedef  {Object}  ASAPConfig
	* @property {String}  UUID           
	* @property {Number}  SPID           Default Service Provider ID
	* @property {Number}  CreatedAtNanos When the object was first created
	* @property {Number}  UpdatedAtNanos When the object was last updated
	* @property {Number}  DeletedAtNanos When the object was deleted
	* @property {String}  Description    Object Description
	* @property {Number}  FromMSISDN     
	* @property {Number}  ToMSISDN       
	* @property {String}  ProductCode    
	* @property {String}  ServiceNode    
	* @property {String}  SMSBillingId   
	* @property {Boolean} ExceptionFlag  
	* @property {String}  OutdialPrefix  
	* @property {String}  VFEnvironment  
	*
	*/
	GetASAPConfigByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Get)
		// Prepare URI params...
		let paramVFEnvironment = encodeURIComponent(req.VFEnvironment)
		let paramUUID = encodeURIComponent(req.UUID)
		return axios.get(`/v3/asap/${paramVFEnvironment}/config/${paramUUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveASAPConfig', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetASAPConfigByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetASAPConfigByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetASAPConfigByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetASAPConfigByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetASAPConfigByUUID'))
			})
	},

	/**
	* RPC Method: GetASAPConfigsPaginated
	* Description: Searches for multiple ASAPConfig objects using a search request
	* HTTP Method: GET
	* API Path: /api/v3/asap/${req.VFEnvironment}/config
	* @param    {ASAPConfigSearchRequest} req The API payload object (ASAPConfigSearchRequest)
	* @return   {ASAPConfigPageResponse} The API response object (ASAPConfigPageResponse)
	*
	* @typedef  {Object}  ASAPConfigSearchRequest
	* @property {SearchOptions[]} SearchOptions  Search options to use (for sorting and/or pagination)
	* @property {Boolean} IncludeDeleted IncludeDeleted field present on the search request ensures that deleted records (records with DeleteAtNanos > 0) are not returned unless this is set
	* @property {Number}  SPID           (optional) SPID
	* @property {String}  Description    (optional) Description
	* @property {Number}  FromMSISDN     
	* @property {Number}  ToMSISDN       
	* @property {String}  ProductCode    
	* @property {String}  VFEnvironment  
	*
	* @typedef  {Object}  SearchOptions  Search options
	* @property {Number}  PageNumber     The page number to start at (or provide IndexStart)
	* @property {Number}  PageSize       The page size
	* @property {Number}  IndexStart     The record number to start at (or provide PageNumber)
	* @property {String}  SortBy         The field to sort by
	* @property {Boolean} SortDesc       Is the sort direction descending?
	*
	* @typedef  {Object}  ASAPConfigPageResponse
	* @property {PageInfo} PageInfo    PageInfo only needs the values ThisPageNumber && PageSize set
	* @property {ASAPConfig[]} ASAPConfigs 
	*
	* @typedef  {Object}   PageInfo
	* @property {Number}   PageNumber             The page number
	* @property {Number}   PageItemCount          Number of items on this page
	* @property {Number}   TotalPages             Total pages available
	* @property {Number}   TotalItemCount         Total items available
	* @property {Number}   IndexStart             The record number this search started at
	* @property {Number}   PageSize               The length of the page
	* @property {Number[]} DisplayPageList        The array of page numbers to display
	* @property {Number}   DisplayPagesEitherSide Number of pages to display either side
	*
	*/
	GetASAPConfigsPaginated({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Find)
		// Prepare URI params...
		let paramVFEnvironment = encodeURIComponent(req.VFEnvironment)
		return axios.get(`/v3/asap/${paramVFEnvironment}/config`, { params: req, validateStatus: valFuncFind }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			commit('mutSaveASAPConfigs', { Response: response.data })
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetASAPConfigsPaginated ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetASAPConfigsPaginated ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetASAPConfigsPaginated ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetASAPConfigsPaginated UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetASAPConfigsPaginated'))
			})
	},

	/**
	* RPC Method: PurgeASAPConfigByUUID
	* Description: Purges a ASAPConfig object. Requires JWT
	* HTTP Method: DELETE
	* API Path: /api/v3/asap/${req.VFEnvironment}/config/${req.UUID}/purge
	* @param    {ASAPConfigUUIDSearch} req The API payload object (ASAPConfigUUIDSearch)
	* @return   {ASAPConfig} The API response object (ASAPConfig)
	*
	* @typedef  {Object}  ASAPConfigUUIDSearch
	* @property {String}  UUID          
	* @property {Number}  SPID          Service Provider ID - always required
	* @property {String}  Description   (optional) Description
	* @property {Number}  FromMSISDN    
	* @property {Number}  ToMSISDN      
	* @property {String}  ProductCode   
	* @property {String}  VFEnvironment 
	*
	* @typedef  {Object}  ASAPConfig
	* @property {String}  UUID           
	* @property {Number}  SPID           Default Service Provider ID
	* @property {Number}  CreatedAtNanos When the object was first created
	* @property {Number}  UpdatedAtNanos When the object was last updated
	* @property {Number}  DeletedAtNanos When the object was deleted
	* @property {String}  Description    Object Description
	* @property {Number}  FromMSISDN     
	* @property {Number}  ToMSISDN       
	* @property {String}  ProductCode    
	* @property {String}  ServiceNode    
	* @property {String}  SMSBillingId   
	* @property {Boolean} ExceptionFlag  
	* @property {String}  OutdialPrefix  
	* @property {String}  VFEnvironment  
	*
	*/
	PurgeASAPConfigByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Purge)
		return axios.delete(`/v3/asap/${req.VFEnvironment}/config/${req.UUID}/purge`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveASAPConfig', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('PurgeASAPConfigByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('PurgeASAPConfigByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('PurgeASAPConfigByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('PurgeASAPConfigByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in PurgeASAPConfigByUUID'))
			})
	},

	/**
	* RPC Method: UpdateASAPConfig
	* Description: Update a single ASAPConfig object
	* HTTP Method: PUT
	* API Path: /api/v3/asap/${req.VFEnvironment}/config/${req.UUID}
	* @param    {ASAPConfig} req The API payload object (ASAPConfig)
	* @return   {ASAPConfig} The API response object (ASAPConfig)
	*
	* @typedef  {Object}  ASAPConfig
	* @property {String}  UUID           
	* @property {Number}  SPID           Default Service Provider ID
	* @property {Number}  CreatedAtNanos When the object was first created
	* @property {Number}  UpdatedAtNanos When the object was last updated
	* @property {Number}  DeletedAtNanos When the object was deleted
	* @property {String}  Description    Object Description
	* @property {Number}  FromMSISDN     
	* @property {Number}  ToMSISDN       
	* @property {String}  ProductCode    
	* @property {String}  ServiceNode    
	* @property {String}  SMSBillingId   
	* @property {Boolean} ExceptionFlag  
	* @property {String}  OutdialPrefix  
	* @property {String}  VFEnvironment  
	*
	*/
	UpdateASAPConfig({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Update)
		return axios.put(`/v3/asap/${req.VFEnvironment}/config/${req.UUID}`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveASAPConfig', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('UpdateASAPConfig ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('UpdateASAPConfig ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('UpdateASAPConfig ERROR:', error)
					return Promise.reject(error)
				}
				console.log('UpdateASAPConfig UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in UpdateASAPConfig'))
			})
	},

	//
	// Service: ASAPWorkflowRPC
	// 
	/**
	* RPC Method: AddASAPWorkflow
	* Description: Add searchable Workflow object
	* HTTP Method: POST
	* API Path: /api/v3/asap/${req.VFEnvironment}/workflow
	* @param    {ASAPWorkflowObject} req The API payload object (ASAPWorkflowObject)
	* @return   {ASAPWorkflowObject} The API response object (ASAPWorkflowObject)
	*
	* @typedef  {Object}  ASAPWorkflowObject
	* @property {String}  UUID                   
	* @property {Number}  SPID                   Default Service Provider ID
	* @property {Number}  CreatedAtNanos         When the object was first created
	* @property {Number}  UpdatedAtNanos         When the object was last updated
	* @property {Number}  DeletedAtNanos         When the object was deleted
	* @property {String}  WorkflowID             
	* @property {String}  RunID                  
	* @property {Number}  WorkflowStartedNanos   
	* @property {Number}  WorkflowCompletedNanos 
	* @property {String}  Status                 
	* @property {String}  MSISDN                 
	* @property {String}  IMSI                   
	* @property {String}  OrderID                
	* @property {String}  Namespace              
	* @property {String}  VFEnvironment          
	* @property {String}  TemporalEndpoint       
	* @property {String}  WorkflowName           
	* @property {String}  OrderType              
	*
	*/
	AddASAPWorkflow({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.post(`/v3/asap/${req.VFEnvironment}/workflow`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('AddASAPWorkflow ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('AddASAPWorkflow ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('AddASAPWorkflow ERROR:', error)
					return Promise.reject(error)
				}
				console.log('AddASAPWorkflow UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in AddASAPWorkflow'))
			})
	},

	/**
	* RPC Method: GetASAPWorkflowObjectsPaginated
	* Description: Searches for multiple ASAPWorkflow objects using a search request
	* HTTP Method: GET
	* API Path: /api/v3/asap/${req.VFEnvironment}/workflow
	* @param    {ASAPWorkflowObjectSearchRequest} req The API payload object (ASAPWorkflowObjectSearchRequest)
	* @return   {ASAPWorkflowObjectPageResponse} The API response object (ASAPWorkflowObjectPageResponse)
	*
	* @typedef  {Object}  ASAPWorkflowObjectSearchRequest
	* @property {SearchOptions[]} SearchOptions  Search options to use (for sorting and/or pagination)
	* @property {Boolean} IncludeDeleted IncludeDeleted field present on the search request ensures that deleted records (records with DeleteAtNanos > 0) are not returned unless this is set
	* @property {Number}  SPID           (optional) SPID
	* @property {String}  SmartSearch    (optional) Description
	* @property {String}  VFEnvironment  
	* @property {String}  WorkflowID     
	* @property {String}  RunID          
	* @property {String}  Status         
	* @property {String}  MSISDN         
	* @property {String}  IMSI           
	* @property {String}  OrderID        
	* @property {String}  WorkflowName   
	* @property {String}  OrderType      
	*
	* @typedef  {Object}  SearchOptions  Search options
	* @property {Number}  PageNumber     The page number to start at (or provide IndexStart)
	* @property {Number}  PageSize       The page size
	* @property {Number}  IndexStart     The record number to start at (or provide PageNumber)
	* @property {String}  SortBy         The field to sort by
	* @property {Boolean} SortDesc       Is the sort direction descending?
	*
	* @typedef  {Object}  ASAPWorkflowObjectPageResponse
	* @property {PageInfo} PageInfo            PageInfo only needs the values ThisPageNumber && PageSize set
	* @property {ASAPWorkflowObject[]} ASAPWorkflowObjects 
	*
	* @typedef  {Object}   PageInfo
	* @property {Number}   PageNumber             The page number
	* @property {Number}   PageItemCount          Number of items on this page
	* @property {Number}   TotalPages             Total pages available
	* @property {Number}   TotalItemCount         Total items available
	* @property {Number}   IndexStart             The record number this search started at
	* @property {Number}   PageSize               The length of the page
	* @property {Number[]} DisplayPageList        The array of page numbers to display
	* @property {Number}   DisplayPagesEitherSide Number of pages to display either side
	*
	*/
	GetASAPWorkflowObjectsPaginated({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Find)
		// Prepare URI params...
		let paramVFEnvironment = encodeURIComponent(req.VFEnvironment)
		return axios.get(`/v3/asap/${paramVFEnvironment}/workflow`, { params: req, validateStatus: valFuncFind }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			commit('mutSaveASAPWorkflowObjects', { Response: response.data })
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetASAPWorkflowObjectsPaginated ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetASAPWorkflowObjectsPaginated ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetASAPWorkflowObjectsPaginated ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetASAPWorkflowObjectsPaginated UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetASAPWorkflowObjectsPaginated'))
			})
	},
};

export default {
	namespaced: true,
	state: initialState,
	getters,
	mutations,
	actions,
};