let uuid_counter = 0

const state = {
    toasts: [],
}

const getters = {
    toasts_list: state => state.toasts
}

const mutations = {
    addToast(state, payload) {
        const { uuid, message, type, delay } = payload

        state.toasts.push({ uuid, message, type, delay })
    },

    removeToast(state, payload) {
        const { uuid } = payload

        state.toasts = state.toasts.filter(toast => toast.uuid != uuid)
    },

    clearAllToasts(state) {
        state.toasts = []
    },
}

const actions = {
    addToast({ commit }, { message, type = 'success', delay = 0 }) {
        commit('addToast', { uuid: ++uuid_counter, message, type, delay })
    },

    removeToast({ commit }, { uuid }) {
        commit('removeToast', { uuid })
    },

    clearAllToasts({ commit }) {
        commit('clearAllToasts')
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
}