// Code generated by protoc-gen-iqwebapi. DO NOT EDIT.
// source: service.proto

import axios from 'axios';
import StoreHash from '@/utils/StoreHash';

const initialState = {
};

const valFuncFind = function (status) {
	return (status == 200) ? true : (status == 404) ? true : false;
}

const default_pageinfo = {
	PageNumber: 0,
	PageItemCount: 0,
	TotalPages: 0,
	TotalItemCount: 0,
	IndexStart: 0,
	PageSize: 10,
	DisplayPageList: [],
	DisplayPageEitherSide: 4,
}

const getters = {
};

const mutations = {
};

const actions = {};

export default {
	namespaced: true,
	state: initialState,
	getters,
	mutations,
	actions,
};