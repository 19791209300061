const API_DOCS_HOST = process.env.VUE_APP_API_DOCS_HOST

const state = {}

const getters = {
    api_docs_host: state => API_DOCS_HOST,
}

const mutations = {}

const actions = {}

export default {
    state,
    getters,
    mutations,
    actions,
}