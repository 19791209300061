// Code generated by protoc-gen-iqwebapi. DO NOT EDIT.
// source: productorder.proto

import axios from 'axios';
import StoreHash from '@/utils/StoreHash';

const initialState = {
	ProductOrders: {
		UUIDs: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
	ProductOrderCharacteristics: {
		UUIDs: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
	ProductOrderStates: {
		UUIDs: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
};

const valFuncFind = function (status) {
	return (status == 200) ? true : (status == 404) ? true : false;
}

const default_pageinfo = {
	PageNumber: 0,
	PageItemCount: 0,
	TotalPages: 0,
	TotalItemCount: 0,
	IndexStart: 0,
	PageSize: 10,
	DisplayPageList: [],
	DisplayPageEitherSide: 4,
}

const getters = {
	getProductOrderByUUID: state => (UUID) => (state.ProductOrders.UUIDs[ UUID ] && !state.ProductOrders.UUIDs[ UUID ].expired()) ?  state.ProductOrders.UUIDs[ UUID ].hashedData() : null,
	getProductOrderCharacteristicByUUID: state => (UUID) => (state.ProductOrderCharacteristics.UUIDs[ UUID ] && !state.ProductOrderCharacteristics.UUIDs[ UUID ].expired()) ?  state.ProductOrderCharacteristics.UUIDs[ UUID ].hashedData() : null,
	getProductOrderStateByUUID: state => (UUID) => (state.ProductOrderStates.UUIDs[ UUID ] && !state.ProductOrderStates.UUIDs[ UUID ].expired()) ?  state.ProductOrderStates.UUIDs[ UUID ].hashedData() : null,
};

const mutations = {
	// mutSaveProductOrder saves a single ProductOrder object
	// into both the StoreHash cache and the VueX store
	mutSaveProductOrder(state, obj) {
		if (!obj.UUID) { return } // Must have UUID to Save

		if (!(obj.UUID in state.ProductOrders.UUIDs)) {
			state.ProductOrders.UUIDs[ obj.UUID ] = new StoreHash(state.ProductOrders.lifetime)
		}
		state.ProductOrders.UUIDs[ obj.UUID ].fix(obj) // Add item to StoreHash
	},
	// mutRemoveProductOrderByUUID removes a ProductOrder object
	// with the given UUID from StoreHash cache and VueX store
	mutRemoveProductOrderByUUID(state, UUID) {
		if (!UUID) {
			// Must have UUID to Remove
			return
		}
		delete state.ProductOrders.UUIDs[ UUID ]    // remove item from StoreHash
	},
	// mutSaveProductOrders saves a paginated response of ProductOrders
	mutSaveProductOrders(state, { Response }) {
		if (!Response || !Array.isArray(Response.ProductOrders)) {
			// Must have Response object with results
			return
		}
		for (let i = 0; i < Response.ProductOrders.length; i++) {
			if (!(Response.ProductOrders[i].UUID in state.ProductOrders.UUIDs)) {
				state.ProductOrders.UUIDs[ Response.ProductOrders[i].UUID ] = new StoreHash(state.ProductOrders.lifetime)
			}
			state.ProductOrders.UUIDs[ Response.ProductOrders[i].UUID ].fix(Response.ProductOrders[i])  // Add single ProductOrder to StoreHash
		}
	},
	// mutSaveProductOrderCharacteristic saves a single ProductOrderCharacteristic object
	// into both the StoreHash cache and the VueX store
	mutSaveProductOrderCharacteristic(state, obj) {
		if (!obj.UUID) { return } // Must have UUID to Save

		if (!(obj.UUID in state.ProductOrderCharacteristics.UUIDs)) {
			state.ProductOrderCharacteristics.UUIDs[ obj.UUID ] = new StoreHash(state.ProductOrderCharacteristics.lifetime)
		}
		state.ProductOrderCharacteristics.UUIDs[ obj.UUID ].fix(obj) // Add item to StoreHash
	},
	// mutRemoveProductOrderCharacteristicByUUID removes a ProductOrderCharacteristic object
	// with the given UUID from StoreHash cache and VueX store
	mutRemoveProductOrderCharacteristicByUUID(state, UUID) {
		if (!UUID) {
			// Must have UUID to Remove
			return
		}
		delete state.ProductOrderCharacteristics.UUIDs[ UUID ]    // remove item from StoreHash
	},
	// mutSaveProductOrderState saves a single ProductOrderState object
	// into both the StoreHash cache and the VueX store
	mutSaveProductOrderState(state, obj) {
		if (!obj.UUID) { return } // Must have UUID to Save

		if (!(obj.UUID in state.ProductOrderStates.UUIDs)) {
			state.ProductOrderStates.UUIDs[ obj.UUID ] = new StoreHash(state.ProductOrderStates.lifetime)
		}
		state.ProductOrderStates.UUIDs[ obj.UUID ].fix(obj) // Add item to StoreHash
	},
	// mutRemoveProductOrderStateByUUID removes a ProductOrderState object
	// with the given UUID from StoreHash cache and VueX store
	mutRemoveProductOrderStateByUUID(state, UUID) {
		if (!UUID) {
			// Must have UUID to Remove
			return
		}
		delete state.ProductOrderStates.UUIDs[ UUID ]    // remove item from StoreHash
	},
};

const actions = {
	// getCachedProductOrderByUUID fetches a ProductOrder from the cache
	// and if not in the cache, fetches it from the API
	getCachedProductOrderByUUID({ state, dispatch, commit }, { UUID, RefreshCache }) {
		if (!RefreshCache && state.ProductOrders.UUIDs[ UUID ] && !state.ProductOrders.UUIDs[ UUID ].expired()) {
			// Return the cached record
			return state.ProductOrders.UUIDs[ UUID ].hashedData()
		}
		// Fetch from the API
		return dispatch('GetProductOrderByUUID', { UUID }).then(respPayload => {
			commit('mutSaveProductOrder', respPayload)
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveProductOrderByUUID', UUID)
			return Promise.reject(error)
		})
	},

	// getCachedProductOrderCharacteristicByUUID fetches a ProductOrderCharacteristic from the cache
	// and if not in the cache, fetches it from the API
	getCachedProductOrderCharacteristicByUUID({ state, dispatch, commit }, { UUID, RefreshCache }) {
		if (!RefreshCache && state.ProductOrderCharacteristics.UUIDs[ UUID ] && !state.ProductOrderCharacteristics.UUIDs[ UUID ].expired()) {
			// Return the cached record
			return state.ProductOrderCharacteristics.UUIDs[ UUID ].hashedData()
		}
		// Fetch from the API
		return dispatch('GetProductOrderCharacteristicByUUID', { UUID }).then(respPayload => {
			commit('mutSaveProductOrderCharacteristic', respPayload)
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveProductOrderCharacteristicByUUID', UUID)
			return Promise.reject(error)
		})
	},

	// getCachedProductOrderStateByUUID fetches a ProductOrderState from the cache
	// and if not in the cache, fetches it from the API
	getCachedProductOrderStateByUUID({ state, dispatch, commit }, { UUID, RefreshCache }) {
		if (!RefreshCache && state.ProductOrderStates.UUIDs[ UUID ] && !state.ProductOrderStates.UUIDs[ UUID ].expired()) {
			// Return the cached record
			return state.ProductOrderStates.UUIDs[ UUID ].hashedData()
		}
		// Fetch from the API
		return dispatch('GetProductOrderStateByUUID', { UUID }).then(respPayload => {
			commit('mutSaveProductOrderState', respPayload)
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveProductOrderStateByUUID', UUID)
			return Promise.reject(error)
		})
	},

	//
	// Service: ProductOrderRPC
	// 
	/**
	* RPC Method: FindProductOrdersPaginated
	* Description: Search through product and filter by various values provided below
	* HTTP Method: GET
	* API Path: /api/v1/${req.SPID}/product_order
	* @param    {ProductOrderSearchRequest} req The API payload object (ProductOrderSearchRequest)
	* @return   {ProductOrderSearchResponse} The API response object (ProductOrderSearchResponse)
	*
	* @typedef  {Object}  ProductOrderSearchRequest
	* @property {SearchOptions[]} SearchOptions          Standard search options
	* @property {String}  UUID                   UUID of product order: use get product order by UUID call instead
	* @property {String}  ProductType            ProductType of order, supported types are: FWA
	* @property {ProductOrderState} State                  State of order
	* @property {Number}  SPID                   SPID of order
	* @property {Boolean} InException            If this order is InException (needs attendance)
	* @property {String}  Substate               Search by the SubState of the order
	* @property {String}  ProductInstanceUUID    Search for orders related to a particular product instance
	* @property {String}  SmartSearch            Do a smart search
	* @property {String}  ExternalRef            Search by external ref
	* @property {String}  CustomerUUID           Search by customer uuid
	* @property {String}  ParentProductOrderUUID Search by ParentProductOrderUUID
	*
	* @typedef  {Object}  SearchOptions  Search options
	* @property {Number}  PageNumber     The page number to start at (or provide IndexStart)
	* @property {Number}  PageSize       The page size
	* @property {Number}  IndexStart     The record number to start at (or provide PageNumber)
	* @property {String}  SortBy         The field to sort by
	* @property {Boolean} SortDesc       Is the sort direction descending?
	*
	* @typedef  {Object}  ProductOrderSearchResponse
	* @property {PageInfo} PageInfo      PageInfo only needs the values ThisPageNumber && PageSize set
	* @property {ProductOrder[]} ProductOrders List of product orders whuch match the search
	*
	* @typedef  {Object}   PageInfo
	* @property {Number}   PageNumber             The page number
	* @property {Number}   PageItemCount          Number of items on this page
	* @property {Number}   TotalPages             Total pages available
	* @property {Number}   TotalItemCount         Total items available
	* @property {Number}   IndexStart             The record number this search started at
	* @property {Number}   PageSize               The length of the page
	* @property {Number[]} DisplayPageList        The array of page numbers to display
	* @property {Number}   DisplayPagesEitherSide Number of pages to display either side
	*
	*/
	FindProductOrdersPaginated({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Find)
		// Prepare URI params...
		let paramSPID = encodeURIComponent(req.SPID)
		return axios.get(`/v1/${paramSPID}/product_order`, { params: req, validateStatus: valFuncFind }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			commit('mutSaveProductOrders', { Response: response.data })
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('FindProductOrdersPaginated ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('FindProductOrdersPaginated ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('FindProductOrdersPaginated ERROR:', error)
					return Promise.reject(error)
				}
				console.log('FindProductOrdersPaginated UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in FindProductOrdersPaginated'))
			})
	},

	/**
	* RPC Method: GetProductOrderStateHistoryCharacteristicsActivityLogByUUID
	* Description: Get a product order with all details including state history and activity log
	* HTTP Method: GET
	* API Path: /api/v1/${req.SPID}/product_order/${req.UUID}
	* @param    {GetProductOrderByUUIDRequest} req The API payload object (GetProductOrderByUUIDRequest)
	* @return   {GetProductOrderByUUIDResponse} The API response object (GetProductOrderByUUIDResponse)
	*
	* @typedef  {Object}  GetProductOrderByUUIDRequest
	* @property {Number}  SPID         SPID
	* @property {String}  UUID         UUID of the product order
	* @property {String}  CustomerUUID Customer UUID of the product order
	*
	* @typedef  {Object}  GetProductOrderByUUIDResponse
	* @property {Number}  SPID            SPID
	* @property {String}  UUID            UUID of the order
	* @property {ProductOrder} Order           The base order record
	* @property {ProductOrderState} State           The current state (& substate) of the order
	* @property {ProductOrderState[]} History         The state history of the order
	* @property {ProductOrderCharacteristic[]} Characteristics The characteristics of the order
	*
	*/
	GetProductOrderStateHistoryCharacteristicsActivityLogByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		// Prepare URI params...
		let paramSPID = encodeURIComponent(req.SPID)
		let paramUUID = encodeURIComponent(req.UUID)
		return axios.get(`/v1/${paramSPID}/product_order/${paramUUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetProductOrderStateHistoryCharacteristicsActivityLogByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetProductOrderStateHistoryCharacteristicsActivityLogByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetProductOrderStateHistoryCharacteristicsActivityLogByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetProductOrderStateHistoryCharacteristicsActivityLogByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetProductOrderStateHistoryCharacteristicsActivityLogByUUID'))
			})
	},
};

export default {
	namespaced: true,
	state: initialState,
	getters,
	mutations,
	actions,
};