import axiosUnique from '@/utils/axiosUnique'

const state = {
    tv_households_search_results: {
        PageInfo: null,
        SearchResults: null,
    },
}

const getters = {
    tv_households_search_results: state => state.tv_households_search_results,
}

const mutations = {
    saveTVHouseholdsSearchResults(state, { PageInfo, SearchResults }) {
        state.tv_households_search_results.PageInfo = PageInfo
        state.tv_households_search_results.SearchResults = SearchResults
    },

    clearTVHouseholdsSearchResults(state) {
        state.tv_households_search_results.PageInfo = null
        state.tv_households_search_results.SearchResults = null
    }
}

const actions = {
    /**
     * Получение данных для таблицы "Search results" на странице "/tv/households/kaltura/search-results/:page(\\d+)?"
     */
    getTVHouseholdsSearchResults({}, { params }) {
        console.group();

        console.log('getTVHouseholdsSearchResults()')
        console.log(params)

        console.groupEnd();

        return new Promise((resolve, reject) => {
            const delay_in_ms = 2000

            setTimeout(() => {
                import('./hardcoded-test-data/tv/households/kaltura/search-results-list.json')
                    .then(response => {
                        const PageInfo = response.default.PageInfo
                        const SearchResults = response.default.SearchResults

                        resolve({ PageInfo, SearchResults })
                    })
                    .catch(error => {
                        reject(error)
                    })
            }, delay_in_ms)
        })

        /*
            return axiosUnique.get('/v2/:spid/tv/households/kaltura', { params })
                .then(({ apidata }) => {
                    const PageInfo = apidata.PageInfo
                    const SearchResults = apidata.SearchResults

                    return Promise.resolve({ PageInfo, SearchResults })
                })
                .catch(error => {
                    return Promise.reject(error)
                })
        */
    },


    /**
     * Получение данных для таблицы "Local Households" на странице "/tv/households/local/:page(\\d+)?"
     */
    getLocalHouseholds({}, { params }) {
        console.group();

        console.log('getLocalHouseholds()')
        console.log(params)

        console.groupEnd();

        return new Promise((resolve, reject) => {
            const delay_in_ms = 2000

            setTimeout(() => {
                import('./hardcoded-test-data/tv/households/local/local-households-list.json')
                    .then(response => {
                        const PageInfo = response.default.PageInfo
                        const LocalHouseholds = response.default.LocalHouseholds

                        resolve({ PageInfo, LocalHouseholds })
                    })
                    .catch(error => {
                        reject(error)
                    })
            }, delay_in_ms)
        })

        /*
            return axiosUnique.get('/v2/:spid/tv/households/local', { params })
                .then(({ apidata }) => {
                    const PageInfo = apidata.PageInfo
                    const LocalHouseholds = apidata.LocalHouseholds

                    return Promise.resolve({ PageInfo, LocalHouseholds })
                })
                .catch(error => {
                    return Promise.reject(error)
                })
        */
    },

    /**
     * Получение данных для правого сайдбара на странице "Local Households" (/tv/households/local/:page(\\d+)?)
     */
    getCustomerHouseholds({}, { uuid }) {
        console.group();

        console.log('getCustomerHouseholds()')
        console.log(uuid)

        console.groupEnd();

        return new Promise((resolve, reject) => {
            const delay_in_ms = 2000

            setTimeout(() => {
                import('./hardcoded-test-data/tv/households/local/customer-households-list.json')
                    .then(response => {
                        resolve(response.default)
                    })
                    .catch(error => {
                        reject(error)
                    })
            }, delay_in_ms)
        })

        /*
            return axiosUnique.get(`/v2/:spid/tv/households/customer/${ uuid }`)
                .then(({ apidata }) => {
                    return Promise.resolve(apidata)
                })
                .catch(error => {
                    return Promise.reject(error)
                })
        */
    },

    /**
     * Получение данных для страниц "/tv/households/local/:uuid" и "/tv/devices/local/:uuid"
     */
    getCustomerPackages({}, { uuid }) {
        console.group();

        console.log('getCustomerPackages()')
        console.log(uuid)

        console.groupEnd();

        return new Promise((resolve, reject) => {
            const delay_in_ms = 2000

            setTimeout(() => {
                import('./hardcoded-test-data/tv/households/local/customer-packages-list.json')
                    .then(response => {
                        const Packages = response.default.Packages

                        resolve({ Packages })
                    })
                    .catch(error => {
                        reject(error)
                    })
            }, delay_in_ms)
        })

        /*
            return axiosUnique.get(`/v2/:spid/tv/households/customer/${ uuid }/packages`)
                .then(({ apidata }) => {
                    const Packages = apidata.Packages

                    return Promise.resolve({ Packages })
                })
                .catch(error => {
                    return Promise.reject(error)
                })
        */
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
}