import { toHex, formatHex } from 'color-fns'

const VERTICAL_LINES_ENABLED = process.env.VUE_APP_VERTICAL_LINES_ENABLED
    ? isNaN(process.env.VUE_APP_VERTICAL_LINES_ENABLED)
        ? process.env.VUE_APP_VERTICAL_LINES_ENABLED.trim().toLowerCase() == 'true'
        : process.env.VUE_APP_VERTICAL_LINES_ENABLED > 0
    : false

/**
 * @todo добавить цвета для обычного текста
 */
const COLOR_SCHEME = {
    backgrounds: {
        title: 'Backgrounds',
        elements: {
            'main-bg': {
                title: 'Main Background gradient',
                states: {
                    from: '--color-layout-gradient-from',
                    to: '--color-layout-gradient-to',
                },
            },
            'dropdown-bg': {
                title: 'DropDown',
                states: {
                    default: '--color-dropdown-bg',
                    hover: '--color-dropdown-bg-hover',
                },
            },
            'dialog-over': {
                title: 'Modal window Overlay',
                states: {
                    default: [
                        { class: '--color-dialog-over-primary', alpha: 'cc' },
                        { class: '--color-dialog-over-secondary', alpha: 'ff' },
                    ]
                },
            },
        },
    },

    texts: {
        title: 'Texts',
        elements: {
            'h1': {
                title: 'H1',
                states: {
                    default: '--color-text-h1',
                }
            },
            'h2': {
                title: 'H2',
                states: {
                    default: '--color-text-h2',
                }
            },
            'h3': {
                title: 'H3',
                states: {
                    default: '--color-text-h3',
                }
            },
            'h4': {
                title: 'H4',
                states: {
                    default: '--color-text-h4',
                }
            },
        },
    },

    table: {
        title: 'Tables',
        elements: {
            'table-head-bg': {
                title: 'Header Background',
                states: {
                    default: '--color-table-head',
                }
            },
            'table-head-color': {
                title: 'Header Text Color',
                states: {
                    default: '--color-table-head-bg',
                }
            },
            'table-row': {
                title: 'Row Background',
                states: {
                    default: '--color-table-row-bg',
                    hover: '--color-table-row-bg-hover',
                    active: '--color-table-row-bg-active',
                }
            },
            'table-deviders': {
                title: 'Dividers',
                states: {
                    default: '--color-divider',
                }
            },
            'table-tags': {
                title: 'Tags',
                states: {
                    default: '--color-table-tag',
                }
            },
            'table-action-primary': {
                title: 'Primary Action Icons',
                states: {
                    default: '--color-table-action-primary',
                    hover: '--color-table-action-primary-hover',
                    active: '--color-table-action-primary-active',
                }
            },
            'table-action-secondary': {
                title: 'Secondary Action Icons',
                states: {
                    default: '--color-table-action-secondary',
                    hover: '--color-table-action-secondary-hover',
                    active: '--color-table-action-secondary-active',
                }
            },
        },
    },

    buttons: {
        title: 'Buttons',
        elements: {
            'btn-primary-bg': {
                title: 'Main Button Background',
                states: {
                    default: [
                        '--color-btn-primary-bg',
                        '--color-icon-search',
                        '--color-icon-action',
                        '--color-loader',
                    ],
                    hover: '--color-btn-primary-bg-hover',
                    active: '--color-btn-primary-bg-active',
                }
            },
            'btn-primary-color': {
                title: 'Main Button Color',
                states: {
                    default: '--color-btn-primary',
                    hover: '--color-btn-primary-hover',
                    active: '--color-btn-primary-active',
                }
            },

            'btn-secondary-bg': {
                title: 'Secondary Button Background',
                states: {
                    default: '--color-btn-secondary-bg',
                    hover: '--color-btn-secondary-bg-hover',
                    active: '--color-btn-secondary-bg-active',
                }
            },
            'btn-secondary-color': {
                title: 'Secondary Button Color',
                states: {
                    default: '--color-btn-secondary',
                    hover: '--color-btn-secondary-hover',
                    active: '--color-btn-secondary-active',
                }
            },

            'btn-cancel-bg': {
                title: 'Cancel Button Background',
                states: {
                    default: '--color-btn-cancel-bg',
                    hover: '--color-btn-cancel-bg-hover',
                    active: '--color-btn-cancel-bg-active',
                }
            },
            'btn-cancel-color': {
                title: 'Cancel Button Color',
                states: {
                    default: '--color-btn-cancel',
                    hover: '--color-btn-cancel-hover',
                    active: '--color-btn-cancel-active',
                }
            },

            'btn-pagination-bg': {
                title: 'Pagination Button Background',
                states: {
                    default: '--color-pagination-page-bg',
                    hover: '--color-pagination-page-bg-hover',
                    active: '--color-pagination-page-bg-active',
                }
            },
            'btn-pagination-color': {
                title: 'Pagination Button Color',
                states: {
                    default: '--color-pagination-page',
                    hover: '--color-pagination-page-hover',
                    active: '--color-pagination-page-active',
                }
            },
        },
    },

    nav: {
        title: 'Nav-bar',
        elements: {
            'nav-text': {
                title: 'Text',
                states: {
                    default: '--color-nav-bar-text',
                }
            },
            'nav-dropdown': {
                title: 'DropDown',
                states: {
                    default: false,
                    hover: '--color-nav-bar-link-hover',
                    active: '--color-nav-bar-link-active',
                }
            },
            'nav-icons': {
                title: 'Icons',
                states: {
                    default: '--color-nav-bar-icon',
                    hover: '--color-nav-bar-icon-hover',
                    active: '--color-nav-bar-icon-active',
                }
            },
        }
    },

    tabs: {
        title: 'Tabs',
        elements: {
            'tabs': {
                title: 'Tab Color',
                states: {
                    default: '--color-tab',
                    hover: '--color-tab-hover',
                    active: '--color-tab-active',
                }
            },
        }
    },
    
    inputs: {
        title: 'Inputs',
        elements: {
            'input-default-bg': {
                title: 'Input Background',
                states: {
                    default: '--color-input-bg',
                }
            },
            'input-default-border': {
                title: 'Input Border',
                states: {
                    default: '--color-input-border',
                    hover: '--color-input-border-hover',
                    active: '--color-input-border-active',
                }
            },

            'input-search-bg': {
                title: 'Search Input Background',
                states: {
                    default: '--color-search-bg',
                }
            },
            'input-search-border': {
                title: 'Search Input Border',
                states: {
                    default: '--color-search-border',
                    hover: '--color-search-border-hover',
                    active: '--color-search-border-active',
                }
            },
        }
    },

    stages: {
        title: 'Stages',
        elements: {
            'stage-title': {
                title: 'Stage Title',
                states: {
                    default: '--color-stages-title',
                    // hover: true,
                    active: '--color-stages-title-active',
                }
            },
            'stage-icons-bg': {
                title: 'Stage Icons Background',
                states: {
                    default: '--color-stages-icons-bg',
                    // hover: true,
                    active: '--color-stages-icons-bg-active',
                }
            },
            'stage-icons-color': {
                title: 'Stage Icons Color',
                states: {
                    default: '--color-stage-icon',
                    // hover: true,
                    active: '--color-stage-icon-active',
                }
            },
        }
    },

    images: {
        title: 'Images',
        elements: {
            'color-image-main': {
                title: 'Main image color',
                states: {
                    default: '--color-image-main',
                }
            },
            'color-image-secondary-1': {
                title: 'Secondary image color 1',
                states: {
                    default: '--color-image-secondary-1',
                }
            },
            'color-image-secondary-2': {
                title: 'Secondary image color 2',
                states: {
                    default: '--color-image-secondary-2',
                }
            },
        }
    },
}
const COLOR_SCHEME_KEY_GROUP = "branding"

const hashed_colors = {}
const colorWithAlpha = (color_hash, alpha) => {
    const key = `${ color_hash }+${ alpha }`

    if (alpha) {
        alpha = alpha.toLowerCase()
    }

    if (color_hash && color_hash.length > 1) {
        const color = color_hash.substr(1)

        if (!(key in hashed_colors)) {
            const color_length = color.length
            const alpha_length = alpha.length

            if (alpha_length == 1 || alpha_length == 2) {
                if (alpha == 'ff' || alpha == 'f') {
                    switch (color_length) {
                        case 4: { hashed_colors[key] = color.substr(0,3) } break
                        case 8: { hashed_colors[key] = color.substr(0,6) } break
                    }
                } else {
                    switch (color_length) {
                        case 3: {
                            const r = color[0],
                                  g = color[1],
                                  b = color[2]

                            if (alpha_length == 2) {
                                if (alpha[0] == alpha[1]) {
                                    alpha = alpha[0]
                                } else {
                                    r+= r
                                    g+= g
                                    b+= b
                                }
                            }

                            hashed_colors[key] = r + g + b + alpha
                        } break

                        case 6: {
                            const r = color.substr(0, 2),
                                  g = color.substr(2, 2),
                                  b = color.substr(4, 2)

                            if (r[0] == r[1]
                                && g[0] == g[1]
                                && b[0] == b[1]
                                && (
                                    alpha_length == 1
                                    || alpha[0] == alpha[1]
                                )
                            ) {
                                hashed_colors[key] = r[0] + g[0] + b[0] + alpha[0]
                            } else {
                                hashed_colors[key] = r + g + b + alpha + (alpha_length == 1 ? alpha : '')
                            }
                        } break
                    }
                }
            }

            hashed_colors[key] = '#' + (key in hashed_colors ? hashed_colors[key] : color)
        }
    }

    return hashed_colors[key]
}
const normalizeHex = (color) => {
    if (!(color in hashed_colors)) {
        let hex = ''

        if (color) {
            if (color[0] == '#') {
                switch (color.length) {
                    case 4:
                    case 5: {
                        const [r, g, b, a] = color.substr(1).trim('')

                        hex = '#' + r+r + g+g + b+b + (a ? a+a : '')
                    } break

                    case 7:
                    case 9: {
                        hex = color
                    } break
                }
            } else if (color.toLowerCase() != 'transparent') {
                hex = formatHex(toHex(color))
            }
        }

        hashed_colors[color] = (hex[0] == '#' ? hex : '#00000000').toLowerCase()
    }

    return hashed_colors[color]
}

const state = {}

const getters = {
    color_scheme: state => COLOR_SCHEME,
    color_scheme_categories: state => {
        let categories = {}

        for (const key in COLOR_SCHEME) {
            categories[ key ] = {
                key,
                title: COLOR_SCHEME[ key ].title,
            }
        }

        return categories
    },

    color_scheme_key_group: state => COLOR_SCHEME_KEY_GROUP,

    colorSchemeCompactToExtended: state => (scheme, SPID) => {
        // console.group('colorSchemeCompactToExtended: state => (scheme, SPID, KeyGroup) => {')
        // console.log('scheme', scheme)
        // console.log('SPID', SPID)

        let transformed_scheme = []

        for (const group in COLOR_SCHEME) {
            if (COLOR_SCHEME[group].elements) {
                for (const element in COLOR_SCHEME[group].elements) {
                    if (COLOR_SCHEME[group].elements[element].states) {
                        const colors = scheme[element]

                        if (colors) {
                            let index = 0

                            for (const state in COLOR_SCHEME[group].elements[element].states) {
                                if (COLOR_SCHEME[group].elements[element].states[state]) {
                                    const Value = colors[index++]

                                    if (Value) {
                                        const Key = state == 'default' ? element : `${ element }-${ state }`

                                        transformed_scheme.push({
                                            KeyGroup: 'branding',
                                            Key,
                                            Value
                                        })
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        if (SPID != undefined) {
            for (let i = 0, len = transformed_scheme.length; i < len; i++) {
                transformed_scheme[i].SPID = SPID
            }
        }

        // console.log('transformed_scheme', transformed_scheme)
        // console.groupEnd()

        return transformed_scheme
    },

    colorSchemeExtendedToCompact: state => (scheme, expand_with_root, remove_empty, root_styles) => {
        // console.group('colorSchemeExtendedToCompact: state => (scheme, expand_with_root) => {')
        // console.log('scheme', [...scheme])
        // console.log('expand_with_root', expand_with_root)

        let transformed_scheme = {}
        let transformed_scheme_keys = {}
        let transformed_scheme_variables = {}

        for (const group in COLOR_SCHEME) {
            if (COLOR_SCHEME[group].elements) {
                for (const element in COLOR_SCHEME[group].elements) {
                    if (COLOR_SCHEME[group].elements[element].states) {
                        const colors = []

                        for (const state in COLOR_SCHEME[group].elements[element].states) {
                            if (COLOR_SCHEME[group].elements[element].states[state]) {
                                const index_color = colors.length
                                const element_state = COLOR_SCHEME[group].elements[element].states[state]

                                const index_color_position = {
                                    colors,
                                    element,
                                    index: index_color,
                                }
                                
                                transformed_scheme_keys[ state == 'default' ? element : `${ element }-${ state }` ] = index_color_position
                                
                                if (typeof element_state == 'string') {
                                    transformed_scheme_variables[ element_state ] = index_color_position
                                } else if (Array.isArray(element_state)) {
                                    for (let i = 0, len = element_state.length; i < len; i++) {
                                        const element_substate = element_state[i]

                                        if (typeof element_substate == 'string') {
                                            transformed_scheme_variables[ element_substate ] = index_color_position
                                        } else if (element_substate.class) {
                                            transformed_scheme_variables[ element_substate.class ] = {
                                                ...index_color_position,
                                                alpha: element_substate.alpha
                                            }
                                        }
                                    }
                                } else if (element_state.class) {
                                    transformed_scheme_variables[ element_state.class ] = {
                                        ...index_color_position,
                                        alpha: element_state.alpha
                                    }
                                }

                                colors.push(null)
                            }
                        }
                        
                        transformed_scheme[element] = colors
                    }
                }
            }
        }

        // console.log('transformed_scheme', [...transformed_scheme])
        // console.log('transformed_scheme_keys', {...transformed_scheme_keys})
        
        for (let i = 0, len = scheme.length; i < len; i++) {
            const key = scheme[i].Key
            const color = scheme[i].Value
            // console.log('key', 'color', key, color)

            if (key in transformed_scheme_keys) {
                transformed_scheme_keys[ key ].colors[ transformed_scheme_keys[ key ].index ] = normalizeHex(color)
            } else {
                transformed_scheme[key] = [ normalizeHex(color) ]
            }
        }

        // console.log('transformed_scheme_variables', transformed_scheme_variables)
        if (expand_with_root) {
            // console.group('expand_with_root')

            const use_computed_root_styles = !root_styles

            if (use_computed_root_styles) {
                root_styles = getComputedStyle(document.documentElement)
            }

            for (const key in transformed_scheme_variables) {
                if (!transformed_scheme_variables[key].colors[transformed_scheme_variables[key].index]) {
                    // console.log(key, root_styles.getPropertyValue(key).trim())

                    transformed_scheme_variables[key].colors[transformed_scheme_variables[key].index] = use_computed_root_styles
                        ? normalizeHex(root_styles.getPropertyValue(key).trim())
                        : normalizeHex(root_styles[key])
                }
            }

            // console.groupEnd()
        }
        
        if (remove_empty) {
            // console.log('transformed_scheme', {...transformed_scheme})
            for (const key in transformed_scheme) {
                // console.log(key, transformed_scheme[key], transformed_scheme[key].findIndex(color => Boolean(color)))

                if (transformed_scheme[key].findIndex(color => Boolean(color)) < 0) {
                    delete transformed_scheme[key]
                }
            }
        }

        // console.log('transformed_scheme', transformed_scheme)
        // console.groupEnd()

        return transformed_scheme
    },

    colorSchemeCompactToVariables: state => (scheme, remove_duplicate_root, root_styles) => {
        // console.group('colorSchemeCompactToVariables: state => (scheme) => {')
        // console.log('scheme', scheme)

        let scheme_variables = {}

        for (const group in COLOR_SCHEME) {
            if (COLOR_SCHEME[group].elements) {
                for (const element in COLOR_SCHEME[group].elements) {

                    // 'main-bg': {
                    //     title: 'Main Background',
                    //     states: {
                    //         default: [
                    //             '--color-layout-gradient-from',
                    //             '--color-layout-gradient-to',
                    //         ],
                    //     },
                    // },

                    // 'dropdown-bg': {
                    //     title: 'DropDown',
                    //     states: {
                    //         default: '--color-dropdown-bg',
                    //         hover: '--color-dropdown-bg-hover',
                    //     },
                    // },

                    // 'dialog-over': {
                    //     title: 'Modal window Background',
                    //     states: {
                    //         default: [
                    //             { class: '--color-dialog-over-primary', alpha: 'cc' },
                    //             '--color-dialog-over-secondary',
                    //         ]
                    //     },
                    // },

                    if (COLOR_SCHEME[group].elements[element].states
                        && Array.isArray(scheme[element])
                        && scheme[element].length
                    ) {
                        let index_color = 0

                        for (const state in COLOR_SCHEME[group].elements[element].states) {
                            if (COLOR_SCHEME[group].elements[element].states[state]) {
                                const color = normalizeHex(scheme[element][index_color])

                                if (color) {
                                    const element_state = COLOR_SCHEME[group].elements[element].states[state]
                                    
                                    if (typeof element_state == 'string') {
                                        scheme_variables[ element_state ] = color
                                    } else if (Array.isArray(element_state)) {
                                        for (let i = 0, len = element_state.length; i < len; i++) {
                                            const element_substate = element_state[i]

                                            if (typeof element_substate == 'string') {
                                                scheme_variables[ element_substate ] = color
                                            } else if (element_substate.class) {
                                                scheme_variables[ element_substate.class ] = element_substate.alpha
                                                ? colorWithAlpha(color, element_substate.alpha)
                                                : color
                                            }
                                        }
                                    } else if (element_state.class) {
                                        scheme_variables[ element_state.class ] = element_state.alpha
                                            ? colorWithAlpha(color, element_state.alpha)
                                            : color
                                    }
                                }

                                index_color++
                            }
                        }
                    }
                }
            }
        }

        // console.log('scheme_variables', {...scheme_variables})

        if (remove_duplicate_root) {
            // console.group('remove_duplicate_root')

            const use_computed_root_styles = !root_styles

            if (use_computed_root_styles && remove_duplicate_root) {
                root_styles = getComputedStyle(document.documentElement)
            }

            for (const key in scheme_variables) {
                const root_color = use_computed_root_styles
                    ? normalizeHex(root_styles.getPropertyValue(key).trim())
                    : normalizeHex(root_styles[key])

                if (scheme_variables[key] == root_color) {
                    delete scheme_variables[key]
                }
            }

            // console.log('scheme_variables', {...scheme_variables})
            // console.groupEnd()
        }
        
        // console.groupEnd()

        return scheme_variables
    },

    root_color_scheme: (state, getters) => getters.colorSchemeExtendedToCompact([], true),

    vertical_lines_enabled: () => VERTICAL_LINES_ENABLED,
}

const mutations = {}

const actions = {}

export default {
    state,
    getters,
    mutations,
    actions,
}