// Code generated by protoc-gen-iqwebapi. DO NOT EDIT.
// source: productinstance.proto

import axios from 'axios';
import StoreHash from '@/utils/StoreHash';

const initialState = {
	ProductInstances: {
		UUIDs: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
	ProductInstanceSearches: {
		SearchTexts: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
	ProductInstanceCharacteristics: {
		UUIDs: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
	ProductInstanceNotes: {
		UUIDs: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
	ProductInstanceStates: {
		UUIDs: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
};

const valFuncFind = function (status) {
	return (status == 200) ? true : (status == 404) ? true : false;
}

const default_pageinfo = {
	PageNumber: 0,
	PageItemCount: 0,
	TotalPages: 0,
	TotalItemCount: 0,
	IndexStart: 0,
	PageSize: 10,
	DisplayPageList: [],
	DisplayPageEitherSide: 4,
}

const getters = {
	getProductInstanceByUUID: state => (UUID) => (state.ProductInstances.UUIDs[ UUID ] && !state.ProductInstances.UUIDs[ UUID ].expired()) ?  state.ProductInstances.UUIDs[ UUID ].hashedData() : null,
	getProductInstancesForSearchText: state => (SearchText) => state.ProductInstanceSearches[ SearchText ] ? state.ProductInstanceSearches[ SearchText ] : null,
	getProductInstanceCharacteristicByUUID: state => (UUID) => (state.ProductInstanceCharacteristics.UUIDs[ UUID ] && !state.ProductInstanceCharacteristics.UUIDs[ UUID ].expired()) ?  state.ProductInstanceCharacteristics.UUIDs[ UUID ].hashedData() : null,
	getProductInstanceNoteByUUID: state => (UUID) => (state.ProductInstanceNotes.UUIDs[ UUID ] && !state.ProductInstanceNotes.UUIDs[ UUID ].expired()) ?  state.ProductInstanceNotes.UUIDs[ UUID ].hashedData() : null,
	getProductInstanceStateByUUID: state => (UUID) => (state.ProductInstanceStates.UUIDs[ UUID ] && !state.ProductInstanceStates.UUIDs[ UUID ].expired()) ?  state.ProductInstanceStates.UUIDs[ UUID ].hashedData() : null,
};

const mutations = {
	// mutSaveProductInstance saves a single ProductInstance object
	// into both the StoreHash cache and the VueX store
	mutSaveProductInstance(state, obj) {
		if (!obj.UUID) { return } // Must have UUID to Save

		if (!(obj.UUID in state.ProductInstances.UUIDs)) {
			state.ProductInstances.UUIDs[ obj.UUID ] = new StoreHash(state.ProductInstances.lifetime)
		}
		state.ProductInstances.UUIDs[ obj.UUID ].fix(obj) // Add item to StoreHash
	},
	// mutRemoveProductInstanceByUUID removes a ProductInstance object
	// with the given UUID from StoreHash cache and VueX store
	mutRemoveProductInstanceByUUID(state, UUID) {
		if (!UUID) {
			// Must have UUID to Remove
			return
		}
		delete state.ProductInstances.UUIDs[ UUID ]    // remove item from StoreHash
	},
	// mutSaveProductInstanceSearch saves a search response
	// with the given SearchText to the StoreHash cache and VueX store
	mutSaveProductInstanceSearch(state, { SearchText, Response }) {
		if (!SearchText || !Response) {
			// Must have SearchText to Save and Response object
			return
		}
		let storedObj = {
			PageInfo: Object.assign({}, default_pageinfo), // shallow copy of default_pageinfo
			UUIDs: [],
		}
		// Deep copy ??? i.e. JSON.parse(JSON.stringify(obj));
		if (Response.PageInfo) {
			Response.PageInfo = Object.assign({}, Response.PageInfo) // shallow copy of the PageInfo
		}
		if (Array.isArray(Response.ProductInstances)) {
			for (let i = 0; i < Response.ProductInstances.length; i++) {
				storedObj.UUIDs.push(Response.ProductInstances[i].UUID)
			}
		}
		state.ProductInstanceSearches[ SearchText ] = storedObj      // Add search text and list of UUIDs to VueX state

		if (!(SearchText in state.ProductInstanceSearches.SearchTexts)) {
			state.ProductInstanceSearches.SearchTexts[ SearchText ] = new StoreHash(state.ProductInstanceSearches.lifetime)
		}
		state.ProductInstanceSearches.SearchTexts[ SearchText ].fix() // Add item to StoreHash
	},
	// mutRemoveProductInstanceSearch removes a search response
	// with the given SearchText from StoreHash cache and VueX store
	mutRemoveProductInstanceSearch(state, SearchText) {
		if (!SearchText) {
			// Must have SearchText to Remove
			return
		}
		state.ProductInstanceSearches[ SearchText ] = null               // cause VueX to trigger a data cascade
		delete state.ProductInstanceSearches[ SearchText ]               // remove item from VueX state
		delete state.ProductInstanceSearches.SearchTexts[ SearchText ]  // remove item from StoreHash
	},
	// mutSaveProductInstances saves a paginated response of ProductInstances
	mutSaveProductInstances(state, { Response }) {
		if (!Response || !Array.isArray(Response.ProductInstances)) {
			// Must have Response object with results
			return
		}
		for (let i = 0; i < Response.ProductInstances.length; i++) {
			if (!(Response.ProductInstances[i].UUID in state.ProductInstances.UUIDs)) {
				state.ProductInstances.UUIDs[ Response.ProductInstances[i].UUID ] = new StoreHash(state.ProductInstances.lifetime)
			}
			state.ProductInstances.UUIDs[ Response.ProductInstances[i].UUID ].fix(Response.ProductInstances[i])  // Add single ProductInstance to StoreHash
		}
	},
	// mutSaveProductInstanceCharacteristic saves a single ProductInstanceCharacteristic object
	// into both the StoreHash cache and the VueX store
	mutSaveProductInstanceCharacteristic(state, obj) {
		if (!obj.UUID) { return } // Must have UUID to Save

		if (!(obj.UUID in state.ProductInstanceCharacteristics.UUIDs)) {
			state.ProductInstanceCharacteristics.UUIDs[ obj.UUID ] = new StoreHash(state.ProductInstanceCharacteristics.lifetime)
		}
		state.ProductInstanceCharacteristics.UUIDs[ obj.UUID ].fix(obj) // Add item to StoreHash
	},
	// mutRemoveProductInstanceCharacteristicByUUID removes a ProductInstanceCharacteristic object
	// with the given UUID from StoreHash cache and VueX store
	mutRemoveProductInstanceCharacteristicByUUID(state, UUID) {
		if (!UUID) {
			// Must have UUID to Remove
			return
		}
		delete state.ProductInstanceCharacteristics.UUIDs[ UUID ]    // remove item from StoreHash
	},
	// mutSaveProductInstanceCharacteristics saves a paginated response of ProductInstanceCharacteristics
	mutSaveProductInstanceCharacteristics(state, { Response }) {
		if (!Response || !Array.isArray(Response.ProductInstanceCharacteristics)) {
			// Must have Response object with results
			return
		}
		for (let i = 0; i < Response.ProductInstanceCharacteristics.length; i++) {
			if (!(Response.ProductInstanceCharacteristics[i].UUID in state.ProductInstanceCharacteristics.UUIDs)) {
				state.ProductInstanceCharacteristics.UUIDs[ Response.ProductInstanceCharacteristics[i].UUID ] = new StoreHash(state.ProductInstanceCharacteristics.lifetime)
			}
			state.ProductInstanceCharacteristics.UUIDs[ Response.ProductInstanceCharacteristics[i].UUID ].fix(Response.ProductInstanceCharacteristics[i])  // Add single ProductInstanceCharacteristic to StoreHash
		}
	},
	// mutSaveProductInstanceNote saves a single ProductInstanceNote object
	// into both the StoreHash cache and the VueX store
	mutSaveProductInstanceNote(state, obj) {
		if (!obj.UUID) { return } // Must have UUID to Save

		if (!(obj.UUID in state.ProductInstanceNotes.UUIDs)) {
			state.ProductInstanceNotes.UUIDs[ obj.UUID ] = new StoreHash(state.ProductInstanceNotes.lifetime)
		}
		state.ProductInstanceNotes.UUIDs[ obj.UUID ].fix(obj) // Add item to StoreHash
	},
	// mutRemoveProductInstanceNoteByUUID removes a ProductInstanceNote object
	// with the given UUID from StoreHash cache and VueX store
	mutRemoveProductInstanceNoteByUUID(state, UUID) {
		if (!UUID) {
			// Must have UUID to Remove
			return
		}
		delete state.ProductInstanceNotes.UUIDs[ UUID ]    // remove item from StoreHash
	},
	// mutSaveProductInstanceNotes saves a paginated response of ProductInstanceNotes
	mutSaveProductInstanceNotes(state, { Response }) {
		if (!Response || !Array.isArray(Response.ProductInstanceNotes)) {
			// Must have Response object with results
			return
		}
		for (let i = 0; i < Response.ProductInstanceNotes.length; i++) {
			if (!(Response.ProductInstanceNotes[i].UUID in state.ProductInstanceNotes.UUIDs)) {
				state.ProductInstanceNotes.UUIDs[ Response.ProductInstanceNotes[i].UUID ] = new StoreHash(state.ProductInstanceNotes.lifetime)
			}
			state.ProductInstanceNotes.UUIDs[ Response.ProductInstanceNotes[i].UUID ].fix(Response.ProductInstanceNotes[i])  // Add single ProductInstanceNote to StoreHash
		}
	},
	// mutSaveProductInstanceState saves a single ProductInstanceState object
	// into both the StoreHash cache and the VueX store
	mutSaveProductInstanceState(state, obj) {
		if (!obj.UUID) { return } // Must have UUID to Save

		if (!(obj.UUID in state.ProductInstanceStates.UUIDs)) {
			state.ProductInstanceStates.UUIDs[ obj.UUID ] = new StoreHash(state.ProductInstanceStates.lifetime)
		}
		state.ProductInstanceStates.UUIDs[ obj.UUID ].fix(obj) // Add item to StoreHash
	},
	// mutRemoveProductInstanceStateByUUID removes a ProductInstanceState object
	// with the given UUID from StoreHash cache and VueX store
	mutRemoveProductInstanceStateByUUID(state, UUID) {
		if (!UUID) {
			// Must have UUID to Remove
			return
		}
		delete state.ProductInstanceStates.UUIDs[ UUID ]    // remove item from StoreHash
	},
	// mutSaveProductInstanceStates saves a paginated response of ProductInstanceStates
	mutSaveProductInstanceStates(state, { Response }) {
		if (!Response || !Array.isArray(Response.ProductInstanceStates)) {
			// Must have Response object with results
			return
		}
		for (let i = 0; i < Response.ProductInstanceStates.length; i++) {
			if (!(Response.ProductInstanceStates[i].UUID in state.ProductInstanceStates.UUIDs)) {
				state.ProductInstanceStates.UUIDs[ Response.ProductInstanceStates[i].UUID ] = new StoreHash(state.ProductInstanceStates.lifetime)
			}
			state.ProductInstanceStates.UUIDs[ Response.ProductInstanceStates[i].UUID ].fix(Response.ProductInstanceStates[i])  // Add single ProductInstanceState to StoreHash
		}
	},
};

const actions = {
	// getCachedProductInstanceByUUID fetches a ProductInstance from the cache
	// and if not in the cache, fetches it from the API
	getCachedProductInstanceByUUID({ state, dispatch, commit }, { UUID, RefreshCache }) {
		if (!RefreshCache && state.ProductInstances.UUIDs[ UUID ] && !state.ProductInstances.UUIDs[ UUID ].expired()) {
			// Return the cached record
			return state.ProductInstances.UUIDs[ UUID ].hashedData()
		}
		// Fetch from the API
		return dispatch('GetProductInstanceByUUID', { UUID }).then(respPayload => {
			commit('mutSaveProductInstance', respPayload)
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveProductInstanceByUUID', UUID)
			return Promise.reject(error)
		})
	},

	// getCachedProductInstancesBySearch fetches ProductInstances from the cache
	// and if not in the cache, fetches it from the API
	getCachedProductInstancesBySearch({ state, getters, dispatch, commit }, { SPID, SearchText, RefreshCache }) {
		if (!RefreshCache && state.ProductInstanceSearches.SearchTexts[ SearchText ] && !state.ProductInstanceSearches.SearchTexts[ SearchText ].expired()) {
			// Return the cached records
			let resp = getters.getProductInstancesForSearchText(SearchText)
			resp.ProductInstances = []
			for (let i = 0; i < resp.UUIDs.length; i++) {
				resp.ProductInstances.push(getters.getProductInstanceByUUID(resp.UUIDs[i]))
			}
			return Promise.resolve(resp)
		}
		// Search using the API
		const apiReq = {
			SPID: SPID,
			SmartSearch: SearchText,
		}
		return dispatch('FindProductInstancesPaginated', apiReq).then(respPayload => {
			commit('mutSaveProductInstanceSearch', { SearchText: SearchText, Response: respPayload })
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveProductInstanceSearch', SearchText)
			return Promise.reject(error)
		})
	},

	// getCachedProductInstanceCharacteristicByUUID fetches a ProductInstanceCharacteristic from the cache
	// and if not in the cache, fetches it from the API
	getCachedProductInstanceCharacteristicByUUID({ state, dispatch, commit }, { UUID, RefreshCache }) {
		if (!RefreshCache && state.ProductInstanceCharacteristics.UUIDs[ UUID ] && !state.ProductInstanceCharacteristics.UUIDs[ UUID ].expired()) {
			// Return the cached record
			return state.ProductInstanceCharacteristics.UUIDs[ UUID ].hashedData()
		}
		// Fetch from the API
		return dispatch('GetProductInstanceCharacteristicByUUID', { UUID }).then(respPayload => {
			commit('mutSaveProductInstanceCharacteristic', respPayload)
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveProductInstanceCharacteristicByUUID', UUID)
			return Promise.reject(error)
		})
	},

	// getCachedProductInstanceNoteByUUID fetches a ProductInstanceNote from the cache
	// and if not in the cache, fetches it from the API
	getCachedProductInstanceNoteByUUID({ state, dispatch, commit }, { UUID, RefreshCache }) {
		if (!RefreshCache && state.ProductInstanceNotes.UUIDs[ UUID ] && !state.ProductInstanceNotes.UUIDs[ UUID ].expired()) {
			// Return the cached record
			return state.ProductInstanceNotes.UUIDs[ UUID ].hashedData()
		}
		// Fetch from the API
		return dispatch('GetProductInstanceNoteByUUID', { UUID }).then(respPayload => {
			commit('mutSaveProductInstanceNote', respPayload)
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveProductInstanceNoteByUUID', UUID)
			return Promise.reject(error)
		})
	},

	// getCachedProductInstanceStateByUUID fetches a ProductInstanceState from the cache
	// and if not in the cache, fetches it from the API
	getCachedProductInstanceStateByUUID({ state, dispatch, commit }, { UUID, RefreshCache }) {
		if (!RefreshCache && state.ProductInstanceStates.UUIDs[ UUID ] && !state.ProductInstanceStates.UUIDs[ UUID ].expired()) {
			// Return the cached record
			return state.ProductInstanceStates.UUIDs[ UUID ].hashedData()
		}
		// Fetch from the API
		return dispatch('GetProductInstanceStateByUUID', { UUID }).then(respPayload => {
			commit('mutSaveProductInstanceState', respPayload)
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveProductInstanceStateByUUID', UUID)
			return Promise.reject(error)
		})
	},

	//
	// Service: ProductInstanceRPC
	// 
	/**
	* RPC Method: AddProductInstanceCharacteristic
	* Description: Add a new product catalog characteristic
	* HTTP Method: POST
	* API Path: /api/v1/product_instance_characteristic
	* @param    {ProductInstanceCharacteristic} req The API payload object (ProductInstanceCharacteristic)
	* @return   {ProductInstanceCharacteristic} The API response object (ProductInstanceCharacteristic)
	*
	* @typedef  {Object}  ProductInstanceCharacteristic
	* @property {String}  UUID                The unique index for this characteristic
	* @property {Number}  SPID                The service provider
	* @property {String}  ProductInstanceUUID The product instance that this characteristic belongs to
	* @property {Number}  CreatedAtNanos      Unixnano when this characteristic was created
	* @property {Number}  DeletedAtNanos      Unixnano when this characteristic was deleted
	* @property {String}  ReplacesUUID        If this characteristic has been updated, the previous one is linked here
	* @property {String}  Name                Name of this characteristic
	* @property {String}  Value               Value of this characteristic
	* @property {String}  DefaultValue        Default value of this characteristic
	* @property {Boolean} Required            Is this characteristic required (ie, no default)
	* @property {String}  ValueType           The type of this characteristic, string, int, imsi, ipv4_address, ipv6_address, etc
	* @property {String}  ValidationRules     The validation rules for this type
	* @property {Boolean} CanAdd              Can the customer specify this characteristic (read-only?)
	* @property {Boolean} CanUpdate           Can this characteristic be updated after creation
	* @property {String}  CustomerUUID        The (optional) CustomerUUID of the ProductInstanceState
	*
	*/
	AddProductInstanceCharacteristic({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Add)
		return axios.post(`/v1/product_instance_characteristic`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('AddProductInstanceCharacteristic ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('AddProductInstanceCharacteristic ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('AddProductInstanceCharacteristic ERROR:', error)
					return Promise.reject(error)
				}
				console.log('AddProductInstanceCharacteristic UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in AddProductInstanceCharacteristic'))
			})
	},

	/**
	* RPC Method: AddProductInstanceNote
	* Description: Add a new product instance note
	* HTTP Method: POST
	* API Path: /api/v1/${req.SPID}/product_instance_note/${req.ProductInstanceUUID}
	* @param    {ProductInstanceNote} req The API payload object (ProductInstanceNote)
	* @return   {ProductInstanceNote} The API response object (ProductInstanceNote)
	*
	* @typedef  {Object}  ProductInstanceNote
	* @property {String}  UUID                The unique index for this note
	* @property {Number}  SPID                The service provider
	* @property {String}  ProductInstanceUUID The product instance that this characteristic belongs to
	* @property {Number}  CreatedAtNanos      Unixnano when this note was created
	* @property {Number}  DeletedAtNanos      Unixnano when this note was deleted
	* @property {String}  CreatedBy           Who added this note?
	* @property {String}  Text                The note text
	*
	*/
	AddProductInstanceNote({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Add)
		return axios.post(`/v1/${req.SPID}/product_instance_note/${req.ProductInstanceUUID}`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('AddProductInstanceNote ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('AddProductInstanceNote ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('AddProductInstanceNote ERROR:', error)
					return Promise.reject(error)
				}
				console.log('AddProductInstanceNote UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in AddProductInstanceNote'))
			})
	},

	/**
	* RPC Method: ChangeCurrentProductInstanceState
	* Description: Changes the current product instance state, while maintaining history.
	* HTTP Method: POST
	* API Path: /api/v1/product_state
	* @param    {ProductInstanceStateChange} req The API payload object (ProductInstanceStateChange)
	* @return   {ProductInstanceState} The API response object (ProductInstanceState)
	*
	* @typedef  {Object}  ProductInstanceStateChange
	* @property {Number}  SPID                
	* @property {String}  ProductInstanceUUID 
	* @property {ENUM}    State               
	*
	* @typedef  {Object}  ProductInstanceState
	* @property {String}  UUID                Unique ID for this product instance state
	* @property {String}  ProductInstanceUUID The UUID of the product instance this belongs to
	* @property {Number}  CreatedAtNanos      Unixnano when this product instance state was created
	* @property {Number}  DeletedAtNanos      Unixnano when this product instance state was deleted
	* @property {ENUM}    State               The state of the product instance state
	* @property {String}  CustomerUUID        The (optional) CustomerUUID of the ProductInstanceState
	*
	*/
	ChangeCurrentProductInstanceState({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.post(`/v1/product_state`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('ChangeCurrentProductInstanceState ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('ChangeCurrentProductInstanceState ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('ChangeCurrentProductInstanceState ERROR:', error)
					return Promise.reject(error)
				}
				console.log('ChangeCurrentProductInstanceState UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in ChangeCurrentProductInstanceState'))
			})
	},

	/**
	* RPC Method: DeleteProductInstanceByUUID
	* Description: Delete a product instance
	* HTTP Method: DELETE
	* API Path: /api/v1/${req.SPID}/product_instance/${req.UUID}
	* @param    {ProductInstanceUUIDSearch} req The API payload object (ProductInstanceUUIDSearch)
	* @return   {ProductInstance} The API response object (ProductInstance)
	*
	* @typedef  {Object}  ProductInstanceUUIDSearch
	* @property {Number}  SPID The UUID of the product instance
	* @property {String}  UUID 
	*
	* @typedef  {Object}  ProductInstance
	* @property {String}  UUID                           option (google.api.http) = {,      get: "/api/v1/{SPID}/product_instance_basic",  };
	* @property {Number}  SPID                           Which service provider this product instance belongs to
	* @property {String}  ProductType                    Product type: FWA_Speed,FWA_Volume,UFB,postpaymobile <= check these please
	* @property {Number}  CreatedAtNanos                 Unixnano when this product instance was created
	* @property {Number}  UpdatedAtNanos                 GetProductInstanceAndStateAndCharacteristicsByUUID
	* @property {Number}  DeletedAtNanos                 CRUD: product_instance_characteristic
	* @property {String}  CustomerUUID                   CustomerUUID is the UUID of the customer this product instace belongs to
	* @property {String}  ExternalRef                    ExternalRef is an external reference (as opposed to a CustomerUUID)
	* @property {String}  ProductCatalogAvailabilityUUID ProductCatalogAvailabilityUUID the product catalog entry this is based off
	* @property {Number}  Cents                          Cents is the cost of this product to the service provider
	* @property {String}  RetailName                     CRUD: product_state
	* @property {String}  RetailDescription              A description of this product to end-customers
	* @property {Number}  RetailCents                    The retail price for the catalog item
	* @property {String}  ParentProductInstanceUUID      Parent Product Instance UUID
	*
	*/
	DeleteProductInstanceByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Delete)
		return axios.delete(`/v1/${req.SPID}/product_instance/${req.UUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveProductInstance', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('DeleteProductInstanceByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('DeleteProductInstanceByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('DeleteProductInstanceByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('DeleteProductInstanceByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in DeleteProductInstanceByUUID'))
			})
	},

	/**
	* RPC Method: DeleteProductInstanceCharacteristic
	* Description: Delete a product catalog characteristic
	* HTTP Method: DELETE
	* API Path: /api/v1/product_instance_characteristic/${req.UUID}
	* @param    {DeleteProductInstanceCharacteristicRequest} req The API payload object (DeleteProductInstanceCharacteristicRequest)
	* @return   {ProductInstanceCharacteristic} The API response object (ProductInstanceCharacteristic)
	*
	* @typedef  {Object}  DeleteProductInstanceCharacteristicRequest
	* @property {String}  UUID The UUID of the product catalog charastics
	*
	* @typedef  {Object}  ProductInstanceCharacteristic
	* @property {String}  UUID                The unique index for this characteristic
	* @property {Number}  SPID                The service provider
	* @property {String}  ProductInstanceUUID The product instance that this characteristic belongs to
	* @property {Number}  CreatedAtNanos      Unixnano when this characteristic was created
	* @property {Number}  DeletedAtNanos      Unixnano when this characteristic was deleted
	* @property {String}  ReplacesUUID        If this characteristic has been updated, the previous one is linked here
	* @property {String}  Name                Name of this characteristic
	* @property {String}  Value               Value of this characteristic
	* @property {String}  DefaultValue        Default value of this characteristic
	* @property {Boolean} Required            Is this characteristic required (ie, no default)
	* @property {String}  ValueType           The type of this characteristic, string, int, imsi, ipv4_address, ipv6_address, etc
	* @property {String}  ValidationRules     The validation rules for this type
	* @property {Boolean} CanAdd              Can the customer specify this characteristic (read-only?)
	* @property {Boolean} CanUpdate           Can this characteristic be updated after creation
	* @property {String}  CustomerUUID        The (optional) CustomerUUID of the ProductInstanceState
	*
	*/
	DeleteProductInstanceCharacteristic({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Delete)
		return axios.delete(`/v1/product_instance_characteristic/${req.UUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveProductInstanceCharacteristic', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('DeleteProductInstanceCharacteristic ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('DeleteProductInstanceCharacteristic ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('DeleteProductInstanceCharacteristic ERROR:', error)
					return Promise.reject(error)
				}
				console.log('DeleteProductInstanceCharacteristic UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in DeleteProductInstanceCharacteristic'))
			})
	},

	/**
	* RPC Method: FindProductInstanceCharacteristicsPaginated
	* Description: Search through product characteristic
	* HTTP Method: GET
	* API Path: /api/v1/product_instance_characteristic
	* @param    {ProductInstanceCharacteristicSearchRequest} req The API payload object (ProductInstanceCharacteristicSearchRequest)
	* @return   {ProductInstanceCharacteristicSearchResponse} The API response object (ProductInstanceCharacteristicSearchResponse)
	*
	* @typedef  {Object}  ProductInstanceCharacteristicSearchRequest
	* @property {SearchOptions[]} SearchOptions       Standard search options
	* @property {Number}  SPID                Service Provider Identifier
	* @property {String}  ProductInstanceUUID The product instance that this characteristic belongs to
	* @property {String}  ReplacesUUID        If this characteristic has been updated, the previous one is linked here
	* @property {String}  Name                Name of this characteristic
	* @property {String}  Value               Value of this characteristic
	* @property {String}  DefaultValue        Default value of this characteristic
	* @property {Boolean} Required            Is this characteristic required (ie, no default)
	* @property {String}  ValueType           The type of this characteristic, string, int, imsi, ipv4_address, ipv6_address, etc
	* @property {String}  ValidationRules     The validation rules for this type
	* @property {Boolean} IncludeDeleted      Included deleted instances
	*
	* @typedef  {Object}  SearchOptions  Search options
	* @property {Number}  PageNumber     The page number to start at (or provide IndexStart)
	* @property {Number}  PageSize       The page size
	* @property {Number}  IndexStart     The record number to start at (or provide PageNumber)
	* @property {String}  SortBy         The field to sort by
	* @property {Boolean} SortDesc       Is the sort direction descending?
	*
	* @typedef  {Object}  ProductInstanceCharacteristicSearchResponse
	* @property {PageInfo} PageInfo                       PageInfo only needs the values ThisPageNumber && PageSize set
	* @property {ProductInstanceCharacteristic[]} ProductInstanceCharacteristics Repeated list of product instances
	*
	* @typedef  {Object}   PageInfo
	* @property {Number}   PageNumber             The page number
	* @property {Number}   PageItemCount          Number of items on this page
	* @property {Number}   TotalPages             Total pages available
	* @property {Number}   TotalItemCount         Total items available
	* @property {Number}   IndexStart             The record number this search started at
	* @property {Number}   PageSize               The length of the page
	* @property {Number[]} DisplayPageList        The array of page numbers to display
	* @property {Number}   DisplayPagesEitherSide Number of pages to display either side
	*
	*/
	FindProductInstanceCharacteristicsPaginated({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Find)
		return axios.get(`/v1/product_instance_characteristic`, { params: req, validateStatus: valFuncFind }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			commit('mutSaveProductInstanceCharacteristics', { Response: response.data })
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('FindProductInstanceCharacteristicsPaginated ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('FindProductInstanceCharacteristicsPaginated ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('FindProductInstanceCharacteristicsPaginated ERROR:', error)
					return Promise.reject(error)
				}
				console.log('FindProductInstanceCharacteristicsPaginated UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in FindProductInstanceCharacteristicsPaginated'))
			})
	},

	/**
	* RPC Method: FindProductInstanceNotesPaginated
	* Description: Get the notes on a product instance
	* HTTP Method: GET
	* API Path: /api/v1/${req.SPID}/product_instance_note/${req.ProductInstanceUUID}
	* @param    {ProductInstanceNoteSearchRequest} req The API payload object (ProductInstanceNoteSearchRequest)
	* @return   {ProductInstanceNotesSearchResponse} The API response object (ProductInstanceNotesSearchResponse)
	*
	* @typedef  {Object}  ProductInstanceNoteSearchRequest
	* @property {SearchOptions[]} SearchOptions       Standard search options
	* @property {Number}  SPID                SPID
	* @property {String}  ProductInstanceUUID Product Instance UUID
	* @property {String}  CustomerUUID        Customer UUID
	*
	* @typedef  {Object}  SearchOptions  Search options
	* @property {Number}  PageNumber     The page number to start at (or provide IndexStart)
	* @property {Number}  PageSize       The page size
	* @property {Number}  IndexStart     The record number to start at (or provide PageNumber)
	* @property {String}  SortBy         The field to sort by
	* @property {Boolean} SortDesc       Is the sort direction descending?
	*
	* @typedef  {Object}  ProductInstanceNotesSearchResponse
	* @property {PageInfo} PageInfo             PageInfo only needs the values ThisPageNumber && PageSize set
	* @property {ProductInstanceNote[]} ProductInstanceNotes Repeated list of product instance notes
	*
	* @typedef  {Object}   PageInfo
	* @property {Number}   PageNumber             The page number
	* @property {Number}   PageItemCount          Number of items on this page
	* @property {Number}   TotalPages             Total pages available
	* @property {Number}   TotalItemCount         Total items available
	* @property {Number}   IndexStart             The record number this search started at
	* @property {Number}   PageSize               The length of the page
	* @property {Number[]} DisplayPageList        The array of page numbers to display
	* @property {Number}   DisplayPagesEitherSide Number of pages to display either side
	*
	*/
	FindProductInstanceNotesPaginated({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Find)
		// Prepare URI params...
		let paramSPID = encodeURIComponent(req.SPID)
		let paramProductInstanceUUID = encodeURIComponent(req.ProductInstanceUUID)
		return axios.get(`/v1/${paramSPID}/product_instance_note/${paramProductInstanceUUID}`, { params: req, validateStatus: valFuncFind }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			commit('mutSaveProductInstanceNotes', { Response: response.data })
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('FindProductInstanceNotesPaginated ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('FindProductInstanceNotesPaginated ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('FindProductInstanceNotesPaginated ERROR:', error)
					return Promise.reject(error)
				}
				console.log('FindProductInstanceNotesPaginated UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in FindProductInstanceNotesPaginated'))
			})
	},

	/**
	* RPC Method: FindProductInstanceStatesPaginated
	* Description: Search through product characteristic
	* HTTP Method: GET
	* API Path: /api/v1/product_state
	* @param    {ProductInstanceStateSearchRequest} req The API payload object (ProductInstanceStateSearchRequest)
	* @return   {ProductInstanceStateSearchResponse} The API response object (ProductInstanceStateSearchResponse)
	*
	* @typedef  {Object}  ProductInstanceStateSearchRequest
	* @property {SearchOptions[]} SearchOptions       Standard search options
	* @property {String}  ProductInstanceUUID The UUID of the product instance this belongs to
	* @property {ENUM}    State               The state of the product instance state
	*
	* @typedef  {Object}  SearchOptions  Search options
	* @property {Number}  PageNumber     The page number to start at (or provide IndexStart)
	* @property {Number}  PageSize       The page size
	* @property {Number}  IndexStart     The record number to start at (or provide PageNumber)
	* @property {String}  SortBy         The field to sort by
	* @property {Boolean} SortDesc       Is the sort direction descending?
	*
	* @typedef  {Object}  ProductInstanceStateSearchResponse
	* @property {PageInfo} PageInfo              PageInfo only needs the values ThisPageNumber && PageSize set
	* @property {ProductInstanceState[]} ProductInstanceStates Repeated list of product instances
	*
	* @typedef  {Object}   PageInfo
	* @property {Number}   PageNumber             The page number
	* @property {Number}   PageItemCount          Number of items on this page
	* @property {Number}   TotalPages             Total pages available
	* @property {Number}   TotalItemCount         Total items available
	* @property {Number}   IndexStart             The record number this search started at
	* @property {Number}   PageSize               The length of the page
	* @property {Number[]} DisplayPageList        The array of page numbers to display
	* @property {Number}   DisplayPagesEitherSide Number of pages to display either side
	*
	*/
	FindProductInstanceStatesPaginated({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Find)
		return axios.get(`/v1/product_state`, { params: req, validateStatus: valFuncFind }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			commit('mutSaveProductInstanceStates', { Response: response.data })
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('FindProductInstanceStatesPaginated ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('FindProductInstanceStatesPaginated ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('FindProductInstanceStatesPaginated ERROR:', error)
					return Promise.reject(error)
				}
				console.log('FindProductInstanceStatesPaginated UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in FindProductInstanceStatesPaginated'))
			})
	},

	/**
	* RPC Method: FindProductInstancesWithDetailsPaginated
	* Description: Search through product instances
	* HTTP Method: GET
	* API Path: /api/v1/${req.SPID}/product_instance
	* @param    {ProductInstanceSearchRequest} req The API payload object (ProductInstanceSearchRequest)
	* @return   {ProductInstanceWithDetailsSearchResponse} The API response object (ProductInstanceWithDetailsSearchResponse)
	*
	* @typedef  {Object}  ProductInstanceSearchRequest
	* @property {SearchOptions[]} SearchOptions             Standard search options
	* @property {Number}  SPID                      Service Provider Identifier
	* @property {String}  ProductType               The type of product
	* @property {String}  CustomerUUID              Customer UUID
	* @property {Boolean} IncludeDeleted            Included deleted instances
	* @property {String}  SmartSearch               SmartSearch
	* @property {String}  ParentProductInstanceUUID ParentProductInstanceUUID
	*
	* @typedef  {Object}  ProductInstanceWithDetailsSearchResponse
	* @property {PageInfo} PageInfo                   PageInfo only needs the values ThisPageNumber && PageSize set
	* @property {ProductInstanceWithDetails[]} ProductInstanceWithDetails Repeated list of product instances
	*
	*/
	FindProductInstancesWithDetailsPaginated({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		// Prepare URI params...
		let paramSPID = encodeURIComponent(req.SPID)
		return axios.get(`/v1/${paramSPID}/product_instance`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('FindProductInstancesWithDetailsPaginated ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('FindProductInstancesWithDetailsPaginated ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('FindProductInstancesWithDetailsPaginated ERROR:', error)
					return Promise.reject(error)
				}
				console.log('FindProductInstancesWithDetailsPaginated UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in FindProductInstancesWithDetailsPaginated'))
			})
	},

	/**
	* RPC Method: GetProductInstanceAndStateAndCharacteristicsByUUID
	* Description: Get a product instance
	* HTTP Method: GET
	* API Path: /api/v1/${req.SPID}/product_instance/${req.UUID}
	* @param    {GetProductInstanceByUUIDRequest} req The API payload object (GetProductInstanceByUUIDRequest)
	* @return   {GetProductInstanceByUUIDResponse} The API response object (GetProductInstanceByUUIDResponse)
	*
	* @typedef  {Object}  GetProductInstanceByUUIDRequest
	* @property {Number}  SPID         SPID
	* @property {String}  UUID         Serivce Instance UUID
	* @property {String}  CustomerUUID Customer UUID
	*
	* @typedef  {Object}  GetProductInstanceByUUIDResponse
	* @property {Number}  SPID            SPID
	* @property {String}  UUID            UUID of the product instance
	* @property {ProductInstance} Instance        Product instance record
	* @property {ProductInstanceState} State           Current product instance state
	* @property {ProductInstanceCharacteristic[]} Characteristics List of characteristics for this product instance
	* @property {ProductInstanceState[]} History         Current product instance state
	*
	*/
	GetProductInstanceAndStateAndCharacteristicsByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		// Prepare URI params...
		let paramSPID = encodeURIComponent(req.SPID)
		let paramUUID = encodeURIComponent(req.UUID)
		return axios.get(`/v1/${paramSPID}/product_instance/${paramUUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetProductInstanceAndStateAndCharacteristicsByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetProductInstanceAndStateAndCharacteristicsByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetProductInstanceAndStateAndCharacteristicsByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetProductInstanceAndStateAndCharacteristicsByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetProductInstanceAndStateAndCharacteristicsByUUID'))
			})
	},

	/**
	* RPC Method: GetProductInstanceByUUID
	* Description: Get a product instance
	* HTTP Method: GET
	* API Path: /api/v1/${req.SPID}/product_instance/${req.UUID}
	* @param    {ProductInstanceUUIDSearch} req The API payload object (ProductInstanceUUIDSearch)
	* @return   {ProductInstance} The API response object (ProductInstance)
	*
	* @typedef  {Object}  ProductInstanceUUIDSearch
	* @property {Number}  SPID The UUID of the product instance
	* @property {String}  UUID 
	*
	* @typedef  {Object}  ProductInstance
	* @property {String}  UUID                           option (google.api.http) = {,      get: "/api/v1/{SPID}/product_instance_basic",  };
	* @property {Number}  SPID                           Which service provider this product instance belongs to
	* @property {String}  ProductType                    Product type: FWA_Speed,FWA_Volume,UFB,postpaymobile <= check these please
	* @property {Number}  CreatedAtNanos                 Unixnano when this product instance was created
	* @property {Number}  UpdatedAtNanos                 GetProductInstanceAndStateAndCharacteristicsByUUID
	* @property {Number}  DeletedAtNanos                 CRUD: product_instance_characteristic
	* @property {String}  CustomerUUID                   CustomerUUID is the UUID of the customer this product instace belongs to
	* @property {String}  ExternalRef                    ExternalRef is an external reference (as opposed to a CustomerUUID)
	* @property {String}  ProductCatalogAvailabilityUUID ProductCatalogAvailabilityUUID the product catalog entry this is based off
	* @property {Number}  Cents                          Cents is the cost of this product to the service provider
	* @property {String}  RetailName                     CRUD: product_state
	* @property {String}  RetailDescription              A description of this product to end-customers
	* @property {Number}  RetailCents                    The retail price for the catalog item
	* @property {String}  ParentProductInstanceUUID      Parent Product Instance UUID
	*
	*/
	GetProductInstanceByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Get)
		// Prepare URI params...
		let paramSPID = encodeURIComponent(req.SPID)
		let paramUUID = encodeURIComponent(req.UUID)
		return axios.get(`/v1/${paramSPID}/product_instance/${paramUUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveProductInstance', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetProductInstanceByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetProductInstanceByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetProductInstanceByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetProductInstanceByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetProductInstanceByUUID'))
			})
	},

	/**
	* RPC Method: GetProductInstanceCharacteristicByUUID
	* Description: Search through product characteristic
	* HTTP Method: GET
	* API Path: /api/v1/product_instance_characteristic/${req.UUID}
	* @param    {ProductInstanceCharacteristicUUIDSearch} req The API payload object (ProductInstanceCharacteristicUUIDSearch)
	* @return   {ProductInstanceCharacteristic} The API response object (ProductInstanceCharacteristic)
	*
	* @typedef  {Object}  ProductInstanceCharacteristicUUIDSearch
	* @property {String}  UUID The UUID of the product catalog charastics
	*
	* @typedef  {Object}  ProductInstanceCharacteristic
	* @property {String}  UUID                The unique index for this characteristic
	* @property {Number}  SPID                The service provider
	* @property {String}  ProductInstanceUUID The product instance that this characteristic belongs to
	* @property {Number}  CreatedAtNanos      Unixnano when this characteristic was created
	* @property {Number}  DeletedAtNanos      Unixnano when this characteristic was deleted
	* @property {String}  ReplacesUUID        If this characteristic has been updated, the previous one is linked here
	* @property {String}  Name                Name of this characteristic
	* @property {String}  Value               Value of this characteristic
	* @property {String}  DefaultValue        Default value of this characteristic
	* @property {Boolean} Required            Is this characteristic required (ie, no default)
	* @property {String}  ValueType           The type of this characteristic, string, int, imsi, ipv4_address, ipv6_address, etc
	* @property {String}  ValidationRules     The validation rules for this type
	* @property {Boolean} CanAdd              Can the customer specify this characteristic (read-only?)
	* @property {Boolean} CanUpdate           Can this characteristic be updated after creation
	* @property {String}  CustomerUUID        The (optional) CustomerUUID of the ProductInstanceState
	*
	*/
	GetProductInstanceCharacteristicByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Get)
		// Prepare URI params...
		let paramUUID = encodeURIComponent(req.UUID)
		return axios.get(`/v1/product_instance_characteristic/${paramUUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveProductInstanceCharacteristic', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetProductInstanceCharacteristicByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetProductInstanceCharacteristicByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetProductInstanceCharacteristicByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetProductInstanceCharacteristicByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetProductInstanceCharacteristicByUUID'))
			})
	},

	/**
	* RPC Method: GetProductInstanceStateByUUID
	* Description: Search through product characteristic
	* HTTP Method: GET
	* API Path: /api/v1/product_state/${req.UUID}
	* @param    {ProductInstanceStateUUIDSearch} req The API payload object (ProductInstanceStateUUIDSearch)
	* @return   {ProductInstanceState} The API response object (ProductInstanceState)
	*
	* @typedef  {Object}  ProductInstanceStateUUIDSearch
	* @property {String}  UUID The UUID of the product catalog charastics
	*
	* @typedef  {Object}  ProductInstanceState
	* @property {String}  UUID                Unique ID for this product instance state
	* @property {String}  ProductInstanceUUID The UUID of the product instance this belongs to
	* @property {Number}  CreatedAtNanos      Unixnano when this product instance state was created
	* @property {Number}  DeletedAtNanos      Unixnano when this product instance state was deleted
	* @property {ENUM}    State               The state of the product instance state
	* @property {String}  CustomerUUID        The (optional) CustomerUUID of the ProductInstanceState
	*
	*/
	GetProductInstanceStateByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Get)
		// Prepare URI params...
		let paramUUID = encodeURIComponent(req.UUID)
		return axios.get(`/v1/product_state/${paramUUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveProductInstanceState', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetProductInstanceStateByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetProductInstanceStateByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetProductInstanceStateByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetProductInstanceStateByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetProductInstanceStateByUUID'))
			})
	},

	/**
	* RPC Method: GetProductInstancesByCharacteristicNameAndValue
	* Description: get product instances by a characteristic name and value
	* HTTP Method: GET
	* API Path: /api/v1/product_instances_by_char
	* @param    {CharacteristicNameAndValue} req The API payload object (CharacteristicNameAndValue)
	* @return   {ProductInstancesWithCharacteristicsResponse} The API response object (ProductInstancesWithCharacteristicsResponse)
	*
	* @typedef  {Object}  CharacteristicNameAndValue
	* @property {Number}  SPID  The UUID of the product instance
	* @property {String}  Name  
	* @property {String}  Value 
	*
	* @typedef  {Object}  ProductInstancesWithCharacteristicsResponse
	* @property {ProductInstancesWithCharacteristics[]} ProductInstancesWithCharacteristics 
	*
	*/
	GetProductInstancesByCharacteristicNameAndValue({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.get(`/v1/product_instances_by_char`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetProductInstancesByCharacteristicNameAndValue ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetProductInstancesByCharacteristicNameAndValue ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetProductInstancesByCharacteristicNameAndValue ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetProductInstancesByCharacteristicNameAndValue UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetProductInstancesByCharacteristicNameAndValue'))
			})
	},

	/**
	* RPC Method: UpdateProductInstanceCharacteristic
	* Description: Update a product catalog characteristic
	* HTTP Method: PUT
	* API Path: /api/v1/product_instance_characteristic/${req.UUID}
	* @param    {ProductInstanceCharacteristic} req The API payload object (ProductInstanceCharacteristic)
	* @return   {ProductInstanceCharacteristic} The API response object (ProductInstanceCharacteristic)
	*
	* @typedef  {Object}  ProductInstanceCharacteristic
	* @property {String}  UUID                The unique index for this characteristic
	* @property {Number}  SPID                The service provider
	* @property {String}  ProductInstanceUUID The product instance that this characteristic belongs to
	* @property {Number}  CreatedAtNanos      Unixnano when this characteristic was created
	* @property {Number}  DeletedAtNanos      Unixnano when this characteristic was deleted
	* @property {String}  ReplacesUUID        If this characteristic has been updated, the previous one is linked here
	* @property {String}  Name                Name of this characteristic
	* @property {String}  Value               Value of this characteristic
	* @property {String}  DefaultValue        Default value of this characteristic
	* @property {Boolean} Required            Is this characteristic required (ie, no default)
	* @property {String}  ValueType           The type of this characteristic, string, int, imsi, ipv4_address, ipv6_address, etc
	* @property {String}  ValidationRules     The validation rules for this type
	* @property {Boolean} CanAdd              Can the customer specify this characteristic (read-only?)
	* @property {Boolean} CanUpdate           Can this characteristic be updated after creation
	* @property {String}  CustomerUUID        The (optional) CustomerUUID of the ProductInstanceState
	*
	*/
	UpdateProductInstanceCharacteristic({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Update)
		return axios.put(`/v1/product_instance_characteristic/${req.UUID}`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveProductInstanceCharacteristic', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('UpdateProductInstanceCharacteristic ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('UpdateProductInstanceCharacteristic ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('UpdateProductInstanceCharacteristic ERROR:', error)
					return Promise.reject(error)
				}
				console.log('UpdateProductInstanceCharacteristic UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in UpdateProductInstanceCharacteristic'))
			})
	},
};

export default {
	namespaced: true,
	state: initialState,
	getters,
	mutations,
	actions,
};