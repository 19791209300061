// Code generated by protoc-gen-iqwebapi. DO NOT EDIT.
// source: productcatalog.proto

import axios from 'axios';
import StoreHash from '@/utils/StoreHash';

const initialState = {
	ProductCatalogAvailabilities: {
		UUIDs: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
	ProductCatalogCharacteristics: {
		UUIDs: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
	ProductCatalogCharacteristicOverrides: {
		UUIDs: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
	ProductCatalogEntries: {
		UUIDs: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
	ProductCatalogEntrySummaries: {
		UUIDs: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
};

const valFuncFind = function (status) {
	return (status == 200) ? true : (status == 404) ? true : false;
}

const default_pageinfo = {
	PageNumber: 0,
	PageItemCount: 0,
	TotalPages: 0,
	TotalItemCount: 0,
	IndexStart: 0,
	PageSize: 10,
	DisplayPageList: [],
	DisplayPageEitherSide: 4,
}

const getters = {
	getProductCatalogAvailabilityByUUID: state => (UUID) => (state.ProductCatalogAvailabilities.UUIDs[ UUID ] && !state.ProductCatalogAvailabilities.UUIDs[ UUID ].expired()) ?  state.ProductCatalogAvailabilities.UUIDs[ UUID ].hashedData() : null,
	getProductCatalogCharacteristicByUUID: state => (UUID) => (state.ProductCatalogCharacteristics.UUIDs[ UUID ] && !state.ProductCatalogCharacteristics.UUIDs[ UUID ].expired()) ?  state.ProductCatalogCharacteristics.UUIDs[ UUID ].hashedData() : null,
	getProductCatalogCharacteristicOverrideByUUID: state => (UUID) => (state.ProductCatalogCharacteristicOverrides.UUIDs[ UUID ] && !state.ProductCatalogCharacteristicOverrides.UUIDs[ UUID ].expired()) ?  state.ProductCatalogCharacteristicOverrides.UUIDs[ UUID ].hashedData() : null,
	getProductCatalogEntryByUUID: state => (UUID) => (state.ProductCatalogEntries.UUIDs[ UUID ] && !state.ProductCatalogEntries.UUIDs[ UUID ].expired()) ?  state.ProductCatalogEntries.UUIDs[ UUID ].hashedData() : null,
	getProductCatalogEntrySummaryByUUID: state => (UUID) => (state.ProductCatalogEntrySummaries.UUIDs[ UUID ] && !state.ProductCatalogEntrySummaries.UUIDs[ UUID ].expired()) ?  state.ProductCatalogEntrySummaries.UUIDs[ UUID ].hashedData() : null,
};

const mutations = {
	// mutSaveProductCatalogAvailability saves a single ProductCatalogAvailability object
	// into both the StoreHash cache and the VueX store
	mutSaveProductCatalogAvailability(state, obj) {
		if (!obj.UUID) { return } // Must have UUID to Save

		if (!(obj.UUID in state.ProductCatalogAvailabilities.UUIDs)) {
			state.ProductCatalogAvailabilities.UUIDs[ obj.UUID ] = new StoreHash(state.ProductCatalogAvailabilities.lifetime)
		}
		state.ProductCatalogAvailabilities.UUIDs[ obj.UUID ].fix(obj) // Add item to StoreHash
	},
	// mutRemoveProductCatalogAvailabilityByUUID removes a ProductCatalogAvailability object
	// with the given UUID from StoreHash cache and VueX store
	mutRemoveProductCatalogAvailabilityByUUID(state, UUID) {
		if (!UUID) {
			// Must have UUID to Remove
			return
		}
		delete state.ProductCatalogAvailabilities.UUIDs[ UUID ]    // remove item from StoreHash
	},
	// mutSaveProductCatalogAvailabilities saves a paginated response of ProductCatalogAvailabilities
	mutSaveProductCatalogAvailabilities(state, { Response }) {
		if (!Response || !Array.isArray(Response.ProductCatalogAvailabilities)) {
			// Must have Response object with results
			return
		}
		for (let i = 0; i < Response.ProductCatalogAvailabilities.length; i++) {
			if (!(Response.ProductCatalogAvailabilities[i].UUID in state.ProductCatalogAvailabilities.UUIDs)) {
				state.ProductCatalogAvailabilities.UUIDs[ Response.ProductCatalogAvailabilities[i].UUID ] = new StoreHash(state.ProductCatalogAvailabilities.lifetime)
			}
			state.ProductCatalogAvailabilities.UUIDs[ Response.ProductCatalogAvailabilities[i].UUID ].fix(Response.ProductCatalogAvailabilities[i])  // Add single ProductCatalogAvailability to StoreHash
		}
	},
	// mutSaveProductCatalogCharacteristic saves a single ProductCatalogCharacteristic object
	// into both the StoreHash cache and the VueX store
	mutSaveProductCatalogCharacteristic(state, obj) {
		if (!obj.UUID) { return } // Must have UUID to Save

		if (!(obj.UUID in state.ProductCatalogCharacteristics.UUIDs)) {
			state.ProductCatalogCharacteristics.UUIDs[ obj.UUID ] = new StoreHash(state.ProductCatalogCharacteristics.lifetime)
		}
		state.ProductCatalogCharacteristics.UUIDs[ obj.UUID ].fix(obj) // Add item to StoreHash
	},
	// mutRemoveProductCatalogCharacteristicByUUID removes a ProductCatalogCharacteristic object
	// with the given UUID from StoreHash cache and VueX store
	mutRemoveProductCatalogCharacteristicByUUID(state, UUID) {
		if (!UUID) {
			// Must have UUID to Remove
			return
		}
		delete state.ProductCatalogCharacteristics.UUIDs[ UUID ]    // remove item from StoreHash
	},
	// mutSaveProductCatalogCharacteristics saves a paginated response of ProductCatalogCharacteristics
	mutSaveProductCatalogCharacteristics(state, { Response }) {
		if (!Response || !Array.isArray(Response.ProductCatalogCharacteristics)) {
			// Must have Response object with results
			return
		}
		for (let i = 0; i < Response.ProductCatalogCharacteristics.length; i++) {
			if (!(Response.ProductCatalogCharacteristics[i].UUID in state.ProductCatalogCharacteristics.UUIDs)) {
				state.ProductCatalogCharacteristics.UUIDs[ Response.ProductCatalogCharacteristics[i].UUID ] = new StoreHash(state.ProductCatalogCharacteristics.lifetime)
			}
			state.ProductCatalogCharacteristics.UUIDs[ Response.ProductCatalogCharacteristics[i].UUID ].fix(Response.ProductCatalogCharacteristics[i])  // Add single ProductCatalogCharacteristic to StoreHash
		}
	},
	// mutSaveProductCatalogCharacteristicOverride saves a single ProductCatalogCharacteristicOverride object
	// into both the StoreHash cache and the VueX store
	mutSaveProductCatalogCharacteristicOverride(state, obj) {
		if (!obj.UUID) { return } // Must have UUID to Save

		if (!(obj.UUID in state.ProductCatalogCharacteristicOverrides.UUIDs)) {
			state.ProductCatalogCharacteristicOverrides.UUIDs[ obj.UUID ] = new StoreHash(state.ProductCatalogCharacteristicOverrides.lifetime)
		}
		state.ProductCatalogCharacteristicOverrides.UUIDs[ obj.UUID ].fix(obj) // Add item to StoreHash
	},
	// mutRemoveProductCatalogCharacteristicOverrideByUUID removes a ProductCatalogCharacteristicOverride object
	// with the given UUID from StoreHash cache and VueX store
	mutRemoveProductCatalogCharacteristicOverrideByUUID(state, UUID) {
		if (!UUID) {
			// Must have UUID to Remove
			return
		}
		delete state.ProductCatalogCharacteristicOverrides.UUIDs[ UUID ]    // remove item from StoreHash
	},
	// mutSaveProductCatalogEntry saves a single ProductCatalogEntry object
	// into both the StoreHash cache and the VueX store
	mutSaveProductCatalogEntry(state, obj) {
		if (!obj.UUID) { return } // Must have UUID to Save

		if (!(obj.UUID in state.ProductCatalogEntries.UUIDs)) {
			state.ProductCatalogEntries.UUIDs[ obj.UUID ] = new StoreHash(state.ProductCatalogEntries.lifetime)
		}
		state.ProductCatalogEntries.UUIDs[ obj.UUID ].fix(obj) // Add item to StoreHash
	},
	// mutRemoveProductCatalogEntryByUUID removes a ProductCatalogEntry object
	// with the given UUID from StoreHash cache and VueX store
	mutRemoveProductCatalogEntryByUUID(state, UUID) {
		if (!UUID) {
			// Must have UUID to Remove
			return
		}
		delete state.ProductCatalogEntries.UUIDs[ UUID ]    // remove item from StoreHash
	},
	// mutSaveProductCatalogEntries saves a paginated response of ProductCatalogEntries
	mutSaveProductCatalogEntries(state, { Response }) {
		if (!Response || !Array.isArray(Response.ProductCatalogEntries)) {
			// Must have Response object with results
			return
		}
		for (let i = 0; i < Response.ProductCatalogEntries.length; i++) {
			if (!(Response.ProductCatalogEntries[i].UUID in state.ProductCatalogEntries.UUIDs)) {
				state.ProductCatalogEntries.UUIDs[ Response.ProductCatalogEntries[i].UUID ] = new StoreHash(state.ProductCatalogEntries.lifetime)
			}
			state.ProductCatalogEntries.UUIDs[ Response.ProductCatalogEntries[i].UUID ].fix(Response.ProductCatalogEntries[i])  // Add single ProductCatalogEntry to StoreHash
		}
	},
	// mutSaveProductCatalogEntrySummary saves a single ProductCatalogEntrySummary object
	// into both the StoreHash cache and the VueX store
	mutSaveProductCatalogEntrySummary(state, obj) {
		if (!obj.UUID) { return } // Must have UUID to Save

		if (!(obj.UUID in state.ProductCatalogEntrySummaries.UUIDs)) {
			state.ProductCatalogEntrySummaries.UUIDs[ obj.UUID ] = new StoreHash(state.ProductCatalogEntrySummaries.lifetime)
		}
		state.ProductCatalogEntrySummaries.UUIDs[ obj.UUID ].fix(obj) // Add item to StoreHash
	},
	// mutRemoveProductCatalogEntrySummaryByUUID removes a ProductCatalogEntrySummary object
	// with the given UUID from StoreHash cache and VueX store
	mutRemoveProductCatalogEntrySummaryByUUID(state, UUID) {
		if (!UUID) {
			// Must have UUID to Remove
			return
		}
		delete state.ProductCatalogEntrySummaries.UUIDs[ UUID ]    // remove item from StoreHash
	},
};

const actions = {
	// getCachedProductCatalogAvailabilityByUUID fetches a ProductCatalogAvailability from the cache
	// and if not in the cache, fetches it from the API
	getCachedProductCatalogAvailabilityByUUID({ state, dispatch, commit }, { UUID, RefreshCache }) {
		if (!RefreshCache && state.ProductCatalogAvailabilities.UUIDs[ UUID ] && !state.ProductCatalogAvailabilities.UUIDs[ UUID ].expired()) {
			// Return the cached record
			return state.ProductCatalogAvailabilities.UUIDs[ UUID ].hashedData()
		}
		// Fetch from the API
		return dispatch('GetProductCatalogAvailabilityByUUID', { UUID }).then(respPayload => {
			commit('mutSaveProductCatalogAvailability', respPayload)
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveProductCatalogAvailabilityByUUID', UUID)
			return Promise.reject(error)
		})
	},

	// getCachedProductCatalogCharacteristicByUUID fetches a ProductCatalogCharacteristic from the cache
	// and if not in the cache, fetches it from the API
	getCachedProductCatalogCharacteristicByUUID({ state, dispatch, commit }, { UUID, RefreshCache }) {
		if (!RefreshCache && state.ProductCatalogCharacteristics.UUIDs[ UUID ] && !state.ProductCatalogCharacteristics.UUIDs[ UUID ].expired()) {
			// Return the cached record
			return state.ProductCatalogCharacteristics.UUIDs[ UUID ].hashedData()
		}
		// Fetch from the API
		return dispatch('GetProductCatalogCharacteristicByUUID', { UUID }).then(respPayload => {
			commit('mutSaveProductCatalogCharacteristic', respPayload)
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveProductCatalogCharacteristicByUUID', UUID)
			return Promise.reject(error)
		})
	},

	// getCachedProductCatalogCharacteristicOverrideByUUID fetches a ProductCatalogCharacteristicOverride from the cache
	// and if not in the cache, fetches it from the API
	getCachedProductCatalogCharacteristicOverrideByUUID({ state, dispatch, commit }, { UUID, RefreshCache }) {
		if (!RefreshCache && state.ProductCatalogCharacteristicOverrides.UUIDs[ UUID ] && !state.ProductCatalogCharacteristicOverrides.UUIDs[ UUID ].expired()) {
			// Return the cached record
			return state.ProductCatalogCharacteristicOverrides.UUIDs[ UUID ].hashedData()
		}
		// Fetch from the API
		return dispatch('GetProductCatalogCharacteristicOverrideByUUID', { UUID }).then(respPayload => {
			commit('mutSaveProductCatalogCharacteristicOverride', respPayload)
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveProductCatalogCharacteristicOverrideByUUID', UUID)
			return Promise.reject(error)
		})
	},

	// getCachedProductCatalogEntryByUUID fetches a ProductCatalogEntry from the cache
	// and if not in the cache, fetches it from the API
	getCachedProductCatalogEntryByUUID({ state, dispatch, commit }, { UUID, RefreshCache }) {
		if (!RefreshCache && state.ProductCatalogEntries.UUIDs[ UUID ] && !state.ProductCatalogEntries.UUIDs[ UUID ].expired()) {
			// Return the cached record
			return state.ProductCatalogEntries.UUIDs[ UUID ].hashedData()
		}
		// Fetch from the API
		return dispatch('GetProductCatalogEntryByUUID', { UUID }).then(respPayload => {
			commit('mutSaveProductCatalogEntry', respPayload)
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveProductCatalogEntryByUUID', UUID)
			return Promise.reject(error)
		})
	},

	// getCachedProductCatalogEntrySummaryByUUID fetches a ProductCatalogEntrySummary from the cache
	// and if not in the cache, fetches it from the API
	getCachedProductCatalogEntrySummaryByUUID({ state, dispatch, commit }, { UUID, RefreshCache }) {
		if (!RefreshCache && state.ProductCatalogEntrySummaries.UUIDs[ UUID ] && !state.ProductCatalogEntrySummaries.UUIDs[ UUID ].expired()) {
			// Return the cached record
			return state.ProductCatalogEntrySummaries.UUIDs[ UUID ].hashedData()
		}
		// Fetch from the API
		return dispatch('GetProductCatalogEntrySummaryByUUID', { UUID }).then(respPayload => {
			commit('mutSaveProductCatalogEntrySummary', respPayload)
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveProductCatalogEntrySummaryByUUID', UUID)
			return Promise.reject(error)
		})
	},

	//
	// Service: ProductCatalogRPC
	// 
	/**
	* RPC Method: AddProductCatalogAvailability
	* Description: Add a new product catalog availability
	* HTTP Method: POST
	* API Path: /api/v1/product_catalog_availability
	* @param    {ProductCatalogAvailability} req The API payload object (ProductCatalogAvailability)
	* @return   {ProductCatalogAvailability} The API response object (ProductCatalogAvailability)
	*
	* @typedef  {Object}  ProductCatalogAvailability
	* @property {String}  UUID                    The unique ID for this object
	* @property {String}  ProductCatalogEntryUUID The UUID of the product catalog entry that this defines availability information for
	* @property {Number}  SPID                    Service Provider Identifier of the service provider that this availability is defined for
	* @property {Number}  CreatedAtNanos          Unixnano when this product was created
	* @property {Number}  UpdatedAtNanos          Unixnano when this product was updated
	* @property {Number}  DeletedAtNanos          Unixnano when this product was deleted
	* @property {Boolean} Available               Is this product available?
	* @property {Number}  PriceOverride           An override price from the catalog price
	* @property {Number}  ValidFromNanos          The starting unixnano when this is valid
	* @property {Number}  PurchaseValidToNanos    The ending unixnano when this validatity finishes
	* @property {Number}  VersionValidToNanos     The ending unixnano when customers should be migrated to new Product Version
	* @property {Number}  Priority                The priority of this record as compared to other matches
	* @property {String}  RetailName              The name of this product to end-customers
	* @property {String}  RetailDescription       A description of this product to end-customers
	* @property {Number}  RetailCents             The retail price for the catalog item
	* @property {ProductCatalogAvailability_OverrideCharacteristicsEntry[]} OverrideCharacteristics Any catalog overrides being set - this is not stored in the DB directly
	*
	*/
	AddProductCatalogAvailability({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Add)
		return axios.post(`/v1/product_catalog_availability`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('AddProductCatalogAvailability ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('AddProductCatalogAvailability ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('AddProductCatalogAvailability ERROR:', error)
					return Promise.reject(error)
				}
				console.log('AddProductCatalogAvailability UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in AddProductCatalogAvailability'))
			})
	},

	/**
	* RPC Method: AddProductCatalogCharacteristic
	* Description: Add a new product catalog characteristic
	* HTTP Method: POST
	* API Path: /api/v1/product_catalog_characteristic
	* @param    {ProductCatalogCharacteristic} req The API payload object (ProductCatalogCharacteristic)
	* @return   {ProductCatalogCharacteristic} The API response object (ProductCatalogCharacteristic)
	*
	* @typedef  {Object}  ProductCatalogCharacteristic
	* @property {String}  UUID                    The unique index for this characteristic
	* @property {String}  ProductCatalogEntryUUID The product catalog entry this characteristic belongs to
	* @property {Number}  CreatedAtNanos          Unixnano when this characteristic was created
	* @property {Number}  DeletedAtNanos          Unixnano when this characteristic was deleted
	* @property {String}  ReplacesUUID            If this characteristic has been updated, the previous one is linked here
	* @property {String}  Name                    Name of this characteristic
	* @property {String}  DefaultValue            KILL VALUE on catalog only use default value,  string Value = 8 [json_name = "Value", (tagger.tags) = "json:\"Value\" db:\"Value\" gorm:\"Column:Value;Size:255;NOT NULL;INDEX\" bson:\"Value\" graphql:\"Value\" firestore:\"Value\""];,  Default value of this characteristic
	* @property {Boolean} Required                Is this characteristic required (ie, no default)
	* @property {String}  ValueType               The type of this characteristic, string, int, imsi, ipv4_address, ipv6_address, etc
	* @property {String}  ValidationRules         The validation rules for this type
	* @property {Boolean} CanAdd                  Can the customer specify this characteristic (read-only?)
	* @property {Boolean} CanUpdate               Can this characteristic be updated after creation
	*
	*/
	AddProductCatalogCharacteristic({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Add)
		return axios.post(`/v1/product_catalog_characteristic`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('AddProductCatalogCharacteristic ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('AddProductCatalogCharacteristic ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('AddProductCatalogCharacteristic ERROR:', error)
					return Promise.reject(error)
				}
				console.log('AddProductCatalogCharacteristic UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in AddProductCatalogCharacteristic'))
			})
	},

	/**
	* RPC Method: AddProductCatalogEntry
	* Description: Search through product catalog
	* HTTP Method: POST
	* API Path: /api/v1/product_catalog
	* @param    {ProductCatalogEntry} req The API payload object (ProductCatalogEntry)
	* @return   {ProductCatalogEntry} The API response object (ProductCatalogEntry)
	*
	* @typedef  {Object}  ProductCatalogEntry
	* @property {String}  UUID           The unique ID for this entry
	* @property {String}  Name           The name of this product
	* @property {String}  Description    A description of this product
	* @property {Number}  CreatedAtNanos Unixnano when this product was created
	* @property {Number}  UpdatedAtNanos Unixnano when this product was updated
	* @property {Number}  DeletedAtNanos DeletedAtNanos is when this product was deleted, and is used to signal the deletion of downstream copies.
	* @property {String}  ProductType    The type of product, ie, FWA or UFB or Mobile or TV or...
	*
	*/
	AddProductCatalogEntry({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Add)
		return axios.post(`/v1/product_catalog`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('AddProductCatalogEntry ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('AddProductCatalogEntry ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('AddProductCatalogEntry ERROR:', error)
					return Promise.reject(error)
				}
				console.log('AddProductCatalogEntry UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in AddProductCatalogEntry'))
			})
	},

	/**
	* RPC Method: DeleteProductCatalogAvailability
	* Description: Delete a product catalog availability
	* HTTP Method: DELETE
	* API Path: /api/v1/product_catalog_availability/${req.UUID}
	* @param    {DeleteProductCatalogAvailabilityRequest} req The API payload object (DeleteProductCatalogAvailabilityRequest)
	* @return   {ProductCatalogAvailability} The API response object (ProductCatalogAvailability)
	*
	* @typedef  {Object}  DeleteProductCatalogAvailabilityRequest
	* @property {Number}  SPID The UUID of the product catalog charastics
	* @property {String}  UUID 
	*
	* @typedef  {Object}  ProductCatalogAvailability
	* @property {String}  UUID                    The unique ID for this object
	* @property {String}  ProductCatalogEntryUUID The UUID of the product catalog entry that this defines availability information for
	* @property {Number}  SPID                    Service Provider Identifier of the service provider that this availability is defined for
	* @property {Number}  CreatedAtNanos          Unixnano when this product was created
	* @property {Number}  UpdatedAtNanos          Unixnano when this product was updated
	* @property {Number}  DeletedAtNanos          Unixnano when this product was deleted
	* @property {Boolean} Available               Is this product available?
	* @property {Number}  PriceOverride           An override price from the catalog price
	* @property {Number}  ValidFromNanos          The starting unixnano when this is valid
	* @property {Number}  PurchaseValidToNanos    The ending unixnano when this validatity finishes
	* @property {Number}  VersionValidToNanos     The ending unixnano when customers should be migrated to new Product Version
	* @property {Number}  Priority                The priority of this record as compared to other matches
	* @property {String}  RetailName              The name of this product to end-customers
	* @property {String}  RetailDescription       A description of this product to end-customers
	* @property {Number}  RetailCents             The retail price for the catalog item
	* @property {ProductCatalogAvailability_OverrideCharacteristicsEntry[]} OverrideCharacteristics Any catalog overrides being set - this is not stored in the DB directly
	*
	*/
	DeleteProductCatalogAvailability({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Delete)
		return axios.delete(`/v1/product_catalog_availability/${req.UUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveProductCatalogAvailability', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('DeleteProductCatalogAvailability ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('DeleteProductCatalogAvailability ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('DeleteProductCatalogAvailability ERROR:', error)
					return Promise.reject(error)
				}
				console.log('DeleteProductCatalogAvailability UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in DeleteProductCatalogAvailability'))
			})
	},

	/**
	* RPC Method: DeleteProductCatalogCharacteristic
	* Description: Delete a product catalog characteristic
	* HTTP Method: DELETE
	* API Path: /api/v1/product_catalog_characteristic/${req.UUID}
	* @param    {DeleteProductCatalogCharacteristicRequest} req The API payload object (DeleteProductCatalogCharacteristicRequest)
	* @return   {ProductCatalogCharacteristic} The API response object (ProductCatalogCharacteristic)
	*
	* @typedef  {Object}  DeleteProductCatalogCharacteristicRequest
	* @property {String}  UUID The UUID of the product catalog charastics
	*
	* @typedef  {Object}  ProductCatalogCharacteristic
	* @property {String}  UUID                    The unique index for this characteristic
	* @property {String}  ProductCatalogEntryUUID The product catalog entry this characteristic belongs to
	* @property {Number}  CreatedAtNanos          Unixnano when this characteristic was created
	* @property {Number}  DeletedAtNanos          Unixnano when this characteristic was deleted
	* @property {String}  ReplacesUUID            If this characteristic has been updated, the previous one is linked here
	* @property {String}  Name                    Name of this characteristic
	* @property {String}  DefaultValue            KILL VALUE on catalog only use default value,  string Value = 8 [json_name = "Value", (tagger.tags) = "json:\"Value\" db:\"Value\" gorm:\"Column:Value;Size:255;NOT NULL;INDEX\" bson:\"Value\" graphql:\"Value\" firestore:\"Value\""];,  Default value of this characteristic
	* @property {Boolean} Required                Is this characteristic required (ie, no default)
	* @property {String}  ValueType               The type of this characteristic, string, int, imsi, ipv4_address, ipv6_address, etc
	* @property {String}  ValidationRules         The validation rules for this type
	* @property {Boolean} CanAdd                  Can the customer specify this characteristic (read-only?)
	* @property {Boolean} CanUpdate               Can this characteristic be updated after creation
	*
	*/
	DeleteProductCatalogCharacteristic({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Delete)
		return axios.delete(`/v1/product_catalog_characteristic/${req.UUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveProductCatalogCharacteristic', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('DeleteProductCatalogCharacteristic ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('DeleteProductCatalogCharacteristic ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('DeleteProductCatalogCharacteristic ERROR:', error)
					return Promise.reject(error)
				}
				console.log('DeleteProductCatalogCharacteristic UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in DeleteProductCatalogCharacteristic'))
			})
	},

	/**
	* RPC Method: DeleteProductCatalogEntry
	* Description: Delete a product catalog entrie and associated characteristics and availabilities
	* HTTP Method: DELETE
	* API Path: /api/v1/product_catalog/${req.UUID}
	* @param    {DeleteProductCatalogEntryRequest} req The API payload object (DeleteProductCatalogEntryRequest)
	* @return   {ProductCatalogEntry} The API response object (ProductCatalogEntry)
	*
	* @typedef  {Object}  DeleteProductCatalogEntryRequest
	* @property {String}  UUID The UUID of the product catalog entry
	*
	* @typedef  {Object}  ProductCatalogEntry
	* @property {String}  UUID           The unique ID for this entry
	* @property {String}  Name           The name of this product
	* @property {String}  Description    A description of this product
	* @property {Number}  CreatedAtNanos Unixnano when this product was created
	* @property {Number}  UpdatedAtNanos Unixnano when this product was updated
	* @property {Number}  DeletedAtNanos DeletedAtNanos is when this product was deleted, and is used to signal the deletion of downstream copies.
	* @property {String}  ProductType    The type of product, ie, FWA or UFB or Mobile or TV or...
	*
	*/
	DeleteProductCatalogEntry({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Delete)
		return axios.delete(`/v1/product_catalog/${req.UUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveProductCatalogEntry', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('DeleteProductCatalogEntry ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('DeleteProductCatalogEntry ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('DeleteProductCatalogEntry ERROR:', error)
					return Promise.reject(error)
				}
				console.log('DeleteProductCatalogEntry UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in DeleteProductCatalogEntry'))
			})
	},

	/**
	* RPC Method: FindProductCatalogAvailabilitiesPaginated
	* Description: Search through product availabilies
	* HTTP Method: GET
	* API Path: /api/v1/product_catalog_availability
	* @param    {ProductCatalogAvailabilitySearchRequest} req The API payload object (ProductCatalogAvailabilitySearchRequest)
	* @return   {ProductCatalogAvailabilitySearchResponse} The API response object (ProductCatalogAvailabilitySearchResponse)
	*
	* @typedef  {Object}  ProductCatalogAvailabilitySearchRequest
	* @property {SearchOptions[]} SearchOptions           Standard search options
	* @property {Number}  SPID                    Service Provider Identifier
	* @property {String}  UUID                    
	* @property {String}  ProductCatalogEntryUUID 
	* @property {Boolean} IncludeDeleted          
	*
	* @typedef  {Object}  SearchOptions  Search options
	* @property {Number}  PageNumber     The page number to start at (or provide IndexStart)
	* @property {Number}  PageSize       The page size
	* @property {Number}  IndexStart     The record number to start at (or provide PageNumber)
	* @property {String}  SortBy         The field to sort by
	* @property {Boolean} SortDesc       Is the sort direction descending?
	*
	* @typedef  {Object}  ProductCatalogAvailabilitySearchResponse
	* @property {PageInfo} PageInfo                     PageInfo only needs the values ThisPageNumber && PageSize set
	* @property {ProductCatalogAvailability[]} ProductCatalogAvailabilities 
	*
	* @typedef  {Object}   PageInfo
	* @property {Number}   PageNumber             The page number
	* @property {Number}   PageItemCount          Number of items on this page
	* @property {Number}   TotalPages             Total pages available
	* @property {Number}   TotalItemCount         Total items available
	* @property {Number}   IndexStart             The record number this search started at
	* @property {Number}   PageSize               The length of the page
	* @property {Number[]} DisplayPageList        The array of page numbers to display
	* @property {Number}   DisplayPagesEitherSide Number of pages to display either side
	*
	*/
	FindProductCatalogAvailabilitiesPaginated({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Find)
		return axios.get(`/v1/product_catalog_availability`, { params: req, validateStatus: valFuncFind }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			commit('mutSaveProductCatalogAvailabilities', { Response: response.data })
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('FindProductCatalogAvailabilitiesPaginated ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('FindProductCatalogAvailabilitiesPaginated ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('FindProductCatalogAvailabilitiesPaginated ERROR:', error)
					return Promise.reject(error)
				}
				console.log('FindProductCatalogAvailabilitiesPaginated UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in FindProductCatalogAvailabilitiesPaginated'))
			})
	},

	/**
	* RPC Method: FindProductCatalogCharacteristicsPaginated
	* Description: Search through product characteristic
	* HTTP Method: GET
	* API Path: /api/v1/product_catalog_characteristic
	* @param    {ProductCatalogCharacteristicSearchRequest} req The API payload object (ProductCatalogCharacteristicSearchRequest)
	* @return   {ProductCatalogCharacteristicSearchResponse} The API response object (ProductCatalogCharacteristicSearchResponse)
	*
	* @typedef  {Object}  ProductCatalogCharacteristicSearchRequest
	* @property {SearchOptions[]} SearchOptions           Standard search options
	* @property {String}  ProductCatalogEntryUUID Service Provider Identifier,  int32 SPID = 2 [(tagger.tags) = "json:\"SPID\" db:\"SPID\" gorm:\"Column:SPID;NOT NULL\" bson:\"SPID\" graphql:\"SPID\" firestore:\"SPID\"" ];
	* @property {Boolean} IncludeDeleted          
	*
	* @typedef  {Object}  SearchOptions  Search options
	* @property {Number}  PageNumber     The page number to start at (or provide IndexStart)
	* @property {Number}  PageSize       The page size
	* @property {Number}  IndexStart     The record number to start at (or provide PageNumber)
	* @property {String}  SortBy         The field to sort by
	* @property {Boolean} SortDesc       Is the sort direction descending?
	*
	* @typedef  {Object}  ProductCatalogCharacteristicSearchResponse
	* @property {PageInfo} PageInfo                      PageInfo only needs the values ThisPageNumber && PageSize set
	* @property {ProductCatalogCharacteristic[]} ProductCatalogCharacteristics 
	*
	* @typedef  {Object}   PageInfo
	* @property {Number}   PageNumber             The page number
	* @property {Number}   PageItemCount          Number of items on this page
	* @property {Number}   TotalPages             Total pages available
	* @property {Number}   TotalItemCount         Total items available
	* @property {Number}   IndexStart             The record number this search started at
	* @property {Number}   PageSize               The length of the page
	* @property {Number[]} DisplayPageList        The array of page numbers to display
	* @property {Number}   DisplayPagesEitherSide Number of pages to display either side
	*
	*/
	FindProductCatalogCharacteristicsPaginated({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Find)
		return axios.get(`/v1/product_catalog_characteristic`, { params: req, validateStatus: valFuncFind }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			commit('mutSaveProductCatalogCharacteristics', { Response: response.data })
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('FindProductCatalogCharacteristicsPaginated ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('FindProductCatalogCharacteristicsPaginated ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('FindProductCatalogCharacteristicsPaginated ERROR:', error)
					return Promise.reject(error)
				}
				console.log('FindProductCatalogCharacteristicsPaginated UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in FindProductCatalogCharacteristicsPaginated'))
			})
	},

	/**
	* RPC Method: FindProductCatalogEntriesPaginated
	* Description: Search through product catalog
	* HTTP Method: GET
	* API Path: /api/v1/product_catalog
	* @param    {ProductCatalogEntrySearchRequest} req The API payload object (ProductCatalogEntrySearchRequest)
	* @return   {ProductCatalogEntrySearchResponse} The API response object (ProductCatalogEntrySearchResponse)
	*
	* @typedef  {Object}  ProductCatalogEntrySearchRequest
	* @property {SearchOptions[]} SearchOptions Standard search options
	* @property {String}  UUID          Service Provider Identifier
	* @property {Number}  SPID          SPID  not used
	*
	* @typedef  {Object}  SearchOptions  Search options
	* @property {Number}  PageNumber     The page number to start at (or provide IndexStart)
	* @property {Number}  PageSize       The page size
	* @property {Number}  IndexStart     The record number to start at (or provide PageNumber)
	* @property {String}  SortBy         The field to sort by
	* @property {Boolean} SortDesc       Is the sort direction descending?
	*
	* @typedef  {Object}  ProductCatalogEntrySearchResponse
	* @property {PageInfo} PageInfo              PageInfo only needs the values ThisPageNumber && PageSize set
	* @property {ProductCatalogEntry[]} ProductCatalogEntries 
	*
	* @typedef  {Object}   PageInfo
	* @property {Number}   PageNumber             The page number
	* @property {Number}   PageItemCount          Number of items on this page
	* @property {Number}   TotalPages             Total pages available
	* @property {Number}   TotalItemCount         Total items available
	* @property {Number}   IndexStart             The record number this search started at
	* @property {Number}   PageSize               The length of the page
	* @property {Number[]} DisplayPageList        The array of page numbers to display
	* @property {Number}   DisplayPagesEitherSide Number of pages to display either side
	*
	*/
	FindProductCatalogEntriesPaginated({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Find)
		return axios.get(`/v1/product_catalog`, { params: req, validateStatus: valFuncFind }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			commit('mutSaveProductCatalogEntries', { Response: response.data })
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('FindProductCatalogEntriesPaginated ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('FindProductCatalogEntriesPaginated ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('FindProductCatalogEntriesPaginated ERROR:', error)
					return Promise.reject(error)
				}
				console.log('FindProductCatalogEntriesPaginated UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in FindProductCatalogEntriesPaginated'))
			})
	},

	/**
	* RPC Method: FindProductCatalogEntriesWithDetailsPaginated
	* Description: Search through product catalog
	* HTTP Method: GET
	* API Path: /api/v1/${req.SPID}/product_catalog
	* @param    {ProductCatalogEntryWithDetailsSearchRequest} req The API payload object (ProductCatalogEntryWithDetailsSearchRequest)
	* @return   {ProductCatalogEntryWithDetailsSearchResponse} The API response object (ProductCatalogEntryWithDetailsSearchResponse)
	*
	* @typedef  {Object}  ProductCatalogEntryWithDetailsSearchRequest
	* @property {SearchOptions[]} SearchOptions Standard search options
	* @property {Number}  SPID          Service Provider Identifier - From Availability - required
	* @property {String}  ProductType   Search for a product type, supported types are: FWA, PostpayMobile
	* @property {String}  Name          Search for a product name - from Entry
	* @property {Boolean} Available     From Availability
	* @property {String}  CustomerUUID  From Availability
	*
	* @typedef  {Object}  ProductCatalogEntryWithDetailsSearchResponse
	* @property {PageInfo} PageInfo                            PageInfo only needs the values ThisPageNumber && PageSize set
	* @property {ProductCatalogEntryWithDetailsItem[]} ProductCatalogEntryWithDetailsItems Results
	*
	*/
	FindProductCatalogEntriesWithDetailsPaginated({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		// Prepare URI params...
		let paramSPID = encodeURIComponent(req.SPID)
		return axios.get(`/v1/${paramSPID}/product_catalog`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('FindProductCatalogEntriesWithDetailsPaginated ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('FindProductCatalogEntriesWithDetailsPaginated ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('FindProductCatalogEntriesWithDetailsPaginated ERROR:', error)
					return Promise.reject(error)
				}
				console.log('FindProductCatalogEntriesWithDetailsPaginated UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in FindProductCatalogEntriesWithDetailsPaginated'))
			})
	},

	/**
	* RPC Method: GetProductCatalogAvailabilityByUUID
	* Description: Get one product availability by UUID
	* HTTP Method: GET
	* API Path: /api/v1/product_catalog_availability/${req.UUID}
	* @param    {ProductCatalogAvailabilityUUIDSearch} req The API payload object (ProductCatalogAvailabilityUUIDSearch)
	* @return   {ProductCatalogAvailability} The API response object (ProductCatalogAvailability)
	*
	* @typedef  {Object}  ProductCatalogAvailabilityUUIDSearch
	* @property {SearchOptions[]} SearchOptions Standard search options
	* @property {Number}  SPID          The service provider
	* @property {String}  UUID          The UUID
	*
	* @typedef  {Object}  ProductCatalogAvailability
	* @property {String}  UUID                    The unique ID for this object
	* @property {String}  ProductCatalogEntryUUID The UUID of the product catalog entry that this defines availability information for
	* @property {Number}  SPID                    Service Provider Identifier of the service provider that this availability is defined for
	* @property {Number}  CreatedAtNanos          Unixnano when this product was created
	* @property {Number}  UpdatedAtNanos          Unixnano when this product was updated
	* @property {Number}  DeletedAtNanos          Unixnano when this product was deleted
	* @property {Boolean} Available               Is this product available?
	* @property {Number}  PriceOverride           An override price from the catalog price
	* @property {Number}  ValidFromNanos          The starting unixnano when this is valid
	* @property {Number}  PurchaseValidToNanos    The ending unixnano when this validatity finishes
	* @property {Number}  VersionValidToNanos     The ending unixnano when customers should be migrated to new Product Version
	* @property {Number}  Priority                The priority of this record as compared to other matches
	* @property {String}  RetailName              The name of this product to end-customers
	* @property {String}  RetailDescription       A description of this product to end-customers
	* @property {Number}  RetailCents             The retail price for the catalog item
	* @property {ProductCatalogAvailability_OverrideCharacteristicsEntry[]} OverrideCharacteristics Any catalog overrides being set - this is not stored in the DB directly
	*
	*/
	GetProductCatalogAvailabilityByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Get)
		// Prepare URI params...
		let paramUUID = encodeURIComponent(req.UUID)
		return axios.get(`/v1/product_catalog_availability/${paramUUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveProductCatalogAvailability', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetProductCatalogAvailabilityByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetProductCatalogAvailabilityByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetProductCatalogAvailabilityByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetProductCatalogAvailabilityByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetProductCatalogAvailabilityByUUID'))
			})
	},

	/**
	* RPC Method: GetProductCatalogCharacteristicByUUID
	* Description: Search through product characteristic
	* HTTP Method: GET
	* API Path: /api/v1/product_catalog_characteristic/${req.UUID}
	* @param    {ProductCatalogCharacteristicUUIDSearch} req The API payload object (ProductCatalogCharacteristicUUIDSearch)
	* @return   {ProductCatalogCharacteristic} The API response object (ProductCatalogCharacteristic)
	*
	* @typedef  {Object}  ProductCatalogCharacteristicUUIDSearch
	* @property {String}  UUID The UUID of the product catalog charastics
	*
	* @typedef  {Object}  ProductCatalogCharacteristic
	* @property {String}  UUID                    The unique index for this characteristic
	* @property {String}  ProductCatalogEntryUUID The product catalog entry this characteristic belongs to
	* @property {Number}  CreatedAtNanos          Unixnano when this characteristic was created
	* @property {Number}  DeletedAtNanos          Unixnano when this characteristic was deleted
	* @property {String}  ReplacesUUID            If this characteristic has been updated, the previous one is linked here
	* @property {String}  Name                    Name of this characteristic
	* @property {String}  DefaultValue            KILL VALUE on catalog only use default value,  string Value = 8 [json_name = "Value", (tagger.tags) = "json:\"Value\" db:\"Value\" gorm:\"Column:Value;Size:255;NOT NULL;INDEX\" bson:\"Value\" graphql:\"Value\" firestore:\"Value\""];,  Default value of this characteristic
	* @property {Boolean} Required                Is this characteristic required (ie, no default)
	* @property {String}  ValueType               The type of this characteristic, string, int, imsi, ipv4_address, ipv6_address, etc
	* @property {String}  ValidationRules         The validation rules for this type
	* @property {Boolean} CanAdd                  Can the customer specify this characteristic (read-only?)
	* @property {Boolean} CanUpdate               Can this characteristic be updated after creation
	*
	*/
	GetProductCatalogCharacteristicByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Get)
		// Prepare URI params...
		let paramUUID = encodeURIComponent(req.UUID)
		return axios.get(`/v1/product_catalog_characteristic/${paramUUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveProductCatalogCharacteristic', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetProductCatalogCharacteristicByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetProductCatalogCharacteristicByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetProductCatalogCharacteristicByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetProductCatalogCharacteristicByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetProductCatalogCharacteristicByUUID'))
			})
	},

	/**
	* RPC Method: GetProductCatalogEntryByUUID
	* Description: Get one product entry by UUID
	* HTTP Method: GET
	* API Path: /api/v1/product_catalog/${req.UUID}
	* @param    {ProductCatalogEntryUUIDSearch} req The API payload object (ProductCatalogEntryUUIDSearch)
	* @return   {ProductCatalogEntry} The API response object (ProductCatalogEntry)
	*
	* @typedef  {Object}  ProductCatalogEntryUUIDSearch
	* @property {SearchOptions[]} SearchOptions Standard search options
	* @property {String}  UUID          Entry UUID
	*
	* @typedef  {Object}  ProductCatalogEntry
	* @property {String}  UUID           The unique ID for this entry
	* @property {String}  Name           The name of this product
	* @property {String}  Description    A description of this product
	* @property {Number}  CreatedAtNanos Unixnano when this product was created
	* @property {Number}  UpdatedAtNanos Unixnano when this product was updated
	* @property {Number}  DeletedAtNanos DeletedAtNanos is when this product was deleted, and is used to signal the deletion of downstream copies.
	* @property {String}  ProductType    The type of product, ie, FWA or UFB or Mobile or TV or...
	*
	*/
	GetProductCatalogEntryByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Get)
		// Prepare URI params...
		let paramUUID = encodeURIComponent(req.UUID)
		return axios.get(`/v1/product_catalog/${paramUUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveProductCatalogEntry', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetProductCatalogEntryByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetProductCatalogEntryByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetProductCatalogEntryByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetProductCatalogEntryByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetProductCatalogEntryByUUID'))
			})
	},

	/**
	* RPC Method: GetProductCatalogEntryWithDetailsByAvailabilityUUID
	* Description: Get a product catalog entry using the Availability UUID
	* HTTP Method: GET
	* API Path: /api/v1/${req.SPID}/product_catalog/availability/${req.UUID}
	* @param    {GetProductCatalogEntryByUUIDRequest} req The API payload object (GetProductCatalogEntryByUUIDRequest)
	* @return   {GetProductCatalogEntryByUUIDResponse} The API response object (GetProductCatalogEntryByUUIDResponse)
	*
	* @typedef  {Object}  GetProductCatalogEntryByUUIDRequest
	* @property {Number}  SPID         Service Provider Identifier
	* @property {String}  UUID         The UUID of the product catalog entry
	* @property {String}  CustomerUUID CustomerUUID if the request is customer related
	*
	* @typedef  {Object}  GetProductCatalogEntryByUUIDResponse
	* @property {String}  UUID            Service Provider Identifier that this record belongs to,  int32 SPID = 1 [(tagger.tags) = "json:\"SPID\" db:\"SPID\" gorm:\"Column:SPID;NOT NULL\" bson:\"SPID\" graphql:\"SPID\" firestore:\"SPID\"" ];,  The UUID of this catalog entry
	* @property {ProductCatalogEntry} CatalogEntry    The actual catalog entry record
	* @property {ProductCatalogAvailability} Availability    The availability information for this SPID for this product catalog entry
	* @property {ProductCatalogCharacteristic[]} Characteristics The characteristics for this product catalog entry
	*
	*/
	GetProductCatalogEntryWithDetailsByAvailabilityUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		// Prepare URI params...
		let paramSPID = encodeURIComponent(req.SPID)
		let paramUUID = encodeURIComponent(req.UUID)
		return axios.get(`/v1/${paramSPID}/product_catalog/availability/${paramUUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetProductCatalogEntryWithDetailsByAvailabilityUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetProductCatalogEntryWithDetailsByAvailabilityUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetProductCatalogEntryWithDetailsByAvailabilityUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetProductCatalogEntryWithDetailsByAvailabilityUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetProductCatalogEntryWithDetailsByAvailabilityUUID'))
			})
	},

	/**
	* RPC Method: GetProductCatalogEntryWithDetailsByProductType
	* Description: Gets the most recent product catalog entry by product type
	* HTTP Method: GET
	* API Path: /api/v1/${req.SPID}/product_catalog/by_product_type
	* @param    {GetProductCatalogEntryByProductTypeRequest} req The API payload object (GetProductCatalogEntryByProductTypeRequest)
	* @return   {GetProductCatalogEntryByUUIDResponse} The API response object (GetProductCatalogEntryByUUIDResponse)
	*
	* @typedef  {Object}  GetProductCatalogEntryByProductTypeRequest
	* @property {Number}  SPID         Service Provider Identifier
	* @property {String}  ProductType  The UUID of the product catalog entry
	* @property {String}  CustomerUUID CustomerUUID if the request is customer related
	*
	* @typedef  {Object}  GetProductCatalogEntryByUUIDResponse
	* @property {String}  UUID            Service Provider Identifier that this record belongs to,  int32 SPID = 1 [(tagger.tags) = "json:\"SPID\" db:\"SPID\" gorm:\"Column:SPID;NOT NULL\" bson:\"SPID\" graphql:\"SPID\" firestore:\"SPID\"" ];,  The UUID of this catalog entry
	* @property {ProductCatalogEntry} CatalogEntry    The actual catalog entry record
	* @property {ProductCatalogAvailability} Availability    The availability information for this SPID for this product catalog entry
	* @property {ProductCatalogCharacteristic[]} Characteristics The characteristics for this product catalog entry
	*
	*/
	GetProductCatalogEntryWithDetailsByProductType({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		// Prepare URI params...
		let paramSPID = encodeURIComponent(req.SPID)
		return axios.get(`/v1/${paramSPID}/product_catalog/by_product_type`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetProductCatalogEntryWithDetailsByProductType ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetProductCatalogEntryWithDetailsByProductType ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetProductCatalogEntryWithDetailsByProductType ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetProductCatalogEntryWithDetailsByProductType UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetProductCatalogEntryWithDetailsByProductType'))
			})
	},

	/**
	* RPC Method: GetProductCatalogEntryWithDetailsByUUID
	* Description: Get a product catalog entry
	* HTTP Method: GET
	* API Path: /api/v1/${req.SPID}/product_catalog/${req.UUID}
	* @param    {GetProductCatalogEntryByUUIDRequest} req The API payload object (GetProductCatalogEntryByUUIDRequest)
	* @return   {GetProductCatalogEntryByUUIDResponse} The API response object (GetProductCatalogEntryByUUIDResponse)
	*
	* @typedef  {Object}  GetProductCatalogEntryByUUIDRequest
	* @property {Number}  SPID         Service Provider Identifier
	* @property {String}  UUID         The UUID of the product catalog entry
	* @property {String}  CustomerUUID CustomerUUID if the request is customer related
	*
	* @typedef  {Object}  GetProductCatalogEntryByUUIDResponse
	* @property {String}  UUID            Service Provider Identifier that this record belongs to,  int32 SPID = 1 [(tagger.tags) = "json:\"SPID\" db:\"SPID\" gorm:\"Column:SPID;NOT NULL\" bson:\"SPID\" graphql:\"SPID\" firestore:\"SPID\"" ];,  The UUID of this catalog entry
	* @property {ProductCatalogEntry} CatalogEntry    The actual catalog entry record
	* @property {ProductCatalogAvailability} Availability    The availability information for this SPID for this product catalog entry
	* @property {ProductCatalogCharacteristic[]} Characteristics The characteristics for this product catalog entry
	*
	*/
	GetProductCatalogEntryWithDetailsByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		// Prepare URI params...
		let paramSPID = encodeURIComponent(req.SPID)
		let paramUUID = encodeURIComponent(req.UUID)
		return axios.get(`/v1/${paramSPID}/product_catalog/${paramUUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetProductCatalogEntryWithDetailsByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetProductCatalogEntryWithDetailsByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetProductCatalogEntryWithDetailsByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetProductCatalogEntryWithDetailsByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetProductCatalogEntryWithDetailsByUUID'))
			})
	},

	/**
	* RPC Method: ProductCatalogEntryRelatedServiceProviders
	* Description: Return the related service providers for this product catalog entry.
	* HTTP Method: GET
	* API Path: /api/v1/product_catalog/${req.UUID}/related_spids
	* @param    {ProductCatalogEntryRelatedServiceProvidersRequest} req The API payload object (ProductCatalogEntryRelatedServiceProvidersRequest)
	* @return   {ProductCatalogEntryRelatedServiceProvidersResponse} The API response object (ProductCatalogEntryRelatedServiceProvidersResponse)
	*
	* @typedef  {Object}  ProductCatalogEntryRelatedServiceProvidersRequest
	* @property {String}  UUID UUID of the  ProductCatalogEntry.
	*
	* @typedef  {Object}  ProductCatalogEntryRelatedServiceProvidersResponse
	* @property {Number}  SPIDs SPIDs is a list of service provider IDs , relating to the requested ProductCatalogEntry, or ProductInstance.
	*
	*/
	ProductCatalogEntryRelatedServiceProviders({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		// Prepare URI params...
		let paramUUID = encodeURIComponent(req.UUID)
		return axios.get(`/v1/product_catalog/${paramUUID}/related_spids`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('ProductCatalogEntryRelatedServiceProviders ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('ProductCatalogEntryRelatedServiceProviders ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('ProductCatalogEntryRelatedServiceProviders ERROR:', error)
					return Promise.reject(error)
				}
				console.log('ProductCatalogEntryRelatedServiceProviders UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in ProductCatalogEntryRelatedServiceProviders'))
			})
	},

	/**
	* RPC Method: UpdateProductCatalogAvailability
	* Description: Update a product catalog availability
	* HTTP Method: PUT
	* API Path: /api/v1/product_catalog_availability/${req.UUID}
	* @param    {ProductCatalogAvailability} req The API payload object (ProductCatalogAvailability)
	* @return   {ProductCatalogAvailability} The API response object (ProductCatalogAvailability)
	*
	* @typedef  {Object}  ProductCatalogAvailability
	* @property {String}  UUID                    The unique ID for this object
	* @property {String}  ProductCatalogEntryUUID The UUID of the product catalog entry that this defines availability information for
	* @property {Number}  SPID                    Service Provider Identifier of the service provider that this availability is defined for
	* @property {Number}  CreatedAtNanos          Unixnano when this product was created
	* @property {Number}  UpdatedAtNanos          Unixnano when this product was updated
	* @property {Number}  DeletedAtNanos          Unixnano when this product was deleted
	* @property {Boolean} Available               Is this product available?
	* @property {Number}  PriceOverride           An override price from the catalog price
	* @property {Number}  ValidFromNanos          The starting unixnano when this is valid
	* @property {Number}  PurchaseValidToNanos    The ending unixnano when this validatity finishes
	* @property {Number}  VersionValidToNanos     The ending unixnano when customers should be migrated to new Product Version
	* @property {Number}  Priority                The priority of this record as compared to other matches
	* @property {String}  RetailName              The name of this product to end-customers
	* @property {String}  RetailDescription       A description of this product to end-customers
	* @property {Number}  RetailCents             The retail price for the catalog item
	* @property {ProductCatalogAvailability_OverrideCharacteristicsEntry[]} OverrideCharacteristics Any catalog overrides being set - this is not stored in the DB directly
	*
	*/
	UpdateProductCatalogAvailability({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Update)
		return axios.put(`/v1/product_catalog_availability/${req.UUID}`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveProductCatalogAvailability', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('UpdateProductCatalogAvailability ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('UpdateProductCatalogAvailability ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('UpdateProductCatalogAvailability ERROR:', error)
					return Promise.reject(error)
				}
				console.log('UpdateProductCatalogAvailability UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in UpdateProductCatalogAvailability'))
			})
	},

	/**
	* RPC Method: UpdateProductCatalogCharacteristic
	* Description: Update a product catalog characteristic
	* HTTP Method: PUT
	* API Path: /api/v1/product_catalog_characteristic/${req.UUID}
	* @param    {ProductCatalogCharacteristic} req The API payload object (ProductCatalogCharacteristic)
	* @return   {ProductCatalogCharacteristic} The API response object (ProductCatalogCharacteristic)
	*
	* @typedef  {Object}  ProductCatalogCharacteristic
	* @property {String}  UUID                    The unique index for this characteristic
	* @property {String}  ProductCatalogEntryUUID The product catalog entry this characteristic belongs to
	* @property {Number}  CreatedAtNanos          Unixnano when this characteristic was created
	* @property {Number}  DeletedAtNanos          Unixnano when this characteristic was deleted
	* @property {String}  ReplacesUUID            If this characteristic has been updated, the previous one is linked here
	* @property {String}  Name                    Name of this characteristic
	* @property {String}  DefaultValue            KILL VALUE on catalog only use default value,  string Value = 8 [json_name = "Value", (tagger.tags) = "json:\"Value\" db:\"Value\" gorm:\"Column:Value;Size:255;NOT NULL;INDEX\" bson:\"Value\" graphql:\"Value\" firestore:\"Value\""];,  Default value of this characteristic
	* @property {Boolean} Required                Is this characteristic required (ie, no default)
	* @property {String}  ValueType               The type of this characteristic, string, int, imsi, ipv4_address, ipv6_address, etc
	* @property {String}  ValidationRules         The validation rules for this type
	* @property {Boolean} CanAdd                  Can the customer specify this characteristic (read-only?)
	* @property {Boolean} CanUpdate               Can this characteristic be updated after creation
	*
	*/
	UpdateProductCatalogCharacteristic({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Update)
		return axios.put(`/v1/product_catalog_characteristic/${req.UUID}`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveProductCatalogCharacteristic', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('UpdateProductCatalogCharacteristic ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('UpdateProductCatalogCharacteristic ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('UpdateProductCatalogCharacteristic ERROR:', error)
					return Promise.reject(error)
				}
				console.log('UpdateProductCatalogCharacteristic UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in UpdateProductCatalogCharacteristic'))
			})
	},

	/**
	* RPC Method: UpdateProductCatalogEntry
	* Description: Update a product catalog entry
	* HTTP Method: PUT
	* API Path: /api/v1/product_catalog/${req.UUID}
	* @param    {ProductCatalogEntry} req The API payload object (ProductCatalogEntry)
	* @return   {ProductCatalogEntry} The API response object (ProductCatalogEntry)
	*
	* @typedef  {Object}  ProductCatalogEntry
	* @property {String}  UUID           The unique ID for this entry
	* @property {String}  Name           The name of this product
	* @property {String}  Description    A description of this product
	* @property {Number}  CreatedAtNanos Unixnano when this product was created
	* @property {Number}  UpdatedAtNanos Unixnano when this product was updated
	* @property {Number}  DeletedAtNanos DeletedAtNanos is when this product was deleted, and is used to signal the deletion of downstream copies.
	* @property {String}  ProductType    The type of product, ie, FWA or UFB or Mobile or TV or...
	*
	*/
	UpdateProductCatalogEntry({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Update)
		return axios.put(`/v1/product_catalog/${req.UUID}`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveProductCatalogEntry', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('UpdateProductCatalogEntry ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('UpdateProductCatalogEntry ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('UpdateProductCatalogEntry ERROR:', error)
					return Promise.reject(error)
				}
				console.log('UpdateProductCatalogEntry UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in UpdateProductCatalogEntry'))
			})
	},
};

export default {
	namespaced: true,
	state: initialState,
	getters,
	mutations,
	actions,
};