import axios from 'axios'
import axiosUnique from '@/utils/axiosUnique'

import StoreHash from '@/utils/StoreHash'

let hashed = {
    mobile_services: {
        uuids: {},
        lifetime: 5 * 60 * 1000, // 5 min
    }
}

const state = {
    mobile_services: {},
    mobile_services_plans: {},
}

const getters = {
    getMobileServiceByUUID: state => uuid => state.mobile_services[uuid],

    mobile_services_plans: state => state.mobile_services_plans,
}

const mutations = {
    saveMobileServicesPlans(state, plans) {
        let mobile_services_plans = {}

        for (let i = 0, len = plans.length; i < len; i++) {
            mobile_services_plans[plans[i].UUID] = plans[i]
        }

        state.mobile_services_plans = mobile_services_plans
    },

    saveMobileServices(state, services) {
        let services_list = {}

        for (let n = 0, len = services.length; n < len; n++) {
            const mobile_service = services[n]
            const uuid = mobile_service.UUID

            if (!(uuid in hashed.mobile_services.uuids)) {
                hashed.mobile_services.uuids[ uuid ] = new StoreHash(hashed.mobile_services.lifetime)
            }
            
            services_list[ uuid ] = mobile_service

            hashed.mobile_services.uuids[ uuid ].fix()
        }

        if (Object.keys(services_list).length) {
            state.mobile_services = {
                ...state.mobile_services,
                ...services_list
            }
        }
    },

    saveMobileService(state, service) {
        const uuid = service.UUID

        if (!(uuid in hashed.mobile_services.uuids)) {
            hashed.mobile_services.uuids[ uuid ] = new StoreHash(hashed.mobile_services.lifetime)
        }

        state.mobile_services = {
            ...state.mobile_services,
            [ uuid ]: service
        }

        hashed.mobile_services.uuids[ uuid ].fix()
    },

    deleteMobileServiceByUUID(state, uuid) {
        if (uuid in hashed.mobile_services.uuids) {
            delete hashed.mobile_services.uuids[ uuid ]
        }

        if (uuid in state.mobile_services) {
            delete state.mobile_services[ uuid ]
        }
    },

    resetHashedMobile(state) {
        state.mobile_services = {}

        hashed.mobile_services.uuids = {}
    }
}

const actions = {
    /**
     * Получение списка опций для выпадающего списка
     */
    getMobileServicesPlans({getters, commit}) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                import('./hardcoded-test-data/mobile/mobile-services-plans.json')
                    .then(response => {
                        const Plans = response.default.Plans

                        commit('saveMobileServicesPlans', Plans)

                        resolve(getters.mobile_services_plans)
                    })
                    .catch(error => {
                        reject(error)
                    })
            }, 1000)
        })

        /*
            const params = {
                'SearchOptions.PageSize': -1,
            }

            return axiosUnique.get('/v2/:spid/mobile/mobile-services-plans', params)
            .then(response => {
                const Plans = response.apidata.Plans

                commit('saveMobileServicesPlans', Plans)

                resolve(getters.mobile_services_plans)
            })
            .catch(error => {
                return Promise.reject(error)
            })
        */
    },

    getMobileServices({ commit }, { params }) {
        /**
            * @todo Replace 2 corresponding lines below for real data
            */
        return import('./hardcoded-test-data/mobile/mobile-services-list.json').then(response => {
            const services = response.default.Services
    
        // return axiosUnique.get(`/v2/:spid/mobile/services`, { params }).then(response => {
        //     const services = response.apidata.Services
    
            commit('saveMobileServices', services)
    
            return Promise.resolve(response)
        }).catch(error => {
            return Promise.reject(error)
        })
    
    },

    getMobileServiceByUUID({getters, commit}, { uuid, refresh }) {
        if (!(uuid in hashed.mobile_services.uuids)) {
            hashed.mobile_services.uuids[ uuid ] = new StoreHash(hashed.mobile_services.lifetime)
        }

        if (refresh || hashed.mobile_services.uuids[ uuid ].expired()) {
            /**
             * @todo Replace 3 corresponding lines below for real data
             */
            return import('./hardcoded-test-data/mobile/mobile-service-by-uuid.json').then(apidata => {
                const service = apidata.default
                uuid = service.UUID

                return Promise.resolve(service)
                
            // return axiosUnique.get(`/v2/:spid/mobile/service/${ uuid }`).then(({apidata}) => {
            //     const service = apidata

                commit('saveMobileService', service)
                return Promise.resolve(getters.getMobileServiceByUUID(uuid))
            }).catch(error => {
                return Promise.reject(error)
            })
        } else {
            return Promise.resolve(getters.getMobileServiceByUUID(uuid))
        }
    },

    deleteMobileServiceByUUID({ commit }, { uuid }) {

        return new Promise((resolve, reject) => {
            setTimeout(() => {
                import('./hardcoded-test-data/mobile/successfully-deleted-mobile-service.json')
                    .then(response => {
                        const service = response.default

                        commit('deleteMobileServiceByUUID', uuid)

                        resolve({ service })
                    })
                    .catch(error => {
                        reject(error)
                    })
            }, 2000)
        })

        /*
            return axiosUnique.delete(`/v2/:spid/mobile/service/${ uuid }`)
                .then(({ apidata }) => {
                    const service = apidata

                    commit('deleteMobileServiceByUUID', uuid)

                    return Promise.resolve({ service })
                })
                .catch(error => {
                    return Promise.reject(error)
                })
        */
    },

    getMobileOrders({ commit }, { params }) {

        return new Promise((resolve, reject) => {
            setTimeout(() => {
                import('./hardcoded-test-data/mobile/orders-list.json')
                    .then(response => {
                        const PageInfo = response.default.PageInfo
                        const Orders = response.default.Orders
    
                        resolve({ PageInfo, Orders })
                    })
                    .catch(error => {
                        reject(error)
                    })
            }, 1000)
        })
    
        /*
            return axiosUnique.get('/v2/:spid/mobile/orders', { params })
                .then(response => {
                    const PageInfo = response.apidata.PageInfo
                    const Orders = response.apidata.Orders
    
                    resolve({ PageInfo, Orders })
                })
                .catch(error => {
                    return Promise.reject(error)
                })
        */
    
    },

    getMobileProducts({ commit }, { params }) {

        return new Promise((resolve, reject) => {
            setTimeout(() => {
                import('./hardcoded-test-data/mobile/products-list.json')
                    .then(response => {
                        const PageInfo = response.default.PageInfo
                        const Products = response.default.Products
    
                        resolve({ PageInfo, Products })
                    })
                    .catch(error => {
                        reject(error)
                    })
            }, 1000)
        })

        /*
            return axiosUnique.get('/v2/:spid/mobile/products', { params })
                .then(response => {
                    const PageInfo = response.apidata.PageInfo
                    const Products = response.apidata.Products
    
                    resolve({ PageInfo, Products })
                })
                .catch(error => {
                    return Promise.reject(error)
                })
        */
    
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
}