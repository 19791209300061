import axios from 'axios'
import axiosUnique from '@/utils/axiosUnique'

import StoreHash from '@/utils/StoreHash'

let hashed = {
    teams: {
        uuids: {},
        lifetime: 1 * 60 * 1000, // 1 min
    },
    teams_all: new StoreHash(1 * 60 * 1000), // 1 min
    teams_roles: new StoreHash(5 * 60 * 1000), // 5 min
}

const state = {
    teams: {},
    teams_roles: {},
    teams_filter: null,
}

const getters = {
    getTeamByUUID: state => uuid => state.teams[uuid],

    teams: state => state.teams,
    teams_roles: state => state.teams_roles,

    teams_filter: state => state.teams_filter,
}

const mutations = {
    saveTeams(state, { teams, is_all }) {
        let teams_list = {}

        for (let n = 0, len = teams.length; n < len; n++) {
            const team = teams[n]
            const uuid = team.UUID

            if (!(uuid in hashed.teams.uuids)) {
                hashed.teams.uuids[ uuid ] = new StoreHash(hashed.teams.lifetime)
            }

            teams_list[ uuid ] = team

            hashed.teams.uuids[ uuid ].fix()
        }

        if (is_all) {
            state.teams = teams_list
            hashed.teams_all.fix()
        } else {
            if (Object.keys(teams_list).length) {
                state.teams = {
                    ...state.teams,
                    ...teams_list
                }
            }
        }
    },

    saveTeam(state, team) {
        const uuid = team.UUID

        if (!(uuid in hashed.teams.uuids)) {
            hashed.teams.uuids[ uuid ] = new StoreHash(hashed.teams.lifetime)
        }

        state.teams = {
            ...state.teams,
            [ uuid ]: team
        }

        hashed.teams.uuids[ uuid ].fix()
    },

    saveTeamsRoles(state, roles) {
        let teams_roles = {}

        for (let i = 0, len = roles.length; i < len; i++) {
            const role = roles[i]

            teams_roles[ role.Name ] = role
        }

        state.teams_roles = teams_roles
    },

    saveTeamsFilter(state, filter) {
        state.teams_filter = filter
    },

    clearTeamsFilter(state) {
        state.teams_filter = null
    },

    resetHashedTeams(state) {
        state.teams = {}
        state.teams_roles = {}

        hashed.teams.uuids = {}
        hashed.teams_all.reset()
        hashed.teams_roles.reset()
    },
}

const actions = {
    getTeamsAll({ getters, commit, dispatch }, payload = { params: {} }) {
        if (hashed.teams_all.expired()) {
            const _params = {
                SPID: getters.current_spid,
                ...payload.params,
                'SearchOptions.PageSize': -1,
            }

            return dispatch('getTeams', { params: _params, is_all: true })
        } else {
            return Promise.resolve(getters.teams)
        }
    },

    getTeams({commit, dispatch}, { params, filter, is_all }) {
        // return import('./hardcoded-test-data/teams/teams-list.json').then(response => {
        //     const teams = response.default.Teams

        return axiosUnique.get('/v3/auth_role_group', { params }).then(response => {
            const { data } = response
            const { AuthRoleGroups } = data

            const teams = AuthRoleGroups

            const resolveTeams = () => {
                commit('saveTeams', { teams, is_all })

                const action = filter ? 'saveTeamsFilter' : 'clearTeamsFilter'
                const resolve = () => Promise.resolve(data)

                return dispatch(action, filter).then(resolve).catch(resolve)
            }

            return dispatch('getTeamsRoles').then(resolveTeams).catch(resolveTeams)
        }).catch(error => {
            return Promise.reject(error)
        })
    },

    getTeamByUUID({ getters, commit, dispatch }, { uuid, refresh }) {
        if (!(uuid in hashed.teams.uuids)) {
            hashed.teams.uuids[ uuid ] = new StoreHash(hashed.teams.lifetime)
        }

        if (refresh || hashed.teams.uuids[ uuid ].expired()) {
            return axiosUnique.get(`/v3/auth_role_group/${ uuid }`).then(({ data: team }) => {

                const resolveTeam = () => {
                    commit('saveTeam', team)
                    return Promise.resolve(getters.getTeamByUUID(uuid))
                }

                return dispatch('getTeamsRoles').then(resolveTeam).catch(resolveTeam)
            }).catch(error => {
                return Promise.reject(error)
            })
        } else {
            return Promise.resolve(getters.getTeamByUUID(uuid))
        }
    },

    getTeamsRoles({ getters, commit }) {
        /**
         * @todo Replace corresponding lines below for real data
         */
        return import('./hardcoded-test-data/teams/teams-roles.json')
            .then(apidata => {
                const filteredRoles = []

                let can_add_role = false

                const spmodules = getters.modules_sp

                for (let i = 0, len = apidata.Roles.length; i < len; i++) {
                    can_add_role = true

                    if (Array.isArray(apidata.Roles[i].IncludeWithModules) && apidata.Roles[i].IncludeWithModules.length) {
                        can_add_role = false

                        for (let j = 0, len = apidata.Roles[i].IncludeWithModules.length; j < len; j++) {
                            if (apidata.Roles[i].IncludeWithModules[j] in spmodules) {
                                can_add_role = true
                                break
                            }
                        }
                    }

                    if (can_add_role && Array.isArray(apidata.Roles[i].WithAppThemes) && apidata.Roles[i].WithAppThemes.length) {
                        can_add_role = false

                        for (let j = 0, len = apidata.Roles[i].WithAppThemes.length; j < len; j++) {
                            if (apidata.Roles[i].WithAppThemes[j] == process.env.VUE_APP_THEME) {
                                can_add_role = true
                                break
                            }
                        }
                    }

                    if (can_add_role) {
                        filteredRoles.push(apidata.Roles[i])
                    }
                }

                commit('saveTeamsRoles', filteredRoles)

                return Promise.resolve(getters.teams_roles)
            })
            .catch(error => {
                return Promise.reject(error)
            })
    },

    updateTeam({getters, commit}, { uuid, params }) {
        return axios.put(`/v2/:spid/team/${ uuid }`, params).then(({apidata}) => {
            commit('saveTeam', apidata)
            return Promise.resolve(getters.getTeamByUUID(uuid))
        }).catch(error => {
            return Promise.reject(error)
        })
    },

    removeTeam({}, { UUID }) {
        return axios.delete(`/v3/auth_role_group/${ UUID }`)
            .then(response => {
                return Promise.resolve(response)
            })
            .catch(error => {
                return Promise.reject(error)
            })
    },

    getTeamMembers({commit}, { uuid, params }) {
        /**
         * @todo Replace 2 corresponding lines below for real data
         */
        return import('./hardcoded-test-data/teams/team-members.json').then(response => {
            const members = response.default.Members

        // return axiosUnique.get(`/v2/:spid/team/${ uuid }/members`, { params }).then(response => {
        //     const members = response.apidata.Members

            commit('saveMembers', { members })
            return Promise.resolve(response)
        }).catch(error => {
            return Promise.reject(error)
        })
    },

    loadTeamsFilter({getters}) {
        const filter = getters.teams_filter

        return filter
            ? Promise.resolve(filter)
            : Promise.reject(filter)
    },

    saveTeamsFilter({commit, getters}, filter) {
        commit('saveTeamsFilter', filter)
        return Promise.resolve(getters.teams_filter)
    },

    clearTeamsFilter({commit, getters}) {
        commit('clearTeamsFilter')
        return Promise.resolve(getters.teams_filter)
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
}