// Code generated by protoc-gen-iqwebapi. DO NOT EDIT.
// source: service_catalog.proto

import axios from 'axios';
import StoreHash from '@/utils/StoreHash';

const initialState = {
	ServiceCatalogCharacteristics: {
		UUIDs: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
	ServiceCatalogEntries: {
		UUIDs: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
};

const valFuncFind = function (status) {
	return (status == 200) ? true : (status == 404) ? true : false;
}

const default_pageinfo = {
	PageNumber: 0,
	PageItemCount: 0,
	TotalPages: 0,
	TotalItemCount: 0,
	IndexStart: 0,
	PageSize: 10,
	DisplayPageList: [],
	DisplayPageEitherSide: 4,
}

const getters = {
	getServiceCatalogCharacteristicByUUID: state => (UUID) => (state.ServiceCatalogCharacteristics.UUIDs[ UUID ] && !state.ServiceCatalogCharacteristics.UUIDs[ UUID ].expired()) ?  state.ServiceCatalogCharacteristics.UUIDs[ UUID ].hashedData() : null,
	getServiceCatalogEntryByUUID: state => (UUID) => (state.ServiceCatalogEntries.UUIDs[ UUID ] && !state.ServiceCatalogEntries.UUIDs[ UUID ].expired()) ?  state.ServiceCatalogEntries.UUIDs[ UUID ].hashedData() : null,
};

const mutations = {
	// mutSaveServiceCatalogCharacteristic saves a single ServiceCatalogCharacteristic object
	// into both the StoreHash cache and the VueX store
	mutSaveServiceCatalogCharacteristic(state, obj) {
		if (!obj.UUID) { return } // Must have UUID to Save

		if (!(obj.UUID in state.ServiceCatalogCharacteristics.UUIDs)) {
			state.ServiceCatalogCharacteristics.UUIDs[ obj.UUID ] = new StoreHash(state.ServiceCatalogCharacteristics.lifetime)
		}
		state.ServiceCatalogCharacteristics.UUIDs[ obj.UUID ].fix(obj) // Add item to StoreHash
	},
	// mutRemoveServiceCatalogCharacteristicByUUID removes a ServiceCatalogCharacteristic object
	// with the given UUID from StoreHash cache and VueX store
	mutRemoveServiceCatalogCharacteristicByUUID(state, UUID) {
		if (!UUID) {
			// Must have UUID to Remove
			return
		}
		delete state.ServiceCatalogCharacteristics.UUIDs[ UUID ]    // remove item from StoreHash
	},
	// mutSaveServiceCatalogCharacteristics saves a paginated response of ServiceCatalogCharacteristics
	mutSaveServiceCatalogCharacteristics(state, { Response }) {
		if (!Response || !Array.isArray(Response.ServiceCatalogCharacteristics)) {
			// Must have Response object with results
			return
		}
		for (let i = 0; i < Response.ServiceCatalogCharacteristics.length; i++) {
			if (!(Response.ServiceCatalogCharacteristics[i].UUID in state.ServiceCatalogCharacteristics.UUIDs)) {
				state.ServiceCatalogCharacteristics.UUIDs[ Response.ServiceCatalogCharacteristics[i].UUID ] = new StoreHash(state.ServiceCatalogCharacteristics.lifetime)
			}
			state.ServiceCatalogCharacteristics.UUIDs[ Response.ServiceCatalogCharacteristics[i].UUID ].fix(Response.ServiceCatalogCharacteristics[i])  // Add single ServiceCatalogCharacteristic to StoreHash
		}
	},
	// mutSaveServiceCatalogEntry saves a single ServiceCatalogEntry object
	// into both the StoreHash cache and the VueX store
	mutSaveServiceCatalogEntry(state, obj) {
		if (!obj.UUID) { return } // Must have UUID to Save

		if (!(obj.UUID in state.ServiceCatalogEntries.UUIDs)) {
			state.ServiceCatalogEntries.UUIDs[ obj.UUID ] = new StoreHash(state.ServiceCatalogEntries.lifetime)
		}
		state.ServiceCatalogEntries.UUIDs[ obj.UUID ].fix(obj) // Add item to StoreHash
	},
	// mutRemoveServiceCatalogEntryByUUID removes a ServiceCatalogEntry object
	// with the given UUID from StoreHash cache and VueX store
	mutRemoveServiceCatalogEntryByUUID(state, UUID) {
		if (!UUID) {
			// Must have UUID to Remove
			return
		}
		delete state.ServiceCatalogEntries.UUIDs[ UUID ]    // remove item from StoreHash
	},
	// mutSaveServiceCatalogEntries saves a paginated response of ServiceCatalogEntries
	mutSaveServiceCatalogEntries(state, { Response }) {
		if (!Response || !Array.isArray(Response.ServiceCatalogEntries)) {
			// Must have Response object with results
			return
		}
		for (let i = 0; i < Response.ServiceCatalogEntries.length; i++) {
			if (!(Response.ServiceCatalogEntries[i].UUID in state.ServiceCatalogEntries.UUIDs)) {
				state.ServiceCatalogEntries.UUIDs[ Response.ServiceCatalogEntries[i].UUID ] = new StoreHash(state.ServiceCatalogEntries.lifetime)
			}
			state.ServiceCatalogEntries.UUIDs[ Response.ServiceCatalogEntries[i].UUID ].fix(Response.ServiceCatalogEntries[i])  // Add single ServiceCatalogEntry to StoreHash
		}
	},
};

const actions = {
	// getCachedServiceCatalogCharacteristicByUUID fetches a ServiceCatalogCharacteristic from the cache
	// and if not in the cache, fetches it from the API
	getCachedServiceCatalogCharacteristicByUUID({ state, dispatch, commit }, { UUID, RefreshCache }) {
		if (!RefreshCache && state.ServiceCatalogCharacteristics.UUIDs[ UUID ] && !state.ServiceCatalogCharacteristics.UUIDs[ UUID ].expired()) {
			// Return the cached record
			return state.ServiceCatalogCharacteristics.UUIDs[ UUID ].hashedData()
		}
		// Fetch from the API
		return dispatch('GetServiceCatalogCharacteristicByUUID', { UUID }).then(respPayload => {
			commit('mutSaveServiceCatalogCharacteristic', respPayload)
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveServiceCatalogCharacteristicByUUID', UUID)
			return Promise.reject(error)
		})
	},

	// getCachedServiceCatalogEntryByUUID fetches a ServiceCatalogEntry from the cache
	// and if not in the cache, fetches it from the API
	getCachedServiceCatalogEntryByUUID({ state, dispatch, commit }, { UUID, RefreshCache }) {
		if (!RefreshCache && state.ServiceCatalogEntries.UUIDs[ UUID ] && !state.ServiceCatalogEntries.UUIDs[ UUID ].expired()) {
			// Return the cached record
			return state.ServiceCatalogEntries.UUIDs[ UUID ].hashedData()
		}
		// Fetch from the API
		return dispatch('GetServiceCatalogEntryByUUID', { UUID }).then(respPayload => {
			commit('mutSaveServiceCatalogEntry', respPayload)
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveServiceCatalogEntryByUUID', UUID)
			return Promise.reject(error)
		})
	},

	//
	// Service: ServiceCatalogEntryRPC
	// 
	/**
	* RPC Method: AddServiceCatalogCharacteristic
	* Description: Add a new service catalog characteristic
	* HTTP Method: POST
	* API Path: /api/v1/service_catalog_characteristic
	* @param    {ServiceCatalogCharacteristic} req The API payload object (ServiceCatalogCharacteristic)
	* @return   {ServiceCatalogCharacteristic} The API response object (ServiceCatalogCharacteristic)
	*
	* @typedef  {Object}  ServiceCatalogCharacteristic
	* @property {String}  UUID                    The unique index for this characteristic
	* @property {String}  ServiceCatalogEntryUUID The service catalog entry that this characteristic belongs to
	* @property {Number}  CreatedAtNanos          Unixnano when this characteristic was created
	* @property {Number}  DeletedAtNanos          Unixnano when this characteristic was deleted
	* @property {String}  ReplacesUUID            If this characteristic has been updated, the previous one is linked here
	* @property {String}  Name                    Name of this characteristic
	* @property {String}  Value                   Value of this characteristic
	* @property {String}  DefaultValue            Default value of this characteristic
	* @property {Boolean} Required                Is this characteristic required (ie, no default)
	* @property {String}  ValueType               The type of this characteristic, string, int, imsi, ipv4_address, ipv6_address, etc
	* @property {String}  ValidationRules         The validation rules for this type
	* @property {Boolean} CanAdd                  Can the customer specify this characteristic (read-only?)
	* @property {Boolean} CanUpdate               Can this characteristic be updated after creation
	*
	*/
	AddServiceCatalogCharacteristic({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Add)
		return axios.post(`/v1/service_catalog_characteristic`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('AddServiceCatalogCharacteristic ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('AddServiceCatalogCharacteristic ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('AddServiceCatalogCharacteristic ERROR:', error)
					return Promise.reject(error)
				}
				console.log('AddServiceCatalogCharacteristic UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in AddServiceCatalogCharacteristic'))
			})
	},

	/**
	* RPC Method: AddServiceCatalogEntry
	* Description: Search through service catalog
	* HTTP Method: POST
	* API Path: /api/v1/service_catalog
	* @param    {ServiceCatalogEntry} req The API payload object (ServiceCatalogEntry)
	* @return   {ServiceCatalogEntry} The API response object (ServiceCatalogEntry)
	*
	* @typedef  {Object}  ServiceCatalogEntry
	* @property {String}  UUID           Unique ID for this service catalog entry
	* @property {String}  Name           CRUD: service_catalog
	* @property {Number}  CreatedAtNanos Unixnanos when this catalog entry was created
	* @property {Number}  UpdatedAtNanos Unixnanos when this catalog entry was updated
	* @property {Number}  DeletedAtNanos Unixnanos when this catalog entry was deleted
	* @property {String}  ServiceType    The type of service, ie, Mobility, Fixed, TV, siptrunk....
	*
	*/
	AddServiceCatalogEntry({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Add)
		return axios.post(`/v1/service_catalog`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('AddServiceCatalogEntry ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('AddServiceCatalogEntry ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('AddServiceCatalogEntry ERROR:', error)
					return Promise.reject(error)
				}
				console.log('AddServiceCatalogEntry UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in AddServiceCatalogEntry'))
			})
	},

	/**
	* RPC Method: DeleteServiceCatalogCharacteristic
	* Description: Delete a service catalog characteristic
	* HTTP Method: DELETE
	* API Path: /api/v1/service_catalog_characteristic/${req.UUID}
	* @param    {DeleteServiceCatalogCharacteristicRequest} req The API payload object (DeleteServiceCatalogCharacteristicRequest)
	* @return   {ServiceCatalogCharacteristic} The API response object (ServiceCatalogCharacteristic)
	*
	* @typedef  {Object}  DeleteServiceCatalogCharacteristicRequest
	* @property {String}  UUID UUID of the service catalog characteristic
	*
	* @typedef  {Object}  ServiceCatalogCharacteristic
	* @property {String}  UUID                    The unique index for this characteristic
	* @property {String}  ServiceCatalogEntryUUID The service catalog entry that this characteristic belongs to
	* @property {Number}  CreatedAtNanos          Unixnano when this characteristic was created
	* @property {Number}  DeletedAtNanos          Unixnano when this characteristic was deleted
	* @property {String}  ReplacesUUID            If this characteristic has been updated, the previous one is linked here
	* @property {String}  Name                    Name of this characteristic
	* @property {String}  Value                   Value of this characteristic
	* @property {String}  DefaultValue            Default value of this characteristic
	* @property {Boolean} Required                Is this characteristic required (ie, no default)
	* @property {String}  ValueType               The type of this characteristic, string, int, imsi, ipv4_address, ipv6_address, etc
	* @property {String}  ValidationRules         The validation rules for this type
	* @property {Boolean} CanAdd                  Can the customer specify this characteristic (read-only?)
	* @property {Boolean} CanUpdate               Can this characteristic be updated after creation
	*
	*/
	DeleteServiceCatalogCharacteristic({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Delete)
		return axios.delete(`/v1/service_catalog_characteristic/${req.UUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveServiceCatalogCharacteristic', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('DeleteServiceCatalogCharacteristic ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('DeleteServiceCatalogCharacteristic ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('DeleteServiceCatalogCharacteristic ERROR:', error)
					return Promise.reject(error)
				}
				console.log('DeleteServiceCatalogCharacteristic UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in DeleteServiceCatalogCharacteristic'))
			})
	},

	/**
	* RPC Method: DeleteServiceCatalogEntry
	* Description: Delete a service catalog entrie and associated characteristics and availabilities
	* HTTP Method: DELETE
	* API Path: /api/v1/service_catalog/${req.UUID}
	* @param    {DeleteServiceCatalogEntryRequest} req The API payload object (DeleteServiceCatalogEntryRequest)
	* @return   {ServiceCatalogEntry} The API response object (ServiceCatalogEntry)
	*
	* @typedef  {Object}  DeleteServiceCatalogEntryRequest
	* @property {String}  UUID UUID of the service catalog entry
	*
	* @typedef  {Object}  ServiceCatalogEntry
	* @property {String}  UUID           Unique ID for this service catalog entry
	* @property {String}  Name           CRUD: service_catalog
	* @property {Number}  CreatedAtNanos Unixnanos when this catalog entry was created
	* @property {Number}  UpdatedAtNanos Unixnanos when this catalog entry was updated
	* @property {Number}  DeletedAtNanos Unixnanos when this catalog entry was deleted
	* @property {String}  ServiceType    The type of service, ie, Mobility, Fixed, TV, siptrunk....
	*
	*/
	DeleteServiceCatalogEntry({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Delete)
		return axios.delete(`/v1/service_catalog/${req.UUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveServiceCatalogEntry', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('DeleteServiceCatalogEntry ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('DeleteServiceCatalogEntry ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('DeleteServiceCatalogEntry ERROR:', error)
					return Promise.reject(error)
				}
				console.log('DeleteServiceCatalogEntry UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in DeleteServiceCatalogEntry'))
			})
	},

	/**
	* RPC Method: FindServiceCatalogCharacteristicsPaginated
	* Description: Search through service characteristic
	* HTTP Method: GET
	* API Path: /api/v1/service_catalog_characteristic
	* @param    {ServiceCatalogCharacteristicSearchRequest} req The API payload object (ServiceCatalogCharacteristicSearchRequest)
	* @return   {ServiceCatalogCharacteristicSearchResponse} The API response object (ServiceCatalogCharacteristicSearchResponse)
	*
	* @typedef  {Object}  ServiceCatalogCharacteristicSearchRequest
	* @property {SearchOptions[]} SearchOptions           Standard search options
	* @property {String}  ServiceCatalogEntryUUID The service catalog entry that this characteristic belongs to
	* @property {String}  Name                    Name of this characteristic
	* @property {String}  Value                   Value of this characteristic
	* @property {String}  DefaultValue            Default value of this characteristic
	* @property {Boolean} Required                Is this characteristic required (ie, no default)
	* @property {String}  ValueType               The type of this characteristic, string, int, imsi, ipv4_address, ipv6_address, etc
	* @property {String}  ValidationRules         The validation rules for this type
	* @property {Boolean} IncludeDeleted          
	* @property {String}  ReplacesUUID            
	*
	* @typedef  {Object}  SearchOptions  Search options
	* @property {Number}  PageNumber     The page number to start at (or provide IndexStart)
	* @property {Number}  PageSize       The page size
	* @property {Number}  IndexStart     The record number to start at (or provide PageNumber)
	* @property {String}  SortBy         The field to sort by
	* @property {Boolean} SortDesc       Is the sort direction descending?
	*
	* @typedef  {Object}  ServiceCatalogCharacteristicSearchResponse
	* @property {PageInfo} PageInfo                      PageInfo only needs the values ThisPageNumber && PageSize set
	* @property {ServiceCatalogCharacteristic[]} ServiceCatalogCharacteristics Repeated list of service instances
	*
	* @typedef  {Object}   PageInfo
	* @property {Number}   PageNumber             The page number
	* @property {Number}   PageItemCount          Number of items on this page
	* @property {Number}   TotalPages             Total pages available
	* @property {Number}   TotalItemCount         Total items available
	* @property {Number}   IndexStart             The record number this search started at
	* @property {Number}   PageSize               The length of the page
	* @property {Number[]} DisplayPageList        The array of page numbers to display
	* @property {Number}   DisplayPagesEitherSide Number of pages to display either side
	*
	*/
	FindServiceCatalogCharacteristicsPaginated({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Find)
		return axios.get(`/v1/service_catalog_characteristic`, { params: req, validateStatus: valFuncFind }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			commit('mutSaveServiceCatalogCharacteristics', { Response: response.data })
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('FindServiceCatalogCharacteristicsPaginated ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('FindServiceCatalogCharacteristicsPaginated ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('FindServiceCatalogCharacteristicsPaginated ERROR:', error)
					return Promise.reject(error)
				}
				console.log('FindServiceCatalogCharacteristicsPaginated UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in FindServiceCatalogCharacteristicsPaginated'))
			})
	},

	/**
	* RPC Method: FindServiceCatalogEntriesPaginated
	* Description: Search through service catalog
	* HTTP Method: GET
	* API Path: /api/v1/service_catalog
	* @param    {ServiceCatalogEntrySearchRequest} req The API payload object (ServiceCatalogEntrySearchRequest)
	* @return   {ServiceCatalogEntrySearchResponse} The API response object (ServiceCatalogEntrySearchResponse)
	*
	* @typedef  {Object}  ServiceCatalogEntrySearchRequest
	* @property {SearchOptions[]} SearchOptions Standard search options
	* @property {String}  UUID          UUID of service catalog entry, probably use the get service by uuid call instead
	* @property {String}  ServiceType   Service type, supported types are: mobility
	* @property {Number}  SPID          SPID
	* @property {String}  Name          Name of this service catalog entry
	* @property {String}  SmartSearch   NOT USED
	* @property {String}  CustomerUUID  NOT USED
	*
	* @typedef  {Object}  SearchOptions  Search options
	* @property {Number}  PageNumber     The page number to start at (or provide IndexStart)
	* @property {Number}  PageSize       The page size
	* @property {Number}  IndexStart     The record number to start at (or provide PageNumber)
	* @property {String}  SortBy         The field to sort by
	* @property {Boolean} SortDesc       Is the sort direction descending?
	*
	* @typedef  {Object}  ServiceCatalogEntrySearchResponse
	* @property {PageInfo} PageInfo              PageInfo only needs the values ThisPageNumber && PageSize set
	* @property {ServiceCatalogEntry[]} ServiceCatalogEntries An array of ServiceCatalogEntry records
	*
	* @typedef  {Object}   PageInfo
	* @property {Number}   PageNumber             The page number
	* @property {Number}   PageItemCount          Number of items on this page
	* @property {Number}   TotalPages             Total pages available
	* @property {Number}   TotalItemCount         Total items available
	* @property {Number}   IndexStart             The record number this search started at
	* @property {Number}   PageSize               The length of the page
	* @property {Number[]} DisplayPageList        The array of page numbers to display
	* @property {Number}   DisplayPagesEitherSide Number of pages to display either side
	*
	*/
	FindServiceCatalogEntriesPaginated({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Find)
		return axios.get(`/v1/service_catalog`, { params: req, validateStatus: valFuncFind }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			commit('mutSaveServiceCatalogEntries', { Response: response.data })
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('FindServiceCatalogEntriesPaginated ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('FindServiceCatalogEntriesPaginated ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('FindServiceCatalogEntriesPaginated ERROR:', error)
					return Promise.reject(error)
				}
				console.log('FindServiceCatalogEntriesPaginated UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in FindServiceCatalogEntriesPaginated'))
			})
	},

	/**
	* RPC Method: GetServiceCatalogCharacteristicByUUID
	* Description: Search through service characteristic
	* HTTP Method: GET
	* API Path: /api/v1/service_catalog_characteristic/${req.UUID}
	* @param    {ServiceCatalogCharacteristicUUIDSearch} req The API payload object (ServiceCatalogCharacteristicUUIDSearch)
	* @return   {ServiceCatalogCharacteristic} The API response object (ServiceCatalogCharacteristic)
	*
	* @typedef  {Object}  ServiceCatalogCharacteristicUUIDSearch
	* @property {String}  UUID UUID of the service catalog characteristic
	*
	* @typedef  {Object}  ServiceCatalogCharacteristic
	* @property {String}  UUID                    The unique index for this characteristic
	* @property {String}  ServiceCatalogEntryUUID The service catalog entry that this characteristic belongs to
	* @property {Number}  CreatedAtNanos          Unixnano when this characteristic was created
	* @property {Number}  DeletedAtNanos          Unixnano when this characteristic was deleted
	* @property {String}  ReplacesUUID            If this characteristic has been updated, the previous one is linked here
	* @property {String}  Name                    Name of this characteristic
	* @property {String}  Value                   Value of this characteristic
	* @property {String}  DefaultValue            Default value of this characteristic
	* @property {Boolean} Required                Is this characteristic required (ie, no default)
	* @property {String}  ValueType               The type of this characteristic, string, int, imsi, ipv4_address, ipv6_address, etc
	* @property {String}  ValidationRules         The validation rules for this type
	* @property {Boolean} CanAdd                  Can the customer specify this characteristic (read-only?)
	* @property {Boolean} CanUpdate               Can this characteristic be updated after creation
	*
	*/
	GetServiceCatalogCharacteristicByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Get)
		// Prepare URI params...
		let paramUUID = encodeURIComponent(req.UUID)
		return axios.get(`/v1/service_catalog_characteristic/${paramUUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveServiceCatalogCharacteristic', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetServiceCatalogCharacteristicByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetServiceCatalogCharacteristicByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetServiceCatalogCharacteristicByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetServiceCatalogCharacteristicByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetServiceCatalogCharacteristicByUUID'))
			})
	},

	/**
	* RPC Method: GetServiceCatalogEntryByUUID
	* Description: Get one service entry by UUID
	* HTTP Method: GET
	* API Path: /api/v1/service_catalog/${req.UUID}
	* @param    {ServiceCatalogEntryUUIDSearch} req The API payload object (ServiceCatalogEntryUUIDSearch)
	* @return   {ServiceCatalogEntry} The API response object (ServiceCatalogEntry)
	*
	* @typedef  {Object}  ServiceCatalogEntryUUIDSearch
	* @property {String}  UUID UUID of the service catalog entry
	*
	* @typedef  {Object}  ServiceCatalogEntry
	* @property {String}  UUID           Unique ID for this service catalog entry
	* @property {String}  Name           CRUD: service_catalog
	* @property {Number}  CreatedAtNanos Unixnanos when this catalog entry was created
	* @property {Number}  UpdatedAtNanos Unixnanos when this catalog entry was updated
	* @property {Number}  DeletedAtNanos Unixnanos when this catalog entry was deleted
	* @property {String}  ServiceType    The type of service, ie, Mobility, Fixed, TV, siptrunk....
	*
	*/
	GetServiceCatalogEntryByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Get)
		// Prepare URI params...
		let paramUUID = encodeURIComponent(req.UUID)
		return axios.get(`/v1/service_catalog/${paramUUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveServiceCatalogEntry', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetServiceCatalogEntryByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetServiceCatalogEntryByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetServiceCatalogEntryByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetServiceCatalogEntryByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetServiceCatalogEntryByUUID'))
			})
	},

	/**
	* RPC Method: GetServiceCatalogEntryWithDetailsByUUID
	* Description: Get a service catalog entry
	* HTTP Method: GET
	* API Path: /api/v1/service_catalog/${req.UUID}
	* @param    {ServiceCatalogEntryUUIDSearch} req The API payload object (ServiceCatalogEntryUUIDSearch)
	* @return   {GetServiceCatalogEntryByUUIDResponse} The API response object (GetServiceCatalogEntryByUUIDResponse)
	*
	* @typedef  {Object}  ServiceCatalogEntryUUIDSearch
	* @property {String}  UUID UUID of the service catalog entry
	*
	* @typedef  {Object}  GetServiceCatalogEntryByUUIDResponse
	* @property {String}  UUID            UUID of the service catalog entry
	* @property {ServiceCatalogEntry} CatalogEntry    The actual service catalog entry
	* @property {ServiceCatalogCharacteristic[]} Characteristics The characteristics related to this service catalog entry
	*
	*/
	GetServiceCatalogEntryWithDetailsByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		// Prepare URI params...
		let paramUUID = encodeURIComponent(req.UUID)
		return axios.get(`/v1/service_catalog/${paramUUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetServiceCatalogEntryWithDetailsByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetServiceCatalogEntryWithDetailsByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetServiceCatalogEntryWithDetailsByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetServiceCatalogEntryWithDetailsByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetServiceCatalogEntryWithDetailsByUUID'))
			})
	},

	/**
	* RPC Method: UpdateServiceCatalogCharacteristic
	* Description: Update a service catalog characteristic
	* HTTP Method: PUT
	* API Path: /api/v1/service_catalog_characteristic/${req.UUID}
	* @param    {ServiceCatalogCharacteristic} req The API payload object (ServiceCatalogCharacteristic)
	* @return   {ServiceCatalogCharacteristic} The API response object (ServiceCatalogCharacteristic)
	*
	* @typedef  {Object}  ServiceCatalogCharacteristic
	* @property {String}  UUID                    The unique index for this characteristic
	* @property {String}  ServiceCatalogEntryUUID The service catalog entry that this characteristic belongs to
	* @property {Number}  CreatedAtNanos          Unixnano when this characteristic was created
	* @property {Number}  DeletedAtNanos          Unixnano when this characteristic was deleted
	* @property {String}  ReplacesUUID            If this characteristic has been updated, the previous one is linked here
	* @property {String}  Name                    Name of this characteristic
	* @property {String}  Value                   Value of this characteristic
	* @property {String}  DefaultValue            Default value of this characteristic
	* @property {Boolean} Required                Is this characteristic required (ie, no default)
	* @property {String}  ValueType               The type of this characteristic, string, int, imsi, ipv4_address, ipv6_address, etc
	* @property {String}  ValidationRules         The validation rules for this type
	* @property {Boolean} CanAdd                  Can the customer specify this characteristic (read-only?)
	* @property {Boolean} CanUpdate               Can this characteristic be updated after creation
	*
	*/
	UpdateServiceCatalogCharacteristic({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Update)
		return axios.put(`/v1/service_catalog_characteristic/${req.UUID}`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveServiceCatalogCharacteristic', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('UpdateServiceCatalogCharacteristic ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('UpdateServiceCatalogCharacteristic ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('UpdateServiceCatalogCharacteristic ERROR:', error)
					return Promise.reject(error)
				}
				console.log('UpdateServiceCatalogCharacteristic UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in UpdateServiceCatalogCharacteristic'))
			})
	},

	/**
	* RPC Method: UpdateServiceCatalogEntry
	* Description: Update a service catalog entry
	* HTTP Method: PUT
	* API Path: /api/v1/service_catalog/${req.UUID}
	* @param    {ServiceCatalogEntry} req The API payload object (ServiceCatalogEntry)
	* @return   {ServiceCatalogEntry} The API response object (ServiceCatalogEntry)
	*
	* @typedef  {Object}  ServiceCatalogEntry
	* @property {String}  UUID           Unique ID for this service catalog entry
	* @property {String}  Name           CRUD: service_catalog
	* @property {Number}  CreatedAtNanos Unixnanos when this catalog entry was created
	* @property {Number}  UpdatedAtNanos Unixnanos when this catalog entry was updated
	* @property {Number}  DeletedAtNanos Unixnanos when this catalog entry was deleted
	* @property {String}  ServiceType    The type of service, ie, Mobility, Fixed, TV, siptrunk....
	*
	*/
	UpdateServiceCatalogEntry({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Update)
		return axios.put(`/v1/service_catalog/${req.UUID}`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveServiceCatalogEntry', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('UpdateServiceCatalogEntry ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('UpdateServiceCatalogEntry ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('UpdateServiceCatalogEntry ERROR:', error)
					return Promise.reject(error)
				}
				console.log('UpdateServiceCatalogEntry UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in UpdateServiceCatalogEntry'))
			})
	},
};

export default {
	namespaced: true,
	state: initialState,
	getters,
	mutations,
	actions,
};