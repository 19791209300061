// Code generated by protoc-gen-iqwebapi. DO NOT EDIT.
// source: bulkprequal.proto

import axios from 'axios';
import StoreHash from '@/utils/StoreHash';

const initialState = {
	BulkPreQualJobs: {
		UUIDs: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
	BulkPreQualJobSearches: {
		SearchTexts: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
	PreQuals: {
		UUIDs: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
	PreQualSearches: {
		SearchTexts: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
};

const valFuncFind = function (status) {
	return (status == 200) ? true : (status == 404) ? true : false;
}

const default_pageinfo = {
	PageNumber: 0,
	PageItemCount: 0,
	TotalPages: 0,
	TotalItemCount: 0,
	IndexStart: 0,
	PageSize: 10,
	DisplayPageList: [],
	DisplayPageEitherSide: 4,
}

const getters = {
	getBulkPreQualJobByUUID: state => (UUID) => (state.BulkPreQualJobs.UUIDs[ UUID ] && !state.BulkPreQualJobs.UUIDs[ UUID ].expired()) ?  state.BulkPreQualJobs.UUIDs[ UUID ].hashedData() : null,
	getBulkPreQualJobsForSearchText: state => (SearchText) => state.BulkPreQualJobSearches[ SearchText ] ? state.BulkPreQualJobSearches[ SearchText ] : null,
	getPreQualByUUID: state => (UUID) => (state.PreQuals.UUIDs[ UUID ] && !state.PreQuals.UUIDs[ UUID ].expired()) ?  state.PreQuals.UUIDs[ UUID ].hashedData() : null,
	getPreQualsForSearchText: state => (SearchText) => state.PreQualSearches[ SearchText ] ? state.PreQualSearches[ SearchText ] : null,
};

const mutations = {
	// mutSaveBulkPreQualJob saves a single BulkPreQualJob object
	// into both the StoreHash cache and the VueX store
	mutSaveBulkPreQualJob(state, obj) {
		if (!obj.UUID) { return } // Must have UUID to Save

		if (!(obj.UUID in state.BulkPreQualJobs.UUIDs)) {
			state.BulkPreQualJobs.UUIDs[ obj.UUID ] = new StoreHash(state.BulkPreQualJobs.lifetime)
		}
		state.BulkPreQualJobs.UUIDs[ obj.UUID ].fix(obj) // Add item to StoreHash
	},
	// mutRemoveBulkPreQualJobByUUID removes a BulkPreQualJob object
	// with the given UUID from StoreHash cache and VueX store
	mutRemoveBulkPreQualJobByUUID(state, UUID) {
		if (!UUID) {
			// Must have UUID to Remove
			return
		}
		delete state.BulkPreQualJobs.UUIDs[ UUID ]    // remove item from StoreHash
	},
	// mutSaveBulkPreQualJobSearch saves a search response
	// with the given SearchText to the StoreHash cache and VueX store
	mutSaveBulkPreQualJobSearch(state, { SearchText, Response }) {
		if (!SearchText || !Response) {
			// Must have SearchText to Save and Response object
			return
		}
		let storedObj = {
			PageInfo: Object.assign({}, default_pageinfo), // shallow copy of default_pageinfo
			UUIDs: [],
		}
		// Deep copy ??? i.e. JSON.parse(JSON.stringify(obj));
		if (Response.PageInfo) {
			Response.PageInfo = Object.assign({}, Response.PageInfo) // shallow copy of the PageInfo
		}
		if (Array.isArray(Response.BulkPreQualJobs)) {
			for (let i = 0; i < Response.BulkPreQualJobs.length; i++) {
				storedObj.UUIDs.push(Response.BulkPreQualJobs[i].UUID)
			}
		}
		state.BulkPreQualJobSearches[ SearchText ] = storedObj      // Add search text and list of UUIDs to VueX state

		if (!(SearchText in state.BulkPreQualJobSearches.SearchTexts)) {
			state.BulkPreQualJobSearches.SearchTexts[ SearchText ] = new StoreHash(state.BulkPreQualJobSearches.lifetime)
		}
		state.BulkPreQualJobSearches.SearchTexts[ SearchText ].fix() // Add item to StoreHash
	},
	// mutRemoveBulkPreQualJobSearch removes a search response
	// with the given SearchText from StoreHash cache and VueX store
	mutRemoveBulkPreQualJobSearch(state, SearchText) {
		if (!SearchText) {
			// Must have SearchText to Remove
			return
		}
		state.BulkPreQualJobSearches[ SearchText ] = null               // cause VueX to trigger a data cascade
		delete state.BulkPreQualJobSearches[ SearchText ]               // remove item from VueX state
		delete state.BulkPreQualJobSearches.SearchTexts[ SearchText ]  // remove item from StoreHash
	},
	// mutSaveBulkPreQualJobs saves a paginated response of BulkPreQualJobs
	mutSaveBulkPreQualJobs(state, { Response }) {
		if (!Response || !Array.isArray(Response.BulkPreQualJobs)) {
			// Must have Response object with results
			return
		}
		for (let i = 0; i < Response.BulkPreQualJobs.length; i++) {
			if (!(Response.BulkPreQualJobs[i].UUID in state.BulkPreQualJobs.UUIDs)) {
				state.BulkPreQualJobs.UUIDs[ Response.BulkPreQualJobs[i].UUID ] = new StoreHash(state.BulkPreQualJobs.lifetime)
			}
			state.BulkPreQualJobs.UUIDs[ Response.BulkPreQualJobs[i].UUID ].fix(Response.BulkPreQualJobs[i])  // Add single BulkPreQualJob to StoreHash
		}
	},
	// mutSavePreQual saves a single PreQual object
	// into both the StoreHash cache and the VueX store
	mutSavePreQual(state, obj) {
		if (!obj.UUID) { return } // Must have UUID to Save

		if (!(obj.UUID in state.PreQuals.UUIDs)) {
			state.PreQuals.UUIDs[ obj.UUID ] = new StoreHash(state.PreQuals.lifetime)
		}
		state.PreQuals.UUIDs[ obj.UUID ].fix(obj) // Add item to StoreHash
	},
	// mutRemovePreQualByUUID removes a PreQual object
	// with the given UUID from StoreHash cache and VueX store
	mutRemovePreQualByUUID(state, UUID) {
		if (!UUID) {
			// Must have UUID to Remove
			return
		}
		delete state.PreQuals.UUIDs[ UUID ]    // remove item from StoreHash
	},
	// mutSavePreQualSearch saves a search response
	// with the given SearchText to the StoreHash cache and VueX store
	mutSavePreQualSearch(state, { SearchText, Response }) {
		if (!SearchText || !Response) {
			// Must have SearchText to Save and Response object
			return
		}
		let storedObj = {
			PageInfo: Object.assign({}, default_pageinfo), // shallow copy of default_pageinfo
			UUIDs: [],
		}
		// Deep copy ??? i.e. JSON.parse(JSON.stringify(obj));
		if (Response.PageInfo) {
			Response.PageInfo = Object.assign({}, Response.PageInfo) // shallow copy of the PageInfo
		}
		if (Array.isArray(Response.PreQuals)) {
			for (let i = 0; i < Response.PreQuals.length; i++) {
				storedObj.UUIDs.push(Response.PreQuals[i].UUID)
			}
		}
		state.PreQualSearches[ SearchText ] = storedObj      // Add search text and list of UUIDs to VueX state

		if (!(SearchText in state.PreQualSearches.SearchTexts)) {
			state.PreQualSearches.SearchTexts[ SearchText ] = new StoreHash(state.PreQualSearches.lifetime)
		}
		state.PreQualSearches.SearchTexts[ SearchText ].fix() // Add item to StoreHash
	},
	// mutRemovePreQualSearch removes a search response
	// with the given SearchText from StoreHash cache and VueX store
	mutRemovePreQualSearch(state, SearchText) {
		if (!SearchText) {
			// Must have SearchText to Remove
			return
		}
		state.PreQualSearches[ SearchText ] = null               // cause VueX to trigger a data cascade
		delete state.PreQualSearches[ SearchText ]               // remove item from VueX state
		delete state.PreQualSearches.SearchTexts[ SearchText ]  // remove item from StoreHash
	},
	// mutSavePreQuals saves a paginated response of PreQuals
	mutSavePreQuals(state, { Response }) {
		if (!Response || !Array.isArray(Response.PreQuals)) {
			// Must have Response object with results
			return
		}
		for (let i = 0; i < Response.PreQuals.length; i++) {
			if (!(Response.PreQuals[i].UUID in state.PreQuals.UUIDs)) {
				state.PreQuals.UUIDs[ Response.PreQuals[i].UUID ] = new StoreHash(state.PreQuals.lifetime)
			}
			state.PreQuals.UUIDs[ Response.PreQuals[i].UUID ].fix(Response.PreQuals[i])  // Add single PreQual to StoreHash
		}
	},
};

const actions = {
	// getCachedBulkPreQualJobByUUID fetches a BulkPreQualJob from the cache
	// and if not in the cache, fetches it from the API
	getCachedBulkPreQualJobByUUID({ state, dispatch, commit }, { UUID, RefreshCache }) {
		if (!RefreshCache && state.BulkPreQualJobs.UUIDs[ UUID ] && !state.BulkPreQualJobs.UUIDs[ UUID ].expired()) {
			// Return the cached record
			return state.BulkPreQualJobs.UUIDs[ UUID ].hashedData()
		}
		// Fetch from the API
		return dispatch('GetBulkPreQualJobByUUID', { UUID }).then(respPayload => {
			commit('mutSaveBulkPreQualJob', respPayload)
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveBulkPreQualJobByUUID', UUID)
			return Promise.reject(error)
		})
	},

	// getCachedBulkPreQualJobsBySearch fetches BulkPreQualJobs from the cache
	// and if not in the cache, fetches it from the API
	getCachedBulkPreQualJobsBySearch({ state, getters, dispatch, commit }, { SPID, SearchText, RefreshCache }) {
		if (!RefreshCache && state.BulkPreQualJobSearches.SearchTexts[ SearchText ] && !state.BulkPreQualJobSearches.SearchTexts[ SearchText ].expired()) {
			// Return the cached records
			let resp = getters.getBulkPreQualJobsForSearchText(SearchText)
			resp.BulkPreQualJobs = []
			for (let i = 0; i < resp.UUIDs.length; i++) {
				resp.BulkPreQualJobs.push(getters.getBulkPreQualJobByUUID(resp.UUIDs[i]))
			}
			return Promise.resolve(resp)
		}
		// Search using the API
		const apiReq = {
			SPID: SPID,
			SmartSearch: SearchText,
		}
		return dispatch('GetBulkPreQualJobsPaginated', apiReq).then(respPayload => {
			commit('mutSaveBulkPreQualJobSearch', { SearchText: SearchText, Response: respPayload })
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveBulkPreQualJobSearch', SearchText)
			return Promise.reject(error)
		})
	},

	// getCachedPreQualByUUID fetches a PreQual from the cache
	// and if not in the cache, fetches it from the API
	getCachedPreQualByUUID({ state, dispatch, commit }, { UUID, RefreshCache }) {
		if (!RefreshCache && state.PreQuals.UUIDs[ UUID ] && !state.PreQuals.UUIDs[ UUID ].expired()) {
			// Return the cached record
			return state.PreQuals.UUIDs[ UUID ].hashedData()
		}
		// Fetch from the API
		return dispatch('GetPreQualByUUID', { UUID }).then(respPayload => {
			commit('mutSavePreQual', respPayload)
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemovePreQualByUUID', UUID)
			return Promise.reject(error)
		})
	},

	// getCachedPreQualsBySearch fetches PreQuals from the cache
	// and if not in the cache, fetches it from the API
	getCachedPreQualsBySearch({ state, getters, dispatch, commit }, { SPID, SearchText, RefreshCache }) {
		if (!RefreshCache && state.PreQualSearches.SearchTexts[ SearchText ] && !state.PreQualSearches.SearchTexts[ SearchText ].expired()) {
			// Return the cached records
			let resp = getters.getPreQualsForSearchText(SearchText)
			resp.PreQuals = []
			for (let i = 0; i < resp.UUIDs.length; i++) {
				resp.PreQuals.push(getters.getPreQualByUUID(resp.UUIDs[i]))
			}
			return Promise.resolve(resp)
		}
		// Search using the API
		const apiReq = {
			SPID: SPID,
			SmartSearch: SearchText,
		}
		return dispatch('GetPreQualsPaginated', apiReq).then(respPayload => {
			commit('mutSavePreQualSearch', { SearchText: SearchText, Response: respPayload })
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemovePreQualSearch', SearchText)
			return Promise.reject(error)
		})
	},

	//
	// Service: BulkPreQualRPC
	// 
	/**
	* RPC Method: AddBulkPreQualJob
	* Description: Add a BulkPreQual job
	* HTTP Method: POST
	* API Path: /api/v3/bulk_prequal
	* @param    {BulkPreQualJob} req The API payload object (BulkPreQualJob)
	* @return   {BulkPreQualJob} The API response object (BulkPreQualJob)
	*
	* @typedef  {Object}  BulkPreQualJob
	* @property {String}  UUID                
	* @property {Number}  SPID                Service Provider ID
	* @property {Number}  CreatedAtNanos      When the object was first created
	* @property {String}  CreatedBy           Who the object was created by
	* @property {Number}  UpdatedAtNanos      When the object was last updated
	* @property {String}  UpdatedBy           Who the object was updated by
	* @property {Number}  DeletedAtNanos      When the object was deleted
	* @property {String}  DeletedBy           Who deleted the object
	* @property {String}  Description         Description for this Bulk Prequal
	* @property {StringSlice} SearchTexts         Textual Addresses to look for (or lookup AddressIDs if no spaces or commas in the text)
	* @property {StringSlice} SearchServices      List of service types to search for (UFB|DSL|FWA)
	* @property {Number}  CountTotal          Count of searchtexts
	* @property {Number}  CountFoundAddress   Count of addresses found
	* @property {Number}  CountMatchedAddress Count of addresses matched
	* @property {Number}  CountPrequalDone    Count of prequals done
	* @property {Number}  CountHaveService    Count of matched addresses with service
	*
	*/
	AddBulkPreQualJob({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Add)
		return axios.post(`/v3/bulk_prequal`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('AddBulkPreQualJob ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('AddBulkPreQualJob ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('AddBulkPreQualJob ERROR:', error)
					return Promise.reject(error)
				}
				console.log('AddBulkPreQualJob UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in AddBulkPreQualJob'))
			})
	},

	/**
	* RPC Method: AddPreQual
	* Description: Add a PreQual
	* HTTP Method: POST
	* API Path: /api/v3/prequal
	* @param    {PreQual} req The API payload object (PreQual)
	* @return   {PreQual} The API response object (PreQual)
	*
	* @typedef  {Object}  PreQual
	* @property {String}  UUID            
	* @property {Number}  SPID            Service Provider ID
	* @property {String}  BulkPreQualUUID BulkPreQualUUID
	* @property {String}  CustomerUUID    
	* @property {Number}  CreatedAtNanos  When the object was first created
	* @property {String}  CreatedBy       Who the object was created by
	* @property {Number}  UpdatedAtNanos  When the object was last updated
	* @property {String}  UpdatedBy       Who the object was updated by
	* @property {Number}  DeletedAtNanos  When the object was deleted
	* @property {String}  DeletedBy       Who the object was deleted by
	* @property {String}  SearchText      Textual Addresses to look for (or lookup AddressIDs if no spaces or commas in the text)
	* @property {StringSlice} SearchServices  List of service types to search for (UFB|DSL|FWA)
	* @property {StringSlice} LocationsFlat   Candidate Locations, in the format (LocationProvider|LocationProviderIDX|AddressText)
	* @property {IQLocation[]} Locations       List of structured address entities
	* @property {Service[]} Services        (not saved)
	* @property {StringSlice} ServicesFlat    Services in the format (LocationProvider|LocationProviderID|ServiceType)
	*
	*/
	AddPreQual({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Add)
		return axios.post(`/v3/prequal`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('AddPreQual ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('AddPreQual ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('AddPreQual ERROR:', error)
					return Promise.reject(error)
				}
				console.log('AddPreQual UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in AddPreQual'))
			})
	},

	/**
	* RPC Method: DeleteBulkPreQualJobByUUID
	* Description: Deletes a BulkPreQualJob object. Requires JWT
	* HTTP Method: DELETE
	* API Path: /api/v3/bulk_prequal/${req.UUID}
	* @param    {BulkPreQualJobUUIDSearch} req The API payload object (BulkPreQualJobUUIDSearch)
	* @return   {BulkPreQualJob} The API response object (BulkPreQualJob)
	*
	* @typedef  {Object}  BulkPreQualJobUUIDSearch
	* @property {String}  UUID        
	* @property {Number}  SPID        Service Provider ID
	* @property {String}  Description (optional) Description
	*
	* @typedef  {Object}  BulkPreQualJob
	* @property {String}  UUID                
	* @property {Number}  SPID                Service Provider ID
	* @property {Number}  CreatedAtNanos      When the object was first created
	* @property {String}  CreatedBy           Who the object was created by
	* @property {Number}  UpdatedAtNanos      When the object was last updated
	* @property {String}  UpdatedBy           Who the object was updated by
	* @property {Number}  DeletedAtNanos      When the object was deleted
	* @property {String}  DeletedBy           Who deleted the object
	* @property {String}  Description         Description for this Bulk Prequal
	* @property {StringSlice} SearchTexts         Textual Addresses to look for (or lookup AddressIDs if no spaces or commas in the text)
	* @property {StringSlice} SearchServices      List of service types to search for (UFB|DSL|FWA)
	* @property {Number}  CountTotal          Count of searchtexts
	* @property {Number}  CountFoundAddress   Count of addresses found
	* @property {Number}  CountMatchedAddress Count of addresses matched
	* @property {Number}  CountPrequalDone    Count of prequals done
	* @property {Number}  CountHaveService    Count of matched addresses with service
	*
	*/
	DeleteBulkPreQualJobByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Delete)
		return axios.delete(`/v3/bulk_prequal/${req.UUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveBulkPreQualJob', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('DeleteBulkPreQualJobByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('DeleteBulkPreQualJobByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('DeleteBulkPreQualJobByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('DeleteBulkPreQualJobByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in DeleteBulkPreQualJobByUUID'))
			})
	},

	/**
	* RPC Method: DeletePreQualByUUID
	* Description: Deletes a PreQual object. Requires JWT
	* HTTP Method: DELETE
	* API Path: /api/v3/prequal/${req.UUID}
	* @param    {PreQualUUIDSearch} req The API payload object (PreQualUUIDSearch)
	* @return   {PreQual} The API response object (PreQual)
	*
	* @typedef  {Object}  PreQualUUIDSearch
	* @property {String}  UUID        
	* @property {Number}  SPID        Service Provider ID
	* @property {String}  Description (optional) Description
	*
	* @typedef  {Object}  PreQual
	* @property {String}  UUID            
	* @property {Number}  SPID            Service Provider ID
	* @property {String}  BulkPreQualUUID BulkPreQualUUID
	* @property {String}  CustomerUUID    
	* @property {Number}  CreatedAtNanos  When the object was first created
	* @property {String}  CreatedBy       Who the object was created by
	* @property {Number}  UpdatedAtNanos  When the object was last updated
	* @property {String}  UpdatedBy       Who the object was updated by
	* @property {Number}  DeletedAtNanos  When the object was deleted
	* @property {String}  DeletedBy       Who the object was deleted by
	* @property {String}  SearchText      Textual Addresses to look for (or lookup AddressIDs if no spaces or commas in the text)
	* @property {StringSlice} SearchServices  List of service types to search for (UFB|DSL|FWA)
	* @property {StringSlice} LocationsFlat   Candidate Locations, in the format (LocationProvider|LocationProviderIDX|AddressText)
	* @property {IQLocation[]} Locations       List of structured address entities
	* @property {Service[]} Services        (not saved)
	* @property {StringSlice} ServicesFlat    Services in the format (LocationProvider|LocationProviderID|ServiceType)
	*
	*/
	DeletePreQualByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Delete)
		return axios.delete(`/v3/prequal/${req.UUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSavePreQual', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('DeletePreQualByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('DeletePreQualByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('DeletePreQualByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('DeletePreQualByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in DeletePreQualByUUID'))
			})
	},

	/**
	* RPC Method: GetBulkPreQualJobByUUID
	* Description: Get a single BulkPreQualJob object using the UUID
	* HTTP Method: GET
	* API Path: /api/v3/bulk_prequal/${req.UUID}
	* @param    {BulkPreQualJobUUIDSearch} req The API payload object (BulkPreQualJobUUIDSearch)
	* @return   {BulkPreQualJob} The API response object (BulkPreQualJob)
	*
	* @typedef  {Object}  BulkPreQualJobUUIDSearch
	* @property {String}  UUID        
	* @property {Number}  SPID        Service Provider ID
	* @property {String}  Description (optional) Description
	*
	* @typedef  {Object}  BulkPreQualJob
	* @property {String}  UUID                
	* @property {Number}  SPID                Service Provider ID
	* @property {Number}  CreatedAtNanos      When the object was first created
	* @property {String}  CreatedBy           Who the object was created by
	* @property {Number}  UpdatedAtNanos      When the object was last updated
	* @property {String}  UpdatedBy           Who the object was updated by
	* @property {Number}  DeletedAtNanos      When the object was deleted
	* @property {String}  DeletedBy           Who deleted the object
	* @property {String}  Description         Description for this Bulk Prequal
	* @property {StringSlice} SearchTexts         Textual Addresses to look for (or lookup AddressIDs if no spaces or commas in the text)
	* @property {StringSlice} SearchServices      List of service types to search for (UFB|DSL|FWA)
	* @property {Number}  CountTotal          Count of searchtexts
	* @property {Number}  CountFoundAddress   Count of addresses found
	* @property {Number}  CountMatchedAddress Count of addresses matched
	* @property {Number}  CountPrequalDone    Count of prequals done
	* @property {Number}  CountHaveService    Count of matched addresses with service
	*
	*/
	GetBulkPreQualJobByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Get)
		return axios.get(`/v3/bulk_prequal/${req.UUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveBulkPreQualJob', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetBulkPreQualJobByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetBulkPreQualJobByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetBulkPreQualJobByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetBulkPreQualJobByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetBulkPreQualJobByUUID'))
			})
	},

	/**
	* RPC Method: GetBulkPreQualJobsPaginated
	* Description: Searches for multiple BulkPreQualJob objects using a search request
	* HTTP Method: GET
	* API Path: /api/v3/bulk_prequal
	* @param    {BulkPreQualJobSearchRequest} req The API payload object (BulkPreQualJobSearchRequest)
	* @return   {BulkPreQualJobPageResponse} The API response object (BulkPreQualJobPageResponse)
	*
	* @typedef  {Object}  BulkPreQualJobSearchRequest
	* @property {SearchOptions[]} SearchOptions      Search options to use (for sorting and/or pagination)
	* @property {Boolean} IncludeDeleted     
	* @property {Number}  SPID               (optional) SPID
	* @property {String}  Description        (optional) Description
	* @property {Boolean} IncludeSearchTexts 
	*
	* @typedef  {Object}  SearchOptions  Search options
	* @property {Number}  PageNumber     The page number to start at (or provide IndexStart)
	* @property {Number}  PageSize       The page size
	* @property {Number}  IndexStart     The record number to start at (or provide PageNumber)
	* @property {String}  SortBy         The field to sort by
	* @property {Boolean} SortDesc       Is the sort direction descending?
	*
	* @typedef  {Object}  BulkPreQualJobPageResponse
	* @property {PageInfo} PageInfo        PageInfo only needs the values ThisPageNumber && PageSize set
	* @property {BulkPreQualJob[]} BulkPreQualJobs 
	*
	* @typedef  {Object}   PageInfo
	* @property {Number}   PageNumber             The page number
	* @property {Number}   PageItemCount          Number of items on this page
	* @property {Number}   TotalPages             Total pages available
	* @property {Number}   TotalItemCount         Total items available
	* @property {Number}   IndexStart             The record number this search started at
	* @property {Number}   PageSize               The length of the page
	* @property {Number[]} DisplayPageList        The array of page numbers to display
	* @property {Number}   DisplayPagesEitherSide Number of pages to display either side
	*
	*/
	GetBulkPreQualJobsPaginated({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Find)
		return axios.get(`/v3/bulk_prequal`, { params: req, validateStatus: valFuncFind }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			commit('mutSaveBulkPreQualJobs', { Response: response.data })
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetBulkPreQualJobsPaginated ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetBulkPreQualJobsPaginated ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetBulkPreQualJobsPaginated ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetBulkPreQualJobsPaginated UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetBulkPreQualJobsPaginated'))
			})
	},

	/**
	* RPC Method: GetPreQualByUUID
	* Description: Get a single PreQual object using the UUID
	* HTTP Method: GET
	* API Path: /api/v3/prequal/${req.UUID}
	* @param    {PreQualUUIDSearch} req The API payload object (PreQualUUIDSearch)
	* @return   {PreQual} The API response object (PreQual)
	*
	* @typedef  {Object}  PreQualUUIDSearch
	* @property {String}  UUID        
	* @property {Number}  SPID        Service Provider ID
	* @property {String}  Description (optional) Description
	*
	* @typedef  {Object}  PreQual
	* @property {String}  UUID            
	* @property {Number}  SPID            Service Provider ID
	* @property {String}  BulkPreQualUUID BulkPreQualUUID
	* @property {String}  CustomerUUID    
	* @property {Number}  CreatedAtNanos  When the object was first created
	* @property {String}  CreatedBy       Who the object was created by
	* @property {Number}  UpdatedAtNanos  When the object was last updated
	* @property {String}  UpdatedBy       Who the object was updated by
	* @property {Number}  DeletedAtNanos  When the object was deleted
	* @property {String}  DeletedBy       Who the object was deleted by
	* @property {String}  SearchText      Textual Addresses to look for (or lookup AddressIDs if no spaces or commas in the text)
	* @property {StringSlice} SearchServices  List of service types to search for (UFB|DSL|FWA)
	* @property {StringSlice} LocationsFlat   Candidate Locations, in the format (LocationProvider|LocationProviderIDX|AddressText)
	* @property {IQLocation[]} Locations       List of structured address entities
	* @property {Service[]} Services        (not saved)
	* @property {StringSlice} ServicesFlat    Services in the format (LocationProvider|LocationProviderID|ServiceType)
	*
	*/
	GetPreQualByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Get)
		return axios.get(`/v3/prequal/${req.UUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSavePreQual', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetPreQualByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetPreQualByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetPreQualByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetPreQualByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetPreQualByUUID'))
			})
	},

	/**
	* RPC Method: GetPreQualReport
	* Description: Get PreQual objects using the selected UUIDs
	* HTTP Method: POST
	* API Path: /api/v3/prequal/report
	* @param    {PreQualUUIDsSearch} req The API payload object (PreQualUUIDsSearch)
	* @return   {GetPreQualReportResponse} The API response object (GetPreQualReportResponse)
	*
	* @typedef  {Object}  PreQualUUIDsSearch
	* @property {PreQualUUIDSearch[]} PreQualUUIDSearchs 
	* @property {Number}  SPID               Service Provider ID
	*
	* @typedef  {Object}  GetPreQualReportResponse
	* @property {Object}  CSVData 
	*
	*/
	GetPreQualReport({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.post(`/v3/prequal/report`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetPreQualReport ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetPreQualReport ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetPreQualReport ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetPreQualReport UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetPreQualReport'))
			})
	},

	/**
	* RPC Method: GetPreQualsPaginated
	* Description: Searches for multiple PreQual objects using a search request
	* HTTP Method: GET
	* API Path: /api/v3/prequal
	* @param    {PreQualSearchRequest} req The API payload object (PreQualSearchRequest)
	* @return   {PreQualPageResponse} The API response object (PreQualPageResponse)
	*
	* @typedef  {Object}  PreQualSearchRequest
	* @property {SearchOptions[]} SearchOptions   Search options to use (for sorting and/or pagination)
	* @property {Boolean} IncludeDeleted  
	* @property {Number}  SPID            (optional) SPID
	* @property {String}  CustomerUUID    Filter by CustomerUUID
	* @property {String}  BulkPreQualUUID Filter by BulkPreQualUUID
	*
	* @typedef  {Object}  SearchOptions  Search options
	* @property {Number}  PageNumber     The page number to start at (or provide IndexStart)
	* @property {Number}  PageSize       The page size
	* @property {Number}  IndexStart     The record number to start at (or provide PageNumber)
	* @property {String}  SortBy         The field to sort by
	* @property {Boolean} SortDesc       Is the sort direction descending?
	*
	* @typedef  {Object}  PreQualPageResponse
	* @property {PageInfo} PageInfo PageInfo only needs the values ThisPageNumber && PageSize set
	* @property {PreQual[]} PreQuals 
	*
	* @typedef  {Object}   PageInfo
	* @property {Number}   PageNumber             The page number
	* @property {Number}   PageItemCount          Number of items on this page
	* @property {Number}   TotalPages             Total pages available
	* @property {Number}   TotalItemCount         Total items available
	* @property {Number}   IndexStart             The record number this search started at
	* @property {Number}   PageSize               The length of the page
	* @property {Number[]} DisplayPageList        The array of page numbers to display
	* @property {Number}   DisplayPagesEitherSide Number of pages to display either side
	*
	*/
	GetPreQualsPaginated({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Find)
		return axios.get(`/v3/prequal`, { params: req, validateStatus: valFuncFind }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			commit('mutSavePreQuals', { Response: response.data })
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetPreQualsPaginated ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetPreQualsPaginated ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetPreQualsPaginated ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetPreQualsPaginated UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetPreQualsPaginated'))
			})
	},

	/**
	* RPC Method: PurgeBulkPreQualJobByUUID
	* Description: Purges a BulkPreQualJob object. Requires JWT
	* HTTP Method: DELETE
	* API Path: /api/v3/bulk_prequal/${req.UUID}/purge
	* @param    {BulkPreQualJobUUIDSearch} req The API payload object (BulkPreQualJobUUIDSearch)
	* @return   {BulkPreQualJob} The API response object (BulkPreQualJob)
	*
	* @typedef  {Object}  BulkPreQualJobUUIDSearch
	* @property {String}  UUID        
	* @property {Number}  SPID        Service Provider ID
	* @property {String}  Description (optional) Description
	*
	* @typedef  {Object}  BulkPreQualJob
	* @property {String}  UUID                
	* @property {Number}  SPID                Service Provider ID
	* @property {Number}  CreatedAtNanos      When the object was first created
	* @property {String}  CreatedBy           Who the object was created by
	* @property {Number}  UpdatedAtNanos      When the object was last updated
	* @property {String}  UpdatedBy           Who the object was updated by
	* @property {Number}  DeletedAtNanos      When the object was deleted
	* @property {String}  DeletedBy           Who deleted the object
	* @property {String}  Description         Description for this Bulk Prequal
	* @property {StringSlice} SearchTexts         Textual Addresses to look for (or lookup AddressIDs if no spaces or commas in the text)
	* @property {StringSlice} SearchServices      List of service types to search for (UFB|DSL|FWA)
	* @property {Number}  CountTotal          Count of searchtexts
	* @property {Number}  CountFoundAddress   Count of addresses found
	* @property {Number}  CountMatchedAddress Count of addresses matched
	* @property {Number}  CountPrequalDone    Count of prequals done
	* @property {Number}  CountHaveService    Count of matched addresses with service
	*
	*/
	PurgeBulkPreQualJobByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Purge)
		return axios.delete(`/v3/bulk_prequal/${req.UUID}/purge`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveBulkPreQualJob', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('PurgeBulkPreQualJobByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('PurgeBulkPreQualJobByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('PurgeBulkPreQualJobByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('PurgeBulkPreQualJobByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in PurgeBulkPreQualJobByUUID'))
			})
	},

	/**
	* RPC Method: PurgePreQualByUUID
	* Description: Purges a PreQual object. Requires JWT
	* HTTP Method: DELETE
	* API Path: /api/v3/prequal/${req.UUID}/purge
	* @param    {PreQualUUIDSearch} req The API payload object (PreQualUUIDSearch)
	* @return   {PreQual} The API response object (PreQual)
	*
	* @typedef  {Object}  PreQualUUIDSearch
	* @property {String}  UUID        
	* @property {Number}  SPID        Service Provider ID
	* @property {String}  Description (optional) Description
	*
	* @typedef  {Object}  PreQual
	* @property {String}  UUID            
	* @property {Number}  SPID            Service Provider ID
	* @property {String}  BulkPreQualUUID BulkPreQualUUID
	* @property {String}  CustomerUUID    
	* @property {Number}  CreatedAtNanos  When the object was first created
	* @property {String}  CreatedBy       Who the object was created by
	* @property {Number}  UpdatedAtNanos  When the object was last updated
	* @property {String}  UpdatedBy       Who the object was updated by
	* @property {Number}  DeletedAtNanos  When the object was deleted
	* @property {String}  DeletedBy       Who the object was deleted by
	* @property {String}  SearchText      Textual Addresses to look for (or lookup AddressIDs if no spaces or commas in the text)
	* @property {StringSlice} SearchServices  List of service types to search for (UFB|DSL|FWA)
	* @property {StringSlice} LocationsFlat   Candidate Locations, in the format (LocationProvider|LocationProviderIDX|AddressText)
	* @property {IQLocation[]} Locations       List of structured address entities
	* @property {Service[]} Services        (not saved)
	* @property {StringSlice} ServicesFlat    Services in the format (LocationProvider|LocationProviderID|ServiceType)
	*
	*/
	PurgePreQualByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Purge)
		return axios.delete(`/v3/prequal/${req.UUID}/purge`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSavePreQual', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('PurgePreQualByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('PurgePreQualByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('PurgePreQualByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('PurgePreQualByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in PurgePreQualByUUID'))
			})
	},

	/**
	* RPC Method: UpdateBulkPreQualJob
	* Description: Update a single BulkPreQual job
	* HTTP Method: PUT
	* API Path: /api/v3/bulk_prequal/${req.UUID}
	* @param    {BulkPreQualJob} req The API payload object (BulkPreQualJob)
	* @return   {BulkPreQualJob} The API response object (BulkPreQualJob)
	*
	* @typedef  {Object}  BulkPreQualJob
	* @property {String}  UUID                
	* @property {Number}  SPID                Service Provider ID
	* @property {Number}  CreatedAtNanos      When the object was first created
	* @property {String}  CreatedBy           Who the object was created by
	* @property {Number}  UpdatedAtNanos      When the object was last updated
	* @property {String}  UpdatedBy           Who the object was updated by
	* @property {Number}  DeletedAtNanos      When the object was deleted
	* @property {String}  DeletedBy           Who deleted the object
	* @property {String}  Description         Description for this Bulk Prequal
	* @property {StringSlice} SearchTexts         Textual Addresses to look for (or lookup AddressIDs if no spaces or commas in the text)
	* @property {StringSlice} SearchServices      List of service types to search for (UFB|DSL|FWA)
	* @property {Number}  CountTotal          Count of searchtexts
	* @property {Number}  CountFoundAddress   Count of addresses found
	* @property {Number}  CountMatchedAddress Count of addresses matched
	* @property {Number}  CountPrequalDone    Count of prequals done
	* @property {Number}  CountHaveService    Count of matched addresses with service
	*
	*/
	UpdateBulkPreQualJob({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Update)
		return axios.put(`/v3/bulk_prequal/${req.UUID}`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveBulkPreQualJob', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('UpdateBulkPreQualJob ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('UpdateBulkPreQualJob ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('UpdateBulkPreQualJob ERROR:', error)
					return Promise.reject(error)
				}
				console.log('UpdateBulkPreQualJob UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in UpdateBulkPreQualJob'))
			})
	},

	/**
	* RPC Method: UpdatePreQual
	* Description: Update a single PreQual
	* HTTP Method: PUT
	* API Path: /api/v3/prequal/${req.UUID}
	* @param    {PreQual} req The API payload object (PreQual)
	* @return   {PreQual} The API response object (PreQual)
	*
	* @typedef  {Object}  PreQual
	* @property {String}  UUID            
	* @property {Number}  SPID            Service Provider ID
	* @property {String}  BulkPreQualUUID BulkPreQualUUID
	* @property {String}  CustomerUUID    
	* @property {Number}  CreatedAtNanos  When the object was first created
	* @property {String}  CreatedBy       Who the object was created by
	* @property {Number}  UpdatedAtNanos  When the object was last updated
	* @property {String}  UpdatedBy       Who the object was updated by
	* @property {Number}  DeletedAtNanos  When the object was deleted
	* @property {String}  DeletedBy       Who the object was deleted by
	* @property {String}  SearchText      Textual Addresses to look for (or lookup AddressIDs if no spaces or commas in the text)
	* @property {StringSlice} SearchServices  List of service types to search for (UFB|DSL|FWA)
	* @property {StringSlice} LocationsFlat   Candidate Locations, in the format (LocationProvider|LocationProviderIDX|AddressText)
	* @property {IQLocation[]} Locations       List of structured address entities
	* @property {Service[]} Services        (not saved)
	* @property {StringSlice} ServicesFlat    Services in the format (LocationProvider|LocationProviderID|ServiceType)
	*
	*/
	UpdatePreQual({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Update)
		return axios.put(`/v3/prequal/${req.UUID}`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSavePreQual', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('UpdatePreQual ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('UpdatePreQual ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('UpdatePreQual ERROR:', error)
					return Promise.reject(error)
				}
				console.log('UpdatePreQual UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in UpdatePreQual'))
			})
	},
};

export default {
	namespaced: true,
	state: initialState,
	getters,
	mutations,
	actions,
};