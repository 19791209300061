// Code generated by protoc-gen-iqwebapi. DO NOT EDIT.
// source: nps.proto

import axios from 'axios';
import StoreHash from '@/utils/StoreHash';

const initialState = {
	NPSResponses: {
		UUIDs: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
	NPSSurveys: {
		UUIDs: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
};

const valFuncFind = function (status) {
	return (status == 200) ? true : (status == 404) ? true : false;
}

const default_pageinfo = {
	PageNumber: 0,
	PageItemCount: 0,
	TotalPages: 0,
	TotalItemCount: 0,
	IndexStart: 0,
	PageSize: 10,
	DisplayPageList: [],
	DisplayPageEitherSide: 4,
}

const getters = {
	getNPSResponseByUUID: state => (UUID) => (state.NPSResponses.UUIDs[ UUID ] && !state.NPSResponses.UUIDs[ UUID ].expired()) ?  state.NPSResponses.UUIDs[ UUID ].hashedData() : null,
	getNPSSurveyByUUID: state => (UUID) => (state.NPSSurveys.UUIDs[ UUID ] && !state.NPSSurveys.UUIDs[ UUID ].expired()) ?  state.NPSSurveys.UUIDs[ UUID ].hashedData() : null,
};

const mutations = {
	// mutSaveNPSResponse saves a single NPSResponse object
	// into both the StoreHash cache and the VueX store
	mutSaveNPSResponse(state, obj) {
		if (!obj.UUID) { return } // Must have UUID to Save

		if (!(obj.UUID in state.NPSResponses.UUIDs)) {
			state.NPSResponses.UUIDs[ obj.UUID ] = new StoreHash(state.NPSResponses.lifetime)
		}
		state.NPSResponses.UUIDs[ obj.UUID ].fix(obj) // Add item to StoreHash
	},
	// mutRemoveNPSResponseByUUID removes a NPSResponse object
	// with the given UUID from StoreHash cache and VueX store
	mutRemoveNPSResponseByUUID(state, UUID) {
		if (!UUID) {
			// Must have UUID to Remove
			return
		}
		delete state.NPSResponses.UUIDs[ UUID ]    // remove item from StoreHash
	},
	// mutSaveNPSResponses saves a paginated response of NPSResponses
	mutSaveNPSResponses(state, { Response }) {
		if (!Response || !Array.isArray(Response.NPSResponses)) {
			// Must have Response object with results
			return
		}
		for (let i = 0; i < Response.NPSResponses.length; i++) {
			if (!(Response.NPSResponses[i].UUID in state.NPSResponses.UUIDs)) {
				state.NPSResponses.UUIDs[ Response.NPSResponses[i].UUID ] = new StoreHash(state.NPSResponses.lifetime)
			}
			state.NPSResponses.UUIDs[ Response.NPSResponses[i].UUID ].fix(Response.NPSResponses[i])  // Add single NPSResponse to StoreHash
		}
	},
	// mutSaveNPSSurvey saves a single NPSSurvey object
	// into both the StoreHash cache and the VueX store
	mutSaveNPSSurvey(state, obj) {
		if (!obj.UUID) { return } // Must have UUID to Save

		if (!(obj.UUID in state.NPSSurveys.UUIDs)) {
			state.NPSSurveys.UUIDs[ obj.UUID ] = new StoreHash(state.NPSSurveys.lifetime)
		}
		state.NPSSurveys.UUIDs[ obj.UUID ].fix(obj) // Add item to StoreHash
	},
	// mutRemoveNPSSurveyByUUID removes a NPSSurvey object
	// with the given UUID from StoreHash cache and VueX store
	mutRemoveNPSSurveyByUUID(state, UUID) {
		if (!UUID) {
			// Must have UUID to Remove
			return
		}
		delete state.NPSSurveys.UUIDs[ UUID ]    // remove item from StoreHash
	},
	// mutSaveNPSSurveys saves a paginated response of NPSSurveys
	mutSaveNPSSurveys(state, { Response }) {
		if (!Response || !Array.isArray(Response.NPSSurveys)) {
			// Must have Response object with results
			return
		}
		for (let i = 0; i < Response.NPSSurveys.length; i++) {
			if (!(Response.NPSSurveys[i].UUID in state.NPSSurveys.UUIDs)) {
				state.NPSSurveys.UUIDs[ Response.NPSSurveys[i].UUID ] = new StoreHash(state.NPSSurveys.lifetime)
			}
			state.NPSSurveys.UUIDs[ Response.NPSSurveys[i].UUID ].fix(Response.NPSSurveys[i])  // Add single NPSSurvey to StoreHash
		}
	},
};

const actions = {
	// getCachedNPSResponseByUUID fetches a NPSResponse from the cache
	// and if not in the cache, fetches it from the API
	getCachedNPSResponseByUUID({ state, dispatch, commit }, { UUID, RefreshCache }) {
		if (!RefreshCache && state.NPSResponses.UUIDs[ UUID ] && !state.NPSResponses.UUIDs[ UUID ].expired()) {
			// Return the cached record
			return state.NPSResponses.UUIDs[ UUID ].hashedData()
		}
		// Fetch from the API
		return dispatch('GetNPSResponseByUUID', { UUID }).then(respPayload => {
			commit('mutSaveNPSResponse', respPayload)
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveNPSResponseByUUID', UUID)
			return Promise.reject(error)
		})
	},

	// getCachedNPSSurveyByUUID fetches a NPSSurvey from the cache
	// and if not in the cache, fetches it from the API
	getCachedNPSSurveyByUUID({ state, dispatch, commit }, { UUID, RefreshCache }) {
		if (!RefreshCache && state.NPSSurveys.UUIDs[ UUID ] && !state.NPSSurveys.UUIDs[ UUID ].expired()) {
			// Return the cached record
			return state.NPSSurveys.UUIDs[ UUID ].hashedData()
		}
		// Fetch from the API
		return dispatch('GetNPSSurveyByUUID', { UUID }).then(respPayload => {
			commit('mutSaveNPSSurvey', respPayload)
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveNPSSurveyByUUID', UUID)
			return Promise.reject(error)
		})
	},

	//
	// Service: NPSRPC
	// 
	/**
	* AddNPSResponse: 
	* HTTP Method: POST
	* API Path: /api/v2/${req.SPID}/nps_response
	* @param    {NPSResponse} req The API payload object (NPSResponse)
	* @return   {NPSResponse} The API response object (NPSResponse)
	*
	* @typedef  {Object}  NPSResponse
	* @property {String}  UUID                 
	* @property {String}  NPSSurveyUUID        
	* @property {Number}  SPID                 
	* @property {String}  CustomerUUID         
	* @property {String}  Email                
	* @property {String}  MobileNumber         
	* @property {String}  SurveyType           
	* @property {Number}  EffortScore          
	* @property {Number}  ProductServicesScore 
	* @property {Number}  RecommendScore       
	* @property {String}  ExperienceComment    
	* @property {String}  ProductGroup         
	* @property {Number}  CreatedAtNanos       
	* @property {String}  Category1            
	* @property {String}  Category2            
	* @property {String}  Stack                
	* @property {String}  StackDetail          
	* @property {String}  Segment              
	* @property {String}  PlanName             
	* @property {String}  RegionName           
	* @property {String}  DeviceName           
	* @property {ENUM}    DeviceType           
	* @property {String}  HouseholdUUID        
	* @property {String}  Journey              
	* @property {Number}  DeletedAtNanos       
	*
	*/
	AddNPSResponse({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Add)
		return axios.post(`/v2/${req.SPID}/nps_response`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('AddNPSResponse ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('AddNPSResponse ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('AddNPSResponse ERROR:', error)
					return Promise.reject(error)
				}
				console.log('AddNPSResponse UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in AddNPSResponse'))
			})
	},

	/**
	* AddNPSSurvey: 
	* HTTP Method: POST
	* API Path: /api/v2/${req.SPID}/nps_survey
	* @param    {NPSSurvey} req The API payload object (NPSSurvey)
	* @return   {NPSSurvey} The API response object (NPSSurvey)
	*
	* @typedef  {Object}  NPSSurvey
	* @property {String}  UUID                      
	* @property {Number}  SPID                      
	* @property {Boolean} Active                    
	* @property {Number}  CreatedAtNanos            
	* @property {Number}  UpdatedAtNanos            
	* @property {Number}  DeletedAtNanos            
	* @property {String}  ProductGroup              
	* @property {String}  SurveyType                
	* @property {String}  QuestionEffort            
	* @property {String}  QuestionProductServices   
	* @property {String}  QuestionRecommend         
	* @property {String}  QuestionExperienceComment 
	* @property {Number}  ValidFromNanos            
	* @property {Number}  ValidUntilNanos           
	* @property {String}  Title                     
	* @property {String}  Description               
	* @property {Boolean} CommentEnabled            
	* @property {String}  SurveyResponse            
	* @property {Number}  OrderEffort               repeated NPSQuestion Questions = 19 [ json_name = "Questions", (tagger.tags) = "json:\"Questions\" db:\"-\" gorm:\"-\" bson:\"Questions\" csv:\"Questions\" query:\"Questions\" xml:\"Questions\" dynamodb:\"Questions\" datastore:\"Questions\" bigquery:\"Questions\" spanner:\"Questions\" mapstructure:\"Questions\" graphql:\"Questions\" firestore:\"Questions\""];
	* @property {Number}  OrderProductServices      
	* @property {Number}  OrderRecommend            
	* @property {Number}  OrderExperienceComment    
	*
	*/
	AddNPSSurvey({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Add)
		return axios.post(`/v2/${req.SPID}/nps_survey`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('AddNPSSurvey ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('AddNPSSurvey ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('AddNPSSurvey ERROR:', error)
					return Promise.reject(error)
				}
				console.log('AddNPSSurvey UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in AddNPSSurvey'))
			})
	},

	/**
	* DeleteNPSResponse: 
	* HTTP Method: DELETE
	* API Path: /api/v2/${req.SPID}/nps_response/${req.UUID}
	* @param    {NPSResponseSearchRequest} req The API payload object (NPSResponseSearchRequest)
	* @return   {NPSResponse} The API response object (NPSResponse)
	*
	* @typedef  {Object}  NPSResponseSearchRequest
	* @property {SearchOptions[]} SearchOptions       Search options to use (for sorting and/or pagination)
	* @property {String}  UUID                
	* @property {Number}  SPID                
	* @property {String}  NPSSurveyUUID       
	* @property {String}  CustomerUUID        
	* @property {Boolean} IncludeDeleted      
	* @property {Number}  CreatedAtFromNanos  
	* @property {Number}  CreatedAtUntilNanos 
	*
	* @typedef  {Object}  NPSResponse
	* @property {String}  UUID                 
	* @property {String}  NPSSurveyUUID        
	* @property {Number}  SPID                 
	* @property {String}  CustomerUUID         
	* @property {String}  Email                
	* @property {String}  MobileNumber         
	* @property {String}  SurveyType           
	* @property {Number}  EffortScore          
	* @property {Number}  ProductServicesScore 
	* @property {Number}  RecommendScore       
	* @property {String}  ExperienceComment    
	* @property {String}  ProductGroup         
	* @property {Number}  CreatedAtNanos       
	* @property {String}  Category1            
	* @property {String}  Category2            
	* @property {String}  Stack                
	* @property {String}  StackDetail          
	* @property {String}  Segment              
	* @property {String}  PlanName             
	* @property {String}  RegionName           
	* @property {String}  DeviceName           
	* @property {ENUM}    DeviceType           
	* @property {String}  HouseholdUUID        
	* @property {String}  Journey              
	* @property {Number}  DeletedAtNanos       
	*
	*/
	DeleteNPSResponse({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Delete)
		return axios.delete(`/v2/${req.SPID}/nps_response/${req.UUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveNPSResponse', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('DeleteNPSResponse ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('DeleteNPSResponse ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('DeleteNPSResponse ERROR:', error)
					return Promise.reject(error)
				}
				console.log('DeleteNPSResponse UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in DeleteNPSResponse'))
			})
	},

	/**
	* DeleteNPSSurvey: 
	* HTTP Method: DELETE
	* API Path: /api/v2/${req.SPID}/nps_survey/${req.UUID}
	* @param    {NPSSurveySearchRequest} req The API payload object (NPSSurveySearchRequest)
	* @return   {NPSSurvey} The API response object (NPSSurvey)
	*
	* @typedef  {Object}  NPSSurveySearchRequest
	* @property {SearchOptions[]} SearchOptions   Search options to use (for sorting and/or pagination)
	* @property {String}  UUID            
	* @property {Number}  SPID            
	* @property {Boolean} IncludeDeleted  
	* @property {String}  ProductGroup    
	* @property {String}  SurveyType      
	* @property {Number}  ValidFromNanos  
	* @property {Number}  ValidUntilNanos 
	* @property {String}  SmartSearch     
	* @property {Boolean} ActiveOnly      
	* @property {String}  CustomerUUID    
	*
	* @typedef  {Object}  NPSSurvey
	* @property {String}  UUID                      
	* @property {Number}  SPID                      
	* @property {Boolean} Active                    
	* @property {Number}  CreatedAtNanos            
	* @property {Number}  UpdatedAtNanos            
	* @property {Number}  DeletedAtNanos            
	* @property {String}  ProductGroup              
	* @property {String}  SurveyType                
	* @property {String}  QuestionEffort            
	* @property {String}  QuestionProductServices   
	* @property {String}  QuestionRecommend         
	* @property {String}  QuestionExperienceComment 
	* @property {Number}  ValidFromNanos            
	* @property {Number}  ValidUntilNanos           
	* @property {String}  Title                     
	* @property {String}  Description               
	* @property {Boolean} CommentEnabled            
	* @property {String}  SurveyResponse            
	* @property {Number}  OrderEffort               repeated NPSQuestion Questions = 19 [ json_name = "Questions", (tagger.tags) = "json:\"Questions\" db:\"-\" gorm:\"-\" bson:\"Questions\" csv:\"Questions\" query:\"Questions\" xml:\"Questions\" dynamodb:\"Questions\" datastore:\"Questions\" bigquery:\"Questions\" spanner:\"Questions\" mapstructure:\"Questions\" graphql:\"Questions\" firestore:\"Questions\""];
	* @property {Number}  OrderProductServices      
	* @property {Number}  OrderRecommend            
	* @property {Number}  OrderExperienceComment    
	*
	*/
	DeleteNPSSurvey({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Delete)
		return axios.delete(`/v2/${req.SPID}/nps_survey/${req.UUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveNPSSurvey', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('DeleteNPSSurvey ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('DeleteNPSSurvey ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('DeleteNPSSurvey ERROR:', error)
					return Promise.reject(error)
				}
				console.log('DeleteNPSSurvey UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in DeleteNPSSurvey'))
			})
	},

	/**
	* GetNPSResponseByUUID: 
	* HTTP Method: GET
	* API Path: /api/v2/${req.SPID}/nps_response/${req.UUID}
	* @param    {NPSResponseSearchRequest} req The API payload object (NPSResponseSearchRequest)
	* @return   {NPSResponse} The API response object (NPSResponse)
	*
	* @typedef  {Object}  NPSResponseSearchRequest
	* @property {SearchOptions[]} SearchOptions       Search options to use (for sorting and/or pagination)
	* @property {String}  UUID                
	* @property {Number}  SPID                
	* @property {String}  NPSSurveyUUID       
	* @property {String}  CustomerUUID        
	* @property {Boolean} IncludeDeleted      
	* @property {Number}  CreatedAtFromNanos  
	* @property {Number}  CreatedAtUntilNanos 
	*
	* @typedef  {Object}  NPSResponse
	* @property {String}  UUID                 
	* @property {String}  NPSSurveyUUID        
	* @property {Number}  SPID                 
	* @property {String}  CustomerUUID         
	* @property {String}  Email                
	* @property {String}  MobileNumber         
	* @property {String}  SurveyType           
	* @property {Number}  EffortScore          
	* @property {Number}  ProductServicesScore 
	* @property {Number}  RecommendScore       
	* @property {String}  ExperienceComment    
	* @property {String}  ProductGroup         
	* @property {Number}  CreatedAtNanos       
	* @property {String}  Category1            
	* @property {String}  Category2            
	* @property {String}  Stack                
	* @property {String}  StackDetail          
	* @property {String}  Segment              
	* @property {String}  PlanName             
	* @property {String}  RegionName           
	* @property {String}  DeviceName           
	* @property {ENUM}    DeviceType           
	* @property {String}  HouseholdUUID        
	* @property {String}  Journey              
	* @property {Number}  DeletedAtNanos       
	*
	*/
	GetNPSResponseByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Get)
		return axios.get(`/v2/${req.SPID}/nps_response/${req.UUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveNPSResponse', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetNPSResponseByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetNPSResponseByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetNPSResponseByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetNPSResponseByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetNPSResponseByUUID'))
			})
	},

	/**
	* GetNPSResponsesPaginated: 
	* HTTP Method: GET
	* API Path: /api/v2/${req.SPID}/nps_response
	* @param    {NPSResponseSearchRequest} req The API payload object (NPSResponseSearchRequest)
	* @return   {NPSResponsePageResponse} The API response object (NPSResponsePageResponse)
	*
	* @typedef  {Object}  NPSResponseSearchRequest
	* @property {SearchOptions[]} SearchOptions       Search options to use (for sorting and/or pagination)
	* @property {String}  UUID                
	* @property {Number}  SPID                
	* @property {String}  NPSSurveyUUID       
	* @property {String}  CustomerUUID        
	* @property {Boolean} IncludeDeleted      
	* @property {Number}  CreatedAtFromNanos  
	* @property {Number}  CreatedAtUntilNanos 
	*
	* @typedef  {Object}  SearchOptions  Search options
	* @property {Number}  PageNumber     The page number to start at (or provide IndexStart)
	* @property {Number}  PageSize       The page size
	* @property {Number}  IndexStart     The record number to start at (or provide PageNumber)
	* @property {String}  SortBy         The field to sort by
	* @property {Boolean} SortDesc       Is the sort direction descending?
	*
	* @typedef  {Object}  NPSResponsePageResponse
	* @property {PageInfo} PageInfo     PageInfo only needs the values ThisPageNumber && PageSize set
	* @property {NPSResponse[]} NPSResponses 
	*
	* @typedef  {Object}   PageInfo
	* @property {Number}   PageNumber             The page number
	* @property {Number}   PageItemCount          Number of items on this page
	* @property {Number}   TotalPages             Total pages available
	* @property {Number}   TotalItemCount         Total items available
	* @property {Number}   IndexStart             The record number this search started at
	* @property {Number}   PageSize               The length of the page
	* @property {Number[]} DisplayPageList        The array of page numbers to display
	* @property {Number}   DisplayPagesEitherSide Number of pages to display either side
	*
	*/
	GetNPSResponsesPaginated({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Find)
		return axios.get(`/v2/${req.SPID}/nps_response`, { params: req, validateStatus: valFuncFind }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			commit('mutSaveNPSResponses', { Response: response.data })
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetNPSResponsesPaginated ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetNPSResponsesPaginated ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetNPSResponsesPaginated ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetNPSResponsesPaginated UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetNPSResponsesPaginated'))
			})
	},

	/**
	* GetNPSSurveyByUUID: 
	* HTTP Method: GET
	* API Path: /api/v2/${req.SPID}/nps_survey/${req.UUID}
	* @param    {NPSSurveySearchRequest} req The API payload object (NPSSurveySearchRequest)
	* @return   {NPSSurvey} The API response object (NPSSurvey)
	*
	* @typedef  {Object}  NPSSurveySearchRequest
	* @property {SearchOptions[]} SearchOptions   Search options to use (for sorting and/or pagination)
	* @property {String}  UUID            
	* @property {Number}  SPID            
	* @property {Boolean} IncludeDeleted  
	* @property {String}  ProductGroup    
	* @property {String}  SurveyType      
	* @property {Number}  ValidFromNanos  
	* @property {Number}  ValidUntilNanos 
	* @property {String}  SmartSearch     
	* @property {Boolean} ActiveOnly      
	* @property {String}  CustomerUUID    
	*
	* @typedef  {Object}  NPSSurvey
	* @property {String}  UUID                      
	* @property {Number}  SPID                      
	* @property {Boolean} Active                    
	* @property {Number}  CreatedAtNanos            
	* @property {Number}  UpdatedAtNanos            
	* @property {Number}  DeletedAtNanos            
	* @property {String}  ProductGroup              
	* @property {String}  SurveyType                
	* @property {String}  QuestionEffort            
	* @property {String}  QuestionProductServices   
	* @property {String}  QuestionRecommend         
	* @property {String}  QuestionExperienceComment 
	* @property {Number}  ValidFromNanos            
	* @property {Number}  ValidUntilNanos           
	* @property {String}  Title                     
	* @property {String}  Description               
	* @property {Boolean} CommentEnabled            
	* @property {String}  SurveyResponse            
	* @property {Number}  OrderEffort               repeated NPSQuestion Questions = 19 [ json_name = "Questions", (tagger.tags) = "json:\"Questions\" db:\"-\" gorm:\"-\" bson:\"Questions\" csv:\"Questions\" query:\"Questions\" xml:\"Questions\" dynamodb:\"Questions\" datastore:\"Questions\" bigquery:\"Questions\" spanner:\"Questions\" mapstructure:\"Questions\" graphql:\"Questions\" firestore:\"Questions\""];
	* @property {Number}  OrderProductServices      
	* @property {Number}  OrderRecommend            
	* @property {Number}  OrderExperienceComment    
	*
	*/
	GetNPSSurveyByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Get)
		return axios.get(`/v2/${req.SPID}/nps_survey/${req.UUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveNPSSurvey', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetNPSSurveyByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetNPSSurveyByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetNPSSurveyByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetNPSSurveyByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetNPSSurveyByUUID'))
			})
	},

	/**
	* GetNPSSurveysPaginated: 
	* HTTP Method: GET
	* API Path: /api/v2/${req.SPID}/nps_survey
	* @param    {NPSSurveySearchRequest} req The API payload object (NPSSurveySearchRequest)
	* @return   {NPSSurveyPageResponse} The API response object (NPSSurveyPageResponse)
	*
	* @typedef  {Object}  NPSSurveySearchRequest
	* @property {SearchOptions[]} SearchOptions   Search options to use (for sorting and/or pagination)
	* @property {String}  UUID            
	* @property {Number}  SPID            
	* @property {Boolean} IncludeDeleted  
	* @property {String}  ProductGroup    
	* @property {String}  SurveyType      
	* @property {Number}  ValidFromNanos  
	* @property {Number}  ValidUntilNanos 
	* @property {String}  SmartSearch     
	* @property {Boolean} ActiveOnly      
	* @property {String}  CustomerUUID    
	*
	* @typedef  {Object}  SearchOptions  Search options
	* @property {Number}  PageNumber     The page number to start at (or provide IndexStart)
	* @property {Number}  PageSize       The page size
	* @property {Number}  IndexStart     The record number to start at (or provide PageNumber)
	* @property {String}  SortBy         The field to sort by
	* @property {Boolean} SortDesc       Is the sort direction descending?
	*
	* @typedef  {Object}  NPSSurveyPageResponse
	* @property {PageInfo} PageInfo   PageInfo only needs the values ThisPageNumber && PageSize set
	* @property {NPSSurvey[]} NPSSurveys 
	*
	* @typedef  {Object}   PageInfo
	* @property {Number}   PageNumber             The page number
	* @property {Number}   PageItemCount          Number of items on this page
	* @property {Number}   TotalPages             Total pages available
	* @property {Number}   TotalItemCount         Total items available
	* @property {Number}   IndexStart             The record number this search started at
	* @property {Number}   PageSize               The length of the page
	* @property {Number[]} DisplayPageList        The array of page numbers to display
	* @property {Number}   DisplayPagesEitherSide Number of pages to display either side
	*
	*/
	GetNPSSurveysPaginated({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Find)
		return axios.get(`/v2/${req.SPID}/nps_survey`, { params: req, validateStatus: valFuncFind }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			commit('mutSaveNPSSurveys', { Response: response.data })
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetNPSSurveysPaginated ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetNPSSurveysPaginated ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetNPSSurveysPaginated ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetNPSSurveysPaginated UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetNPSSurveysPaginated'))
			})
	},

	/**
	* UpdateNPSResponse: 
	* HTTP Method: PUT
	* API Path: /api/v2/${req.SPID}/nps_response/${req.UUID}
	* @param    {NPSResponse} req The API payload object (NPSResponse)
	* @return   {NPSResponse} The API response object (NPSResponse)
	*
	* @typedef  {Object}  NPSResponse
	* @property {String}  UUID                 
	* @property {String}  NPSSurveyUUID        
	* @property {Number}  SPID                 
	* @property {String}  CustomerUUID         
	* @property {String}  Email                
	* @property {String}  MobileNumber         
	* @property {String}  SurveyType           
	* @property {Number}  EffortScore          
	* @property {Number}  ProductServicesScore 
	* @property {Number}  RecommendScore       
	* @property {String}  ExperienceComment    
	* @property {String}  ProductGroup         
	* @property {Number}  CreatedAtNanos       
	* @property {String}  Category1            
	* @property {String}  Category2            
	* @property {String}  Stack                
	* @property {String}  StackDetail          
	* @property {String}  Segment              
	* @property {String}  PlanName             
	* @property {String}  RegionName           
	* @property {String}  DeviceName           
	* @property {ENUM}    DeviceType           
	* @property {String}  HouseholdUUID        
	* @property {String}  Journey              
	* @property {Number}  DeletedAtNanos       
	*
	*/
	UpdateNPSResponse({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Update)
		return axios.put(`/v2/${req.SPID}/nps_response/${req.UUID}`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveNPSResponse', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('UpdateNPSResponse ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('UpdateNPSResponse ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('UpdateNPSResponse ERROR:', error)
					return Promise.reject(error)
				}
				console.log('UpdateNPSResponse UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in UpdateNPSResponse'))
			})
	},

	/**
	* UpdateNPSSurvey: 
	* HTTP Method: PUT
	* API Path: /api/v2/${req.SPID}/nps_survey/${req.UUID}
	* @param    {NPSSurvey} req The API payload object (NPSSurvey)
	* @return   {NPSSurvey} The API response object (NPSSurvey)
	*
	* @typedef  {Object}  NPSSurvey
	* @property {String}  UUID                      
	* @property {Number}  SPID                      
	* @property {Boolean} Active                    
	* @property {Number}  CreatedAtNanos            
	* @property {Number}  UpdatedAtNanos            
	* @property {Number}  DeletedAtNanos            
	* @property {String}  ProductGroup              
	* @property {String}  SurveyType                
	* @property {String}  QuestionEffort            
	* @property {String}  QuestionProductServices   
	* @property {String}  QuestionRecommend         
	* @property {String}  QuestionExperienceComment 
	* @property {Number}  ValidFromNanos            
	* @property {Number}  ValidUntilNanos           
	* @property {String}  Title                     
	* @property {String}  Description               
	* @property {Boolean} CommentEnabled            
	* @property {String}  SurveyResponse            
	* @property {Number}  OrderEffort               repeated NPSQuestion Questions = 19 [ json_name = "Questions", (tagger.tags) = "json:\"Questions\" db:\"-\" gorm:\"-\" bson:\"Questions\" csv:\"Questions\" query:\"Questions\" xml:\"Questions\" dynamodb:\"Questions\" datastore:\"Questions\" bigquery:\"Questions\" spanner:\"Questions\" mapstructure:\"Questions\" graphql:\"Questions\" firestore:\"Questions\""];
	* @property {Number}  OrderProductServices      
	* @property {Number}  OrderRecommend            
	* @property {Number}  OrderExperienceComment    
	*
	*/
	UpdateNPSSurvey({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Update)
		return axios.put(`/v2/${req.SPID}/nps_survey/${req.UUID}`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveNPSSurvey', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('UpdateNPSSurvey ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('UpdateNPSSurvey ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('UpdateNPSSurvey ERROR:', error)
					return Promise.reject(error)
				}
				console.log('UpdateNPSSurvey UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in UpdateNPSSurvey'))
			})
	},
};

export default {
	namespaced: true,
	state: initialState,
	getters,
	mutations,
	actions,
};