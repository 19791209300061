import router from '@/router'
import store from '@/store'
import storage from '@/utils/storage'


const INACTIVE_LOGOUT = process.env.VUE_APP_INACTIVE_LOGOUT ? parseInt(process.env.VUE_APP_INACTIVE_LOGOUT) || 0 : 0

const INACTIVE_LOGOUT_KEY = 'last_activity'

let inactive_timeout = null
const inactiveLogout = () => {
    const last_activity_state = store.getters.last_activity
    const last_activity_storage = storage.get(INACTIVE_LOGOUT_KEY)

    if (last_activity_state) {
        if (!last_activity_storage
            || last_activity_state >= last_activity_storage
            || (last_activity_state + store.getters.inactive_logout) < last_activity_storage
        ) {
            const resolve_clear = () => {
                const resolve_redirect = () => {
                    router.push('/')
                }

                store.dispatch('clearUserActivity').then(resolve_redirect).catch(resolve_redirect)
            }
    
            store.dispatch('Logout').then(resolve_clear).catch(resolve_clear)
        } else {
            store.dispatch('fixUserActivity', last_activity_storage).then(()=>{}).catch(()=>{})
        }
    }
}

const state = {
    last_activity: null
}

const getters = {
    last_activity: state => state.last_activity,
    inactive_logout: (state, getters) => INACTIVE_LOGOUT * (60 * 1000), // INACTIVE_LOGOUT min
}

const mutations = {
    fixUserActivity(state, timeout) {
        const timeout_now = (new Date()).getTime()

        const last_activity = timeout ? timeout : timeout_now

        const timeout_delay = timeout
            ? (store.getters.inactive_logout - (timeout_now - timeout))
            : store.getters.inactive_logout

        if (inactive_timeout !== null) {
            clearTimeout(inactive_timeout)
        }

        state.last_activity = last_activity
        storage.set(INACTIVE_LOGOUT_KEY, last_activity)

        inactive_timeout = setTimeout(inactiveLogout, timeout_delay)
    },

    clearUserActivity(state) {
        if (inactive_timeout !== null) {
            clearTimeout(inactive_timeout)
            inactive_timeout = null
        }

        state.last_activity = null
        storage.remove(INACTIVE_LOGOUT_KEY)
    },
}

const actions = {
    fixUserActivity({commit}, timeout) {
        commit('fixUserActivity', timeout)

        return Promise.resolve(inactive_timeout)
    },

    clearUserActivity({commit}) {
        commit('clearUserActivity')

        return Promise.resolve(inactive_timeout)
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
}