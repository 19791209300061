const MODULE_TYPES = {
    PRODUCT: 'PRODUCT',
    SERVICE: 'SERVICE',
    TOOL:    'TOOL',
}

const AVAILABLE_MODULES = {
    /**
     * Product modules
     */
    PROD_FIBRE: {
        Name:  'PROD_FIBRE',
        Type:   MODULE_TYPES.PRODUCT,
        Title: 'Fibre Broadband',
    },
    PROD_FWA: {
        Name:  'PROD_FWA',
        Type:   MODULE_TYPES.PRODUCT,
        Title: 'Fixed Wireless',
    },
    PROD_MOBILE: {
        Name:  'PROD_MOBILE',
        Type:   MODULE_TYPES.PRODUCT,
        Title: 'PostPay Mobile',
    },
    DOMAINS: {
        Name:  'DOMAINS',
        Type:   MODULE_TYPES.PRODUCT,
        Title: 'Domains',
    },
    SIP_TRUNKING: {
        Name:  'SIP_TRUNKING',
        Type:   MODULE_TYPES.PRODUCT,
        Title: 'SIP Trunking',
    },
    BULK_PRE_QUAL: {
        Name:  'BULK_PRE_QUAL',
        Type:   MODULE_TYPES.PRODUCT,
        Title: 'Bulk Pre-Qual',
    },
    PREPAY_MOBILE: {
        Name:  'PREPAY_MOBILE',
        Type:   MODULE_TYPES.PRODUCT,
        Title: 'PrePay Mobile',
    },

    /**
     * Service modules
     */
    SVC_RADIUS: {
        Name:  'SVC_RADIUS',
        Type:   MODULE_TYPES.SERVICE,
        Title: 'RADIUS',
    },
    SVC_TV: {
        Name:  'SVC_TV',
        Type:   MODULE_TYPES.SERVICE,
        Title: 'Vodafone TV',
    },
    SVC_NUMBER_PORTING: {
        Name:  'SVC_NUMBER_PORTING',
        Type:   MODULE_TYPES.SERVICE,
        Title: 'Number Porting',
    },
    SVC_MATRIXX: {
        Name:  'SVC_MATRIXX',
        Type:   MODULE_TYPES.SERVICE,
        Title: 'Matrixx',
    },
    SVC_SMS_ROUTER: {
        Name:  'SVC_SMS_ROUTER',
        Type:   MODULE_TYPES.SERVICE,
        Title: 'SMS Router',
    },
    IP_MANAGER: {
        Name:  'IP_MANAGER',
        Type:   MODULE_TYPES.SERVICE,
        Title: 'IP Manager',
    },
    PSTN_MANAGER: {
        Name:  'PSTN_MANAGER',
        Type:   MODULE_TYPES.SERVICE,
        Title: 'PSTN Manager',
    },
    NUMBER_MANAGER: {
        Name:  'NUMBER_MANAGER',
        Type:   MODULE_TYPES.SERVICE,
        Title: 'Number Manager',
    },
    LOYALTY_MANAGEMENT: {
        Name:  'LOYALTY_MANAGEMENT',
        Type:   MODULE_TYPES.SERVICE,
        Title: 'Voucher Mngt',
    },
    SRV_ASAP: {
        Name:  'SRV_ASAP',
        Type:   MODULE_TYPES.SERVICE,
        Title: 'ASAP',
    },
    SERVICE_INVENTORY: {
        Name:  'SERVICE_INVENTORY',
        Type:   MODULE_TYPES.SERVICE,
        Title: 'Service Inventory',
    },
    SERVICE_ORDERS: {
        Name:  'SERVICE_ORDERS',
        Type:   MODULE_TYPES.SERVICE,
        Title: 'Service Orders',
    },

    /**
     * Tools modules
     */
    DIGITAL_COMMS: {
        Name:  'DIGITAL_COMMS',
        Type:   MODULE_TYPES.TOOL,
        Title: 'Digital Comms',
    },
    SMS_GATEWAY: {
        Name:  'SMS_GATEWAY',
        Type:   MODULE_TYPES.TOOL,
        Title: 'SMS Gateway',
    },
}

const state = {
    application_included_modules: [
        ...(process.env.VUE_APP_PRODUCT_MODULES ? process.env.VUE_APP_PRODUCT_MODULES.split(',') : []),
        ...(process.env.VUE_APP_SERVICE_MODULES ? process.env.VUE_APP_SERVICE_MODULES.split(',') : []),
        ...(process.env.VUE_APP_TOOLS_MODULES ? process.env.VUE_APP_TOOLS_MODULES.split(',') : []),
    ],

    test_features: process.env.VUE_APP_TEST_FEATURES ? process.env.VUE_APP_TEST_FEATURES.split(',') : [],
}

const getters = {
    module_types: () => MODULE_TYPES,

    modules_env: (state, getters) => {
        let modules = {}

        let modules_env = {}
        for (let i = 0, len = state.application_included_modules.length; i < len; i++) {
            modules_env[ state.application_included_modules[i] ] = true
        }

        for (const key in AVAILABLE_MODULES) {
            if (key in modules_env) {
                modules[ key ] = AVAILABLE_MODULES[ key ]
            }
        }

        return modules
    },

    modules_user: (state, getters) => {
        let modules = {}

        const modules_env = getters.modules_env

        if (getters.is_super_admin) {
            modules = getters.modules_env
        } else {
            const current_sp = getters.current_sp

            if (current_sp && current_sp.Features) {
                for (let i = 0, len = current_sp.Features.length; i < len; i++) {
                    if (Boolean(modules_env[current_sp.Features[i]])) {
                        modules[ current_sp.Features[i] ] = modules_env[current_sp.Features[i]]
                    } else {
                        console.error('ERROR: Unknown module in service provider config:', current_sp.Features[i])
                    }
                }
            }
        }

        return modules
    },

    modules_sp: (state, getters) => {
        const features = {}
        const modules_sp = {}

        const modules_env = getters.modules_env
        const current_sp = getters.current_sp

        if (current_sp && current_sp.Features) {
            for (let i = 0, len = current_sp.Features.length; i < len; i++) {
                if (Boolean(modules_env[current_sp.Features[i]])) {
                    features[ current_sp.Features[i] ] = modules_env[current_sp.Features[i]]
                } else {
                    console.error('ERROR: Unknown module in service provider config:', current_sp.Features[i])
                }
            }
        }

        for (const key in modules_env) {
            if (key in features) {
                modules_sp[key] = features[key]
            }
        }

        const test_features = state.test_features

        for (let i = 0, len = test_features.length; i < len; i++) {
            if (test_features[i] in modules_env) {
                modules_sp[test_features[i]] = AVAILABLE_MODULES[test_features[i]]
            }
        }

        return modules_sp
    },

    isModuleAvailable: (state, getters) => module => module in getters.modules_sp,
}

const mutations = {}

const actions = {}

export default {
    state,
    getters,
    mutations,
    actions,
}