import axios from 'axios'

const AVAILABLE_ADDING_TYPES = {
    PROD_MOBILE: {
        Postpay: {
            key: 'postpaymobile',
            title: 'Post-Pay Mobile',
            plan_type: 'postpaymobile',
        },
        Prepay: {
            key: 'prepaymobile',
            title: 'Pre-Pay Mobile Base Plan',
            plan_type: 'prepaymobile',
        },
        PrepayAddon: {
            key: 'prepaymobileAddon',
            title: 'Pre-Pay Mobile Addon',
            plan_type: 'prepaymobileAddon',
        },
        Prepay: {
            key: 'prepaymobile',
            title: 'Pre-Pay Mobile Base Plan',
            plan_type: 'prepaymobile',
        },
    },
    PROD_FIBRE: {
        UFB: {
            key: 'UFB',
            title: 'UFB Fibre',
            plan_type: 'UFB',
        },
    },
    PROD_FWA: {
        FWA_Volume: {
            key: 'FWA_Volume',
            title: 'Fixed Wireless Volume',
            plan_type: 'FWA_Volume',
        },
        FWA_Speed: {
            key: 'FWA_Speed',
            title: 'Fixed Wireless Speed',
            plan_type: 'FWA_Speed',
        },
    },
}

const state = {}

const getters = {
    products_adding_types: (state, getters) => {
        let products_adding_types = {}

        const modules_sp = getters.modules_sp
        const is_prerelease_mode = getters.is_prerelease_mode

        for (const key in AVAILABLE_ADDING_TYPES) {
            if (key in modules_sp) {
                for (const type in AVAILABLE_ADDING_TYPES[key]) {
                    // if (!is_prerelease_mode && (type == 'PrepayAddon' || type == 'Prepay')) {
                    //     continue
                    // }
                    products_adding_types[type] = AVAILABLE_ADDING_TYPES[key][type]
                }
            }
        }
        
        return products_adding_types
    },
}

const mutations = {}

const actions = {}

export default {
    state,
    getters,
    mutations,
    actions,
}