import axios from 'axios'
import axiosUnique from '@/utils/axiosUnique'

import StoreHash from '@/utils/StoreHash'

import randomInteger from '@/helpers/random-integer'

const SHOW_BILLING_TAB = process.env.VUE_APP_SHOW_BILLING_TAB === 'true'
const SHOW_ORDERS_TAB = process.env.VUE_APP_SHOW_ORDERS_TAB === 'true'

let hashed = {
    customers: {
        uuids: {},
        lifetime: 1 * 60 * 1000, // 1 min
    }
}

const state = {
    customers: {},
    customers_filter: null,
}

const getters = {
    show_billing_tab: () => SHOW_BILLING_TAB,

    show_orders_tab: () => SHOW_ORDERS_TAB,

    getCustomersByUUID: state => uuid => state.customers[uuid],

    customers_filter: state => state.customers_filter,
}

const mutations = {
    saveCustomers(state, { customers }) {
        let customers_list = {}

        for (let n = 0, len = customers.length; n < len; n++) {
            const customer = customers[n]
            const uuid = customer.UUID

            if (!(uuid in hashed.customers.uuids)) {
                hashed.customers.uuids[ uuid ] = new StoreHash(hashed.customers.lifetime)
            }
            
            customers_list[ uuid ] = customer

            hashed.customers.uuids[ uuid ].fix()
        }

        if (Object.keys(customers_list).length) {
            state.customers = {
                ...state.customers,
                ...customers_list
            }
        }
    },

    saveCustomer(state, customer) {
        const uuid = customer.UUID

        if (!(uuid in hashed.customers.uuids)) {
            hashed.customers.uuids[ uuid ] = new StoreHash(hashed.customers.lifetime)
        }

        state.customers = {
            ...state.customers,
            [ uuid ]: customer
        }

        hashed.customers.uuids[ uuid ].fix()
    },

    saveCustomersFilter(state, filter) {
        state.customers_filter = filter
    },    
    clearCustomersFilter(state) {
        state.customers_filter = null
    },

    resetHashedCustomers(state) {
        state.customers = {}

        hashed.customers.uuids = {}
    },
}

const actions = {
    /**
     * Search for customers by given fields
     */
    searchCustomers({}, { params }) {
        return axiosUnique.get('/v2/:spid/customer', { params })
            .then(({ apidata: { Customers } }) => {
                return Promise.resolve(Customers)
            })
            .catch(error => {
                console.log(error)
                return Promise.reject(error)
            })
    },

    /**
     * Создание нового клиента
     */
    createCustomer({}, { params }) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                import('./hardcoded-test-data/customers/customer-by-uuid.json')
                    .then(response => {
                        const customer = response.default
                        resolve(customer)
                    })
                    .catch(error => {
                        reject(error)
                    })
            }, 1000)
        })

        /*
            return axiosUnique.post('/v2/:spid/customers/create', params)
                .then(response => {
                    const customer = response.apidata
                    resolve(customer)
                })
                .catch(error => {
                    return Promise.reject(error)
                })
        */
    },

    /**
     * Генерация мобильного номера
     */
    getNewNumber() {
        return new Promise(resolve => {
            setTimeout(() => {
                const country_code = '+649';
                let mobile_number = `${ country_code }`;
        
                for (let i = 0; i < 7; i++) {
                    mobile_number += randomInteger(0, 9)
                }

                resolve(mobile_number)
            }, 1000)
        })

        /*
            return axiosUnique.get('/v2/:spid/customers/get-new-number')
                .then(response => {
                    const mobile_number = response.apidata.MobileNumber
                    resolve(mobile_number)
                })
                .catch(error => {
                    return Promise.reject(error)
                })
        */
    },

    /**
     * Получение данных для таблицы "Product Orders" на основании UUID клиента
     */
    getProductOrders({}, { uuid }) {

        return new Promise((resolve, reject) => {
            setTimeout(() => {
                import('./hardcoded-test-data/customers/product-orders-by-uuid.json')
                    .then(response => {
                        const PageInfo = response.default.PageInfo
                        const Orders = response.default.Orders

                        resolve({ PageInfo, Orders })
                    })
                    .catch(error => {
                        reject(error)
                    })
            }, 1000)
        })

        /*
            const params = {
                'SearchOptions.PageSize': -1,
            }

            return axiosUnique.get(`/v2/:spid/customer/${ uuid }/product-orders`, params)
                .then(({ apidata }) => {
                    const PageInfo = apidata.PageInfo
                    const Orders = apidata.Orders

                    resolve({ PageInfo, Orders })
                })
                .catch(error => {
                    return Promise.reject(error)
                })
        */
    },

    /**
     * Получение данных по кредитной карте на основании UUID клиента
     */
    getCreditCardInfo({}, { uuid }) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                import('./hardcoded-test-data/customers/credit-card-info-by-uuid.json')
                    .then(response => {
                        /**
                         * На основании дизайна я предпологаю,
                         * что у пользователя может быть только одна кредитная карта одновременно
                         */
                        const Card = response.default.Card

                        resolve({ Card })
                    })
                    .catch(error => {
                        reject(error)
                    })
            }, 2000)
        })

        // return axiosUnique.get(`/v2/:spid/customer/${ uuid }/credit-card`)
        //     .then(({ apidata }) => {
        //         /**
        //          * На основании дизайна я предпологаю,
        //          * что у пользователя может быть только одна кредитная карта одновременно
        //          */
        //         const Card = apidata.Card

        //         resolve({ Card })
        //     })
        //     .catch(error => {
        //         return Promise.reject(error)
        //     })
    },

    /**
     * Удаление кредитной карты, которая привязана к аккаунту пользователя
     */
    removeCreditCard({}, { customer_uuid, credit_card_uuid }) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                import('./hardcoded-test-data/customers/successfully-removed-credit-card.json')
                    .then(response => {
                        const Card = response.default.Card

                        resolve({ Card })
                    })
                    .catch(error => {
                        reject(error)
                    })
            }, 2000)
        })

        /*
            return axiosUnique.delete(`/v2/:spid/customer/${ customer_uuid }/credit-card/${ credit_card_uuid }`)
                .then(({ apidata }) => {
                    const Card = apidata.Card

                    return Promise.resolve({ Card })
                })
                .catch(error => {
                    return Promise.reject(error)
                })
        */
    },

    /**
     * Добавление нового комментария
     */
    addComment({}, { uuid, params }) {

        return new Promise((resolve, reject) => {
            setTimeout(() => {
                import('./hardcoded-test-data/customers/successfully-added-comment.json')
                    .then(response => {
                        const Comment = response.default.Comment

                        resolve({ Comment })
                    })
                    .catch(error => {
                        reject(error)
                    })
            }, 2000)
        })

        /*
            return axiosUnique.post(`/v2/:spid/customer/${ uuid }/comments`, params)
                .then(({ apidata }) => {
                    const Comment = apidata.Comment
    
                    resolve({ Comment })
                })
                .catch(error => {
                    return Promise.reject(error)
                })
        */
    },

    /**
     * Получение списка комментариев на основании UUID клиента
     */
    getComments({}, { uuid, params }) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                import('./hardcoded-test-data/customers/comments-by-uuid.json')
                    .then(response => {
                        const PageInfo = response.default.PageInfo
                        const Comments = response.default.Comments

                        resolve({ PageInfo, Comments })
                    })
                    .catch(error => {
                        reject(error)
                    })
            }, 2000)
        })

        /*
            return axiosUnique.get(`/v2/:spid/customer/${ uuid }/comments`, params)
                .then(({ apidata }) => {
                    const PageInfo = apidata.PageInfo
                    const Comments = apidata.Comments

                    resolve({ PageInfo, Comments })
                })
                .catch(error => {
                    return Promise.reject(error)
                })
        */
    },

    getCustomers({commit, dispatch}, { params, filter }) {
        return axiosUnique.get(`/v2/:spid/customer`, { params }).then(response => {
            const customers = response.apidata.Customers

            commit('saveCustomers', { customers })

            const action = filter ? 'saveCustomersFilter' : 'clearCustomersFilter'
            const resolve = () => Promise.resolve(response)

            return dispatch(action, filter).then(resolve).catch(resolve)
        }).catch(error => {
            return Promise.reject(error)
        })
    },

    getCustomerByUUID({getters, commit, dispatch}, { uuid, refresh }) {
        if (!(uuid in hashed.customers.uuids)) {
			hashed.customers.uuids[ uuid ] = new StoreHash(hashed.customers.lifetime)
        }

        if (refresh || hashed.customers.uuids[ uuid ].expired()) {
            return dispatch('api_customer/GetCustomerByUUID', { UUID: uuid, SPID: getters.current_spid }).then(customer => {
                commit('saveCustomer', customer)
                return Promise.resolve(getters.getCustomersByUUID(uuid))
            }).catch(error => {
                return Promise.reject(error)
            })
        } else {
            return Promise.resolve(getters.getCustomersByUUID(uuid))
        }
    },

    updateCustomer({getters, commit}, { uuid, params }) {
        return axios.put(`/v2/:spid/customer/${ uuid }`, params).then(({apidata}) => {
            commit('saveCustomer', apidata)
            return Promise.resolve(getters.getCustomersByUUID(uuid))
        }).catch(error => {
            return Promise.reject(error)
        })
    },

    deactivateCustomer({getters, commit}, { uuid }) {
        return axios.delete(`/v2/:spid/customer/${ uuid }`).then(({apidata}) => {
            commit('saveCustomer', apidata)
            return Promise.resolve(getters.getCustomersByUUID(uuid))
        }).catch(error => Promise.reject(error))
    },

    reactivateCustomer({ state, commit }, { uuid }) {
        const payload = {
            'Active': 1,
            'DeletedAt': 0,
        }

        return axios.put(`/v2/:spid/customer/${ uuid }`, payload).then(({apidata}) => {
            commit('saveCustomer', apidata)
            return Promise.resolve(getters.getCustomersByUUID(uuid))
        }).catch(error => Promise.reject(error))
    },

    loadCustomersFilter({getters}) {
        const filter = getters.customers_filter

        return filter
            ? Promise.resolve(filter)
            : Promise.reject(filter)
    },
    saveCustomersFilter({commit, getters}, filter) {
        commit('saveCustomersFilter', filter)
        return Promise.resolve(getters.customers_filter)
    },
    clearCustomersFilter({commit, getters}) {
        commit('clearCustomersFilter')
        return Promise.resolve(getters.customers_filter)
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
}