// Code generated by protoc-gen-iqwebapi. DO NOT EDIT.
// source: campaign.proto

import axios from 'axios';
import StoreHash from '@/utils/StoreHash';

const initialState = {
	Campaigns: {
		UUIDs: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
	Vouchers: {
		UUIDs: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
};

const valFuncFind = function (status) {
	return (status == 200) ? true : (status == 404) ? true : false;
}

const default_pageinfo = {
	PageNumber: 0,
	PageItemCount: 0,
	TotalPages: 0,
	TotalItemCount: 0,
	IndexStart: 0,
	PageSize: 10,
	DisplayPageList: [],
	DisplayPageEitherSide: 4,
}

const getters = {
	getCampaignByUUID: state => (UUID) => (state.Campaigns.UUIDs[ UUID ] && !state.Campaigns.UUIDs[ UUID ].expired()) ?  state.Campaigns.UUIDs[ UUID ].hashedData() : null,
	getVoucherByUUID: state => (UUID) => (state.Vouchers.UUIDs[ UUID ] && !state.Vouchers.UUIDs[ UUID ].expired()) ?  state.Vouchers.UUIDs[ UUID ].hashedData() : null,
};

const mutations = {
	// mutSaveCampaign saves a single Campaign object
	// into both the StoreHash cache and the VueX store
	mutSaveCampaign(state, obj) {
		if (!obj.UUID) { return } // Must have UUID to Save

		if (!(obj.UUID in state.Campaigns.UUIDs)) {
			state.Campaigns.UUIDs[ obj.UUID ] = new StoreHash(state.Campaigns.lifetime)
		}
		state.Campaigns.UUIDs[ obj.UUID ].fix(obj) // Add item to StoreHash
	},
	// mutRemoveCampaignByUUID removes a Campaign object
	// with the given UUID from StoreHash cache and VueX store
	mutRemoveCampaignByUUID(state, UUID) {
		if (!UUID) {
			// Must have UUID to Remove
			return
		}
		delete state.Campaigns.UUIDs[ UUID ]    // remove item from StoreHash
	},
	// mutSaveCampaigns saves a paginated response of Campaigns
	mutSaveCampaigns(state, { Response }) {
		if (!Response || !Array.isArray(Response.Campaigns)) {
			// Must have Response object with results
			return
		}
		for (let i = 0; i < Response.Campaigns.length; i++) {
			if (!(Response.Campaigns[i].UUID in state.Campaigns.UUIDs)) {
				state.Campaigns.UUIDs[ Response.Campaigns[i].UUID ] = new StoreHash(state.Campaigns.lifetime)
			}
			state.Campaigns.UUIDs[ Response.Campaigns[i].UUID ].fix(Response.Campaigns[i])  // Add single Campaign to StoreHash
		}
	},
	// mutSaveVoucher saves a single Voucher object
	// into both the StoreHash cache and the VueX store
	mutSaveVoucher(state, obj) {
		if (!obj.UUID) { return } // Must have UUID to Save

		if (!(obj.UUID in state.Vouchers.UUIDs)) {
			state.Vouchers.UUIDs[ obj.UUID ] = new StoreHash(state.Vouchers.lifetime)
		}
		state.Vouchers.UUIDs[ obj.UUID ].fix(obj) // Add item to StoreHash
	},
	// mutRemoveVoucherByUUID removes a Voucher object
	// with the given UUID from StoreHash cache and VueX store
	mutRemoveVoucherByUUID(state, UUID) {
		if (!UUID) {
			// Must have UUID to Remove
			return
		}
		delete state.Vouchers.UUIDs[ UUID ]    // remove item from StoreHash
	},
	// mutSaveVouchers saves a paginated response of Vouchers
	mutSaveVouchers(state, { Response }) {
		if (!Response || !Array.isArray(Response.Vouchers)) {
			// Must have Response object with results
			return
		}
		for (let i = 0; i < Response.Vouchers.length; i++) {
			if (!(Response.Vouchers[i].UUID in state.Vouchers.UUIDs)) {
				state.Vouchers.UUIDs[ Response.Vouchers[i].UUID ] = new StoreHash(state.Vouchers.lifetime)
			}
			state.Vouchers.UUIDs[ Response.Vouchers[i].UUID ].fix(Response.Vouchers[i])  // Add single Voucher to StoreHash
		}
	},
};

const actions = {
	// getCachedCampaignByUUID fetches a Campaign from the cache
	// and if not in the cache, fetches it from the API
	getCachedCampaignByUUID({ state, dispatch, commit }, { UUID, RefreshCache }) {
		if (!RefreshCache && state.Campaigns.UUIDs[ UUID ] && !state.Campaigns.UUIDs[ UUID ].expired()) {
			// Return the cached record
			return state.Campaigns.UUIDs[ UUID ].hashedData()
		}
		// Fetch from the API
		return dispatch('GetCampaignByUUID', { UUID }).then(respPayload => {
			commit('mutSaveCampaign', respPayload)
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveCampaignByUUID', UUID)
			return Promise.reject(error)
		})
	},

	// getCachedVoucherByUUID fetches a Voucher from the cache
	// and if not in the cache, fetches it from the API
	getCachedVoucherByUUID({ state, dispatch, commit }, { UUID, RefreshCache }) {
		if (!RefreshCache && state.Vouchers.UUIDs[ UUID ] && !state.Vouchers.UUIDs[ UUID ].expired()) {
			// Return the cached record
			return state.Vouchers.UUIDs[ UUID ].hashedData()
		}
		// Fetch from the API
		return dispatch('GetVoucherByUUID', { UUID }).then(respPayload => {
			commit('mutSaveVoucher', respPayload)
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveVoucherByUUID', UUID)
			return Promise.reject(error)
		})
	},

	//
	// Service: CampaignRPC
	// 
	/**
	* AddVouchers: 
	* HTTP Method: POST
	* API Path: /api/v1/${req.SPID}/campaign/${req.CampaignUUID}/add_vouchers
	* @param    {VoucherAddRequest} req The API payload object (VoucherAddRequest)
	* @return   {VoucherGenAddResponse} The API response object (VoucherGenAddResponse)
	*
	* @typedef  {Object}  VoucherAddRequest
	* @property {String}  CampaignUUID 
	* @property {Number}  SPID         
	* @property {String}  VoucherCode  
	*
	* @typedef  {Object}  VoucherGenAddResponse
	* @property {String}  CampaignUUID     
	* @property {Number}  SPID             
	* @property {Number}  NumVouchersAdded 
	*
	*/
	AddVouchers({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.post(`/v1/${req.SPID}/campaign/${req.CampaignUUID}/add_vouchers`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('AddVouchers ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('AddVouchers ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('AddVouchers ERROR:', error)
					return Promise.reject(error)
				}
				console.log('AddVouchers UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in AddVouchers'))
			})
	},

	/**
	* AllocateVoucher: 
	* HTTP Method: GET
	* API Path: /api/v1/${req.SPID}/customer/${req.CustomerUUID}/campaign/${req.CampaignUUID}
	* @param    {VoucherAllocateRequest} req The API payload object (VoucherAllocateRequest)
	* @return   {Voucher} The API response object (Voucher)
	*
	* @typedef  {Object}  VoucherAllocateRequest
	* @property {String}  CampaignUUID 
	* @property {Number}  SPID         
	* @property {String}  CustomerUUID 
	* @property {String}  AgentUUID    
	*
	* @typedef  {Object}  Voucher
	* @property {String}  UUID             
	* @property {Number}  SPID             
	* @property {String}  CampaignUUID     
	* @property {String}  VoucherCode      VoucherCode is the actual voucher code
	* @property {Number}  CreatedAtNanos   
	* @property {Number}  UpdatedAtNanos   
	* @property {Number}  DeletedAtNanos   
	* @property {Number}  AllocatedAtNanos 
	* @property {Number}  ExpiresAtNanos   
	* @property {String}  CustomerUUID     CustomerUUID is the customer this voucher was assigned to
	*
	*/
	AllocateVoucher({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.get(`/v1/${req.SPID}/customer/${req.CustomerUUID}/campaign/${req.CampaignUUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('AllocateVoucher ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('AllocateVoucher ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('AllocateVoucher ERROR:', error)
					return Promise.reject(error)
				}
				console.log('AllocateVoucher UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in AllocateVoucher'))
			})
	},

	/**
	* AvailableCampaigns: 
	* HTTP Method: GET
	* API Path: /api/v1/${req.SPID}/customer/${req.CustomerUUID}/campaigns
	* @param    {AvailableCampaignsRequest} req The API payload object (AvailableCampaignsRequest)
	* @return   {AvailableCampaignsResponse} The API response object (AvailableCampaignsResponse)
	*
	* @typedef  {Object}  AvailableCampaignsRequest
	* @property {String}  CustomerUUID                 
	* @property {Number}  SPID                         
	* @property {Boolean} IncludeAutoallocateQualified 
	*
	* @typedef  {Object}  AvailableCampaignsResponse
	* @property {Campaign[]} Campaigns 
	*
	*/
	AvailableCampaigns({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.get(`/v1/${req.SPID}/customer/${req.CustomerUUID}/campaigns`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('AvailableCampaigns ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('AvailableCampaigns ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('AvailableCampaigns ERROR:', error)
					return Promise.reject(error)
				}
				console.log('AvailableCampaigns UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in AvailableCampaigns'))
			})
	},

	/**
	* CreateCampaign: 
	* HTTP Method: POST
	* API Path: /api/v1/${req.SPID}/campaign
	* @param    {Campaign} req The API payload object (Campaign)
	* @return   {Campaign} The API response object (Campaign)
	*
	* @typedef  {Object}  Campaign
	* @property {String}  UUID                      
	* @property {Number}  SPID                      
	* @property {String}  Name                      Name is the name of this campagn
	* @property {Number}  CreatedAtNanos            
	* @property {Number}  UpdatedAtNanos            
	* @property {Number}  DeletedAtNanos            
	* @property {Number}  ExpiresAtNanos            
	* @property {Number}  VouchersPerCustomer       VouchersPerCustomer is the number of vouchers that can be issued for each customer - by the customer themselves
	* @property {String}  LinkedProduct             LinkedProduct is if this campaign is linked to a particulat product
	* @property {String}  LinkedDiscount            LinkedDiscount is the product discount this campaign is linked to
	* @property {String}  Description               Description is the name of this campagn
	* @property {String}  Logo                      Logo displayed to the user
	* @property {Boolean} AutoAllocateSTBActivation If this is true then allocate when customer activates a STB
	* @property {Number}  StartsAtNanos             
	* @property {String}  AutoAllocateType          Type of autoallocation: stbaddition, tvpackagepurchase
	* @property {String}  AutoAllocateDetails       Autoallocation details: for package purchase, the package name
	* @property {Number}  Rank                      
	* @property {String}  SignupPopoverTitle        
	* @property {String}  SignupPopoverText         
	* @property {String}  SignupPopoverImage        
	* @property {String}  ClaimButtonText           
	* @property {String}  ReminderBannerText        
	* @property {String}  ReminderBannerButton      
	* @property {String}  PurchaseReminderText      
	*
	*/
	CreateCampaign({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.post(`/v1/${req.SPID}/campaign`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('CreateCampaign ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('CreateCampaign ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('CreateCampaign ERROR:', error)
					return Promise.reject(error)
				}
				console.log('CreateCampaign UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in CreateCampaign'))
			})
	},

	/**
	* DeleteCampaignByUUID: 
	* HTTP Method: DELETE
	* API Path: /api/v2/${req.SPID}/campaign/${req.UUID}
	* @param    {DeleteCampaignRequest} req The API payload object (DeleteCampaignRequest)
	* @return   {Campaign} The API response object (Campaign)
	*
	* @typedef  {Object}  DeleteCampaignRequest
	* @property {Number}  SPID 
	* @property {String}  UUID 
	*
	* @typedef  {Object}  Campaign
	* @property {String}  UUID                      
	* @property {Number}  SPID                      
	* @property {String}  Name                      Name is the name of this campagn
	* @property {Number}  CreatedAtNanos            
	* @property {Number}  UpdatedAtNanos            
	* @property {Number}  DeletedAtNanos            
	* @property {Number}  ExpiresAtNanos            
	* @property {Number}  VouchersPerCustomer       VouchersPerCustomer is the number of vouchers that can be issued for each customer - by the customer themselves
	* @property {String}  LinkedProduct             LinkedProduct is if this campaign is linked to a particulat product
	* @property {String}  LinkedDiscount            LinkedDiscount is the product discount this campaign is linked to
	* @property {String}  Description               Description is the name of this campagn
	* @property {String}  Logo                      Logo displayed to the user
	* @property {Boolean} AutoAllocateSTBActivation If this is true then allocate when customer activates a STB
	* @property {Number}  StartsAtNanos             
	* @property {String}  AutoAllocateType          Type of autoallocation: stbaddition, tvpackagepurchase
	* @property {String}  AutoAllocateDetails       Autoallocation details: for package purchase, the package name
	* @property {Number}  Rank                      
	* @property {String}  SignupPopoverTitle        
	* @property {String}  SignupPopoverText         
	* @property {String}  SignupPopoverImage        
	* @property {String}  ClaimButtonText           
	* @property {String}  ReminderBannerText        
	* @property {String}  ReminderBannerButton      
	* @property {String}  PurchaseReminderText      
	*
	*/
	DeleteCampaignByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Delete)
		return axios.delete(`/v2/${req.SPID}/campaign/${req.UUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveCampaign', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('DeleteCampaignByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('DeleteCampaignByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('DeleteCampaignByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('DeleteCampaignByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in DeleteCampaignByUUID'))
			})
	},

	/**
	* DeleteVoucher: 
	* HTTP Method: DELETE
	* API Path: /api/v2/${req.SPID}/voucher/${req.UUID}
	* @param    {DeleteVoucherRequest} req The API payload object (DeleteVoucherRequest)
	* @return   {Voucher} The API response object (Voucher)
	*
	* @typedef  {Object}  DeleteVoucherRequest
	* @property {Number}  SPID 
	* @property {String}  UUID 
	*
	* @typedef  {Object}  Voucher
	* @property {String}  UUID             
	* @property {Number}  SPID             
	* @property {String}  CampaignUUID     
	* @property {String}  VoucherCode      VoucherCode is the actual voucher code
	* @property {Number}  CreatedAtNanos   
	* @property {Number}  UpdatedAtNanos   
	* @property {Number}  DeletedAtNanos   
	* @property {Number}  AllocatedAtNanos 
	* @property {Number}  ExpiresAtNanos   
	* @property {String}  CustomerUUID     CustomerUUID is the customer this voucher was assigned to
	*
	*/
	DeleteVoucher({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Delete)
		return axios.delete(`/v2/${req.SPID}/voucher/${req.UUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveVoucher', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('DeleteVoucher ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('DeleteVoucher ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('DeleteVoucher ERROR:', error)
					return Promise.reject(error)
				}
				console.log('DeleteVoucher UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in DeleteVoucher'))
			})
	},

	/**
	* GenerateVouchers: 
	* HTTP Method: POST
	* API Path: /api/v1/${req.SPID}/campaign/${req.CampaignUUID}/generate_vouchers
	* @param    {VoucherGenerateRequest} req The API payload object (VoucherGenerateRequest)
	* @return   {VoucherGenAddResponse} The API response object (VoucherGenAddResponse)
	*
	* @typedef  {Object}  VoucherGenerateRequest
	* @property {String}  CampaignUUID     
	* @property {Number}  SPID             
	* @property {Number}  NumberOfVouchers 
	*
	* @typedef  {Object}  VoucherGenAddResponse
	* @property {String}  CampaignUUID     
	* @property {Number}  SPID             
	* @property {Number}  NumVouchersAdded 
	*
	*/
	GenerateVouchers({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.post(`/v1/${req.SPID}/campaign/${req.CampaignUUID}/generate_vouchers`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GenerateVouchers ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GenerateVouchers ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GenerateVouchers ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GenerateVouchers UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GenerateVouchers'))
			})
	},

	/**
	* GetCampaign: 
	* HTTP Method: GET
	* API Path: /api/v1/${req.SPID}/campaign/${req.UUID}
	* @param    {GetCampaignRequest} req The API payload object (GetCampaignRequest)
	* @return   {Campaign} The API response object (Campaign)
	*
	* @typedef  {Object}  GetCampaignRequest
	* @property {String}  UUID 
	* @property {Number}  SPID 
	*
	* @typedef  {Object}  Campaign
	* @property {String}  UUID                      
	* @property {Number}  SPID                      
	* @property {String}  Name                      Name is the name of this campagn
	* @property {Number}  CreatedAtNanos            
	* @property {Number}  UpdatedAtNanos            
	* @property {Number}  DeletedAtNanos            
	* @property {Number}  ExpiresAtNanos            
	* @property {Number}  VouchersPerCustomer       VouchersPerCustomer is the number of vouchers that can be issued for each customer - by the customer themselves
	* @property {String}  LinkedProduct             LinkedProduct is if this campaign is linked to a particulat product
	* @property {String}  LinkedDiscount            LinkedDiscount is the product discount this campaign is linked to
	* @property {String}  Description               Description is the name of this campagn
	* @property {String}  Logo                      Logo displayed to the user
	* @property {Boolean} AutoAllocateSTBActivation If this is true then allocate when customer activates a STB
	* @property {Number}  StartsAtNanos             
	* @property {String}  AutoAllocateType          Type of autoallocation: stbaddition, tvpackagepurchase
	* @property {String}  AutoAllocateDetails       Autoallocation details: for package purchase, the package name
	* @property {Number}  Rank                      
	* @property {String}  SignupPopoverTitle        
	* @property {String}  SignupPopoverText         
	* @property {String}  SignupPopoverImage        
	* @property {String}  ClaimButtonText           
	* @property {String}  ReminderBannerText        
	* @property {String}  ReminderBannerButton      
	* @property {String}  PurchaseReminderText      
	*
	*/
	GetCampaign({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Get)
		return axios.get(`/v1/${req.SPID}/campaign/${req.UUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveCampaign', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetCampaign ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetCampaign ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetCampaign ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetCampaign UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetCampaign'))
			})
	},

	/**
	* GetCampaignReport: 
	* HTTP Method: GET
	* API Path: /api/v1/${req.SPID}/campaign/${req.UUID}/report
	* @param    {GetCampaignRequest} req The API payload object (GetCampaignRequest)
	* @return   {GetCampaignReportResponse} The API response object (GetCampaignReportResponse)
	*
	* @typedef  {Object}  GetCampaignRequest
	* @property {String}  UUID 
	* @property {Number}  SPID 
	*
	* @typedef  {Object}  GetCampaignReportResponse
	* @property {Object}  CSVData 
	*
	*/
	GetCampaignReport({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.get(`/v1/${req.SPID}/campaign/${req.UUID}/report`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetCampaignReport ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetCampaignReport ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetCampaignReport ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetCampaignReport UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetCampaignReport'))
			})
	},

	/**
	* GetCampaigns: 
	* HTTP Method: POST
	* API Path: /api/v1/${req.SPID}/campaigns
	* @param    {CampaignSearchRequest} req The API payload object (CampaignSearchRequest)
	* @return   {CampaignSearchResponse} The API response object (CampaignSearchResponse)
	*
	* @typedef  {Object}  CampaignSearchRequest
	* @property {SearchOptions[]} SearchOptions Search options to use (for sorting and/or pagination)
	* @property {Number}  SPID          
	*
	* @typedef  {Object}  SearchOptions  Search options
	* @property {Number}  PageNumber     The page number to start at (or provide IndexStart)
	* @property {Number}  PageSize       The page size
	* @property {Number}  IndexStart     The record number to start at (or provide PageNumber)
	* @property {String}  SortBy         The field to sort by
	* @property {Boolean} SortDesc       Is the sort direction descending?
	*
	* @typedef  {Object}  CampaignSearchResponse
	* @property {PageInfo} PageInfo  PageInfo only needs the values ThisPageNumber && PageSize set
	* @property {Number}  SPID      
	* @property {Campaign[]} Campaigns 
	*
	* @typedef  {Object}   PageInfo
	* @property {Number}   PageNumber             The page number
	* @property {Number}   PageItemCount          Number of items on this page
	* @property {Number}   TotalPages             Total pages available
	* @property {Number}   TotalItemCount         Total items available
	* @property {Number}   IndexStart             The record number this search started at
	* @property {Number}   PageSize               The length of the page
	* @property {Number[]} DisplayPageList        The array of page numbers to display
	* @property {Number}   DisplayPagesEitherSide Number of pages to display either side
	*
	*/
	GetCampaigns({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Find)
		return axios.post(`/v1/${req.SPID}/campaigns`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			commit('mutSaveCampaigns', { Response: response.data })
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetCampaigns ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetCampaigns ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetCampaigns ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetCampaigns UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetCampaigns'))
			})
	},

	/**
	* GetVouchers: 
	* HTTP Method: POST
	* API Path: /api/v1/${req.SPID}/campaign/${req.CampaignUUID}/vouchers
	* @param    {VoucherSearchRequest} req The API payload object (VoucherSearchRequest)
	* @return   {VoucherSearchResponse} The API response object (VoucherSearchResponse)
	*
	* @typedef  {Object}  VoucherSearchRequest
	* @property {SearchOptions[]} SearchOptions    Search options to use (for sorting and/or pagination)
	* @property {Number}  SPID             
	* @property {String}  CampaignUUID     
	* @property {String}  CustomerUUID     
	* @property {Boolean} IncludeCampaigns 
	*
	* @typedef  {Object}  SearchOptions  Search options
	* @property {Number}  PageNumber     The page number to start at (or provide IndexStart)
	* @property {Number}  PageSize       The page size
	* @property {Number}  IndexStart     The record number to start at (or provide PageNumber)
	* @property {String}  SortBy         The field to sort by
	* @property {Boolean} SortDesc       Is the sort direction descending?
	*
	* @typedef  {Object}  VoucherSearchResponse
	* @property {PageInfo} PageInfo  PageInfo only needs the values ThisPageNumber && PageSize set
	* @property {Number}  SPID      
	* @property {Voucher[]} Vouchers  
	* @property {Campaign[]} Campaigns 
	*
	* @typedef  {Object}   PageInfo
	* @property {Number}   PageNumber             The page number
	* @property {Number}   PageItemCount          Number of items on this page
	* @property {Number}   TotalPages             Total pages available
	* @property {Number}   TotalItemCount         Total items available
	* @property {Number}   IndexStart             The record number this search started at
	* @property {Number}   PageSize               The length of the page
	* @property {Number[]} DisplayPageList        The array of page numbers to display
	* @property {Number}   DisplayPagesEitherSide Number of pages to display either side
	*
	*/
	GetVouchers({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Find)
		return axios.post(`/v1/${req.SPID}/campaign/${req.CampaignUUID}/vouchers`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			commit('mutSaveVouchers', { Response: response.data })
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetVouchers ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetVouchers ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetVouchers ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetVouchers UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetVouchers'))
			})
	},

	/**
	* UpdateCampaign: 
	* HTTP Method: PUT
	* API Path: /api/v1/${req.SPID}/campaign/${req.UUID}
	* @param    {Campaign} req The API payload object (Campaign)
	* @return   {Campaign} The API response object (Campaign)
	*
	* @typedef  {Object}  Campaign
	* @property {String}  UUID                      
	* @property {Number}  SPID                      
	* @property {String}  Name                      Name is the name of this campagn
	* @property {Number}  CreatedAtNanos            
	* @property {Number}  UpdatedAtNanos            
	* @property {Number}  DeletedAtNanos            
	* @property {Number}  ExpiresAtNanos            
	* @property {Number}  VouchersPerCustomer       VouchersPerCustomer is the number of vouchers that can be issued for each customer - by the customer themselves
	* @property {String}  LinkedProduct             LinkedProduct is if this campaign is linked to a particulat product
	* @property {String}  LinkedDiscount            LinkedDiscount is the product discount this campaign is linked to
	* @property {String}  Description               Description is the name of this campagn
	* @property {String}  Logo                      Logo displayed to the user
	* @property {Boolean} AutoAllocateSTBActivation If this is true then allocate when customer activates a STB
	* @property {Number}  StartsAtNanos             
	* @property {String}  AutoAllocateType          Type of autoallocation: stbaddition, tvpackagepurchase
	* @property {String}  AutoAllocateDetails       Autoallocation details: for package purchase, the package name
	* @property {Number}  Rank                      
	* @property {String}  SignupPopoverTitle        
	* @property {String}  SignupPopoverText         
	* @property {String}  SignupPopoverImage        
	* @property {String}  ClaimButtonText           
	* @property {String}  ReminderBannerText        
	* @property {String}  ReminderBannerButton      
	* @property {String}  PurchaseReminderText      
	*
	*/
	UpdateCampaign({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Update)
		return axios.put(`/v1/${req.SPID}/campaign/${req.UUID}`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveCampaign', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('UpdateCampaign ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('UpdateCampaign ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('UpdateCampaign ERROR:', error)
					return Promise.reject(error)
				}
				console.log('UpdateCampaign UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in UpdateCampaign'))
			})
	},
};

export default {
	namespaced: true,
	state: initialState,
	getters,
	mutations,
	actions,
};