// Code generated by protoc-gen-iqwebapi. DO NOT EDIT.
// source: simdb.proto

import axios from 'axios';
import StoreHash from '@/utils/StoreHash';

const initialState = {
	SIMS: {
		UUIDs: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
	SIMSearches: {
		SearchTexts: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
	SIMOrders: {
		UUIDs: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
	SIMOrderSearches: {
		SearchTexts: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
	SIMRanges: {
		UUIDs: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
};

const valFuncFind = function (status) {
	return (status == 200) ? true : (status == 404) ? true : false;
}

const default_pageinfo = {
	PageNumber: 0,
	PageItemCount: 0,
	TotalPages: 0,
	TotalItemCount: 0,
	IndexStart: 0,
	PageSize: 10,
	DisplayPageList: [],
	DisplayPageEitherSide: 4,
}

const getters = {
	getSIMByUUID: state => (UUID) => (state.SIMS.UUIDs[ UUID ] && !state.SIMS.UUIDs[ UUID ].expired()) ?  state.SIMS.UUIDs[ UUID ].hashedData() : null,
	getSIMSForSearchText: state => (SearchText) => state.SIMSearches[ SearchText ] ? state.SIMSearches[ SearchText ] : null,
	getSIMOrderByUUID: state => (UUID) => (state.SIMOrders.UUIDs[ UUID ] && !state.SIMOrders.UUIDs[ UUID ].expired()) ?  state.SIMOrders.UUIDs[ UUID ].hashedData() : null,
	getSIMOrdersForSearchText: state => (SearchText) => state.SIMOrderSearches[ SearchText ] ? state.SIMOrderSearches[ SearchText ] : null,
	getSIMRangeByUUID: state => (UUID) => (state.SIMRanges.UUIDs[ UUID ] && !state.SIMRanges.UUIDs[ UUID ].expired()) ?  state.SIMRanges.UUIDs[ UUID ].hashedData() : null,
};

const mutations = {
	// mutSaveSIM saves a single SIM object
	// into both the StoreHash cache and the VueX store
	mutSaveSIM(state, obj) {
		if (!obj.UUID) { return } // Must have UUID to Save

		if (!(obj.UUID in state.SIMS.UUIDs)) {
			state.SIMS.UUIDs[ obj.UUID ] = new StoreHash(state.SIMS.lifetime)
		}
		state.SIMS.UUIDs[ obj.UUID ].fix(obj) // Add item to StoreHash
	},
	// mutRemoveSIMByUUID removes a SIM object
	// with the given UUID from StoreHash cache and VueX store
	mutRemoveSIMByUUID(state, UUID) {
		if (!UUID) {
			// Must have UUID to Remove
			return
		}
		delete state.SIMS.UUIDs[ UUID ]    // remove item from StoreHash
	},
	// mutSaveSIMSearch saves a search response
	// with the given SearchText to the StoreHash cache and VueX store
	mutSaveSIMSearch(state, { SearchText, Response }) {
		if (!SearchText || !Response) {
			// Must have SearchText to Save and Response object
			return
		}
		let storedObj = {
			PageInfo: Object.assign({}, default_pageinfo), // shallow copy of default_pageinfo
			UUIDs: [],
		}
		// Deep copy ??? i.e. JSON.parse(JSON.stringify(obj));
		if (Response.PageInfo) {
			Response.PageInfo = Object.assign({}, Response.PageInfo) // shallow copy of the PageInfo
		}
		if (Array.isArray(Response.SIMS)) {
			for (let i = 0; i < Response.SIMS.length; i++) {
				storedObj.UUIDs.push(Response.SIMS[i].UUID)
			}
		}
		state.SIMSearches[ SearchText ] = storedObj      // Add search text and list of UUIDs to VueX state

		if (!(SearchText in state.SIMSearches.SearchTexts)) {
			state.SIMSearches.SearchTexts[ SearchText ] = new StoreHash(state.SIMSearches.lifetime)
		}
		state.SIMSearches.SearchTexts[ SearchText ].fix() // Add item to StoreHash
	},
	// mutRemoveSIMSearch removes a search response
	// with the given SearchText from StoreHash cache and VueX store
	mutRemoveSIMSearch(state, SearchText) {
		if (!SearchText) {
			// Must have SearchText to Remove
			return
		}
		state.SIMSearches[ SearchText ] = null               // cause VueX to trigger a data cascade
		delete state.SIMSearches[ SearchText ]               // remove item from VueX state
		delete state.SIMSearches.SearchTexts[ SearchText ]  // remove item from StoreHash
	},
	// mutSaveSIMS saves a paginated response of SIMS
	mutSaveSIMS(state, { Response }) {
		if (!Response || !Array.isArray(Response.SIMS)) {
			// Must have Response object with results
			return
		}
		for (let i = 0; i < Response.SIMS.length; i++) {
			if (!(Response.SIMS[i].UUID in state.SIMS.UUIDs)) {
				state.SIMS.UUIDs[ Response.SIMS[i].UUID ] = new StoreHash(state.SIMS.lifetime)
			}
			state.SIMS.UUIDs[ Response.SIMS[i].UUID ].fix(Response.SIMS[i])  // Add single SIM to StoreHash
		}
	},
	// mutSaveSIMOrder saves a single SIMOrder object
	// into both the StoreHash cache and the VueX store
	mutSaveSIMOrder(state, obj) {
		if (!obj.UUID) { return } // Must have UUID to Save

		if (!(obj.UUID in state.SIMOrders.UUIDs)) {
			state.SIMOrders.UUIDs[ obj.UUID ] = new StoreHash(state.SIMOrders.lifetime)
		}
		state.SIMOrders.UUIDs[ obj.UUID ].fix(obj) // Add item to StoreHash
	},
	// mutRemoveSIMOrderByUUID removes a SIMOrder object
	// with the given UUID from StoreHash cache and VueX store
	mutRemoveSIMOrderByUUID(state, UUID) {
		if (!UUID) {
			// Must have UUID to Remove
			return
		}
		delete state.SIMOrders.UUIDs[ UUID ]    // remove item from StoreHash
	},
	// mutSaveSIMOrderSearch saves a search response
	// with the given SearchText to the StoreHash cache and VueX store
	mutSaveSIMOrderSearch(state, { SearchText, Response }) {
		if (!SearchText || !Response) {
			// Must have SearchText to Save and Response object
			return
		}
		let storedObj = {
			PageInfo: Object.assign({}, default_pageinfo), // shallow copy of default_pageinfo
			UUIDs: [],
		}
		// Deep copy ??? i.e. JSON.parse(JSON.stringify(obj));
		if (Response.PageInfo) {
			Response.PageInfo = Object.assign({}, Response.PageInfo) // shallow copy of the PageInfo
		}
		if (Array.isArray(Response.SIMOrders)) {
			for (let i = 0; i < Response.SIMOrders.length; i++) {
				storedObj.UUIDs.push(Response.SIMOrders[i].UUID)
			}
		}
		state.SIMOrderSearches[ SearchText ] = storedObj      // Add search text and list of UUIDs to VueX state

		if (!(SearchText in state.SIMOrderSearches.SearchTexts)) {
			state.SIMOrderSearches.SearchTexts[ SearchText ] = new StoreHash(state.SIMOrderSearches.lifetime)
		}
		state.SIMOrderSearches.SearchTexts[ SearchText ].fix() // Add item to StoreHash
	},
	// mutRemoveSIMOrderSearch removes a search response
	// with the given SearchText from StoreHash cache and VueX store
	mutRemoveSIMOrderSearch(state, SearchText) {
		if (!SearchText) {
			// Must have SearchText to Remove
			return
		}
		state.SIMOrderSearches[ SearchText ] = null               // cause VueX to trigger a data cascade
		delete state.SIMOrderSearches[ SearchText ]               // remove item from VueX state
		delete state.SIMOrderSearches.SearchTexts[ SearchText ]  // remove item from StoreHash
	},
	// mutSaveSIMOrders saves a paginated response of SIMOrders
	mutSaveSIMOrders(state, { Response }) {
		if (!Response || !Array.isArray(Response.SIMOrders)) {
			// Must have Response object with results
			return
		}
		for (let i = 0; i < Response.SIMOrders.length; i++) {
			if (!(Response.SIMOrders[i].UUID in state.SIMOrders.UUIDs)) {
				state.SIMOrders.UUIDs[ Response.SIMOrders[i].UUID ] = new StoreHash(state.SIMOrders.lifetime)
			}
			state.SIMOrders.UUIDs[ Response.SIMOrders[i].UUID ].fix(Response.SIMOrders[i])  // Add single SIMOrder to StoreHash
		}
	},
	// mutSaveSIMRange saves a single SIMRange object
	// into both the StoreHash cache and the VueX store
	mutSaveSIMRange(state, obj) {
		if (!obj.UUID) { return } // Must have UUID to Save

		if (!(obj.UUID in state.SIMRanges.UUIDs)) {
			state.SIMRanges.UUIDs[ obj.UUID ] = new StoreHash(state.SIMRanges.lifetime)
		}
		state.SIMRanges.UUIDs[ obj.UUID ].fix(obj) // Add item to StoreHash
	},
	// mutRemoveSIMRangeByUUID removes a SIMRange object
	// with the given UUID from StoreHash cache and VueX store
	mutRemoveSIMRangeByUUID(state, UUID) {
		if (!UUID) {
			// Must have UUID to Remove
			return
		}
		delete state.SIMRanges.UUIDs[ UUID ]    // remove item from StoreHash
	},
	// mutSaveSIMRanges saves a paginated response of SIMRanges
	mutSaveSIMRanges(state, { Response }) {
		if (!Response || !Array.isArray(Response.SIMRanges)) {
			// Must have Response object with results
			return
		}
		for (let i = 0; i < Response.SIMRanges.length; i++) {
			if (!(Response.SIMRanges[i].UUID in state.SIMRanges.UUIDs)) {
				state.SIMRanges.UUIDs[ Response.SIMRanges[i].UUID ] = new StoreHash(state.SIMRanges.lifetime)
			}
			state.SIMRanges.UUIDs[ Response.SIMRanges[i].UUID ].fix(Response.SIMRanges[i])  // Add single SIMRange to StoreHash
		}
	},
};

const actions = {
	// getCachedSIMByUUID fetches a SIM from the cache
	// and if not in the cache, fetches it from the API
	getCachedSIMByUUID({ state, dispatch, commit }, { UUID, RefreshCache }) {
		if (!RefreshCache && state.SIMS.UUIDs[ UUID ] && !state.SIMS.UUIDs[ UUID ].expired()) {
			// Return the cached record
			return state.SIMS.UUIDs[ UUID ].hashedData()
		}
		// Fetch from the API
		return dispatch('GetSIMByUUID', { UUID }).then(respPayload => {
			commit('mutSaveSIM', respPayload)
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveSIMByUUID', UUID)
			return Promise.reject(error)
		})
	},

	// getCachedSIMSBySearch fetches SIMS from the cache
	// and if not in the cache, fetches it from the API
	getCachedSIMSBySearch({ state, getters, dispatch, commit }, { SPID, SearchText, RefreshCache }) {
		if (!RefreshCache && state.SIMSearches.SearchTexts[ SearchText ] && !state.SIMSearches.SearchTexts[ SearchText ].expired()) {
			// Return the cached records
			let resp = getters.getSIMSForSearchText(SearchText)
			resp.SIMS = []
			for (let i = 0; i < resp.UUIDs.length; i++) {
				resp.SIMS.push(getters.getSIMByUUID(resp.UUIDs[i]))
			}
			return Promise.resolve(resp)
		}
		// Search using the API
		const apiReq = {
			SPID: SPID,
			SmartSearch: SearchText,
		}
		return dispatch('FindSIMSPaginated', apiReq).then(respPayload => {
			commit('mutSaveSIMSearch', { SearchText: SearchText, Response: respPayload })
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveSIMSearch', SearchText)
			return Promise.reject(error)
		})
	},

	// getCachedSIMOrderByUUID fetches a SIMOrder from the cache
	// and if not in the cache, fetches it from the API
	getCachedSIMOrderByUUID({ state, dispatch, commit }, { UUID, RefreshCache }) {
		if (!RefreshCache && state.SIMOrders.UUIDs[ UUID ] && !state.SIMOrders.UUIDs[ UUID ].expired()) {
			// Return the cached record
			return state.SIMOrders.UUIDs[ UUID ].hashedData()
		}
		// Fetch from the API
		return dispatch('GetSIMOrderByUUID', { UUID }).then(respPayload => {
			commit('mutSaveSIMOrder', respPayload)
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveSIMOrderByUUID', UUID)
			return Promise.reject(error)
		})
	},

	// getCachedSIMOrdersBySearch fetches SIMOrders from the cache
	// and if not in the cache, fetches it from the API
	getCachedSIMOrdersBySearch({ state, getters, dispatch, commit }, { SPID, SearchText, RefreshCache }) {
		if (!RefreshCache && state.SIMOrderSearches.SearchTexts[ SearchText ] && !state.SIMOrderSearches.SearchTexts[ SearchText ].expired()) {
			// Return the cached records
			let resp = getters.getSIMOrdersForSearchText(SearchText)
			resp.SIMOrders = []
			for (let i = 0; i < resp.UUIDs.length; i++) {
				resp.SIMOrders.push(getters.getSIMOrderByUUID(resp.UUIDs[i]))
			}
			return Promise.resolve(resp)
		}
		// Search using the API
		const apiReq = {
			SPID: SPID,
			SmartSearch: SearchText,
		}
		return dispatch('FindSIMOrdersPaginated', apiReq).then(respPayload => {
			commit('mutSaveSIMOrderSearch', { SearchText: SearchText, Response: respPayload })
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveSIMOrderSearch', SearchText)
			return Promise.reject(error)
		})
	},

	// getCachedSIMRangeByUUID fetches a SIMRange from the cache
	// and if not in the cache, fetches it from the API
	getCachedSIMRangeByUUID({ state, dispatch, commit }, { UUID, RefreshCache }) {
		if (!RefreshCache && state.SIMRanges.UUIDs[ UUID ] && !state.SIMRanges.UUIDs[ UUID ].expired()) {
			// Return the cached record
			return state.SIMRanges.UUIDs[ UUID ].hashedData()
		}
		// Fetch from the API
		return dispatch('GetSIMRangeByUUID', { UUID }).then(respPayload => {
			commit('mutSaveSIMRange', respPayload)
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveSIMRangeByUUID', UUID)
			return Promise.reject(error)
		})
	},

	//
	// Service: SIMDBRPC
	// 
	/**
	* RPC Method: AddSIM
	* Description: Adds new SIMs for the SPID to the SIMDB
	* HTTP Method: POST
	* API Path: /api/v1/${req.SPID}/SIM
	* @param    {SIM} req The API payload object (SIM)
	* @return   {SIM} The API response object (SIM)
	*
	* @typedef  {Object}  SIM
	* @property {String}  UUID                
	* @property {Number}  SPID                
	* @property {Boolean} Enabled             If this SIM is enabled or not
	* @property {String}  ServiceInstanceUUID The ServiceInstanceUUID this SIM is being used by
	* @property {Number}  CreatedAtNanos      When the SIM entry was created
	* @property {Number}  UpdatedAtNanos      When the SIM entry was updated
	* @property {Number}  DeletedAtNanos      When the SIM entry was deleted
	* @property {String}  SIMID               This is the SIMID
	* @property {String}  IMSI                This is the IMSI programmed on the SIM
	* @property {StringSlice} Tags                Tags
	* @property {Boolean} Active              ------------------,   --- SIM RANGE ---,  ------------------
	* @property {String}  ICCID               This is the ICCID
	* @property {String}  PreAllocatedMSISDN  This is the (optional) pre-allocated MSISDN
	* @property {Boolean} Reserved            If this SIM is reserved or not (for example for pre-activation)
	* @property {Boolean} IsEsim              If this SIM is esim or physical sim
	* @property {String}  SIMRangeUUID        SIMRange association UUID
	*
	*/
	AddSIM({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Add)
		return axios.post(`/v1/${req.SPID}/SIM`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('AddSIM ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('AddSIM ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('AddSIM ERROR:', error)
					return Promise.reject(error)
				}
				console.log('AddSIM UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in AddSIM'))
			})
	},

	/**
	* RPC Method: AddSIMOrder
	* Description: Adds a new SIM order to the order list
	* HTTP Method: POST
	* API Path: /api/v1/${req.SPID}/SIM_order
	* @param    {SIMOrder} req The API payload object (SIMOrder)
	* @return   {SIMOrder} The API response object (SIMOrder)
	*
	* @typedef  {Object}  SIMOrder
	* @property {String}  UUID                     
	* @property {Number}  SPID                     
	* @property {Number}  CreatedAtNanos           When the SIM entry was created
	* @property {Number}  UpdatedAtNanos           When the SIM entry was updated
	* @property {Number}  DeletedAtNanos           When the SIM entry was deleted
	* @property {String}  SIMIDRangeStart          This is the SIMIDRangeStart
	* @property {String}  SIMIDRangeEnd            This is the SIMIDRangeEnd
	* @property {String}  IMSIRangeStart           This is the start of the IMSI range
	* @property {String}  IMSIRangeEnd             This is the end of the IMSI range
	* @property {Number}  SentToManufacturingNanos When the SIM Order was sent to the manufacturer
	* @property {Number}  LoadedIntoVaultNanos     When the SIM Order was loaded into the vault
	* @property {String}  Manufacturer             This is the IMSI programmed on the SIM
	* @property {String}  Description              This is the IMSI programmed on the SIM
	*
	*/
	AddSIMOrder({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Add)
		return axios.post(`/v1/${req.SPID}/SIM_order`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('AddSIMOrder ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('AddSIMOrder ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('AddSIMOrder ERROR:', error)
					return Promise.reject(error)
				}
				console.log('AddSIMOrder UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in AddSIMOrder'))
			})
	},

	/**
	* RPC Method: AddSIMRange
	* Description: Add a SIM range
	* HTTP Method: POST
	* API Path: /api/v3/sim_range
	* @param    {SIMRange} req The API payload object (SIMRange)
	* @return   {SIMRange} The API response object (SIMRange)
	*
	* @typedef  {Object}  SIMRange
	* @property {String}  UUID           Unique identifier
	* @property {Number}  SPID           Service provider ID
	* @property {Number}  CreatedAtNanos When the SIM range was created
	* @property {Number}  UpdatedAtNanos When the SIM range was updated
	* @property {Number}  DeletedAtNanos When the SIM range was deleted
	* @property {String}  ALGID          
	* @property {String}  KBDID          
	* @property {String}  ACSUB          
	* @property {String}  STK            
	* @property {String}  SIMRangeStart  
	* @property {String}  SIMRangeEnd    
	* @property {String}  IMSIRangeStart 
	* @property {String}  IMSIRangeEnd   
	*
	*/
	AddSIMRange({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Add)
		return axios.post(`/v3/sim_range`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('AddSIMRange ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('AddSIMRange ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('AddSIMRange ERROR:', error)
					return Promise.reject(error)
				}
				console.log('AddSIMRange UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in AddSIMRange'))
			})
	},

	/**
	* RPC Method: DeleteSIMRange
	* Description: Delete a SIM Range
	* HTTP Method: DELETE
	* API Path: /api/v3/sim_range/${req.UUID}
	* @param    {SIMRangeUUIDSearch} req The API payload object (SIMRangeUUIDSearch)
	* @return   {SIMRange} The API response object (SIMRange)
	*
	* @typedef  {Object}  SIMRangeUUIDSearch
	* @property {Number}  SPID 
	* @property {String}  UUID 
	*
	* @typedef  {Object}  SIMRange
	* @property {String}  UUID           Unique identifier
	* @property {Number}  SPID           Service provider ID
	* @property {Number}  CreatedAtNanos When the SIM range was created
	* @property {Number}  UpdatedAtNanos When the SIM range was updated
	* @property {Number}  DeletedAtNanos When the SIM range was deleted
	* @property {String}  ALGID          
	* @property {String}  KBDID          
	* @property {String}  ACSUB          
	* @property {String}  STK            
	* @property {String}  SIMRangeStart  
	* @property {String}  SIMRangeEnd    
	* @property {String}  IMSIRangeStart 
	* @property {String}  IMSIRangeEnd   
	*
	*/
	DeleteSIMRange({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Delete)
		return axios.delete(`/v3/sim_range/${req.UUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveSIMRange', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('DeleteSIMRange ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('DeleteSIMRange ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('DeleteSIMRange ERROR:', error)
					return Promise.reject(error)
				}
				console.log('DeleteSIMRange UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in DeleteSIMRange'))
			})
	},

	/**
	* RPC Method: FindSIMOrdersPaginated
	* Description: Fetch multiple SIMs, filtering by IMSI, SIMID and Tags
	* HTTP Method: GET
	* API Path: /api/v1/${req.SPID}/SIM_order
	* @param    {SIMOrderSearchRequest} req The API payload object (SIMOrderSearchRequest)
	* @return   {SIMOrderPageResponse} The API response object (SIMOrderPageResponse)
	*
	* @typedef  {Object}  SIMOrderSearchRequest
	* @property {SearchOptions[]} SearchOptions       
	* @property {Number}  SPID                
	* @property {String}  UUID                
	* @property {String}  SIMID               
	* @property {String}  IMSI                
	* @property {String}  SmartSearch         
	* @property {String}  ServiceInstanceUUID 
	* @property {String}  Tags                
	* @property {Boolean} Enabled             
	* @property {Boolean} Active              
	*
	* @typedef  {Object}  SearchOptions  Search options
	* @property {Number}  PageNumber     The page number to start at (or provide IndexStart)
	* @property {Number}  PageSize       The page size
	* @property {Number}  IndexStart     The record number to start at (or provide PageNumber)
	* @property {String}  SortBy         The field to sort by
	* @property {Boolean} SortDesc       Is the sort direction descending?
	*
	* @typedef  {Object}  SIMOrderPageResponse
	* @property {PageInfo} PageInfo  
	* @property {SIMOrder[]} SIMOrders 
	*
	* @typedef  {Object}   PageInfo
	* @property {Number}   PageNumber             The page number
	* @property {Number}   PageItemCount          Number of items on this page
	* @property {Number}   TotalPages             Total pages available
	* @property {Number}   TotalItemCount         Total items available
	* @property {Number}   IndexStart             The record number this search started at
	* @property {Number}   PageSize               The length of the page
	* @property {Number[]} DisplayPageList        The array of page numbers to display
	* @property {Number}   DisplayPagesEitherSide Number of pages to display either side
	*
	*/
	FindSIMOrdersPaginated({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Find)
		// Prepare URI params...
		let paramSPID = encodeURIComponent(req.SPID)
		return axios.get(`/v1/${paramSPID}/SIM_order`, { params: req, validateStatus: valFuncFind }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			commit('mutSaveSIMOrders', { Response: response.data })
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('FindSIMOrdersPaginated ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('FindSIMOrdersPaginated ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('FindSIMOrdersPaginated ERROR:', error)
					return Promise.reject(error)
				}
				console.log('FindSIMOrdersPaginated UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in FindSIMOrdersPaginated'))
			})
	},

	/**
	* RPC Method: FindSIMSAvailablePaginated
	* Description: Fetch multiple SIMs, filtering by IMSI, SIMID and Tags, limit to enabled and available
	* HTTP Method: GET
	* API Path: /api/v1/${req.SPID}/SIMSAvailable
	* @param    {SIMSearchRequest} req The API payload object (SIMSearchRequest)
	* @return   {SIMPageResponse} The API response object (SIMPageResponse)
	*
	* @typedef  {Object}  SIMSearchRequest
	* @property {SearchOptions[]} SearchOptions       
	* @property {Number}  SPID                
	* @property {String}  UUID                
	* @property {String}  SIMID               
	* @property {String}  IMSI                
	* @property {String}  SmartSearch         
	* @property {String}  ServiceInstanceUUID 
	* @property {String}  Tags                
	* @property {Boolean} Enabled             
	* @property {Boolean} Active              
	*
	* @typedef  {Object}  SIMPageResponse
	* @property {PageInfo} PageInfo 
	* @property {SIM[]} SIMS     
	*
	*/
	FindSIMSAvailablePaginated({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		// Prepare URI params...
		let paramSPID = encodeURIComponent(req.SPID)
		return axios.get(`/v1/${paramSPID}/SIMSAvailable`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('FindSIMSAvailablePaginated ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('FindSIMSAvailablePaginated ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('FindSIMSAvailablePaginated ERROR:', error)
					return Promise.reject(error)
				}
				console.log('FindSIMSAvailablePaginated UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in FindSIMSAvailablePaginated'))
			})
	},

	/**
	* RPC Method: FindSIMSPaginated
	* Description: Fetch multiple SIMs, filtering by IMSI, SIMID and Tags
	* HTTP Method: GET
	* API Path: /api/v1/${req.SPID}/SIM
	* @param    {SIMSearchRequest} req The API payload object (SIMSearchRequest)
	* @return   {SIMPageResponse} The API response object (SIMPageResponse)
	*
	* @typedef  {Object}  SIMSearchRequest
	* @property {SearchOptions[]} SearchOptions       
	* @property {Number}  SPID                
	* @property {String}  UUID                
	* @property {String}  SIMID               
	* @property {String}  IMSI                
	* @property {String}  SmartSearch         
	* @property {String}  ServiceInstanceUUID 
	* @property {String}  Tags                
	* @property {Boolean} Enabled             
	* @property {Boolean} Active              
	*
	* @typedef  {Object}  SearchOptions  Search options
	* @property {Number}  PageNumber     The page number to start at (or provide IndexStart)
	* @property {Number}  PageSize       The page size
	* @property {Number}  IndexStart     The record number to start at (or provide PageNumber)
	* @property {String}  SortBy         The field to sort by
	* @property {Boolean} SortDesc       Is the sort direction descending?
	*
	* @typedef  {Object}  SIMPageResponse
	* @property {PageInfo} PageInfo 
	* @property {SIM[]} SIMS     
	*
	* @typedef  {Object}   PageInfo
	* @property {Number}   PageNumber             The page number
	* @property {Number}   PageItemCount          Number of items on this page
	* @property {Number}   TotalPages             Total pages available
	* @property {Number}   TotalItemCount         Total items available
	* @property {Number}   IndexStart             The record number this search started at
	* @property {Number}   PageSize               The length of the page
	* @property {Number[]} DisplayPageList        The array of page numbers to display
	* @property {Number}   DisplayPagesEitherSide Number of pages to display either side
	*
	*/
	FindSIMSPaginated({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Find)
		// Prepare URI params...
		let paramSPID = encodeURIComponent(req.SPID)
		return axios.get(`/v1/${paramSPID}/SIM`, { params: req, validateStatus: valFuncFind }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			commit('mutSaveSIMS', { Response: response.data })
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('FindSIMSPaginated ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('FindSIMSPaginated ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('FindSIMSPaginated ERROR:', error)
					return Promise.reject(error)
				}
				console.log('FindSIMSPaginated UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in FindSIMSPaginated'))
			})
	},

	/**
	* RPC Method: GetSIMByUUID
	* Description: Get a single SIM
	* HTTP Method: GET
	* API Path: /api/v1/${req.SPID}/SIM/${req.UUID}
	* @param    {SIMUUIDSearch} req The API payload object (SIMUUIDSearch)
	* @return   {SIM} The API response object (SIM)
	*
	* @typedef  {Object}  SIMUUIDSearch
	* @property {Number}  SPID 
	* @property {String}  UUID 
	*
	* @typedef  {Object}  SIM
	* @property {String}  UUID                
	* @property {Number}  SPID                
	* @property {Boolean} Enabled             If this SIM is enabled or not
	* @property {String}  ServiceInstanceUUID The ServiceInstanceUUID this SIM is being used by
	* @property {Number}  CreatedAtNanos      When the SIM entry was created
	* @property {Number}  UpdatedAtNanos      When the SIM entry was updated
	* @property {Number}  DeletedAtNanos      When the SIM entry was deleted
	* @property {String}  SIMID               This is the SIMID
	* @property {String}  IMSI                This is the IMSI programmed on the SIM
	* @property {StringSlice} Tags                Tags
	* @property {Boolean} Active              ------------------,   --- SIM RANGE ---,  ------------------
	* @property {String}  ICCID               This is the ICCID
	* @property {String}  PreAllocatedMSISDN  This is the (optional) pre-allocated MSISDN
	* @property {Boolean} Reserved            If this SIM is reserved or not (for example for pre-activation)
	* @property {Boolean} IsEsim              If this SIM is esim or physical sim
	* @property {String}  SIMRangeUUID        SIMRange association UUID
	*
	*/
	GetSIMByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Get)
		// Prepare URI params...
		let paramSPID = encodeURIComponent(req.SPID)
		let paramUUID = encodeURIComponent(req.UUID)
		return axios.get(`/v1/${paramSPID}/SIM/${paramUUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveSIM', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetSIMByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetSIMByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetSIMByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetSIMByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetSIMByUUID'))
			})
	},

	/**
	* RPC Method: GetSIMOrderByUUID
	* Description: Get a single SIM order
	* HTTP Method: GET
	* API Path: /api/v1/${req.SPID}/SIM_order/${req.UUID}
	* @param    {SIMOrderUUIDSearch} req The API payload object (SIMOrderUUIDSearch)
	* @return   {SIMOrder} The API response object (SIMOrder)
	*
	* @typedef  {Object}  SIMOrderUUIDSearch
	* @property {Number}  SPID 
	* @property {String}  UUID 
	*
	* @typedef  {Object}  SIMOrder
	* @property {String}  UUID                     
	* @property {Number}  SPID                     
	* @property {Number}  CreatedAtNanos           When the SIM entry was created
	* @property {Number}  UpdatedAtNanos           When the SIM entry was updated
	* @property {Number}  DeletedAtNanos           When the SIM entry was deleted
	* @property {String}  SIMIDRangeStart          This is the SIMIDRangeStart
	* @property {String}  SIMIDRangeEnd            This is the SIMIDRangeEnd
	* @property {String}  IMSIRangeStart           This is the start of the IMSI range
	* @property {String}  IMSIRangeEnd             This is the end of the IMSI range
	* @property {Number}  SentToManufacturingNanos When the SIM Order was sent to the manufacturer
	* @property {Number}  LoadedIntoVaultNanos     When the SIM Order was loaded into the vault
	* @property {String}  Manufacturer             This is the IMSI programmed on the SIM
	* @property {String}  Description              This is the IMSI programmed on the SIM
	*
	*/
	GetSIMOrderByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Get)
		// Prepare URI params...
		let paramSPID = encodeURIComponent(req.SPID)
		let paramUUID = encodeURIComponent(req.UUID)
		return axios.get(`/v1/${paramSPID}/SIM_order/${paramUUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveSIMOrder', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetSIMOrderByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetSIMOrderByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetSIMOrderByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetSIMOrderByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetSIMOrderByUUID'))
			})
	},

	/**
	* RPC Method: GetSIMRangeByUUID
	* Description: Get a SIM Range by UUID
	* HTTP Method: GET
	* API Path: /api/v3/sim_range/${req.UUID}
	* @param    {SIMRangeUUIDSearch} req The API payload object (SIMRangeUUIDSearch)
	* @return   {SIMRange} The API response object (SIMRange)
	*
	* @typedef  {Object}  SIMRangeUUIDSearch
	* @property {Number}  SPID 
	* @property {String}  UUID 
	*
	* @typedef  {Object}  SIMRange
	* @property {String}  UUID           Unique identifier
	* @property {Number}  SPID           Service provider ID
	* @property {Number}  CreatedAtNanos When the SIM range was created
	* @property {Number}  UpdatedAtNanos When the SIM range was updated
	* @property {Number}  DeletedAtNanos When the SIM range was deleted
	* @property {String}  ALGID          
	* @property {String}  KBDID          
	* @property {String}  ACSUB          
	* @property {String}  STK            
	* @property {String}  SIMRangeStart  
	* @property {String}  SIMRangeEnd    
	* @property {String}  IMSIRangeStart 
	* @property {String}  IMSIRangeEnd   
	*
	*/
	GetSIMRangeByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Get)
		// Prepare URI params...
		let paramUUID = encodeURIComponent(req.UUID)
		return axios.get(`/v3/sim_range/${paramUUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveSIMRange', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetSIMRangeByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetSIMRangeByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetSIMRangeByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetSIMRangeByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetSIMRangeByUUID'))
			})
	},

	/**
	* RPC Method: GetSIMRangesPaginated
	* Description: Get multiple SIM Ranges by filters
	* HTTP Method: GET
	* API Path: /api/v3/sim_range
	* @param    {SIMRangeSearchRequest} req The API payload object (SIMRangeSearchRequest)
	* @return   {SIMRangePageResponse} The API response object (SIMRangePageResponse)
	*
	* @typedef  {Object}  SIMRangeSearchRequest
	* @property {SearchOptions[]} SearchOptions 
	* @property {String}  UUID          
	* @property {Number}  SPID          
	* @property {String}  ALGID         
	* @property {String}  KBDID         
	* @property {String}  ACSUB         
	* @property {String}  STK           
	*
	* @typedef  {Object}  SearchOptions  Search options
	* @property {Number}  PageNumber     The page number to start at (or provide IndexStart)
	* @property {Number}  PageSize       The page size
	* @property {Number}  IndexStart     The record number to start at (or provide PageNumber)
	* @property {String}  SortBy         The field to sort by
	* @property {Boolean} SortDesc       Is the sort direction descending?
	*
	* @typedef  {Object}  SIMRangePageResponse
	* @property {PageInfo} PageInfo  
	* @property {SIMRange[]} SIMRanges 
	*
	* @typedef  {Object}   PageInfo
	* @property {Number}   PageNumber             The page number
	* @property {Number}   PageItemCount          Number of items on this page
	* @property {Number}   TotalPages             Total pages available
	* @property {Number}   TotalItemCount         Total items available
	* @property {Number}   IndexStart             The record number this search started at
	* @property {Number}   PageSize               The length of the page
	* @property {Number[]} DisplayPageList        The array of page numbers to display
	* @property {Number}   DisplayPagesEitherSide Number of pages to display either side
	*
	*/
	GetSIMRangesPaginated({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Find)
		return axios.get(`/v3/sim_range`, { params: req, validateStatus: valFuncFind }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			commit('mutSaveSIMRanges', { Response: response.data })
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetSIMRangesPaginated ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetSIMRangesPaginated ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetSIMRangesPaginated ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetSIMRangesPaginated UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetSIMRangesPaginated'))
			})
	},

	/**
	* RPC Method: MigrateSIMsToSPID
	* Description: Update the details of a SIM
	* HTTP Method: POST
	* API Path: /api/v1/SIM_Migrate
	* @param    {MigrateSIMsRequest} req The API payload object (MigrateSIMsRequest)
	* @return   {MigrateSIMsResponse} The API response object (MigrateSIMsResponse)
	*
	* @typedef  {Object}  MigrateSIMsRequest
	* @property {String}  SIMIDStartRange The start of the SIMID range to move
	* @property {String}  SIMIDEndRange   The end of the SIMID range to move
	* @property {Number}  SPID            The SPID to move the SIMs onto
	*
	* @typedef  {Object}  MigrateSIMsResponse
	* @property {Number}  SuccessfullyMoved 
	* @property {Number}  FailedToMove      
	*
	*/
	MigrateSIMsToSPID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.post(`/v1/SIM_Migrate`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('MigrateSIMsToSPID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('MigrateSIMsToSPID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('MigrateSIMsToSPID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('MigrateSIMsToSPID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in MigrateSIMsToSPID'))
			})
	},

	/**
	* RPC Method: UpdateSIM
	* Description: Update the details of a SIM
	* HTTP Method: PUT
	* API Path: /api/v1/${req.SPID}/SIM/${req.UUID}
	* @param    {SIM} req The API payload object (SIM)
	* @return   {SIM} The API response object (SIM)
	*
	* @typedef  {Object}  SIM
	* @property {String}  UUID                
	* @property {Number}  SPID                
	* @property {Boolean} Enabled             If this SIM is enabled or not
	* @property {String}  ServiceInstanceUUID The ServiceInstanceUUID this SIM is being used by
	* @property {Number}  CreatedAtNanos      When the SIM entry was created
	* @property {Number}  UpdatedAtNanos      When the SIM entry was updated
	* @property {Number}  DeletedAtNanos      When the SIM entry was deleted
	* @property {String}  SIMID               This is the SIMID
	* @property {String}  IMSI                This is the IMSI programmed on the SIM
	* @property {StringSlice} Tags                Tags
	* @property {Boolean} Active              ------------------,   --- SIM RANGE ---,  ------------------
	* @property {String}  ICCID               This is the ICCID
	* @property {String}  PreAllocatedMSISDN  This is the (optional) pre-allocated MSISDN
	* @property {Boolean} Reserved            If this SIM is reserved or not (for example for pre-activation)
	* @property {Boolean} IsEsim              If this SIM is esim or physical sim
	* @property {String}  SIMRangeUUID        SIMRange association UUID
	*
	*/
	UpdateSIM({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Update)
		return axios.put(`/v1/${req.SPID}/SIM/${req.UUID}`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveSIM', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('UpdateSIM ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('UpdateSIM ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('UpdateSIM ERROR:', error)
					return Promise.reject(error)
				}
				console.log('UpdateSIM UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in UpdateSIM'))
			})
	},

	/**
	* RPC Method: UpdateSIMOrder
	* Description: Update the details of a SIM order
	* HTTP Method: PUT
	* API Path: /api/v1/${req.SPID}/SIM_order/${req.UUID}
	* @param    {SIMOrder} req The API payload object (SIMOrder)
	* @return   {SIMOrder} The API response object (SIMOrder)
	*
	* @typedef  {Object}  SIMOrder
	* @property {String}  UUID                     
	* @property {Number}  SPID                     
	* @property {Number}  CreatedAtNanos           When the SIM entry was created
	* @property {Number}  UpdatedAtNanos           When the SIM entry was updated
	* @property {Number}  DeletedAtNanos           When the SIM entry was deleted
	* @property {String}  SIMIDRangeStart          This is the SIMIDRangeStart
	* @property {String}  SIMIDRangeEnd            This is the SIMIDRangeEnd
	* @property {String}  IMSIRangeStart           This is the start of the IMSI range
	* @property {String}  IMSIRangeEnd             This is the end of the IMSI range
	* @property {Number}  SentToManufacturingNanos When the SIM Order was sent to the manufacturer
	* @property {Number}  LoadedIntoVaultNanos     When the SIM Order was loaded into the vault
	* @property {String}  Manufacturer             This is the IMSI programmed on the SIM
	* @property {String}  Description              This is the IMSI programmed on the SIM
	*
	*/
	UpdateSIMOrder({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Update)
		return axios.put(`/v1/${req.SPID}/SIM_order/${req.UUID}`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveSIMOrder', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('UpdateSIMOrder ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('UpdateSIMOrder ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('UpdateSIMOrder ERROR:', error)
					return Promise.reject(error)
				}
				console.log('UpdateSIMOrder UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in UpdateSIMOrder'))
			})
	},

	/**
	* RPC Method: UpdateSIMRange
	* Description: Update a SIM range
	* HTTP Method: PUT
	* API Path: /api/v3/sim_range/${req.UUID}
	* @param    {SIMRange} req The API payload object (SIMRange)
	* @return   {SIMRange} The API response object (SIMRange)
	*
	* @typedef  {Object}  SIMRange
	* @property {String}  UUID           Unique identifier
	* @property {Number}  SPID           Service provider ID
	* @property {Number}  CreatedAtNanos When the SIM range was created
	* @property {Number}  UpdatedAtNanos When the SIM range was updated
	* @property {Number}  DeletedAtNanos When the SIM range was deleted
	* @property {String}  ALGID          
	* @property {String}  KBDID          
	* @property {String}  ACSUB          
	* @property {String}  STK            
	* @property {String}  SIMRangeStart  
	* @property {String}  SIMRangeEnd    
	* @property {String}  IMSIRangeStart 
	* @property {String}  IMSIRangeEnd   
	*
	*/
	UpdateSIMRange({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Update)
		return axios.put(`/v3/sim_range/${req.UUID}`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveSIMRange', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('UpdateSIMRange ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('UpdateSIMRange ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('UpdateSIMRange ERROR:', error)
					return Promise.reject(error)
				}
				console.log('UpdateSIMRange UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in UpdateSIMRange'))
			})
	},

	/**
	* RPC Method: UploadSIMRange
	* Description: Import SIM range from uploaded pgp encrypted file, expecting file to be Base64 encoded.
	* HTTP Method: POST
	* API Path: /api/v3/sim_range/upload
	* @param    {UploadSIMRangeRequest} req The API payload object (UploadSIMRangeRequest)
	* @return   {UploadSIMRangeResponse} The API response object (UploadSIMRangeResponse)
	*
	* @typedef  {Object}  UploadSIMRangeRequest
	* @property {Number}  SPID Default Service Provider ID
	* @property {Object}  Data Base64 encoded string file content
	*
	* @typedef  {Object}  UploadSIMRangeResponse
	* @property {UploadSIMRangeResponse_SimResultsEntry[]} SimResults import result of each included sim
	*
	*/
	UploadSIMRange({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.post(`/v3/sim_range/upload`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('UploadSIMRange ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('UploadSIMRange ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('UploadSIMRange ERROR:', error)
					return Promise.reject(error)
				}
				console.log('UploadSIMRange UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in UploadSIMRange'))
			})
	},
};

export default {
	namespaced: true,
	state: initialState,
	getters,
	mutations,
	actions,
};