// Code generated by protoc-gen-iqwebapi. DO NOT EDIT.
// source: charging.proto

import axios from 'axios';
import StoreHash from '@/utils/StoreHash';

const initialState = {
	ChargeRequests: {
		UUIDs: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
	ChargeResponses: {
		UUIDs: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
	RefundRequests: {
		UUIDs: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
	RefundResponses: {
		UUIDs: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
};

const valFuncFind = function (status) {
	return (status == 200) ? true : (status == 404) ? true : false;
}

const default_pageinfo = {
	PageNumber: 0,
	PageItemCount: 0,
	TotalPages: 0,
	TotalItemCount: 0,
	IndexStart: 0,
	PageSize: 10,
	DisplayPageList: [],
	DisplayPageEitherSide: 4,
}

const getters = {
	getChargeRequestByUUID: state => (UUID) => (state.ChargeRequests.UUIDs[ UUID ] && !state.ChargeRequests.UUIDs[ UUID ].expired()) ?  state.ChargeRequests.UUIDs[ UUID ].hashedData() : null,
	getChargeResponseByUUID: state => (UUID) => (state.ChargeResponses.UUIDs[ UUID ] && !state.ChargeResponses.UUIDs[ UUID ].expired()) ?  state.ChargeResponses.UUIDs[ UUID ].hashedData() : null,
	getRefundRequestByUUID: state => (UUID) => (state.RefundRequests.UUIDs[ UUID ] && !state.RefundRequests.UUIDs[ UUID ].expired()) ?  state.RefundRequests.UUIDs[ UUID ].hashedData() : null,
	getRefundResponseByUUID: state => (UUID) => (state.RefundResponses.UUIDs[ UUID ] && !state.RefundResponses.UUIDs[ UUID ].expired()) ?  state.RefundResponses.UUIDs[ UUID ].hashedData() : null,
};

const mutations = {
	// mutSaveChargeRequest saves a single ChargeRequest object
	// into both the StoreHash cache and the VueX store
	mutSaveChargeRequest(state, obj) {
		if (!obj.UUID) { return } // Must have UUID to Save

		if (!(obj.UUID in state.ChargeRequests.UUIDs)) {
			state.ChargeRequests.UUIDs[ obj.UUID ] = new StoreHash(state.ChargeRequests.lifetime)
		}
		state.ChargeRequests.UUIDs[ obj.UUID ].fix(obj) // Add item to StoreHash
	},
	// mutRemoveChargeRequestByUUID removes a ChargeRequest object
	// with the given UUID from StoreHash cache and VueX store
	mutRemoveChargeRequestByUUID(state, UUID) {
		if (!UUID) {
			// Must have UUID to Remove
			return
		}
		delete state.ChargeRequests.UUIDs[ UUID ]    // remove item from StoreHash
	},
	// mutSaveChargeResponse saves a single ChargeResponse object
	// into both the StoreHash cache and the VueX store
	mutSaveChargeResponse(state, obj) {
		if (!obj.UUID) { return } // Must have UUID to Save

		if (!(obj.UUID in state.ChargeResponses.UUIDs)) {
			state.ChargeResponses.UUIDs[ obj.UUID ] = new StoreHash(state.ChargeResponses.lifetime)
		}
		state.ChargeResponses.UUIDs[ obj.UUID ].fix(obj) // Add item to StoreHash
	},
	// mutRemoveChargeResponseByUUID removes a ChargeResponse object
	// with the given UUID from StoreHash cache and VueX store
	mutRemoveChargeResponseByUUID(state, UUID) {
		if (!UUID) {
			// Must have UUID to Remove
			return
		}
		delete state.ChargeResponses.UUIDs[ UUID ]    // remove item from StoreHash
	},
	// mutSaveRefundRequest saves a single RefundRequest object
	// into both the StoreHash cache and the VueX store
	mutSaveRefundRequest(state, obj) {
		if (!obj.UUID) { return } // Must have UUID to Save

		if (!(obj.UUID in state.RefundRequests.UUIDs)) {
			state.RefundRequests.UUIDs[ obj.UUID ] = new StoreHash(state.RefundRequests.lifetime)
		}
		state.RefundRequests.UUIDs[ obj.UUID ].fix(obj) // Add item to StoreHash
	},
	// mutRemoveRefundRequestByUUID removes a RefundRequest object
	// with the given UUID from StoreHash cache and VueX store
	mutRemoveRefundRequestByUUID(state, UUID) {
		if (!UUID) {
			// Must have UUID to Remove
			return
		}
		delete state.RefundRequests.UUIDs[ UUID ]    // remove item from StoreHash
	},
	// mutSaveRefundResponse saves a single RefundResponse object
	// into both the StoreHash cache and the VueX store
	mutSaveRefundResponse(state, obj) {
		if (!obj.UUID) { return } // Must have UUID to Save

		if (!(obj.UUID in state.RefundResponses.UUIDs)) {
			state.RefundResponses.UUIDs[ obj.UUID ] = new StoreHash(state.RefundResponses.lifetime)
		}
		state.RefundResponses.UUIDs[ obj.UUID ].fix(obj) // Add item to StoreHash
	},
	// mutRemoveRefundResponseByUUID removes a RefundResponse object
	// with the given UUID from StoreHash cache and VueX store
	mutRemoveRefundResponseByUUID(state, UUID) {
		if (!UUID) {
			// Must have UUID to Remove
			return
		}
		delete state.RefundResponses.UUIDs[ UUID ]    // remove item from StoreHash
	},
};

const actions = {
	// getCachedChargeRequestByUUID fetches a ChargeRequest from the cache
	// and if not in the cache, fetches it from the API
	getCachedChargeRequestByUUID({ state, dispatch, commit }, { UUID, RefreshCache }) {
		if (!RefreshCache && state.ChargeRequests.UUIDs[ UUID ] && !state.ChargeRequests.UUIDs[ UUID ].expired()) {
			// Return the cached record
			return state.ChargeRequests.UUIDs[ UUID ].hashedData()
		}
		// Fetch from the API
		return dispatch('GetChargeRequestByUUID', { UUID }).then(respPayload => {
			commit('mutSaveChargeRequest', respPayload)
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveChargeRequestByUUID', UUID)
			return Promise.reject(error)
		})
	},

	// getCachedChargeResponseByUUID fetches a ChargeResponse from the cache
	// and if not in the cache, fetches it from the API
	getCachedChargeResponseByUUID({ state, dispatch, commit }, { UUID, RefreshCache }) {
		if (!RefreshCache && state.ChargeResponses.UUIDs[ UUID ] && !state.ChargeResponses.UUIDs[ UUID ].expired()) {
			// Return the cached record
			return state.ChargeResponses.UUIDs[ UUID ].hashedData()
		}
		// Fetch from the API
		return dispatch('GetChargeResponseByUUID', { UUID }).then(respPayload => {
			commit('mutSaveChargeResponse', respPayload)
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveChargeResponseByUUID', UUID)
			return Promise.reject(error)
		})
	},

	// getCachedRefundRequestByUUID fetches a RefundRequest from the cache
	// and if not in the cache, fetches it from the API
	getCachedRefundRequestByUUID({ state, dispatch, commit }, { UUID, RefreshCache }) {
		if (!RefreshCache && state.RefundRequests.UUIDs[ UUID ] && !state.RefundRequests.UUIDs[ UUID ].expired()) {
			// Return the cached record
			return state.RefundRequests.UUIDs[ UUID ].hashedData()
		}
		// Fetch from the API
		return dispatch('GetRefundRequestByUUID', { UUID }).then(respPayload => {
			commit('mutSaveRefundRequest', respPayload)
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveRefundRequestByUUID', UUID)
			return Promise.reject(error)
		})
	},

	// getCachedRefundResponseByUUID fetches a RefundResponse from the cache
	// and if not in the cache, fetches it from the API
	getCachedRefundResponseByUUID({ state, dispatch, commit }, { UUID, RefreshCache }) {
		if (!RefreshCache && state.RefundResponses.UUIDs[ UUID ] && !state.RefundResponses.UUIDs[ UUID ].expired()) {
			// Return the cached record
			return state.RefundResponses.UUIDs[ UUID ].hashedData()
		}
		// Fetch from the API
		return dispatch('GetRefundResponseByUUID', { UUID }).then(respPayload => {
			commit('mutSaveRefundResponse', respPayload)
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveRefundResponseByUUID', UUID)
			return Promise.reject(error)
		})
	},

	//
	// Service: ChargeRPC
	// 
	/**
	* BraintreeVerifyPaymentToken: 
	* HTTP Method: POST
	* API Path: /api/v1/braintree_verify_token
	* @param    {TokenVerifyReq} req The API payload object (TokenVerifyReq)
	* @return   {TokenVerifyResp} The API response object (TokenVerifyResp)
	*
	* @typedef  {Object}  TokenVerifyReq
	*
	* @typedef  {Object}  TokenVerifyResp
	*
	*/
	BraintreeVerifyPaymentToken({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.post(`/v1/braintree_verify_token`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('BraintreeVerifyPaymentToken ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('BraintreeVerifyPaymentToken ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('BraintreeVerifyPaymentToken ERROR:', error)
					return Promise.reject(error)
				}
				console.log('BraintreeVerifyPaymentToken UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in BraintreeVerifyPaymentToken'))
			})
	},

	/**
	* GetClientToken: 
	* HTTP Method: POST
	* API Path: /api/v1/get_client_token
	* @param    {PaymentProviderTokenReq} req The API payload object (PaymentProviderTokenReq)
	* @return   {TokenString} The API response object (TokenString)
	*
	* @typedef  {Object}  PaymentProviderTokenReq
	*
	* @typedef  {Object}  TokenString
	*
	*/
	GetClientToken({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.post(`/v1/get_client_token`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetClientToken ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetClientToken ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetClientToken ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetClientToken UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetClientToken'))
			})
	},

	/**
	* GetPayouts: 
	* HTTP Method: POST
	* API Path: /api/v1/${req.SPID}/payouts
	* @param    {PayoutSearchRequest} req The API payload object (PayoutSearchRequest)
	* @return   {PayoutSearchResponse} The API response object (PayoutSearchResponse)
	*
	* @typedef  {Object}  PayoutSearchRequest
	* @property {Number}  SPID                  
	* @property {Number}  GeneratedStartAtNanos 
	* @property {Number}  GeneratedEndAtNanos   
	* @property {Number}  AvailableStartAtNanos 
	* @property {Number}  AvailableEndAtNanos   
	*
	* @typedef  {Object}  PayoutSearchResponse
	* @property {Payout[]} Payouts 
	*
	*/
	GetPayouts({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.post(`/v1/${req.SPID}/payouts`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetPayouts ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetPayouts ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetPayouts ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetPayouts UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetPayouts'))
			})
	},

	/**
	* GetRemoteCharge: 
	* HTTP Method: GET
	* API Path: /api/v2/${req.SPID}/customer/${req.CustomerUUID}/charge/${req.UUID}
	* @param    {ChargeSearchRequest} req The API payload object (ChargeSearchRequest)
	* @return   {ChargeResponse} The API response object (ChargeResponse)
	*
	* @typedef  {Object}  ChargeSearchRequest
	* @property {SearchOptions[]} SearchOptions             Search options to use (for sorting and/or pagination)
	* @property {String}  UUID                      
	* @property {String}  CustomerUUID              
	* @property {String}  PaymentMethodUUID         
	* @property {Number}  RequestedFromNanos        
	* @property {Number}  RequestedToNanos          
	* @property {String}  RefundUUID                
	* @property {String}  ProviderTransactionID     
	* @property {String}  PaymentProviderCustomerID 
	* @property {ENUM}    PaymentProvider           
	* @property {Number}  SPID                      
	* @property {String}  ProviderInvoiceID         
	*
	* @typedef  {Object}  ChargeResponse
	* @property {String}  UUID                                   
	* @property {Number}  SPID                                   
	* @property {String}  CustomerUUID                           
	* @property {String}  PaymentMethodUUID                      
	* @property {ENUM}    PaymentProvider                        
	* @property {Number}  Cents                                  
	* @property {ENUM}    PaymentStatus                          
	* @property {String}  SPGLCode                               
	* @property {Number}  RequestedAtNanos                       
	* @property {Number}  StartedAtNanos                         
	* @property {Number}  FinishedAtNanos                        
	* @property {Number}  LastErrorNanos                         
	* @property {String}  LastErrorMessage                       
	* @property {ENUM}    ChargeError                            
	* @property {String}  RefundUUID                             
	* @property {String}  ProviderTransactionID                  
	* @property {String}  ProviderStatus                         
	* @property {String}  ProviderSettlementBatchID              
	* @property {String}  ProviderSettlementDisbursementDate     
	* @property {String}  ProviderSettlementAmount               
	* @property {String}  ProviderSettlementCurrencyISOCode      
	* @property {String}  ProviderSettlementCurrencyExchangeRate 
	* @property {Number}  ProviderSettlementFundsHeld            
	* @property {Number}  ProviderSettlementSuccess              
	* @property {String}  ProviderRefundID                       
	* @property {String}  ProviderRefundedTransactionID          
	* @property {Number}  ProviderResponseCode                   
	* @property {String}  ProviderResponseText                   
	* @property {String}  ProviderAuthorizationCode              
	* @property {String}  Description                            
	* @property {String}  OrderID                                
	* @property {String}  SubscriptionUUID                       
	* @property {String}  PaymentProviderSubID                   
	* @property {String}  PaymentProviderCustomerID              
	* @property {String}  PaymentProviderPayMethID               
	* @property {String}  MetaTags                               
	* @property {String}  ProviderInvoiceID                      
	* @property {String}  ProviderRefundIDs                      
	* @property {String}  ProviderInvoiceNumber                  
	*
	*/
	GetRemoteCharge({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.get(`/v2/${req.SPID}/customer/${req.CustomerUUID}/charge/${req.UUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetRemoteCharge ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetRemoteCharge ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetRemoteCharge ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetRemoteCharge UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetRemoteCharge'))
			})
	},

	/**
	* GetRemoteChargesPaginated: 
	* HTTP Method: GET
	* API Path: /api/v2/${req.SPID}/customer/${req.CustomerUUID}/charge
	* @param    {ChargeSearchRequest} req The API payload object (ChargeSearchRequest)
	* @return   {ChargePageResponse} The API response object (ChargePageResponse)
	*
	* @typedef  {Object}  ChargeSearchRequest
	* @property {SearchOptions[]} SearchOptions             Search options to use (for sorting and/or pagination)
	* @property {String}  UUID                      
	* @property {String}  CustomerUUID              
	* @property {String}  PaymentMethodUUID         
	* @property {Number}  RequestedFromNanos        
	* @property {Number}  RequestedToNanos          
	* @property {String}  RefundUUID                
	* @property {String}  ProviderTransactionID     
	* @property {String}  PaymentProviderCustomerID 
	* @property {ENUM}    PaymentProvider           
	* @property {Number}  SPID                      
	* @property {String}  ProviderInvoiceID         
	*
	* @typedef  {Object}  ChargePageResponse
	* @property {PageInfo} PageInfo PageInfo only needs the values ThisPageNumber && PageSize set
	* @property {ChargeResponse[]} Charges  
	*
	*/
	GetRemoteChargesPaginated({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.get(`/v2/${req.SPID}/customer/${req.CustomerUUID}/charge`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetRemoteChargesPaginated ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetRemoteChargesPaginated ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetRemoteChargesPaginated ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetRemoteChargesPaginated UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetRemoteChargesPaginated'))
			})
	},

	/**
	* Refund: 
	* HTTP Method: DELETE
	* API Path: /api/v2/${req.SPID}/customer/${req.CustomerUUID}/charge/${req.UUID}
	* @param    {RefundRequest} req The API payload object (RefundRequest)
	* @return   {RefundResponse} The API response object (RefundResponse)
	*
	* @typedef  {Object}  RefundRequest
	* @property {String}  UUID                  
	* @property {Number}  SPID                  
	* @property {String}  CustomerUUID          
	* @property {String}  ChargeUUID            
	* @property {String}  SPGLCode              
	* @property {ENUM}    PaymentProvider       
	* @property {String}  ProviderTransactionID 
	*
	* @typedef  {Object}  RefundResponse
	* @property {String}  UUID                      
	* @property {Number}  SPID                      
	* @property {String}  CustomerUUID              
	* @property {String}  ChargeUUID                
	* @property {String}  PaymentMethodUUID         
	* @property {ENUM}    PaymentProvider           
	* @property {Number}  Cents                     
	* @property {Number}  RequestedAtNanos          
	* @property {Number}  StartedAtNanos            
	* @property {Number}  FinishedAtNanos           
	* @property {String}  OrigProviderTransactionID 
	* @property {String}  OrigProviderResponse      
	* @property {String}  ProviderTransactionID     
	* @property {String}  ProviderResponse          
	* @property {Number}  LastErrorNanos            
	* @property {String}  LastErrorMessage          
	* @property {String}  PaymentProviderSubID      
	* @property {String}  PaymentProviderCustomerID 
	* @property {String}  SPGLCode                  
	* @property {String}  ProviderRefundIDs         
	*
	*/
	Refund({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.delete(`/v2/${req.SPID}/customer/${req.CustomerUUID}/charge/${req.UUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('Refund ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('Refund ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('Refund ERROR:', error)
					return Promise.reject(error)
				}
				console.log('Refund UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in Refund'))
			})
	},
};

export default {
	namespaced: true,
	state: initialState,
	getters,
	mutations,
	actions,
};