import axios from 'axios'
import axiosUnique from '@/utils/axiosUnique'

import StoreHash from '@/utils/StoreHash'

let hashed = {
    radius_sessions: {
        uuids: {},
        lifetime: 1 * 60 * 1000, // 1 min
    },
}

const state = {
    radius_sessions: {},
    radius_sessions_filter: null,
}

const getters = {
    getRadiusSessionByUUID: state => uuid => state.radius_sessions[uuid],

    radius_sessions_filter: state => state.radius_sessions_filter,
}

const mutations = {
    saveRadiusSessions(state, sessions) {
        let sessions_list = {}

        for (let n = 0, len = sessions.length; n < len; n++) {
            const session = sessions[n]
            const uuid = session.UUID

            if (!(uuid in hashed.radius_sessions.uuids)) {
                hashed.radius_sessions.uuids[ uuid ] = new StoreHash(hashed.radius_sessions.lifetime)
            }
            
            sessions_list[ uuid ] = session

            hashed.radius_sessions.uuids[ uuid ].fix()
        }

        if (Object.keys(sessions_list).length) {
            state.radius_sessions = {
                ...state.radius_sessions,
                ...sessions_list
            }
        }
    },

    saveRadiusSession(state, session) {
        const uuid = session.UUID

        if (!(uuid in hashed.radius_sessions.uuids)) {
            hashed.radius_sessions.uuids[ uuid ] = new StoreHash(hashed.radius_sessions.lifetime)
        }

        state.radius_sessions = {
            ...state.radius_sessions,
            [ uuid ]: session
        }

        hashed.radius_sessions.uuids[ uuid ].fix()
    },

    saveRadiusSessionsFilter(state, filter) {
        state.radius_sessions_filter = filter
    },    
    clearRadiusSessionsFilter(state) {
        state.radius_sessions_filter = null
    },

    resetHashedRadiusSessions(state) {
        state.radius_sessions = {}

        hashed.radius_sessions.uuids = {}
    },
}

const actions = {
    getRadiusSessions({commit, dispatch}, { params, filter }) {
        /**
         * @todo Replace 2 corresponding lines below for real data
         */
        return import('./hardcoded-test-data/radius/sessions/radius-sessions-list.json').then(response => {
            const sessions = response.default.Sessions

        // return axiosUnique.get(`/v2/:spid/radius/sessions`, { params }).then(response => {
        //     const sessions = response.apidata.Sessions

            commit('saveRadiusSessions', sessions)

            const action = filter ? 'saveRadiusSessionsFilter' : 'clearRadiusSessionsFilter'
            const resolve = () => Promise.resolve(response)

            return dispatch(action, filter).then(resolve).catch(resolve)
        }).catch(error => {
            return Promise.reject(error)
        })
    },

    getRadiusSessionByUUID({getters, commit, dispatch}, { uuid, refresh }) {
        if (!(uuid in hashed.radius_sessions.uuids)) {
			hashed.radius_sessions.uuids[ uuid ] = new StoreHash(hashed.radius_sessions.lifetime)
        }

        if (refresh || hashed.radius_sessions.uuids[ uuid ].expired()) {
            /**
             * @todo Replace 3 corresponding lines below for real data
             */
            return import('./hardcoded-test-data/radius/sessions/radius-session-by-uuid.json').then(apidata => {
                const session = apidata.default
                uuid = session.UUID
                
            // return axiosUnique.get(`/v2/:spid/radius/session/${ uuid }`).then(({apidata}) => {
            //     const session = apidata

                commit('saveRadiusSession', session)
                return Promise.resolve(getters.getRadiusSessionByUUID(uuid))
            }).catch(error => {
                return Promise.reject(error)
            })
        } else {
            return Promise.resolve(getters.getRadiusSessionByUUID(uuid))
        }
    },

    loadRadiusSessionsFilter({getters}) {
        const filter = getters.radius_sessions_filter

        return filter
            ? Promise.resolve(filter)
            : Promise.reject(filter)
    },
    saveRadiusSessionsFilter({commit, getters}, filter) {
        commit('saveRadiusSessionsFilter', filter)
        return Promise.resolve(getters.radius_sessions_filter)
    },
    clearRadiusSessionsFilter({commit, getters}) {
        commit('clearRadiusSessionsFilter')
        return Promise.resolve(getters.radius_sessions_filter)
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
}