import Vue from 'vue'

import axios from 'axios'
import axiosUnique from '@/utils/axiosUnique'

import StoreHash from '@/utils/StoreHash'

let hashed = {
    brandings: {
        spids: {},
        lifetime: 1 * 60 * 1000, // 1 min
    },
}

const state = {
    brandings: {},
    brandings_extended: {},
}

const getters = {
    getBrandingBySPID: state => spid => state.brandings[spid],
    getBrandingExtendedBySPID: state => spid => state.brandings_extended[spid],
}

const mutations = {
    saveBranding(state, { spid, scheme, updating }) {
        if (updating && spid in state.brandings) {
            const scheme_spid = state.brandings[spid]

            for (const key in scheme) {
                if (key in scheme_spid) {
                    let colors = []

                    for (let i = 0, len = scheme[key].length; i < len; i++) {
                        colors.push(scheme[key][i] || scheme_spid[key][i])
                    }

                    Vue.set(state.brandings[spid], key, colors)
                } else {
                    Vue.set(state.brandings[spid], key, scheme[key])
                }
            }
        } else {
            if (!(spid in hashed.brandings.spids)) {
                hashed.brandings.spids[ spid ] = new StoreHash(hashed.brandings.lifetime)
            }

            Vue.set(state.brandings, spid, scheme)

            hashed.brandings.spids[ spid ].fix()
        }
    },

    saveBrandingExtended(state, { spid, scheme, updating }) {
        let scheme_saving = null

        if (updating && spid in state.brandings_extended) {
            const scheme_length = scheme.length

            if (scheme_length) {
                scheme_saving = state.brandings_extended[spid]

                for (let i = 0; i < scheme_length; i++) {
                    const index = scheme_saving.findIndex(color => color.Key == scheme[i].Key)

                    if (index > -1) {
                        scheme_saving[index] = scheme[i]
                    } else {
                        scheme_saving.push(scheme[i])
                    }
                }
            }
        } else {
            scheme_saving = scheme
        }

        if (scheme_saving) {
            for (let i = 0, len = scheme_saving.length; i < len; i++) {
                delete scheme_saving[i].SPID
                delete scheme_saving[i].KeyGroup
                delete scheme_saving[i].CreatedAt
                delete scheme_saving[i].UpdatedAt
                delete scheme_saving[i].DeletedAt
            }

            Vue.set(state.brandings_extended, spid, scheme_saving)
        }
    },

    removeBrandingItem (state, { spid, scheme }) {
        if (spid in state.brandings) {
            for (const key in scheme) {
                if (key in state.brandings[spid]) {
                    for (let i = 0, len = scheme[key].length; i < len; i++) {
                        if (scheme[key][i]) {
                            state.brandings[spid][key].splice(i, 1, scheme[key][i])
                        }
                    }
                }
            }
        }
    },

    saveBrandingExtendedItem (state, { spid, uuid }) {
        if (spid in state.brandings_extended) {
            const index = state.brandings_extended[spid].findIndex(color => color.UUID == uuid)

            if (index > -1) {
                state.brandings_extended[spid].splice(index, 1)
            }
        }
    },

    resetHashedBrandings(state) {
        state.brandings = {}

        hashed.brandings.spids = {}
    },
}

const actions = {
    getBrandingBySPID({getters, commit, dispatch}, { spid, refresh }) {
        if (!(spid in hashed.brandings.spids)) {
			hashed.brandings.spids[ spid ] = new StoreHash(hashed.brandings.lifetime)
        }

        if (refresh || hashed.brandings.spids[ spid ].expired()) {
            const payload = {
                SPID: spid,
                KeyGroup: getters.color_scheme_key_group,

                'SearchOptions.PageNumber': -1,
            }
                
            return dispatch('api_serviceprovider/FindServiceProviderSettingsPaginated', payload).then(({ServiceProviderSettings}) => {
                const scheme = getters.colorSchemeExtendedToCompact(ServiceProviderSettings, true)

                commit('saveBranding', { spid, scheme })
                commit('saveBrandingExtended', { spid, scheme: ServiceProviderSettings })

                return Promise.resolve(getters.getBrandingBySPID(spid))
            }).catch(error => {
                return Promise.reject(error)
            })
        } else {
            return Promise.resolve(getters.getBrandingBySPID(spid))
        }
    },


   
    saveBranding({getters, commit, dispatch}, { spid, scheme }) {
        // // console.group('saveBranding({getters, commit}, { spid, scheme }) {')
        // // console.log('spid', spid)
        // // console.log('scheme', {...scheme})

        const extended_color_scheme = getters.getBrandingExtendedBySPID(spid) || []
        const original_color_scheme = getters.colorSchemeExtendedToCompact(extended_color_scheme, false)
        // // console.log('original_color_scheme', {...original_color_scheme})

        const new_color_scheme = {}
        const changed_color_scheme = {}
        const removed_color_scheme = {}
        
        const root_color_scheme = getters.root_color_scheme
        // // console.log('root_color_scheme', {...root_color_scheme})

        for (const key in scheme) {
            if (key in original_color_scheme) {
                for (let i = 0, len = scheme[key].length; i < len; i++) {
                    if (scheme[key][i] != original_color_scheme[key][i]) {
                        let target = null

                        if (key in root_color_scheme && scheme[key][i] == root_color_scheme[key][i]) {
                            if (original_color_scheme[key][i]) {
                                target = removed_color_scheme
                            }
                        } else {
                            target = original_color_scheme[key][i] ? changed_color_scheme : new_color_scheme
                        }

                        if (target) {
                            if (!(key in target)) {
                                target[key] = new Array(len)
                            }

                            target[key][i] = scheme[key][i]
                        }
                    }
                }
            } else {
                new_color_scheme[key] = scheme[key]
            }
        }

        // // console.log('new_color_scheme', {...new_color_scheme})
        // // console.log('changed_color_scheme', {...changed_color_scheme})
        // // console.log('removed_color_scheme', {...removed_color_scheme})

        const branding_changes = []
        const TEST_branding_changes = {}

        const with_new_scheme = Object.keys(new_color_scheme).length > 0
        const with_changed_scheme = Object.keys(changed_color_scheme).length > 0
        const with_removed_scheme = Object.keys(removed_color_scheme).length > 0

        if (with_new_scheme) {
            const new_scheme = getters.colorSchemeCompactToExtended(new_color_scheme)

            TEST_branding_changes['created'] = new_scheme
            branding_changes.push( dispatch('createBrandingScheme', { spid, scheme: new_scheme }) )
        }

        if (with_changed_scheme || with_removed_scheme) {
            let extended_color_scheme_uuids = {}

            for (let i = 0, len = extended_color_scheme.length; i < len; i++) {
                extended_color_scheme_uuids[ extended_color_scheme[i].Key ] = extended_color_scheme[i].UUID
            }

            if (with_changed_scheme) {
                const changed_scheme = getters.colorSchemeCompactToExtended(changed_color_scheme)

                for (let i = 0, len = changed_scheme.length; i < len; i++) {
                    changed_scheme[i].UUID = extended_color_scheme_uuids[ changed_scheme[i].Key ]
                }
                
                TEST_branding_changes['changed'] = changed_scheme
                branding_changes.push( dispatch('updateBrandingScheme', { spid, scheme: changed_scheme }) )
            }

            if (with_removed_scheme) {
                const removed_scheme = getters.colorSchemeCompactToExtended(removed_color_scheme)

                TEST_branding_changes['removed'] = []
                for (let i = 0, len = removed_scheme.length; i < len; i++) {
                    removed_scheme[i].UUID = extended_color_scheme_uuids[ removed_scheme[i].Key ]
                }

                TEST_branding_changes['removed'] = removed_scheme
                branding_changes.push( dispatch('deleteBrandingScheme', { spid, scheme: removed_scheme }) )
            }
        }

        // // console.warn('TEST_branding_changes', TEST_branding_changes)
        // // console.warn('branding_changes', branding_changes)

        const payload = getters.colorSchemeCompactToExtended(changed_color_scheme, spid)
        // // console.log('payload', payload)

        // // console.groupEnd()

        return branding_changes.length
            ? Promise.all(branding_changes)
                .then(() => getters.getBrandingBySPID(spid))
                .catch(() => getters.getBrandingBySPID(spid))
            : getters.getBrandingBySPID(spid)
    },

    createBrandingScheme({getters, commit, dispatch}, { spid, scheme }) {
        // // console.group('createBrandingScheme({getters, commit}, { spid, scheme }) {')
        // // console.log('spid', spid)
        // // console.log('scheme', scheme)

        const payload = {
            SPID: spid,
            ServiceProviderSettings: scheme,
        }

        // // console.log('payload', payload)
        // // console.groupEnd()

        return dispatch('api_serviceprovider/AddServiceProviderSettings', payload).then(({ServiceProviderSettings}) => {
            const scheme_created = getters.colorSchemeExtendedToCompact(ServiceProviderSettings, false, true)

            commit('saveBranding', { spid, scheme: scheme_created, updating: true })
            commit('saveBrandingExtended', { spid, scheme: ServiceProviderSettings, updating: true })

            return getters.getBrandingBySPID(spid)
        }).catch(error => Promise.reject(error))
    },

    updateBrandingScheme({getters, commit, dispatch}, { spid, scheme }) {
        // console.group('updateBrandingScheme({getters, commit}, { spid, scheme }) {')
        // console.log('spid', spid)
        // console.log('scheme', scheme)

        const payload = {
            SPID: spid,
            ServiceProviderSettings: scheme,
        }
        
        // console.groupEnd()

        return dispatch('api_serviceprovider/UpdateServiceProviderSettings', payload).then(({ServiceProviderSettings}) => {
            const scheme_updated = getters.colorSchemeExtendedToCompact(ServiceProviderSettings, false, true)

            commit('saveBranding', { spid, scheme: scheme_updated, updating: true })
            commit('saveBrandingExtended', { spid, scheme: ServiceProviderSettings, updating: true })

            return getters.getBrandingBySPID(spid)
        }).catch(error => Promise.reject(error))
    },

    deleteBrandingScheme({getters, commit, dispatch}, { spid, scheme }) {
        // console.group('deleteBrandingScheme({getters, commit}, { spid, scheme }) {')
        // console.log('spid', spid)
        // console.log('scheme', scheme)

        const removeBranding = (removed_item) =>
            dispatch('api_serviceprovider/DeleteServiceProviderSetting', { UUID: removed_item.UUID }).then(() => {
                const scheme_removed = getters.colorSchemeExtendedToCompact([removed_item], false, true)
                // console.warn('removed_item', removed_item)

                commit('removeBrandingItem', { spid, scheme: scheme_removed })
                commit('saveBrandingExtendedItem', { spid, uuid: removed_item.UUID })

                return Promise.resolve(removed_item.UUID)
            }).catch(error => Promise.reject(error))

        const colors_removal = []
        for (let i = 0, len = scheme.length; i < len; i++) {
            colors_removal.push( removeBranding(scheme[i]) )
        }
        
        // console.groupEnd()

        return colors_removal.length
            ? Promise.all(colors_removal)
                .then(() => getters.getBrandingBySPID(spid))
                .catch(() => getters.getBrandingBySPID(spid))
            : getters.getBrandingBySPID(spid)
    },



    /**
     * Импорт файла со стилями брендинга в БД
     * Достаточно переименовать .scss или .sass файл цветовой схемы в .scheme с указанием SPID в имени (напр. _spid-11.scheme)
     */
    importBrandingFromFile({getters, dispatch}) {
        const branding_saving = []

        const file_paths = require.context('../../assets/scss/themes', false, /.scheme/)

        file_paths.keys().forEach(path => {
            const match = /.*spid-(\d+).*/i.exec(path)

            if (match) {
                const spid = +match[1]
                
                if (spid) {
                    const scheme_plain = file_paths(path).default

                    const file_styles = {}
                    
                    const pattern = /(--color-[^:]*):[^#\w]*((?:#(?:(?:[a-f0-9]{6,8})|(?:[a-f0-9]{3,4})))|transparent|(?:rgba?\([\d,.\s]*\)))/gim

                    let color = null
                    while (color = pattern.exec(scheme_plain)) {
                        const [text, key, hex] = color

                        file_styles[key] = hex
                    }
                    
                    if (Object.keys(file_styles).length) {
                        const root_styles = getters.colorSchemeCompactToVariables(getters.root_color_scheme)
                        const file_scheme = getters.colorSchemeExtendedToCompact([], true, false, {...root_styles, ...file_styles})

                        branding_saving.push( dispatch('saveBranding', { spid, scheme: file_scheme }) )
                    }
                }
            }
        })

        return branding_saving.length
            ? Promise.all(branding_saving)
            : Promise.reject(new Error('No files with color schemes'))
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
}