// Code generated by protoc-gen-iqwebapi. DO NOT EDIT.
// source: paymentmethod.proto

import axios from 'axios';
import StoreHash from '@/utils/StoreHash';

const initialState = {
	PaymentMethods: {
		UUIDs: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
};

const valFuncFind = function (status) {
	return (status == 200) ? true : (status == 404) ? true : false;
}

const default_pageinfo = {
	PageNumber: 0,
	PageItemCount: 0,
	TotalPages: 0,
	TotalItemCount: 0,
	IndexStart: 0,
	PageSize: 10,
	DisplayPageList: [],
	DisplayPageEitherSide: 4,
}

const getters = {
	getPaymentMethodByUUID: state => (UUID) => (state.PaymentMethods.UUIDs[ UUID ] && !state.PaymentMethods.UUIDs[ UUID ].expired()) ?  state.PaymentMethods.UUIDs[ UUID ].hashedData() : null,
};

const mutations = {
	// mutSavePaymentMethod saves a single PaymentMethod object
	// into both the StoreHash cache and the VueX store
	mutSavePaymentMethod(state, obj) {
		if (!obj.UUID) { return } // Must have UUID to Save

		if (!(obj.UUID in state.PaymentMethods.UUIDs)) {
			state.PaymentMethods.UUIDs[ obj.UUID ] = new StoreHash(state.PaymentMethods.lifetime)
		}
		state.PaymentMethods.UUIDs[ obj.UUID ].fix(obj) // Add item to StoreHash
	},
	// mutRemovePaymentMethodByUUID removes a PaymentMethod object
	// with the given UUID from StoreHash cache and VueX store
	mutRemovePaymentMethodByUUID(state, UUID) {
		if (!UUID) {
			// Must have UUID to Remove
			return
		}
		delete state.PaymentMethods.UUIDs[ UUID ]    // remove item from StoreHash
	},
	// mutSavePaymentMethods saves a paginated response of PaymentMethods
	mutSavePaymentMethods(state, { Response }) {
		if (!Response || !Array.isArray(Response.PaymentMethods)) {
			// Must have Response object with results
			return
		}
		for (let i = 0; i < Response.PaymentMethods.length; i++) {
			if (!(Response.PaymentMethods[i].UUID in state.PaymentMethods.UUIDs)) {
				state.PaymentMethods.UUIDs[ Response.PaymentMethods[i].UUID ] = new StoreHash(state.PaymentMethods.lifetime)
			}
			state.PaymentMethods.UUIDs[ Response.PaymentMethods[i].UUID ].fix(Response.PaymentMethods[i])  // Add single PaymentMethod to StoreHash
		}
	},
};

const actions = {
	// getCachedPaymentMethodByUUID fetches a PaymentMethod from the cache
	// and if not in the cache, fetches it from the API
	getCachedPaymentMethodByUUID({ state, dispatch, commit }, { UUID, RefreshCache }) {
		if (!RefreshCache && state.PaymentMethods.UUIDs[ UUID ] && !state.PaymentMethods.UUIDs[ UUID ].expired()) {
			// Return the cached record
			return state.PaymentMethods.UUIDs[ UUID ].hashedData()
		}
		// Fetch from the API
		return dispatch('GetPaymentMethodByUUID', { UUID }).then(respPayload => {
			commit('mutSavePaymentMethod', respPayload)
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemovePaymentMethodByUUID', UUID)
			return Promise.reject(error)
		})
	},

	//
	// Service: PaymentMethodRPC
	// 
	/**
	* AddPaymentMethod: 
	* HTTP Method: POST
	* API Path: /api/v2/${req.SPID}/customer/${req.CustomerUUID}/payment_method
	* @param    {PaymentMethod} req The API payload object (PaymentMethod)
	* @return   {PaymentMethod} The API response object (PaymentMethod)
	*
	* @typedef  {Object}  PaymentMethod
	* @property {String}  UUID                      
	* @property {Number}  SPID                      
	* @property {String}  CustomerUUID              rpc GetPaymentMethods(PaymentMethodSearchRequest) returns (stream PaymentMethod) {,  }
	* @property {Number}  CreatedAtNanos            
	* @property {Number}  UpdatedAtNanos            
	* @property {Number}  DeletedAtNanos            
	* @property {ENUM}    PaymentProvider           
	* @property {String}  CardType                  
	* @property {String}  CardName                  
	* @property {String}  CardLast4                 
	* @property {Number}  CardExpiryMonth           
	* @property {Number}  CardExpiryYear            
	* @property {Number}  CardExpiredAfterNanos     
	* @property {String}  Nonce                     
	* @property {String}  Token                     
	* @property {Number}  LastErrorNanos            
	* @property {String}  LastErrorMessage          
	* @property {Number}  Rank                      
	* @property {Number}  LastUsedNanos             
	* @property {String}  PaymentProviderCustomerID 
	*
	*/
	AddPaymentMethod({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Add)
		return axios.post(`/v2/${req.SPID}/customer/${req.CustomerUUID}/payment_method`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('AddPaymentMethod ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('AddPaymentMethod ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('AddPaymentMethod ERROR:', error)
					return Promise.reject(error)
				}
				console.log('AddPaymentMethod UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in AddPaymentMethod'))
			})
	},

	/**
	* DeletePaymentMethod: 
	* HTTP Method: DELETE
	* API Path: /api/v2/${req.SPID}/customer/${req.CustomerUUID}/payment_method/${req.UUID}
	* @param    {PaymentMethod} req The API payload object (PaymentMethod)
	* @return   {PaymentMethod} The API response object (PaymentMethod)
	*
	* @typedef  {Object}  PaymentMethod
	* @property {String}  UUID                      
	* @property {Number}  SPID                      
	* @property {String}  CustomerUUID              rpc GetPaymentMethods(PaymentMethodSearchRequest) returns (stream PaymentMethod) {,  }
	* @property {Number}  CreatedAtNanos            
	* @property {Number}  UpdatedAtNanos            
	* @property {Number}  DeletedAtNanos            
	* @property {ENUM}    PaymentProvider           
	* @property {String}  CardType                  
	* @property {String}  CardName                  
	* @property {String}  CardLast4                 
	* @property {Number}  CardExpiryMonth           
	* @property {Number}  CardExpiryYear            
	* @property {Number}  CardExpiredAfterNanos     
	* @property {String}  Nonce                     
	* @property {String}  Token                     
	* @property {Number}  LastErrorNanos            
	* @property {String}  LastErrorMessage          
	* @property {Number}  Rank                      
	* @property {Number}  LastUsedNanos             
	* @property {String}  PaymentProviderCustomerID 
	*
	*/
	DeletePaymentMethod({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Delete)
		return axios.delete(`/v2/${req.SPID}/customer/${req.CustomerUUID}/payment_method/${req.UUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSavePaymentMethod', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('DeletePaymentMethod ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('DeletePaymentMethod ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('DeletePaymentMethod ERROR:', error)
					return Promise.reject(error)
				}
				console.log('DeletePaymentMethod UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in DeletePaymentMethod'))
			})
	},

	/**
	* GetPaymentMethodByUUID: 
	* HTTP Method: GET
	* API Path: /api/v2/${req.SPID}/customer/${req.CustomerUUID}/payment_method/${req.UUID}
	* @param    {PaymentMethodUUIDSearch} req The API payload object (PaymentMethodUUIDSearch)
	* @return   {PaymentMethod} The API response object (PaymentMethod)
	*
	* @typedef  {Object}  PaymentMethodUUIDSearch
	* @property {Number}  SPID         
	* @property {String}  UUID         
	* @property {String}  CustomerUUID 
	*
	* @typedef  {Object}  PaymentMethod
	* @property {String}  UUID                      
	* @property {Number}  SPID                      
	* @property {String}  CustomerUUID              rpc GetPaymentMethods(PaymentMethodSearchRequest) returns (stream PaymentMethod) {,  }
	* @property {Number}  CreatedAtNanos            
	* @property {Number}  UpdatedAtNanos            
	* @property {Number}  DeletedAtNanos            
	* @property {ENUM}    PaymentProvider           
	* @property {String}  CardType                  
	* @property {String}  CardName                  
	* @property {String}  CardLast4                 
	* @property {Number}  CardExpiryMonth           
	* @property {Number}  CardExpiryYear            
	* @property {Number}  CardExpiredAfterNanos     
	* @property {String}  Nonce                     
	* @property {String}  Token                     
	* @property {Number}  LastErrorNanos            
	* @property {String}  LastErrorMessage          
	* @property {Number}  Rank                      
	* @property {Number}  LastUsedNanos             
	* @property {String}  PaymentProviderCustomerID 
	*
	*/
	GetPaymentMethodByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Get)
		return axios.get(`/v2/${req.SPID}/customer/${req.CustomerUUID}/payment_method/${req.UUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSavePaymentMethod', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetPaymentMethodByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetPaymentMethodByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetPaymentMethodByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetPaymentMethodByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetPaymentMethodByUUID'))
			})
	},

	/**
	* GetPaymentMethodsPaginated: 
	* HTTP Method: GET
	* API Path: /api/v2/${req.SPID}/customer/${req.CustomerUUID}/payment_method
	* @param    {PaymentMethodSearchRequest} req The API payload object (PaymentMethodSearchRequest)
	* @return   {PaymentMethodPageResponse} The API response object (PaymentMethodPageResponse)
	*
	* @typedef  {Object}  PaymentMethodSearchRequest
	* @property {SearchOptions[]} SearchOptions             Search options to use (for sorting and/or pagination)
	* @property {Number}  SPID                      
	* @property {String}  CustomerUUID              
	* @property {ENUM}    PaymentProvider           
	* @property {Number}  CardExpiryMonth           
	* @property {Number}  CardExpiryYear            
	* @property {Number}  CardExpiredAfterNanos     
	* @property {String}  Nonce                     
	* @property {String}  Token                     
	* @property {String}  PaymentProviderCustomerID 
	* @property {Boolean} IncludeDeleted            
	*
	* @typedef  {Object}  SearchOptions  Search options
	* @property {Number}  PageNumber     The page number to start at (or provide IndexStart)
	* @property {Number}  PageSize       The page size
	* @property {Number}  IndexStart     The record number to start at (or provide PageNumber)
	* @property {String}  SortBy         The field to sort by
	* @property {Boolean} SortDesc       Is the sort direction descending?
	*
	* @typedef  {Object}  PaymentMethodPageResponse
	* @property {PageInfo} PageInfo       PageInfo only needs the values ThisPageNumber && PageSize set
	* @property {PaymentMethod[]} PaymentMethods 
	*
	* @typedef  {Object}   PageInfo
	* @property {Number}   PageNumber             The page number
	* @property {Number}   PageItemCount          Number of items on this page
	* @property {Number}   TotalPages             Total pages available
	* @property {Number}   TotalItemCount         Total items available
	* @property {Number}   IndexStart             The record number this search started at
	* @property {Number}   PageSize               The length of the page
	* @property {Number[]} DisplayPageList        The array of page numbers to display
	* @property {Number}   DisplayPagesEitherSide Number of pages to display either side
	*
	*/
	GetPaymentMethodsPaginated({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Find)
		return axios.get(`/v2/${req.SPID}/customer/${req.CustomerUUID}/payment_method`, { params: req, validateStatus: valFuncFind }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			commit('mutSavePaymentMethods', { Response: response.data })
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetPaymentMethodsPaginated ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetPaymentMethodsPaginated ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetPaymentMethodsPaginated ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetPaymentMethodsPaginated UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetPaymentMethodsPaginated'))
			})
	},

	/**
	* SetPaymentMethod: 
	* HTTP Method: PUT
	* API Path: /api/v2/${req.SPID}/customer/${req.CustomerUUID}/payment_method/${req.UUID}
	* @param    {PaymentMethod} req The API payload object (PaymentMethod)
	* @return   {PaymentMethod} The API response object (PaymentMethod)
	*
	* @typedef  {Object}  PaymentMethod
	* @property {String}  UUID                      
	* @property {Number}  SPID                      
	* @property {String}  CustomerUUID              rpc GetPaymentMethods(PaymentMethodSearchRequest) returns (stream PaymentMethod) {,  }
	* @property {Number}  CreatedAtNanos            
	* @property {Number}  UpdatedAtNanos            
	* @property {Number}  DeletedAtNanos            
	* @property {ENUM}    PaymentProvider           
	* @property {String}  CardType                  
	* @property {String}  CardName                  
	* @property {String}  CardLast4                 
	* @property {Number}  CardExpiryMonth           
	* @property {Number}  CardExpiryYear            
	* @property {Number}  CardExpiredAfterNanos     
	* @property {String}  Nonce                     
	* @property {String}  Token                     
	* @property {Number}  LastErrorNanos            
	* @property {String}  LastErrorMessage          
	* @property {Number}  Rank                      
	* @property {Number}  LastUsedNanos             
	* @property {String}  PaymentProviderCustomerID 
	*
	*/
	SetPaymentMethod({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Update)
		return axios.put(`/v2/${req.SPID}/customer/${req.CustomerUUID}/payment_method/${req.UUID}`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSavePaymentMethod', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('SetPaymentMethod ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('SetPaymentMethod ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('SetPaymentMethod ERROR:', error)
					return Promise.reject(error)
				}
				console.log('SetPaymentMethod UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in SetPaymentMethod'))
			})
	},
};

export default {
	namespaced: true,
	state: initialState,
	getters,
	mutations,
	actions,
};