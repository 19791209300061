import Vue from 'vue'
import Vuex from 'vuex'
import VueCookies from 'vue-cookies'

import modules from './modules'

Vue.use(Vuex)
Vue.use(VueCookies)

const SPID = process.env.VUE_APP_SPID
const IDENTITY_PROVIDER = process.env.VUE_APP_IDENTITY_PROVIDER
const THEME = process.env.VUE_APP_THEME == 'plan-b'
    ? 'pb'
    : process.env.VUE_APP_THEME == 'iqhive' ? 'iq' : 'vd'

export default new Vuex.Store({
    modules,

    state: {},

    getters: {
        spid: () => SPID,
        identity_provider: () => IDENTITY_PROVIDER,
        theme: () => THEME,
    },

    mutations: {},
    actions: {}
})