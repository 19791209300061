// const randomInteger = (min, max) => {
//     const rand = min - 0.5 + Math.random() * (max - min + 1);
//     return Math.round(rand);
// }

const randomInteger = (min, max) => {
    const rand = min + Math.random() * (max + 1 - min)
    return Math.floor(rand)
}

export default randomInteger