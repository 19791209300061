// Code generated by protoc-gen-iqwebapi. DO NOT EDIT.
// source: token.proto

import axios from 'axios';
import StoreHash from '@/utils/StoreHash';

const initialState = {
	Tokens: {
		UUIDs: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
};

const valFuncFind = function (status) {
	return (status == 200) ? true : (status == 404) ? true : false;
}

const default_pageinfo = {
	PageNumber: 0,
	PageItemCount: 0,
	TotalPages: 0,
	TotalItemCount: 0,
	IndexStart: 0,
	PageSize: 10,
	DisplayPageList: [],
	DisplayPageEitherSide: 4,
}

const getters = {
	getTokenByUUID: state => (UUID) => (state.Tokens.UUIDs[ UUID ] && !state.Tokens.UUIDs[ UUID ].expired()) ?  state.Tokens.UUIDs[ UUID ].hashedData() : null,
};

const mutations = {
	// mutSaveToken saves a single Token object
	// into both the StoreHash cache and the VueX store
	mutSaveToken(state, obj) {
		if (!obj.UUID) { return } // Must have UUID to Save

		if (!(obj.UUID in state.Tokens.UUIDs)) {
			state.Tokens.UUIDs[ obj.UUID ] = new StoreHash(state.Tokens.lifetime)
		}
		state.Tokens.UUIDs[ obj.UUID ].fix(obj) // Add item to StoreHash
	},
	// mutRemoveTokenByUUID removes a Token object
	// with the given UUID from StoreHash cache and VueX store
	mutRemoveTokenByUUID(state, UUID) {
		if (!UUID) {
			// Must have UUID to Remove
			return
		}
		delete state.Tokens.UUIDs[ UUID ]    // remove item from StoreHash
	},
};

const actions = {
	// getCachedTokenByUUID fetches a Token from the cache
	// and if not in the cache, fetches it from the API
	getCachedTokenByUUID({ state, dispatch, commit }, { UUID, RefreshCache }) {
		if (!RefreshCache && state.Tokens.UUIDs[ UUID ] && !state.Tokens.UUIDs[ UUID ].expired()) {
			// Return the cached record
			return state.Tokens.UUIDs[ UUID ].hashedData()
		}
		// Fetch from the API
		return dispatch('GetTokenByUUID', { UUID }).then(respPayload => {
			commit('mutSaveToken', respPayload)
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveTokenByUUID', UUID)
			return Promise.reject(error)
		})
	},

	//
	// Service: TokenRPC
	// 
	/**
	* GenerateToken: 
	* HTTP Method: POST
	* API Path: /api/v2/${req.SPID}/new_token
	* @param    {GenerateTokenRequest} req The API payload object (GenerateTokenRequest)
	* @return   {Token} The API response object (Token)
	*
	* @typedef  {Object}  GenerateTokenRequest
	* @property {ENUM}    TokenType       
	* @property {Number}  SPID            
	* @property {String}  CustomerUUID    
	* @property {Number}  ValidFromNanos  
	* @property {Number}  ValidUntilNanos 
	* @property {String}  KeyName         
	* @property {String}  Data            
	* @property {String}  SendingSystem   
	* @property {Number}  TimeRequested   
	*
	* @typedef  {Object}  Token
	* @property {String}  UUID            
	* @property {ENUM}    TokenType       
	* @property {Number}  SPID            
	* @property {String}  CustomerUUID    
	* @property {Number}  ValidFromNanos  
	* @property {Number}  ValidUntilNanos 
	* @property {String}  KeyName         
	* @property {String}  Data            
	* @property {String}  SendingSystem   
	* @property {Number}  TimeRequested   
	* @property {Number}  TimeInvalidated 
	*
	*/
	GenerateToken({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.post(`/v2/${req.SPID}/new_token`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GenerateToken ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GenerateToken ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GenerateToken ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GenerateToken UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GenerateToken'))
			})
	},

	/**
	* GetToken: 
	* HTTP Method: GET
	* API Path: /api/v2/${req.SPID}/token/${req.KeyName}
	* @param    {GetTokenRequest} req The API payload object (GetTokenRequest)
	* @return   {Token} The API response object (Token)
	*
	* @typedef  {Object}  GetTokenRequest
	* @property {ENUM}    TokenType    
	* @property {Number}  SPID         
	* @property {String}  CustomerUUID 
	* @property {String}  UUID         
	* @property {String}  KeyName      
	* @property {Boolean} AllowInvalid 
	* @property {Boolean} AllowExpired 
	*
	* @typedef  {Object}  Token
	* @property {String}  UUID            
	* @property {ENUM}    TokenType       
	* @property {Number}  SPID            
	* @property {String}  CustomerUUID    
	* @property {Number}  ValidFromNanos  
	* @property {Number}  ValidUntilNanos 
	* @property {String}  KeyName         
	* @property {String}  Data            
	* @property {String}  SendingSystem   
	* @property {Number}  TimeRequested   
	* @property {Number}  TimeInvalidated 
	*
	*/
	GetToken({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.get(`/v2/${req.SPID}/token/${req.KeyName}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetToken ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetToken ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetToken ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetToken UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetToken'))
			})
	},

	/**
	* InvalidateToken: 
	* HTTP Method: DELETE
	* API Path: /api/v2/${req.SPID}/token/${req.KeyName}
	* @param    {GetTokenRequest} req The API payload object (GetTokenRequest)
	* @return   {Token} The API response object (Token)
	*
	* @typedef  {Object}  GetTokenRequest
	* @property {ENUM}    TokenType    
	* @property {Number}  SPID         
	* @property {String}  CustomerUUID 
	* @property {String}  UUID         
	* @property {String}  KeyName      
	* @property {Boolean} AllowInvalid 
	* @property {Boolean} AllowExpired 
	*
	* @typedef  {Object}  Token
	* @property {String}  UUID            
	* @property {ENUM}    TokenType       
	* @property {Number}  SPID            
	* @property {String}  CustomerUUID    
	* @property {Number}  ValidFromNanos  
	* @property {Number}  ValidUntilNanos 
	* @property {String}  KeyName         
	* @property {String}  Data            
	* @property {String}  SendingSystem   
	* @property {Number}  TimeRequested   
	* @property {Number}  TimeInvalidated 
	*
	*/
	InvalidateToken({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.delete(`/v2/${req.SPID}/token/${req.KeyName}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('InvalidateToken ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('InvalidateToken ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('InvalidateToken ERROR:', error)
					return Promise.reject(error)
				}
				console.log('InvalidateToken UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in InvalidateToken'))
			})
	},
};

export default {
	namespaced: true,
	state: initialState,
	getters,
	mutations,
	actions,
};