import axios from 'axios'

const AVAILABLE_SERVICE_TYPES = {
    PROD_MOBILE: {
        cellular: {
            key: 'cellular',
            title: 'Cellular Service (cellular)',
            service_type: 'cellular',
        },
        fwa_ocs: {
            key: 'fwa_ocs',
            title: 'Online Charging System (FWA template)',
            service_type: 'ocs',
        },
        postpay_ocs: {
            key: 'postpay_ocs',
            title: 'Online Charging System (Postpay template)',
            service_type: 'ocs',
        },
        prepay_ocs: {
            key: 'prepay_ocs',
            title: 'Online Charging System (Prepay template)',
            service_type: 'ocs',
        },
        cellularmessaging: {
            key: 'cellularmessaging',
            title: 'Cellular Messaging (cellularmessaging)',
            service_type: 'cellularmessaging',
        },
        cellularvoicemail: {
            key: 'cellularvoicemail',
            title: 'Cellular Voicemail (cellularvoicemail)',
            service_type: 'cellularvoicemail',
        },
        comverse: {
            key: 'comverse',
            title: 'Comverse Voicemail (comverse)',
            service_type: 'comverse',
        },
    },
    PROD_FIBRE: {
    },
    PROD_FWA: {
    },
}

const state = {}

const getters = {
    services_adding_types: (state, getters) => {
        let services_adding_types = {}

        const modules_sp = getters.modules_sp
        const is_prerelease_mode = getters.is_prerelease_mode

        for (const key in AVAILABLE_SERVICE_TYPES) {
            if (key in modules_sp) {
                for (const type in AVAILABLE_SERVICE_TYPES[key]) {
                    if (!is_prerelease_mode && (type == 'hidden1' || type == 'hidden2')) {
                        continue
                    }
                    services_adding_types[type] = AVAILABLE_SERVICE_TYPES[key][type]
                }
            }
        }
        
        return services_adding_types
    },
}

const mutations = {}

const actions = {}

export default {
    state,
    getters,
    mutations,
    actions,
}