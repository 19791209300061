// Code generated by protoc-gen-iqwebapi. DO NOT EDIT.
// source: subscription.proto

import axios from 'axios';
import StoreHash from '@/utils/StoreHash';

const initialState = {
	Subscriptions: {
		UUIDs: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
};

const valFuncFind = function (status) {
	return (status == 200) ? true : (status == 404) ? true : false;
}

const default_pageinfo = {
	PageNumber: 0,
	PageItemCount: 0,
	TotalPages: 0,
	TotalItemCount: 0,
	IndexStart: 0,
	PageSize: 10,
	DisplayPageList: [],
	DisplayPageEitherSide: 4,
}

const getters = {
	getSubscriptionByUUID: state => (UUID) => (state.Subscriptions.UUIDs[ UUID ] && !state.Subscriptions.UUIDs[ UUID ].expired()) ?  state.Subscriptions.UUIDs[ UUID ].hashedData() : null,
};

const mutations = {
	// mutSaveSubscription saves a single Subscription object
	// into both the StoreHash cache and the VueX store
	mutSaveSubscription(state, obj) {
		if (!obj.UUID) { return } // Must have UUID to Save

		if (!(obj.UUID in state.Subscriptions.UUIDs)) {
			state.Subscriptions.UUIDs[ obj.UUID ] = new StoreHash(state.Subscriptions.lifetime)
		}
		state.Subscriptions.UUIDs[ obj.UUID ].fix(obj) // Add item to StoreHash
	},
	// mutRemoveSubscriptionByUUID removes a Subscription object
	// with the given UUID from StoreHash cache and VueX store
	mutRemoveSubscriptionByUUID(state, UUID) {
		if (!UUID) {
			// Must have UUID to Remove
			return
		}
		delete state.Subscriptions.UUIDs[ UUID ]    // remove item from StoreHash
	},
	// mutSaveSubscriptions saves a paginated response of Subscriptions
	mutSaveSubscriptions(state, { Response }) {
		if (!Response || !Array.isArray(Response.Subscriptions)) {
			// Must have Response object with results
			return
		}
		for (let i = 0; i < Response.Subscriptions.length; i++) {
			if (!(Response.Subscriptions[i].UUID in state.Subscriptions.UUIDs)) {
				state.Subscriptions.UUIDs[ Response.Subscriptions[i].UUID ] = new StoreHash(state.Subscriptions.lifetime)
			}
			state.Subscriptions.UUIDs[ Response.Subscriptions[i].UUID ].fix(Response.Subscriptions[i])  // Add single Subscription to StoreHash
		}
	},
};

const actions = {
	// getCachedSubscriptionByUUID fetches a Subscription from the cache
	// and if not in the cache, fetches it from the API
	getCachedSubscriptionByUUID({ state, dispatch, commit }, { UUID, RefreshCache }) {
		if (!RefreshCache && state.Subscriptions.UUIDs[ UUID ] && !state.Subscriptions.UUIDs[ UUID ].expired()) {
			// Return the cached record
			return state.Subscriptions.UUIDs[ UUID ].hashedData()
		}
		// Fetch from the API
		return dispatch('GetSubscriptionByUUID', { UUID }).then(respPayload => {
			commit('mutSaveSubscription', respPayload)
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveSubscriptionByUUID', UUID)
			return Promise.reject(error)
		})
	},

	//
	// Service: SubscriptionRPC
	// 
	/**
	* Cancel: 
	* HTTP Method: DELETE
	* API Path: /api/v2/{Selector.SPID}/customer/{Selector.CustomerUUID}/subscription/{Selector.SubscriptionUUID}
	* @param    {SubscriptionSimpleActionRequest} req The API payload object (SubscriptionSimpleActionRequest)
	* @return   {Subscription} The API response object (Subscription)
	*
	* @typedef  {Object}  SubscriptionSimpleActionRequest
	* @property {SubscriptionSelector} Selector             Subscription Selectors ..., ,  Must supply either SubscriptionUUID or PaymentProvider + PaymentProviderSubID
	* @property {ENUM}    Reason               
	* @property {Boolean} SkipSubscriptionSync 
	*
	* @typedef  {Object}  Subscription
	* @property {String}  UUID                            
	* @property {Number}  SPID                            
	* @property {String}  CustomerUUID                    
	* @property {ENUM}    PaymentProvider                 
	* @property {String}  PaymentProviderCustomerID       PaymentProviderCustomerID should be set from Customer.PaymentProviderID
	* @property {String}  PaymentProviderSubID            
	* @property {String}  PaymentMethodUUID               
	* @property {ENUM}    SetupReason                     
	* @property {ENUM}    SubscriptionFrequency           
	* @property {Number}  SubscriptionFrequencyMultiplier 
	* @property {Number}  Cents                           
	* @property {Number}  StartDate                       
	* @property {Number}  EndDate                         TODO: Figure out best format
	* @property {String}  ReplacesSubscriptionUUID        
	* @property {String}  ReplacedBySubscriptionUUID      
	* @property {String}  MetaTags                        
	* @property {String}  OrderID                         
	* @property {String}  Description                     
	* @property {String}  ParentProductUUID               
	* @property {String}  ProductUUIDs                    
	* @property {String}  ServiceUUIDs                    
	* @property {Number}  RequestedAtNanos                
	* @property {Number}  StartedAtNanos                  
	* @property {Number}  CancelledAtNanos                
	* @property {Number}  LastErrorNanos                  
	* @property {String}  LastErrorMessage                
	* @property {ENUM}    LastChargeError                 
	* @property {ENUM}    SubscriptionStatus              
	* @property {Number}  CurrentBillingPeriodStart       CurrentBillingPeriodStart represents the start of the current Billing Period (not when the last success charge was made for this billing period)
	* @property {Number}  CurrentBillingPeriodEnd         CurrentBillingPeriodEnd represents the end of the current Billing Period (NextBillDate - 1 nano)
	* @property {Number}  NextBillDate                    NextBillDate should always be CurrentBillingPeriodEnd + 1 nano
	* @property {Number}  PaidFrom                        
	* @property {Number}  PaidUntil                       PaidUntil is only set once the subscription is actually Paid, and it reflects when payment has been made up until
	* @property {Number}  BillingDOM                      
	* @property {Number}  LastAttemptAtNanos              TODO: Figure out best format
	* @property {String}  LastProviderChargeID            
	* @property {ENUM}    CancelReason                    
	* @property {String}  SPGLCode                        
	* @property {Number}  CreatedAtNanos                  
	* @property {Number}  UpdatedAtNanos                  
	*
	*/
	Cancel({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.delete(`/v2/{Selector.SPID}/customer/{Selector.CustomerUUID}/subscription/{Selector.SubscriptionUUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('Cancel ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('Cancel ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('Cancel ERROR:', error)
					return Promise.reject(error)
				}
				console.log('Cancel UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in Cancel'))
			})
	},

	/**
	* ChangeAmount: 
	* HTTP Method: POST
	* API Path: /api/v1/subcription_change_amount
	* @param    {SubscriptionProrateRequest} req The API payload object (SubscriptionProrateRequest)
	* @return   {AmendSubscriptions} The API response object (AmendSubscriptions)
	*
	* @typedef  {Object}  SubscriptionProrateRequest
	* @property {SubscriptionSelector} Selector             Subscription Selectors ..., ,  Must supply either SubscriptionUUID or PaymentProvider + PaymentProviderSubID
	* @property {ENUM}    Reason               
	* @property {Boolean} GetAmountOnly        
	* @property {String}  ProrateFromDate      ProrateFromDate is used to specify when the proration started.,   The value is the date to prorate from, defaults to now, however,,   It is HIGHLY recommended to always provide this (and try avoid using time.Now() due to billing retries on different days)
	* @property {String}  PaymentMethodUUID    PaymentMethodUUID specifies the PaymentMethodUUID to use to make the Proration charge.,   If not provided, it will attempt to use the PaymentMethod on the existing Subscription
	* @property {String}  InterimDescription   used in this way: fmt.Sprintf("Part month charge for %s", req.Description)
	* @property {String}  NewDescription       used in this way: fmt.Sprintf("Part month charge for %s", req.Description)
	* @property {Number}  InterimCents         InterimCents is the interim subscription cents (used to Prorate)
	* @property {Number}  NewCents             NewCents is NOT USED IN PRORATION CALCULATION
	* @property {String}  NewPaidUntilDate     NewPaidUntilDate is used to change the subcription DOM date, for example, if customer requests to change their DOM to another day.,   NB: This is the new PaidUntil date (which is day-1), NOT new BillStartDate, so this must be set to (BillStartDate-1) if changing billing DOM,   If not provided, defaults to current subscription PaidUntilDate (i.e. no change to DOM)
	* @property {Boolean} AllowDowngrade       
	* @property {Boolean} ProvideProrateRefund 
	* @property {Number}  CurrentCents         CurrentCents is the current subscription cents (used to Prorate)
	* @property {String}  ProratePeriodStart   Ideally should be provided, as if not provided, will use Subscription PeriodStart/PeriodEnd (which breaks things when a service with a dependancy is cancelled, and then re-added)
	* @property {String}  ProratePeriodEnd     
	* @property {String}  PendingProductUUIDs  replacement ProductUUIDs for the new subscription that is created
	* @property {String}  NewProductUUIDs      
	* @property {PendingMetadataEntry[]} PendingMetadata      
	* @property {NewMetadataEntry[]} NewMetadata          
	* @property {Boolean} AllowZeroNewCents    Allow NewCents to be 0, so prorate and charge but no ongoing subscription
	*
	* @typedef  {Object}  AmendSubscriptions
	* @property {Subscription} InterimSubscription 
	* @property {Subscription} NewSubscription     
	*
	*/
	ChangeAmount({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.post(`/v1/subcription_change_amount`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('ChangeAmount ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('ChangeAmount ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('ChangeAmount ERROR:', error)
					return Promise.reject(error)
				}
				console.log('ChangeAmount UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in ChangeAmount'))
			})
	},

	/**
	* ChangeEndDate: 
	* HTTP Method: POST
	* API Path: /api/v1/subcription_change_end_date
	* @param    {SubscriptionProrateRequest} req The API payload object (SubscriptionProrateRequest)
	* @return   {AmendSubscriptions} The API response object (AmendSubscriptions)
	*
	* @typedef  {Object}  SubscriptionProrateRequest
	* @property {SubscriptionSelector} Selector             Subscription Selectors ..., ,  Must supply either SubscriptionUUID or PaymentProvider + PaymentProviderSubID
	* @property {ENUM}    Reason               
	* @property {Boolean} GetAmountOnly        
	* @property {String}  ProrateFromDate      ProrateFromDate is used to specify when the proration started.,   The value is the date to prorate from, defaults to now, however,,   It is HIGHLY recommended to always provide this (and try avoid using time.Now() due to billing retries on different days)
	* @property {String}  PaymentMethodUUID    PaymentMethodUUID specifies the PaymentMethodUUID to use to make the Proration charge.,   If not provided, it will attempt to use the PaymentMethod on the existing Subscription
	* @property {String}  InterimDescription   used in this way: fmt.Sprintf("Part month charge for %s", req.Description)
	* @property {String}  NewDescription       used in this way: fmt.Sprintf("Part month charge for %s", req.Description)
	* @property {Number}  InterimCents         InterimCents is the interim subscription cents (used to Prorate)
	* @property {Number}  NewCents             NewCents is NOT USED IN PRORATION CALCULATION
	* @property {String}  NewPaidUntilDate     NewPaidUntilDate is used to change the subcription DOM date, for example, if customer requests to change their DOM to another day.,   NB: This is the new PaidUntil date (which is day-1), NOT new BillStartDate, so this must be set to (BillStartDate-1) if changing billing DOM,   If not provided, defaults to current subscription PaidUntilDate (i.e. no change to DOM)
	* @property {Boolean} AllowDowngrade       
	* @property {Boolean} ProvideProrateRefund 
	* @property {Number}  CurrentCents         CurrentCents is the current subscription cents (used to Prorate)
	* @property {String}  ProratePeriodStart   Ideally should be provided, as if not provided, will use Subscription PeriodStart/PeriodEnd (which breaks things when a service with a dependancy is cancelled, and then re-added)
	* @property {String}  ProratePeriodEnd     
	* @property {String}  PendingProductUUIDs  replacement ProductUUIDs for the new subscription that is created
	* @property {String}  NewProductUUIDs      
	* @property {PendingMetadataEntry[]} PendingMetadata      
	* @property {NewMetadataEntry[]} NewMetadata          
	* @property {Boolean} AllowZeroNewCents    Allow NewCents to be 0, so prorate and charge but no ongoing subscription
	*
	* @typedef  {Object}  AmendSubscriptions
	* @property {Subscription} InterimSubscription 
	* @property {Subscription} NewSubscription     
	*
	*/
	ChangeEndDate({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.post(`/v1/subcription_change_end_date`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('ChangeEndDate ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('ChangeEndDate ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('ChangeEndDate ERROR:', error)
					return Promise.reject(error)
				}
				console.log('ChangeEndDate UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in ChangeEndDate'))
			})
	},

	/**
	* GetSubscriptionByUUID: 
	* HTTP Method: GET
	* API Path: /api/v2/${req.SPID}/customer/${req.CustomerUUID}/subscription/${req.UUID}
	* @param    {SubscriptionUUIDSearch} req The API payload object (SubscriptionUUIDSearch)
	* @return   {Subscription} The API response object (Subscription)
	*
	* @typedef  {Object}  SubscriptionUUIDSearch
	* @property {Number}  SPID                 
	* @property {String}  UUID                 
	* @property {String}  CustomerUUID         
	* @property {Boolean} SkipSubscriptionSync 
	*
	* @typedef  {Object}  Subscription
	* @property {String}  UUID                            
	* @property {Number}  SPID                            
	* @property {String}  CustomerUUID                    
	* @property {ENUM}    PaymentProvider                 
	* @property {String}  PaymentProviderCustomerID       PaymentProviderCustomerID should be set from Customer.PaymentProviderID
	* @property {String}  PaymentProviderSubID            
	* @property {String}  PaymentMethodUUID               
	* @property {ENUM}    SetupReason                     
	* @property {ENUM}    SubscriptionFrequency           
	* @property {Number}  SubscriptionFrequencyMultiplier 
	* @property {Number}  Cents                           
	* @property {Number}  StartDate                       
	* @property {Number}  EndDate                         TODO: Figure out best format
	* @property {String}  ReplacesSubscriptionUUID        
	* @property {String}  ReplacedBySubscriptionUUID      
	* @property {String}  MetaTags                        
	* @property {String}  OrderID                         
	* @property {String}  Description                     
	* @property {String}  ParentProductUUID               
	* @property {String}  ProductUUIDs                    
	* @property {String}  ServiceUUIDs                    
	* @property {Number}  RequestedAtNanos                
	* @property {Number}  StartedAtNanos                  
	* @property {Number}  CancelledAtNanos                
	* @property {Number}  LastErrorNanos                  
	* @property {String}  LastErrorMessage                
	* @property {ENUM}    LastChargeError                 
	* @property {ENUM}    SubscriptionStatus              
	* @property {Number}  CurrentBillingPeriodStart       CurrentBillingPeriodStart represents the start of the current Billing Period (not when the last success charge was made for this billing period)
	* @property {Number}  CurrentBillingPeriodEnd         CurrentBillingPeriodEnd represents the end of the current Billing Period (NextBillDate - 1 nano)
	* @property {Number}  NextBillDate                    NextBillDate should always be CurrentBillingPeriodEnd + 1 nano
	* @property {Number}  PaidFrom                        
	* @property {Number}  PaidUntil                       PaidUntil is only set once the subscription is actually Paid, and it reflects when payment has been made up until
	* @property {Number}  BillingDOM                      
	* @property {Number}  LastAttemptAtNanos              TODO: Figure out best format
	* @property {String}  LastProviderChargeID            
	* @property {ENUM}    CancelReason                    
	* @property {String}  SPGLCode                        
	* @property {Number}  CreatedAtNanos                  
	* @property {Number}  UpdatedAtNanos                  
	*
	*/
	GetSubscriptionByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Get)
		return axios.get(`/v2/${req.SPID}/customer/${req.CustomerUUID}/subscription/${req.UUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveSubscription', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetSubscriptionByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetSubscriptionByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetSubscriptionByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetSubscriptionByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetSubscriptionByUUID'))
			})
	},

	/**
	* GetSubscriptionsPaginated: 
	* HTTP Method: GET
	* API Path: /api/v2/${req.SPID}/customer/${req.CustomerUUID}/subscription
	* @param    {SubscriptionSearchRequest} req The API payload object (SubscriptionSearchRequest)
	* @return   {SubscriptionPageResponse} The API response object (SubscriptionPageResponse)
	*
	* @typedef  {Object}  SubscriptionSearchRequest
	* @property {SearchOptions[]} SearchOptions            Search options to use (for sorting and/or pagination)
	* @property {Number}  SPID                     
	* @property {String}  CustomerUUID             
	* @property {String}  UUID                     
	* @property {String}  PaymentMethodUUID        
	* @property {ENUM}    SubscriptionStatus       
	* @property {String}  ReplacesSubscriptionUUID 
	* @property {Boolean} SkipSubscriptionSync     
	*
	* @typedef  {Object}  SearchOptions  Search options
	* @property {Number}  PageNumber     The page number to start at (or provide IndexStart)
	* @property {Number}  PageSize       The page size
	* @property {Number}  IndexStart     The record number to start at (or provide PageNumber)
	* @property {String}  SortBy         The field to sort by
	* @property {Boolean} SortDesc       Is the sort direction descending?
	*
	* @typedef  {Object}  SubscriptionPageResponse
	* @property {PageInfo} PageInfo      PageInfo only needs the values ThisPageNumber && PageSize set
	* @property {Subscription[]} Subscriptions 
	*
	* @typedef  {Object}   PageInfo
	* @property {Number}   PageNumber             The page number
	* @property {Number}   PageItemCount          Number of items on this page
	* @property {Number}   TotalPages             Total pages available
	* @property {Number}   TotalItemCount         Total items available
	* @property {Number}   IndexStart             The record number this search started at
	* @property {Number}   PageSize               The length of the page
	* @property {Number[]} DisplayPageList        The array of page numbers to display
	* @property {Number}   DisplayPagesEitherSide Number of pages to display either side
	*
	*/
	GetSubscriptionsPaginated({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Find)
		return axios.get(`/v2/${req.SPID}/customer/${req.CustomerUUID}/subscription`, { params: req, validateStatus: valFuncFind }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			commit('mutSaveSubscriptions', { Response: response.data })
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetSubscriptionsPaginated ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetSubscriptionsPaginated ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetSubscriptionsPaginated ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetSubscriptionsPaginated UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetSubscriptionsPaginated'))
			})
	},

	/**
	* GetSubscriptionsUUIDs: 
	* HTTP Method: GET
	* API Path: /api/v2/${req.SPID}/customer/${req.CustomerUUID}/subscription_uuids
	* @param    {SubscriptionSearchRequest} req The API payload object (SubscriptionSearchRequest)
	* @return   {tktcommonproto.UUIDsResponse} The API response object (tktcommonproto.UUIDsResponse)
	*
	* @typedef  {Object}  SubscriptionSearchRequest
	* @property {SearchOptions[]} SearchOptions            Search options to use (for sorting and/or pagination)
	* @property {Number}  SPID                     
	* @property {String}  CustomerUUID             
	* @property {String}  UUID                     
	* @property {String}  PaymentMethodUUID        
	* @property {ENUM}    SubscriptionStatus       
	* @property {String}  ReplacesSubscriptionUUID 
	* @property {Boolean} SkipSubscriptionSync     
	*
	* @typedef  {Object}  tktcommonproto.UUIDsResponse
	*
	*/
	GetSubscriptionsUUIDs({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: FindUUIDs)
		return axios.get(`/v2/${req.SPID}/customer/${req.CustomerUUID}/subscription_uuids`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetSubscriptionsUUIDs ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetSubscriptionsUUIDs ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetSubscriptionsUUIDs ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetSubscriptionsUUIDs UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetSubscriptionsUUIDs'))
			})
	},

	/**
	* RetryPayment: 
	* HTTP Method: POST
	* API Path: /api/v1/subcription_retry
	* @param    {SubscriptionSimpleActionRequest} req The API payload object (SubscriptionSimpleActionRequest)
	* @return   {Subscription} The API response object (Subscription)
	*
	* @typedef  {Object}  SubscriptionSimpleActionRequest
	* @property {SubscriptionSelector} Selector             Subscription Selectors ..., ,  Must supply either SubscriptionUUID or PaymentProvider + PaymentProviderSubID
	* @property {ENUM}    Reason               
	* @property {Boolean} SkipSubscriptionSync 
	*
	* @typedef  {Object}  Subscription
	* @property {String}  UUID                            
	* @property {Number}  SPID                            
	* @property {String}  CustomerUUID                    
	* @property {ENUM}    PaymentProvider                 
	* @property {String}  PaymentProviderCustomerID       PaymentProviderCustomerID should be set from Customer.PaymentProviderID
	* @property {String}  PaymentProviderSubID            
	* @property {String}  PaymentMethodUUID               
	* @property {ENUM}    SetupReason                     
	* @property {ENUM}    SubscriptionFrequency           
	* @property {Number}  SubscriptionFrequencyMultiplier 
	* @property {Number}  Cents                           
	* @property {Number}  StartDate                       
	* @property {Number}  EndDate                         TODO: Figure out best format
	* @property {String}  ReplacesSubscriptionUUID        
	* @property {String}  ReplacedBySubscriptionUUID      
	* @property {String}  MetaTags                        
	* @property {String}  OrderID                         
	* @property {String}  Description                     
	* @property {String}  ParentProductUUID               
	* @property {String}  ProductUUIDs                    
	* @property {String}  ServiceUUIDs                    
	* @property {Number}  RequestedAtNanos                
	* @property {Number}  StartedAtNanos                  
	* @property {Number}  CancelledAtNanos                
	* @property {Number}  LastErrorNanos                  
	* @property {String}  LastErrorMessage                
	* @property {ENUM}    LastChargeError                 
	* @property {ENUM}    SubscriptionStatus              
	* @property {Number}  CurrentBillingPeriodStart       CurrentBillingPeriodStart represents the start of the current Billing Period (not when the last success charge was made for this billing period)
	* @property {Number}  CurrentBillingPeriodEnd         CurrentBillingPeriodEnd represents the end of the current Billing Period (NextBillDate - 1 nano)
	* @property {Number}  NextBillDate                    NextBillDate should always be CurrentBillingPeriodEnd + 1 nano
	* @property {Number}  PaidFrom                        
	* @property {Number}  PaidUntil                       PaidUntil is only set once the subscription is actually Paid, and it reflects when payment has been made up until
	* @property {Number}  BillingDOM                      
	* @property {Number}  LastAttemptAtNanos              TODO: Figure out best format
	* @property {String}  LastProviderChargeID            
	* @property {ENUM}    CancelReason                    
	* @property {String}  SPGLCode                        
	* @property {Number}  CreatedAtNanos                  
	* @property {Number}  UpdatedAtNanos                  
	*
	*/
	RetryPayment({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.post(`/v1/subcription_retry`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('RetryPayment ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('RetryPayment ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('RetryPayment ERROR:', error)
					return Promise.reject(error)
				}
				console.log('RetryPayment UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in RetryPayment'))
			})
	},

	/**
	* Setup: 
	* HTTP Method: POST
	* API Path: /api/v1/subcription_setup
	* @param    {SubscriptionSetupRequest} req The API payload object (SubscriptionSetupRequest)
	* @return   {Subscription} The API response object (Subscription)
	*
	* @typedef  {Object}  SubscriptionSetupRequest
	* @property {Number}  SPID                            Mandatory parameters
	* @property {String}  CustomerUUID                    
	* @property {ENUM}    Reason                          
	* @property {ENUM}    SubscriptionFrequency           
	* @property {Number}  SubscriptionFrequencyMultiplier no longer used ?,  rpc GetSubscription(SubscriptionSimpleActionRequest) returns (Subscription) {,  }
	* @property {Number}  Cents                           
	* @property {String}  StartDate                       RetryPayment only requires Reason and selector. i.e. SubscriptionUUID or PaymentProvider+PaymentProviderSubID
	* @property {String}  EndDate                         HandlePendingSubscription performs the PENDING subscription handler call
	* @property {Number}  BillingDOM                      Cancel performs immediate cancellation and requires Reason and selector. i.e. SubscriptionUUID or PaymentProvider+PaymentProviderSubID.
	* @property {String}  ReplacesSubscriptionUUID        ForceCancel performs immediate cancellation and requires Reason and selector. i.e. SubscriptionUUID or PaymentProvider+PaymentProviderSubID.,  and also allows cancelling Due, Overdue and PendingCancellation subscriptions
	* @property {String}  PaymentMethodUUID               ChangeEndDate requires Reason, NewEndDate and a selector. i.e. SubscriptionUUID or PaymentProvider+PaymentProviderSubID
	* @property {String}  OrderID                         ChangeAmount requires Reason, NewAmount and a selector. i.e. SubscriptionUUID or PaymentProvider+PaymentProviderSubID,  It will calculate and charge the pro-rata amount (if applicable)
	* @property {String}  Description                     
	* @property {String}  ParentProductUUID               
	* @property {String}  ProductUUIDs                    
	* @property {String}  ServiceUUIDs                    
	* @property {String}  PaymentProviderCustomerID       PaymentProviderCustomerID should be set from Customer.PaymentProviderID
	* @property {MetadataEntry[]} Metadata                        
	* @property {String}  SPGLCode                        
	* @property {Boolean} AllowBackBill                   
	*
	* @typedef  {Object}  Subscription
	* @property {String}  UUID                            
	* @property {Number}  SPID                            
	* @property {String}  CustomerUUID                    
	* @property {ENUM}    PaymentProvider                 
	* @property {String}  PaymentProviderCustomerID       PaymentProviderCustomerID should be set from Customer.PaymentProviderID
	* @property {String}  PaymentProviderSubID            
	* @property {String}  PaymentMethodUUID               
	* @property {ENUM}    SetupReason                     
	* @property {ENUM}    SubscriptionFrequency           
	* @property {Number}  SubscriptionFrequencyMultiplier 
	* @property {Number}  Cents                           
	* @property {Number}  StartDate                       
	* @property {Number}  EndDate                         TODO: Figure out best format
	* @property {String}  ReplacesSubscriptionUUID        
	* @property {String}  ReplacedBySubscriptionUUID      
	* @property {String}  MetaTags                        
	* @property {String}  OrderID                         
	* @property {String}  Description                     
	* @property {String}  ParentProductUUID               
	* @property {String}  ProductUUIDs                    
	* @property {String}  ServiceUUIDs                    
	* @property {Number}  RequestedAtNanos                
	* @property {Number}  StartedAtNanos                  
	* @property {Number}  CancelledAtNanos                
	* @property {Number}  LastErrorNanos                  
	* @property {String}  LastErrorMessage                
	* @property {ENUM}    LastChargeError                 
	* @property {ENUM}    SubscriptionStatus              
	* @property {Number}  CurrentBillingPeriodStart       CurrentBillingPeriodStart represents the start of the current Billing Period (not when the last success charge was made for this billing period)
	* @property {Number}  CurrentBillingPeriodEnd         CurrentBillingPeriodEnd represents the end of the current Billing Period (NextBillDate - 1 nano)
	* @property {Number}  NextBillDate                    NextBillDate should always be CurrentBillingPeriodEnd + 1 nano
	* @property {Number}  PaidFrom                        
	* @property {Number}  PaidUntil                       PaidUntil is only set once the subscription is actually Paid, and it reflects when payment has been made up until
	* @property {Number}  BillingDOM                      
	* @property {Number}  LastAttemptAtNanos              TODO: Figure out best format
	* @property {String}  LastProviderChargeID            
	* @property {ENUM}    CancelReason                    
	* @property {String}  SPGLCode                        
	* @property {Number}  CreatedAtNanos                  
	* @property {Number}  UpdatedAtNanos                  
	*
	*/
	Setup({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.post(`/v1/subcription_setup`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('Setup ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('Setup ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('Setup ERROR:', error)
					return Promise.reject(error)
				}
				console.log('Setup UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in Setup'))
			})
	},
};

export default {
	namespaced: true,
	state: initialState,
	getters,
	mutations,
	actions,
};