<template>
    <div class="app-toast" :class="[type]">
        <i></i>

        <div class="message">{{ message }}</div>

        <button class="close" @click="close"></button>
    </div>
</template>

<script>
export default {
    props: {
        uuid:    { required: true                   },
        message: { type: String, required: true     },
        type:    { type: String, default: 'success' },
        delay:   { type: Number, default: 0         },
    },

    data() {
        return {
            timer: null,
        }
    },

    mounted() {
        if (this.delay) {
            this.timer = setTimeout(() => {
                this.close()
            }, this.delay)
        }
    },

    methods: {
        close() {
            this.$emit('close', this.uuid)
        },
    },

    beforeDestroy() {
        if (this.delay) {
            clearTimeout(this.timer)
        }
    },
}
</script>

<style lang="scss">
.app-toast {
    display: flex;
    align-items: center;

    padding: 12px 15px;

    border-radius: $border-radius-primary;

    i {
        display: block;

        width: 24px;
        height: 24px;

        margin-right: 8px;
    }

    .message {
        margin-right: 8px;

        font-size: 18px;
        font-weight: bold;
        line-height: 24px;
    }

    .close {
        display: block;

        width: 24px;
        height: 24px;

        margin-left: auto;

        transition: $transition-duration-primary;

        @include icon-before($icon-close);
    }

    @each $type in $toast-types {
        &.#{ $type } {
            background-color: map-get($toast-backgrounds, $type);

            i {
                color: map-get($toast-icons-colors, $type);

                @include icon-before(map-get($icons-toast-types, $type));
            }

            .message {
                color: map-get($toast-colors, $type);
            }

            .close {
                color: map-get($toast-icons-colors, $type);

                &:hover {
                    color: map-get($toast-icons-hover-colors, $type);
                }
            }
        }
    }

    &.fade-enter-active,
    &.fade-leave-active {
        transition: all $transition-duration-primary ease-in-out;
    }

    &.fade-enter {
        opacity: 0;
        transform: translateY(8px);
    }

    &.fade-enter-to {
        opacity: 1;
        transform: translateY(0px);
    }

    &.fade-leave {
        opacity: 1;
        transform: translateY(0px);
    }

    &.fade-leave-to {
        opacity: 0;
        transform: translateY(-8px);
    }
}

@media (max-width: $tablet-size) {
    .app-toast {}
}

@media (max-width: $mobile-size) {
    .app-toast {
        .message {
            font-size: 14px;
            line-height: 18px;
        }
    }
}
</style>