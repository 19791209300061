import Storage from './StorageClass'

// export default new Storage('cookies')
// export default new Storage('localStorage')
// export default new Storage('sessionStorage')

let thisStorage = new Storage('localStorage')

if (process.env.VUE_APP_PROJECT && process.env.VUE_APP_PROJECT.toLowerCase() != 'admin') {
    thisStorage = new Storage('sessionStorage')
}

export default thisStorage
