import App from '@/App.vue'
// import devtools from '@vue/devtools'

import icons_fonts from '@/assets/scss/icons-fonts.scss'
import root from '@/assets/scss/root.scss'
import reset from '@/assets/scss/reset.scss'
import common from '@/assets/scss/common.scss'

import axiosMiddleware from '@/middlewares/axios-middleware'
import router from '@/router'
import store from '@/store'
import axios from 'axios'
import Vue from 'vue'

import directives from '@/directives'
Vue.use(directives)

const theme = store.getters.theme

Vue.use(axiosMiddleware, axios, store, router)
Vue.prototype.$axios = axios
Vue.config.productionTip = false

// if (process.env.VUE_APP_DEBUG === '1') {
//   devtools.connect("10.20.30.250:8080")
// }

const load = async () => {
    const fonts = await import('@/assets/scss/' + theme + '/fonts.scss')

    // Для возможности работы с SA Email Builder через iFrame в рамках общего домена
    // To be able to work with SA Email Builder via iFrame within a common domain
    document.domain = /(run.app)$/.test(window.location.hostname)
        ? window.location.hostname
        : /(co|com|net|org|govt)\.(\w{2,3})$/.test(window.location.hostname)
            ? window.location.hostname.split('.').splice(-3).join('.')
            : /^((\d{1,3}\.){3}\d{1,3})$/.test(window.location.hostname)
                ? window.location.hostname
                : window.location.hostname.split('.').splice(-2).join('.')

    store.dispatch('AutoLogin').then(() => {
        new Vue({
            router,
            store,

            fonts,
            icons_fonts,
            root,
            reset,
            common,

            render: h => h(App)
        }).$mount('#app')
    }).catch(() => {
        console.log('AutoLogin promise error')
    })
}

load()