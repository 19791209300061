// Code generated by protoc-gen-iqwebapi. DO NOT EDIT.
// source: lmnpproto.proto

import axios from 'axios';
import StoreHash from '@/utils/StoreHash';

const initialState = {
	CarrierServiceProviderMaps: {
		UUIDs: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
	Ports: {
		UUIDs: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
	PortNumbers: {
		UUIDs: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
	ServiceProviderApprovedNumberPortChanges: {
		UUIDs: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
	ServiceProviderApprovedPortChanges: {
		UUIDs: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
	ServiceProviderNumberPorts: {
		UUIDs: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
	ServiceProviderPorts: {
		UUIDs: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
	ThirdPartyNetworkUpdates: {
		UUIDs: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
	ThirdPartyNetworkUpdateNumbers: {
		UUIDs: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
};

const valFuncFind = function (status) {
	return (status == 200) ? true : (status == 404) ? true : false;
}

const default_pageinfo = {
	PageNumber: 0,
	PageItemCount: 0,
	TotalPages: 0,
	TotalItemCount: 0,
	IndexStart: 0,
	PageSize: 10,
	DisplayPageList: [],
	DisplayPageEitherSide: 4,
}

const getters = {
	getCarrierServiceProviderMapByUUID: state => (UUID) => (state.CarrierServiceProviderMaps.UUIDs[ UUID ] && !state.CarrierServiceProviderMaps.UUIDs[ UUID ].expired()) ?  state.CarrierServiceProviderMaps.UUIDs[ UUID ].hashedData() : null,
	getPortByUUID: state => (UUID) => (state.Ports.UUIDs[ UUID ] && !state.Ports.UUIDs[ UUID ].expired()) ?  state.Ports.UUIDs[ UUID ].hashedData() : null,
	getPortNumberByUUID: state => (UUID) => (state.PortNumbers.UUIDs[ UUID ] && !state.PortNumbers.UUIDs[ UUID ].expired()) ?  state.PortNumbers.UUIDs[ UUID ].hashedData() : null,
	getServiceProviderApprovedNumberPortChangeByUUID: state => (UUID) => (state.ServiceProviderApprovedNumberPortChanges.UUIDs[ UUID ] && !state.ServiceProviderApprovedNumberPortChanges.UUIDs[ UUID ].expired()) ?  state.ServiceProviderApprovedNumberPortChanges.UUIDs[ UUID ].hashedData() : null,
	getServiceProviderApprovedPortChangeByUUID: state => (UUID) => (state.ServiceProviderApprovedPortChanges.UUIDs[ UUID ] && !state.ServiceProviderApprovedPortChanges.UUIDs[ UUID ].expired()) ?  state.ServiceProviderApprovedPortChanges.UUIDs[ UUID ].hashedData() : null,
	getServiceProviderNumberPortByUUID: state => (UUID) => (state.ServiceProviderNumberPorts.UUIDs[ UUID ] && !state.ServiceProviderNumberPorts.UUIDs[ UUID ].expired()) ?  state.ServiceProviderNumberPorts.UUIDs[ UUID ].hashedData() : null,
	getServiceProviderPortByUUID: state => (UUID) => (state.ServiceProviderPorts.UUIDs[ UUID ] && !state.ServiceProviderPorts.UUIDs[ UUID ].expired()) ?  state.ServiceProviderPorts.UUIDs[ UUID ].hashedData() : null,
	getThirdPartyNetworkUpdateByUUID: state => (UUID) => (state.ThirdPartyNetworkUpdates.UUIDs[ UUID ] && !state.ThirdPartyNetworkUpdates.UUIDs[ UUID ].expired()) ?  state.ThirdPartyNetworkUpdates.UUIDs[ UUID ].hashedData() : null,
	getThirdPartyNetworkUpdateNumberByUUID: state => (UUID) => (state.ThirdPartyNetworkUpdateNumbers.UUIDs[ UUID ] && !state.ThirdPartyNetworkUpdateNumbers.UUIDs[ UUID ].expired()) ?  state.ThirdPartyNetworkUpdateNumbers.UUIDs[ UUID ].hashedData() : null,
};

const mutations = {
	// mutSaveCarrierServiceProviderMap saves a single CarrierServiceProviderMap object
	// into both the StoreHash cache and the VueX store
	mutSaveCarrierServiceProviderMap(state, obj) {
		if (!obj.UUID) { return } // Must have UUID to Save

		if (!(obj.UUID in state.CarrierServiceProviderMaps.UUIDs)) {
			state.CarrierServiceProviderMaps.UUIDs[ obj.UUID ] = new StoreHash(state.CarrierServiceProviderMaps.lifetime)
		}
		state.CarrierServiceProviderMaps.UUIDs[ obj.UUID ].fix(obj) // Add item to StoreHash
	},
	// mutRemoveCarrierServiceProviderMapByUUID removes a CarrierServiceProviderMap object
	// with the given UUID from StoreHash cache and VueX store
	mutRemoveCarrierServiceProviderMapByUUID(state, UUID) {
		if (!UUID) {
			// Must have UUID to Remove
			return
		}
		delete state.CarrierServiceProviderMaps.UUIDs[ UUID ]    // remove item from StoreHash
	},
	// mutSavePort saves a single Port object
	// into both the StoreHash cache and the VueX store
	mutSavePort(state, obj) {
		if (!obj.UUID) { return } // Must have UUID to Save

		if (!(obj.UUID in state.Ports.UUIDs)) {
			state.Ports.UUIDs[ obj.UUID ] = new StoreHash(state.Ports.lifetime)
		}
		state.Ports.UUIDs[ obj.UUID ].fix(obj) // Add item to StoreHash
	},
	// mutRemovePortByUUID removes a Port object
	// with the given UUID from StoreHash cache and VueX store
	mutRemovePortByUUID(state, UUID) {
		if (!UUID) {
			// Must have UUID to Remove
			return
		}
		delete state.Ports.UUIDs[ UUID ]    // remove item from StoreHash
	},
	// mutSavePorts saves a paginated response of Ports
	mutSavePorts(state, { Response }) {
		if (!Response || !Array.isArray(Response.Ports)) {
			// Must have Response object with results
			return
		}
		for (let i = 0; i < Response.Ports.length; i++) {
			if (!(Response.Ports[i].UUID in state.Ports.UUIDs)) {
				state.Ports.UUIDs[ Response.Ports[i].UUID ] = new StoreHash(state.Ports.lifetime)
			}
			state.Ports.UUIDs[ Response.Ports[i].UUID ].fix(Response.Ports[i])  // Add single Port to StoreHash
		}
	},
	// mutSavePortNumber saves a single PortNumber object
	// into both the StoreHash cache and the VueX store
	mutSavePortNumber(state, obj) {
		if (!obj.UUID) { return } // Must have UUID to Save

		if (!(obj.UUID in state.PortNumbers.UUIDs)) {
			state.PortNumbers.UUIDs[ obj.UUID ] = new StoreHash(state.PortNumbers.lifetime)
		}
		state.PortNumbers.UUIDs[ obj.UUID ].fix(obj) // Add item to StoreHash
	},
	// mutRemovePortNumberByUUID removes a PortNumber object
	// with the given UUID from StoreHash cache and VueX store
	mutRemovePortNumberByUUID(state, UUID) {
		if (!UUID) {
			// Must have UUID to Remove
			return
		}
		delete state.PortNumbers.UUIDs[ UUID ]    // remove item from StoreHash
	},
	// mutSavePortNumbers saves a paginated response of PortNumbers
	mutSavePortNumbers(state, { Response }) {
		if (!Response || !Array.isArray(Response.PortNumbers)) {
			// Must have Response object with results
			return
		}
		for (let i = 0; i < Response.PortNumbers.length; i++) {
			if (!(Response.PortNumbers[i].UUID in state.PortNumbers.UUIDs)) {
				state.PortNumbers.UUIDs[ Response.PortNumbers[i].UUID ] = new StoreHash(state.PortNumbers.lifetime)
			}
			state.PortNumbers.UUIDs[ Response.PortNumbers[i].UUID ].fix(Response.PortNumbers[i])  // Add single PortNumber to StoreHash
		}
	},
	// mutSaveServiceProviderApprovedNumberPortChange saves a single ServiceProviderApprovedNumberPortChange object
	// into both the StoreHash cache and the VueX store
	mutSaveServiceProviderApprovedNumberPortChange(state, obj) {
		if (!obj.UUID) { return } // Must have UUID to Save

		if (!(obj.UUID in state.ServiceProviderApprovedNumberPortChanges.UUIDs)) {
			state.ServiceProviderApprovedNumberPortChanges.UUIDs[ obj.UUID ] = new StoreHash(state.ServiceProviderApprovedNumberPortChanges.lifetime)
		}
		state.ServiceProviderApprovedNumberPortChanges.UUIDs[ obj.UUID ].fix(obj) // Add item to StoreHash
	},
	// mutRemoveServiceProviderApprovedNumberPortChangeByUUID removes a ServiceProviderApprovedNumberPortChange object
	// with the given UUID from StoreHash cache and VueX store
	mutRemoveServiceProviderApprovedNumberPortChangeByUUID(state, UUID) {
		if (!UUID) {
			// Must have UUID to Remove
			return
		}
		delete state.ServiceProviderApprovedNumberPortChanges.UUIDs[ UUID ]    // remove item from StoreHash
	},
	// mutSaveServiceProviderApprovedNumberPortChanges saves a paginated response of ServiceProviderApprovedNumberPortChanges
	mutSaveServiceProviderApprovedNumberPortChanges(state, { Response }) {
		if (!Response || !Array.isArray(Response.ServiceProviderApprovedNumberPortChanges)) {
			// Must have Response object with results
			return
		}
		for (let i = 0; i < Response.ServiceProviderApprovedNumberPortChanges.length; i++) {
			if (!(Response.ServiceProviderApprovedNumberPortChanges[i].UUID in state.ServiceProviderApprovedNumberPortChanges.UUIDs)) {
				state.ServiceProviderApprovedNumberPortChanges.UUIDs[ Response.ServiceProviderApprovedNumberPortChanges[i].UUID ] = new StoreHash(state.ServiceProviderApprovedNumberPortChanges.lifetime)
			}
			state.ServiceProviderApprovedNumberPortChanges.UUIDs[ Response.ServiceProviderApprovedNumberPortChanges[i].UUID ].fix(Response.ServiceProviderApprovedNumberPortChanges[i])  // Add single ServiceProviderApprovedNumberPortChange to StoreHash
		}
	},
	// mutSaveServiceProviderApprovedPortChange saves a single ServiceProviderApprovedPortChange object
	// into both the StoreHash cache and the VueX store
	mutSaveServiceProviderApprovedPortChange(state, obj) {
		if (!obj.UUID) { return } // Must have UUID to Save

		if (!(obj.UUID in state.ServiceProviderApprovedPortChanges.UUIDs)) {
			state.ServiceProviderApprovedPortChanges.UUIDs[ obj.UUID ] = new StoreHash(state.ServiceProviderApprovedPortChanges.lifetime)
		}
		state.ServiceProviderApprovedPortChanges.UUIDs[ obj.UUID ].fix(obj) // Add item to StoreHash
	},
	// mutRemoveServiceProviderApprovedPortChangeByUUID removes a ServiceProviderApprovedPortChange object
	// with the given UUID from StoreHash cache and VueX store
	mutRemoveServiceProviderApprovedPortChangeByUUID(state, UUID) {
		if (!UUID) {
			// Must have UUID to Remove
			return
		}
		delete state.ServiceProviderApprovedPortChanges.UUIDs[ UUID ]    // remove item from StoreHash
	},
	// mutSaveServiceProviderApprovedPortChanges saves a paginated response of ServiceProviderApprovedPortChanges
	mutSaveServiceProviderApprovedPortChanges(state, { Response }) {
		if (!Response || !Array.isArray(Response.ServiceProviderApprovedPortChanges)) {
			// Must have Response object with results
			return
		}
		for (let i = 0; i < Response.ServiceProviderApprovedPortChanges.length; i++) {
			if (!(Response.ServiceProviderApprovedPortChanges[i].UUID in state.ServiceProviderApprovedPortChanges.UUIDs)) {
				state.ServiceProviderApprovedPortChanges.UUIDs[ Response.ServiceProviderApprovedPortChanges[i].UUID ] = new StoreHash(state.ServiceProviderApprovedPortChanges.lifetime)
			}
			state.ServiceProviderApprovedPortChanges.UUIDs[ Response.ServiceProviderApprovedPortChanges[i].UUID ].fix(Response.ServiceProviderApprovedPortChanges[i])  // Add single ServiceProviderApprovedPortChange to StoreHash
		}
	},
	// mutSaveServiceProviderNumberPort saves a single ServiceProviderNumberPort object
	// into both the StoreHash cache and the VueX store
	mutSaveServiceProviderNumberPort(state, obj) {
		if (!obj.UUID) { return } // Must have UUID to Save

		if (!(obj.UUID in state.ServiceProviderNumberPorts.UUIDs)) {
			state.ServiceProviderNumberPorts.UUIDs[ obj.UUID ] = new StoreHash(state.ServiceProviderNumberPorts.lifetime)
		}
		state.ServiceProviderNumberPorts.UUIDs[ obj.UUID ].fix(obj) // Add item to StoreHash
	},
	// mutRemoveServiceProviderNumberPortByUUID removes a ServiceProviderNumberPort object
	// with the given UUID from StoreHash cache and VueX store
	mutRemoveServiceProviderNumberPortByUUID(state, UUID) {
		if (!UUID) {
			// Must have UUID to Remove
			return
		}
		delete state.ServiceProviderNumberPorts.UUIDs[ UUID ]    // remove item from StoreHash
	},
	// mutSaveServiceProviderNumberPorts saves a paginated response of ServiceProviderNumberPorts
	mutSaveServiceProviderNumberPorts(state, { Response }) {
		if (!Response || !Array.isArray(Response.ServiceProviderNumberPorts)) {
			// Must have Response object with results
			return
		}
		for (let i = 0; i < Response.ServiceProviderNumberPorts.length; i++) {
			if (!(Response.ServiceProviderNumberPorts[i].UUID in state.ServiceProviderNumberPorts.UUIDs)) {
				state.ServiceProviderNumberPorts.UUIDs[ Response.ServiceProviderNumberPorts[i].UUID ] = new StoreHash(state.ServiceProviderNumberPorts.lifetime)
			}
			state.ServiceProviderNumberPorts.UUIDs[ Response.ServiceProviderNumberPorts[i].UUID ].fix(Response.ServiceProviderNumberPorts[i])  // Add single ServiceProviderNumberPort to StoreHash
		}
	},
	// mutSaveServiceProviderPort saves a single ServiceProviderPort object
	// into both the StoreHash cache and the VueX store
	mutSaveServiceProviderPort(state, obj) {
		if (!obj.UUID) { return } // Must have UUID to Save

		if (!(obj.UUID in state.ServiceProviderPorts.UUIDs)) {
			state.ServiceProviderPorts.UUIDs[ obj.UUID ] = new StoreHash(state.ServiceProviderPorts.lifetime)
		}
		state.ServiceProviderPorts.UUIDs[ obj.UUID ].fix(obj) // Add item to StoreHash
	},
	// mutRemoveServiceProviderPortByUUID removes a ServiceProviderPort object
	// with the given UUID from StoreHash cache and VueX store
	mutRemoveServiceProviderPortByUUID(state, UUID) {
		if (!UUID) {
			// Must have UUID to Remove
			return
		}
		delete state.ServiceProviderPorts.UUIDs[ UUID ]    // remove item from StoreHash
	},
	// mutSaveServiceProviderPorts saves a paginated response of ServiceProviderPorts
	mutSaveServiceProviderPorts(state, { Response }) {
		if (!Response || !Array.isArray(Response.ServiceProviderPorts)) {
			// Must have Response object with results
			return
		}
		for (let i = 0; i < Response.ServiceProviderPorts.length; i++) {
			if (!(Response.ServiceProviderPorts[i].UUID in state.ServiceProviderPorts.UUIDs)) {
				state.ServiceProviderPorts.UUIDs[ Response.ServiceProviderPorts[i].UUID ] = new StoreHash(state.ServiceProviderPorts.lifetime)
			}
			state.ServiceProviderPorts.UUIDs[ Response.ServiceProviderPorts[i].UUID ].fix(Response.ServiceProviderPorts[i])  // Add single ServiceProviderPort to StoreHash
		}
	},
	// mutSaveThirdPartyNetworkUpdate saves a single ThirdPartyNetworkUpdate object
	// into both the StoreHash cache and the VueX store
	mutSaveThirdPartyNetworkUpdate(state, obj) {
		if (!obj.UUID) { return } // Must have UUID to Save

		if (!(obj.UUID in state.ThirdPartyNetworkUpdates.UUIDs)) {
			state.ThirdPartyNetworkUpdates.UUIDs[ obj.UUID ] = new StoreHash(state.ThirdPartyNetworkUpdates.lifetime)
		}
		state.ThirdPartyNetworkUpdates.UUIDs[ obj.UUID ].fix(obj) // Add item to StoreHash
	},
	// mutRemoveThirdPartyNetworkUpdateByUUID removes a ThirdPartyNetworkUpdate object
	// with the given UUID from StoreHash cache and VueX store
	mutRemoveThirdPartyNetworkUpdateByUUID(state, UUID) {
		if (!UUID) {
			// Must have UUID to Remove
			return
		}
		delete state.ThirdPartyNetworkUpdates.UUIDs[ UUID ]    // remove item from StoreHash
	},
	// mutSaveThirdPartyNetworkUpdates saves a paginated response of ThirdPartyNetworkUpdates
	mutSaveThirdPartyNetworkUpdates(state, { Response }) {
		if (!Response || !Array.isArray(Response.ThirdPartyNetworkUpdates)) {
			// Must have Response object with results
			return
		}
		for (let i = 0; i < Response.ThirdPartyNetworkUpdates.length; i++) {
			if (!(Response.ThirdPartyNetworkUpdates[i].UUID in state.ThirdPartyNetworkUpdates.UUIDs)) {
				state.ThirdPartyNetworkUpdates.UUIDs[ Response.ThirdPartyNetworkUpdates[i].UUID ] = new StoreHash(state.ThirdPartyNetworkUpdates.lifetime)
			}
			state.ThirdPartyNetworkUpdates.UUIDs[ Response.ThirdPartyNetworkUpdates[i].UUID ].fix(Response.ThirdPartyNetworkUpdates[i])  // Add single ThirdPartyNetworkUpdate to StoreHash
		}
	},
	// mutSaveThirdPartyNetworkUpdateNumber saves a single ThirdPartyNetworkUpdateNumber object
	// into both the StoreHash cache and the VueX store
	mutSaveThirdPartyNetworkUpdateNumber(state, obj) {
		if (!obj.UUID) { return } // Must have UUID to Save

		if (!(obj.UUID in state.ThirdPartyNetworkUpdateNumbers.UUIDs)) {
			state.ThirdPartyNetworkUpdateNumbers.UUIDs[ obj.UUID ] = new StoreHash(state.ThirdPartyNetworkUpdateNumbers.lifetime)
		}
		state.ThirdPartyNetworkUpdateNumbers.UUIDs[ obj.UUID ].fix(obj) // Add item to StoreHash
	},
	// mutRemoveThirdPartyNetworkUpdateNumberByUUID removes a ThirdPartyNetworkUpdateNumber object
	// with the given UUID from StoreHash cache and VueX store
	mutRemoveThirdPartyNetworkUpdateNumberByUUID(state, UUID) {
		if (!UUID) {
			// Must have UUID to Remove
			return
		}
		delete state.ThirdPartyNetworkUpdateNumbers.UUIDs[ UUID ]    // remove item from StoreHash
	},
	// mutSaveThirdPartyNetworkUpdateNumbers saves a paginated response of ThirdPartyNetworkUpdateNumbers
	mutSaveThirdPartyNetworkUpdateNumbers(state, { Response }) {
		if (!Response || !Array.isArray(Response.ThirdPartyNetworkUpdateNumbers)) {
			// Must have Response object with results
			return
		}
		for (let i = 0; i < Response.ThirdPartyNetworkUpdateNumbers.length; i++) {
			if (!(Response.ThirdPartyNetworkUpdateNumbers[i].UUID in state.ThirdPartyNetworkUpdateNumbers.UUIDs)) {
				state.ThirdPartyNetworkUpdateNumbers.UUIDs[ Response.ThirdPartyNetworkUpdateNumbers[i].UUID ] = new StoreHash(state.ThirdPartyNetworkUpdateNumbers.lifetime)
			}
			state.ThirdPartyNetworkUpdateNumbers.UUIDs[ Response.ThirdPartyNetworkUpdateNumbers[i].UUID ].fix(Response.ThirdPartyNetworkUpdateNumbers[i])  // Add single ThirdPartyNetworkUpdateNumber to StoreHash
		}
	},
};

const actions = {
	// getCachedCarrierServiceProviderMapByUUID fetches a CarrierServiceProviderMap from the cache
	// and if not in the cache, fetches it from the API
	getCachedCarrierServiceProviderMapByUUID({ state, dispatch, commit }, { UUID, RefreshCache }) {
		if (!RefreshCache && state.CarrierServiceProviderMaps.UUIDs[ UUID ] && !state.CarrierServiceProviderMaps.UUIDs[ UUID ].expired()) {
			// Return the cached record
			return state.CarrierServiceProviderMaps.UUIDs[ UUID ].hashedData()
		}
		// Fetch from the API
		return dispatch('GetCarrierServiceProviderMapByUUID', { UUID }).then(respPayload => {
			commit('mutSaveCarrierServiceProviderMap', respPayload)
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveCarrierServiceProviderMapByUUID', UUID)
			return Promise.reject(error)
		})
	},

	// getCachedPortByUUID fetches a Port from the cache
	// and if not in the cache, fetches it from the API
	getCachedPortByUUID({ state, dispatch, commit }, { UUID, RefreshCache }) {
		if (!RefreshCache && state.Ports.UUIDs[ UUID ] && !state.Ports.UUIDs[ UUID ].expired()) {
			// Return the cached record
			return state.Ports.UUIDs[ UUID ].hashedData()
		}
		// Fetch from the API
		return dispatch('GetPortByUUID', { UUID }).then(respPayload => {
			commit('mutSavePort', respPayload)
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemovePortByUUID', UUID)
			return Promise.reject(error)
		})
	},

	// getCachedPortNumberByUUID fetches a PortNumber from the cache
	// and if not in the cache, fetches it from the API
	getCachedPortNumberByUUID({ state, dispatch, commit }, { UUID, RefreshCache }) {
		if (!RefreshCache && state.PortNumbers.UUIDs[ UUID ] && !state.PortNumbers.UUIDs[ UUID ].expired()) {
			// Return the cached record
			return state.PortNumbers.UUIDs[ UUID ].hashedData()
		}
		// Fetch from the API
		return dispatch('GetPortNumberByUUID', { UUID }).then(respPayload => {
			commit('mutSavePortNumber', respPayload)
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemovePortNumberByUUID', UUID)
			return Promise.reject(error)
		})
	},

	// getCachedServiceProviderApprovedNumberPortChangeByUUID fetches a ServiceProviderApprovedNumberPortChange from the cache
	// and if not in the cache, fetches it from the API
	getCachedServiceProviderApprovedNumberPortChangeByUUID({ state, dispatch, commit }, { UUID, RefreshCache }) {
		if (!RefreshCache && state.ServiceProviderApprovedNumberPortChanges.UUIDs[ UUID ] && !state.ServiceProviderApprovedNumberPortChanges.UUIDs[ UUID ].expired()) {
			// Return the cached record
			return state.ServiceProviderApprovedNumberPortChanges.UUIDs[ UUID ].hashedData()
		}
		// Fetch from the API
		return dispatch('GetServiceProviderApprovedNumberPortChangeByUUID', { UUID }).then(respPayload => {
			commit('mutSaveServiceProviderApprovedNumberPortChange', respPayload)
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveServiceProviderApprovedNumberPortChangeByUUID', UUID)
			return Promise.reject(error)
		})
	},

	// getCachedServiceProviderApprovedPortChangeByUUID fetches a ServiceProviderApprovedPortChange from the cache
	// and if not in the cache, fetches it from the API
	getCachedServiceProviderApprovedPortChangeByUUID({ state, dispatch, commit }, { UUID, RefreshCache }) {
		if (!RefreshCache && state.ServiceProviderApprovedPortChanges.UUIDs[ UUID ] && !state.ServiceProviderApprovedPortChanges.UUIDs[ UUID ].expired()) {
			// Return the cached record
			return state.ServiceProviderApprovedPortChanges.UUIDs[ UUID ].hashedData()
		}
		// Fetch from the API
		return dispatch('GetServiceProviderApprovedPortChangeByUUID', { UUID }).then(respPayload => {
			commit('mutSaveServiceProviderApprovedPortChange', respPayload)
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveServiceProviderApprovedPortChangeByUUID', UUID)
			return Promise.reject(error)
		})
	},

	// getCachedServiceProviderNumberPortByUUID fetches a ServiceProviderNumberPort from the cache
	// and if not in the cache, fetches it from the API
	getCachedServiceProviderNumberPortByUUID({ state, dispatch, commit }, { UUID, RefreshCache }) {
		if (!RefreshCache && state.ServiceProviderNumberPorts.UUIDs[ UUID ] && !state.ServiceProviderNumberPorts.UUIDs[ UUID ].expired()) {
			// Return the cached record
			return state.ServiceProviderNumberPorts.UUIDs[ UUID ].hashedData()
		}
		// Fetch from the API
		return dispatch('GetServiceProviderNumberPortByUUID', { UUID }).then(respPayload => {
			commit('mutSaveServiceProviderNumberPort', respPayload)
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveServiceProviderNumberPortByUUID', UUID)
			return Promise.reject(error)
		})
	},

	// getCachedServiceProviderPortByUUID fetches a ServiceProviderPort from the cache
	// and if not in the cache, fetches it from the API
	getCachedServiceProviderPortByUUID({ state, dispatch, commit }, { UUID, RefreshCache }) {
		if (!RefreshCache && state.ServiceProviderPorts.UUIDs[ UUID ] && !state.ServiceProviderPorts.UUIDs[ UUID ].expired()) {
			// Return the cached record
			return state.ServiceProviderPorts.UUIDs[ UUID ].hashedData()
		}
		// Fetch from the API
		return dispatch('GetServiceProviderPortByUUID', { UUID }).then(respPayload => {
			commit('mutSaveServiceProviderPort', respPayload)
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveServiceProviderPortByUUID', UUID)
			return Promise.reject(error)
		})
	},

	// getCachedThirdPartyNetworkUpdateByUUID fetches a ThirdPartyNetworkUpdate from the cache
	// and if not in the cache, fetches it from the API
	getCachedThirdPartyNetworkUpdateByUUID({ state, dispatch, commit }, { UUID, RefreshCache }) {
		if (!RefreshCache && state.ThirdPartyNetworkUpdates.UUIDs[ UUID ] && !state.ThirdPartyNetworkUpdates.UUIDs[ UUID ].expired()) {
			// Return the cached record
			return state.ThirdPartyNetworkUpdates.UUIDs[ UUID ].hashedData()
		}
		// Fetch from the API
		return dispatch('GetThirdPartyNetworkUpdateByUUID', { UUID }).then(respPayload => {
			commit('mutSaveThirdPartyNetworkUpdate', respPayload)
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveThirdPartyNetworkUpdateByUUID', UUID)
			return Promise.reject(error)
		})
	},

	// getCachedThirdPartyNetworkUpdateNumberByUUID fetches a ThirdPartyNetworkUpdateNumber from the cache
	// and if not in the cache, fetches it from the API
	getCachedThirdPartyNetworkUpdateNumberByUUID({ state, dispatch, commit }, { UUID, RefreshCache }) {
		if (!RefreshCache && state.ThirdPartyNetworkUpdateNumbers.UUIDs[ UUID ] && !state.ThirdPartyNetworkUpdateNumbers.UUIDs[ UUID ].expired()) {
			// Return the cached record
			return state.ThirdPartyNetworkUpdateNumbers.UUIDs[ UUID ].hashedData()
		}
		// Fetch from the API
		return dispatch('GetThirdPartyNetworkUpdateNumberByUUID', { UUID }).then(respPayload => {
			commit('mutSaveThirdPartyNetworkUpdateNumber', respPayload)
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveThirdPartyNetworkUpdateNumberByUUID', UUID)
			return Promise.reject(error)
		})
	},

	//
	// Service: LMNPRPC
	// 
	/**
	* RPC Method: AddPort
	* Description: Add a Port object
	* HTTP Method: POST
	* API Path: /api/v3/ipms/port
	* @param    {Port} req The API payload object (Port)
	* @return   {Port} The API response object (Port)
	*
	* @typedef  {Object}  Port
	* @property {String}  UUID                              
	* @property {Number}  SPID                              Default Service Provider ID
	* @property {Number}  SOM                               
	* @property {String}  AccountNumber                     
	* @property {String}  AdditionalCustomerInfo            
	* @property {String}  Category                          
	* @property {String}  CustomerContactName               
	* @property {String}  CustomerContactNumber             
	* @property {String}  CustomerExistingServiceAddress    
	* @property {String}  CustomerName                      
	* @property {String}  GainingServiceProviderContact     
	* @property {String}  GspInternalReference              
	* @property {String}  LspInternalReference              
	* @property {Boolean} LspOverride                       
	* @property {Boolean} PrePayPrePaid                     
	* @property {Number}  RfsDateTimeStartNanos             
	* @property {Number}  ActionDueDateTimeNanos            Converted from IPMS DurationData
	* @property {Number}  SOMGainingServiceProviderId       
	* @property {Boolean} EmergencyReturn                   
	* @property {Number}  SOMLosingServiceProviderId        
	* @property {String}  SOMStatus                         
	* @property {Number}  SOMStatusDateTimeNanos            
	* @property {String}  SOMType                           
	* @property {Number}  SOMCompletedStateEnteredDateNanos 
	* @property {Number}  CreatedAtNanos                    
	* @property {Number}  UpdatedAtNanos                    
	*
	*/
	AddPort({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Add)
		return axios.post(`/v3/ipms/port`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('AddPort ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('AddPort ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('AddPort ERROR:', error)
					return Promise.reject(error)
				}
				console.log('AddPort UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in AddPort'))
			})
	},

	/**
	* RPC Method: AddPortNumber
	* Description: Add a PortNumber object. Note SPID is an IPMS SPID, not our SPID.
	* HTTP Method: POST
	* API Path: /api/v3/ipms/port_number
	* @param    {PortNumber} req The API payload object (PortNumber)
	* @return   {PortNumber} The API response object (PortNumber)
	*
	* @typedef  {Object}  PortNumber
	* @property {String}  UUID                            
	* @property {Number}  SPID                            Default Service Provider ID
	* @property {Number}  SOM                             
	* @property {Number}  CreatedAtNanos                  When the object was first created
	* @property {Number}  UpdatedAtNanos                  When the object was last updated
	* @property {Number}  DeletedAtNanos                  When the object was deleted
	* @property {Number}  GainingCarrierID                
	* @property {Number}  LosingCarrierID                 
	* @property {String}  PhoneNumber                     
	* @property {String}  HandsetReference                
	* @property {String}  PortUUID                        PortDirection Direction = 11;,  int32 HOC = 12 [(validator.field) = {,      int_gt: 0,      human_error: "Must have a HOC",  }];,  string ServiceType = 13 [(validator.field) = {,      string_not_empty: true,,      human_error: "Must have a ServiceType of FIXED or MOBILE",,  }];
	* @property {ENUM}    GainingCarrierProgressStatus    
	* @property {ENUM}    LosingCarrierProgressStatus     
	* @property {ENUM}    TestedAndCompleteProgressStatus 
	*
	*/
	AddPortNumber({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Add)
		return axios.post(`/v3/ipms/port_number`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('AddPortNumber ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('AddPortNumber ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('AddPortNumber ERROR:', error)
					return Promise.reject(error)
				}
				console.log('AddPortNumber UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in AddPortNumber'))
			})
	},

	/**
	* RPC Method: AddThirdPartyNetworkUpdate
	* Description: Add a ThirdPartyNetworkUpdate object
	* HTTP Method: POST
	* API Path: /api/v3/ipms/third_party_network_update
	* @param    {ThirdPartyNetworkUpdate} req The API payload object (ThirdPartyNetworkUpdate)
	* @return   {ThirdPartyNetworkUpdate} The API response object (ThirdPartyNetworkUpdate)
	*
	* @typedef  {Object}  ThirdPartyNetworkUpdate
	* @property {String}  UUID                 
	* @property {Number}  SPID                 Default Service Provider ID
	* @property {Number}  SOM                  
	* @property {Number}  ConfirmingCarrierID  
	* @property {Number}  ConfirmationDueNanos 
	* @property {String}  Type                 
	* @property {Number}  CreatedAtNanos       
	* @property {Number}  UpdatedAtNanos       
	* @property {Number}  Attempts             
	*
	*/
	AddThirdPartyNetworkUpdate({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Add)
		return axios.post(`/v3/ipms/third_party_network_update`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('AddThirdPartyNetworkUpdate ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('AddThirdPartyNetworkUpdate ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('AddThirdPartyNetworkUpdate ERROR:', error)
					return Promise.reject(error)
				}
				console.log('AddThirdPartyNetworkUpdate UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in AddThirdPartyNetworkUpdate'))
			})
	},

	/**
	* RPC Method: AddThirdPartyNetworkUpdateNumber
	* Description: Add a ThirdPartyNetworkUpdateNumber object
	* HTTP Method: POST
	* API Path: /api/v3/ipms/third_party_network_update_number
	* @param    {ThirdPartyNetworkUpdateNumber} req The API payload object (ThirdPartyNetworkUpdateNumber)
	* @return   {ThirdPartyNetworkUpdateNumber} The API response object (ThirdPartyNetworkUpdateNumber)
	*
	* @typedef  {Object}  ThirdPartyNetworkUpdateNumber
	* @property {String}  UUID                        
	* @property {Number}  SPID                        Default Service Provider ID
	* @property {Number}  SOM                         
	* @property {Number}  ConfirmingCarrierID         
	* @property {Number}  DonorCarrierID              
	* @property {Number}  GainingCarrierID            
	* @property {Number}  LosingCarrierID             
	* @property {String}  PhoneNumber                 
	* @property {String}  ThirdPartyNetworkUpdateUUID 
	* @property {String}  Type                        
	* @property {Number}  CreatedAtNanos              
	* @property {Number}  UpdatedAtNanos              
	*
	*/
	AddThirdPartyNetworkUpdateNumber({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Add)
		return axios.post(`/v3/ipms/third_party_network_update_number`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('AddThirdPartyNetworkUpdateNumber ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('AddThirdPartyNetworkUpdateNumber ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('AddThirdPartyNetworkUpdateNumber ERROR:', error)
					return Promise.reject(error)
				}
				console.log('AddThirdPartyNetworkUpdateNumber UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in AddThirdPartyNetworkUpdateNumber'))
			})
	},

	/**
	* RPC Method: DeletePortByUUID
	* Description: Deletes a Port object. Requires JWT. Note SPID is an IPMS SPID, not our SPID.
	* HTTP Method: DELETE
	* API Path: /api/v3/ipms/port/${req.UUID}
	* @param    {PortUUIDSearch} req The API payload object (PortUUIDSearch)
	* @return   {Port} The API response object (Port)
	*
	* @typedef  {Object}  PortUUIDSearch
	* @property {String}  UUID        
	* @property {Number}  SPID        Service Provider ID - always required
	* @property {String}  Description (optional) Description
	*
	* @typedef  {Object}  Port
	* @property {String}  UUID                              
	* @property {Number}  SPID                              Default Service Provider ID
	* @property {Number}  SOM                               
	* @property {String}  AccountNumber                     
	* @property {String}  AdditionalCustomerInfo            
	* @property {String}  Category                          
	* @property {String}  CustomerContactName               
	* @property {String}  CustomerContactNumber             
	* @property {String}  CustomerExistingServiceAddress    
	* @property {String}  CustomerName                      
	* @property {String}  GainingServiceProviderContact     
	* @property {String}  GspInternalReference              
	* @property {String}  LspInternalReference              
	* @property {Boolean} LspOverride                       
	* @property {Boolean} PrePayPrePaid                     
	* @property {Number}  RfsDateTimeStartNanos             
	* @property {Number}  ActionDueDateTimeNanos            Converted from IPMS DurationData
	* @property {Number}  SOMGainingServiceProviderId       
	* @property {Boolean} EmergencyReturn                   
	* @property {Number}  SOMLosingServiceProviderId        
	* @property {String}  SOMStatus                         
	* @property {Number}  SOMStatusDateTimeNanos            
	* @property {String}  SOMType                           
	* @property {Number}  SOMCompletedStateEnteredDateNanos 
	* @property {Number}  CreatedAtNanos                    
	* @property {Number}  UpdatedAtNanos                    
	*
	*/
	DeletePortByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Delete)
		return axios.delete(`/v3/ipms/port/${req.UUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSavePort', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('DeletePortByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('DeletePortByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('DeletePortByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('DeletePortByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in DeletePortByUUID'))
			})
	},

	/**
	* RPC Method: DeletePortNumberByUUID
	* Description: Deletes a PortNumber object. Requires JWT. Note SPID is an IPMS SPID, not our SPID.
	* HTTP Method: DELETE
	* API Path: /api/v3/ipms/port_number/${req.UUID}
	* @param    {PortNumberUUIDSearch} req The API payload object (PortNumberUUIDSearch)
	* @return   {PortNumber} The API response object (PortNumber)
	*
	* @typedef  {Object}  PortNumberUUIDSearch
	* @property {String}  UUID        
	* @property {Number}  SPID        Service Provider ID - always required
	* @property {String}  Description (optional) Description
	*
	* @typedef  {Object}  PortNumber
	* @property {String}  UUID                            
	* @property {Number}  SPID                            Default Service Provider ID
	* @property {Number}  SOM                             
	* @property {Number}  CreatedAtNanos                  When the object was first created
	* @property {Number}  UpdatedAtNanos                  When the object was last updated
	* @property {Number}  DeletedAtNanos                  When the object was deleted
	* @property {Number}  GainingCarrierID                
	* @property {Number}  LosingCarrierID                 
	* @property {String}  PhoneNumber                     
	* @property {String}  HandsetReference                
	* @property {String}  PortUUID                        PortDirection Direction = 11;,  int32 HOC = 12 [(validator.field) = {,      int_gt: 0,      human_error: "Must have a HOC",  }];,  string ServiceType = 13 [(validator.field) = {,      string_not_empty: true,,      human_error: "Must have a ServiceType of FIXED or MOBILE",,  }];
	* @property {ENUM}    GainingCarrierProgressStatus    
	* @property {ENUM}    LosingCarrierProgressStatus     
	* @property {ENUM}    TestedAndCompleteProgressStatus 
	*
	*/
	DeletePortNumberByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Delete)
		return axios.delete(`/v3/ipms/port_number/${req.UUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSavePortNumber', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('DeletePortNumberByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('DeletePortNumberByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('DeletePortNumberByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('DeletePortNumberByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in DeletePortNumberByUUID'))
			})
	},

	/**
	* RPC Method: DeleteThirdPartyNetworkUpdateByUUID
	* Description: Deletes a ThirdPartyNetworkUpdate object from the databa
	* HTTP Method: DELETE
	* API Path: /api/v3/third_party_network_update/${req.UUID}
	* @param    {ThirdPartyNetworkUpdateUUIDSearch} req The API payload object (ThirdPartyNetworkUpdateUUIDSearch)
	* @return   {ThirdPartyNetworkUpdate} The API response object (ThirdPartyNetworkUpdate)
	*
	* @typedef  {Object}  ThirdPartyNetworkUpdateUUIDSearch
	* @property {String}  UUID 
	* @property {Number}  SPID 
	* @property {Number}  SOM  
	*
	* @typedef  {Object}  ThirdPartyNetworkUpdate
	* @property {String}  UUID                 
	* @property {Number}  SPID                 Default Service Provider ID
	* @property {Number}  SOM                  
	* @property {Number}  ConfirmingCarrierID  
	* @property {Number}  ConfirmationDueNanos 
	* @property {String}  Type                 
	* @property {Number}  CreatedAtNanos       
	* @property {Number}  UpdatedAtNanos       
	* @property {Number}  Attempts             
	*
	*/
	DeleteThirdPartyNetworkUpdateByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Delete)
		return axios.delete(`/v3/third_party_network_update/${req.UUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveThirdPartyNetworkUpdate', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('DeleteThirdPartyNetworkUpdateByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('DeleteThirdPartyNetworkUpdateByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('DeleteThirdPartyNetworkUpdateByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('DeleteThirdPartyNetworkUpdateByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in DeleteThirdPartyNetworkUpdateByUUID'))
			})
	},

	/**
	* RPC Method: DeleteThirdPartyNetworkUpdateNumberByUUID
	* Description: Deletes a ThirdPartyNetworkUpdateNumber object from the database
	* HTTP Method: DELETE
	* API Path: /api/v3/third_party_network_update_number/${req.UUID}
	* @param    {ThirdPartyNetworkUpdateNumberUUIDSearch} req The API payload object (ThirdPartyNetworkUpdateNumberUUIDSearch)
	* @return   {ThirdPartyNetworkUpdateNumber} The API response object (ThirdPartyNetworkUpdateNumber)
	*
	* @typedef  {Object}  ThirdPartyNetworkUpdateNumberUUIDSearch
	* @property {String}  UUID 
	* @property {Number}  SPID 
	* @property {Number}  SOM  
	*
	* @typedef  {Object}  ThirdPartyNetworkUpdateNumber
	* @property {String}  UUID                        
	* @property {Number}  SPID                        Default Service Provider ID
	* @property {Number}  SOM                         
	* @property {Number}  ConfirmingCarrierID         
	* @property {Number}  DonorCarrierID              
	* @property {Number}  GainingCarrierID            
	* @property {Number}  LosingCarrierID             
	* @property {String}  PhoneNumber                 
	* @property {String}  ThirdPartyNetworkUpdateUUID 
	* @property {String}  Type                        
	* @property {Number}  CreatedAtNanos              
	* @property {Number}  UpdatedAtNanos              
	*
	*/
	DeleteThirdPartyNetworkUpdateNumberByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Delete)
		return axios.delete(`/v3/third_party_network_update_number/${req.UUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveThirdPartyNetworkUpdateNumber', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('DeleteThirdPartyNetworkUpdateNumberByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('DeleteThirdPartyNetworkUpdateNumberByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('DeleteThirdPartyNetworkUpdateNumberByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('DeleteThirdPartyNetworkUpdateNumberByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in DeleteThirdPartyNetworkUpdateNumberByUUID'))
			})
	},

	/**
	* RPC Method: GetCarriers
	* Description: Gets a list of all Carriers in the TCF if the companyId parameter is zero. If companyId is not zero then returns only Carriers for that Company.
	* HTTP Method: GET
	* API Path: /api/v3/${req.SPID}/ipms/get_carriers
	* @param    {GetCarriersRequest} req The API payload object (GetCarriersRequest)
	* @return   {GetCarriersResponse} The API response object (GetCarriersResponse)
	*
	* @typedef  {Object}  GetCarriersRequest
	* @property {Number}  SPID      
	* @property {Number}  CompanyID 
	*
	* @typedef  {Object}  GetCarriersResponse
	* @property {CarrierData[]} Carriers 
	*
	*/
	GetCarriers({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		// Prepare URI params...
		let paramSPID = encodeURIComponent(req.SPID)
		return axios.get(`/v3/${paramSPID}/ipms/get_carriers`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetCarriers ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetCarriers ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetCarriers ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetCarriers UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetCarriers'))
			})
	},

	/**
	* RPC Method: GetCompanies
	* Description: This method gets a list of all companies in the TCF.
	* HTTP Method: GET
	* API Path: /api/v3/${req.SPID}/ipms/get_companies
	* @param    {GetCompaniesRequest} req The API payload object (GetCompaniesRequest)
	* @return   {GetCompaniesResponse} The API response object (GetCompaniesResponse)
	*
	* @typedef  {Object}  GetCompaniesRequest
	* @property {Number}  SPID 
	*
	* @typedef  {Object}  GetCompaniesResponse
	* @property {CompanyData[]} CompanyData 
	*
	*/
	GetCompanies({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		// Prepare URI params...
		let paramSPID = encodeURIComponent(req.SPID)
		return axios.get(`/v3/${paramSPID}/ipms/get_companies`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetCompanies ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetCompanies ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetCompanies ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetCompanies UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetCompanies'))
			})
	},

	/**
	* RPC Method: GetNetworkUpdates
	* Description: This method allows a carrier to get a list of network updates that they have not yet handled.
	* HTTP Method: GET
	* API Path: /api/v3/${req.SPID}/ipms/get_network_updates
	* @param    {GetNetworkUpdatesRequest} req The API payload object (GetNetworkUpdatesRequest)
	* @return   {GetNetworkUpdatesResponse} The API response object (GetNetworkUpdatesResponse)
	*
	* @typedef  {Object}  GetNetworkUpdatesRequest
	* @property {Number}  SPID       
	* @property {Number}  SOM        
	* @property {String}  SOMType    
	* @property {Int32Slice} CarrierIDs 
	*
	* @typedef  {Object}  GetNetworkUpdatesResponse
	* @property {NetworkUpdateData[]} NetworkUpdateDataList 
	*
	*/
	GetNetworkUpdates({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		// Prepare URI params...
		let paramSPID = encodeURIComponent(req.SPID)
		return axios.get(`/v3/${paramSPID}/ipms/get_network_updates`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetNetworkUpdates ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetNetworkUpdates ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetNetworkUpdates ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetNetworkUpdates UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetNetworkUpdates'))
			})
	},

	/**
	* RPC Method: GetPortAndNumberDetail
	* Description: Get full carrier port and individual number info. Port information is replicated per number.
	* HTTP Method: GET
	* API Path: /api/v3/ipms/port/full_port_and_number/${req.SOM}/${req.PhoneNumber}
	* @param    {PortAndPortNumberDetailSearch} req The API payload object (PortAndPortNumberDetailSearch)
	* @return   {PortAndPortNumberDetail} The API response object (PortAndPortNumberDetail)
	*
	* @typedef  {Object}  PortAndPortNumberDetailSearch
	* @property {Number}  SPID        Service Provider ID - always required
	* @property {Number}  SOM         
	* @property {String}  PhoneNumber 
	*
	* @typedef  {Object}  PortAndPortNumberDetail
	* @property {Port} Port       
	* @property {PortNumber} PortNumber Only one number with multiple messages
	*
	*/
	GetPortAndNumberDetail({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		// Prepare URI params...
		let paramSOM = encodeURIComponent(req.SOM)
		let paramPhoneNumber = encodeURIComponent(req.PhoneNumber)
		return axios.get(`/v3/ipms/port/full_port_and_number/${paramSOM}/${paramPhoneNumber}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetPortAndNumberDetail ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetPortAndNumberDetail ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetPortAndNumberDetail ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetPortAndNumberDetail UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetPortAndNumberDetail'))
			})
	},

	/**
	* RPC Method: GetPortBySOM
	* Description: Get a full number port by SOM with numbers
	* HTTP Method: GET
	* API Path: /api/v3/ipms/port/bysom/${req.SOM}
	* @param    {PortSOMSearch} req The API payload object (PortSOMSearch)
	* @return   {PortWithNumbers} The API response object (PortWithNumbers)
	*
	* @typedef  {Object}  PortSOMSearch
	* @property {Number}  SPID Service Provider ID - always required
	* @property {Number}  SOM  
	*
	* @typedef  {Object}  PortWithNumbers
	* @property {Port} Port        
	* @property {PortNumber[]} PortNumbers 
	*
	*/
	GetPortBySOM({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		// Prepare URI params...
		let paramSOM = encodeURIComponent(req.SOM)
		return axios.get(`/v3/ipms/port/bysom/${paramSOM}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetPortBySOM ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetPortBySOM ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetPortBySOM ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetPortBySOM UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetPortBySOM'))
			})
	},

	/**
	* RPC Method: GetPortByUUID
	* Description: Get a single carrier Port object using the UUID
	* HTTP Method: GET
	* API Path: /api/v3/ipms/port/${req.UUID}
	* @param    {PortUUIDSearch} req The API payload object (PortUUIDSearch)
	* @return   {Port} The API response object (Port)
	*
	* @typedef  {Object}  PortUUIDSearch
	* @property {String}  UUID        
	* @property {Number}  SPID        Service Provider ID - always required
	* @property {String}  Description (optional) Description
	*
	* @typedef  {Object}  Port
	* @property {String}  UUID                              
	* @property {Number}  SPID                              Default Service Provider ID
	* @property {Number}  SOM                               
	* @property {String}  AccountNumber                     
	* @property {String}  AdditionalCustomerInfo            
	* @property {String}  Category                          
	* @property {String}  CustomerContactName               
	* @property {String}  CustomerContactNumber             
	* @property {String}  CustomerExistingServiceAddress    
	* @property {String}  CustomerName                      
	* @property {String}  GainingServiceProviderContact     
	* @property {String}  GspInternalReference              
	* @property {String}  LspInternalReference              
	* @property {Boolean} LspOverride                       
	* @property {Boolean} PrePayPrePaid                     
	* @property {Number}  RfsDateTimeStartNanos             
	* @property {Number}  ActionDueDateTimeNanos            Converted from IPMS DurationData
	* @property {Number}  SOMGainingServiceProviderId       
	* @property {Boolean} EmergencyReturn                   
	* @property {Number}  SOMLosingServiceProviderId        
	* @property {String}  SOMStatus                         
	* @property {Number}  SOMStatusDateTimeNanos            
	* @property {String}  SOMType                           
	* @property {Number}  SOMCompletedStateEnteredDateNanos 
	* @property {Number}  CreatedAtNanos                    
	* @property {Number}  UpdatedAtNanos                    
	*
	*/
	GetPortByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Get)
		// Prepare URI params...
		let paramUUID = encodeURIComponent(req.UUID)
		return axios.get(`/v3/ipms/port/${paramUUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSavePort', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetPortByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetPortByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetPortByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetPortByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetPortByUUID'))
			})
	},

	/**
	* RPC Method: GetPortNumberByUUID
	* Description: Get a single PortNumber object using the UUID. Note SPID is an IPMS SPID, not our SPID.
	* HTTP Method: GET
	* API Path: /api/v3/ipms/port_number/${req.UUID}
	* @param    {PortNumberUUIDSearch} req The API payload object (PortNumberUUIDSearch)
	* @return   {PortNumber} The API response object (PortNumber)
	*
	* @typedef  {Object}  PortNumberUUIDSearch
	* @property {String}  UUID        
	* @property {Number}  SPID        Service Provider ID - always required
	* @property {String}  Description (optional) Description
	*
	* @typedef  {Object}  PortNumber
	* @property {String}  UUID                            
	* @property {Number}  SPID                            Default Service Provider ID
	* @property {Number}  SOM                             
	* @property {Number}  CreatedAtNanos                  When the object was first created
	* @property {Number}  UpdatedAtNanos                  When the object was last updated
	* @property {Number}  DeletedAtNanos                  When the object was deleted
	* @property {Number}  GainingCarrierID                
	* @property {Number}  LosingCarrierID                 
	* @property {String}  PhoneNumber                     
	* @property {String}  HandsetReference                
	* @property {String}  PortUUID                        PortDirection Direction = 11;,  int32 HOC = 12 [(validator.field) = {,      int_gt: 0,      human_error: "Must have a HOC",  }];,  string ServiceType = 13 [(validator.field) = {,      string_not_empty: true,,      human_error: "Must have a ServiceType of FIXED or MOBILE",,  }];
	* @property {ENUM}    GainingCarrierProgressStatus    
	* @property {ENUM}    LosingCarrierProgressStatus     
	* @property {ENUM}    TestedAndCompleteProgressStatus 
	*
	*/
	GetPortNumberByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Get)
		// Prepare URI params...
		let paramUUID = encodeURIComponent(req.UUID)
		return axios.get(`/v3/ipms/port_number/${paramUUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSavePortNumber', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetPortNumberByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetPortNumberByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetPortNumberByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetPortNumberByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetPortNumberByUUID'))
			})
	},

	/**
	* RPC Method: GetPortNumbersPaginated
	* Description: Searches for multiple PortNumber objects using a search request. Note SPID is an IPMS SPID, not our SPID.
	* HTTP Method: GET
	* API Path: /api/v3/ipms/port_number
	* @param    {PortNumberSearchRequest} req The API payload object (PortNumberSearchRequest)
	* @return   {PortNumberPageResponse} The API response object (PortNumberPageResponse)
	*
	* @typedef  {Object}  PortNumberSearchRequest
	* @property {SearchOptions[]} SearchOptions  Search options to use (for sorting and/or pagination)
	* @property {Boolean} IncludeDeleted IncludeDeleted field present on the search request ensures that deleted records (records with DeleteAtNanos > 0) are not returned unless this is set
	* @property {Number}  SPID           (optional) SPID
	* @property {String}  Description    (optional) Description
	*
	* @typedef  {Object}  SearchOptions  Search options
	* @property {Number}  PageNumber     The page number to start at (or provide IndexStart)
	* @property {Number}  PageSize       The page size
	* @property {Number}  IndexStart     The record number to start at (or provide PageNumber)
	* @property {String}  SortBy         The field to sort by
	* @property {Boolean} SortDesc       Is the sort direction descending?
	*
	* @typedef  {Object}  PortNumberPageResponse
	* @property {PageInfo} PageInfo    PageInfo only needs the values ThisPageNumber && PageSize set
	* @property {PortNumber[]} PortNumbers 
	*
	* @typedef  {Object}   PageInfo
	* @property {Number}   PageNumber             The page number
	* @property {Number}   PageItemCount          Number of items on this page
	* @property {Number}   TotalPages             Total pages available
	* @property {Number}   TotalItemCount         Total items available
	* @property {Number}   IndexStart             The record number this search started at
	* @property {Number}   PageSize               The length of the page
	* @property {Number[]} DisplayPageList        The array of page numbers to display
	* @property {Number}   DisplayPagesEitherSide Number of pages to display either side
	*
	*/
	GetPortNumbersPaginated({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Find)
		return axios.get(`/v3/ipms/port_number`, { params: req, validateStatus: valFuncFind }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			commit('mutSavePortNumbers', { Response: response.data })
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetPortNumbersPaginated ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetPortNumbersPaginated ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetPortNumbersPaginated ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetPortNumbersPaginated UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetPortNumbersPaginated'))
			})
	},

	/**
	* RPC Method: GetPortsPaginated
	* Description: Searches for multiple carrier Port objects using a search request. Note SPID is an IPMS SPID, not our SPID.
	* HTTP Method: GET
	* API Path: /api/v3/ipms/port
	* @param    {PortSearchRequest} req The API payload object (PortSearchRequest)
	* @return   {PortPageResponse} The API response object (PortPageResponse)
	*
	* @typedef  {Object}  PortSearchRequest
	* @property {SearchOptions[]} SearchOptions  Search options to use (for sorting and/or pagination)
	* @property {Boolean} IncludeDeleted IncludeDeleted field present on the search request ensures that deleted records (records with DeleteAtNanos > 0) are not returned unless this is set
	* @property {Number}  SPID           (optional) SPID
	* @property {String}  Description    (optional) Description
	*
	* @typedef  {Object}  SearchOptions  Search options
	* @property {Number}  PageNumber     The page number to start at (or provide IndexStart)
	* @property {Number}  PageSize       The page size
	* @property {Number}  IndexStart     The record number to start at (or provide PageNumber)
	* @property {String}  SortBy         The field to sort by
	* @property {Boolean} SortDesc       Is the sort direction descending?
	*
	* @typedef  {Object}  PortPageResponse
	* @property {PageInfo} PageInfo PageInfo only needs the values ThisPageNumber && PageSize set
	* @property {Port[]} Ports    
	*
	* @typedef  {Object}   PageInfo
	* @property {Number}   PageNumber             The page number
	* @property {Number}   PageItemCount          Number of items on this page
	* @property {Number}   TotalPages             Total pages available
	* @property {Number}   TotalItemCount         Total items available
	* @property {Number}   IndexStart             The record number this search started at
	* @property {Number}   PageSize               The length of the page
	* @property {Number[]} DisplayPageList        The array of page numbers to display
	* @property {Number}   DisplayPagesEitherSide Number of pages to display either side
	*
	*/
	GetPortsPaginated({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Find)
		return axios.get(`/v3/ipms/port`, { params: req, validateStatus: valFuncFind }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			commit('mutSavePorts', { Response: response.data })
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetPortsPaginated ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetPortsPaginated ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetPortsPaginated ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetPortsPaginated UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetPortsPaginated'))
			})
	},

	/**
	* RPC Method: GetSOMsByStatus
	* Description: Gets a list of SOMs from the database as specified by status
	* HTTP Method: GET
	* API Path: /api/v3/ipms/port/som/by_status
	* @param    {SOMsByStatusRequest} req The API payload object (SOMsByStatusRequest)
	* @return   {SOMsByStatusResponse} The API response object (SOMsByStatusResponse)
	*
	* @typedef  {Object}  SOMsByStatusRequest
	* @property {Number}  SPID      Service Provider ID - always required
	* @property {String}  SOMStatus 
	*
	* @typedef  {Object}  SOMsByStatusResponse
	* @property {Number}  SPID      
	* @property {Number}  SOMs      
	* @property {String}  SOMStatus 
	*
	*/
	GetSOMsByStatus({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.get(`/v3/ipms/port/som/by_status`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetSOMsByStatus ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetSOMsByStatus ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetSOMsByStatus ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetSOMsByStatus UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetSOMsByStatus'))
			})
	},

	/**
	* RPC Method: GetServiceProviderApprovedNumberPortChangesPaginated
	* Description: Searches for multiple ServiceProviderApprovedNumberPortChange objects using a search request. Unless deleted ServiceProviderApprovedPortChange are included, only ServiceProviderApprovedPortChange in progress will be displayed.
	* HTTP Method: GET
	* API Path: /api/v3/ipms/service_provider_approved_number_port_change
	* @param    {ServiceProviderApprovedNumberPortChangeSearchRequest} req The API payload object (ServiceProviderApprovedNumberPortChangeSearchRequest)
	* @return   {ServiceProviderApprovedNumberPortChangePageResponse} The API response object (ServiceProviderApprovedNumberPortChangePageResponse)
	*
	* @typedef  {Object}  ServiceProviderApprovedNumberPortChangeSearchRequest
	* @property {SearchOptions[]} SearchOptions  Search options to use (for sorting and/or pagination)
	* @property {Boolean} IncludeDeleted IncludeDeleted field present on the search request ensures that deleted records (records with DeleteAtNanos > 0) are not returned unless this is set
	* @property {Number}  SPID           (optional) SPID
	* @property {String}  Description    (optional) Description
	*
	* @typedef  {Object}  SearchOptions  Search options
	* @property {Number}  PageNumber     The page number to start at (or provide IndexStart)
	* @property {Number}  PageSize       The page size
	* @property {Number}  IndexStart     The record number to start at (or provide PageNumber)
	* @property {String}  SortBy         The field to sort by
	* @property {Boolean} SortDesc       Is the sort direction descending?
	*
	* @typedef  {Object}  ServiceProviderApprovedNumberPortChangePageResponse
	* @property {PageInfo} PageInfo                                 PageInfo only needs the values ThisPageNumber && PageSize set
	* @property {ServiceProviderApprovedNumberPortChange[]} ServiceProviderApprovedNumberPortChanges 
	*
	* @typedef  {Object}   PageInfo
	* @property {Number}   PageNumber             The page number
	* @property {Number}   PageItemCount          Number of items on this page
	* @property {Number}   TotalPages             Total pages available
	* @property {Number}   TotalItemCount         Total items available
	* @property {Number}   IndexStart             The record number this search started at
	* @property {Number}   PageSize               The length of the page
	* @property {Number[]} DisplayPageList        The array of page numbers to display
	* @property {Number}   DisplayPagesEitherSide Number of pages to display either side
	*
	*/
	GetServiceProviderApprovedNumberPortChangesPaginated({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Find)
		return axios.get(`/v3/ipms/service_provider_approved_number_port_change`, { params: req, validateStatus: valFuncFind }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			commit('mutSaveServiceProviderApprovedNumberPortChanges', { Response: response.data })
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetServiceProviderApprovedNumberPortChangesPaginated ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetServiceProviderApprovedNumberPortChangesPaginated ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetServiceProviderApprovedNumberPortChangesPaginated ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetServiceProviderApprovedNumberPortChangesPaginated UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetServiceProviderApprovedNumberPortChangesPaginated'))
			})
	},

	/**
	* RPC Method: GetServiceProviderApprovedPortChangesPaginated
	* Description: Searches for multiple ServiceProviderApprovedPortChange objects using a search request. Unless deleted ServiceProviderApprovedPortChanges are included, only ServiceProviderApprovedPortChanges in progress will be displayed.
	* HTTP Method: GET
	* API Path: /api/v3/ipms/service_provider_approved_port_change
	* @param    {ServiceProviderApprovedPortChangeSearchRequest} req The API payload object (ServiceProviderApprovedPortChangeSearchRequest)
	* @return   {ServiceProviderApprovedPortChangePageResponse} The API response object (ServiceProviderApprovedPortChangePageResponse)
	*
	* @typedef  {Object}  ServiceProviderApprovedPortChangeSearchRequest
	* @property {SearchOptions[]} SearchOptions  Search options to use (for sorting and/or pagination)
	* @property {Boolean} IncludeDeleted IncludeDeleted field present on the search request ensures that deleted records (records with DeleteAtNanos > 0) are not returned unless this is set
	* @property {Number}  SPID           (optional) SPID
	* @property {String}  Description    (optional) Description
	*
	* @typedef  {Object}  SearchOptions  Search options
	* @property {Number}  PageNumber     The page number to start at (or provide IndexStart)
	* @property {Number}  PageSize       The page size
	* @property {Number}  IndexStart     The record number to start at (or provide PageNumber)
	* @property {String}  SortBy         The field to sort by
	* @property {Boolean} SortDesc       Is the sort direction descending?
	*
	* @typedef  {Object}  ServiceProviderApprovedPortChangePageResponse
	* @property {PageInfo} PageInfo                           PageInfo only needs the values ThisPageNumber && PageSize set
	* @property {ServiceProviderApprovedPortChange[]} ServiceProviderApprovedPortChanges 
	*
	* @typedef  {Object}   PageInfo
	* @property {Number}   PageNumber             The page number
	* @property {Number}   PageItemCount          Number of items on this page
	* @property {Number}   TotalPages             Total pages available
	* @property {Number}   TotalItemCount         Total items available
	* @property {Number}   IndexStart             The record number this search started at
	* @property {Number}   PageSize               The length of the page
	* @property {Number[]} DisplayPageList        The array of page numbers to display
	* @property {Number}   DisplayPagesEitherSide Number of pages to display either side
	*
	*/
	GetServiceProviderApprovedPortChangesPaginated({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Find)
		return axios.get(`/v3/ipms/service_provider_approved_port_change`, { params: req, validateStatus: valFuncFind }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			commit('mutSaveServiceProviderApprovedPortChanges', { Response: response.data })
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetServiceProviderApprovedPortChangesPaginated ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetServiceProviderApprovedPortChangesPaginated ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetServiceProviderApprovedPortChangesPaginated ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetServiceProviderApprovedPortChangesPaginated UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetServiceProviderApprovedPortChangesPaginated'))
			})
	},

	/**
	* RPC Method: GetServiceProviderNumberPortsPaginated
	* Description: Searches for multiple PortNumber objects using a search request. Unless deleted PortNumbers are included, only PortNumbers in progress will be displayed.
	* HTTP Method: POST
	* API Path: /api/v3/ipms/service_provider_number_port
	* @param    {ServiceProviderNumberPortSearchRequest} req The API payload object (ServiceProviderNumberPortSearchRequest)
	* @return   {ServiceProviderNumberPortPageResponse} The API response object (ServiceProviderNumberPortPageResponse)
	*
	* @typedef  {Object}  ServiceProviderNumberPortSearchRequest
	* @property {SearchOptions[]} SearchOptions  Search options to use (for sorting and/or pagination)
	* @property {Boolean} IncludeDeleted IncludeDeleted field present on the search request ensures that deleted records (records with DeleteAtNanos > 0) are not returned unless this is set
	* @property {Number}  SPID           (optional) SPID
	* @property {String}  Description    (optional) Description
	* @property {String}  PortUUID       ServiceProviderUUID of the associated ServiceProviderPort
	*
	* @typedef  {Object}  SearchOptions  Search options
	* @property {Number}  PageNumber     The page number to start at (or provide IndexStart)
	* @property {Number}  PageSize       The page size
	* @property {Number}  IndexStart     The record number to start at (or provide PageNumber)
	* @property {String}  SortBy         The field to sort by
	* @property {Boolean} SortDesc       Is the sort direction descending?
	*
	* @typedef  {Object}  ServiceProviderNumberPortPageResponse
	* @property {PageInfo} PageInfo                   PageInfo only needs the values ThisPageNumber && PageSize set
	* @property {ServiceProviderNumberPort[]} ServiceProviderNumberPorts 
	*
	* @typedef  {Object}   PageInfo
	* @property {Number}   PageNumber             The page number
	* @property {Number}   PageItemCount          Number of items on this page
	* @property {Number}   TotalPages             Total pages available
	* @property {Number}   TotalItemCount         Total items available
	* @property {Number}   IndexStart             The record number this search started at
	* @property {Number}   PageSize               The length of the page
	* @property {Number[]} DisplayPageList        The array of page numbers to display
	* @property {Number}   DisplayPagesEitherSide Number of pages to display either side
	*
	*/
	GetServiceProviderNumberPortsPaginated({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Find)
		return axios.post(`/v3/ipms/service_provider_number_port`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			commit('mutSaveServiceProviderNumberPorts', { Response: response.data })
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetServiceProviderNumberPortsPaginated ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetServiceProviderNumberPortsPaginated ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetServiceProviderNumberPortsPaginated ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetServiceProviderNumberPortsPaginated UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetServiceProviderNumberPortsPaginated'))
			})
	},

	/**
	* RPC Method: GetServiceProviderPortByUUID
	* Description: Get a single Service Provider Port object using the UUID
	* HTTP Method: GET
	* API Path: /api/v3/ipms/service_provider_port/${req.UUID}
	* @param    {ServiceProviderPortUUIDSearch} req The API payload object (ServiceProviderPortUUIDSearch)
	* @return   {ServiceProviderPort} The API response object (ServiceProviderPort)
	*
	* @typedef  {Object}  ServiceProviderPortUUIDSearch
	* @property {String}  UUID        
	* @property {Number}  SPID        Service Provider ID - always required
	* @property {String}  Description (optional) Description
	*
	* @typedef  {Object}  ServiceProviderPort
	* @property {String}  UUID                           
	* @property {Number}  SPID                           IQHive Service Provider ID for this port
	* @property {Number}  CreatedAtNanos                 When the object was first created
	* @property {Number}  UpdatedAtNanos                 When the object was last updated
	* @property {Number}  DeletedAtNanos                 When the object was deleted
	* @property {String}  AccountNumber                  Customers Postpay account number requesting this port
	* @property {String}  AdditionalCustomerInfo         
	* @property {String}  Category                       
	* @property {String}  CustomerContactName            
	* @property {String}  CustomerContactNumber          
	* @property {String}  CustomerExistingServiceAddress 
	* @property {String}  CustomerName                   
	* @property {String}  GainingServiceProviderContact  
	* @property {String}  GspInternalReference           
	* @property {Number}  LosingServiceProviderID        
	* @property {Number}  GainingCarrierID               
	* @property {Number}  LosingCarrierID                
	* @property {Boolean} IsLspOverride                  
	* @property {Boolean} PrePayPrePaid                  
	* @property {Number}  RfsDateTimeStartNanos          
	* @property {Number}  SOM                            
	* @property {String}  ProductType                    
	* @property {String}  Status                         
	* @property {String}  ApprovedPortChangeState        
	* @property {Boolean} IsPortIn                       
	* @property {Number}  Version                        
	* @property {String}  Comments                       
	* @property {Number}  IPMSSPID                       IPMS Service Provider ID for this port
	* @property {String}  CustomerUUID                   
	* @property {Number}  IPMSGainingServiceProviderID   IPMS GSPID
	*
	*/
	GetServiceProviderPortByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Get)
		// Prepare URI params...
		let paramUUID = encodeURIComponent(req.UUID)
		return axios.get(`/v3/ipms/service_provider_port/${paramUUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveServiceProviderPort', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetServiceProviderPortByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetServiceProviderPortByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetServiceProviderPortByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetServiceProviderPortByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetServiceProviderPortByUUID'))
			})
	},

	/**
	* RPC Method: GetServiceProviderPortsPaginated
	* Description: Searches for multiple Port objects using a search request. Unless deleted ports are included, only ports in progress will be displayed.
	* HTTP Method: GET
	* API Path: /api/v3/ipms/service_provider_port
	* @param    {ServiceProviderPortSearchRequest} req The API payload object (ServiceProviderPortSearchRequest)
	* @return   {ServiceProviderPortPageResponse} The API response object (ServiceProviderPortPageResponse)
	*
	* @typedef  {Object}  ServiceProviderPortSearchRequest
	* @property {SearchOptions[]} SearchOptions  Search options to use (for sorting and/or pagination)
	* @property {Boolean} IncludeDeleted IncludeDeleted field present on the search request ensures that deleted records (records with DeleteAtNanos > 0) are not returned unless this is set
	* @property {Number}  SPID           (optional) SPID
	* @property {String}  Description    (optional) Description
	* @property {Number}  SOM            
	*
	* @typedef  {Object}  SearchOptions  Search options
	* @property {Number}  PageNumber     The page number to start at (or provide IndexStart)
	* @property {Number}  PageSize       The page size
	* @property {Number}  IndexStart     The record number to start at (or provide PageNumber)
	* @property {String}  SortBy         The field to sort by
	* @property {Boolean} SortDesc       Is the sort direction descending?
	*
	* @typedef  {Object}  ServiceProviderPortPageResponse
	* @property {PageInfo} PageInfo             PageInfo only needs the values ThisPageNumber && PageSize set
	* @property {ServiceProviderPort[]} ServiceProviderPorts 
	*
	* @typedef  {Object}   PageInfo
	* @property {Number}   PageNumber             The page number
	* @property {Number}   PageItemCount          Number of items on this page
	* @property {Number}   TotalPages             Total pages available
	* @property {Number}   TotalItemCount         Total items available
	* @property {Number}   IndexStart             The record number this search started at
	* @property {Number}   PageSize               The length of the page
	* @property {Number[]} DisplayPageList        The array of page numbers to display
	* @property {Number}   DisplayPagesEitherSide Number of pages to display either side
	*
	*/
	GetServiceProviderPortsPaginated({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Find)
		return axios.get(`/v3/ipms/service_provider_port`, { params: req, validateStatus: valFuncFind }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			commit('mutSaveServiceProviderPorts', { Response: response.data })
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetServiceProviderPortsPaginated ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetServiceProviderPortsPaginated ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetServiceProviderPortsPaginated ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetServiceProviderPortsPaginated UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetServiceProviderPortsPaginated'))
			})
	},

	/**
	* RPC Method: GetServiceProviders
	* Description: Gets a list of all Service Providers in the TCF if the companyId parameter is zero. If companyId is not zero then returns only Service Providers for that Company.
	* HTTP Method: GET
	* API Path: /api/v3/${req.SPID}/ipms/get_service_providers
	* @param    {GetServiceProvidersRequest} req The API payload object (GetServiceProvidersRequest)
	* @return   {GetServiceProvidersResponse} The API response object (GetServiceProvidersResponse)
	*
	* @typedef  {Object}  GetServiceProvidersRequest
	* @property {Number}  SPID      
	* @property {Number}  CompanyID 
	* @property {ENUM}    SPType    
	*
	* @typedef  {Object}  GetServiceProvidersResponse
	* @property {ServiceProviderData[]} ServiceProviders 
	*
	*/
	GetServiceProviders({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		// Prepare URI params...
		let paramSPID = encodeURIComponent(req.SPID)
		return axios.get(`/v3/${paramSPID}/ipms/get_service_providers`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetServiceProviders ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetServiceProviders ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetServiceProviders ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetServiceProviders UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetServiceProviders'))
			})
	},

	/**
	* RPC Method: GetThirdPartyNetworkUpdateBySomAndType
	* Description: Get a ThirdPartyNetworkUpdate by SOM from the database
	* HTTP Method: GET
	* API Path: /api/v3/ipms/third_party_network_update/bysom/${req.SOM}
	* @param    {ThirdPartyNetworkUpdateBySomAndTypeRequest} req The API payload object (ThirdPartyNetworkUpdateBySomAndTypeRequest)
	* @return   {ThirdPartyNetworkUpdateBySomAndTypeResponse} The API response object (ThirdPartyNetworkUpdateBySomAndTypeResponse)
	*
	* @typedef  {Object}  ThirdPartyNetworkUpdateBySomAndTypeRequest
	* @property {Number}  SOM  
	* @property {Number}  SPID 
	* @property {String}  Type 
	*
	* @typedef  {Object}  ThirdPartyNetworkUpdateBySomAndTypeResponse
	* @property {ThirdPartyNetworkUpdate} ThirdPartyNetworkUpdate        
	* @property {ThirdPartyNetworkUpdateNumber[]} ThirdPartyNetworkUpdateNumbers 
	*
	*/
	GetThirdPartyNetworkUpdateBySomAndType({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		// Prepare URI params...
		let paramSOM = encodeURIComponent(req.SOM)
		return axios.get(`/v3/ipms/third_party_network_update/bysom/${paramSOM}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetThirdPartyNetworkUpdateBySomAndType ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetThirdPartyNetworkUpdateBySomAndType ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetThirdPartyNetworkUpdateBySomAndType ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetThirdPartyNetworkUpdateBySomAndType UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetThirdPartyNetworkUpdateBySomAndType'))
			})
	},

	/**
	* RPC Method: GetThirdPartyNetworkUpdateByUUID
	* Description: Get a single ThirdPartyNetworkUpdate object using the UUID
	* HTTP Method: GET
	* API Path: /api/v3/third_party_network_update/${req.UUID}
	* @param    {ThirdPartyNetworkUpdateUUIDSearch} req The API payload object (ThirdPartyNetworkUpdateUUIDSearch)
	* @return   {ThirdPartyNetworkUpdate} The API response object (ThirdPartyNetworkUpdate)
	*
	* @typedef  {Object}  ThirdPartyNetworkUpdateUUIDSearch
	* @property {String}  UUID 
	* @property {Number}  SPID 
	* @property {Number}  SOM  
	*
	* @typedef  {Object}  ThirdPartyNetworkUpdate
	* @property {String}  UUID                 
	* @property {Number}  SPID                 Default Service Provider ID
	* @property {Number}  SOM                  
	* @property {Number}  ConfirmingCarrierID  
	* @property {Number}  ConfirmationDueNanos 
	* @property {String}  Type                 
	* @property {Number}  CreatedAtNanos       
	* @property {Number}  UpdatedAtNanos       
	* @property {Number}  Attempts             
	*
	*/
	GetThirdPartyNetworkUpdateByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Get)
		// Prepare URI params...
		let paramUUID = encodeURIComponent(req.UUID)
		return axios.get(`/v3/third_party_network_update/${paramUUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveThirdPartyNetworkUpdate', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetThirdPartyNetworkUpdateByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetThirdPartyNetworkUpdateByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetThirdPartyNetworkUpdateByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetThirdPartyNetworkUpdateByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetThirdPartyNetworkUpdateByUUID'))
			})
	},

	/**
	* RPC Method: GetThirdPartyNetworkUpdateNumberByUUID
	* Description: Get a single ThirdPartyNetworkUpdateNumber object using the UUID
	* HTTP Method: GET
	* API Path: /api/v3/third_party_network_update_number/${req.UUID}
	* @param    {ThirdPartyNetworkUpdateNumberUUIDSearch} req The API payload object (ThirdPartyNetworkUpdateNumberUUIDSearch)
	* @return   {ThirdPartyNetworkUpdateNumber} The API response object (ThirdPartyNetworkUpdateNumber)
	*
	* @typedef  {Object}  ThirdPartyNetworkUpdateNumberUUIDSearch
	* @property {String}  UUID 
	* @property {Number}  SPID 
	* @property {Number}  SOM  
	*
	* @typedef  {Object}  ThirdPartyNetworkUpdateNumber
	* @property {String}  UUID                        
	* @property {Number}  SPID                        Default Service Provider ID
	* @property {Number}  SOM                         
	* @property {Number}  ConfirmingCarrierID         
	* @property {Number}  DonorCarrierID              
	* @property {Number}  GainingCarrierID            
	* @property {Number}  LosingCarrierID             
	* @property {String}  PhoneNumber                 
	* @property {String}  ThirdPartyNetworkUpdateUUID 
	* @property {String}  Type                        
	* @property {Number}  CreatedAtNanos              
	* @property {Number}  UpdatedAtNanos              
	*
	*/
	GetThirdPartyNetworkUpdateNumberByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Get)
		// Prepare URI params...
		let paramUUID = encodeURIComponent(req.UUID)
		return axios.get(`/v3/third_party_network_update_number/${paramUUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveThirdPartyNetworkUpdateNumber', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetThirdPartyNetworkUpdateNumberByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetThirdPartyNetworkUpdateNumberByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetThirdPartyNetworkUpdateNumberByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetThirdPartyNetworkUpdateNumberByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetThirdPartyNetworkUpdateNumberByUUID'))
			})
	},

	/**
	* RPC Method: GetThirdPartyNetworkUpdateNumbersPaginated
	* Description: Searches for multiple ThirdPartyNetworkUpdateNumber objects using a search request
	* HTTP Method: GET
	* API Path: /api/v3/third_party_network_update_number
	* @param    {ThirdPartyNetworkUpdateNumberSearchRequest} req The API payload object (ThirdPartyNetworkUpdateNumberSearchRequest)
	* @return   {ThirdPartyNetworkUpdateNumberPageResponse} The API response object (ThirdPartyNetworkUpdateNumberPageResponse)
	*
	* @typedef  {Object}  ThirdPartyNetworkUpdateNumberSearchRequest
	* @property {SearchOptions[]} SearchOptions Search options to use (for sorting and/or pagination)
	* @property {Number}  SPID          
	* @property {Number}  SOM           
	*
	* @typedef  {Object}  SearchOptions  Search options
	* @property {Number}  PageNumber     The page number to start at (or provide IndexStart)
	* @property {Number}  PageSize       The page size
	* @property {Number}  IndexStart     The record number to start at (or provide PageNumber)
	* @property {String}  SortBy         The field to sort by
	* @property {Boolean} SortDesc       Is the sort direction descending?
	*
	* @typedef  {Object}  ThirdPartyNetworkUpdateNumberPageResponse
	* @property {PageInfo} PageInfo                       
	* @property {ThirdPartyNetworkUpdateNumber[]} ThirdPartyNetworkUpdateNumbers 
	*
	* @typedef  {Object}   PageInfo
	* @property {Number}   PageNumber             The page number
	* @property {Number}   PageItemCount          Number of items on this page
	* @property {Number}   TotalPages             Total pages available
	* @property {Number}   TotalItemCount         Total items available
	* @property {Number}   IndexStart             The record number this search started at
	* @property {Number}   PageSize               The length of the page
	* @property {Number[]} DisplayPageList        The array of page numbers to display
	* @property {Number}   DisplayPagesEitherSide Number of pages to display either side
	*
	*/
	GetThirdPartyNetworkUpdateNumbersPaginated({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Find)
		return axios.get(`/v3/third_party_network_update_number`, { params: req, validateStatus: valFuncFind }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			commit('mutSaveThirdPartyNetworkUpdateNumbers', { Response: response.data })
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetThirdPartyNetworkUpdateNumbersPaginated ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetThirdPartyNetworkUpdateNumbersPaginated ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetThirdPartyNetworkUpdateNumbersPaginated ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetThirdPartyNetworkUpdateNumbersPaginated UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetThirdPartyNetworkUpdateNumbersPaginated'))
			})
	},

	/**
	* RPC Method: GetThirdPartyNetworkUpdatesPaginated
	* Description: Searches for multiple ThirdPartyNetworkUpdate objects using a search request.
	* HTTP Method: GET
	* API Path: /api/v3/third_party_network_update
	* @param    {ThirdPartyNetworkUpdateSearchRequest} req The API payload object (ThirdPartyNetworkUpdateSearchRequest)
	* @return   {ThirdPartyNetworkUpdatePageResponse} The API response object (ThirdPartyNetworkUpdatePageResponse)
	*
	* @typedef  {Object}  ThirdPartyNetworkUpdateSearchRequest
	* @property {SearchOptions[]} SearchOptions Search options to use (for sorting and/or pagination)
	* @property {Number}  SPID          
	* @property {Number}  SOM           
	*
	* @typedef  {Object}  SearchOptions  Search options
	* @property {Number}  PageNumber     The page number to start at (or provide IndexStart)
	* @property {Number}  PageSize       The page size
	* @property {Number}  IndexStart     The record number to start at (or provide PageNumber)
	* @property {String}  SortBy         The field to sort by
	* @property {Boolean} SortDesc       Is the sort direction descending?
	*
	* @typedef  {Object}  ThirdPartyNetworkUpdatePageResponse
	* @property {PageInfo} PageInfo                 
	* @property {ThirdPartyNetworkUpdate[]} ThirdPartyNetworkUpdates 
	*
	* @typedef  {Object}   PageInfo
	* @property {Number}   PageNumber             The page number
	* @property {Number}   PageItemCount          Number of items on this page
	* @property {Number}   TotalPages             Total pages available
	* @property {Number}   TotalItemCount         Total items available
	* @property {Number}   IndexStart             The record number this search started at
	* @property {Number}   PageSize               The length of the page
	* @property {Number[]} DisplayPageList        The array of page numbers to display
	* @property {Number}   DisplayPagesEitherSide Number of pages to display either side
	*
	*/
	GetThirdPartyNetworkUpdatesPaginated({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Find)
		return axios.get(`/v3/third_party_network_update`, { params: req, validateStatus: valFuncFind }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			commit('mutSaveThirdPartyNetworkUpdates', { Response: response.data })
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetThirdPartyNetworkUpdatesPaginated ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetThirdPartyNetworkUpdatesPaginated ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetThirdPartyNetworkUpdatesPaginated ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetThirdPartyNetworkUpdatesPaginated UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetThirdPartyNetworkUpdatesPaginated'))
			})
	},

	/**
	* RPC Method: NumberEnquiry
	* Description: This method allows a user to get full details of a number including summary of any SOMs (ports, relinquishments) that the number has been involved in.
	* HTTP Method: GET
	* API Path: /api/v3/${req.SPID}/ipms/number_enquiry
	* @param    {NumberEnquiryRequest} req The API payload object (NumberEnquiryRequest)
	* @return   {NumberEnquiryResponse} The API response object (NumberEnquiryResponse)
	*
	* @typedef  {Object}  NumberEnquiryRequest
	* @property {Number}  SPID   
	* @property {String}  Number 
	*
	* @typedef  {Object}  NumberEnquiryResponse
	* @property {Number}  CarrierID         
	* @property {Number}  DonorCarrierID    
	* @property {String}  Number            
	* @property {Number}  ServiceProviderID 
	*
	*/
	NumberEnquiry({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		// Prepare URI params...
		let paramSPID = encodeURIComponent(req.SPID)
		return axios.get(`/v3/${paramSPID}/ipms/number_enquiry`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('NumberEnquiry ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('NumberEnquiry ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('NumberEnquiry ERROR:', error)
					return Promise.reject(error)
				}
				console.log('NumberEnquiry UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in NumberEnquiry'))
			})
	},

	/**
	* RPC Method: PurgePortByUUID
	* Description: Purges a Port object. Requires JWT. Note SPID is an IPMS SPID, not our SPID.
	* HTTP Method: DELETE
	* API Path: /api/v3/ipms/port/${req.UUID}/purge
	* @param    {PortUUIDSearch} req The API payload object (PortUUIDSearch)
	* @return   {Port} The API response object (Port)
	*
	* @typedef  {Object}  PortUUIDSearch
	* @property {String}  UUID        
	* @property {Number}  SPID        Service Provider ID - always required
	* @property {String}  Description (optional) Description
	*
	* @typedef  {Object}  Port
	* @property {String}  UUID                              
	* @property {Number}  SPID                              Default Service Provider ID
	* @property {Number}  SOM                               
	* @property {String}  AccountNumber                     
	* @property {String}  AdditionalCustomerInfo            
	* @property {String}  Category                          
	* @property {String}  CustomerContactName               
	* @property {String}  CustomerContactNumber             
	* @property {String}  CustomerExistingServiceAddress    
	* @property {String}  CustomerName                      
	* @property {String}  GainingServiceProviderContact     
	* @property {String}  GspInternalReference              
	* @property {String}  LspInternalReference              
	* @property {Boolean} LspOverride                       
	* @property {Boolean} PrePayPrePaid                     
	* @property {Number}  RfsDateTimeStartNanos             
	* @property {Number}  ActionDueDateTimeNanos            Converted from IPMS DurationData
	* @property {Number}  SOMGainingServiceProviderId       
	* @property {Boolean} EmergencyReturn                   
	* @property {Number}  SOMLosingServiceProviderId        
	* @property {String}  SOMStatus                         
	* @property {Number}  SOMStatusDateTimeNanos            
	* @property {String}  SOMType                           
	* @property {Number}  SOMCompletedStateEnteredDateNanos 
	* @property {Number}  CreatedAtNanos                    
	* @property {Number}  UpdatedAtNanos                    
	*
	*/
	PurgePortByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Purge)
		return axios.delete(`/v3/ipms/port/${req.UUID}/purge`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSavePort', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('PurgePortByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('PurgePortByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('PurgePortByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('PurgePortByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in PurgePortByUUID'))
			})
	},

	/**
	* RPC Method: PurgePortNumberByUUID
	* Description: Purges a PortNumber object. Requires JWT. Note SPID is an IPMS SPID, not our SPID.
	* HTTP Method: DELETE
	* API Path: /api/v3/ipms/port_number/${req.UUID}/purge
	* @param    {PortNumberUUIDSearch} req The API payload object (PortNumberUUIDSearch)
	* @return   {PortNumber} The API response object (PortNumber)
	*
	* @typedef  {Object}  PortNumberUUIDSearch
	* @property {String}  UUID        
	* @property {Number}  SPID        Service Provider ID - always required
	* @property {String}  Description (optional) Description
	*
	* @typedef  {Object}  PortNumber
	* @property {String}  UUID                            
	* @property {Number}  SPID                            Default Service Provider ID
	* @property {Number}  SOM                             
	* @property {Number}  CreatedAtNanos                  When the object was first created
	* @property {Number}  UpdatedAtNanos                  When the object was last updated
	* @property {Number}  DeletedAtNanos                  When the object was deleted
	* @property {Number}  GainingCarrierID                
	* @property {Number}  LosingCarrierID                 
	* @property {String}  PhoneNumber                     
	* @property {String}  HandsetReference                
	* @property {String}  PortUUID                        PortDirection Direction = 11;,  int32 HOC = 12 [(validator.field) = {,      int_gt: 0,      human_error: "Must have a HOC",  }];,  string ServiceType = 13 [(validator.field) = {,      string_not_empty: true,,      human_error: "Must have a ServiceType of FIXED or MOBILE",,  }];
	* @property {ENUM}    GainingCarrierProgressStatus    
	* @property {ENUM}    LosingCarrierProgressStatus     
	* @property {ENUM}    TestedAndCompleteProgressStatus 
	*
	*/
	PurgePortNumberByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Purge)
		return axios.delete(`/v3/ipms/port_number/${req.UUID}/purge`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSavePortNumber', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('PurgePortNumberByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('PurgePortNumberByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('PurgePortNumberByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('PurgePortNumberByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in PurgePortNumberByUUID'))
			})
	},

	/**
	* RPC Method: PurgeThirdPartyNetworkUpdateByUUID
	* Description: Purges a ThirdPartyNetworkUpdate object from the
	* HTTP Method: DELETE
	* API Path: /api/v3/third_party_network_update/${req.UUID}/purge
	* @param    {ThirdPartyNetworkUpdateUUIDSearch} req The API payload object (ThirdPartyNetworkUpdateUUIDSearch)
	* @return   {ThirdPartyNetworkUpdate} The API response object (ThirdPartyNetworkUpdate)
	*
	* @typedef  {Object}  ThirdPartyNetworkUpdateUUIDSearch
	* @property {String}  UUID 
	* @property {Number}  SPID 
	* @property {Number}  SOM  
	*
	* @typedef  {Object}  ThirdPartyNetworkUpdate
	* @property {String}  UUID                 
	* @property {Number}  SPID                 Default Service Provider ID
	* @property {Number}  SOM                  
	* @property {Number}  ConfirmingCarrierID  
	* @property {Number}  ConfirmationDueNanos 
	* @property {String}  Type                 
	* @property {Number}  CreatedAtNanos       
	* @property {Number}  UpdatedAtNanos       
	* @property {Number}  Attempts             
	*
	*/
	PurgeThirdPartyNetworkUpdateByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Purge)
		return axios.delete(`/v3/third_party_network_update/${req.UUID}/purge`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveThirdPartyNetworkUpdate', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('PurgeThirdPartyNetworkUpdateByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('PurgeThirdPartyNetworkUpdateByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('PurgeThirdPartyNetworkUpdateByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('PurgeThirdPartyNetworkUpdateByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in PurgeThirdPartyNetworkUpdateByUUID'))
			})
	},

	/**
	* RPC Method: PurgeThirdPartyNetworkUpdateNumberByUUID
	* Description: Purges a ThirdPartyNetworkUpdateNumber object from the database
	* HTTP Method: DELETE
	* API Path: /api/v3/third_party_network_update_number/${req.UUID}/purge
	* @param    {ThirdPartyNetworkUpdateNumberUUIDSearch} req The API payload object (ThirdPartyNetworkUpdateNumberUUIDSearch)
	* @return   {ThirdPartyNetworkUpdateNumber} The API response object (ThirdPartyNetworkUpdateNumber)
	*
	* @typedef  {Object}  ThirdPartyNetworkUpdateNumberUUIDSearch
	* @property {String}  UUID 
	* @property {Number}  SPID 
	* @property {Number}  SOM  
	*
	* @typedef  {Object}  ThirdPartyNetworkUpdateNumber
	* @property {String}  UUID                        
	* @property {Number}  SPID                        Default Service Provider ID
	* @property {Number}  SOM                         
	* @property {Number}  ConfirmingCarrierID         
	* @property {Number}  DonorCarrierID              
	* @property {Number}  GainingCarrierID            
	* @property {Number}  LosingCarrierID             
	* @property {String}  PhoneNumber                 
	* @property {String}  ThirdPartyNetworkUpdateUUID 
	* @property {String}  Type                        
	* @property {Number}  CreatedAtNanos              
	* @property {Number}  UpdatedAtNanos              
	*
	*/
	PurgeThirdPartyNetworkUpdateNumberByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Purge)
		return axios.delete(`/v3/third_party_network_update_number/${req.UUID}/purge`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveThirdPartyNetworkUpdateNumber', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('PurgeThirdPartyNetworkUpdateNumberByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('PurgeThirdPartyNetworkUpdateNumberByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('PurgeThirdPartyNetworkUpdateNumberByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('PurgeThirdPartyNetworkUpdateNumberByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in PurgeThirdPartyNetworkUpdateNumberByUUID'))
			})
	},

	/**
	* RPC Method: SOMEnquiry
	* Description: This method allows a user to get full details of a SOM (port or relinquishment) from the IPMS.
	* HTTP Method: GET
	* API Path: /api/v3/${req.SPID}/ipms/som_enquiry
	* @param    {SOMEnquiryRequest} req The API payload object (SOMEnquiryRequest)
	* @return   {SOMEnquiryResponse} The API response object (SOMEnquiryResponse)
	*
	* @typedef  {Object}  SOMEnquiryRequest
	* @property {Number}  SPID 
	* @property {Number}  SOM  
	*
	* @typedef  {Object}  SOMEnquiryResponse
	* @property {Boolean} IsArchived           
	* @property {AuditData[]} Audits               
	* @property {NetworkUpdateEnquiryDetailData[]} NetworkUpdates       
	* @property {PortEnquiryDetailData} PortDetail           
	* @property {RelinquishmentEnquiryDetailData} RelinquishmentDetail 
	* @property {ServiceOrderManagementData} SOM                  
	*
	*/
	SOMEnquiry({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		// Prepare URI params...
		let paramSPID = encodeURIComponent(req.SPID)
		return axios.get(`/v3/${paramSPID}/ipms/som_enquiry`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('SOMEnquiry ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('SOMEnquiry ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('SOMEnquiry ERROR:', error)
					return Promise.reject(error)
				}
				console.log('SOMEnquiry UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in SOMEnquiry'))
			})
	},

	/**
	* RPC Method: SOMStatusEnquiry
	* Description: This method allows a user to get minimal details of a SOM (port or relinquishment) from the IPMS.
	* HTTP Method: GET
	* API Path: /api/v3/${req.SPID}/ipms/som_status_enquiry
	* @param    {SOMStatusEnquiryRequest} req The API payload object (SOMStatusEnquiryRequest)
	* @return   {SOMStatusEnquiriesResponse} The API response object (SOMStatusEnquiriesResponse)
	*
	* @typedef  {Object}  SOMStatusEnquiryRequest
	* @property {Number}  SPID 
	* @property {Number}  SOM  
	*
	* @typedef  {Object}  SOMStatusEnquiriesResponse
	* @property {SOMStatusEnquiry[]} SOMStatusEnquiryResponses 
	*
	*/
	SOMStatusEnquiry({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		// Prepare URI params...
		let paramSPID = encodeURIComponent(req.SPID)
		return axios.get(`/v3/${paramSPID}/ipms/som_status_enquiry`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('SOMStatusEnquiry ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('SOMStatusEnquiry ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('SOMStatusEnquiry ERROR:', error)
					return Promise.reject(error)
				}
				console.log('SOMStatusEnquiry UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in SOMStatusEnquiry'))
			})
	},

	/**
	* RPC Method: ServiceProviderDeprovisionComplete
	* Description: Called by LSPs in intra-carrier ports. It routes provision notifications to the correct GSP, informing them that they may begin provisioning the service. This avoids the case where an LSP could deprovision after the GSP has provisioned, disconnecting the service. This call allows synchronisation between LSP and GSP so that does not happen.
	* HTTP Method: POST
	* API Path: /api/v3/service_provider_deprovision_notification
	* @param    {DeprovisionNotificationRequest} req The API payload object (DeprovisionNotificationRequest)
	* @return   {DeprovisionNotificationResponse} The API response object (DeprovisionNotificationResponse)
	*
	* @typedef  {Object}  DeprovisionNotificationRequest
	* @property {Number}  SPID    Service Provider ID
	* @property {Number}  SOM     Service Order Number, used by the IPMS to identify the port
	* @property {StringSlice} Numbers The numbers being deprovisioned
	*
	* @typedef  {Object}  DeprovisionNotificationResponse
	* @property {String}  Status 
	*
	*/
	ServiceProviderDeprovisionComplete({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.post(`/v3/service_provider_deprovision_notification`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('ServiceProviderDeprovisionComplete ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('ServiceProviderDeprovisionComplete ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('ServiceProviderDeprovisionComplete ERROR:', error)
					return Promise.reject(error)
				}
				console.log('ServiceProviderDeprovisionComplete UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in ServiceProviderDeprovisionComplete'))
			})
	},

	/**
	* RPC Method: ServiceProviderLookupSP
	* Description: Looks up LSP by MSISDN.
	* HTTP Method: GET
	* API Path: /api/v3/ipms/service_provider_lookup
	* @param    {ServiceProviderLookupSPRequest} req The API payload object (ServiceProviderLookupSPRequest)
	* @return   {ServiceProviderLookupSPResult} The API response object (ServiceProviderLookupSPResult)
	*
	* @typedef  {Object}  ServiceProviderLookupSPRequest
	* @property {Number}  SPID        IQHive Service Provider ID for this port
	* @property {String}  PhoneNumber 
	*
	* @typedef  {Object}  ServiceProviderLookupSPResult
	* @property {Number}  IPMSSPID            
	* @property {ServiceProviderData[]} ServiceProviderList 
	*
	*/
	ServiceProviderLookupSP({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.get(`/v3/ipms/service_provider_lookup`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('ServiceProviderLookupSP ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('ServiceProviderLookupSP ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('ServiceProviderLookupSP ERROR:', error)
					return Promise.reject(error)
				}
				console.log('ServiceProviderLookupSP UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in ServiceProviderLookupSP'))
			})
	},

	/**
	* RPC Method: ServiceProviderProvisionNotification
	* Description: This is exposed for use by the GSP routing service, activated by the ServiceProviderDeprovisionComplete call. It should not be used directly, use ServiceProviderDeprovisionComplete instead.
	* HTTP Method: POST
	* API Path: /api/v3/service_provider_provision_notification
	* @param    {DeprovisionNotificationRequest} req The API payload object (DeprovisionNotificationRequest)
	* @return   {DeprovisionNotificationResponse} The API response object (DeprovisionNotificationResponse)
	*
	* @typedef  {Object}  DeprovisionNotificationRequest
	* @property {Number}  SPID    Service Provider ID
	* @property {Number}  SOM     Service Order Number, used by the IPMS to identify the port
	* @property {StringSlice} Numbers The numbers being deprovisioned
	*
	* @typedef  {Object}  DeprovisionNotificationResponse
	* @property {String}  Status 
	*
	*/
	ServiceProviderProvisionNotification({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.post(`/v3/service_provider_provision_notification`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('ServiceProviderProvisionNotification ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('ServiceProviderProvisionNotification ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('ServiceProviderProvisionNotification ERROR:', error)
					return Promise.reject(error)
				}
				console.log('ServiceProviderProvisionNotification UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in ServiceProviderProvisionNotification'))
			})
	},

	/**
	* RPC Method: UpdatePort
	* Description: Update a single carrier Port object.
	* HTTP Method: PUT
	* API Path: /api/v3/ipms/port/${req.UUID}
	* @param    {Port} req The API payload object (Port)
	* @return   {Port} The API response object (Port)
	*
	* @typedef  {Object}  Port
	* @property {String}  UUID                              
	* @property {Number}  SPID                              Default Service Provider ID
	* @property {Number}  SOM                               
	* @property {String}  AccountNumber                     
	* @property {String}  AdditionalCustomerInfo            
	* @property {String}  Category                          
	* @property {String}  CustomerContactName               
	* @property {String}  CustomerContactNumber             
	* @property {String}  CustomerExistingServiceAddress    
	* @property {String}  CustomerName                      
	* @property {String}  GainingServiceProviderContact     
	* @property {String}  GspInternalReference              
	* @property {String}  LspInternalReference              
	* @property {Boolean} LspOverride                       
	* @property {Boolean} PrePayPrePaid                     
	* @property {Number}  RfsDateTimeStartNanos             
	* @property {Number}  ActionDueDateTimeNanos            Converted from IPMS DurationData
	* @property {Number}  SOMGainingServiceProviderId       
	* @property {Boolean} EmergencyReturn                   
	* @property {Number}  SOMLosingServiceProviderId        
	* @property {String}  SOMStatus                         
	* @property {Number}  SOMStatusDateTimeNanos            
	* @property {String}  SOMType                           
	* @property {Number}  SOMCompletedStateEnteredDateNanos 
	* @property {Number}  CreatedAtNanos                    
	* @property {Number}  UpdatedAtNanos                    
	*
	*/
	UpdatePort({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Update)
		return axios.put(`/v3/ipms/port/${req.UUID}`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSavePort', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('UpdatePort ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('UpdatePort ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('UpdatePort ERROR:', error)
					return Promise.reject(error)
				}
				console.log('UpdatePort UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in UpdatePort'))
			})
	},

	/**
	* RPC Method: UpdatePortNumber
	* Description: Update a single PortNumber object. Note SPID is an IPMS SPID, not our SPID.
	* HTTP Method: PUT
	* API Path: /api/v3/ipms/port_number/${req.UUID}
	* @param    {PortNumber} req The API payload object (PortNumber)
	* @return   {PortNumber} The API response object (PortNumber)
	*
	* @typedef  {Object}  PortNumber
	* @property {String}  UUID                            
	* @property {Number}  SPID                            Default Service Provider ID
	* @property {Number}  SOM                             
	* @property {Number}  CreatedAtNanos                  When the object was first created
	* @property {Number}  UpdatedAtNanos                  When the object was last updated
	* @property {Number}  DeletedAtNanos                  When the object was deleted
	* @property {Number}  GainingCarrierID                
	* @property {Number}  LosingCarrierID                 
	* @property {String}  PhoneNumber                     
	* @property {String}  HandsetReference                
	* @property {String}  PortUUID                        PortDirection Direction = 11;,  int32 HOC = 12 [(validator.field) = {,      int_gt: 0,      human_error: "Must have a HOC",  }];,  string ServiceType = 13 [(validator.field) = {,      string_not_empty: true,,      human_error: "Must have a ServiceType of FIXED or MOBILE",,  }];
	* @property {ENUM}    GainingCarrierProgressStatus    
	* @property {ENUM}    LosingCarrierProgressStatus     
	* @property {ENUM}    TestedAndCompleteProgressStatus 
	*
	*/
	UpdatePortNumber({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Update)
		return axios.put(`/v3/ipms/port_number/${req.UUID}`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSavePortNumber', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('UpdatePortNumber ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('UpdatePortNumber ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('UpdatePortNumber ERROR:', error)
					return Promise.reject(error)
				}
				console.log('UpdatePortNumber UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in UpdatePortNumber'))
			})
	},

	/**
	* RPC Method: UpdatePortStatusOnPortNumber
	* Description: Update the status on a port number object, used by the tkt-nzlmnp-orchestrator. Note SPID is an IPMS SPID, not our SPID.
	* HTTP Method: PUT
	* API Path: /api/v3/ipms/port_number/status_update/${req.SOM}/${req.PhoneNumber}
	* @param    {StatusUpdatePortNumber} req The API payload object (StatusUpdatePortNumber)
	* @return   {PortAndPortNumberDetail} The API response object (PortAndPortNumberDetail)
	*
	* @typedef  {Object}  StatusUpdatePortNumber
	* @property {Number}  SPID                            Service Provider ID - always required
	* @property {Number}  SOM                             
	* @property {String}  PhoneNumber                     
	* @property {ENUM}    GainingCarrierProgressStatus    
	* @property {ENUM}    LosingCarrierProgressStatus     
	* @property {ENUM}    TestedAndCompleteProgressStatus 
	*
	* @typedef  {Object}  PortAndPortNumberDetail
	* @property {Port} Port       
	* @property {PortNumber} PortNumber Only one number with multiple messages
	*
	*/
	UpdatePortStatusOnPortNumber({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.put(`/v3/ipms/port_number/status_update/${req.SOM}/${req.PhoneNumber}`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('UpdatePortStatusOnPortNumber ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('UpdatePortStatusOnPortNumber ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('UpdatePortStatusOnPortNumber ERROR:', error)
					return Promise.reject(error)
				}
				console.log('UpdatePortStatusOnPortNumber UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in UpdatePortStatusOnPortNumber'))
			})
	},

	/**
	* RPC Method: UpdateThirdPartyNetworkUpdate
	* Description: Update a single ThirdPartyNetworkUpdate object
	* HTTP Method: PUT
	* API Path: /api/v3/ipms/third_party_network_update
	* @param    {ThirdPartyNetworkUpdate} req The API payload object (ThirdPartyNetworkUpdate)
	* @return   {ThirdPartyNetworkUpdate} The API response object (ThirdPartyNetworkUpdate)
	*
	* @typedef  {Object}  ThirdPartyNetworkUpdate
	* @property {String}  UUID                 
	* @property {Number}  SPID                 Default Service Provider ID
	* @property {Number}  SOM                  
	* @property {Number}  ConfirmingCarrierID  
	* @property {Number}  ConfirmationDueNanos 
	* @property {String}  Type                 
	* @property {Number}  CreatedAtNanos       
	* @property {Number}  UpdatedAtNanos       
	* @property {Number}  Attempts             
	*
	*/
	UpdateThirdPartyNetworkUpdate({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Update)
		return axios.put(`/v3/ipms/third_party_network_update`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveThirdPartyNetworkUpdate', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('UpdateThirdPartyNetworkUpdate ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('UpdateThirdPartyNetworkUpdate ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('UpdateThirdPartyNetworkUpdate ERROR:', error)
					return Promise.reject(error)
				}
				console.log('UpdateThirdPartyNetworkUpdate UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in UpdateThirdPartyNetworkUpdate'))
			})
	},

	/**
	* RPC Method: UpdateThirdPartyNetworkUpdateFields
	* Description: Update some or all fields on a ThirdPartyNetworkUpdate object
	* HTTP Method: PATCH
	* API Path: /api/v3/ipms/third_party_network_update/${req.UUID}
	* @param    {ThirdPartyNetworkUpdateFields} req The API payload object (ThirdPartyNetworkUpdateFields)
	* @return   {ThirdPartyNetworkUpdate} The API response object (ThirdPartyNetworkUpdate)
	*
	* @typedef  {Object}  ThirdPartyNetworkUpdateFields
	* @property {Number}  SPID                    
	* @property {String}  UUID                    
	* @property {ThirdPartyNetworkUpdate} ThirdPartyNetworkUpdate 
	* @property {String}  Fields                  
	*
	* @typedef  {Object}  ThirdPartyNetworkUpdate
	* @property {String}  UUID                 
	* @property {Number}  SPID                 Default Service Provider ID
	* @property {Number}  SOM                  
	* @property {Number}  ConfirmingCarrierID  
	* @property {Number}  ConfirmationDueNanos 
	* @property {String}  Type                 
	* @property {Number}  CreatedAtNanos       
	* @property {Number}  UpdatedAtNanos       
	* @property {Number}  Attempts             
	*
	*/
	UpdateThirdPartyNetworkUpdateFields({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: UpdateFields)
		return axios.patch(`/v3/ipms/third_party_network_update/${req.UUID}`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('UpdateThirdPartyNetworkUpdateFields ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('UpdateThirdPartyNetworkUpdateFields ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('UpdateThirdPartyNetworkUpdateFields ERROR:', error)
					return Promise.reject(error)
				}
				console.log('UpdateThirdPartyNetworkUpdateFields UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in UpdateThirdPartyNetworkUpdateFields'))
			})
	},

	/**
	* RPC Method: UpdateThirdPartyNetworkUpdateNumber
	* Description: Update a single ThirdPartyNetworkUpdateNumber object
	* HTTP Method: PUT
	* API Path: /api/v3/ipms/third_party_network_update_number
	* @param    {ThirdPartyNetworkUpdateNumber} req The API payload object (ThirdPartyNetworkUpdateNumber)
	* @return   {ThirdPartyNetworkUpdateNumber} The API response object (ThirdPartyNetworkUpdateNumber)
	*
	* @typedef  {Object}  ThirdPartyNetworkUpdateNumber
	* @property {String}  UUID                        
	* @property {Number}  SPID                        Default Service Provider ID
	* @property {Number}  SOM                         
	* @property {Number}  ConfirmingCarrierID         
	* @property {Number}  DonorCarrierID              
	* @property {Number}  GainingCarrierID            
	* @property {Number}  LosingCarrierID             
	* @property {String}  PhoneNumber                 
	* @property {String}  ThirdPartyNetworkUpdateUUID 
	* @property {String}  Type                        
	* @property {Number}  CreatedAtNanos              
	* @property {Number}  UpdatedAtNanos              
	*
	*/
	UpdateThirdPartyNetworkUpdateNumber({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Update)
		return axios.put(`/v3/ipms/third_party_network_update_number`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveThirdPartyNetworkUpdateNumber', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('UpdateThirdPartyNetworkUpdateNumber ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('UpdateThirdPartyNetworkUpdateNumber ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('UpdateThirdPartyNetworkUpdateNumber ERROR:', error)
					return Promise.reject(error)
				}
				console.log('UpdateThirdPartyNetworkUpdateNumber UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in UpdateThirdPartyNetworkUpdateNumber'))
			})
	},
};

export default {
	namespaced: true,
	state: initialState,
	getters,
	mutations,
	actions,
};