import axiosUnique from '@/utils/axiosUnique'

const state = {}

const getters = {}

const mutations = {}

const actions = {

    /**
     * Получение данных для таблицы "TV Entitlements" на странице "/tv/entitlements/:page(\\d+)?"
     */
    getTVEntitlements({}, { params }) {
        // console.group();

        // console.log('getTVEntitlements()')
        // console.log(params)

        // console.groupEnd();

        return new Promise((resolve, reject) => {
            const delay_in_ms = 2000

            setTimeout(() => {
                import('./hardcoded-test-data/tv/entitlements/entitlements-list.json')
                    .then(response => {
                        const PageInfo = response.default.PageInfo
                        const Entitlements = response.default.Entitlements

                        resolve({ PageInfo, Entitlements })
                    })
                    .catch(error => {
                        reject(error)
                    })
            }, delay_in_ms)
        })

        /*
            return axiosUnique.get('/v2/:spid/tv/entitlements', { params })
                .then(({ apidata }) => {
                    const PageInfo = apidata.PageInfo
                    const Entitlements = apidata.Entitlements
    
                    return Promise.resolve({ PageInfo, Entitlements })
                })
                .catch(error => {
                    return Promise.reject(error)
                })
        */
    },

    /**
     * Создание через модальное окно "Add TV Entitlement" на странице "/tv/entitlements/:page(\\d+)?"
     */
    addTVEntitlement({}, { data }) {
        console.group();

        console.log('addTVEntitlement()')
        console.log(data)

        console.groupEnd();

        return new Promise((resolve, reject) => {
            const delay_in_ms = 2000

            setTimeout(() => {
                import('./hardcoded-test-data/tv/entitlements/created-entitlement.json')
                    .then(response => {
                        resolve(response.default)
                    })
                    .catch(error => {
                        reject(error)
                    })
            }, delay_in_ms)
        })

        /*
            return axiosUnique.post(`/v2/:spid/tv/entitlements`, data)
                .then(({ apidata }) => {
                    return Promise.resolve(apidata)
                })
                .catch(error => {
                    return Promise.reject(error)
                })
        */
    },


    /**
     * Редактирование через модальное окно "Change TV Entitlement" на странице "/tv/entitlements/:page(\\d+)?"
     */
    changeTVEntitlement({}, { uuid, data }) {
        console.group();

        console.log('changeTVEntitlement()')
        console.log(uuid)
        console.log(data)

        console.groupEnd();

        return new Promise((resolve, reject) => {
            const delay_in_ms = 2000

            setTimeout(() => {
                import('./hardcoded-test-data/tv/entitlements/updated-entitlement.json')
                    .then(response => {
                        resolve(response.default)
                    })
                    .catch(error => {
                        reject(error)
                    })
            }, delay_in_ms)
        })

        /*
            return axiosUnique.put(`/v2/:spid/tv/entitlements/${ uuid }`, data)
                .then(({ apidata }) => {
                    return Promise.resolve(apidata)
                })
                .catch(error => {
                    return Promise.reject(error)
                })
        */
    },

    /**
     * Удаление через модальное окно "Entitlement removal" на странице "/tv/entitlements/:page(\\d+)?"
     */
    deleteTVEntitlement({}, { uuid }) {
        console.group();

        console.log('deleteTVEntitlement()')
        console.log(uuid)

        console.groupEnd();

        return new Promise((resolve, reject) => {
            const delay_in_ms = 2000

            setTimeout(() => {
                import('./hardcoded-test-data/tv/entitlements/deleted-entitlement.json')
                    .then(response => {
                        resolve(response.default)
                    })
                    .catch(error => {
                        reject(error)
                    })
            }, delay_in_ms)
        })

        /*
            return axiosUnique.delete(`/v2/:spid/tv/entitlements/${ uuid }`)
                .then(({ apidata }) => {
                    return Promise.resolve(apidata)
                })
                .catch(error => {
                    return Promise.reject(error)
                })
        */
    }
}

export default {
    state,
    getters,
    mutations,
    actions,
}