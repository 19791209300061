// Code generated by protoc-gen-iqwebapi. DO NOT EDIT.
// source: service_order.proto

import axios from 'axios';
import StoreHash from '@/utils/StoreHash';

const initialState = {
	ServiceOrders: {
		UUIDs: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
	ServiceOrderSearches: {
		SearchTexts: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
	ServiceOrderCharacteristics: {
		UUIDs: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
	ServiceOrderStates: {
		UUIDs: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
};

const valFuncFind = function (status) {
	return (status == 200) ? true : (status == 404) ? true : false;
}

const default_pageinfo = {
	PageNumber: 0,
	PageItemCount: 0,
	TotalPages: 0,
	TotalItemCount: 0,
	IndexStart: 0,
	PageSize: 10,
	DisplayPageList: [],
	DisplayPageEitherSide: 4,
}

const getters = {
	getServiceOrderByUUID: state => (UUID) => (state.ServiceOrders.UUIDs[ UUID ] && !state.ServiceOrders.UUIDs[ UUID ].expired()) ?  state.ServiceOrders.UUIDs[ UUID ].hashedData() : null,
	getServiceOrdersForSearchText: state => (SearchText) => state.ServiceOrderSearches[ SearchText ] ? state.ServiceOrderSearches[ SearchText ] : null,
	getServiceOrderCharacteristicByUUID: state => (UUID) => (state.ServiceOrderCharacteristics.UUIDs[ UUID ] && !state.ServiceOrderCharacteristics.UUIDs[ UUID ].expired()) ?  state.ServiceOrderCharacteristics.UUIDs[ UUID ].hashedData() : null,
	getServiceOrderStateByUUID: state => (UUID) => (state.ServiceOrderStates.UUIDs[ UUID ] && !state.ServiceOrderStates.UUIDs[ UUID ].expired()) ?  state.ServiceOrderStates.UUIDs[ UUID ].hashedData() : null,
};

const mutations = {
	// mutSaveServiceOrder saves a single ServiceOrder object
	// into both the StoreHash cache and the VueX store
	mutSaveServiceOrder(state, obj) {
		if (!obj.UUID) { return } // Must have UUID to Save

		if (!(obj.UUID in state.ServiceOrders.UUIDs)) {
			state.ServiceOrders.UUIDs[ obj.UUID ] = new StoreHash(state.ServiceOrders.lifetime)
		}
		state.ServiceOrders.UUIDs[ obj.UUID ].fix(obj) // Add item to StoreHash
	},
	// mutRemoveServiceOrderByUUID removes a ServiceOrder object
	// with the given UUID from StoreHash cache and VueX store
	mutRemoveServiceOrderByUUID(state, UUID) {
		if (!UUID) {
			// Must have UUID to Remove
			return
		}
		delete state.ServiceOrders.UUIDs[ UUID ]    // remove item from StoreHash
	},
	// mutSaveServiceOrderSearch saves a search response
	// with the given SearchText to the StoreHash cache and VueX store
	mutSaveServiceOrderSearch(state, { SearchText, Response }) {
		if (!SearchText || !Response) {
			// Must have SearchText to Save and Response object
			return
		}
		let storedObj = {
			PageInfo: Object.assign({}, default_pageinfo), // shallow copy of default_pageinfo
			UUIDs: [],
		}
		// Deep copy ??? i.e. JSON.parse(JSON.stringify(obj));
		if (Response.PageInfo) {
			Response.PageInfo = Object.assign({}, Response.PageInfo) // shallow copy of the PageInfo
		}
		if (Array.isArray(Response.ServiceOrders)) {
			for (let i = 0; i < Response.ServiceOrders.length; i++) {
				storedObj.UUIDs.push(Response.ServiceOrders[i].UUID)
			}
		}
		state.ServiceOrderSearches[ SearchText ] = storedObj      // Add search text and list of UUIDs to VueX state

		if (!(SearchText in state.ServiceOrderSearches.SearchTexts)) {
			state.ServiceOrderSearches.SearchTexts[ SearchText ] = new StoreHash(state.ServiceOrderSearches.lifetime)
		}
		state.ServiceOrderSearches.SearchTexts[ SearchText ].fix() // Add item to StoreHash
	},
	// mutRemoveServiceOrderSearch removes a search response
	// with the given SearchText from StoreHash cache and VueX store
	mutRemoveServiceOrderSearch(state, SearchText) {
		if (!SearchText) {
			// Must have SearchText to Remove
			return
		}
		state.ServiceOrderSearches[ SearchText ] = null               // cause VueX to trigger a data cascade
		delete state.ServiceOrderSearches[ SearchText ]               // remove item from VueX state
		delete state.ServiceOrderSearches.SearchTexts[ SearchText ]  // remove item from StoreHash
	},
	// mutSaveServiceOrders saves a paginated response of ServiceOrders
	mutSaveServiceOrders(state, { Response }) {
		if (!Response || !Array.isArray(Response.ServiceOrders)) {
			// Must have Response object with results
			return
		}
		for (let i = 0; i < Response.ServiceOrders.length; i++) {
			if (!(Response.ServiceOrders[i].UUID in state.ServiceOrders.UUIDs)) {
				state.ServiceOrders.UUIDs[ Response.ServiceOrders[i].UUID ] = new StoreHash(state.ServiceOrders.lifetime)
			}
			state.ServiceOrders.UUIDs[ Response.ServiceOrders[i].UUID ].fix(Response.ServiceOrders[i])  // Add single ServiceOrder to StoreHash
		}
	},
	// mutSaveServiceOrderCharacteristic saves a single ServiceOrderCharacteristic object
	// into both the StoreHash cache and the VueX store
	mutSaveServiceOrderCharacteristic(state, obj) {
		if (!obj.UUID) { return } // Must have UUID to Save

		if (!(obj.UUID in state.ServiceOrderCharacteristics.UUIDs)) {
			state.ServiceOrderCharacteristics.UUIDs[ obj.UUID ] = new StoreHash(state.ServiceOrderCharacteristics.lifetime)
		}
		state.ServiceOrderCharacteristics.UUIDs[ obj.UUID ].fix(obj) // Add item to StoreHash
	},
	// mutRemoveServiceOrderCharacteristicByUUID removes a ServiceOrderCharacteristic object
	// with the given UUID from StoreHash cache and VueX store
	mutRemoveServiceOrderCharacteristicByUUID(state, UUID) {
		if (!UUID) {
			// Must have UUID to Remove
			return
		}
		delete state.ServiceOrderCharacteristics.UUIDs[ UUID ]    // remove item from StoreHash
	},
	// mutSaveServiceOrderState saves a single ServiceOrderState object
	// into both the StoreHash cache and the VueX store
	mutSaveServiceOrderState(state, obj) {
		if (!obj.UUID) { return } // Must have UUID to Save

		if (!(obj.UUID in state.ServiceOrderStates.UUIDs)) {
			state.ServiceOrderStates.UUIDs[ obj.UUID ] = new StoreHash(state.ServiceOrderStates.lifetime)
		}
		state.ServiceOrderStates.UUIDs[ obj.UUID ].fix(obj) // Add item to StoreHash
	},
	// mutRemoveServiceOrderStateByUUID removes a ServiceOrderState object
	// with the given UUID from StoreHash cache and VueX store
	mutRemoveServiceOrderStateByUUID(state, UUID) {
		if (!UUID) {
			// Must have UUID to Remove
			return
		}
		delete state.ServiceOrderStates.UUIDs[ UUID ]    // remove item from StoreHash
	},
};

const actions = {
	// getCachedServiceOrderByUUID fetches a ServiceOrder from the cache
	// and if not in the cache, fetches it from the API
	getCachedServiceOrderByUUID({ state, dispatch, commit }, { UUID, RefreshCache }) {
		if (!RefreshCache && state.ServiceOrders.UUIDs[ UUID ] && !state.ServiceOrders.UUIDs[ UUID ].expired()) {
			// Return the cached record
			return state.ServiceOrders.UUIDs[ UUID ].hashedData()
		}
		// Fetch from the API
		return dispatch('GetServiceOrderByUUID', { UUID }).then(respPayload => {
			commit('mutSaveServiceOrder', respPayload)
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveServiceOrderByUUID', UUID)
			return Promise.reject(error)
		})
	},

	// getCachedServiceOrdersBySearch fetches ServiceOrders from the cache
	// and if not in the cache, fetches it from the API
	getCachedServiceOrdersBySearch({ state, getters, dispatch, commit }, { SPID, SearchText, RefreshCache }) {
		if (!RefreshCache && state.ServiceOrderSearches.SearchTexts[ SearchText ] && !state.ServiceOrderSearches.SearchTexts[ SearchText ].expired()) {
			// Return the cached records
			let resp = getters.getServiceOrdersForSearchText(SearchText)
			resp.ServiceOrders = []
			for (let i = 0; i < resp.UUIDs.length; i++) {
				resp.ServiceOrders.push(getters.getServiceOrderByUUID(resp.UUIDs[i]))
			}
			return Promise.resolve(resp)
		}
		// Search using the API
		const apiReq = {
			SPID: SPID,
			SmartSearch: SearchText,
		}
		return dispatch('FindServiceOrdersPaginated', apiReq).then(respPayload => {
			commit('mutSaveServiceOrderSearch', { SearchText: SearchText, Response: respPayload })
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveServiceOrderSearch', SearchText)
			return Promise.reject(error)
		})
	},

	// getCachedServiceOrderCharacteristicByUUID fetches a ServiceOrderCharacteristic from the cache
	// and if not in the cache, fetches it from the API
	getCachedServiceOrderCharacteristicByUUID({ state, dispatch, commit }, { UUID, RefreshCache }) {
		if (!RefreshCache && state.ServiceOrderCharacteristics.UUIDs[ UUID ] && !state.ServiceOrderCharacteristics.UUIDs[ UUID ].expired()) {
			// Return the cached record
			return state.ServiceOrderCharacteristics.UUIDs[ UUID ].hashedData()
		}
		// Fetch from the API
		return dispatch('GetServiceOrderCharacteristicByUUID', { UUID }).then(respPayload => {
			commit('mutSaveServiceOrderCharacteristic', respPayload)
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveServiceOrderCharacteristicByUUID', UUID)
			return Promise.reject(error)
		})
	},

	// getCachedServiceOrderStateByUUID fetches a ServiceOrderState from the cache
	// and if not in the cache, fetches it from the API
	getCachedServiceOrderStateByUUID({ state, dispatch, commit }, { UUID, RefreshCache }) {
		if (!RefreshCache && state.ServiceOrderStates.UUIDs[ UUID ] && !state.ServiceOrderStates.UUIDs[ UUID ].expired()) {
			// Return the cached record
			return state.ServiceOrderStates.UUIDs[ UUID ].hashedData()
		}
		// Fetch from the API
		return dispatch('GetServiceOrderStateByUUID', { UUID }).then(respPayload => {
			commit('mutSaveServiceOrderState', respPayload)
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveServiceOrderStateByUUID', UUID)
			return Promise.reject(error)
		})
	},

	//
	// Service: ServiceOrderRPC
	// 
	/**
	* RPC Method: FindServiceOrdersPaginated
	* Description: Search through service orders
	* HTTP Method: GET
	* API Path: /api/v1/${req.SPID}/service_order
	* @param    {ServiceOrderSearchRequest} req The API payload object (ServiceOrderSearchRequest)
	* @return   {ServiceOrderSearchResponse} The API response object (ServiceOrderSearchResponse)
	*
	* @typedef  {Object}  ServiceOrderSearchRequest
	* @property {SearchOptions[]} SearchOptions       Standard search options
	* @property {String}  UUID                UUID of service order: use get service order by UUID call instead
	* @property {String}  ServiceType         ServiceType of order, supported types are: FWA
	* @property {ServiceOrderState} State               State of order
	* @property {Number}  SPID                SPID of order
	* @property {Boolean} InException         If this order is InException (needs attendance)
	* @property {String}  Substate            Search by the SubState of the order
	* @property {String}  ServiceInstanceUUID Search for orders related to a particular service instance
	* @property {String}  SmartSearch         Do a smart search
	* @property {String}  ExternalRef         Search by external ref
	* @property {String}  CustomerUUID        Search by customer uuid
	* @property {String}  ProductOrderUUID    Search by product order uuid
	*
	* @typedef  {Object}  SearchOptions  Search options
	* @property {Number}  PageNumber     The page number to start at (or provide IndexStart)
	* @property {Number}  PageSize       The page size
	* @property {Number}  IndexStart     The record number to start at (or provide PageNumber)
	* @property {String}  SortBy         The field to sort by
	* @property {Boolean} SortDesc       Is the sort direction descending?
	*
	* @typedef  {Object}  ServiceOrderSearchResponse
	* @property {PageInfo} PageInfo      PageInfo only needs the values ThisPageNumber && PageSize set
	* @property {ServiceOrder[]} ServiceOrders List of service orders whuch match the search
	*
	* @typedef  {Object}   PageInfo
	* @property {Number}   PageNumber             The page number
	* @property {Number}   PageItemCount          Number of items on this page
	* @property {Number}   TotalPages             Total pages available
	* @property {Number}   TotalItemCount         Total items available
	* @property {Number}   IndexStart             The record number this search started at
	* @property {Number}   PageSize               The length of the page
	* @property {Number[]} DisplayPageList        The array of page numbers to display
	* @property {Number}   DisplayPagesEitherSide Number of pages to display either side
	*
	*/
	FindServiceOrdersPaginated({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Find)
		// Prepare URI params...
		let paramSPID = encodeURIComponent(req.SPID)
		return axios.get(`/v1/${paramSPID}/service_order`, { params: req, validateStatus: valFuncFind }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			commit('mutSaveServiceOrders', { Response: response.data })
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('FindServiceOrdersPaginated ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('FindServiceOrdersPaginated ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('FindServiceOrdersPaginated ERROR:', error)
					return Promise.reject(error)
				}
				console.log('FindServiceOrdersPaginated UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in FindServiceOrdersPaginated'))
			})
	},

	/**
	* RPC Method: GetServiceOrderStateHistoryCharacteristicsByUUID
	* Description: Get a service order
	* HTTP Method: GET
	* API Path: /api/v1/${req.SPID}/service_order/${req.UUID}
	* @param    {GetServiceOrderByUUIDRequest} req The API payload object (GetServiceOrderByUUIDRequest)
	* @return   {GetServiceOrderByUUIDResponse} The API response object (GetServiceOrderByUUIDResponse)
	*
	* @typedef  {Object}  GetServiceOrderByUUIDRequest
	* @property {Number}  SPID         SPID
	* @property {String}  UUID         UUID of the service order
	* @property {String}  CustomerUUID Customer UUID of the service order
	*
	* @typedef  {Object}  GetServiceOrderByUUIDResponse
	* @property {Number}  SPID            SPID
	* @property {String}  UUID            UUID of the order
	* @property {ServiceOrder} Order           The base order record
	* @property {ServiceOrderState} State           The current state (& substate) of the order
	* @property {ServiceOrderState[]} History         The state history of the order
	* @property {ServiceOrderCharacteristic[]} Characteristics The characteristics of the order
	*
	*/
	GetServiceOrderStateHistoryCharacteristicsByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		// Prepare URI params...
		let paramSPID = encodeURIComponent(req.SPID)
		let paramUUID = encodeURIComponent(req.UUID)
		return axios.get(`/v1/${paramSPID}/service_order/${paramUUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetServiceOrderStateHistoryCharacteristicsByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetServiceOrderStateHistoryCharacteristicsByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetServiceOrderStateHistoryCharacteristicsByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetServiceOrderStateHistoryCharacteristicsByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetServiceOrderStateHistoryCharacteristicsByUUID'))
			})
	},
};

export default {
	namespaced: true,
	state: initialState,
	getters,
	mutations,
	actions,
};