// Code generated by protoc-gen-iqwebapi. DO NOT EDIT.
// source: postpay.proto

import axios from 'axios';
import StoreHash from '@/utils/StoreHash';

const initialState = {
};

const valFuncFind = function (status) {
	return (status == 200) ? true : (status == 404) ? true : false;
}

const default_pageinfo = {
	PageNumber: 0,
	PageItemCount: 0,
	TotalPages: 0,
	TotalItemCount: 0,
	IndexStart: 0,
	PageSize: 10,
	DisplayPageList: [],
	DisplayPageEitherSide: 4,
}

const getters = {
};

const mutations = {
};

const actions = {
	//
	// Service: PostpayMobileRPC
	// 
	/**
	* RPC Method: ChangeMSISDN
	* Description: ## ChangeMSISDN
	* 
	* Post a product change order into the system
	* 
	* 
	* HTTP Method: PUT
	* API Path: /api/v1/${req.SPID}/postpay_mobile/${req.UUID}/msisdn_change
	* @param    {MSISDNChangeRequest} req The API payload object (MSISDNChangeRequest)
	* @return   {MSISDNChangeResponse} The API response object (MSISDNChangeResponse)
	*
	* @typedef  {Object}  MSISDNChangeRequest
	* @property {Number}  SPID         
	* @property {String}  UUID         
	* @property {String}  CustomerUUID 
	* @property {String}  MSISDN       
	*
	* @typedef  {Object}  MSISDNChangeResponse
	* @property {Number}  SPID 
	* @property {String}  UUID 
	*
	*/
	ChangeMSISDN({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.put(`/v1/${req.SPID}/postpay_mobile/${req.UUID}/msisdn_change`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('ChangeMSISDN ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('ChangeMSISDN ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('ChangeMSISDN ERROR:', error)
					return Promise.reject(error)
				}
				console.log('ChangeMSISDN UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in ChangeMSISDN'))
			})
	},

	/**
	* RPC Method: ChangePlan
	* Description: ## ChangePlan
	* 
	* Post a product change order into the system
	* 
	* 
	* HTTP Method: PUT
	* API Path: /api/v1/${req.SPID}/postpay_mobile/${req.UUID}/plan_change
	* @param    {PlanChangeRequest} req The API payload object (PlanChangeRequest)
	* @return   {PlanChangeResponse} The API response object (PlanChangeResponse)
	*
	* @typedef  {Object}  PlanChangeRequest
	* @property {Number}  SPID         
	* @property {String}  UUID         
	* @property {String}  CustomerUUID 
	* @property {String}  PlanUUID     
	*
	* @typedef  {Object}  PlanChangeResponse
	* @property {Number}  SPID             
	* @property {String}  ProductOrderUUID 
	*
	*/
	ChangePlan({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.put(`/v1/${req.SPID}/postpay_mobile/${req.UUID}/plan_change`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('ChangePlan ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('ChangePlan ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('ChangePlan ERROR:', error)
					return Promise.reject(error)
				}
				console.log('ChangePlan UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in ChangePlan'))
			})
	},

	/**
	* RPC Method: CreatePostpayProduct
	* Description: ## CreatePostpayProduct
	* 
	* Creates a new postpay mobile product provisioning order.
	* VoiceSettings is optional. If not set, backend will use default value. But if set, need to set all items.
	* DataEnabled is optional. If not set, backend will use default value. But if set, need to set all items.
	* 
	* ### Request fields
	* |**Field**|**Type**|**Description**|
	* |--|--|--|
	* |CustomerUUID|UUID(string)|specifies the customer to attach this product to|
	* |ProductAvailabilityUUID|UUID(string)|this is the UUID from|
	* |MSISDN|MSISDN(string)|is the MSIDN for this product|
	* |SIMID|SIMID(string)|The SIMID that this product will be activated on
	* 
	* ### Example Request (VoiceSetings and DataEnabled show the default values)
	* 
	*     {
	*     	"CustomerUUID": "da387d24-38a5-4ab1-bafa-cb7178db4f29",
	*     	"ProductAvailabilityUUID": "1bcdb203-ec7e-461c-8870-d8467a167a78",
	*     	"MSISDN": "+642123456705",
	*     	"SIMID": "6401150310635192",
	*     	"AutoAllocateMSISDN": true,
	*     	"Voicemail": false,
	*     	"NewGroup": true,
	*        "VoiceSettings": {
	*            "OutgoingCallerID": 3,
	*            "IncomingCallerID": true,
	*            "AllowConferencing": true,
	*            "CallWaitingAvailable": true,
	*            "CallWaitingEnabled": true
	* 	        "CFBActive":false,
	* 	        "CFBAvailable":true,
	* 	        "CFBDestination":"",
	* 	        "CFNRCActive":false,
	* 	        "CFNRCAvailable":true,
	* 	        "CFNRCDestination":"",
	* 	        "CFNRYAvailable":true,
	* 	        "CFNRYActive":false,
	* 	        "CFNRYDestination":true,
	* 	        "CFNRYRingtime":30,
	* 	        "CFUActive":false,
	* 	        "CFUAvailable":true,
	* 	        "CFUDestination":"",
	* 	        "BarIncomingCalls":false,
	* 	        "BarOutgoingCalls":false,
	* 	        "AllowIncomingCalls":true,
	* 	        "AllowIncomingWhileRoaming":true,
	* 	        "AllowOutgoingCalls":true,
	* 	        "AllowOutgoingInternationalCalls":false,
	* 	        "AllowSMSSend":true,
	* 	        "AllowSMSRecieve":true,
	* 	        "OCSBlockPremiumCalls":false,
	* 	        "OCSBlockPremiumSMS":false,
	*        }
	*        "DataEnabled": {
	*            "DataEnabled2G": true,
	*            "DataEnabled3G": true,
	*            "DataEnabled4G": true,
	*            "DataEnabled5G": true,
	*        }
	*     }
	* 
	* HTTP Method: POST
	* API Path: /api/v1/${req.SPID}/postpay_mobile
	* @param    {PostpayMobileCreateRequest} req The API payload object (PostpayMobileCreateRequest)
	* @return   {PostpayMobileCreateResponse} The API response object (PostpayMobileCreateResponse)
	*
	* @typedef  {Object}  PostpayMobileCreateRequest
	* @property {Number}  SPID                    SPID is the service provider ID that the product belongs to
	* @property {String}  CustomerUUID            CustomerUUID if attached to a customer
	* @property {String}  ReferenceCode           ReferenceCode is a freeform string to an external ID
	* @property {String}  ProductAvailabilityUUID ProductAvailabilityUUID is the availability that this product was based off
	* @property {Boolean} AutoAllocateMSISDN      AutoAllocateMSISDN is if we want/wanted to autoallocate a MSISDN
	* @property {String}  MSISDN                  MSISDN is the MSIDN to allocate to this product
	* @property {Boolean} AutoAllocateIMSI        AutoAllocateIMSI is if we want to autoallocate a IMSI (SIM)
	* @property {String}  IMSI                    IMSI is the IMSI that this product has (SIM)
	* @property {Boolean} Voicemail               Voicemail enabled or not
	* @property {Boolean} NewGroup                NewGroup is if this product should be allocated into a new group
	* @property {String}  ExistingGroupUUID       GroupUUID is the availability that this product was based off
	* @property {String}  SIMID                   IMSI is the IMSI that this product has (SIM)
	* @property {PostpayMobileCreateRequest_CharacteristicsEntry[]} Characteristics         List of characteristics that will overwrite the default postpay mobile Characteristics
	* @property {VoiceSettings[]} VoiceSettings           When creating product, set the default values of VoiceSettings such as CLIR/CLIP etc.
	* @property {DataEnabled} DataEnabled             When creating product, set the default values of DataEnabled.
	* @property {Number}  LosingServiceProvider   Losing Service Provider, used when porting number on creation of new product
	* @property {String}  LosingAccountNumber     Account Number of losing service if comming from postpay, used when porting number on creation of new product
	* @property {String}  CustomerName            Customer Name, used when porting number on creation of new product
	* @property {String}  OldSIMID                SIM ID of Losing Service Provider, used when porting number on creation of new product
	* @property {Object}  NewAccountNumber        Account Number for this service, if not provided one will be generated
	*
	* @typedef  {Object}  PostpayMobileCreateResponse
	* @property {Number}  SPID                
	* @property {String}  ProductOrderUUID    
	* @property {ServiceProviderData[]} ServiceProviderList 
	*
	*/
	CreatePostpayProduct({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.post(`/v1/${req.SPID}/postpay_mobile`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('CreatePostpayProduct ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('CreatePostpayProduct ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('CreatePostpayProduct ERROR:', error)
					return Promise.reject(error)
				}
				console.log('CreatePostpayProduct UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in CreatePostpayProduct'))
			})
	},

	/**
	* RPC Method: FindPostpayMobilePlansPaginated
	* Description: ## FindPostpayMobilePlansPaginated
	* 
	* Returns the list of available postpay mobile plans available to the SP customer.
	* 
	* ### Request Fields
	* |**Field**|**Type**|**Description**|
	* |--|--|--|
	* |CustomerUUID|UUID(string)|specifies the customer to attach this product to|
	* You can use the SearchOptions to filter through the returned plans
	* 
	* ### Example Request
	* 
	* 
	* HTTP Method: GET
	* API Path: /api/v1/${req.SPID}/postpay_mobile_plans
	* @param    {PostpayMobilePlanSearchRequest} req The API payload object (PostpayMobilePlanSearchRequest)
	* @return   {PostpayMobilePlanPageResponse} The API response object (PostpayMobilePlanPageResponse)
	*
	* @typedef  {Object}  PostpayMobilePlanSearchRequest
	* @property {SearchOptions[]} SearchOptions Search options to use (for sorting and/or pagination)
	* @property {Number}  SPID          SPID is the service provider ID that the product belongs to
	* @property {String}  CustomerUUID  WARNING: Not used/implemented
	*
	* @typedef  {Object}  PostpayMobilePlanPageResponse
	* @property {PageInfo} PageInfo           PageInfo only needs the values ThisPageNumber && PageSize set
	* @property {PostpayMobilePlan[]} PostpayMobilePlans The list of matching Products
	*
	*/
	FindPostpayMobilePlansPaginated({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		// Prepare URI params...
		let paramSPID = encodeURIComponent(req.SPID)
		return axios.get(`/v1/${paramSPID}/postpay_mobile_plans`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('FindPostpayMobilePlansPaginated ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('FindPostpayMobilePlansPaginated ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('FindPostpayMobilePlansPaginated ERROR:', error)
					return Promise.reject(error)
				}
				console.log('FindPostpayMobilePlansPaginated UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in FindPostpayMobilePlansPaginated'))
			})
	},

	/**
	* RPC Method: FindPostpayProductsPaginated
	* Description: ## FindPostpayProductsPaginated
	* 
	* Returns the list of current or past postpay mobile products.
	* 
	* ### Request Fields
	* |**Field**|**Type**|**Description**|
	* |--|--|--|
	* |SearchOptions||the usual search and pagination options|
	* |SmartSearch|string|This can take input of any of the below, useful for UIs|
	* |UUID|UUID(string)|Limit search to a particular product UUID|
	* |CustomerUUID|UUID(string)|Limit search to a particular customer|
	* |ReferenceCode|string|Limit search to a value in the ReferenceCode field|
	* |SubscriptionUUID|UUID(string)|Limit the search to a particular Subscription|
	* |ProductCatalogUUID|UUID(string)|Limit the search to products based off a particular product catalog entry|
	* |GroupUUID|UUID(string)|Limit the search to a particular sharing group|
	* |MSISDN|string|Limit the search to a paricular MSISDN|
	* |IMSI|string|Limit the search to a particular IMSI (SIM)|
	* 
	* ### Example Request
	* {
	*     /api/v1/1/postpay_mobile?SearchOptions.PageNumber=5&ReferenceCode=fuzzy
	* }
	* 
	* 
	* HTTP Method: GET
	* API Path: /api/v1/${req.SPID}/postpay_mobile
	* @param    {PostpayMobileSearchRequest} req The API payload object (PostpayMobileSearchRequest)
	* @return   {PostpayMobilePageResponse} The API response object (PostpayMobilePageResponse)
	*
	* @typedef  {Object}  PostpayMobileSearchRequest
	* @property {SearchOptions[]} SearchOptions      Search options to use (for sorting and/or pagination)
	* @property {Number}  SPID               SPID is the service provider ID that the product belongs to
	* @property {String}  SmartSearch        CustomerUUID if attached to a customer
	* @property {String}  UUID               UUID is the unique ID for this product
	* @property {String}  CustomerUUID       CustomerUUID if attached to a customer
	* @property {String}  ReferenceCode      ReferenceCode is a freeform string to an external ID
	* @property {String}  SubscriptionUUID   SubscriptionUUID is the subscription that pays for this service
	* @property {String}  ProductCatalogUUID ProductCatalogUUID is the catalog entry this product was based off of
	* @property {String}  GroupUUID          GroupUUID is the availability that this product was based off
	* @property {String}  MSISDN             MSISDN is if we want/wanted to autoallocate a MSISDN
	* @property {String}  IMSI               IMSI is the IMSI that this product has (SIM)
	*
	* @typedef  {Object}  PostpayMobilePageResponse
	* @property {PageInfo} PageInfo        PageInfo only needs the values ThisPageNumber && PageSize set
	* @property {PostpayProduct[]} PostpayProducts The list of matching Products
	*
	*/
	FindPostpayProductsPaginated({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		// Prepare URI params...
		let paramSPID = encodeURIComponent(req.SPID)
		return axios.get(`/v1/${paramSPID}/postpay_mobile`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('FindPostpayProductsPaginated ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('FindPostpayProductsPaginated ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('FindPostpayProductsPaginated ERROR:', error)
					return Promise.reject(error)
				}
				console.log('FindPostpayProductsPaginated UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in FindPostpayProductsPaginated'))
			})
	},

	/**
	* RPC Method: GetDataSettings
	* Description: ## GetDataSettings
	* 
	* Get Data Settings of the product
	* 
	* ### Request fields
	* |**Field**|**Type**|**Description**|
	* |--|--|--|
	* |UUID|string|The product instance UUID|
	* 
	* ### Example Request
	* 
	* {
	* /api/v1/1/postpay_mobile/1bcdb203-ec7e-461c-8870-d8467a167a78/data_settings
	* }
	* 
	* HTTP Method: GET
	* API Path: /api/v1/${req.SPID}/postpay_mobile/${req.UUID}/data_settings
	* @param    {PostpayMobileGetDataSettingsRequest} req The API payload object (PostpayMobileGetDataSettingsRequest)
	* @return   {PostpayMobileGetDataSettingsResponse} The API response object (PostpayMobileGetDataSettingsResponse)
	*
	* @typedef  {Object}  PostpayMobileGetDataSettingsRequest
	* @property {Number}  SPID         
	* @property {String}  UUID         ProductInstanceUUID
	* @property {String}  CustomerUUID For authentication
	*
	* @typedef  {Object}  PostpayMobileGetDataSettingsResponse
	* @property {DataEnabled} DataEnabled 
	*
	*/
	GetDataSettings({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		// Prepare URI params...
		let paramSPID = encodeURIComponent(req.SPID)
		let paramUUID = encodeURIComponent(req.UUID)
		return axios.get(`/v1/${paramSPID}/postpay_mobile/${paramUUID}/data_settings`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetDataSettings ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetDataSettings ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetDataSettings ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetDataSettings UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetDataSettings'))
			})
	},

	/**
	* RPC Method: GetPostpayProductByUUID
	* Description: ## GetPostpayProductByUUID
	* 
	* This endpoint allows for fetching of a Postpay product
	* 
	* 
	* HTTP Method: GET
	* API Path: /api/v1/${req.SPID}/customer/${req.CustomerUUID}/postpay_mobile/${req.UUID}
	* @param    {PostpayGetRequest} req The API payload object (PostpayGetRequest)
	* @return   {PostpayProduct} The API response object (PostpayProduct)
	*
	* @typedef  {Object}  PostpayGetRequest
	* @property {SearchOptions[]} SearchOptions Pagination options
	* @property {Number}  SPID          This is your service provider ID
	* @property {String}  CustomerUUID  Limit search to a specific customer
	* @property {String}  UUID          Limit search to a particular product
	*
	* @typedef  {Object}  PostpayProduct
	* @property {String}  UUID                    UUID is the unique ID for this product
	* @property {Number}  SPID                    Which direction is the port currently in action happening
	* @property {String}  CustomerUUID            CustomerUUID if attached to a customer
	* @property {String}  ReferenceCode           ReferenceCode is a freeform string to an external ID
	* @property {String}  SubscriptionUUID        SubscriptionUUID is the subscription that pays for this service
	* @property {String}  ProductCatalogUUID      ProductCatalogUUID is the catalog entry this product was based off of
	* @property {String}  ProductAvailabilityUUID ProductAvailabilityUUID is the availability that this product was based off
	* @property {String}  GroupUUID               GroupUUID is the availability that this product was based off
	* @property {Number}  Cents                   Cents is the cost of this product in cents
	* @property {Number}  CreatedAtNanos          CreatedAtCents is when this record was created in epoch nanoseconds
	* @property {Number}  UpdatedAtNanos          UpdatedAtCents is when this record was last updated in epoch nanoseconds
	* @property {Number}  DeletedAtNanos          DeletedAtCents is when this record was deleted in epoch nanoseconds
	* @property {Number}  ActivatedAtNanos        ActivatedAtNanos is when this product was activated in epoch nanoseconds
	* @property {Number}  TerminatedAtNanos       TerminatedAtNanos is when this product was terminated in epoch nanoseconds
	* @property {String}  MSISDN                  AutoAllocateMSISDN is if we want/wanted to autoallocate a MSISDN
	* @property {Boolean} AutoAllocateIMSI        AutoAllocateIMSI is if we want to autoallocate a IMSI (SIM)
	* @property {String}  IMSI                    IMSI is the IMSI that this product has (SIM)
	* @property {Boolean} Voicemail               Voicemail enabled or not
	* @property {String}  State                   State is the current product state
	* @property {String}  CellularServiceUUID     Cellular Service UUID
	* @property {String}  VoiceServiceUUID        Voice Service UUID
	* @property {String}  VoicemailServiceUUID    Voicemail Service UUID
	* @property {String}  MessagingServiceUUID    Messaging Service UUID
	* @property {Boolean} AutoAllocateMSISDN      AutoAllocateMSISDN is if we want/wanted to autoallocate a MSISDN
	* @property {String}  SIMID                   IMSI is the IMSI that this product has (SIM)
	* @property {VoiceSettings[]} VoiceSettings           Voice Settings
	* @property {ENUM}    PortingState            
	* @property {ENUM}    PortingAction           PortingDirection is this an INBOUND or OUTBOUND port
	* @property {DataEnabled} DataEnabled             Enable data
	*
	*/
	GetPostpayProductByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		// Prepare URI params...
		let paramSPID = encodeURIComponent(req.SPID)
		let paramCustomerUUID = encodeURIComponent(req.CustomerUUID)
		let paramUUID = encodeURIComponent(req.UUID)
		return axios.get(`/v1/${paramSPID}/customer/${paramCustomerUUID}/postpay_mobile/${paramUUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetPostpayProductByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetPostpayProductByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetPostpayProductByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetPostpayProductByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetPostpayProductByUUID'))
			})
	},

	/**
	* RPC Method: GetVoiceSettings
	* Description: ## GetVoiceSettings
	* 
	* Get voice settings of the product
	* 
	* ### Request fields
	* |**Field**|**Type**|**Description**|
	* |--|--|--|
	* |UUID|string|The product instance UUID|
	* 
	* ### Example Request
	* 
	* {
	* /api/v1/1/postpay_mobile/1bcdb203-ec7e-461c-8870-d8467a167a78/voicesettings
	* }
	* 
	* HTTP Method: GET
	* API Path: /api/v1/${req.SPID}/postpay_mobile/${req.UUID}/voicesettings
	* @param    {PostpayMobileGetVoiceSettingsRequest} req The API payload object (PostpayMobileGetVoiceSettingsRequest)
	* @return   {PostpayMobileGetVoiceSettingsResponse} The API response object (PostpayMobileGetVoiceSettingsResponse)
	*
	* @typedef  {Object}  PostpayMobileGetVoiceSettingsRequest
	* @property {Number}  SPID         
	* @property {String}  UUID         ProductInstanceUUID
	* @property {String}  CustomerUUID For authentication
	*
	* @typedef  {Object}  PostpayMobileGetVoiceSettingsResponse
	* @property {VoiceSettings[]} VoiceSettings 
	*
	*/
	GetVoiceSettings({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		// Prepare URI params...
		let paramSPID = encodeURIComponent(req.SPID)
		let paramUUID = encodeURIComponent(req.UUID)
		return axios.get(`/v1/${paramSPID}/postpay_mobile/${paramUUID}/voicesettings`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetVoiceSettings ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetVoiceSettings ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetVoiceSettings ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetVoiceSettings UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetVoiceSettings'))
			})
	},

	/**
	* RPC Method: PostpayProductPortCancel
	* Description: Cancels the IPMS Port Request if the port has not yet been completed. May only be called if we are the gaining service provider.
	* HTTP Method: PUT
	* API Path: /api/v3/${req.SPID}/customer/${req.CustomerUUID}/mobilepostpay/${req.UUID}/portcancel
	* @param    {PostpayProductPortCancelRequest} req The API payload object (PostpayProductPortCancelRequest)
	* @return   {PostpayProduct} The API response object (PostpayProduct)
	*
	* @typedef  {Object}  PostpayProductPortCancelRequest
	* @property {Number}  SPID         
	* @property {String}  UUID         UUID of PostpayProduct
	* @property {String}  CustomerUUID 
	*
	* @typedef  {Object}  PostpayProduct
	* @property {String}  UUID                    UUID is the unique ID for this product
	* @property {Number}  SPID                    Which direction is the port currently in action happening
	* @property {String}  CustomerUUID            CustomerUUID if attached to a customer
	* @property {String}  ReferenceCode           ReferenceCode is a freeform string to an external ID
	* @property {String}  SubscriptionUUID        SubscriptionUUID is the subscription that pays for this service
	* @property {String}  ProductCatalogUUID      ProductCatalogUUID is the catalog entry this product was based off of
	* @property {String}  ProductAvailabilityUUID ProductAvailabilityUUID is the availability that this product was based off
	* @property {String}  GroupUUID               GroupUUID is the availability that this product was based off
	* @property {Number}  Cents                   Cents is the cost of this product in cents
	* @property {Number}  CreatedAtNanos          CreatedAtCents is when this record was created in epoch nanoseconds
	* @property {Number}  UpdatedAtNanos          UpdatedAtCents is when this record was last updated in epoch nanoseconds
	* @property {Number}  DeletedAtNanos          DeletedAtCents is when this record was deleted in epoch nanoseconds
	* @property {Number}  ActivatedAtNanos        ActivatedAtNanos is when this product was activated in epoch nanoseconds
	* @property {Number}  TerminatedAtNanos       TerminatedAtNanos is when this product was terminated in epoch nanoseconds
	* @property {String}  MSISDN                  AutoAllocateMSISDN is if we want/wanted to autoallocate a MSISDN
	* @property {Boolean} AutoAllocateIMSI        AutoAllocateIMSI is if we want to autoallocate a IMSI (SIM)
	* @property {String}  IMSI                    IMSI is the IMSI that this product has (SIM)
	* @property {Boolean} Voicemail               Voicemail enabled or not
	* @property {String}  State                   State is the current product state
	* @property {String}  CellularServiceUUID     Cellular Service UUID
	* @property {String}  VoiceServiceUUID        Voice Service UUID
	* @property {String}  VoicemailServiceUUID    Voicemail Service UUID
	* @property {String}  MessagingServiceUUID    Messaging Service UUID
	* @property {Boolean} AutoAllocateMSISDN      AutoAllocateMSISDN is if we want/wanted to autoallocate a MSISDN
	* @property {String}  SIMID                   IMSI is the IMSI that this product has (SIM)
	* @property {VoiceSettings[]} VoiceSettings           Voice Settings
	* @property {ENUM}    PortingState            
	* @property {ENUM}    PortingAction           PortingDirection is this an INBOUND or OUTBOUND port
	* @property {DataEnabled} DataEnabled             Enable data
	*
	*/
	PostpayProductPortCancel({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.put(`/v3/${req.SPID}/customer/${req.CustomerUUID}/mobilepostpay/${req.UUID}/portcancel`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('PostpayProductPortCancel ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('PostpayProductPortCancel ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('PostpayProductPortCancel ERROR:', error)
					return Promise.reject(error)
				}
				console.log('PostpayProductPortCancel UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in PostpayProductPortCancel'))
			})
	},

	/**
	* RPC Method: PostpayProductPortRequest
	* Description: Places a Port Request with the tkt-nzlmnp-svc (calls ServiceProviderRequestPort). If successful, begins an automated process involving the IPMS, the gaining and losing service providers, and each respective carrier (if an inter-carrier port). The product service will be notified when the number is ready to be provisioned by the tkt-nzlmnp-svc. The caller may choose to omit the losing service provider ID and the NZLMNP service will attempt to determine it, however if unsuccessful it will return a list of known service provider for the Donor Carrier from which the caller may select from to resubmit the request.
	* HTTP Method: PUT
	* API Path: /api/v3/${req.SPID}/customer/${req.CustomerUUID}/mobilepostpay/${req.UUID}/portnumber
	* @param    {PostpayProductNumberPortRequest} req The API payload object (PostpayProductNumberPortRequest)
	* @return   {PostpayProductNumberPortResponse} The API response object (PostpayProductNumberPortResponse)
	*
	* @typedef  {Object}  PostpayProductNumberPortRequest
	* @property {Number}  SPID          
	* @property {String}  UUID          UUID of PrepayProduct
	* @property {String}  MSISDN        The SIMID to swap onto, the old SIM will be marked as ineligable
	* @property {String}  OldSIMID      The SIMID of the customer's current service (on current provider). Required if coming from Prepay.
	* @property {String}  CustomerUUID  
	* @property {Number}  LSP           Losing Service Provider IPMS ID. If omitted/zero, LMNP will attempt to discover LSP itself by querying the IPMS. However, if unsuccessful will return an error.,  In the case where it is provided, the LSPOverride flag will be 'true' in the request to the IPMS. It will be 'false' if the LSP if discovered.
	* @property {String}  AccountNumber The account number of the customer's current service (on current provider). Required if coming from OnAccount.
	* @property {String}  CustomerName  The name on the customer's account at the current service (on current provider). If coming from OnAccount, LMNP will attempt to use first and last name from the customer service using the CustomerUUID. Ideally this should be provided to avoid mis-match.
	*
	* @typedef  {Object}  PostpayProductNumberPortResponse
	* @property {Number}  SPID                      
	* @property {String}  UUID                      UUID of PostpayProduct
	* @property {String}  MSISDN                    The SIMID to swap onto, the old SIM will be marked as ineligable
	* @property {Number}  PortRFS                   When the port is expected to take place
	* @property {Boolean} TwoFANotificationExpected Will the port require a 2FA response
	* @property {ServiceProviderData[]} ServiceProviderList       
	*
	*/
	PostpayProductPortRequest({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.put(`/v3/${req.SPID}/customer/${req.CustomerUUID}/mobilepostpay/${req.UUID}/portnumber`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('PostpayProductPortRequest ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('PostpayProductPortRequest ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('PostpayProductPortRequest ERROR:', error)
					return Promise.reject(error)
				}
				console.log('PostpayProductPortRequest UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in PostpayProductPortRequest'))
			})
	},

	/**
	* RPC Method: SIMEnabled
	* Description: ## SIMEnabled
	* 
	* HTTP Method: PUT
	* API Path: /api/v1/${req.SPID}/postpay_mobile/${req.UUID}/sim_enabled
	* @param    {SIMEnableReq} req The API payload object (SIMEnableReq)
	* @return   {SIMEnableResponse} The API response object (SIMEnableResponse)
	*
	* @typedef  {Object}  SIMEnableReq
	* @property {Number}  SPID         
	* @property {String}  UUID         
	* @property {Boolean} Enabled      
	* @property {String}  CustomerUUID 
	*
	* @typedef  {Object}  SIMEnableResponse
	* @property {Number}  SPID             
	* @property {String}  ProductOrderUUID string CustomerUUID = 2;
	*
	*/
	SIMEnabled({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.put(`/v1/${req.SPID}/postpay_mobile/${req.UUID}/sim_enabled`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('SIMEnabled ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('SIMEnabled ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('SIMEnabled ERROR:', error)
					return Promise.reject(error)
				}
				console.log('SIMEnabled UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in SIMEnabled'))
			})
	},

	/**
	* RPC Method: TerminatePostpayProduct
	* Description: ## TerminatePostpayProduct
	* 
	* Post a terminate order for a product. You can provide an optional date and time for the request
	* to take effect, otherwise the order will take affect within normal processing parameters.
	*     
	* ### Request Fields
	* |**Field**|**Type**|**Description**|
	* |--|--|--|
	* |TerminationDate|datetime|The requested date/time for the termination to take effect|
	*    
	* ### Example Request
	* {
	*   /api/v1/1/postpay_mobile/da387d24-38a5-4ab1-bafa-cb7178db4f29?TerminateDate=2019-01-01T12:34:00
	* }
	* 
	* 
	* HTTP Method: DELETE
	* API Path: /api/v1/${req.SPID}/postpay_mobile/${req.UUID}
	* @param    {PostpayMobileTerminateRequest} req The API payload object (PostpayMobileTerminateRequest)
	* @return   {PostpayMobileTerminateResponse} The API response object (PostpayMobileTerminateResponse)
	*
	* @typedef  {Object}  PostpayMobileTerminateRequest
	* @property {Number}  SPID         
	* @property {String}  UUID         
	* @property {String}  CustomerUUID 
	*
	* @typedef  {Object}  PostpayMobileTerminateResponse
	* @property {Number}  SPID             
	* @property {String}  ProductOrderUUID 
	*
	*/
	TerminatePostpayProduct({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.delete(`/v1/${req.SPID}/postpay_mobile/${req.UUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('TerminatePostpayProduct ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('TerminatePostpayProduct ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('TerminatePostpayProduct ERROR:', error)
					return Promise.reject(error)
				}
				console.log('TerminatePostpayProduct UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in TerminatePostpayProduct'))
			})
	},

	/**
	* RPC Method: UpdateDataSettings
	* Description: ## UpdateDataSettings
	* 
	* Update Data Settings of the product
	* All DataEnabled items need to be set at the same time
	* 
	* ### Request fields
	* |**Field**|**Type**|**Description**|
	* |--|--|--|
	* |ProductAvailabilityUUID|string|The product plan UUID of this product|
	* |DataEnabled|object(DataEnabled)|The DataEnabled to be set|
	* 
	* ### Example Request
	* 
	*     {
	*        "SPID": 1,
	*        "UUID": "1bcdb203-ec7e-461c-8870-d8467a167a78",
	*        "CustomerUUID": "000c6ca1-ba32-4764-9cdc-360143e0e90f",
	*     	"ProductAvailabilityUUID": "1bcdb203-ec7e-461c-8870-d8467a167a78",
	*     	"DataEnabled": {
	*            "DataEnabled2G": true,
	*            "DataEnabled3G": true,
	*            "DataEnabled4G": true,
	*            "DataEnabled5G": true,
	*        }
	*     }
	* 
	* HTTP Method: PUT
	* API Path: /api/v1/${req.SPID}/postpay_mobile/${req.UUID}/data_settings
	* @param    {PostpayMobileUpdateDataSettingsRequest} req The API payload object (PostpayMobileUpdateDataSettingsRequest)
	* @return   {PostpayMobileUpdateDataSettingsResponse} The API response object (PostpayMobileUpdateDataSettingsResponse)
	*
	* @typedef  {Object}  PostpayMobileUpdateDataSettingsRequest
	* @property {Number}  SPID                    
	* @property {String}  UUID                    
	* @property {String}  CustomerUUID            
	* @property {String}  ProductAvailabilityUUID 
	* @property {DataEnabled} DataEnabled             
	*
	* @typedef  {Object}  PostpayMobileUpdateDataSettingsResponse
	* @property {DataEnabled} DataEnabled 
	*
	*/
	UpdateDataSettings({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.put(`/v1/${req.SPID}/postpay_mobile/${req.UUID}/data_settings`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('UpdateDataSettings ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('UpdateDataSettings ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('UpdateDataSettings ERROR:', error)
					return Promise.reject(error)
				}
				console.log('UpdateDataSettings UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in UpdateDataSettings'))
			})
	},

	/**
	* RPC Method: UpdatePostpayProduct
	* Description: ## UpdatePostpayProduct
	* 
	* Post a product change order into the system
	* 
	* ### Request Fields
	* |**Field**|**Type**|**Description**|
	* |--|--|--|
	* |SearchOptions||the usual search and pagination options|
	* |CustomerUUID|UUID(string)|Change the customer the product is attached to
	* |ReferenceCode|string|Change the reference code this product has
	* |ProductAvailabilityUUID|UUID(string)|Change the product catalog item this product is based off
	* |MSISDN|MSISDN(string)|Change the MSISDN this product has
	* |IMSI|IMSI(string)|Change this product to use a new SIM (IMSI)
	* |Voicemail|boolean|Enable or disable voicemail service
	* |GroupUUID|UUID(string)|Change or remove the sharing group this product is in
	* 
	* ### Example Request
	* {
	*     "ReferenceCode": "cust123/plan53",
	*     "Voicemail": false
	* }
	* 
	* HTTP Method: PUT
	* API Path: /api/v1/${req.SPID}/postpay_mobile/${req.UUID}
	* @param    {PostpayMobileUpdateRequest} req The API payload object (PostpayMobileUpdateRequest)
	* @return   {PostpayMobileUpdateResponse} The API response object (PostpayMobileUpdateResponse)
	*
	* @typedef  {Object}  PostpayMobileUpdateRequest
	* @property {String}  UUID                    UUID is the unique ID for this product
	* @property {Number}  SPID                    SPID is the service provider ID that the product belongs to
	* @property {String}  CustomerUUID            CustomerUUID if attached to a customer
	* @property {String}  ReferenceCode           ReferenceCode is a freeform string to an external ID
	* @property {String}  ProductAvailabilityUUID ProductAvailabilityUUID is the availability that this product was based off
	* @property {Boolean} AutoAllocateMSISDN      AutoAllocateMSISDN is if we want/wanted to autoallocate a MSISDN
	* @property {String}  MSISDN                  AutoAllocateMSISDN is if we want/wanted to autoallocate a MSISDN
	* @property {Boolean} AutoAllocateIMSI        AutoAllocateIMSI is if we want to autoallocate a IMSI (SIM)
	* @property {String}  IMSI                    IMSI is the IMSI that this product has (SIM)
	* @property {Boolean} Voicemail               Voicemail enabled or not
	* @property {String}  GroupUUID               GroupUUID is the sharing group this product is in
	* @property {VoiceSettings[]} VoiceSettings           For updating VoiceSettings such as CLIR, CLIP etc.
	* @property {DataEnabled} DataEnabled             For enable/disable data
	*
	* @typedef  {Object}  PostpayMobileUpdateResponse
	* @property {Number}  SPID             
	* @property {String}  ProductOrderUUID 
	*
	*/
	UpdatePostpayProduct({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.put(`/v1/${req.SPID}/postpay_mobile/${req.UUID}`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('UpdatePostpayProduct ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('UpdatePostpayProduct ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('UpdatePostpayProduct ERROR:', error)
					return Promise.reject(error)
				}
				console.log('UpdatePostpayProduct UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in UpdatePostpayProduct'))
			})
	},

	/**
	* RPC Method: UpdateVoiceMailStatus
	* Description: ## UpdateVoiceMailStatus
	* 
	* Post a voicemail change order into the system
	* 
	* 
	* HTTP Method: PUT
	* API Path: /api/v1/${req.SPID}/postpay_mobile/${req.UUID}/voicemail_change
	* @param    {VoiceMailEnableRequest} req The API payload object (VoiceMailEnableRequest)
	* @return   {VoiceMailEnableResponse} The API response object (VoiceMailEnableResponse)
	*
	* @typedef  {Object}  VoiceMailEnableRequest
	* @property {Number}  SPID         
	* @property {String}  UUID         
	* @property {Boolean} Enabled      
	* @property {String}  CustomerUUID 
	*
	* @typedef  {Object}  VoiceMailEnableResponse
	* @property {Number}  SPID         
	* @property {String}  UUID         
	* @property {String}  CustomerUUID 
	*
	*/
	UpdateVoiceMailStatus({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.put(`/v1/${req.SPID}/postpay_mobile/${req.UUID}/voicemail_change`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('UpdateVoiceMailStatus ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('UpdateVoiceMailStatus ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('UpdateVoiceMailStatus ERROR:', error)
					return Promise.reject(error)
				}
				console.log('UpdateVoiceMailStatus UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in UpdateVoiceMailStatus'))
			})
	},

	/**
	* RPC Method: UpdateVoiceSettings
	* Description: ## UpdateVoiceSettings
	* 
	* Update voice settings of the product.
	* All VoiceSettings items need to be set at the same time.
	* Attention: The contents in VoiceSettings cannot be changed:
	*    SPID
	*    UUID
	*    CustomerUUID
	* 
	* ### Request fields
	* |**Field**|**Type**|**Description**|
	* |--|--|--|
	* |ProductAvailabilityUUID|string|The product plan UUID of this product|
	* |VoiceSettings|object(VoiceSettings)|The voice settings to be set|
	* 
	* ### Example Request
	* 
	*     {
	*     	"ProductAvailabilityUUID": "1bcdb203-ec7e-461c-8870-d8467a167a78",
	*     	"VoiceSettings": {
	*            "SPID": 1,
	*            "UUID": "1bcdb203-ec7e-461c-8870-d8467a167a78",
	*            "CustomerUUID": "000c6ca1-ba32-4764-9cdc-360143e0e90f",
	*            "OutgoingCallerID": 3,
	*            "IncomingCallerID": true,
	*            "AllowConferencing": true,
	*            "CallWaitingAvailable": true,
	*            "CallWaitingEnabled": true
	* 	        "CFBActive":false,
	* 	        "CFBAvailable":true,
	* 	        "CFBDestination":"CFBDestination stub",
	* 	        "CFNRCActive":false,
	* 	        "CFNRCAvailable":true,
	* 	        "CFNRCDestination":"CFNRCDestination stub",
	* 	        "CFNRYAvailable":true,
	* 	        "CFNRYActive":false,
	* 	        "CFNRYDestination":true,
	* 	        "CFNRYRingtime":30,
	* 	        "CFUActive":false,
	* 	        "CFUAvailable":true,
	* 	        "CFUDestination":"CFUDestination stub",
	* 	        "BarIncomingCalls":false,
	* 	        "BarOutgoingCalls":false,
	* 	        "AllowIncomingCalls":true,
	* 	        "AllowIncomingWhileRoaming":true,
	* 	        "AllowOutgoingCalls":true,
	* 	        "AllowOutgoingInternationalCalls":false,
	* 	        "AllowSMSSend":true,
	* 	        "AllowSMSRecieve":true,
	* 	        "OCSBlockPremiumCalls":false,
	* 	        "OCSBlockPremiumSMS":false,
	*        }
	*     }
	* 
	* HTTP Method: PUT
	* API Path: /api/v1/${req.SPID}/postpay_mobile/${req.UUID}/voicesettings
	* @param    {PostpayMobileUpdateVoiceSettingsRequest} req The API payload object (PostpayMobileUpdateVoiceSettingsRequest)
	* @return   {PostpayMobileUpdateVoiceSettingsResponse} The API response object (PostpayMobileUpdateVoiceSettingsResponse)
	*
	* @typedef  {Object}  PostpayMobileUpdateVoiceSettingsRequest
	* @property {Number}  SPID                    
	* @property {String}  UUID                    
	* @property {String}  ProductAvailabilityUUID 
	* @property {VoiceSettings[]} VoiceSettings           
	* @property {String}  CustomerUUID            
	*
	* @typedef  {Object}  PostpayMobileUpdateVoiceSettingsResponse
	* @property {VoiceSettings[]} VoiceSettings 
	*
	*/
	UpdateVoiceSettings({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.put(`/v1/${req.SPID}/postpay_mobile/${req.UUID}/voicesettings`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('UpdateVoiceSettings ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('UpdateVoiceSettings ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('UpdateVoiceSettings ERROR:', error)
					return Promise.reject(error)
				}
				console.log('UpdateVoiceSettings UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in UpdateVoiceSettings'))
			})
	},
};

export default {
	namespaced: true,
	state: initialState,
	getters,
	mutations,
	actions,
};