/**
 * The file enables `@/store/index.js` to import all vuex modules in a one-shot manner.
 * There should not be any reason to edit this file.
 */

/**
 * В объекте "restricted_modules" ключом выступает имя файла без его типа, а значением название модуля из .env.example
 */
 const restricted_modules = {
    'fwa':             'PROD_FWA',
    'fibre':           'PROD_FIBRE',
    'mobile':          'PROD_MOBILE',
    'domains':         'DOMAINS',
    'sip-trunking':    'SIP_TRUNKING',
    'bulk':            'BULK_PRE_QUAL',
    'radius-services': 'SVC_RADIUS',
    'radius-sessions': 'SVC_RADIUS',
}

const restricted_modules_keys = Object.keys(restricted_modules)

const available_modules = [
    ...(process.env.VUE_APP_PRODUCT_MODULES ? process.env.VUE_APP_PRODUCT_MODULES.split(',') : []),
    ...(process.env.VUE_APP_SERVICE_MODULES ? process.env.VUE_APP_SERVICE_MODULES.split(',') : []),
    ...(process.env.VUE_APP_TOOLS_MODULES   ? process.env.VUE_APP_TOOLS_MODULES.split(',')   : []),
]

const file_paths = require.context('.', false, /\.js$/)
const modules = {}

file_paths.keys().forEach(path => {

    const module_name = path.replace(/(\.\/|\.js)/g, '')

    if (path != './index.js') {

        if (restricted_modules_keys.indexOf(module_name) != -1) {

            const result = available_modules.findIndex(module => {
                return module.toLowerCase() == restricted_modules[module_name].toLowerCase()
            }) != -1

            if (result) {

                modules[module_name] = file_paths(path).default

            }

        } else {

            modules[module_name] = file_paths(path).default

        }

    }
})

export default modules
