import axios from 'axios'

import StoreHash from '@/utils/StoreHash'

const vfenvironment_options = process.env.VUE_APP_VFENVIRONMENT_OPTIONS
    ? process.env.VUE_APP_VFENVIRONMENT_OPTIONS
        .split(',')
        .reduce((obj, item) => {
            obj[item.toLowerCase()] = item
            return obj
        }, {})
    : {}

    const WORKFLOW_EXECUTION_STATUSES = {
        'Unspecified': {
            status: 'WORKFLOW_EXECUTION_STATUS_UNSPECIFIED',
            color: 'pending', // _table-tag-colors.scss
            text: 'Unspecified',
            code: 0,
        },
        'Running': {
            status: 'WORKFLOW_EXECUTION_STATUS_RUNNING',
            color: 'pending', // _table-tag-colors.scss
            text: 'Running',
            code: 1,
        },
        'Completed': {
            status: 'WORKFLOW_EXECUTION_STATUS_COMPLETED',
            color: 'completed', // _table-tag-colors.scss
            text: 'Completed',
            code: 2,
        },
        'Failed': {
            status: 'WORKFLOW_EXECUTION_STATUS_FAILED',
            color: 'danger', // _table-tag-colors.scss
            text: 'Failed',
            code: 3,
        },
        'Canceled': {
            status: 'WORKFLOW_EXECUTION_STATUS_CANCELED',
            color: 'pending', // _table-tag-colors.scss
            text: 'Canceled',
            code: 4,
        },
        'Terminated': {
            status: 'WORKFLOW_EXECUTION_STATUS_TERMINATED',
            color: 'pending', // _table-tag-colors.scss
            text: 'Terminated',
            code: 5,
        },
        'Continued as new': {
            status: 'WORKFLOW_EXECUTION_STATUS_CONTINUED_AS_NEW',
            color: 'success', // _table-tag-colors.scss
            text: 'Continued as new',
            code: 6,
        },
        'Timed out': {
            status: 'WORKFLOW_EXECUTION_STATUS_TIMED_OUT',
            color: 'pending', // _table-tag-colors.scss
            text: 'Timed out',
            code: 7,
        },
    }

const hashed = {
    workflow_namespaces: new StoreHash(5 * 60 * 1000), // 5 min,
}

const state = {
    workflow_namespaces: {},
}

const getters = {
    vfenvironment_options: () => vfenvironment_options,
    have_vfenvironment_options: () => Object.keys(vfenvironment_options).length > 0,

    workflow_namespaces: state => state.workflow_namespaces,
    workflow_execution_statuses: () => WORKFLOW_EXECUTION_STATUSES,
}

const mutations = {
    saveWorkflowNamespaces(state, namespaces) {
        const workflow_namespaces = {}

        const pattern = /^VFNZ-ASAP-/i

        for (let i = 0, len = namespaces.length; i < len; i++) {
            if (pattern.test(namespaces[i].namespace_info.name)) {
                const namespace = {
                    id: namespaces[i].namespace_info.id,
                    name: namespaces[i].namespace_info.name,
                    title: namespaces[i].namespace_info.name.replace(pattern, ''),
                }
    
                workflow_namespaces[namespace.id] = namespace
            }
        }

        state.workflow_namespaces = workflow_namespaces

        hashed.workflow_namespaces.fix()
    },

    resetHashedWorkflowNamespaces(state) {
        state.workflow_namespaces = {}

        hashed.workflow_namespaces.reset()
    },
}

const actions = {
    getWorkflowExecutionHistory({}, { namespace_name, workflow_id, run_id, SPID }) {
        return axios.get(`/v3/temporal/${ namespace_name }/workflows/${ workflow_id }/${ run_id }?SPID=${ SPID }`)
            .then(response => {
                return Promise.resolve(response.data.Response)
            })
            .catch(error => {
                return Promise.reject(error)
            })
    },

    getWorkflowExecutions({}, { namespace_name, state, query_params }) {
        return axios.get(`/v3/temporal/${ namespace_name }/workflows/${ state }`, { params: query_params })
            .then(response => {
                // console.log('response.data.Response', response.data.Response)

                return Promise.resolve(response.data.Response)
            })
            .catch(error => {
                return Promise.reject(error)
            })
    },

    getWorkflowNamespaces({ commit, getters }, { SPID }) {
        if (hashed.workflow_namespaces.expired()) {
            const params = {
                SPID,
            }

            return axios.get('/v3/temporal/namespaces', { params })
                .then(response => {
                    const namespaces = response.data.Namespaces

                    commit('saveWorkflowNamespaces', namespaces)

                    return Promise.resolve(getters.workflow_namespaces)
                })
                .catch(error => {
                    return Promise.reject(error)
                })
        } else {
            return Promise.resolve(getters.workflow_namespaces)
        }
    },

    /*
        getWorkflowNamespaces({ commit, getters }) {
            if (hashed.workflow_namespaces.expired()) {
                return new Promise((resolve, reject) => {
                    const delay_in_ms = 3000

                    setTimeout(() => {
                        import('./hardcoded-test-data/asap/namespaces.json')
                            .then(response => {
                                const namespaces = response.default.Namespaces

                                commit('saveWorkflowNamespaces', namespaces)

                                resolve(getters.workflow_namespaces)
                            })
                            .catch(error => {
                                reject(error)
                            })
                    }, delay_in_ms)
                })
            } else {
                return Promise.resolve(getters.workflow_namespaces)
            }
        },
    */
}

export default {
    state,
    getters,
    mutations,
    actions,
}