import axios from 'axios'
import axiosUnique from '@/utils/axiosUnique'

import StoreHash from '@/utils/StoreHash'

let hashed = {
    radius_services: {
        uuids: {},
        lifetime: 1 * 60 * 1000, // 1 min
    },

    radius_services_sessions: {
        uuids: {},
        lifetime: 1 * 60 * 1000, // 1 min
    },
}

const state = {
    radius_services: {},
    radius_services_filter: null,

    radius_services_sessions: {},
}

const getters = {
    getRadiusServiceByUUID: state => uuid => state.radius_services[uuid],
    getRadiusServiceSessions: state => uuid => state.radius_services_sessions[uuid],

    radius_services_filter: state => state.radius_services_filter,
}

const mutations = {
    saveRadiusServices(state, services) {
        let services_list = {}

        for (let n = 0, len = services.length; n < len; n++) {
            const service = services[n]
            const uuid = service.UUID

            if (!(uuid in hashed.radius_services.uuids)) {
                hashed.radius_services.uuids[ uuid ] = new StoreHash(hashed.radius_services.lifetime)
            }
            
            services_list[ uuid ] = service

            hashed.radius_services.uuids[ uuid ].fix()
        }

        if (Object.keys(services_list).length) {
            state.radius_services = {
                ...state.radius_services,
                ...services_list
            }
        }
    },

    saveRadiusService(state, service) {
        const uuid = service.UUID

        if (!(uuid in hashed.radius_services.uuids)) {
            hashed.radius_services.uuids[ uuid ] = new StoreHash(hashed.radius_services.lifetime)
        }

        state.radius_services = {
            ...state.radius_services,
            [ uuid ]: service
        }

        hashed.radius_services.uuids[ uuid ].fix()
    },

    saveRadiusServiceSessions(state, { uuid, sessions }) {
        if (!(uuid in hashed.radius_services_sessions.uuids)) {
            hashed.radius_services_sessions.uuids[ uuid ] = new StoreHash(hashed.radius_services_sessions.lifetime)
        }

        state.radius_services_sessions = {
            ...state.radius_services_sessions,
            [ uuid ]: sessions
        }

        hashed.radius_services_sessions.uuids[ uuid ].fix()
    },

    saveRadiusServicesFilter(state, filter) {
        state.radius_services_filter = filter
    },    
    clearRadiusServicesFilter(state) {
        state.radius_services_filter = null
    },

    resetHashedRadiusServices(state) {
        state.radius_services = {}
        state.radius_services_sessions = {}

        hashed.radius_services.uuids = {}
        hashed.radius_services_sessions.uuids = {}
    },
}

const actions = {
    getRadiusServices({commit, dispatch}, { params, filter }) {
        /**
         * @todo Replace 2 corresponding lines below for real data
         */
        return import('./hardcoded-test-data/radius/services/radius-services-list.json').then(response => {
            const services = response.default.Services

        // return axiosUnique.get(`/v2/:spid/radius/services`, { params }).then(response => {
        //     const services = response.apidata.Services

            commit('saveRadiusServices', services)

            const action = filter ? 'saveRadiusServicesFilter' : 'clearRadiusServicesFilter'
            const resolve = () => Promise.resolve(response)

            return dispatch(action, filter).then(resolve).catch(resolve)
        }).catch(error => {
            return Promise.reject(error)
        })
    },

    getRadiusServiceByUUID({getters, commit, dispatch}, { uuid, refresh }) {
        if (!(uuid in hashed.radius_services.uuids)) {
			hashed.radius_services.uuids[ uuid ] = new StoreHash(hashed.radius_services.lifetime)
        }

        if (refresh || hashed.radius_services.uuids[ uuid ].expired()) {
            /**
             * @todo Replace 3 corresponding lines below for real data
             */
            return import('./hardcoded-test-data/radius/services/radius-service-by-uuid.json').then(apidata => {
                const service = apidata.default
                uuid = service.UUID
                
            // return axiosUnique.get(`/v2/:spid/radius/service/${ uuid }`).then(({apidata}) => {
            //     const service = apidata

                commit('saveRadiusService', service)
                return Promise.resolve(getters.getRadiusServiceByUUID(uuid))
            }).catch(error => {
                return Promise.reject(error)
            })
        } else {
            return Promise.resolve(getters.getRadiusServiceByUUID(uuid))
        }
    },

    getRadiusServiceSessions({getters, commit, dispatch}, { uuid, refresh }) {
        if (!(uuid in hashed.radius_services_sessions.uuids)) {
			hashed.radius_services_sessions.uuids[ uuid ] = new StoreHash(hashed.radius_services_sessions.lifetime)
        }

        if (refresh || hashed.radius_services_sessions.uuids[ uuid ].expired()) {
            /**
             * @todo Replace 2 corresponding lines below for real data
             */
            return import('./hardcoded-test-data/radius/services/radius-service-sessions.json').then(apidata => {
                const sessions = apidata.default.Sessions
                
            // return axiosUnique.get(`/v2/:spid/radius/service/${ uuid }/sessions`).then(({apidata}) => {
            //     const sessions = apidata.Sessions

                commit('saveRadiusServiceSessions', { uuid, sessions })
                return Promise.resolve(getters.getRadiusServiceSessions(uuid))
            }).catch(error => {
                return Promise.reject(error)
            })
        } else {
            return Promise.resolve(getters.getRadiusServiceSessions(uuid))
        }
    },

    loadRadiusServicesFilter({getters}) {
        const filter = getters.radius_services_filter

        return filter
            ? Promise.resolve(filter)
            : Promise.reject(filter)
    },
    saveRadiusServicesFilter({commit, getters}, filter) {
        commit('saveRadiusServicesFilter', filter)
        return Promise.resolve(getters.radius_services_filter)
    },
    clearRadiusServicesFilter({commit, getters}) {
        commit('clearRadiusServicesFilter')
        return Promise.resolve(getters.radius_services_filter)
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
}