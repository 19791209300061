import axiosUnique from '@/utils/axiosUnique'

const state = {
    tv_devices_search_results: {
        PageInfo: null,
        SearchResults: null,
    },
}

const getters = {
    tv_devices_search_results: state => state.tv_devices_search_results,
}

const mutations = {
    saveTVDevicesSearchResults(state, { PageInfo, SearchResults }) {
        state.tv_devices_search_results.PageInfo = PageInfo
        state.tv_devices_search_results.SearchResults = SearchResults
    },

    clearTVDevicesSearchResults(state) {
        state.tv_devices_search_results.PageInfo = null
        state.tv_devices_search_results.SearchResults = null
    }
}

const actions = {
    /**
     * Получение данных для таблицы "Search results" на странице "/tv/devices/kaltura/search-results/:page(\\d+)?"
     */
    getTVDevicesSearchResults({}, { params }) {
        console.group();

        console.log('getTVDevicesSearchResults()')
        console.log(params)

        console.groupEnd();

        return new Promise((resolve, reject) => {
            const delay_in_ms = 2000

            setTimeout(() => {
                import('./hardcoded-test-data/tv/devices/kaltura/search-results-list.json')
                    .then(response => {
                        const PageInfo = response.default.PageInfo
                        const SearchResults = response.default.SearchResults

                        resolve({ PageInfo, SearchResults })
                    })
                    .catch(error => {
                        reject(error)
                    })
            }, delay_in_ms)
        })

        /*
            return axiosUnique.get('/v2/:spid/tv/devices/kaltura', { params })
                .then(({ apidata }) => {
                    const PageInfo = apidata.PageInfo
                    const SearchResults = apidata.SearchResults

                    return Promise.resolve({ PageInfo, SearchResults })
                })
                .catch(error => {
                    return Promise.reject(error)
                })
        */
    },

    /**
     * Получение данных для таблицы "Local TV Devices" на странице "/tv/devices/local/:page(\\d+)?"
     */
    getLocalDevices({}, { params }) {
        console.group();

        console.log('getLocalDevices()')
        console.log(params)

        console.groupEnd();

        return new Promise((resolve, reject) => {
            const delay_in_ms = 2000

            setTimeout(() => {
                import('./hardcoded-test-data/tv/devices/local/local-devices-list.json')
                    .then(response => {
                        const PageInfo = response.default.PageInfo
                        const LocalDevices = response.default.LocalDevices

                        resolve({ PageInfo, LocalDevices })
                    })
                    .catch(error => {
                        reject(error)
                    })
            }, delay_in_ms)
        })

        /*
            return axiosUnique.get('/v2/:spid/tv/devices/local', { params })
                .then(({ apidata }) => {
                    const PageInfo = apidata.PageInfo
                    const LocalDevices = apidata.LocalDevices

                    return Promise.resolve({ PageInfo, LocalDevices })
                })
                .catch(error => {
                    return Promise.reject(error)
                })
        */
    },

    getCustomerDevices({}, { uuid }) {
        console.group();

        console.log('getCustomerDevices()')
        console.log(uuid)

        console.groupEnd();

        return new Promise((resolve, reject) => {
            const delay_in_ms = 2000

            setTimeout(() => {
                import('./hardcoded-test-data/tv/devices/local/customer-devices-list.json')
                    .then(response => {
                        resolve(response.default)
                    })
                    .catch(error => {
                        reject(error)
                    })
            }, delay_in_ms)
        })

        /*
            return axiosUnique.get(`/v2/:spid/tv/households/customer/${ uuid }/devices`)
                .then(({ apidata }) => {
                    return Promise.resolve(apidata)
                })
                .catch(error => {
                    return Promise.reject(error)
                })
        */
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
}