// Code generated by protoc-gen-iqwebapi. DO NOT EDIT.
// source: smsc.proto

import axios from 'axios';

const initialState = {
};

const valFuncFind = function (status) {
	return (status == 200) ? true : (status == 404) ? true : false;
}

const default_pageinfo = {
	PageNumber: 0,
	PageItemCount: 0,
	TotalPages: 0,
	TotalItemCount: 0,
	IndexStart: 0,
	PageSize: 10,
	DisplayPageList: [],
	DisplayPageEitherSide: 4,
}

let hashed = {
}

const getters = {
};

const mutations = {
};

const actions = {
	//
	// Service: SMSCRPC
	// 
	/**
	* AddToBlocklist: Adds blocklist entries by MSISDN. Existing entries will remain.
	* HTTP Method: POST
	* API Path: /api/v3/${req.SPID}/smsc_entry/${req.MSISDN}/blocklist_add
	* @param    {SMSCBlocklistChange} req The API payload object (SMSCBlocklistChange)
	* @return   {SMSCBlocklistResult} The API response object (SMSCBlocklistResult)
	*
	* @typedef  {Object}  SMSCBlocklistChange
	* @property {Number}  SPID            
	* @property {String}  MSISDN          
	* @property {String}  AffectedMSISDNs 
	*
	* @typedef  {Object}  SMSCBlocklistResult
	* @property {String}  MSISDN             
	* @property {Boolean} IsBlocklistEnabled 
	* @property {String}  BlockedMSISDNs     
	*
	*/
	AddToBlocklist({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.post(`/v3/${req.SPID}/smsc_entry/${req.MSISDN}/blocklist_add`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('AddToBlocklist ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('AddToBlocklist ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('AddToBlocklist ERROR:', error)
					return Promise.reject(error)
				}
				console.log('AddToBlocklist UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in AddToBlocklist'))
			})
	},

	/**
	* ClearBlocklist: Clears/removes the blocklist for a MSISDN
	* HTTP Method: DELETE
	* API Path: /api/v3/${req.SPID}/smsc_entry/${req.MSISDN}/blocklist_clear
	* @param    {SMSCBlocklistMSISDNSearch} req The API payload object (SMSCBlocklistMSISDNSearch)
	* @return   {SMSCBlocklistResult} The API response object (SMSCBlocklistResult)
	*
	* @typedef  {Object}  SMSCBlocklistMSISDNSearch
	* @property {Number}  SPID   
	* @property {String}  MSISDN 
	*
	* @typedef  {Object}  SMSCBlocklistResult
	* @property {String}  MSISDN             
	* @property {Boolean} IsBlocklistEnabled 
	* @property {String}  BlockedMSISDNs     
	*
	*/
	ClearBlocklist({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.delete(`/v3/${req.SPID}/smsc_entry/${req.MSISDN}/blocklist_clear`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('ClearBlocklist ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('ClearBlocklist ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('ClearBlocklist ERROR:', error)
					return Promise.reject(error)
				}
				console.log('ClearBlocklist UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in ClearBlocklist'))
			})
	},

	/**
	* CreateSMSCEntry: Add a new SMSC entry
	* HTTP Method: POST
	* API Path: /api/v3/${req.SPID}/smsc_entry/create
	* @param    {SMSCEntry} req The API payload object (SMSCEntry)
	* @return   {SMSCEntry} The API response object (SMSCEntry)
	*
	* @typedef  {Object}  SMSCEntry
	* @property {Number}  SPID               
	* @property {String}  MSISDN             
	* @property {Number}  ChangeTimeNanos    
	* @property {ENUM}    Status             
	* @property {Number}  BillingTypeID      
	* @property {Boolean} IsBlocklistEnabled 
	* @property {String}  BlockedMSISDNs     
	*
	*/
	CreateSMSCEntry({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.post(`/v3/${req.SPID}/smsc_entry/create`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('CreateSMSCEntry ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('CreateSMSCEntry ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('CreateSMSCEntry ERROR:', error)
					return Promise.reject(error)
				}
				console.log('CreateSMSCEntry UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in CreateSMSCEntry'))
			})
	},

	/**
	* DeleteSMSCEntry: Delete an SMSC entry and any associated blocklist entries
	* HTTP Method: DELETE
	* API Path: /api/v3/${req.SPID}/smsc_entry/${req.MSISDN}/delete
	* @param    {SMSCMSISDNSearch} req The API payload object (SMSCMSISDNSearch)
	* @return   {DeletedResult} The API response object (DeletedResult)
	*
	* @typedef  {Object}  SMSCMSISDNSearch
	* @property {Number}  SPID   
	* @property {String}  MSISDN 
	*
	* @typedef  {Object}  DeletedResult
	* @property {Boolean} IsDeleted 
	*
	*/
	DeleteSMSCEntry({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.delete(`/v3/${req.SPID}/smsc_entry/${req.MSISDN}/delete`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('DeleteSMSCEntry ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('DeleteSMSCEntry ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('DeleteSMSCEntry ERROR:', error)
					return Promise.reject(error)
				}
				console.log('DeleteSMSCEntry UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in DeleteSMSCEntry'))
			})
	},

	/**
	* EnableDisableBlocklist: Enable/Disable the blocklist for a SMSC entry by MSISDN
	* HTTP Method: PUT
	* API Path: /api/v3/${req.SPID}/smsc_entry/${req.MSISDN}/toggle_blocklist
	* @param    {SMSCBlocklistEnableDisable} req The API payload object (SMSCBlocklistEnableDisable)
	* @return   {SMSCBlocklistResult} The API response object (SMSCBlocklistResult)
	*
	* @typedef  {Object}  SMSCBlocklistEnableDisable
	* @property {Number}  SPID               
	* @property {String}  MSISDN             
	* @property {Boolean} IsBlocklistEnabled 
	*
	* @typedef  {Object}  SMSCBlocklistResult
	* @property {String}  MSISDN             
	* @property {Boolean} IsBlocklistEnabled 
	* @property {String}  BlockedMSISDNs     
	*
	*/
	EnableDisableBlocklist({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.put(`/v3/${req.SPID}/smsc_entry/${req.MSISDN}/toggle_blocklist`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('EnableDisableBlocklist ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('EnableDisableBlocklist ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('EnableDisableBlocklist ERROR:', error)
					return Promise.reject(error)
				}
				console.log('EnableDisableBlocklist UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in EnableDisableBlocklist'))
			})
	},

	/**
	* GetBlocklist: Enable/Disable the blocklist for a SMSC entry by MSISDN
	* HTTP Method: GET
	* API Path: /api/v3/${req.SPID}/smsc_entry/${req.MSISDN}/blocklist
	* @param    {SMSCBlocklistMSISDNSearch} req The API payload object (SMSCBlocklistMSISDNSearch)
	* @return   {SMSCBlocklistResult} The API response object (SMSCBlocklistResult)
	*
	* @typedef  {Object}  SMSCBlocklistMSISDNSearch
	* @property {Number}  SPID   
	* @property {String}  MSISDN 
	*
	* @typedef  {Object}  SMSCBlocklistResult
	* @property {String}  MSISDN             
	* @property {Boolean} IsBlocklistEnabled 
	* @property {String}  BlockedMSISDNs     
	*
	*/
	GetBlocklist({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.get(`/v3/${req.SPID}/smsc_entry/${req.MSISDN}/blocklist`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetBlocklist ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetBlocklist ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetBlocklist ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetBlocklist UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetBlocklist'))
			})
	},

	/**
	* GetSMSCEntriesPaginated: Get multiple smsc entries
	* HTTP Method: GET
	* API Path: /api/v3/${req.SPID}/smsc_entry
	* @param    {SMSCEntriesPaginatedSearch} req The API payload object (SMSCEntriesPaginatedSearch)
	* @return   {SMSCEntriesPaginatedSearchResults} The API response object (SMSCEntriesPaginatedSearchResults)
	*
	* @typedef  {Object}  SMSCEntriesPaginatedSearch
	* @property {Number}  SPID                 
	* @property {SearchOptions[]} SearchOptions        
	* @property {Number}  ChangeTimeStartNanos 
	* @property {Number}  ChangeTimeEndNanos   
	* @property {Number}  BillingTypeID        
	*
	* @typedef  {Object}  SMSCEntriesPaginatedSearchResults
	* @property {Number}  SPID        
	* @property {PageInfo} PageInfo    
	* @property {SMSCEntrySimple[]} SMSCEntries 
	*
	*/
	GetSMSCEntriesPaginated({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.get(`/v3/${req.SPID}/smsc_entry`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetSMSCEntriesPaginated ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetSMSCEntriesPaginated ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetSMSCEntriesPaginated ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetSMSCEntriesPaginated UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetSMSCEntriesPaginated'))
			})
	},

	/**
	* GetSMSCEntryByMSISDN: Get a single SMSC entry
	* HTTP Method: GET
	* API Path: /api/v3/${req.SPID}/smsc_entry/${req.MSISDN}
	* @param    {SMSCMSISDNSearch} req The API payload object (SMSCMSISDNSearch)
	* @return   {SMSCEntry} The API response object (SMSCEntry)
	*
	* @typedef  {Object}  SMSCMSISDNSearch
	* @property {Number}  SPID   
	* @property {String}  MSISDN 
	*
	* @typedef  {Object}  SMSCEntry
	* @property {Number}  SPID               
	* @property {String}  MSISDN             
	* @property {Number}  ChangeTimeNanos    
	* @property {ENUM}    Status             
	* @property {Number}  BillingTypeID      
	* @property {Boolean} IsBlocklistEnabled 
	* @property {String}  BlockedMSISDNs     
	*
	*/
	GetSMSCEntryByMSISDN({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.get(`/v3/${req.SPID}/smsc_entry/${req.MSISDN}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetSMSCEntryByMSISDN ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetSMSCEntryByMSISDN ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetSMSCEntryByMSISDN ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetSMSCEntryByMSISDN UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetSMSCEntryByMSISDN'))
			})
	},

	/**
	* ModifySMSCEntry: Update an existing SMSC entry
	* HTTP Method: PUT
	* API Path: /api/v3/${req.SPID}/smsc_entry/${req.MSISDN}/update
	* @param    {SMSCEntry} req The API payload object (SMSCEntry)
	* @return   {SMSCEntry} The API response object (SMSCEntry)
	*
	* @typedef  {Object}  SMSCEntry
	* @property {Number}  SPID               
	* @property {String}  MSISDN             
	* @property {Number}  ChangeTimeNanos    
	* @property {ENUM}    Status             
	* @property {Number}  BillingTypeID      
	* @property {Boolean} IsBlocklistEnabled 
	* @property {String}  BlockedMSISDNs     
	*
	*/
	ModifySMSCEntry({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.put(`/v3/${req.SPID}/smsc_entry/${req.MSISDN}/update`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('ModifySMSCEntry ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('ModifySMSCEntry ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('ModifySMSCEntry ERROR:', error)
					return Promise.reject(error)
				}
				console.log('ModifySMSCEntry UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in ModifySMSCEntry'))
			})
	},

	/**
	* RemoveFromBlocklist: Removes blocklist entries by MSISDN
	* HTTP Method: POST
	* API Path: /api/v3/${req.SPID}/smsc_entry/${req.MSISDN}/blocklist_remove
	* @param    {SMSCBlocklistChange} req The API payload object (SMSCBlocklistChange)
	* @return   {SMSCBlocklistResult} The API response object (SMSCBlocklistResult)
	*
	* @typedef  {Object}  SMSCBlocklistChange
	* @property {Number}  SPID            
	* @property {String}  MSISDN          
	* @property {String}  AffectedMSISDNs 
	*
	* @typedef  {Object}  SMSCBlocklistResult
	* @property {String}  MSISDN             
	* @property {Boolean} IsBlocklistEnabled 
	* @property {String}  BlockedMSISDNs     
	*
	*/
	RemoveFromBlocklist({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.post(`/v3/${req.SPID}/smsc_entry/${req.MSISDN}/blocklist_remove`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('RemoveFromBlocklist ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('RemoveFromBlocklist ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('RemoveFromBlocklist ERROR:', error)
					return Promise.reject(error)
				}
				console.log('RemoveFromBlocklist UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in RemoveFromBlocklist'))
			})
	},

	/**
	* SetBlocklist: overrides blocklist entries by MSISDN. Existing entries will be overridden
	* HTTP Method: POST
	* API Path: /api/v3/${req.SPID}/smsc_entry/${req.MSISDN}/blocklist_override
	* @param    {SMSCBlocklistChange} req The API payload object (SMSCBlocklistChange)
	* @return   {SMSCBlocklistResult} The API response object (SMSCBlocklistResult)
	*
	* @typedef  {Object}  SMSCBlocklistChange
	* @property {Number}  SPID            
	* @property {String}  MSISDN          
	* @property {String}  AffectedMSISDNs 
	*
	* @typedef  {Object}  SMSCBlocklistResult
	* @property {String}  MSISDN             
	* @property {Boolean} IsBlocklistEnabled 
	* @property {String}  BlockedMSISDNs     
	*
	*/
	SetBlocklist({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.post(`/v3/${req.SPID}/smsc_entry/${req.MSISDN}/blocklist_override`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('SetBlocklist ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('SetBlocklist ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('SetBlocklist ERROR:', error)
					return Promise.reject(error)
				}
				console.log('SetBlocklist UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in SetBlocklist'))
			})
	},
};

export default {
	namespaced: true,
	state: initialState,
	getters,
	mutations,
	actions,
};