import axios from 'axios'

const DASHBOARD_API_URL = process.env.VUE_APP_DASHBOARD_API_URL || '/api/:spid/dashboard'

const state = {}

const getters = {
    dashboard_api_url: () => DASHBOARD_API_URL,
}

const mutations = {}

const actions = {
    getData({ getters }, { SPID }) {
        /**
         * @todo
         */
        return axios.get(getters.dashboard_api_url.replace(/:spid(\/|\?|$)/gi, `${ SPID }$1`))
            .then(response => {
                return Promise.resolve(response.data)
            })
            .catch(error => {
                return Promise.reject(error)
            })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}